import Container from "./layouts/container";
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider,CssBaseline } from '@mui/material';
import theme from './themes'
import {QueryClientProvider,QueryClient} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools'

// setup the react query clients
const queryClient=new QueryClient();


function App() {
  console.log("theme object=",theme);
  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
            <Container/>
        </ThemeProvider>
      </StyledEngineProvider>
    
      {/* TODO: remove the devtools in development */}
      {/* <ReactQueryDevtools initialIsOpen={false}/> */}
    </QueryClientProvider>
  );
}

export default App;
