import React,{useCallback,useEffect, useState} from "react";
import "../styles/styles.scss"


const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';

const YAxisContextMenu = (props) => {
  let {
    xPosition,
    yPosition,
    exitMenu,
    data,
    key,
    chartScreenType,
    value,
    setBoundary,
    levelData
    
  } = props;

      
  const handleEscKey = useCallback((event) => {
    if (event.key === KEY_NAME_ESC) {
      exitMenu();
    }
  }, [exitMenu]);
 
  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
 
    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);

 
 
  // title="Plot the profiles between the selected levels and chart changes will be lost
  return (
    <>
    <ul
        className="context-menu"
        style={{
            top: yPosition,
            left: xPosition,
        }}>
    
        {levelData[0]==-1 && levelData[1]==-1?
        <li onClick={(e) => setBoundary(value)}>
             Select Level
        </li>:
        <>
        {levelData[0]==-1 || levelData[1]==-1?
        <li onClick={(e) => setBoundary(value)} >
          {value>levelData[1]?"Freeze Upper Level & Plot":"Freeze Lower Level & Plot"}
        </li>:
        <li style={{cursor:'auto', opacity:0.6}} title="Two levels already selected and freezed">
          Select Level
        </li>
        }

        </>
        }
        
        <hr className="hr-menu"/>
        <li onClick={(e) => exitMenu()}>
            Close
        </li>
       
    </ul>
      
    </>
  );
};

export default YAxisContextMenu;
