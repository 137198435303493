import * as React from 'react';
import { useState,useRef,useEffect } from 'react';
import Button from '@mui/material/Button';
import {IconButton,Alert, Snackbar } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Header from '../components/header';
import { Formik, Form, Field,ErrorMessage} from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MuiTextFieldFormik } from '../../../components/CustomTextField/TextField';
import { validateRegisterationForm } from '../../../common/utility/validators';
import { useAxios } from '../../../common/api/hooks/useAxios';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import WelcomeLayout from '../components/welcome-layout';
import FormContainer from '../components/form-container';
import { useTheme } from '@mui/material';
import MainLayoutContainer from '../components/main-layout';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../../common/utility/constant';
import TermsOfUseModal from '../../dashboard/components/terms_of_use';
// import bannerImg from '../../../assets/images/diwali-frontpage-banner.jpeg';
import bannerImg from '../../../assets/images/hny-frontpage-banner1.jpeg';
import miniBannerImg from '../../../assets/images/mini-banner1.jpeg';


const initialValues = {
  first_name:'',
  last_name:'',
  email_id: '',
  password: '',
  contact:'',
  terms: false
};

export default function RegisterUser() {
  const theme=useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const { cancel,responseData, error, loaded, reset,executeAPI } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const formikRef = useRef();
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });

 const { open, msg,severity } = msgState;

 const handleClickShowPassword = () => setShowPassword(!showPassword);
 
 const [apiError,setAPIError]=useState(undefined);
  
/**
 * API response handler  
 */  
  useEffect(() => {
  
    if(loaded){
      if(responseData!=null){
        navigate(`/email-verification?emailId=${formikRef.current.values.email_id}`, { replace: true });
   
      }
      else if(error!==null){
        formikRef?.current.setSubmitting(false);
        if(error?.response.status===400 || error?.response.status===401 || error?.response.status===404){
          setAPIError(error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`);
          //formikRef?.current.setErrors({email_id:"Email id already registered"});
        }else{
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
          ,severity:"info"}); 
        }
      }
      reset();
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loaded,responseData,error,navigate,from]);

  
 /**
 * API caller  
 */  
  const onSubmit = (values, props) => {
    setAPIError(undefined);
    executeAPI(URL.REGISTER,"POST",JSON.stringify(values));    
   }

 /**
 * autoclosing of the snackbar msg bar 
 */ 
    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };

  const [openModal,setOpenModal]=useState(false)
  const openTermModal=()=>{
    setOpenModal(true);
  }

  const closeModal=()=>{
    setOpenModal(false);
  }

  return (
        <MainLayoutContainer>

        {/* Header component */}
        <Header showLogin={true} showIntro={true}/>
       
        {/*Welcome banner left component*/}
        <WelcomeLayout/>

        {openModal?
            <TermsOfUseModal closeModal={closeModal}></TermsOfUseModal>
              : 
        <></>}

        {/* Form container for right layut */}
        <FormContainer>
          {/* <img src={bannerImg} alt="diwali_offer" style={{width: "100%", marginLeft: 0, marginRight: 40, marginTop: -20, marginBottom: 120}} /><br/> */}
          {/* <img src={miniBannerImg} alt="diwali_offer" style={{width: "100%", marginLeft: 0, marginRight: 40, marginTop: -20, marginBottom: 20}} /><br/> */}
       
        <Box sx={{mt:{mobile:0,tablet:0}, width:{mobile:"95%", tablet:"85%", laptop:"75%"} }}>
        <Typography  variant="titleMedium">
          Register for Vtrender Charts
        </Typography>
        <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} validate={validateRegisterationForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
                <Form>
                 
          
                  <Box display="flex" >
                  <Grid container spacing={2} sx={{mt:1,mb:2}}>
                  <Grid item mobile={12} tablet={6}>
                     <Field
                      component={MuiTextFieldFormik}
                      fullWidth
                      required
                      variant="outlined" 
                      size="small" 
                      color="inputFieldColor"
                      id="first_name"
                      name="first_name"
                      type="text"
                      label="First Name"
                    />
                    </Grid>
                     <Grid item mobile={12} tablet={6}>
                    <Field
                      component={MuiTextFieldFormik}
                      fullWidth
                      required
                      variant="outlined" 
                      size="small" 
                      color="inputFieldColor"
                      name="last_name"
                      id="last_name"
                      type="text"
                      label="Last Name"
                    />
                    </Grid>
                    </Grid>
              </Box>

              <Grid container spacing={2} mb={2}>
              <Grid item mobile={12} tablet={6}>
                  
                          <Field
                      component={MuiTextFieldFormik}
                      fullWidth
                      variant="outlined" 
                      size="small" 
                      color="inputFieldColor"
                      id="email_id"
                      name="email_id"
                      type="email"
                      label="Email"
                      required
                    />
                  </Grid> 

                   <Grid item mobile={12} tablet={6}>
               
                    <Field
                      component={MuiTextFieldFormik}
                      fullWidth
                      variant="outlined" 
                      size="small" 
                      color="inputFieldColor"
                      name="contact"
                      id="contact"
                      type="text"
                      label="Mobile No."
                    />
                    </Grid>
              </Grid>

              <Grid item mobile={12} tablet={6} mb={1}>
              
                <Field
                  component={MuiTextFieldFormik}
                  fullWidth
                  variant="outlined" 
                  size="small" 
                  color="inputFieldColor"
                  sx={{ pr:{tablet:1}}}
                  type={showPassword ? "text" : "password"} 
                  label="Password"
                  name="password"
                  required
                  InputProps={{ 
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>

              <Grid item mobile={12} tablet={12} mb={0} alignItems="center">
                <Field as={FormControlLabel}
                    name='terms'
                    control={<Checkbox 
                      sx={{
                        color: theme.palette.secondaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.secondaryTheme.shade03,
                        },
                      }}/>
                    }
                    label={<Typography variant="linkSupportText" >
                        I agree to all
                        <Link onClick={openTermModal} sx={{ml:1,cursor:"pointer"}} variant="link">Terms & Conditions, Fees & Privacy Policy</Link>
                      </Typography>
                    }
                   />
              </Grid>
              
               {/* Error msg incase terms and conditions checkbox is not checked */}
              <ErrorMessage name="terms" render={msg =>  <Typography variant="warningText">{msg}</Typography> } /> 
              
              {apiError!=undefined?
                <Typography variant="errorText">{apiError}</Typography>:
                <></>
              }

              <Grid item mobile={12} tablet={6}> 
                <Button type='submit'  sx={{mb:2,mr:1, mt:1}} variant="contained" disabled={isSubmitting}
                      fullWidth>{isSubmitting ? "Loading" : "Create Account"}</Button>
              </Grid>

          </Form>
          )}
        </Formik>
                
          <Grid item>
            <Typography variant="link">{"Already have an account? "} 
              <Link href="/login" variant="link" sx={{pb:6}}>
              Log in
              </Link>
            </Typography>
          </Grid>
          <br/><br/>
        </Box>
      
      </FormContainer>
      
      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
        <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
          {msg}
        </Alert>  
      </Snackbar>     
    
   </MainLayoutContainer>
  );
}