import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from '@mui/material';
import {TEXT_MSGS,PREMIUM_UPGRADE_MSGS_LIST, OF_ZOOM_TYPE} from '../../../common/utility/constant';
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ArrowBack, ArrowDownward, ArrowForward, ArrowRight, ArrowUpward, CloseFullscreen, Height, OpenInFull } from '@mui/icons-material';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
//   width: 400,
  bgcolor: 'background.paper',
  border: '0px',
//   boxShadow: 24,
  p: 3,
//   overflow: 'scroll',
};

export default function OFHelpPopup(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(props.open);
 
  const handleClose = () => {
    setOpen(false)
    props.onClose();
}

  return (
    
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <CancelOutlinedIcon
                onClick={() => handleClose()}
                sx={{
                ml: 0, mt: 0,top: `calc(0% - 12px)`,left: `calc(100% - 14px)`,
                 height: 24, width: 26, cursor: 'pointer', position: 'fixed', zIndex: 9999
                }}
            />
          {
            (props.zoomType == OF_ZOOM_TYPE.ASYMMETRIC)?
            <>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              How to Zoom and Navigate in Orderflow Chart? - <b style={{color:"#F3B23E"}}>Asymmetric Navigation</b>
            </Typography>

              <Box style={{display:'flex',flexDirection:"row"}} sx={{mt: 2,mb:2}}>
                  <Typography id="modal-modal-description" sx={{ ml:1 }}>
                  <b style={{color:"#F3B23E"}}>Horizontal Zooming:</b> <CloseFullscreen sx={{ml:1, mb:"-7px",transform:'rotate(45deg)'}}/> <OpenInFull sx={{ml:1, mb:"-7px",transform:'rotate(45deg)'}}/><br/>
                  Keep the mouse pointer in middle of chart area and either pinch in or out (or use the mouse scroll-wheel) to have corresponding zoom effect.<br/><br/>
                  </Typography> 
              </Box>

              <Box style={{display:'flex',flexDirection:"row"}} sx={{mt: 2,mb:2}}>
                  <Typography id="modal-modal-description" sx={{ ml:1}}>
                  <b style={{color:"#F3B23E"}}>Vertical Zooming:</b> <CloseFullscreen sx={{ml:1, mb:"-7px",transform:'rotate(-45deg)'}}/><OpenInFull sx={{ml:0, mb:"-7px",transform:'rotate(-45deg)'}}/><br/>
                  Keep the mouse pointer on the y-axis, where levels are printed and either pinch in or out (or use the mouse scroll-wheel) to have corresponding zoom effect.<br/><br/>
                  </Typography> 
              </Box>

              <Box style={{display:'flex',flexDirection:"row"}} sx={{mt: 2,mb:2}}>
                  <Typography id="modal-modal-description" sx={{ ml:1 }}>
                  <b style={{color:"#F3B23E"}}>Horizontal Panning/Shifting:</b><ArrowBack sx={{ml:1, mb:"-7px"}}/> <ArrowForward sx={{ml:0, mb:"-7px"}}/><br/>
                  Click in the middle of chart area and hold the click. Drag the finger/mouse horizontally while holding down the click or pressing the finger, to move the chart area sideways.<br/><br/>
                  </Typography> 
              </Box>

              <Box style={{display:'flex',flexDirection:"row"}} sx={{mt: 2,mb:2}}>
                  <Typography id="modal-modal-description" sx={{ ml:1 }}>
                  <b style={{color:"#F3B23E"}}>Vertical Panning/Shifting:</b><ArrowUpward sx={{ml:1, mb:"-7px"}}/> <ArrowDownward sx={{ml:0, mb:"-7px"}}/><br/>
                  Click on the y-axis, where levels are printed and hold the click. Drag the finger/mouse vertically (up-down) while holding down the click or pressing the finger, to move the chart area up-down.
                  </Typography> 
              </Box>
            </>
            :
            <>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              How to Zoom and Navigate in Orderflow Chart? - <b style={{color:"#F3B23E"}}>Symmetric Navigation</b>
            </Typography>

              <Box style={{display:'flex',flexDirection:"row"}} sx={{mt: 2,mb:2}}>
                  <Typography id="modal-modal-description" sx={{ ml:1 }}>
                    Zooming and Panning activity works similar to Market Profile Chart. <br/><br/>
                    Pinch in or out (or use the mouse scroll-wheel) to have corresponding zoom effect - symmetrical on both x and y axis.<br/><br/>
                    Click anywhere in the chart area and hold the click. Drag the finger/mouse while holding down the click or pressing the finger - in any direction - to move the chart in that direction.
                  </Typography> 
              </Box>
            </>
          }

         
          
          {/* <Typography variant="linkSupportText" sx={{mt:2}}>
           
              <Button variant="contained" onClick={()=>handleClose()} sx={{mr:2}}>
                Close
              </Button>
          </Typography> */}
        </Box>
      </Modal>

  );
}