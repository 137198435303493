import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from '@mui/material';
import {TEXT_MSGS,PREMIUM_UPGRADE_MSGS_LIST} from '../../../common/utility/constant';
import { useNavigate } from "react-router-dom";
import WarnIcon from '@mui/icons-material/Warning';
import { useLocation } from 'react-router-dom';
import { USER_MANUAL_TAB_LIST } from '../../../common/utility/constant';
import UserManual from '../user_manual';
import useMediaQuery from '@mui/material/useMediaQuery';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const style = {
  position: 'absolute',
  top: '7%',
  left: '5%',
  height: "86%",
  //   transform: 'translate(-50%, -50%)',
  width: "90%",
//   transform: 'translate(-50%, -50%)',
//   width: 500,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
//   overflowY:"scroll",
//   p: 3,
};

export default function UserManualPopup(props) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false)
    props.onClose();
}
const location = useLocation();

const index=USER_MANUAL_TAB_LIST.findIndex((item)=>(item?.path?.includes(location.pathname)));
const [value, setValue] = React.useState(index!=-1?index:0);
const isBigScreen = useMediaQuery('(min-width:1024px)');

React.useEffect(()=>{
console.log("location in pop up=",location,location.pathname,index)
},[])


    

  return (
    
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <CancelOutlinedIcon
                onClick={() => handleClose()}
                sx={{
                ml: 0, mt: 0,top: `calc(7% - 12px)`,left: `calc(95% - 13px)`,
                 height: 24, width: 26, cursor: 'pointer', position: 'fixed', zIndex: 9999
                }}
            />
            <Box sx={{  bgcolor: 'background.paper', display: 'flex',mt:0,pt:0,background:"#132d5a",width:"100%",height:"100%", flexDirection: isBigScreen?'row':'column',overflow:'scroll'}}>
                <UserManual value={value} popUp={true}/>
            </Box>

         </Box>
      </Modal>

  );
}