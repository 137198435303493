import React,{memo, useContext,useEffect,useState,useRef} from 'react'
import { UserSettingsContext } from '../../../setup/routes-manager/RequireAuth'
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION,TABLE_TYPES,OF_ZOOM_TYPE, OF_TIME_FRAME,OF_BOTTOM_COL_LIST, DEFAULT_OF_TIME_FRAME, TIME_FRAME_VALUES, OF_TIME_FRAME_VALUES } from '../../../common/utility/constant';
import { Snackbar,Alert,CircularProgress,InputLabel,Switch,Radio,RadioGroup,FormControlLabel,FormLabel, Link ,Checkbox,useTheme} from '@mui/material';
import {Grid, Box,Typography,Select,ListSubheader,TextField,InputAdornment,FormControl,MenuItem} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SettingsFooter from './settings_footer';
import { findIndex } from '@amcharts/amcharts5/.internal/core/util/Array';
import { VP_TYPE,VA_TYPE } from '../../../common/utility/mp_chart_constants';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { createDictList } from '../../../common/utility/helperFunc';
import Modal from '@mui/material/Modal';

import { List } from 'react-virtualized';
import 'react-virtualized/styles.css'; // Import the styles for the List component
import useMediaQuery from '@mui/material/useMediaQuery';
import SettingsFooterPopup from './settings_footer_popup';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// const OF_BOTTOL_COL={Volume:true,VPOC:true,"VPOC Vol":true,COT:true,OI:true,dOI:true};
// const OF_BOTTOL_COL_LIST=["Volume","VPOC","VPOC Vol","COT","OI","dOI"];
const style = {
    position: 'absolute',
    top: '5%',
    left: '10%',
    height: "90%",
    //   transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 0,
    overflow: 'scroll',
  };

const containsText = (text, searchText) =>{
  return text.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
}


 const ITEM_HEIGHT = 26;
 const ITEM_PADDING_TOP = 8;
 const MenuProps = {
   PaperProps: {
     style: {
       maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
       width: 80,
       autoFocus: false
     },
   },
 };

 const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
const OFSettings = (props) => {
  const from = "/login";
  const theme=useTheme();
  const [modalOpen, setModalOpen] = React.useState(props.open);
  const navigate = useNavigate(); 
  const [searchText, setSearchText] = React.useState("");
  const inputRef = React.useRef();
  const [options,setOptions]=useState();
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [selectedInstrument, setSelectedInstrument] = React.useState(userSettings.data.default_instrument_of?userSettings.data.default_instrument_of:userSettings.data.default_instrument);
  const [showGlobalVolumeProfile,setShowGlobalVolumeProfile ] =useState(userSettings.data.show_global_vp_of!=undefined?userSettings.data.show_global_vp_of:true);
  const [showAllVolumeProfile,setShowAllVolumeProfile ] =useState(userSettings.data.show_all_vp);
  const [showGlobalVolumeNumberProfile,setShowGlobalVolumeNumberProfile ] =useState(userSettings.data.show_global_vp_numbers_of!=undefined?userSettings.data.show_global_vp_numbers_of:false);
  const [showAllVolumeNumber,setShowAllVolumeNumber ] =useState(userSettings.data.show_profile_vp_numbers);
  const [showUiNpocKrl,setShowUiNpocKrl ] =useState(userSettings.data.show_ui_npoc_krl!=undefined?userSettings.data.show_ui_npoc_krl:true);
  const [showKrl,setShowKrl ] =useState(userSettings.data.show_krl!=undefined?userSettings.data.show_krl:false);
  const [showCustomKrl,setShowCustomKrl ] =useState(userSettings.data.custom_krl_display!=undefined?userSettings.data.custom_krl_display:false);
  const [customKrlMultipleAnchor,setCustomKrlMultipleAnchor ] =useState(userSettings.data.custom_krl_overlapping_anchoring!=undefined?userSettings.data.custom_krl_overlapping_anchoring:false);
  const [anchorVwap2sdDisplay,setAnchorVwap2sdDisplay ] =useState(userSettings.data.show_2sd_band_custom_anchored_krl!=undefined?userSettings.data.show_2sd_band_custom_anchored_krl:false);
  const [showOFNPOC,setShowOFNPOC ] =useState(userSettings.data.show_ui_npoc_krl_of!=undefined?userSettings.data.show_ui_npoc_krl_of:true);
  const [showOFMetadata,setShowOFMetadata ] =useState(userSettings.data.show_metadata_marker_of!=undefined?userSettings.data.show_metadata_marker_of:true);
  const [vpType,setVPType]=useState(userSettings.data.vp_type)
  const [vaType,setVAType]=useState(userSettings.data.va_type)
  const [maxCompositeDays,setMaxCompositeDays]=useState(userSettings.data.max_ndays_for_composite)
  const [defaultLineChartNDays,setDefaultLineChartNDays]=useState(userSettings.data.default_lc_ndays)
  const [autosaveInterval,setAutosaveInterval]=useState(userSettings.data.autosave_interval)
  const [numberFormatLocale,setNumberFormatLocale]=useState(userSettings.data.number_format_locale)
  const [dataTypeContracts,setDataTypeContracts]=useState(userSettings.data.data_in_contracts);
  const [ordeflowZoomType,setOrdeflowZoomType]=useState(userSettings.data.of_zoom_type?userSettings.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC)
  const [showLivePanel, setShowLivePanel]=useState(userSettings.data.show_live_panel_of!=undefined?userSettings.data.show_live_panel_of:true);
  const [showBottomeTable, setShowBottomTable]=useState(userSettings.data.show_bottom_table_of!=undefined?userSettings.data.show_bottom_table_of:true);
  const [selectedTpo, setSelectedTpo] = React.useState(3);
  const [ofBottomCol,setOfBottomCol]=useState(userSettings.data.of_bottom_col_hidden_list?userSettings.data.of_bottom_col_hidden_list:[]);
  const [selectedTimeFrame,setSelectedTimeFrame]=useState(userSettings.data.default_tf_of?userSettings.data.default_tf_of:DEFAULT_OF_TIME_FRAME)
 
  useEffect(()=>{
    // console.log("OFSETTINGS PAGE userSettings=",userSettings,userSettings.data.show_global_vp_of,showGlobalVolumeProfile)
    if(userSettings.data.tf_tpo_map_of==undefined){
        userSettings.data.tf_tpo_map_of={};
        // userSettings.data.tf_tpo_map_of.NIFTY={"1":2,"3":3,"5":4};
        // console.log("OFSETTINGS PAGE userSettings=",userSettings,userSettings.data.show_global_vp_of,showGlobalVolumeProfile)
    }
  },[])

  const autoFocus=()=>{
    const timeout = setTimeout(() => {
      if( inputRef.current)
      inputRef.current.focus();

    }, 100);

  }

  console.log(userSettings.data)

  const [uiDataState,setUIDataState]=useState({
    tpoGroupData:undefined,
    pprGroupData:undefined,
    instrumentData:undefined,
    tpoGroups:undefined,
    isTPOLoaded:false,
    tableData:undefined
  })
  const {tpoGroupData,instrumentData,isTPOLoaded,tpoGroups,tableData}=uiDataState;

  const [settingsState,setSettingsState]=useState({
    settingsData:userSettings,
    isUserSettingsLoaded:userSettings!=undefined && userSettings.data!=undefined,
    apiTableData:userSettings.data.page_wise_hidden_columns  
  })
  const {settingsData,isUserSettingsLoaded,apiTableData}=settingsState;
  const [currentSettings,setCurrentSettings]=useState()
  
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  
  const { open, msg, severity } = msgState;

  useEffect(() => {
    // document.title = "User Settings";
    console.log("MP settings props instrument and data=",props.instrument,props.selectedInstrumentData)
  }, []);
  
  const handleModalClose = () => {
    // setOpen(false);
    props.cancelHandler();
  };
  
  const [cancel,responseData, error, loaded, reset,executeAPI ] = useAxiosWithAuth();
  const [cancelSettings,responseDataSettings, errorSettings, loadedSettings, resetSettings,executeAPISettings ] = useAxiosWithAuth();
  const [cancelDefault,responseDataDefault, errorDefault, loadedDefault, resetDefault,executeAPIDefault ] = useAxiosWithAuth();

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(null);
  
  useEffect(() => {
    // Function to update parentWidth
    const updateParentWidth = () => {
      if (parentRef.current) {
        const width = parentRef.current.offsetWidth;
        console.log("parent width useeffect=",width)
        setParentWidth(width);
      }
    };

    // Initial update
    updateParentWidth();

    // Attach event listener for window resize
    window.addEventListener('resize', updateParentWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, [parentRef.current]);

  const LargeList = ({ data }) => {
    const height=window.innerHeight-120;
    const isLaptop=useMediaQuery('(min-width:1024px)')
    const is760=useMediaQuery('(min-width:640px)')
    let width=0
    if( isLaptop){
      width=(window.innerWidth*8/12)-20;
    }else if(is760){
      width=Math.max((window.innerWidth*10/12)-20,760)-40;
      // width=760-40;
    }else{
      width=760-40;
    }
   

    const rowRenderer = ({ index, key, style }) => (
      <div key={data[index].name} style={style}>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4}}>
      <Typography sx={{mt:0,mr:5,fontSize:14,width:"140px"}}>{data[index].name}</Typography>
     
     <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <Select
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].dailyTPO}
       MenuProps={MenuProps}
       sx={{height:"24px"}}
       onChange={(event)=>handleTPOChange(event,data[index],"daily")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </Select>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <Select
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].weeklyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"weekly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </Select>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <Select
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].monthlyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"monthly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </Select>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <Select
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].yearlyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"yearly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </Select>
   </FormControl>
   </Box>
      </div>
    );
  
    console.log("width height=",width,height)
    return (
      <List
      width={width}
      height={height}
      rowCount={data.length}
      rowHeight={40}
      rowRenderer={rowRenderer}
      // autoHeight
      overscanRowCount={10}
      containerProps={{
        overflow: 'visible',
      }}
    />

    );
  };

  
  

/**
 * API response handler  
 */  
   useEffect(() => {
    if(loadedDefault){
      if(responseDataDefault!=null){
          
          setSelectedInstrument(responseDataDefault.data.default_instrument_of?responseDataDefault.data.default_instrument_of:responseDataDefault.data.default_instrument);
          const tpoData=processTPOData(tpoGroups,responseDataDefault);
          const pprData=processPPRData(tpoGroups,responseDataDefault);
          console.log("OFSettings : user setting response=",responseDataDefault,tpoData,pprData,props.selectedInstrumentData)
          setUIDataState((prevState)=>({
            ...prevState,
            tpoGroupData:pprData    
          }));
        
          setSettingsState({settingsData:responseDataDefault,isUserSettingsLoaded:true,apiTableData:responseDataDefault.data.page_wise_hidden_columns});
      }
      else if(errorDefault!==null){
        setSettingsState((prevState)=>({
          ...prevState,
          isUserSettingsLoaded:true
        }))
        console.log("Error data=",errorDefault);
        setMsgState({open:true,msg:errorDefault?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(errorDefault?.response?.status === 401 || errorDefault?.response?.status === 403){
          console.log("status received =",errorDefault?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetDefault();
    }
  },[loadedDefault,responseDataDefault]);

  /**
 * API response handler  for user settings save
 */  
   useEffect(() => {
    if(loadedSettings){
    
      if(responseDataSettings!=null){
        setSettingsState((prevState)=>({
            ...prevState,
            settingsData:responseDataSettings
          }));
          setUserSettings(responseDataSettings);
          console.log("Settings : user setting response=",responseDataSettings)
          setMsgState({open:true,msg:responseDataSettings?.message ?? `${TEXT_MSGS.USER_SETTINGS_SAVED}`,severity:"success"});
          props.saveHandler(responseDataSettings);
       
      }
      else if(errorSettings!==null){
        console.log("Error data=",errorSettings);
        setMsgState({open:true,msg:errorSettings?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirect it to login page
        if(errorSettings?.response?.status === 401 || errorSettings?.response?.status === 403){
          console.log("status received =",errorSettings?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetSettings();
    }
  },[loadedSettings,responseDataSettings]);


    //execute the data fetch on the component mount
    useEffect(()=>{
      let data={
        "symbol": "NIFTY",
        "instruments": 1,
        // "tpoList": 1,
        // "snakechart_data": 1
        "user_settings_form" : 1
      }
      executeAPI(URL.MPCHART_UIDATA,"POST",data); 
    },[]);
  
    const processTPOData=(data,userSettings)=>{
      let processedData=[];
      for (const key in data) {
        // console.log(key, data[key])
        let temp=data[key];
        temp.key=key;
        //  temp.name=key;
        temp.name=key.replace(/_OPT/,' OPTIONS');
        // console.log("daily tpo in settings =",userSettings.data.tf_tpo_map[key].daily);
        if(key in userSettings.data.tf_tpo_map){
          temp.dailyTPO=userSettings.data.tf_tpo_map[key].daily
          temp.weeklyTPO=userSettings.data.tf_tpo_map[key].weekly
          temp.monthlyTPO=userSettings.data.tf_tpo_map[key].monthly
          temp.yearlyTPO=userSettings.data.tf_tpo_map[key].yearly
          processedData.push(temp);
        } else {
          temp.dailyTPO=data[key].default_tpo;
          temp.weeklyTPO=data[key].default_tpo*data[key].tf_factor.weekly;
          temp.monthlyTPO=data[key].default_tpo*data[key].tf_factor.monthly;
          temp.yearlyTPO=data[key].default_tpo*data[key].tf_factor.yearly;
          processedData.push(temp);
        }

      }
      return processedData;
    }

    const processPPRData=(data,userSettings)=>{
        let processedData=[];
        for (const key in data) {
          // console.log(key, data[key])
          let temp=data[key];
          temp.key=key;
          //  temp.name=key;
          temp.name=key.replace(/_OPT/,' OPTIONS');
        //   console.log("OFSETTINGS processPPRData",temp);
          // console.log("daily tpo in settings =",userSettings.data.tf_tpo_map[key].daily);
          if(key in userSettings.data.tf_tpo_map_of){
            temp.min1TPO=userSettings.data.tf_tpo_map_of[key].min1
            temp.min3TPO=userSettings.data.tf_tpo_map_of[key].min3
            temp.min5TPO=userSettings.data.tf_tpo_map_of[key].min5
            temp.min15TPO=userSettings.data.tf_tpo_map_of[key].min15
            temp.min30TPO=userSettings.data.tf_tpo_map_of[key].min30
            processedData.push(temp);
          } else {
            // console.log("OFSETTINGS temp else=",temp,data[key]);
            
            temp.min1TPO=data[key].of_default_tpo;
            temp.min3TPO=data[key].of_default_tpo;
            temp.min5TPO=data[key].of_default_tpo;
            temp.min15TPO=data[key].of_default_tpo;
            temp.min30TPO=data[key].of_default_tpo*(data[key].of_tf_factor && data[key].of_tf_factor["30"]?data[key].of_tf_factor["30"]:1);
            processedData.push(temp);
          }
  
        }
        return processedData;
      }

    /**
   * API response handler  
   */  
     useEffect(() => {
      if(loaded){
        if(responseData!=null){
            console.log("OFSettings OFCHart UI Data response=",responseData)
          const tpoData=processTPOData(responseData.tpo_groups,userSettings);
          const pprData=processPPRData(responseData.tpo_groups,userSettings);
          console.log("OFSettings 2 MPCHart UI Data response=",responseData,tpoData,pprData,props.selectedInstrumentData)
           setUIDataState({tpoGroupData:pprData,tpoGroups:responseData.tpo_groups,instrumentData:responseData.instruments, isTPOLoaded:true,tableData:responseData.user_settings_form.page_wise_columns});
           setOptions (responseData.instruments.filter(item=>item.of==true));
        }
        else if(error!==null){
          setUIDataState({tpoGroupData:undefined,tpoGroups:undefined,instrumentData:undefined,isTPOLoaded:true,tableData:undefined});
          console.log("Error data=",error);
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            navigate(from, { replace: true });
          }
      }
      reset();
      }
    },[loaded,responseData]);
    
    useEffect(()=>{
      console.log("searchText=", searchText );
      if(instrumentData!=undefined){
        if(searchText=="")
        setOptions(instrumentData)
        else{
          const temp=instrumentData.filter((option) => containsText(option, searchText))
          setOptions(temp);
        }
      }
    },[searchText])
  
    const handleInstrumentChange = (event) => {
      setSelectedInstrument(event.target.value);
    }  

    const handleTimeframeChange = (event) => {
      setSelectedTimeFrame(event.target.value);
    }  

    const handleVPChange = (event) => {
      setVPType(event.target.value);
    };

    const handleVAChange = (event) => {
      setVAType(event.target.value);
    };

    const handleLocaleChange = (event) => {
      setNumberFormatLocale(event.target.value);
    };

    const handleDataTypeChange = (event) => {
      console.log(event.target.value);
      setDataTypeContracts(event.target.value==="true");
    };

    const handleOFZoomChange = (event) => {
      setOrdeflowZoomType(event.target.value);
    };

    const handleTPOChange=(event,data,type)=>{
      let newData=JSON.parse(JSON.stringify(tpoGroupData));
      let index=newData.findIndex(item=>item.key==data.key)
      if(index==-1)
      return;

      if(type=="min1"){
        newData[index].min1TPO=event.target.value;
      }
      else if(type=="min3"){
        newData[index].min3TPO=event.target.value;
      }
      else if(type=="min5"){
        newData[index].min5TPO=event.target.value;
      }
      else if(type=="min15"){
        newData[index].min15TPO=event.target.value;
      }
      else if(type=="min30"){
        newData[index].min30TPO=event.target.value;
      }
      setUIDataState((prevState) => ({
        ...prevState,
       tpoGroupData:newData
      }));
      
    }

    const disableAutosave=()=>{
      setAutosaveInterval(0);
    }

    const saveSettings=()=>{
        // let newData=JSON.parse(JSON.stringify(userSettings));
        let newData={};
        newData.data={};
    
        newData.data.default_instrument_of=selectedInstrument
        newData.data.tf_tpo_map_of=userSettings.data.tf_tpo_map_of;
        if(newData.data.tf_tpo_map_of==undefined)
        newData.data.tf_tpo_map_of={};


      for(let i=0;i<tpoGroupData.length;i++){
        if(tpoGroupData[i].key in newData.data.tf_tpo_map_of){
          newData.data.tf_tpo_map_of[tpoGroupData[i].key].min1=tpoGroupData[i].min1TPO;
          newData.data.tf_tpo_map_of[tpoGroupData[i].key].min3=tpoGroupData[i].min3TPO;
          newData.data.tf_tpo_map_of[tpoGroupData[i].key].min5=tpoGroupData[i].min5TPO;
          newData.data.tf_tpo_map_of[tpoGroupData[i].key].min15=tpoGroupData[i].min15TPO;
          newData.data.tf_tpo_map_of[tpoGroupData[i].key].min30=tpoGroupData[i].min30TPO;
          
      
        } else {
          newData.data.tf_tpo_map_of[tpoGroupData[i].key] = {}
          newData.data.tf_tpo_map_of[tpoGroupData[i].key].min1=tpoGroupData[i].min1TPO;
          newData.data.tf_tpo_map_of[tpoGroupData[i].key].min3=tpoGroupData[i].min3TPO;
          newData.data.tf_tpo_map_of[tpoGroupData[i].key].min5=tpoGroupData[i].min5TPO;
          newData.data.tf_tpo_map_of[tpoGroupData[i].key].min15=tpoGroupData[i].min15TPO;
          newData.data.tf_tpo_map_of[tpoGroupData[i].key].min30=tpoGroupData[i].min30TPO;

         
        }
      }
   
        newData.data.show_global_vp_of=showGlobalVolumeProfile;
        newData.data.show_global_vp_numbers_of=showGlobalVolumeNumberProfile;
        newData.data.show_metadata_marker_of=showOFMetadata;
        newData.data.show_ui_npoc_krl_of=showOFNPOC;
        newData.data.of_zoom_type=ordeflowZoomType;


      newData.data.show_live_panel_of=showLivePanel;
      newData.data.show_bottom_table_of=showBottomeTable;

      newData.data.number_format_locale=numberFormatLocale;
      newData.data.data_in_contracts=dataTypeContracts;

      newData.data.of_bottom_col_hidden_list=ofBottomCol;
      newData.data.default_tf_of=selectedTimeFrame;


     
      
      setCurrentSettings(newData);
      console.log("Saved setting=",newData);

      let data={"data":newData};

      executeAPISettings(URL.UPDATE_USER_SETTING,"POST",newData.data);

      
     
    }
    useEffect(()=>{
      setSelectedInstrument(settingsData.data.default_instrument_of?settingsData.data.default_instrument_of:settingsData.data.default_instrument);
      setShowGlobalVolumeProfile(settingsData.data.show_global_vp_of!=undefined?settingsData.data.show_global_vp_of:true);
      setShowAllVolumeProfile(settingsData.data.show_all_vp);
      setShowGlobalVolumeNumberProfile(settingsData.data.show_global_vp_numbers_of!=undefined?settingsData.data.show_global_vp_numbers_of:false);
      setShowAllVolumeNumber(settingsData.data.show_profile_vp_numbers);
      setShowUiNpocKrl(settingsData.data.show_ui_npoc_krl);
      setShowKrl(settingsData.data.show_krl);
      setShowCustomKrl(settingsData.data.custom_krl_display);
      setCustomKrlMultipleAnchor(settingsData.data.custom_krl_overlapping_anchoring);
      setAnchorVwap2sdDisplay(settingsData.data.show_2sd_band_custom_anchored_krl);
      setShowOFMetadata(settingsData.data.show_metadata_marker_of)
      setShowOFNPOC(settingsData.data.show_ui_npoc_krl_of);
      setOrdeflowZoomType(settingsData.data.of_zoom_type?settingsData.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC);
      setVPType(settingsData.data.vp_type)
      setVAType(settingsData.data.va_type)
      setOptions (instrumentData);
      setMaxCompositeDays(settingsData.data.max_ndays_for_composite);
      setDefaultLineChartNDays(settingsData.data.default_lc_ndays);
      setAutosaveInterval(settingsData.data.autosave_interval);
      setNumberFormatLocale(settingsData.data.number_format_locale);
      setDataTypeContracts(settingsData.data.data_in_contracts);
      setShowLivePanel(settingsData.data.show_live_panel_of!=undefined?settingsData.data.show_live_panel_of:true);
      setShowBottomTable(settingsData.data.show_bottom_table_of!=undefined?settingsData.data.show_bottom_table_of:true);
      
    },[settingsData])

    const discardChanges=()=>{
      setUIDataState((prevState) => ({
        ...prevState,
       isTPOLoaded:false,
      }));
      setOptions (undefined);
      setSelectedInstrument(settingsData.data.default_instrument_of?settingsData.data.default_instrument_of:settingsData.data.default_instrument);
      setShowGlobalVolumeProfile(settingsData.data.show_global_vp_of!=undefined?settingsData.data.show_global_vp_of:true);
      setShowAllVolumeProfile(settingsData.data.show_all_vp);
      setShowGlobalVolumeNumberProfile(settingsData.data.show_global_vp_numbers_of!=undefined?settingsData.data.show_global_vp_numbers_of:false);
      setShowAllVolumeNumber(settingsData.data.show_profile_vp_numbers);
      setShowUiNpocKrl(settingsData.data.show_ui_npoc_krl);
      setShowKrl(settingsData.data.show_krl);
      setShowCustomKrl(settingsData.data.custom_krl_display);
      setCustomKrlMultipleAnchor(settingsData.data.custom_krl_overlapping_anchoring);
      setAnchorVwap2sdDisplay(settingsData.data.show_2sd_band_custom_anchored_krl);
      setShowOFMetadata(settingsData.data.show_metadata_marker_of)
      setShowOFNPOC(settingsData.data.show_ui_npoc_krl_of);
      setOrdeflowZoomType(settingsData.data.of_zoom_type?settingsData.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC);
      setVPType(settingsData.data.vp_type)
      setVAType(settingsData.data.va_type)
      setOptions (instrumentData);
      setMaxCompositeDays(settingsData.data.max_ndays_for_composite);
      setDefaultLineChartNDays(settingsData.data.default_lc_ndays);
      setAutosaveInterval(settingsData.data.autosave_interval);
      setNumberFormatLocale(settingsData.data.number_format_locale);
      setDataTypeContracts(settingsData.data.data_in_contracts);
      setShowLivePanel(settingsData.data.show_live_panel_of!=undefined?settingsData.data.show_live_panel_of:true);
      setShowBottomTable(settingsData.data.show_bottom_table_of!=undefined?settingsData.data.show_bottom_table_of:true);
      setOfBottomCol(settingsData.data.of_bottom_col_hidden_list?settingsData.data.of_bottom_col_hidden_list:[]);
      setSelectedTimeFrame(settingsData.data.default_tf_of?settingsData.data.default_tf_of:DEFAULT_OF_TIME_FRAME);

      setSettingsState((prevState)=>({
        ...prevState,
        apiTableData:settingsData.data.page_wise_hidden_columns  
      }))
     
      const tpoData=processTPOData(tpoGroups,settingsData);
      // console.log(tpoData)
      setUIDataState({tpoGroupData:tpoData,tpoGroups:tpoGroups,instrumentData:instrumentData, isTPOLoaded:true,tableData:tableData});
      
    }

    const resetToDefault=()=>{
      setSettingsState((prevState)=>({
        ...prevState,
        isUserSettingsLoaded:false
      }))
      // /api/user/settings?reset=true
      executeAPIDefault(`${URL.USER_SETTING}?reset=true`,"GET",{});

    }

/**
 * autoclosing of the snackbar msg bar 
 */ 
   const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

  const handleColumnChange=(event,col)=>{
    if(event.target.checked){
      console.log("col,type",col)
      let data=JSON.parse(JSON.stringify(ofBottomCol));
      data = data.filter(item => item !== col)
      console.log("unchecked=",data);
      setOfBottomCol(data);
      
    }else{
      let data=JSON.parse(JSON.stringify(ofBottomCol));
      data.push(col)
      console.log("unchecked=",data);
      setOfBottomCol(data);
     }
  }


 

  return (
    <Modal open={modalOpen} onClose={handleModalClose}>
    <>
    {isTPOLoaded && isUserSettingsLoaded && options!=undefined?
     <Box sx={style} >
         <CancelOutlinedIcon
                onClick={() => handleModalClose()}
                sx={{
                ml: 0, mt: 0,top: `calc(5% - 12px)`,left: `calc(90% - 13px)`,
                 height: 24, width: 26, cursor: 'pointer', position: 'fixed', zIndex: 9999
                }}
            />
    <Grid container spacing={2}>
    <Grid item mobile={0} tablet={1} laptop={2}>
     
    </Grid>
    <Grid item mobile={12} tablet={10} laptop={8} sx={{minWidth:"760px",ml:2,mr:2}}>
    <Box sx={{display:"flex",mr:1,mt:1}}>
      <Typography sx={{mt:2,fontSize:24,fontWeight:'bold'}}>Orderflow - Instrument Specific Settings</Typography>
      <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Settings specific to the selected instrument in Orderflow Chart</Typography>
              </React.Fragment>
            }
          >
          <InfoIcon sx={{ml:1,marginTop:"20px"}}></InfoIcon>
        </HtmlTooltip>
    </Box>
      {/* Groups TPO settings */}
      {/* <LargeList data={tpoGroupData} /> */}
      <div ref={parentRef} >
      <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,pb:2,pr:2,borderRadius:5,width:"100%"}}>
      <Typography sx={{mt:2,pt:2,pb:2,ml:3,fontSize:14,color:"#92a9a9",fontWeight:"bold"}}>Default PPR Size</Typography>
      <Box display="flex" justifyContent="flex-start" sx={{mb:2,ml:4}}>
      <Typography sx={{width:"140px",mr:5,fontSize:14,color:"#92a9a9"}}>Instrument Group</Typography>
      <Typography sx={{width:"80px",ml:2,fontSize:14,color:"#92a9a9"}}>1 Min</Typography>
      <Typography sx={{width:"80px",ml:2,fontSize:14,color:"#92a9a9"}}>3 Mins</Typography>
      <Typography sx={{width:"80px",ml:2,fontSize:14,color:"#92a9a9"}}>5 Mins</Typography>
      <Typography sx={{width:"80px",ml:2,fontSize:14,color:"#92a9a9"}}>15 Mins</Typography> 
      <Typography sx={{width:"80px",ml:2,fontSize:14,color:"#92a9a9"}}>30 Mins</Typography> 
     </Box>  
     {/* { parentRef.current? */}
     {/* <LargeList data={tpoGroupData} />   */}
     {/* :
     <></>
    }   */}
                   
      {tpoGroupData.filter(item=>item.key==props.selectedInstrumentData.tpo_group).map((item,index) => (
        <>
        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4}}>
         <Typography sx={{mt:0,mr:5,fontSize:14,width:"140px"}}>{item.name}</Typography>
        
        <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item.min1TPO}
          MenuProps={MenuProps}
          sx={{height:"24px"}}
          onChange={(event)=>handleTPOChange(event,item,"min1")}
        >
          {item.of_tpo_list.map((tpo,index) => (
          <MenuItem value={tpo}>{tpo}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item.min3TPO}
          sx={{height:"24px"}}
          MenuProps={MenuProps}
          onChange={(event)=>handleTPOChange(event,item,"min3")}
        >
          {item.of_tpo_list.map((tpo,index) => (
          <MenuItem value={tpo}>{tpo}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item.min5TPO}
          sx={{height:"24px"}}
          MenuProps={MenuProps}
          onChange={(event)=>handleTPOChange(event,item,"min5")}
        >
          {item.of_tpo_list.map((tpo,index) => (
          <MenuItem value={tpo}>{tpo}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item.min15TPO}
          sx={{height:"24px"}}
          MenuProps={MenuProps}
          onChange={(event)=>handleTPOChange(event,item,"min15")}
        >
          {item.of_tpo_list.map((tpo,index) => (
          <MenuItem value={tpo}>{tpo}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item.min30TPO}
          sx={{height:"24px"}}
          MenuProps={MenuProps}
          onChange={(event)=>handleTPOChange(event,item,"min30")}
        >
          {item.of_tpo_list.map((tpo,index) => (
          <MenuItem value={tpo}>{tpo}</MenuItem>
          ))}
        </Select>
      </FormControl>
      </Box>
      </>
      ))}
        
      </Box>
      </div>

    
     

    {/* Orderflow settings */}
    <Box sx={{display:"flex",mr:1,mt:2}}><Typography sx={{mt:2,fontSize:24,fontWeight:'bold'}}>Orderflow - Global Settings</Typography>
      <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Global settings applicable across Orderflow Charts for all instruments in general</Typography>
              </React.Fragment>
            }
          >
          <InfoIcon sx={{ml:1,marginTop:"20px"}}></InfoIcon>
        </HtmlTooltip>
      </Box>
    <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,pb:2,mb:5,pr:2,borderRadius:5,width:"100%"}}>
    <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"2px"}}>
    <FormControl variant="standard" size="small" sx={{ minWidth:"80%",ml:0,mt:1 }}>
      <Box display="flex" justifyContent="flex-start" sx={{mt:1,ml:0}}>
      <Box sx={{width:"272px",display:"flex",mr:1}}>
      <Typography sx={{marginTop:"4px",mr:5,fontSize:16,width:"150px"}}>Default Instrument</Typography>
      <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Default instrument when an orderflow chart is opened from menu.</Typography>
                  {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                  {"It's very engaging. Right?"} */}
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"5px"}}></InfoIcon>
          </HtmlTooltip>
          </Box>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedInstrument}
                        onChange={handleInstrumentChange}
                        onOpen={autoFocus}
                        label="Instrument"
                        sx={{minWidth: 230,border:"1px solid #fff",borderColor:"#fff",paddingLeft:"4px",paddingTop:"4px",marginTop:"2px"}}
                        MenuProps={MenuProps}
                        onClose={() => setSearchText("")}
                        // This prevents rendering empty string in Select's value
                        // if search text would exclude currently selected option.
                        // renderValue={() => selectedInstrument}
                        >
                        
                        <ListSubheader>
                            <TextField
                            size="small"
                            // Autofocus on textfield
                            autoFocus
                            // ref={focusUsernameInputField}
                            inputRef={inputRef}
                            variant='standard'
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                )
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                                }
                            }}
                            />
                        </ListSubheader>  
                        {options.map((item,index) => (
                          <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
                        ))}
                    </Select>
                    </Box>
                </FormControl>
          </Box>
          
      <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"2px"}}>
        <FormControl variant="standard" size="small" sx={{ minWidth:"80%",ml:0,mt:1 }}>
        <Box display="flex" justifyContent="flex-start" sx={{mt:0,ml:0}}>
        <Box sx={{width:"272px",display:"flex",mr:1}}>
        <Typography sx={{marginTop:"4px",mr:5,fontSize:16,width:"150px"}}>Default Time Frame</Typography>
        <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Default time frame when an orderflow chart is opened from menu.</Typography>
                
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"5px"}}></InfoIcon>
          </HtmlTooltip>
          </Box>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedTimeFrame}
                        onChange={handleTimeframeChange}
                        onOpen={autoFocus}
                        label="Time Frame"
                        sx={{minWidth: 230,border:"1px solid #fff",borderColor:"#fff",paddingLeft:"4px",paddingTop:"4px",marginTop:"2px"}}
                        MenuProps={MenuProps}
                        // onClose={() => setSearchText("")}
                        // This prevents rendering empty string in Select's value
                        // if search text would exclude currently selected option.
                        // renderValue={() => selectedInstrument}
                        >
                       
                        {OF_TIME_FRAME.map((item,index) => (
                          <MenuItem key={item.key} value={item.value}>{item.displayText}</MenuItem>
                        ))}
                    </Select>
                    </Box>
                </FormControl>
          </Box>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:1,fontSize:14}}>Navigation Behavior</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Orderflow Zoom/Navigation Behaviour</Typography>
                      <br/>
                      <li><b>Asymmetric (default)</b>: x-axis zooming and panning works independent of y-axis zooming and panning. For movement in y-axis, you need to keep your mouse pointer on y-axis and then attempt zooming or panning activity. Similarly for x-axis movement, keep the mouse pointer inside chart area and then attempt zooming or panning activity.</li>
                      <br/>
                      <li><b>Symmetric</b>: The zooming and panning works similar to Market Profile charts, where both x and y axis zoom toogether in sync and the chart moves in sync with mouse click and drag movement.</li>
                     
                    </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
        <FormControlLabel value={OF_ZOOM_TYPE.ASYMMETRIC} 
          control={<Radio checked={ordeflowZoomType==OF_ZOOM_TYPE.ASYMMETRIC} onChange={handleOFZoomChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Asymmetric Navigation</Typography>} />
          <FormControlLabel value={OF_ZOOM_TYPE.SYMMETRIC}
          control={<Radio checked={ordeflowZoomType==OF_ZOOM_TYPE.SYMMETRIC} onChange={handleOFZoomChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Symmetric Navigation</Typography>} />
        
      </Box>

    <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Show NPOC Lines</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Show/Hide NPOC (Unvisited VPOC) lines in Orderflow Charts. <br/>Enabled by default.</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
          <Switch checked={showOFNPOC} color="secondary"
            onChange={()=>setShowOFNPOC(!showOFNPOC)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Show Metadata Markers</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                     <Typography color="inherit">Show/Hide day level line markers (Day High, Day Low, IB High, IB Low, etc) in Orderflow Charts. <br/>Enabled by default.</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
          <Switch checked={showOFMetadata} color="secondary"
            onChange={()=>setShowOFMetadata(!showOFMetadata)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>
        
        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
            <Typography sx={{mr:1,mt:1,fontSize:14}}>Show Global Volume Profile</Typography>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Always show Global Volume Profile by Default</Typography>
                  {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                  {"It's very engaging. Right?"} */}
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
          </HtmlTooltip>
        </Box>
        
        <Switch checked={showGlobalVolumeProfile} color="secondary"
            onChange={()=>setShowGlobalVolumeProfile(!showGlobalVolumeProfile)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Show Global Profile Volume Numbers</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Show Volume Numbers for the Global Volume profile</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
        
          <Switch checked={showGlobalVolumeNumberProfile} color="secondary"
            onChange={()=>setShowGlobalVolumeNumberProfile(!showGlobalVolumeNumberProfile)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Show Live Panel</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Show Live Panel</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
        
          <Switch checked={showLivePanel} color="secondary"
            onChange={()=>setShowLivePanel(!showLivePanel)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>
        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Show Bottom Table</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Show Bottom Table</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
        
          <Switch checked={showBottomeTable} color="secondary"
            onChange={()=>setShowBottomTable(!showBottomeTable)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" flexDirection='column' sx={{mt:1,ml:4}}>
          <Typography sx={{marginTop:"4px",mr:1,fontSize:14,mb:1}}>Bottom Table Columns (Uncheck the columns you want to hide and then Save the settings)</Typography>
          {OF_BOTTOM_COL_LIST.map((col,index) => (
           <>
            <Box display="flex" justifyContent="flex-start" flexDirection='row' sx={{mt:0,ml:0}}>
              <Checkbox
                value={col}
                onChange={(event)=>handleColumnChange(event,col)}
                checked={!(ofBottomCol.includes(col.toString()))}
                iconStyle={{
                  fill: '#ffb400', //hex color values (yellow)
                }}
              />
              <Typography sx={{mr:1,marginTop:"10px"}}>{col}</Typography>
              </Box>
              </>
            
          ))}             
        </Box>

    </Box>
    
    <Box sx={{display:"flex",mr:1,mt:0}}><Typography sx={{mt:2,fontSize:24,fontWeight:'bold'}}>Global Settings</Typography>
      <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Global settings applicable across all charts</Typography>
                {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                {"It's very engaging. Right?"} */}
              </React.Fragment>
            }
          >
          <InfoIcon sx={{ml:1,marginTop:"20px"}}></InfoIcon>
        </HtmlTooltip>
      </Box>
    <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,pb:2,mb:6,pr:2,borderRadius:5,width:"100%"}}>
    <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"0px"}}>
        <Box sx={{width:"272px",display:"flex"}}>
        <Typography sx={{mr:1,mt:2,fontSize:14}}>Locale for Number Formatting</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Locale used for formatting large numbers across all pages</Typography>
                      Indian locale formats the numbers in Lakhs, Crores format. <br/>
                      US locale formats data in millions and billions
                    </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"16px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
         <FormControlLabel value="en-In" 
          control={<Radio checked={numberFormatLocale === 'en-In'} onChange={handleLocaleChange} size="small"  sx={{mt:1}}/>} 
          label={<Typography sx={{fontSize:14, mt:1}}>Indian Format</Typography>} />
          <FormControlLabel value="en-Us" 
          control={<Radio checked={numberFormatLocale === 'en-Us'} onChange={handleLocaleChange} size="small" sx={{mt:1}} />} 
          label={<Typography sx={{fontSize:14, mt:1}}>US Format</Typography>} />
        
      </Box>
      <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"2px"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:1,fontSize:14}}>OI and Vol Data</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">OI and Volume data across all pages should be displayed in #Contracts vs Total value?</Typography>
                    </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
        <FormControlLabel value="true" 
          control={<Radio checked={dataTypeContracts} onChange={handleDataTypeChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Contracts</Typography>} />
          <FormControlLabel value="false" 
          control={<Radio checked={!dataTypeContracts} onChange={handleDataTypeChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Total Value</Typography>} />
        
      </Box>
   
    </Box>
    
    </Grid>
    <Grid item mobile={0} tablet={1} laptop={2}>
     
    </Grid>
    
   
</Grid>
<SettingsFooterPopup saveSettings={saveSettings} discardChanges={discardChanges} resetDefault={resetToDefault}>

</SettingsFooterPopup>
</Box>    :
<>
<CircularProgress sx={{marginTop:"20%",marginLeft:"45%"}}/></>}
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
       </>
       </Modal>
  )
}

export default OFSettings
   