import * as React from "react";
import {useCallback,useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import { useTheme,Typography,Select,MenuItem,FormControl,InputLabel, Box ,FormControlLabel,Radio,RadioGroup,Snackbar,Alert} from "@mui/material";
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION_SHORT} from "../../../common/utility/constant";
import { CUSTOM_KRL_TYPES,STYLING_CATEGORY_LIST,CUSTOM_KRL_ANCHORED_MAX_DATE_DIFFERENCE,CUSTOM_KRL_TYPES_NON_VP } from "../../../common/utility/mp_chart_constants";
import { ChromePicker } from 'react-color';
import dayjs from 'dayjs';
import { DatePicker} from 'antd';
import Modal from '@mui/material/Modal';
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import '../styles/styles.scss';


const LinePreview = ({ lineType, lineWeight, color }) => {
  // console.log("preview type and color",lineType,color)
  const lineStyle = {
    width: '60px', // Set a fixed width for preview
    height: '1.5px', // Adjust the height according to your preference
    margin: '10px 0', // Adjust the margin according to your preference
    backgroundColor: color, // Color of the lines
  };

  const dashedLineStyle = {
    width:'60px',
    borderBottom: lineType=="style_dashed_line"?`1.5px dashed ${color}`:`3px dashed ${color}`,
  };

  const dottedLineStyle = {
    width:'60px',
    borderBottom: lineType=="style_dotted_line"?`1.5px dotted ${color}`:`3px dotted ${color}`,
  };

  return (
  
    <>
    
      {lineType === 'style_line' && <div style={lineStyle}></div>}
      {lineType === 'style_thick_line' && <div style={{ ...lineStyle, ...{ height: '3px' } }}></div>}
      {lineType === 'style_dotted_line' && <div style={{ ...dottedLineStyle }}></div>}
      {lineType === 'style_dotted_thick_line' && (
        <div style={{...dottedLineStyle, ...{ height: '3px' } }}></div>
      )}
      {lineType === 'style_dashed_line' && <div style={{ ...dashedLineStyle }}></div>}
      {lineType === 'style_dashed_thick_line' && (
        <div style={{ ...dashedLineStyle, ...{ height: '3px' } }}></div>
      )}
      </>
   
  );
};



const DateFormat = 'DD-MM-YYYY';
const style = {
    width: '360px', // Adjust the width as needed
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: "90%",

    maxHeight:"900px",
    transform: 'translate(-50%, -50%)',
    // backgroundColor: 'white',
    bgcolor: 'background.paper',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    overflowY:'auto'
  };

const formatDatesToISO=(dates)=> {
    const formattedDates = dates.map((dateString) => {
      const [day, month, year] = dateString.split('-');
      const dateObject = new Date(`${year}-${month}-${day}`);
      const formattedDate = dateObject.toISOString().split('T')[0];
      return formattedDate;
    });
  
    return formattedDates;
  }

  const isValidPositiveNumber=(value)=> {
    // Use parseFloat to attempt to convert the string to a number
    if(isNaN(value))
    return false;

    const numberValue = parseFloat(value);
  
    // Check if the conversion was successful and the number is positive
    return !isNaN(numberValue) && numberValue > 0;
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));


export default function SaveKRL(props) {
    const theme=useTheme();
  const [modalOpen, setModalOpen] = React.useState(props.open);
  const [title, setTitle] = React.useState(props.krlData?(props.krlData.category=="CUSTOM_KRL"&&props.krlData.description!=undefined?props.krlData.description.split("#T+")[0]:props.krlData.description):"");
  const [level, setLevel] = React.useState(props.krlData&&props.krlData.category==CUSTOM_KRL_TYPES[0].value?props.krlData.level.toString():"");
  const [krlCategoryList,setKrlCategoryList]=React.useState(props.selectedInstrumentData.vp?CUSTOM_KRL_TYPES:CUSTOM_KRL_TYPES_NON_VP);
  const [krlStylingCategoryList,setKrlStylingCategoryList]=React.useState(STYLING_CATEGORY_LIST);
  const [category, setCategory] = React.useState(CUSTOM_KRL_TYPES[0].value);
  const [stylingCategory, setStylingCategory] = React.useState(props.krlData?props.krlData.styling_category.split(".")[1]?props.krlData.styling_category.split(".")[1]:props.krlData.styling_category:STYLING_CATEGORY_LIST[0].value);
  const [stylingCategoryVWAP, setStylingCategoryVWAP] = React.useState(props.krlData?props.krlData.styling_category.split('.')[0]:STYLING_CATEGORY_LIST[0].value);
  const [color, setColor] = useState(props.krlData?props.krlData.marking_color.split(".")[1]?props.krlData.marking_color.split(".")[1]:props.krlData.marking_color:'#ffffff');
  const [colorVWAP, setColorVWAP] =useState(props.krlData?props.krlData.marking_color.split(".")[0]?props.krlData.marking_color.split(".")[0]:props.krlData.marking_color:'#ffffff');
  const [showPicker, setShowPicker] = useState(false);
  const [showPickerVWAP, setShowPickerVWAP] = useState(false);
  const [startDate,setStartDate]=useState(props.krlData?dayjs(props.krlData.start_date,"DD-MM-YYYY"): dayjs(props.profileData.dateList[props.profileData.dateList.length-1], 'DD-MM-YYYY'));
//   const initialDate = moment(dateString, 'DD-MM-YYYY').toDate();
  const [endDate,setEndDate]=useState(props.krlData && props.krlData.end_date?dayjs(props.krlData.end_date,"DD-MM-YYYY"):null);
  const tradeDates=formatDatesToISO(props.tradeDates);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLevelError,setIsLevelError]=useState(false);
  const [isTitleError,setIsTitleError]=useState(false);
  const [isDateError,setIsDateError]=useState(false);
  const [isAnchoredDateError,setIsAnchoredDateError]=useState(false);
//   const [anchorType, setAnchorType] = useState(props.krlData &&props.krlData.category!=CUSTOM_KRL_TYPES[0].value?props.krlData.category:null);
  const [anchorTypeVPOC, setAnchorTypeVPOC] = useState(props.krlData &&(props.krlData.category=="CUSTOM_ANCHORED_VPOC_KRL"||props.krlData.category=="CUSTOM_ANCHORED_VWAP_VPOC_KRL")?true:false);
  const [anchorTypeVWAP, setAnchorTypeVWAP] = useState(props.krlData &&(props.krlData.category=="CUSTOM_ANCHORED_VWAP_KRL"||props.krlData.category=="CUSTOM_ANCHORED_VWAP_VPOC_KRL"||props.krlData.category=="CUSTOM_ANCHORED_VWAP_TPOC_KRL")?true:false);
  const [anchorTypeVWAP2SD, setAnchorTypeVWAP2SD] = useState(anchorTypeVWAP && props.krlData && props.krlData.show_2sd);
  const [anchorTypeTPOC, setAnchorTypeTPOC] = useState(props.krlData &&(props.krlData.category=="CUSTOM_ANCHORED_TPOC_KRL"||props.krlData.category=="CUSTOM_ANCHORED_VWAP_TPOC_KRL")?true:false);
  
  const [isLoading,setIsLoading]=useState(false);// handle api calling state for save and edit
  const [cancel,responseData, error, loaded, reset,executeAPI]  = useAxiosWithAuth();
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });

  const { open, msg, severity } = msgState;

  const handleMessageClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

useEffect(()=>{
  const factor=2; //special tpo +- factor*tposize 

  // executeAPI(URL.DELETE_KRL,"POST",{"id":"6578c94797f75dac8a573c1c"});
  if(!props.krlData){
    let tempLevel=props.level;
    // console.log("Save KRL props",props.proUser,props.profileData,props.level,(props.profileData.ibLowTPO+factor*props.tpo), tempLevel, (props.profileData.ibLowTPO-factor*props.tpo))
    // console.log("Save KRL IBLOW=",props.profileData.ibLowTPO+factor*props.tpo,tempLevel, props.profileData.ibLowTPO+factor*props.tpo <=tempLevel, tempLevel>= props.profileData.ibLowTPO-factor*props.tpo)

  if(props.profileData.vpocTPO && props.profileData.vpocTPO+factor*props.tpo >= tempLevel && tempLevel >=props.profileData.vpocTPO-factor*props.tpo){
    tempLevel=props.profileData.vpoc;
    setCategory("CUSTOM_KRL_VPOC")
    // console.log("Save KRL inside vpoc")
  }else if(props.profileData.vwapTPO && props.profileData.vwapTPO+factor*props.tpo >=tempLevel && tempLevel >= props.profileData.vwapTPO-factor*props.tpo){
    tempLevel=props.profileData.vwap;
    setCategory("CUSTOM_KRL_VWAP")
    // console.log("Save KRL inside vwap")
  }
  else if(props.profileData.vpocTPOBased && props.profileData.vpocTPOBased+factor*props.tpo >=tempLevel && tempLevel>= props.profileData.vpocTPOBased-factor*props.tpo){
    tempLevel=props.profileData.tpoc;
    setCategory("CUSTOM_KRL_TPOC")
    // console.log("Save KRL inside Tpoc")
  }
  else if(props.profileData.high && props.profileData.high+factor*props.tpo >= tempLevel&& tempLevel >=props.profileData.high-factor*props.tpo){
    tempLevel=props.profileData.highVal;
    setCategory("CUSTOM_KRL_DH")
    // console.log("Save KRL inside high")
  }
  else if(props.profileData.low && props.profileData.low+factor*props.tpo >=tempLevel && tempLevel>= props.profileData.low-factor*props.tpo){
    tempLevel=props.profileData.lowVal;
    setCategory("CUSTOM_KRL_DL")
    // console.log("Save KRL inside low")
  }
  else if(props.profileData.ibHighTPO && props.profileData.ibHighTPO+factor*props.tpo >=tempLevel && tempLevel >= props.profileData.ibHighTPO-factor*props.tpo){
    tempLevel=props.profileData.ib_high;
    setCategory("CUSTOM_KRL_IBH")
    // console.log("Save KRL inside ibH")
  }
  else if(props.profileData.ibLowTPO && props.profileData.ibLowTPO+factor*props.tpo >=tempLevel && tempLevel>= props.profileData.ibLowTPO-factor*props.tpo){
    tempLevel=props.profileData.ib_low;
    setCategory("CUSTOM_KRL_IBL")
    // console.log("Save KRL inside ibL")
  }
  else if(props.profileData.vahTPO && props.profileData.vahTPO+factor*props.tpo >=tempLevel && tempLevel>= props.profileData.vahTPO-factor*props.tpo){
    tempLevel=props.profileData.vah;
    setCategory("CUSTOM_KRL_VAH")
    // console.log("Save KRL inside vah")
  }
  else if(props.profileData.valTPO && props.profileData.valTPO+factor*props.tpo >=tempLevel && tempLevel>= props.profileData.valTPO-factor*props.tpo){
    tempLevel=props.profileData.val;
    setCategory("CUSTOM_KRL_VAL")
    // console.log("Save KRL inside val")
  }
 
  setLevel(tempLevel);

}
},[])

 
  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  const handleColorChangeVWAP= (newColor) => {
    setColorVWAP(newColor.hex);
  };

  // const handleColorChangeComplete = () => {
  //   setShowPicker(false); // Close the color picker when color selection is complete
  // };
  const handlePickerClick = () => {
    setShowPickerVWAP(false);
    setShowPicker(!showPicker); // Toggle the color picker visibility
  };

  const handlePickerClickVWAP = () => {
    setShowPicker(false);
    setShowPickerVWAP(!showPickerVWAP); // Toggle the color picker visibility
  };


  const handleChange = (event) => {
    // setAnchorType(null);
    setCategory(event.target.value);
  };

  const handleStylingChange = (event) => {
    setStylingCategory(event.target.value);
  };

  const handleStylingChangeVWAP = (event) => {
    setStylingCategoryVWAP(event.target.value);
  };
  

  const handleClose = () => {
    // setOpen(false);
    props.cancelHandler();
  };

  const isValidKRL=()=>{
    setIsLevelError(false);
    setIsDateError(false);
    setIsAnchoredDateError(false);
    let isFormError=false;
    if(category==CUSTOM_KRL_TYPES[0].value && !anchorTypeVPOC && !anchorTypeVWAP){
        setLevel(level.toString());
        if(level.toString().trim()==""){
            isFormError=true;
            setIsLevelError(true);
        }else if(!isValidPositiveNumber(level.toString().trim())){
            console.log("Save KRL invalid level value",level)
            isFormError=true;
            setIsLevelError(true);
        }
        
    }
    if(title.trim()==""){
        isFormError=true;
        setIsTitleError(true);
    }
    if(endDate){
        console.log("Save KRL isValidKRL 1")
        if(startDate.isAfter(endDate)){
            console.log("Save KRL invalid end date")
            isFormError=true;
            setIsDateError(true);

        }else if(anchorTypeVPOC || anchorTypeVWAP || anchorTypeTPOC){
        const date1=dayjs(startDate).format(DateFormat);
        const date2=dayjs(endDate).format(DateFormat);
        const startDateIndex=props.tradeDates.findIndex(item=>item==date1)
        let endDateIndex=props.tradeDates.findIndex(item=>item==date2)
        if(endDateIndex < 0)
          endDateIndex = props.tradeDates.length+endDate.diff(dayjs(),'d');
        // console.log("Save KRL date index=",startDateIndex,endDateIndex,props.tradeDates,endDateIndex-startDateIndex,CUSTOM_KRL_ANCHORED_MAX_DATE_DIFFERENCE)
            if((endDateIndex-startDateIndex)>CUSTOM_KRL_ANCHORED_MAX_DATE_DIFFERENCE){
            // console.log("Save KRL date difference exceeded")
                isFormError=true;
                setIsAnchoredDateError(true);
                // setIsDateError(true);
            }
        }
        
    }

    return !isFormError;
  }

  //returns the price level based on category selection
  const getSpecialNodeLevel=()=>{
    console.log(props.profileData)
    if(category=="CUSTOM_KRL"){
        return parseFloat(level.toString().trim())
    }
    else if(category=="CUSTOM_KRL_VPOC"){
        return props.profileData.vpoc;
    }else if(category=="CUSTOM_KRL_VWAP"){
        return props.profileData.vwap  
    }else if(category=="CUSTOM_KRL_DH"){
      return props.profileData.highVal  
    }else if(category=="CUSTOM_KRL_DL"){
      return props.profileData.lowVal  
    }else if(category=="CUSTOM_KRL_IBH"){
      return props.profileData.ib_high  
    }else if(category=="CUSTOM_KRL_IBL"){
      return props.profileData.ib_low  
    }else if(category=="CUSTOM_KRL_VAH"){
      return props.profileData.vah  
    }else if(category=="CUSTOM_KRL_VAL"){
      return props.profileData.val  
    }else if(category=="CUSTOM_KRL_TPOC"){
      return props.profileData.tpoc  
    }else{
        return "NA"
    }

  }
  const getAnchorLevel=()=>{
    if(props.krlData)
        return props.krlData.level;
    else if(anchorTypeVPOC){
        return props.profileData.vpoc;
    }else if(anchorTypeVWAP){
        return props.profileData.vwap;
        
    }else if(anchorTypeTPOC){
      return props.profileData.tpoc  
    }
  }

  const getAnchoredCategory=()=>{
    if(anchorTypeVPOC && anchorTypeVWAP)
        return "CUSTOM_ANCHORED_VWAP_VPOC_KRL"
    else if(anchorTypeTPOC && anchorTypeVWAP)
        return "CUSTOM_ANCHORED_VWAP_TPOC_KRL"
    else if(anchorTypeVPOC)
        return "CUSTOM_ANCHORED_VPOC_KRL"
    else if(anchorTypeVWAP)
        return "CUSTOM_ANCHORED_VWAP_KRL"
    else if(anchorTypeTPOC)
        return "CUSTOM_ANCHORED_TPOC_KRL"
  }

  const getMarkingColor=()=>{
    if(anchorTypeVPOC && anchorTypeVWAP)
        return colorVWAP+"."+color
    else if(anchorTypeTPOC && anchorTypeVWAP)
        return colorVWAP+"."+color
    else if(anchorTypeVPOC)
        return color;
    else if(anchorTypeVWAP)
        return color;
    else if(anchorTypeTPOC)
        return color;
  }

  const getStylingCategory=()=>{
    if(anchorTypeVPOC && anchorTypeVWAP)
        return stylingCategoryVWAP+"."+stylingCategory
    else if(anchorTypeTPOC && anchorTypeVWAP)
        return stylingCategoryVWAP+"."+stylingCategory
    else if(anchorTypeVPOC)
        return stylingCategory;
    else if(anchorTypeVWAP)
        return stylingCategory;
     else if(anchorTypeTPOC)
        return stylingCategory;
  }

  const handleSave = () => {
    console.log("Save KRL date= ",anchorTypeTPOC, getMarkingColor(),colorVWAP,anchorTypeVWAP, level,color,stylingCategory, startDate.isAfter(endDate),props.instrument,title,category,stylingCategory,level,startDate,dayjs(startDate).format(DateFormat),endDate,dayjs(endDate).format(DateFormat));

    let isValid=isValidKRL();
    if(isValid){
        let data={
            "id":props.krlData?props.krlData.id:undefined,
            "bookmark_id":props.bookmarkData && props.bookmarkData.category!="AUTO_SAVED"?props.bookmarkData.id:undefined,
            "instrument":props.instrument,
            "level":anchorTypeVPOC||anchorTypeVWAP||anchorTypeTPOC?getAnchorLevel():getSpecialNodeLevel(),
            // "anchored":(category!=krlCategoryList[0].value && rememberMe?true:false),
            "description":title.trim(),
            "category":anchorTypeVPOC||anchorTypeVWAP||anchorTypeTPOC?getAnchoredCategory(): "CUSTOM_KRL",
            "show_2sd":anchorTypeVWAP&&anchorTypeVWAP2SD?true:false,
            "startDate": dayjs(startDate).format(DateFormat),
            "endDate": (endDate?dayjs(endDate).format(DateFormat):""),
            "marking_color":anchorTypeVPOC||anchorTypeVWAP||anchorTypeTPOC?getMarkingColor():color,
            "styling_category":anchorTypeVPOC||anchorTypeVWAP||anchorTypeTPOC?getStylingCategory():stylingCategory

        }
        console.log("Save KRL api data= ",data);
        setIsLoading(true);
        executeAPI(URL.SAVE_KRL,"POST",data);

    }
}

    //Response handler for Save/Edit Krl
   
     useEffect(() => {
        if(loaded){
          // console.log("More data loaded=",responseData);
          if(responseData!=null){
                setIsLoading(false);
                props.saveHandler();
                // setMsgState({open:true,msg:`${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          }
          else if(error!==null){
            setIsLoading(false);
           
            setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
            if(error?.response?.status === 401 || error?.response?.status === 403){
              console.log("status received =",error?.response?.status)
            //   navigate(from, { replace: true });
            }
          }
          reset();
        }
        },[loaded,responseData]);
  
   
  

 
  const handleLevelChange=(event)=>{
    setIsLevelError(false);
    setLevel(event.target.value)
  }
  const handleTitleChange=(event)=>{
    setIsTitleError(false);
    const textarea = event.target;
    const numberOfLines = textarea.value.split('\n').length;

    if (numberOfLines > 3) {
      textarea.value = textarea.value.split('\n').slice(0, 3).join('\n');
    }
    setTitle(textarea.value)
  }

  const getPopupContainer = (trigger) => trigger.parentNode;
  const getPopupContainerVWAP = (trigger) => trigger.parentNode;
  const onChange = (date1, dateString) => {
            
    setStartDate(date1)
    // setStartDate(dayjs(date1))
    let tempDate=dayjs(date1).format(DateFormat)
    // executeAPI(`${URL.FII_DATA}?dt=${tempDate}`,"GET",{}) 
   

  };
  const onChangeEnd = (date1, dateString) => {
    setIsDateError(false);
    setIsAnchoredDateError(false);
    console.log("Save KRL end date change=",date1)
    if (!date1) {
        // Set the state or value to null
        setEndDate(undefined);
      } else {
        // Handle the case when a valid date is selected
       
           
        setEndDate(date1)
    // setEndDate(dayjs(date1))
    let tempDate=dayjs(date1).format(DateFormat)
      }
    // executeAPI(`${URL.FII_DATA}?dt=${tempDate}`,"GET",{}) 
   

  };

const disabledDate=(current)=> {
    const formattedCurrentDate = current.format('YYYY-MM-DD');
    return !tradeDates.includes(formattedCurrentDate);
//  return (current && current >= dayjs().endOf('day'))||dayjs(current).day()==6 || dayjs(current).day()==0
}

const disabledEndDate=(current)=> {
 //disable future dates weekend
  if (current && current.isAfter(dayjs(), 'day') && (current.day() === 0 || current.day() === 6)) {
    return true;
  }

  // Disable past dates if not present in the trade dates list
  const formattedCurrent = current.format('YYYY-MM-DD');
  return current.isBefore(dayjs(), 'day') && !tradeDates.includes(formattedCurrent);

}

const handleColorPickerCancel = () => {
    setShowPicker(false);
  };

  const handleColorPickerDone = (color) => {
    setShowPicker(false);
  };
  const handleColorPickerDoneVWAP = (color) => {
    setShowPickerVWAP(false);
  };


const handleAnchorTypeChange=(event)=>{
    const newValue = event.target.value;

    
    // If the same radio button is clicked again, deselect it
    // setAnchorType(prevValue => (prevValue === newValue ? null : newValue));
}
const getDisableReason=(chk)=>{
  if(!props.proUser)
    return "Sorry, you need an Expert or higher subscription in order to create an Anchored KRL"
  else if(!props.selectedInstrumentData.akrl)
    return "Selected instrument doesn't support Anchored KRL."
  else if((props.krlData && chk != "show_2sd") || (props.krlData && chk=="show_2sd" && !(props.krlData.category=="CUSTOM_ANCHORED_VWAP_KRL"||props.krlData.category=="CUSTOM_ANCHORED_VWAP_VPOC_KRL"||props.krlData.category=="CUSTOM_ANCHORED_VWAP_TPOC_KRL")))
    return "Not allowed to modify krl type. You can delete and create a new krl instead."

  else return ""
}
  return (
    <Modal open={modalOpen} onClose={handleClose}>
     <>
      <Box sx={style} >
        <Box display={'flex'} justifyContent='space-between'flexDirection={'column'} sx={{height:"100%"}}>
        <Box sx={{mt:0,mb:1,display:'flex',justifyContent:"start",flexDirection:"column"}}>
        <Typography sx={{fontSize:24}}>{props.krlData?"Edit Custom KRL":"Create Custom KRL"}</Typography>
          <Box sx={{pt:2,display:'flex',justifyContent:"start",flexDirection:'row',alignItems:'center'}}>
             <FormControl required variant="outlined" size="medium" margin="dense" sx={{  minWidth: 120,mr:4 }}>
             <InputLabel id="demo-simple-select-required-label">Level</InputLabel>
             <Select
             SelectDisplayProps={{ style: { paddingTop: 11, paddingBottom: 11 } }}
             labelId="demo-simple-select-required-label"
             id="demo-simple-select-required"
             value={category}
            label="Level *"
            onChange={handleChange}
            size="small"
            
            >
            {krlCategoryList.map((pos,index) => (
            <MenuItem value={pos.value} key={pos.name} disabled={props.krlData ||anchorTypeVPOC||anchorTypeVWAP||anchorTypeTPOC}>{pos.name}</MenuItem> //disabled={index!=0 &&props.profileData==undefined}
            ))}
            </Select>
        
        </FormControl>
      
            {category==CUSTOM_KRL_TYPES[0].value?
             <FormControl size="small" sx={{ width:"120px",minWidth:'80px',margin:"dense"}}>
            <TextField
                
                required
                variant="outlined"
                value={level}
                disabled={anchorTypeVPOC || anchorTypeVWAP || anchorTypeTPOC}
                // height={20}
                sx={{minWidth:120}}
                onChange={(event) => handleLevelChange(event)}
                inputProps={{ maxLength: 15}}
                // inputProps={{maxLength: 15,sx:{height:20}}}
                // margin="dense"
                id="name"
                label="Level"
                size="small"
                error={isLevelError}
                // helperText={isLevelError ? 'Provide valid level value' : ' '}
               
                
            /> </FormControl>:
             <Typography sx={{mr:0}}>Level: {getSpecialNodeLevel()}</Typography>
             } 
             <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Either select a Custom Level, or pick from selected profile pre-defined values for KRL marking</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{width:20,height:20,mt:0,ml:1}}></InfoIcon>
            </HtmlTooltip>
            </Box>

            <Box display="flex" sx={{ mt:1,marginLeft:"-8px"  }} justifyContent="start"  flexDirection={'column'}>
       {props.selectedInstrumentData.vp?   
       <>
       <Typography sx={{fontSize:"12px", ml:1}}><i>Choose either VPOC or TPOC for anchoring..</i></Typography>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
      <Tooltip title={getDisableReason()} arrow>
      <span>
             <Checkbox 
                      checked={anchorTypeVPOC}
                      onChange={()=>{setAnchorTypeTPOC(false); setAnchorTypeVPOC(!anchorTypeVPOC)}}
                      disabled={!props.proUser || props.krlData || !props.selectedInstrumentData.akrl}
                      sx={{
                        color: theme.palette.secondaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.secondaryTheme.shade03,
                        },
                      }}/>
                      </span>
                      </Tooltip>
                      <Typography variant="linkSupportText" >
                        Anchored VPOC
                      </Typography> 
                      <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit">Draw an Anchored VPOC line starting from the selected start date</Typography>
            </React.Fragment>
          }
        >
        <InfoIcon sx={{marginTop:"0px",ml:2,width:20,height:20}}></InfoIcon>
      </HtmlTooltip>
      </Box></>:
      <></>
        }
      
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
      <Tooltip title={getDisableReason()} arrow>
      <span>
             <Checkbox 
                      checked={anchorTypeTPOC}
                      onChange={()=>{setAnchorTypeVPOC(false); setAnchorTypeTPOC(!anchorTypeTPOC)}}
                      disabled={!props.proUser || props.krlData || !props.selectedInstrumentData.akrl}
                      sx={{
                        color: theme.palette.secondaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.secondaryTheme.shade03,
                        },
                      }}/>
                      </span>
                      </Tooltip>
                      <Typography variant="linkSupportText" >
                        Anchored TPOC
                      </Typography> 
                      <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit">Draw an Anchored TPOC line starting from the selected start date</Typography>
            </React.Fragment>
          }
        >
        <InfoIcon sx={{marginTop:"0px",ml:2,width:20,height:20}}></InfoIcon>
      </HtmlTooltip>
      </Box>       

          <Box>
             <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Tooltip title={getDisableReason()} arrow>
              <span>
              <Checkbox 
                      checked={anchorTypeVWAP}
                      onChange={()=>setAnchorTypeVWAP(!anchorTypeVWAP)}
                      disabled={!props.proUser || props.krlData || !props.selectedInstrumentData.akrl}
                      sx={{
                        color: theme.palette.secondaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.secondaryTheme.shade03,
                        },
              }}/>
              </span>
            </Tooltip>
            <Typography variant="linkSupportText" >
              Anchored {props.selectedInstrumentData.vp?"VWAP":"TWAP"}
            </Typography> 
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Draw an Anchored VWAP (or TPO based VWAP for spot instruments) line starting from the selected start date</Typography>
                </React.Fragment>
              }
            ><InfoIcon sx={{marginTop:"0px",ml:2,width:20,height:20}}></InfoIcon>
            </HtmlTooltip>
            </Box>
            {props.selectedInstrumentData.vp?
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Tooltip title={getDisableReason('show_2sd')} arrow>
              <span>
              <Checkbox 
                      checked={anchorTypeVWAP2SD}
                      onChange={()=>setAnchorTypeVWAP2SD(!anchorTypeVWAP2SD)}
                      disabled={!props.proUser || (props.krlData&&!(props.krlData.category=="CUSTOM_ANCHORED_VWAP_KRL"||props.krlData.category=="CUSTOM_ANCHORED_VWAP_VPOC_KRL"||props.krlData.category=="CUSTOM_ANCHORED_VWAP_TPOC_KRL")) || !props.selectedInstrumentData.akrl}
                      sx={{
                        color: theme.palette.secondaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.secondaryTheme.shade03,
                        },
              }}/>
              </span>
            </Tooltip>
            <Typography variant="linkSupportText" >
              VWAP 2SD Bands
            </Typography> 
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Draw 2SD Bands around Anchored VWAP KRL line. Uses same color as Anchored VWAP for styling</Typography>
                </React.Fragment>
              }
            >
              <InfoIcon sx={{marginTop:"0px",ml:2,width:20,height:20}}></InfoIcon>
            </HtmlTooltip>
            </Box>
            :<></>
          }
          </Box>
          
                      
                      </Box>
       
       
                <FormControl size="small" sx={{ mt:2,width:"230px",minWidth:'80px'}}>
          <TextField
           multiline
           rows={3}
           rowsMax={3}
            required
            size="small"
            variant="outlined"
            value={title}
            sx={{mt:0,minWidth:300}}
            onChange={(event) => handleTitleChange(event)}
            inputProps={{ maxLength: 80 }}
            // margin="dense"
            id="name"
            label="Description"
            error={isTitleError}
            // helperText={title.trim() === "" ? 'Description is required' : ' '}
            // fullWidth
            
         /> 
         </FormControl>
            <Box sx={{mt:3,display:'flex',justifyContent:"start",flexDirection:'row'}}>
                <Typography sx={{marginTop:"4px",marginLeft:"4px"}}>Start Date:</Typography>
                <DatePicker
                 allowClear={false}
                getPopupContainer={getPopupContainer}
                onChange={onChange}
                value={startDate}
                format={DateFormat}
                disabledDate={disabledDate}
            
                    style={{border: "1px solid #415e81",background:"#022D5A",
                borderRadius: "4px",width:200,marginLeft:24,marginTop:"0px",width: '50%'}}
                
                />
                 <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Start Date for plotting the KRL</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{width:20,height:20,marginTop:"4px",ml:1}}></InfoIcon>
            </HtmlTooltip>
            </Box>
            <Box sx={{mt:3,display:'flex',justifyContent:"start",flexDirection:'row'}}>
                <Typography sx={{marginTop:"4px",marginLeft:"4px"}}>End Date:</Typography>
                <DatePicker
                //  allowClear={false}
                getPopupContainer={getPopupContainer}
                onChange={onChangeEnd}
                format={DateFormat}
                disabledDate={disabledEndDate}
                value={endDate}
                
            
                    style={{border: "1px solid #415e81",background:"#022D5A",
                borderRadius: "4px",width:200,marginLeft:30,marginTop:"0px",width: '50%'}}
                
                />
                <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">End Date till which KRL is plot. A blank value means the KRL is plot continuously till right most end.</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{width:20,height:20,marginTop:"4px",ml:1}}></InfoIcon>
            </HtmlTooltip>
            </Box>
            
            {isDateError?
            <Typography variant="errorText">End date should be after the start date</Typography>:
            <></>}

            {isAnchoredDateError?
            <Typography variant="errorText">Right now there is a limitation that the end date should not be more than {CUSTOM_KRL_ANCHORED_MAX_DATE_DIFFERENCE} days of start date, when anchoring.</Typography>:
            <></>}

            {anchorTypeVPOC && anchorTypeVWAP?
           <Typography sx={{mt:2,fontSize:14}}>Styling for VPOC:</Typography>:
            <></>
            }
            {anchorTypeTPOC && anchorTypeVWAP?
           <Typography sx={{mt:2,fontSize:14}}>Styling for TPOC:</Typography>:
            <></>
            }
            <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
            <FormControl  size="medium" sx={{  minWidth: 200,mr:4,mt:2 }}>
             <InputLabel id="demo-simple-select-required-label">Styling Type *</InputLabel>
             <Select
             labelId="demo-simple-select-required-label"
             id="demo-simple-select-required"
             value={stylingCategory}
            label="Styling Type *"
            onChange={handleStylingChange}
            size="small"
            >
            {krlStylingCategoryList.map((pos,index) => (
            <MenuItem value={pos.value} key={pos.name}>{pos.name}</MenuItem>
            ))}
            </Select>
            </FormControl>
            
            <LinePreview lineType={stylingCategory} lineWeight={1} color={color} />
            </Box>

            <Box sx={{mt:2,display:'flex',justifyContent:"start",flexDirection:'row'}}>
           
             <Typography sx={{mr:1,marginLeft:"4px",fontSize:14}}>Styling Color</Typography>
         <div
          style={{
            width: '20px',
            minWidth:"20px",
            height: '20px',
            // borderRadius: '50%',
            backgroundColor: color,
            cursor: 'pointer',
            marginLeft:"8px",
            marginTop:"2px",
            marginRight:"8px"
          }}
          onClick={handlePickerClick}
        />
         {showPicker &&!showPickerVWAP && (
            <Box display={'flex'} flexDirection='column'>
            <ChromePicker
                getPopupContainer={getPopupContainer}
                color={color}
                onChange={handleColorChange}
                
            />
                <div style={{ marginTop: '10px' }}>
                {/* <button onClick={handleColorPickerCancel}>Cancel</button> */}
                <button onClick={handleColorPickerDone} style={{ marginLeft: '0px' }}>
                Close
                </button>
            </div>
          </Box>
         )}

         </Box>

         {(anchorTypeVPOC||anchorTypeTPOC) && anchorTypeVWAP?
         <>
            <Typography sx={{mt:3,fontSize:14}}>Styling for VWAP:</Typography>
            <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
            <FormControl  size="medium" sx={{  minWidth: 200,mr:4,mt:2 }}>
             <InputLabel id="demo-simple-select-required-label">Styling Type *</InputLabel>
             <Select
             labelId="demo-simple-select-required-label"
             id="demo-simple-select-required"
             value={stylingCategoryVWAP}
            label="Styling Type *"
            onChange={handleStylingChangeVWAP}
            size="small"
            >
            {krlStylingCategoryList.map((pos,index) => (
            <MenuItem value={pos.value} key={pos.name}>{pos.name}</MenuItem>
            ))}
            </Select>
            </FormControl>
            <LinePreview lineType={stylingCategoryVWAP} lineWeight={1} color={colorVWAP} />
            </Box>

            <Box sx={{mt:2,display:'flex',justifyContent:"start",flexDirection:'row'}}>
           
             <Typography sx={{mr:1,marginLeft:"4px",fontSize:14}}>Styling Color</Typography>
         <div
          style={{
            width: '20px',
            minWidth:"20px",
            height: '20px',
            // borderRadius: '50%',
            backgroundColor: colorVWAP,
            cursor: 'pointer',
            marginLeft:"8px",
            marginTop:"2px",
            marginRight:"8px"
          }}
          onClick={handlePickerClickVWAP}
        />
         {showPickerVWAP && !showPicker && (
            <Box display={'flex'} flexDirection='column'>
            <ChromePicker
                getPopupContainer={getPopupContainerVWAP}
                color={colorVWAP}
                onChange={handleColorChangeVWAP}
                
            />
                <div style={{ marginTop: '10px' }}>
                {/* <button onClick={handleColorPickerCancel}>Cancel</button> */}
                <button onClick={handleColorPickerDoneVWAP} style={{ marginLeft: '0px' }}>
                Close
                </button>
            </div>
          </Box>
         )}

         </Box>
            </>
            :
            <></>
            }
          
        
         </Box>  
         <Box sx={{mt:0,mb:0,display:'flex',justifyContent:"end"}}>
            
                <Button variant="contained" disabled={isLoading} onClick={()=>handleClose()} sx={{mr:2,bgcolor:"#ffffff",color:"black"}}>
                Cancel
                </Button>
                <Button variant="contained" disabled={isLoading} onClick={()=>handleSave()} sx={{mr:2}}>
                Save
                </Button>
              </Box>
        
        </Box>
        </Box>
        
        
        <Snackbar onClose={handleMessageClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%"}}}>
            {msg}
          </Alert>  
       </Snackbar>
       
       </>
       
    </Modal>
   
  );
}
