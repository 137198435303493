import React, { useEffect, useState } from 'react';
import { Modal, Box,Divider, Typography, Button, TextField, IconButton,Grid,Switch,Radio,RadioGroup,FormControlLabel,Link,useTheme } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { BOTTOM_PANEL_HEIGHT_VAL, BOTTOM_PANEL_VIEWS, CANDLE_BOTTOM_VIEW_TYPE, INFO_PANEL_VIEWS, ROTATING_COT_VAL } from '../../../common/utility/mp_chart_constants';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION,TABLE_TYPES,OF_ZOOM_TYPE, OF_TIME_FRAME,OF_BOTTOM_COL_LIST, CANDLE_BOTTOM_COL, DEFAULT_CANDLE_TIME_FRAME, USER_ROLE_FIELDS, USER_RESOURCES_LIST } from '../../../common/utility/constant';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import useAuth from '../../../common/hooks/useAuth';
import { styled } from '@mui/material/styles';



const modalStyle = {
//   position: 'absolute',
//   top: '10%',
//   left: '10%',
// //   transform: 'translate(-50%, -50%)',
//   width: '60%',
//   bgcolor: 'background.paper',
//   borderRadius: '12px',
//   boxShadow: 24,
//   p: 4,
// position: 'absolute',
// // top: '5%',
// // left: '10%',
// // height: "90%",
// top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)', // Center the modal
//   width: 800, // Set the width of the modal
// //   transform: 'translate(-50%, -50%)',
// // width: "80%",
// bgcolor: 'background.paper',
// // border: '2px solid #000',
// boxShadow: 24,
// p: 0,
// overflow: 'scroll',
position: 'absolute',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)', // Center the modal
width: 800, // Set the width of the modal
maxHeight: '90vh', // Maximum height of the modal (90% of viewport height)
maxWidth: '90vw',
bgcolor: 'background.paper',
borderRadius: '12px',
boxShadow: 24,
p: 0, // Padding inside the modal
overflowY: 'auto', // Enable vertical scrolling
overflowX: 'auto',
};

const closeButtonStyle = {
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    color: 'white',
  };

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));
const CandleSettingsModal = (props) => {
    const { auth } = useAuth();
    const theme=useTheme();
    const [errorCot, setErrorCot] = useState(false);
    const [errorCot2, setErrorCot2] = useState(false);
    const isOFResourceAccess=(auth.resources.find(el=>el==USER_RESOURCES_LIST.OF_INDEX))?true:false;
    const isProUser=(auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))?true:false;
    const isCotAllowed=(isOFResourceAccess && isProUser );
    const [open, setOpen] = useState(props.panel1ModalVisible);
    const isPro2User=(auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2))?true:false;

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    props.onClose();
    setOpen(false)
};

const [data,setData]=useState({
    panel1_bottom_col:(props.data?.candle_map &&props.data.candle_map[props.key1]?.panel1_bottom_col)?props.data.candle_map[props.key1]?.panel1_bottom_col:(props.isOPT?CANDLE_BOTTOM_VIEW_TYPE.VOLUME: CANDLE_BOTTOM_VIEW_TYPE.VOLUME),
    panel1_view_type:(props.data?.candle_map &&props.data.candle_map[props.key1]?.panel1_view_type)?props.data.candle_map[props.key1].panel1_view_type:BOTTOM_PANEL_VIEWS.TOP_LAYER,
    panel1_height:(props.data?.candle_map&&props.data.candle_map[props.key1]?.panel1_height)?props.data.candle_map[props.key1].panel1_height:BOTTOM_PANEL_HEIGHT_VAL["CANDLE_CHART"],
    panel2_bottom_col:(props.selectedInstrumentData.of==false?props.straddleView: props.data?.candle_map &&props.data.candle_map[props.key1]?.panel2_bottom_col)?props.data.candle_map[props.key1]?.panel2_bottom_col:(props.isOPT?CANDLE_BOTTOM_VIEW_TYPE.DOI: CANDLE_BOTTOM_VIEW_TYPE.COT),
    panel2_view_type:(props.data?.candle_map &&props.data.candle_map[props.key1]?.panel2_view_type)?props.data.candle_map[props.key1].panel2_view_type:BOTTOM_PANEL_VIEWS.TOP_LAYER,
    panel2_height:(props.data?.candle_map&&props.data.candle_map[props.key1]?.panel2_height)?props.data.candle_map[props.key1].panel2_height:BOTTOM_PANEL_HEIGHT_VAL["CANDLE_CHART2"],
    info_panel_view:(props.data?.candle_map&&props.data.candle_map[props.key1]?.info_panel_view)?props.data.candle_map[props.key1].info_panel_view:INFO_PANEL_VIEWS.EXPANDED,
    rotatingCot:props.data.rotating_cot_candle?props.data.rotating_cot_candle:ROTATING_COT_VAL["default"],
    rotatingCot2:props.data.rotating_cot2_candle?props.data.rotating_cot2_candle:ROTATING_COT_VAL["default2"]
})

useEffect(()=>{
  console.log("CandleSettingsModal data 1 =",props.data,data.panel2_bottom_col,props.selectedInstrumentData.of,props.straddleView,props.open,props.data,data,props.data?.candle_map,props.key1,props.selectedInstrumentData)
  if(!props.selectedInstrumentData.of){
  setData((prevState) => ({
    ...prevState,
    panel2_bottom_col:props.straddleView
  }));
}
},[])
// useEffect(()=>{
//     console.log("CandleSettingsModal data 2 =",props.data,data.panel2_bottom_col,props.selectedInstrumentData.of,props.straddleView,props.open,props.data,data,props.data?.candle_map,props.key1,props.selectedInstrumentData)
    
//   },[props.data])

  useEffect(()=>{
    console.log("CandleSettingsModal =",props.open,props.data)
    setOpen(props.open);
  },[props.open])



  const handleBottomViewChangeNew= (event,data) => {
    if(event.target.value==CANDLE_BOTTOM_VIEW_TYPE.COT && isCotAllowed==false){
        setErrorCot(true);
        return;
      }
      setErrorCot(false);
      setData((prevState) => ({
        ...prevState,
        panel1_bottom_col:event.target.value
      }));
  }

  const handleBottomViewChangeNew2= (event,data) => {
    if(event.target.value==CANDLE_BOTTOM_VIEW_TYPE.COT && isCotAllowed==false){
        setErrorCot2(true);
        return;
      }
      setErrorCot2(false);
      setData((prevState) => ({
        ...prevState,
        panel2_bottom_col:event.target.value
      }));
  }

  const handleBottomPanelViewChange = (event,data) => {
    setData((prevState) => ({
        ...prevState,
        panel1_view_type:event.target.value
      }));
  }

  const handleBottomPanelViewChange2 = (event,data) => {
    setData((prevState) => ({
        ...prevState,
        panel2_view_type:event.target.value
      }));
  }

  const handleChange = (event,data) => {
    const newValue = event.target.value;
    if (newValue === '' || (newValue>=BOTTOM_PANEL_HEIGHT_VAL["min"]  && newValue <= BOTTOM_PANEL_HEIGHT_VAL["max"])) {
        setData((prevState) => ({
            ...prevState,
            panel1_height:parseInt(event.target.value)
          }));
    }
  }
  const handleChange2 = (event,data) => {
    const newValue = event.target.value;
    if (newValue === '' || (newValue>=BOTTOM_PANEL_HEIGHT_VAL["min"]  && newValue <= BOTTOM_PANEL_HEIGHT_VAL["max"])) {
        console.log("applysettings panel2 height=",event.target.value)
        setData((prevState) => ({
            ...prevState,
            panel2_height:parseInt(event.target.value)
          }));
    }
  }


  const handleInfoPanelViewChange = (event,data) => {
    setData((prevState) => ({
        ...prevState,
        info_panel_view:event.target.value
      }));
 }

 const handleChangeCot = (event) => {
    const newValue = event.target.value;
    if (newValue === '' || (newValue>=ROTATING_COT_VAL["min"]  && newValue <= ROTATING_COT_VAL["max"])) {
        setData((prevState) => ({
            ...prevState,
            rotatingCot:parseInt(event.target.value)
          }));
    }
  };

  const handleChangeCot2 = (event) => {
    const newValue = event.target.value;
    if (newValue === '' || (newValue>=ROTATING_COT_VAL["min"]  && newValue <= ROTATING_COT_VAL["max"])) {
        setData((prevState) => ({
            ...prevState,
            rotatingCot2:parseInt(event.target.value)
          }));
    }
  };


  const handleSave = () => {
    props.onSave(data);
    setOpen(false);
};
  return (
    <div>
   

      {/* Modal Component */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
        {/* <CancelOutlinedIcon
                onClick={() => handleClose()}
                sx={{
                ml: 0, mt: 0,top: `calc(5% - 12px)`,left: `calc(90% - 13px)`,
                 height: 24, width: 26, cursor: 'pointer', position: 'fixed', zIndex: 9999
                }}
            /> */}
             <IconButton sx={closeButtonStyle} onClick={handleClose}>
            <CancelOutlinedIcon />
          </IconButton>
          {/* Modal Header */}
          <Grid container spacing={2}>
    <Grid item mobile={0} tablet={1} laptop={2}>
     
    </Grid>
        

         
    <Grid item mobile={12} tablet={10} laptop={8} sx={{minWidth:"760px",ml:2,mr:2}}>
         
         
    <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,pb:2,mb:6,pr:2,borderRadius:5,width:"100%"}}>
      <Box display="flex" justifyContent="flex-start" flexDirection={"column"} sx={{mt:2,mb:0,ml:2,pr:2,width:"100%"}}>
      
        <>
        <Typography sx={{mr:1,mt:2,fontSize:18,fontWeight:700,ml:4}}>{props.key1}</Typography>
        {props.selectedInstrumentData.of?
        <Typography sx={{mr:1,mt:2,mb:1,fontSize:14,fontWeight:500,fontStyle:'italic',ml:6}}>Container specific temporary settings only.<br/>For changing and saving the default settings, use the Settings section in bottom left corner.</Typography>
        :
        <Typography sx={{mr:1,mt:2,mb:1,fontSize:14,fontWeight:500,fontStyle:'italic',ml:6}}>Container specific temporary settings only.</Typography>
        }
        <Box display="flex" justifyContent="flex-start" sx={{mb:0,ml:4,width:"100%"}}>
              <Box sx={{width:"272px",display:"flex"}}>
                  <Typography sx={{mr:1,mt:2,fontSize:14}}>Top Left Info Panel View</Typography>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">Info Panel Visibility (Top left panel)</Typography>
                        <li>Expanded: Default Info panel will be exapnded view.</li>
                        <li>Collapsed: Default Info panel will be collapsed view.</li>
                      </React.Fragment>
                    }
                  >
                  <InfoIcon sx={{marginTop:"14px"}}></InfoIcon>
                </HtmlTooltip>
              </Box>
      
            <RadioGroup
            row
            sx={{ml:1,mt:1}}
            size='small'
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
               <FormControlLabel value={INFO_PANEL_VIEWS.EXPANDED}
              control={<Radio checked={data.info_panel_view === INFO_PANEL_VIEWS.EXPANDED} onChange={(event)=>handleInfoPanelViewChange(event,data)} size="small" />} 
              label={<Typography sx={{fontSize:14}}>Expanded</Typography>} />
               <FormControlLabel value={INFO_PANEL_VIEWS.COLLAPSED}
              control={<Radio checked={data.info_panel_view === INFO_PANEL_VIEWS.COLLAPSED} onChange={(event)=>handleInfoPanelViewChange(event,data)}size="small" />} 
              label={<Typography sx={{fontSize:14}}>Collapsed</Typography>} />
             
          </RadioGroup>
        </Box>
        
        <Box display="flex" justifyContent="flex-start" sx={{mt:2,mb:0,ml:4,width:"100%"}}>
        {props.selectedInstrumentData.of?
        <Box sx={{width:"272px",display:"flex"}}>
            <Typography sx={{mr:1,mt:2,fontSize:14}}>Panel #2 Display</Typography>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Panel #2 Display (Upper one)</Typography>
                  What to show in the Upper bottom panel (Panel #2)?
                  {/* <li>None: No Volume profile shown by default.</li> */}
                  <li>Volume: Volume bars will be shown.</li>
                  <li>COT: COT bars will be shown.</li>
                  <li>DOI: OI change will be shown.</li>
                  
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"12px"}}></InfoIcon>
          </HtmlTooltip>
        </Box>:
        <Box sx={{width:"272px",display:"flex"}}>
        <Typography sx={{mr:1,mt:2,fontSize:14}}>Bottom Panel Display</Typography>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit">Bottom Panel Display</Typography>
              What to show in the bottom panel ?
              {/* <li>None: No Volume profile shown by default.</li> */}
              <li>Volume: Volume bars will be shown.</li>
              {/* <li>COT: COT bars will be shown.</li> */}
              <li>DOI: OI change will be shown.</li>
              
            </React.Fragment>
          }
        >
        <InfoIcon sx={{marginTop:"12px"}}></InfoIcon>
      </HtmlTooltip>
    </Box>
      }

      <RadioGroup
      row
      sx={{ml:1,mt:1}}
      size='small'
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
    >
      {/* <FormControlLabel value={VP_TYPE.NONE}
        control={<Radio checked={vpType === VP_TYPE.NONE} onChange={handleVPChange} size="small" />} 
        label={<Typography sx={{fontSize:14}}>None</Typography>} /> */}
      <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.VOLUME}
        control={<Radio checked={data.panel2_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.VOLUME} onChange={(event)=>handleBottomViewChangeNew2(event,data)} size="small" />} 
        label={<Typography sx={{fontSize:14}}>Volume</Typography>} />
        {props.selectedInstrumentData.of?
        <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.COT}
            control={<Radio checked={data.panel2_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.COT} onChange={(event)=>handleBottomViewChangeNew2(event,data)}  size="small" />} 
            label={<Typography sx={{fontSize:14}}>COT</Typography>} />
            :<></>
      }
        <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.DOI}
        control={<Radio checked={data.panel2_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.DOI}  onChange={(event)=>handleBottomViewChangeNew2(event,data)}  size="small" />} 
        label={<Typography sx={{fontSize:14}}>DOI</Typography>} />
      
    </RadioGroup>
  </Box>
 
  {errorCot2 && <Typography sx={{marginLeft:"300px",mt:-1}}variant='errorText'>Oops! You don't have orderflow access, needed for COT display. <Link href='/subscription'>Upgrade</Link> your plan now to access it.</Typography>}
  
 

  <Box display="flex" justifyContent="flex-start" sx={{mt:0,mb:0,ml:4,width:"100%"}}>
        <Box sx={{width:"272px",display:"flex"}}>
            <Typography sx={{mr:1,mt:1,fontSize:14}}>{props.selectedInstrumentData.of?"Panel #2 Visibilty":"Bottom Panel Visibility"}</Typography>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">{props.selectedInstrumentData.of?"Panel #2 Visibility (Upper one)":"Bottom Panel Visibility"}</Typography>
                 
                  {/* <li>None: No Volume profile shown by default.</li> */}
                  <li>Top Layer: Show bottom panel at top of main panel.</li>
                  <li>Same Container: Main panel overlap with bottom panel.</li>
                  <li>Hidden: Hide bottom panel.</li>
                 
                  
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
          </HtmlTooltip>
        </Box>

      <RadioGroup
      row
      sx={{ml:1}}
      size='small'
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
    >
         <FormControlLabel value={BOTTOM_PANEL_VIEWS.TOP_LAYER}
        control={<Radio checked={data.panel2_view_type === BOTTOM_PANEL_VIEWS.TOP_LAYER} onChange={(event)=>handleBottomPanelViewChange2(event,data)} size="small" />} 
        label={<Typography sx={{fontSize:14}}>Top Layer</Typography>} />
        <FormControlLabel value={BOTTOM_PANEL_VIEWS.SAME_CONTAINER}
        control={<Radio checked={data.panel2_view_type === BOTTOM_PANEL_VIEWS.SAME_CONTAINER} onChange={(event)=>handleBottomPanelViewChange2(event,data)} size="small" />} 
        label={<Typography sx={{fontSize:14}}>Same Container</Typography>} />
         <FormControlLabel value={BOTTOM_PANEL_VIEWS.HIDDEN}
        control={<Radio checked={data.panel2_view_type === BOTTOM_PANEL_VIEWS.HIDDEN} onChange={(event)=>handleBottomPanelViewChange2(event,data)} size="small" />} 
        label={<Typography sx={{fontSize:14}}>Hidden</Typography>} />
    </RadioGroup>
  </Box>
  <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
  <Box sx={{width:"272px",display:"flex"}}>
    <Typography sx={{mr:1,mt:1,fontSize:14}}>{props.selectedInstrumentData.of?"Panel #2 Height in % (Upper one)":"Bottom Panel Height in %"} </Typography>
    <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{props.selectedInstrumentData.of?"Panel #2 height wrt main panel height in percentage [5,60]":"Bottom Panel height wrt main panel height in percentage [5,60]"}</Typography>
                   </React.Fragment>
            }
          >
        <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
      </HtmlTooltip>
  </Box>
        <TextField
          size="small"
          label=""
          type="number"
          value={data.panel2_height}
          onChange={(event)=>handleChange2(event,data)}
          sx={{height:"25px",ml:1,padding:"1px"}}
          inputProps={{ min: BOTTOM_PANEL_HEIGHT_VAL["min"], max: BOTTOM_PANEL_HEIGHT_VAL["max"] }}
          // fullWidth
    />
  </Box>

  {isPro2User && props.selectedInstrumentData.of?
        <>
        <Box display="flex" justifyContent="flex-start" sx={{mb:2,ml:4,width:"100%"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:1,fontSize:14}}>Panel #2 Rolling COT</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Rolling Cot [3,50]</Typography>
                         </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
              <TextField
                size="small"
                label=""
                type="number"
                value={data.rotatingCot2}
                onChange={handleChangeCot2}
                sx={{height:"25px",ml:1,padding:"1px"}}
                inputProps={{ min: ROTATING_COT_VAL["min"], max: ROTATING_COT_VAL["max"] }}
                // fullWidth
          />
        </Box>
        </>

        
        :
        <></>
        }
{props.selectedInstrumentData.of?
<Divider sx={{mt:2}}/>:
<></>
}
{props.selectedInstrumentData.of? 
  <>
<Box display="flex" justifyContent="flex-start" sx={{mt:1,mb:0,ml:4,width:"100%"}}>
        
        <Box sx={{width:"272px",display:"flex"}}>
            <Typography sx={{mr:1,mt:2,fontSize:14}}>Panel #1 Display</Typography>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Panel #1 Display (Lower one)</Typography>
                  What to show in the lower panel (Panel #1)?
                  {/* <li>None: No Volume profile shown by default.</li> */}
                  <li>Volume: Volume bars will be shown.</li>
                  <li>COT: COT bars will be shown.</li>
                  <li>DOI: OI change will be shown.</li>
                  
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"12px"}}></InfoIcon>
          </HtmlTooltip>
        </Box>

      <RadioGroup
        row
        sx={{ml:1,mt:1}}
        size='small'
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        {/* <FormControlLabel value={VP_TYPE.NONE}
          control={<Radio checked={vpType === VP_TYPE.NONE} onChange={handleVPChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>None</Typography>} /> */}
        <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.VOLUME}
          control={<Radio checked={data.panel1_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.VOLUME} onChange={(event)=>handleBottomViewChangeNew(event,data)} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Volume</Typography>} />
      <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.COT}
          control={<Radio checked={data.panel1_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.COT} onChange={(event)=>handleBottomViewChangeNew(event,data)}  size="small" />} 
          label={<Typography sx={{fontSize:14}}>COT</Typography>} />
          <FormControlLabel value={CANDLE_BOTTOM_VIEW_TYPE.DOI}
          control={<Radio checked={data.panel1_bottom_col === CANDLE_BOTTOM_VIEW_TYPE.DOI}  onChange={(event)=>handleBottomViewChangeNew(event,data)}  size="small" />} 
          label={<Typography sx={{fontSize:14}}>DOI</Typography>} />
        
      </RadioGroup>
    </Box>
  
    {errorCot && <Typography sx={{marginLeft:"300px",mt:-1}}variant='errorText'>Oops! You don't have orderflow access, needed for COT display. <Link href='/subscription'>Upgrade</Link> your plan now to access it.</Typography>}
    
 
    <Box display="flex" justifyContent="flex-start" sx={{mt:0,mb:0,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Panel #1 Visibilty</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Panel #1 Visibility (Lower one)</Typography>
                  
                    {/* <li>None: No Volume profile shown by default.</li> */}
                    <li>Top Layer: Show bottom panel at top of main panel.</li>
                    <li>Same Container: Main panel overlap with bottom panel.</li>
                    <li>Hidden: Hide bottom panel.</li>
                  
                    
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>

        <RadioGroup
        row
        sx={{ml:1}}
        size='small'
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
          <FormControlLabel value={BOTTOM_PANEL_VIEWS.TOP_LAYER}
          control={<Radio checked={data.panel1_view_type === BOTTOM_PANEL_VIEWS.TOP_LAYER} onChange={(event)=>handleBottomPanelViewChange(event,data)} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Top Layer</Typography>} />
          <FormControlLabel value={BOTTOM_PANEL_VIEWS.SAME_CONTAINER}
          control={<Radio checked={data.panel1_view_type === BOTTOM_PANEL_VIEWS.SAME_CONTAINER} onChange={(event)=>handleBottomPanelViewChange(event,data)} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Same Container</Typography>} />
          <FormControlLabel value={BOTTOM_PANEL_VIEWS.HIDDEN}
          control={<Radio checked={data.panel1_view_type === BOTTOM_PANEL_VIEWS.HIDDEN} onChange={(event)=>handleBottomPanelViewChange(event,data)} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Hidden</Typography>} />
      </RadioGroup>
    </Box>
    <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
    <Box sx={{width:"272px",display:"flex"}}>
      <Typography sx={{mr:1,mt:1,fontSize:14}}>Panel #1 Height in % (Lower one)</Typography>
      <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Panel #1 height wrt main panel height in percentage [5,60]</Typography>
                    </React.Fragment>
              }
            >
          <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
        </HtmlTooltip>
    </Box>
          <TextField
            size="small"
            label=""
            type="number"
            value={data.panel1_height}
            onChange={(event)=>handleChange(event,data)}
            sx={{height:"25px",ml:1,padding:"1px"}}
            inputProps={{ min: BOTTOM_PANEL_HEIGHT_VAL["min"], max: BOTTOM_PANEL_HEIGHT_VAL["max"] }}
            // fullWidth
      />
    </Box>
    {isPro2User?
    <>
    <Box display="flex" justifyContent="flex-start" sx={{mb:2,ml:4,width:"100%"}}>
    <Box sx={{width:"272px",display:"flex"}}>
      <Typography sx={{mr:1,mt:1,fontSize:14}}>Panel #1 Rolling COT</Typography>
      <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Rolling Cot [3,50]</Typography>
                    </React.Fragment>
              }
            >
          <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
        </HtmlTooltip>
    </Box>
          <TextField
            size="small"
            label=""
            type="number"
            value={data.rotatingCot}
            onChange={handleChangeCot}
            sx={{height:"25px",ml:1,padding:"1px"}}
            inputProps={{ min: ROTATING_COT_VAL["min"], max: ROTATING_COT_VAL["max"] }}
            // fullWidth
      />
    </Box>
    </>
    :
    <></>
    }
    </>:
    <></>
    }
      
        </>
        
          
        </Box>
      </Box> 

          {/* <Box mt={4} display="flex" justifyContent="space-between"> */}
          <Box sx={{mt:4,mb:0,display:'flex',justifyContent:"end"}}>
            <Button variant="contained" onClick={()=>handleClose()} sx={{mr:2,bgcolor:"#ffffff",color:"black"}}>
                Cancel
                </Button>
                <Button variant="contained"  onClick={()=>handleSave()} sx={{mr:2}}>
                Apply
                </Button>
          </Box>
          </Grid>
          <Grid item mobile={0} tablet={1} laptop={2}>
     
    </Grid>
    
   
</Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default CandleSettingsModal;
