import React,{useEffect} from 'react'
import {Link, Grid } from "@mui/material"

const HelpPage = () => {

    React.useEffect(() => {
        document.title = "Help";
      }, []);

    return (
        <Grid component="main" container display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{height: `calc(100vh - 64px)` }}>
            
            <h1>Hello!</h1>
            <p>For any assistance please write an email to <Link href="mailto:charts@vtrender.com"> charts@vtrender.com</Link></p>
            {/* <div className="flexGrow">
                <Link href="/" variant="link">Go Back to Homepage</Link>
            </div> */}
        </Grid>
    )
}

export default HelpPage
