/**
 * @param {*} theme 
 * @returns the overriden components and variant to be used throughout the app
 */

import { maxHeight } from "@mui/system"

export default function componentStyleOverrides(theme) {
    return {
        MuiToolbar: {
            variants: [
              {
                props: { variant: 'small' },
                style: {
                  backgroundColor:"#0000ff",
                  width:"100vw",
                  height:"32px",
                  position:"fixed"
                  
                },
              }]},

              
            MuiTextField:{
            styleOverrides: {
            root:{
                
                input: {
                    '&:-webkit-autofill': {
                      '-webkit-box-shadow': `0 0 0 100px ${theme.palette.primaryTheme.shade01} inset`,
                      '-webkit-text-fill-color': '#fff',
                      'border-redius':"0px!important"
                    }
                  }
            }
            
            }
            
        },
        MuiOutlinedInput:{
            styleOverrides: {
                input: {
                    '&:focused': {
                        border: `2px solid ${theme.palette.borderColor.main}`,
                        borderColor:theme.palette.buttonTextColor.outlined,
                     },
                    '&:-webkit-autofill': {
                      '-webkit-box-shadow': `0 0 0 100px ${theme.palette.primaryTheme.shade01} inset`,
                      '-webkit-text-fill-color': '#fff',
                      'border-redius':"0px!important"
                    }
                  },
            root:{
                input: {
                    '&:focused': {
                        border: `2px solid ${theme.palette.borderColor.main}`,
                        borderColor:theme.palette.buttonTextColor.outlined,
                     },
                    '&:-webkit-autofill': {
                      '-webkit-box-shadow': `0 0 0 100px ${theme.palette.primaryTheme.shade01} inset`,
                      '-webkit-text-fill-color': '#fff',
                      'border-redius':"0px!important"
                    }
                  }
            }
            
            }
            
        },
        MuiInput:{
          styleOverrides: {
            root:{
              "&:before": {
              borderBottom:"0px!important"
              },
              "&:after": {
                borderBottom:"0px!important"
                }
            }
          }
        },
        MuiInputBase:{
            styleOverrides: {
                input:

                {
                    borderRadius:"0px!important",
                    '&:focused': {
                        border: `2px solid ${theme.palette.borderColor.main}`,
                        borderColor:theme.palette.buttonTextColor.outlined,
                     },
                    '&:-webkit-autofill': {
                      '-webkit-box-shadow': `0 0 0 100px ${theme.palette.primaryTheme.shade01} inset`,
                      '-webkit-text-fill-color': '#fff',
                      'border-redius':"0px!important"
                    }
                  },
            root:{
                input: {
                    borderRadius:"0px",
                    '&:foucsed': {
                        border: `2px solid ${theme.palette.borderColor.main}`,
                        borderColor:theme.palette.buttonTextColor.outlined,
                     },
                    '&:-webkit-autofill': {
                      '-webkit-box-shadow': `0 0 0 100px ${theme.palette.primaryTheme.shade01} inset`,
                      '-webkit-text-fill-color': '#fff',
                      'border-redius':"0px!important"
                    }
                  }
            }
            
            }
            
        },
        
        MuiButtonBase: {
            defaultProps: {
                //disableRipple: true, // No more ripple,                  
            }
        },

        MuiButton: {
            
            styleOverrides: {
                root: ({ownerState})=>({
                    
                    //common properties for MUI Buttom
                    fontWeight: 500,
                    fontSize:"16px",
                    borderRadius: '8px',
                    lineHeight:"21px",
                    textTransform:"none",

                    //properties based on MUI variant
                    ...(ownerState.variant === 'outlined' &&
                     {
                    color: theme.palette.buttonTextColor.outlined,
                    borderColor:theme.palette.buttonTextColor.outlined,
                    border: `2px solid ${theme.palette.borderColor.main}`,
                    '&:hover': {
                        border: `2px solid ${theme.palette.borderColor.main}`,
                        borderColor:theme.palette.buttonTextColor.outlined,
                     },
                    }), 
                    ...(ownerState.variant === 'contained' &&
                    {
                     backgroundColor: theme.palette.secondaryTheme.shade01,
                     color: theme.palette.buttonTextColor.main,
                     '&:hover': {
                        backgroundColor: theme.palette.secondaryTheme.shade03,
                     },
                   }),
                   ...(ownerState.variant === 'containedWhite' &&
                   {
                    backgroundColor: theme.palette.primaryTheme.shade10,
                    color: theme.palette.buttonTextColor.main,
                    '&:hover': {
                       backgroundColor: theme.palette.secondaryTheme.shade05,
                    },
                  }),    
                    
                }),
                
           
            },
                 //create custom variant for MUI buttons
                 variants: [
                  {
                    props: { variant: 'dashed' },
                    style: {
                      textTransform: 'none',
                      color:"#00ff00",
                      border: `2px dashed #0000ff`,
                    },
                  }]
        },
       
        MuiLink: {
          styleOverrides: {
            
          root: {
            color:theme.palette.linkColor.main,
            textDecorationColor:theme.palette.linkColor.main,
          }
        },

           //create custom variant for MUI buttons
           variants: [
            {
              props: { variant: 'secondaryLink' },
              style: {
                color:theme.palette.linkColor.secondary,
                textDecorationColor:theme.palette.linkColor.secondary,
                textDecoration:'none'
              },
            }]
        
        },
        
        MuiListItemButton: {
          styleOverrides: {
          root: {
             fontSize:"14px",
              maxHeight:"40px",
              minHeight:"40px",
              "&:hover": {
                backgroundColor: theme.palette.secondaryTheme.shade03,
              },
              '&.Mui-selected': {
                backgroundColor: theme.palette.secondaryTheme.shade01,
                "&:hover": {
                  backgroundColor: theme.palette.secondaryTheme.shade01,
                }
              }
         },
        },
        },
       
       
        MuiList: {
          styleOverrides: {
          root: {
              fontSize:"14px",
              // padding:"0px"
          },
        },
        },

        MuiSelect: {
          styleOverrides: {
          root: {
            fontSize:"12px",
            "&:before": {
              borderBottom:'0px'          
              },
            "&:after": {
              borderBottom:'0px'
            },
          }
        }
        },
        
      
        MuiMenuItem: {
          styleOverrides: {
          
          root: {
              fontSize:"12px",
              maxHeight:"36px",
              minHeight:"36px",
          }
        }
        },
        
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: '0px'
                }
            }
        },
        };
}
