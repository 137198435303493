import { useEffect,useState } from 'react'
import { URL } from '../../common/api/urls';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import {Snackbar, Box,Select,Typography,Container,CircularProgress,Alert} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import OptionsFooter from './components/options_footer';
import dayjs from 'dayjs';
import { DatePicker} from 'antd';
import { TEXT_MSGS ,SNACKBAR_AUTO_HIDE_DURATION_SHORT} from '../../common/utility/constant';
import {styled, useTheme} from '@mui/material/styles';
import { WATERMARK_CONFIG } from '../../common/utility/constant';
import { ToolbarFixed } from '../../layouts/component.js';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS } from '../../common/utility/constant';

const DateFormat = 'DD-MM-YYYY';
const FooterToolbar=styled(ToolbarFixed)(
    ({theme})=> `
    bottom:0px;
    justify-content:center;
    text-align:center;
    `
  )
export default function FII_DATA() {
    const navigate = useNavigate(); 
    const theme=useTheme();
    const from = "/login";
    const { auth } = useAuth();
    const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
    const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
    const [fiiState,setFiiState]=useState({
        data:undefined,
        isLoading:true,
        date:undefined
    });
    const{data,isLoading,date}=fiiState


    const [msgState, setMsgState] = useState({
        open: false,
        msg:"" ,
        severity:"info"
    });
      
    const { open, msg, severity } = msgState;
     /**
        * autoclosing of the snackbar msg bar 
    */ 
     const handleClose = (event, reason) => {
        setMsgState({...msgState,open:false});
    };
    
    useEffect(() => {
      document.title = "FII Data";
      var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
      document.head.appendChild(link);
    }, []);
    
    useEffect(()=>{
        // let tempDate;
        // if(dayjs().day()==6)
        //     tempDate=dayjs().add(-1)
        // else if(dayjs().day()==0)
        //     tempDate=dayjs().add(-2)
        // else
        //     tempDate=dayjs()    
         
        // setFiiState((prevState)=>({
        //     ...prevState,
        //     date:tempDate

        // }));    

        executeAPI(`${URL.FII_DATA}?dt=`,"GET",{}) 
    },[])

    useEffect(() => {
        if(loaded){
          console.log("loadedsave position target=====>",responseData);
          if(responseData!=null){
               console.log("position saved succesfully")
               let htmlData={__html: responseData.fii_data_str};
            //    let htmlData={__html: 'First &middot; Second'};
               setFiiState((prevState)=>({
                  ...prevState,
                  data:htmlData,
                  isLoading:false  
               }))
           
                      
          }
          else if(error!==null){
           
    
            console.log("Error data=",error);
    
              setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
              //if unauthorized then redirec it to login page
              if(error?.response?.status === 401 || error?.response?.status === 403){
                console.log("status received =",error?.response?.status)
                navigate(from, { replace: true });
              }
          }
          reset();
        }
        },[loaded,responseData]);
    
        const onChange = (date1, dateString) => {
            
            setFiiState((prevState)=>({
              ...prevState,
              date:dayjs(date1)
            }))
            let tempDate=dayjs(date1).format(DateFormat)
            executeAPI(`${URL.FII_DATA}?dt=${tempDate}`,"GET",{}) 
        
          };
        
        const disabledDate=(current)=> {
         return (current && current >= dayjs().endOf('day'))||dayjs(current).day()==6 || dayjs(current).day()==0
        }


    return(
        <>
        {!isLoading?
        <Box >
            {!isBasicUser?
            <DatePicker onChange={onChange} 
            allowClear={false}
            disabledDate={disabledDate}
            value={date}
            format={DateFormat}
            style={{border: "1px solid #415e81",background:"#022D5A",
            borderRadius: "4px",width:200,marginLeft:20,marginTop:"24px"}}
            /> 
            :<></>} 
            {!isBasicUser? <Box style={{marginLeft:20,marginTop:24}}>Selected Date: {dayjs(date).format(DateFormat)}</Box>:<></>} 
            <div style={{margin:20,marginTop:40}}dangerouslySetInnerHTML={data} />
        </Box>:
        <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
        </div>
        }  
        
        <FooterToolbar sx={{borderTop: `1px solid ${theme.palette.primaryTheme.shade03}` }} >
        <Container >
        <Typography  variant="copyRight" align="center" >
          {WATERMARK_CONFIG.TABLE_TEXT}
          </Typography>
        </Container>
      </FooterToolbar>
    <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{marginTop:"48px", width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>  
    </> 
      )
}