import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link,TextField } from '@mui/material';
import {TEXT_MSGS,PREMIUM_UPGRADE_MSGS_LIST} from '../../../common/utility/constant';
import { useNavigate } from "react-router-dom";
import WarnIcon from '@mui/icons-material/Warning';
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { useEffect,useState } from 'react';
import { URL } from '../../../common/api/urls';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RupeeIcon from '@mui/icons-material/CurrencyRupee';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
//   width: 500,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

export default function OrderDetailPopup(props) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false)
    props.onCancel();
}
const [data,setData]=useState(props.data);
const [code, setCode] = useState(undefined);
const [codeApplied,setCodeApplied]=useState(false);
const [couponDesc,setCouponDesc]=useState(false);
const [orderDetail,setOrderDetail]=useState(false);
const [isCouponError,setIsCouponError]=useState(undefined);
const [couponCode,setCouponCode]=useState(undefined);

const [cancelOrderDetail,responseDataOrderDetail,errorOrderDetail,loadedOrderDetail,resetOrderDetail,executeAPIOrderDetail] = useAxiosWithAuth();


const cancel=()=>{
    props.onCancel();
}

const proceed=()=>{
    props.onProceed(couponCode);
}

const applyRemoveCoupon=(code=undefined)=>{
  setIsCouponError(false);
  setCouponDesc("");
  if(code && code!="" && code.trim()!=""){
    let data={"plan_name": props.planName,"status_check":1,"disc_coupon":code.trim()}
    setCouponCode(code.trim());
    setCodeApplied(false);
    executeAPIOrderDetail(URL.SUBSCRIPTION_ORDER,"POST",data)
  }else{
    setCouponCode(undefined);
    setCodeApplied(false);
    setCode(undefined);
    let data={"plan_name": props.planName,"status_check":1}
    executeAPIOrderDetail(URL.SUBSCRIPTION_ORDER,"POST",data)
  }

}

const handleCodeChange = (event) => {
  setIsCouponError(false);
  setCode(event.target.value);
};



    /**
   * API response handler subscription data
   */  
    useEffect(() => {
      // console.log("OrderDetails=",responseDataOrderDetail);
      if(loadedOrderDetail){
        if(responseDataOrderDetail!=null){
          if(responseDataOrderDetail.success!=undefined && responseDataOrderDetail.success==true){
            setData(responseDataOrderDetail);
              // console.log("OrderDetails 1=",responseDataOrderDetail);
              if(couponCode){
                // console.log("coupon 1=",couponCode)
                if(responseDataOrderDetail.discount_code=="" || responseDataOrderDetail.discount_percent==0){
                  setCouponCode(undefined);
                  setIsCouponError(true);
                }
                else {
                  setCodeApplied(true);
                  setCouponDesc("Congratulations! You are eligible for a discount of " + responseDataOrderDetail.discount_percent + "% on your order.")
                  setOrderDetail(responseDataOrderDetail);
                }
              }else{
                // console.log("coupon 2=",couponCode)
                setCouponCode(undefined);
                setIsCouponError(false);
                setCodeApplied(false);
                setCode(undefined);

              }
            
          }
          
          
        }
        else if(errorOrderDetail!==null){
          // setMsgState({open:true,msg:errorOrderDetail?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorOrderDetail?.response?.status === 401 || errorOrderDetail?.response?.status === 403){
            console.log("status received =",errorOrderDetail?.response?.status)
            // navigate(from, { replace: true });
          }
      }
      resetOrderDetail();
      }
    },[loadedOrderDetail,responseDataOrderDetail]);
    

  return (
    
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{fontSize:"34px",fontWeight:600,textAlign:"center"}}>
            Purchase Details
          </Typography>
          {data.current_status!=undefined && data.current_status.length>0?
            <Box sx={{mt:2,display:'flex',justifyContent:"center"}}>
                <Box sx={{display:'flex',mb:1,pb:1,background:"#fff",borderRadius:"8px",pl:2,pr:2,mt:1,flexDirection:'column'}}>
                    {data.current_status.map((item,index) => (
                        
                        <>
                            {index==0?
                            <Typography  sx={{mb:1,mt:1,textAlign:'center',color:"#1e3a8a",fontSize:"20px",fontWeight:600}}>Active Subscription(s)</Typography>:
                            <></>}
                            <Box sx={{display:'flex',mb:1,pb:0,background:"#bfd2f2",borderRadius:"8px",pl:2,pr:2,mt:0,pt:1,flexDirection:'column'}}>
                                <Box sx={{display:'flex'}}>
                                    <Typography  sx={{mb:1,textAlign:'start',color:"#0d203f",fontWeight:600,minWidth:"140px"}}>Plan Details:</Typography>
                                    <Typography  sx={{mb:1,ml:1,textAlign:'start',color:"#0d203f"}}>{item.description}</Typography>
                                </Box>
                                <Box sx={{display:'flex'}}>
                                    <Typography  sx={{mb:2,color:"#0d203f",fontWeight:600,minWidth:"140px"}}>Expiry:</Typography>
                                    <Typography  sx={{mb:2,ml:1,color:"#0d203f"}}>{item.expiry_on}&nbsp;(in&nbsp;{item.remaining_days}&nbsp;days)</Typography>
                                </Box>
                            </Box>
                        </>  
                    ))}
                </Box>
                 
            </Box>:
            <>
            </>
          }

{data.after_payment!=undefined && data.after_payment.length>0?
            <Box sx={{mt:2,display:'flex',justifyContent:"start"}}>
                <Box sx={{display:'flex',mb:1,pb:1,background:"#fff",borderRadius:"8px",pl:2,pr:2,mt:1,flexDirection:'column'}}>
                <Typography  sx={{mb:1,mt:1,textAlign:'center',color:"#1e3a8a",fontSize:"20px",fontWeight:600}}>New Subscription after payment</Typography>
                    {data.after_payment.map((item,index) => (
                        
                        <>
                            
                            <Box sx={{display:'flex',mb:1,pb:0,background:"#bfd2f2",borderRadius:"8px",pl:2,pr:2,mt:0,pt:1,flexDirection:'column'}}>
                                <Box sx={{display:'flex'}}>
                                    <Typography  sx={{mb:1,textAlign:'start',color:"#0d203f",fontWeight:600,minWidth:"140px"}}>Plan Details:</Typography>
                                    <Typography  sx={{mb:1,ml:1,textAlign:'start',color:"#0d203f"}}>{item.description}</Typography>
                                </Box>
                                {item.remaining_days>0?
                                    <Box sx={{display:'flex'}}>
                                        <Typography  sx={{mb:1,color:"#0d203f",fontWeight:600,minWidth:"140px"}}>Expiry:</Typography>
                                        <Typography  sx={{mb:1,ml:1,color:"#0d203f"}}>{item.expiry_on}&nbsp;(in&nbsp;{item.remaining_days}&nbsp;days)</Typography>
                                    </Box>
                                    :
                                    <></>
                                }
                                {item.additional_comments && item.additional_comments!=""?
                                    <Box sx={{display:'flex'}}>
                                        <Typography  sx={{mb:2,color:"#0d203f",fontWeight:600,minWidth:"140px"}}>Notes:</Typography>
                                        <Typography  sx={{mb:2,ml:1,color:"#0d203f"}}>{item.additional_comments}</Typography>
                                    </Box>:
                                <></>
                                }
                            </Box>

                        </>  
                    ))}
                </Box>
                 
            </Box>:
            <>
            </>
          }
          {data.after_payment.findIndex(item=>item.remaining_days==0)>=0?
          <Typography sx={{mb:2,mt:2,color:"#ffce00",fontSize:"18px",fontWeight:600,minWidth:"150px"}}>
            <WarnIcon sx={{mb:-0.5,mt:0}}></WarnIcon> This new plan, when activated will be a downgrade from your current privileges - are you sure, you want to continue?
          </Typography>:
          <></>}
           <Box sx={{mt:3,ml:1,display:'flex',justifyContent:'center',flexDirection:'column'}}>
              <Box sx={{mt:0,display:'flex',justifyContent:"center",flexDirection:'row'}}>
              {!codeApplied?
                <TextField
                  // autoFocus
                  // required
                  variant="outlined"
                  size="small"
                  value={code}
                  sx={{mt:0,maxWidth:300}}
                  onChange={(event) => handleCodeChange(event)}
                  inputProps={{ maxLength: 200 }}
                  id="code"
                  label="Coupon Code"
                  error={isCouponError}
                
                  
                /> :
                <>
                <CheckCircleOutlineIcon sx={{mt:1,mr:1,color:"#40b66e"}}></CheckCircleOutlineIcon>
                {/* <Box sx={{display:'flex',flexDirection:'column'}}> */}
                  <Typography  sx={{mb:1,color:"#fff",mt:1.2,fontWeight:600,minWidth:"140px"}}>Coupon applied: {couponCode}</Typography>
                  {/* <Typography  sx={{mb:1,color:"#fff",mt:0,fontWeight:600,minWidth:"140px"}}>{couponDesc}</Typography> */}
                {/* </Box> */}
                </>
                }
              
              {!codeApplied?
              <Button variant="contained" disabled={code==undefined || code=="" || code.trim().length<4} onClick={()=>applyRemoveCoupon(code)} sx={{ml:2,marginTop:"4px",height:32, bgcolor:"#ffffff",color:"black"}}>
                  Apply
                </Button>:
              <Button variant="contained" onClick={()=>applyRemoveCoupon(undefined)} sx={{ml:2,marginTop:"4px", height:32, bgcolor:"#ffffff",color:"black"}}>
                  Remove
              </Button>
              }
                 
            </Box>
          
            {isCouponError?
              <Typography sx={{ml:0,mt:1,display:"flex",justifyContent:"center",color:"#ec7171",fontSize:"16px"}}>{"Oops! The provided Coupon Code: '"+code+"' is not applicable here."}</Typography>
              :
              <>
              <Typography sx={{ml:0,mt:1,display:"flex",justifyContent:"center",color:"#fff",fontWeight:600,fontSize:"16px"}}>{couponDesc}</Typography>
              {couponDesc != "" && orderDetail && orderDetail != undefined && orderDetail.final_cost != undefined?
              <Typography sx={{ml:0,mt:1,display:"flex",justifyContent:"center",color:"#fff",fontWeight:600,fontSize:"16px"}}>
                {"Original Cost: "} <RupeeIcon sx={{mb:0,mt:0.4,textAlign:'center',fontSize:"16px",fontWeight:600,color:"#fff"}}></RupeeIcon>{orderDetail.original_cost},
                &nbsp;&nbsp;
                {"Final Discounted Cost: "} <RupeeIcon sx={{mb:0,mt:0.4,textAlign:'center',fontSize:"16px",fontWeight:600,color:"#fff"}}></RupeeIcon>{orderDetail.final_cost}
              </Typography>
              :<></>
              }
              </>
            }
            
          </Box>
            <Box sx={{mt:3,display:'flex',justifyContent:"end"}}>
            
                <Button variant="contained" onClick={()=>cancel()} sx={{mr:2}}>
                Cancel
                </Button>
                <Button variant="contained" onClick={()=>proceed(couponCode)} sx={{mr:2,bgcolor:"#ffffff",color:"black"}}>
                Continue
                </Button>
              </Box>
         </Box>
      </Modal>

  );
}