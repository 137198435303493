import React, { useEffect, useState } from 'react'
import { Typography, Container,Toolbar,IconButton,Tooltip,Button,Grid,Box,Switch,FormControlLabel,Avatar,FormControl,Select} from '@mui/material'
import {styled, useTheme} from '@mui/material/styles';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChartOutlined';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { TIME_FRAME ,getChartOptionsData} from '../../../common/utility/configData';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { BOOKMARK_CATEGORY_AUTOSAVE, PROFILE_VIEW,USER_RESOURCES_LIST,TEXT_MSGS } from '../../../common/utility/constant';
import {CHART_TYPE, VA_TYPE,VP_TYPE,USE} from '../../../common/utility/mp_chart_constants';
import AbcIcon from '@mui/icons-material/Abc';
// import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/FilterCenterFocusOutlined';
import { HighlightOff } from '@mui/icons-material';
import { TIME_FRAME_VALUES } from '../../../common/utility/constant';
import GridOnIcon from '@mui/icons-material/GridOn';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { calculateNewValue } from '@testing-library/user-event/dist/utils';
import ScoreOutlinedIcon from '@mui/icons-material/ScoreOutlined';
import SettingsIcon from '@mui/icons-material/Settings';


const options = [
  'None',
  'Atria',
  'Callisto',
  'Dione',
  'Ganymede',
  'Hangouts Call',
  'Luna',
  'Oberon',
  'Phobos',
  'Pyxis',
  
];

const ITEM_HEIGHT = 48;
const ITEM_HEIGHT_NEW = 26;
const ITEM_PADDING_TOP = 8;
 const MenuProps = {
   PaperProps: {
     style: {
       maxHeight: ITEM_HEIGHT_NEW * 10 + ITEM_PADDING_TOP,
       width: 120,
       autoFocus: false
     },
   },
 };


export const FooterToolbar=styled(Toolbar)(
    ({theme})=> `
    background-color:${theme.palette.primaryTheme.shade01};
    width:100vw;
    height:32px;
    position:fixed;
    text-align:center;
    ${theme.breakpoints.up('mobile')}{
      min-height:32px;
    }
   `
  )
   
/**
 * 
 * @returns fixed toolbar for copyright text
 */
function MPChartFooter(props) {
  const theme=useTheme();
  const [userSettings,setUserSettings]=useState(props.userSettings)
  const [selectedTimeFrame,setSelectedTimeFrame]=useState(props.timeFrame)
  const [showVolumeNumber,setShowVolumeNumber]=useState(userSettings.data.show_global_vp_numbers);
  // const [showGlobalVolume,setShowGlobalVolume]=useState(userSettings.data.show_global_vp);
  const [showPerProfileVolumeNumber,setShowPerProfileVolumeNumber]=useState(userSettings?.data?.show_profile_vp_numbers?true:false);
  const [showPerProfilelVolume,setShowPerProfilelVolume]=useState(false);
  const [showOnlyVolumeProfile,setShowOnlyVolumeProfile]=useState(false);
  const [showTPOOnVolume,setShowTPOOnVolume]=useState(false);
  const [autoCenter, setAutocenter] = useState(false);
  const [selectedInstrumentData,setSelectedInstrumentData]=useState(props.selectedInstrumentData)
  const [alignment, setAlignment] = React.useState(PROFILE_VIEW.tpo_only);
  // const [viewState, setViewState] = React.useState({tpo_only:true,vol_tpo_side:false,only_vol:false,tpo_on_vol:false});
  const [viewState, setViewState] = React.useState({tpo_only:(userSettings.data.vp_type==VP_TYPE.NONE||userSettings.data.vp_type==VP_TYPE.BACKGROUND||userSettings.data.vp_type==VP_TYPE.ASIDE),vol_tpo_side:(userSettings.data.vp_type==VP_TYPE.ASIDE),only_vol:(userSettings.data.vp_type==VP_TYPE.ALONE),tpo_on_vol:(userSettings.data.vp_type==VP_TYPE.BACKGROUND)});
  const [vpType,setVPType]= React.useState(userSettings.data.vp_type);
  const [globalVolume, setGlobalVolume] = React.useState(userSettings.data.show_global_vp);
  // const [tpoBasedVA,setTPOBasedVA]=useState(false);
  const [tpoBasedVA,setTPOBasedVA]=useState(selectedInstrumentData.vp?userSettings.data.va_type:VA_TYPE.TPO_BASED);
  const [bookmarkList,setBookmarkList]=useState();
  const [selectedBookmark,setSelectedBookmark]=useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [chartOptionsData,setChartOptionsData]=useState(getChartOptionsData(props.auth));
  const [isStocksAllowed,setIsStocksAllowed]=useState((props.auth.resources?.find(el=>el==USER_RESOURCES_LIST.OF_STOCKS))?true:false)
  const [isIndexAllowed,setIsIndexAllowed]=useState((props.auth.resources?.find(el=>el==USER_RESOURCES_LIST.OF_INDEX))?true:false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event,index) => {
    setAnchorEl(null);
    if(index>=0){
      console.log("event.target.value menu=======>",event.target.value,chartOptionsData[index],index)
      props.handleChartChange(chartOptionsData[index])
    }
   
  };
  const handleCloseBookmark = (event,index) => {
    setAnchorEl(null);
  }
  
useEffect(()=>{
console.log("Hybrid test useeffect=",selectedInstrumentData.vp,userSettings.data.va_type)
},[])
  
  useEffect(()=>{
    let profileView=PROFILE_VIEW.tpo_only;
    if(userSettings.data.vp_type==VP_TYPE.ASIDE)
      profileView=PROFILE_VIEW.vol_tpo_side;
    else if(userSettings.data.vp_type==VP_TYPE.BACKGROUND)
      profileView=PROFILE_VIEW.tpo_on_vol;
    else if(userSettings.data.vp_type==VP_TYPE.ALONE)
      profileView=PROFILE_VIEW.only_vol;
    
      setAlignment(profileView)
  },[userSettings])

  const handleChange = (event, newAlignment) => {
    if(newAlignment==null)return;

    setAlignment(newAlignment);
    console.log("alignment=",newAlignment)
    if(newAlignment==PROFILE_VIEW.tpo_only){
      setViewState({tpo_only:true,vol_tpo_side:false,only_vol:false,tpo_on_vol:false})
      setVPType(VP_TYPE.NONE)

    }else if(newAlignment==PROFILE_VIEW.vol_tpo_side){
      setViewState({tpo_only:true,vol_tpo_side:true,only_vol:false,tpo_on_vol:false})
      setVPType(VP_TYPE.ASIDE)
    }else if(newAlignment==PROFILE_VIEW.tpo_on_vol){
      setViewState({tpo_only:true,vol_tpo_side:false,only_vol:false,tpo_on_vol:true})
      setVPType(VP_TYPE.BACKGROUND)
    }else if(newAlignment==PROFILE_VIEW.only_vol){
      setViewState({tpo_only:false,vol_tpo_side:false,only_vol:true,tpo_on_vol:false})
      setVPType(VP_TYPE.ALONE);
    }
    props.handleDirty(true);
   
  };

  
  const handleVATypeChange=(event,val)=>{
    if(event!=undefined){
    props.showTPOBasedVA(event.target.value);
    setTPOBasedVA(event.target.value);
    }
    else{
      props.showTPOBasedVA(val);
      setTPOBasedVA(val);
    }
    props.handleDirty(true);

  }

  useEffect(()=>{
    props.handleViewStateChange(viewState);
  },[viewState])

  useEffect(()=>{
    props.handleVPType(vpType);
  },[vpType])
  
  const handleClickAutoCenter = () => {
    props.setAutocenter(!autoCenter)
    setAutocenter(!autoCenter)
  };

  const openLineChart=()=>{
    if(props.basicUser)
      props.openSubscribeModal();
    else
     props.openLineChart();
  }


  const openOFChart=()=>{
    if(!isStocksAllowed && selectedInstrumentData.resource && selectedInstrumentData.resource=="stocks"){
      props.openSunscriptionModal(true,TEXT_MSGS.OF_CHART_STOCKS_RESOURCE_NOT_SUBSCRIBED_DESC);
      return;
    }else if(!isIndexAllowed && selectedInstrumentData.resource && selectedInstrumentData.resource=="index"){
      props.openSunscriptionModal(true,TEXT_MSGS.OF_CHART_INDEX_RESOURCE_NOT_SUBSCRIBED_DESC);
      return;
    }
    else
      props.openOFChart();
  }

  const openMPChart=()=>{
    props.openMPChart();
}

const openSettings=()=>{
  props.openSettings();
}
const showPriceTable=()=>{
  props.showPriceTable();
}
const changeTimeFrame=(val)=>{
  if(val!=selectedTimeFrame){
    setSelectedTimeFrame(val);
    props.changeTimeFrame(val);
  }
}

useEffect(()=>{
  if(props.bookmarkData!=undefined){
  console.log("bookmarkData in Footer=",props.bookmarkData);
  const jsonData=props.bookmarkData.data;
  if(jsonData!=undefined){
    console.log("jsonData in Footer inside=",jsonData);
    setViewState({tpo_only:(jsonData.vp_type==VP_TYPE.NONE||jsonData.vp_type==VP_TYPE.BACKGROUND||jsonData.vp_type==VP_TYPE.ASIDE),vol_tpo_side:(jsonData.vp_type==VP_TYPE.ASIDE),only_vol:(jsonData.vp_type==VP_TYPE.ALONE),tpo_on_vol:(jsonData.vp_type==VP_TYPE.BACKGROUND)});
    setVPType(jsonData.vp_type);
    setSelectedTimeFrame(jsonData.tf);
   
    let profileView=PROFILE_VIEW.tpo_only;
    if(jsonData.vp_type==VP_TYPE.ASIDE)
      profileView=PROFILE_VIEW.vol_tpo_side;
    else if(jsonData.vp_type==VP_TYPE.BACKGROUND)
      profileView=PROFILE_VIEW.tpo_on_vol;
    else if(jsonData.vp_type==VP_TYPE.ALONE)
      profileView=PROFILE_VIEW.only_vol;
    
      setAlignment(profileView)
      changeGlobalVolume(jsonData.glob_vol);
      changeGlobalVolumeNumber(jsonData.glob_num);
      changePerProfileVolumeNumber(jsonData.prof_num);
      chnageTPOBasedVA(jsonData.va);
      props.changeTimeFrame(jsonData.tf);
    // setSelectedTpo(props.jsonData.tpo);
  }
  props. handleFooterLoaded();
}
},[props.bookmarkData])

useEffect(()=>{
  console.log("props.timeframe=",props.timeFrame);
  setSelectedTimeFrame(props.timeFrame);
  
},[props.timeFrame])

useEffect(()=>{
  const bookmarks = JSON.parse(localStorage.getItem('bookmark_list'));
  if(bookmarks!=undefined)
    setBookmarkList(bookmarks);
  else
  setBookmarkList(undefined);
 console.log("selected intr vp=======>",props.selectedInstrument.vp) 
},[])

useEffect(()=>{
  setSelectedInstrumentData(props.selectedInstrumentData);
  
},[props.selectedInstrumentData])

const changeGlobalVolume=(val,isDirty=false)=>{
  props.setShowGlobalVolume(val)
  setGlobalVolume(val);
  if(isDirty==true)
  props.handleDirty(true);
}

const changeGlobalVolumeNumber=(val,isDirty=false)=>{
  props.showVolumeNumber(val)
  setShowVolumeNumber(val);
  if(isDirty==true)
  props.handleDirty(true);
  
}

const changePerProfileVolumeNumber=(val,isDirty=false)=>{
props.showPerProfileVolumeNumber(val)
setShowPerProfileVolumeNumber(val);
if(isDirty==true)
props.handleDirty(true);
}

const chnageTPOBasedVA=(val,isDirty=false)=>{
props.showTPOBasedVA(val)
setTPOBasedVA(val);
if(isDirty==true)
props.handleDirty(true);
}

const handleBookmarkClick= (event,index) => {
  if(selectedBookmark==undefined || selectedBookmark.id!=bookmarkList[index].id){
   setSelectedBookmark(bookmarkList[index])
   props.loadBookmark(bookmarkList[index]);
  }
  setAnchorEl(null);
};

useEffect(()=>{
  
    console.log("MP Settings useEffect called MP chart Footer",props.userSettings,props.bookmarkData,props.userSettings.data.show_global_vp);
    if(props.bookmarkData==undefined){
      if(props.selectedInstrumentData.vp){
        if(globalVolume!= props.userSettings.data.show_global_vp)
        changeGlobalVolume(props.userSettings.data.show_global_vp,true);

        if(showVolumeNumber!=props.userSettings.data.show_global_vp_numbers)
        changeGlobalVolumeNumber(props.userSettings.data.show_global_vp_numbers,true);

        if(showPerProfileVolumeNumber!=props.userSettings.data.show_profile_vp_numbers)
        changePerProfileVolumeNumber(props.userSettings.data.show_profile_vp_numbers,true);

      
        let profileView=PROFILE_VIEW.tpo_only;
        if(props.userSettings.data.vp_type==VP_TYPE.ASIDE)
          profileView=PROFILE_VIEW.vol_tpo_side;
        else if(props.userSettings.data.vp_type==VP_TYPE.BACKGROUND)
          profileView=PROFILE_VIEW.tpo_on_vol;
        else if(props.userSettings.data.vp_type==VP_TYPE.ALONE)
          profileView=PROFILE_VIEW.only_vol;
        if(profileView!=alignment){
          handleChange(null,profileView);
        } 
        
        if(tpoBasedVA!=props.userSettings.data.va_type){
          handleVATypeChange(undefined,props.userSettings.data.va_type);
        }
     

      }
      
    }
  
 },[props.userSettings])

  return (
    <>
      <FooterToolbar sx={{paddingLeft:"4px", paddingRight:"8px",borderTop: `1px solid ${theme.palette.primaryTheme.shade03}`,borderRight: `2px solid ${theme.palette.primaryTheme.shade03}`, left:props.chartStateData.left,top:props.chartStateData.top+props.chartStateData.height+20, width:props.chartStateData.width }} >
        {/* <Grid item mobile={8} tablet={10} laptop={10} > */}
            {props.chartType==CHART_TYPE.MARKET_PROFILE ? 
            <>
            {!props.showTable?
            <Box display="flex"   sx={{height:"100%",overflowX:'auto',overflowY:'hidden', width:`calc(${props.chartStateData.width-160}px)`,alignItems:'center'}}>
              <Tooltip title="Market Profile Chart Settings">
              <SettingsIcon onClick={openSettings}sx={{ml:"2px", marginRight:"6px", marginTop:props.chartType==CHART_TYPE.MARKET_PROFILE?"-3px":"-3px",cursor:'pointer'}}/>
          </Tooltip>
              {TIME_FRAME.filter(obj=>{
                if(props.bookmarkData==undefined){
                  // if(!props.basicUser)
                    return props.selectedInstrumentData.tf.includes(obj.value)
                  // else
                  // return props.selectedInstrumentData.tf.includes(obj.value) && obj.value!=TIME_FRAME_VALUES.yearly  //dont show yearly time frame for basic user
                }else{
                  // if(props.basicUser)
                  //   return (props.bookmarkData.category==BOOKMARK_CATEGORY_AUTOSAVE|| props.bookmarkData.data.tf==obj.value) && ( props.selectedInstrumentData.tf.includes(obj.value) && obj.value!=TIME_FRAME_VALUES.yearly )
                  // else
                    return (props.bookmarkData.category==BOOKMARK_CATEGORY_AUTOSAVE|| props.bookmarkData.data.tf==obj.value) && ( props.selectedInstrumentData.tf.includes(obj.value))
                }
            }).map((item,index) => (
                            <Tooltip title={item.displayText}>
                            <Button  variant="text" key={item.key} onClick={()=>changeTimeFrame(item.value)}
                            sx={{minWidth:36,fontSize:14,width:36,height:30,borderRadius:0,marginBottom:"8px",marginTop:1,
                            backgroundColor:selectedTimeFrame==item.value?"#567":"transparent",
                            "&:hover": {
                              backgroundColor: '#567'
                            }}}>
                            {item.key}
                            </Button>
                            </Tooltip>
                        ))}

{/* <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,marginTop:"6px" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedTimeFrame}
          MenuProps={MenuProps}
          sx={{height:"24px"}}
          onChange={(event)=>changeTimeFrame(event.target.value)}
        >
          {TIME_FRAME.filter(obj=>{
                if(props.bookmarkData==undefined){
                  if(!props.basicUser)
                    return props.selectedInstrumentData.tf.includes(obj.value)
                  else
                  return props.selectedInstrumentData.tf.includes(obj.value) && obj.value!=TIME_FRAME_VALUES.yearly  //dont show yearly time frame for basic user
                }else{
                  if(props.basicUser)
                    return (props.bookmarkData.category==BOOKMARK_CATEGORY_AUTOSAVE|| props.bookmarkData.data.tf==obj.value) && ( props.selectedInstrumentData.tf.includes(obj.value) && obj.value!=TIME_FRAME_VALUES.yearly )
                  else
                    return (props.bookmarkData.category==BOOKMARK_CATEGORY_AUTOSAVE|| props.bookmarkData.data.tf==obj.value) && ( props.selectedInstrumentData.tf.includes(obj.value))
                }
            }).map((item,index) => (
                            <MenuItem key={item.key} value={item.value}>{item.displayText}</MenuItem>
                        ))}
        </Select>
      </FormControl>      */}

                        

      {props.selectedInstrumentData.vp?        
     <>                   
                        <ToggleButtonGroup>
            <Tooltip title="Show/Hide Global Volume Profile">
            
                <ToggleButton
            value="check"
            selected={globalVolume}
            onChange={() => {
             changeGlobalVolume(!globalVolume,true);
            }}
            sx={{fontSize:"12px",padding:"5px",height:"26px",borderRadius:0,marginBottom:"6px", backgroundColor:globalVolume?"#567":"transparent",marginLeft:1,marginTop:1}}>
              GV
            </ToggleButton>
            </Tooltip>
            </ToggleButtonGroup>
            <Tooltip title="Show/Hide Global Volume Profile Numbers">
            <ToggleButton
            value="check"
            selected={showVolumeNumber}
            onChange={() => {
              changeGlobalVolumeNumber(!showVolumeNumber,true);
            }}
            sx={{fontSize:"12px",padding:"5px",height:"26px",borderRadius:0,marginBottom:"6px",backgroundColor:showVolumeNumber?"#567":"transparent",marginLeft:1,marginTop:1}}>
              GVN
            </ToggleButton>
            </Tooltip>
              
      <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      size="small"
      sx={{fontSize:"12px",height:"26px",borderRadius:0,marginBottom:"6px",marginLeft:2,marginTop:1}}
    >
      <Tooltip title="TPO Profile Only">
      <ToggleButton value={PROFILE_VIEW.tpo_only} 
       sx={{fontSize:"12px",padding:"5px",backgroundColor:alignment==PROFILE_VIEW.tpo_only?"#567":"transparent"}}>
        T</ToggleButton>
      </Tooltip>
      <Tooltip title="Volume Profile at side of TPO Profile">
      <ToggleButton value={PROFILE_VIEW.vol_tpo_side} 
       sx={{fontSize:"12px",padding:"5px",whiteSpace:"nowrap",backgroundColor:alignment==PROFILE_VIEW.vol_tpo_side?"#567":"transparent"}}>
        T-V</ToggleButton>
      </Tooltip>
      <Tooltip title="Volume Profile behind TPO Profile">
      <ToggleButton value={PROFILE_VIEW.tpo_on_vol} 
       sx={{fontSize:"12px",whiteSpace:"nowrap" ,padding:"5px",backgroundColor:alignment==PROFILE_VIEW.tpo_on_vol?"#567":"transparent"}}>
        T/V</ToggleButton>
      </Tooltip>
      <Tooltip title="Volume Profile Only">      
      <ToggleButton value={PROFILE_VIEW.only_vol}
      sx={{fontSize:"12px",padding:"5px",backgroundColor:alignment==PROFILE_VIEW.only_vol?"#567":"transparent"}}>
        V</ToggleButton>
      </Tooltip>

    </ToggleButtonGroup></>:
    <></>
      }
    
    {props.selectedInstrumentData.vp?        
     <>
    <Tooltip title="Show/Hide Individual Volume Profile Numbers">
            <ToggleButton
            value="check"
            selected={showPerProfileVolumeNumber}
            onChange={() => {
              changePerProfileVolumeNumber(!showPerProfileVolumeNumber,true);
            }}
            sx={{fontSize:"12px",padding:"5px",height:"26px",borderRadius:0,marginBottom:"6px",backgroundColor:showPerProfileVolumeNumber?"#567":"transparent",marginLeft:1,marginTop:1}}>
              VN
            </ToggleButton>
            </Tooltip>
          
            {/* <Tooltip title="Toggle between TPO based VA and Volume based VA">
            <ToggleButton
            value="check"
            selected={tpoBasedVA}
            onChange={() => {
             chnageTPOBasedVA(!tpoBasedVA,true);
            }}
            sx={{fontSize:"12px",whiteSpace:"nowrap",padding:"5px",height:"26px",borderRadius:0,marginBottom:"6px",backgroundColor:tpoBasedVA?"#567":"transparent",marginLeft:1,marginTop:1}}>
              TPO VA
            </ToggleButton>
            </Tooltip>     */}
            <ToggleButtonGroup
      color="primary"
      value={tpoBasedVA}
      exclusive
      onChange={handleVATypeChange}
      aria-label="Platform"
      size="small"
      sx={{fontSize:"12px",height:"26px",borderRadius:0,marginBottom:"6px",marginLeft:2,marginTop:1}}
    >
      <Tooltip title="TPO Based VAH, VAL, POC and Weighted Avg Price (TWAP)">
      <ToggleButton value={VA_TYPE.TPO_BASED} 
       sx={{fontSize:"12px",padding:"5px",whiteSpace:"nowrap",backgroundColor:tpoBasedVA==VA_TYPE.TPO_BASED?"#567":"transparent"}}>
        T-VA</ToggleButton>
      </Tooltip>
      <Tooltip title="Volume Based VAH, VAL, POC and Weighted Avg Price (VWAP)">
      <ToggleButton value={VA_TYPE.VOLUME_BASED} 
       sx={{fontSize:"12px",padding:"5px",whiteSpace:"nowrap",backgroundColor:tpoBasedVA==VA_TYPE.VOLUME_BASED?"#567":"transparent"}}>
        V-VA</ToggleButton>
      </Tooltip>
      <Tooltip title="Hybrid: TPO Based VAH, VAL, POC and Volume Weighted Avg Price (VWAP)">
      <ToggleButton value={VA_TYPE.HYBRID} 
       sx={{fontSize:"12px",whiteSpace:"nowrap" ,padding:"5px",backgroundColor:tpoBasedVA==VA_TYPE.HYBRID?"#567":"transparent"}}>
        H-VA</ToggleButton>
      </Tooltip>
      

    </ToggleButtonGroup>
            </>:
            <></>} 
            {bookmarkList!=undefined && bookmarkList.length>0?
            <>
          
             <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                onClose={handleCloseBookmark}
            >
              <Tooltip title="Bookmarks">
                <MoreVertIcon onClick={handleClick}/>
              </Tooltip>
            </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseBookmark}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {bookmarkList.map((option,index) => (
              <MenuItem key={option.id}
              //  selected={option === 'Pyxis'} 
              value={option.id}
              onClick={(event)=>handleBookmarkClick(event,index)}>
                {option.name}
              </MenuItem>
            ))}
          </Menu>
          </>:<></>}

                       
            </Box>:<>
            <Box display="flex"   sx={{height:"100%",overflowX:'auto',overflowY:'hidden',width:`calc(${props.chartStateData.width-160}px)`,alignItems:'center'}}></Box>
            </>}
            </>:
            <>
             {/* <Box display="flex"   sx={{height:"100%"}}> */}
             <Box display="flex"   sx={{height:"100%",overflowX:'auto',overflowY:'hidden',width:`calc(${props.chartStateData.width-160}px)`,alignItems:'center'}}>
              <Typography sx={{fontSize:14,ml:2,mt:0}}>Charts</Typography>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {chartOptionsData.map((option,index) => (
              <MenuItem key={option.key}
              onClick={(event)=>handleClose(event,index)}>
                {option.key}
              </MenuItem>
            ))}
          </Menu>
          </Box>
            </>}
        {/* </Grid> */}
      {/* <Grid item mobile={4} tablet={2} laptop={2} > */}
        <Box display="flex" justifyContent="flex-end"  sx={{height:"100%",width:"170px",alignItems:"center"}}>
        {props.chartType==CHART_TYPE.MARKET_PROFILE?
        <Tooltip title="Price Table">
       <GridOnIcon  onClick={showPriceTable}sx={{border:"0.5px solid",ml:1,marginTop:props.chartType==CHART_TYPE.MARKET_PROFILE?"-3px":"-3px",height:20,width:20,cursor:'pointer'}}/>
     
        </Tooltip>:
        <></>}
          {/* {selectedInstrumentData.line_chart?
          <Tooltip title={props.isEliteUser?"Candle Chart":"Line Chart"}>
              <ShowChartIcon onClick={openLineChart}sx={{border:"0.5px solid",ml:1,marginTop:props.chartType==CHART_TYPE.MARKET_PROFILE?"-3px":"-3px",height:20,width:20,cursor:'pointer'}}/>
          </Tooltip>:
          <></>
          } */}
          {props.isEliteUser ?
            <>
              {selectedInstrumentData.cc ?
                <Tooltip title={"Candle Chart"}>
                  <ShowChartIcon onClick={openLineChart} sx={{ border: "0.5px solid", ml: 1, marginTop: props.chartType == CHART_TYPE.MARKET_PROFILE ? "-3px" : "-3px", height: 20, width: 20, cursor: 'pointer' }} />
                </Tooltip> :
                <></>
              }
            </> :
            <>
              {selectedInstrumentData.line_chart ?
                <Tooltip title={"Line Chart"}>
                  <ShowChartIcon onClick={openLineChart} sx={{ border: "0.5px solid", ml: 1, marginTop: props.chartType == CHART_TYPE.MARKET_PROFILE ? "-3px" : "-3px", height: 20, width: 20, cursor: 'pointer' }} />
                </Tooltip> :
                <></>
              }
            </>
          }
          {selectedInstrumentData.of?
          <Tooltip title="Orderflow Chart">
              <ScoreOutlinedIcon onClick={openOFChart}sx={{border:"0.5px solid",ml:1,marginTop:props.chartType==CHART_TYPE.MARKET_PROFILE?"-3px":"-3px",height:20,width:20,cursor:'pointer'}}/>
          </Tooltip>:
          <></>
          }
        
          <Tooltip title="MP chart">
            <AbcIcon disabled onClick={openMPChart}sx={{border:"0.5px solid",ml:1,marginTop:props.chartType==CHART_TYPE.MARKET_PROFILE?"-3px":"-3px",height:20,width:20,cursor:'pointer'}}>
            </AbcIcon>
          </Tooltip>

          {props.chartType==CHART_TYPE.MARKET_PROFILE && !props.showTable?
          <>
            <Tooltip title="Reset Zoom / Recenter">
              <RestartAltOutlinedIcon 
                onClick={()=>props.resetChart()}
                sx={{ml:1,marginTop:props.chartType==CHART_TYPE.MARKET_PROFILE?"-4px":"-4px",height:24,width:20,cursor:'pointer'}}>
              </RestartAltOutlinedIcon>
            </Tooltip>
            
            <Tooltip title="Unselect Profiles">
              <HighlightOff
                onClick={()=>props.unselectProfiles()}
                sx={{ml:1,mr:0,marginTop:props.chartType==CHART_TYPE.MARKET_PROFILE?"-4px":"-4px",height:24,width:20,cursor:'pointer'}}>
              </HighlightOff>
            </Tooltip>
            </>:
          <></>}
           
           
          {/* {props.chartType==CHART_TYPE.MARKET_PROFILE?  
        <Tooltip title={!autoCenter?"Enable Auto Centering":"Disable Auto Centering"}>
          <IconButton
            aria-label="toggle auto center"
            onClick={handleClickAutoCenter}
            sx={{color:autoCenter?"#ffffff":"#999999"}}
            >
           {!autoCenter ? <FormatAlignCenterIcon /> : <FormatAlignCenterIcon />}
          </IconButton>
        </Tooltip>:
        <></>} */}

        </Box>
        {/* </Grid> */}
      </FooterToolbar>
    </>
  )
}

export default MPChartFooter