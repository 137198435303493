import * as React from 'react';
import { useState,useRef,useEffect } from 'react';
import Button from '@mui/material/Button';
import {IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Header from '../components/header';
import { Formik, Form, Field } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MuiTextFieldFormik } from '../../../components/CustomTextField/TextField';
import { validateResetPasswordForm } from '../../../common/utility/validators';
import { useAxios } from '../../../common/api/hooks/useAxios';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import WelcomeLayout from '../components/welcome-layout';
import FormContainer from '../components/form-container';
import { useQuery } from '../../../common/utility/helperFunc';
import {Snackbar, Alert} from '@mui/material';
import MainLayoutContainer from '../components/main-layout';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../../common/utility/constant';

 
export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { cancel,responseData, error, loaded, reset,executeAPI } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/login";
  const formikRef = useRef();
  const accessToken=useQuery("token");

  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  const { open, msg,severity } = msgState;

  const [apiError,setAPIError]=useState(undefined);

 
  //form initial values for reset password
 const initialValues = {
  password: '',
  confirm_password:'',
  token:accessToken 
};

/**
 * API response handler  
 */  
  useEffect(() => {
  
    if(loaded){
      if(responseData!=null){
        setMsgState({open:true,msg:responseData.message,severity:"success"});
        navigate(from, { replace: true });
      }
      else if(error!==null){
        formikRef?.current.setSubmitting(false);
        if(error?.response.status===400 || error?.response.status===401 || error?.response.status===404){
          setAPIError(error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`);
          //formikRef?.current.setErrors({email_id:"Email id already registered"});
        }else{
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
          ,severity:"info"}); 
        }
      }
      reset();
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loaded,responseData,error,navigate,from]);


  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  
 /**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };

 /**
 * API caller  
 */  
  const onSubmit = (values, props) => {
    setAPIError(undefined);
    executeAPI(URL.RESET_PASSWORD,"POST",JSON.stringify(values));   
  }



  return (
      <MainLayoutContainer>
        
        {/* Header component */}
        <Header showLogin={true} showIntro={true}/>
       
        {/*Welcome banner left component*/}
        <WelcomeLayout/>
       
        {/* Form container for right layut */}
        <FormContainer>
            
        <Box sx={{ justifyContent:"center",alignItems:"center", height:"100%", width:{mobile:"90%", tablet:"75%", laptop:"60%"} }}>
            <Typography  variant="titleMedium" >
              Reset Password
            </Typography>

            <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} validate={validateResetPasswordForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
              <Form>
            
              {/* Password field */}
              <Box>
                <Field
                  component={MuiTextFieldFormik}
                  fullWidth
                  required
                  variant="outlined" 
                  size="small" 
                  color="inputFieldColor"
                  sx={{ mb: 2,mt:2 }}
                  type={showPassword ? "text" : "password"} 
                  label="Password"
                  name="password"
                  InputProps={{ 
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>

              {/* Confirm Password field */}
              <Box>
                <Field
                  component={MuiTextFieldFormik}
                  fullWidth
                  required
                  variant="outlined" 
                  size="small" 
                  color="inputFieldColor"
                  sx={{ mb: 2 }}
                  type={showConfirmPassword ? "text" : "password"} 
                  label="Confirm Password"
                  name="confirm_password"
                  InputProps={{ 
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              
              {apiError!=undefined?
                <Typography variant="errorText">{apiError}</Typography>:
                <></>
              }
              
                  
                  <Button type='submit' sx={{mb:2,mt:2}} variant="contained" disabled={isSubmitting}
                    fullWidth>{isSubmitting ? "Loading" : "Reset Password"}</Button>
            </Form>
            )}
        </Formik>
      
      </Box>
 
    </FormContainer>
    
    <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
      <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
        {msg}
      </Alert>  
    </Snackbar>
 
  </MainLayoutContainer>
  );
}