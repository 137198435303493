import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../../setup/routes-manager";
import { AuthProvider } from "../../common/context/AuthProvider";



const Container = () => {
  return (
    <>
      <Router>
        <AuthProvider>
            <Routes />
        </AuthProvider>     
      </Router>
    </>
  );
};

export default Container;
