import React, { useEffect } from 'react'
import { Typography, Container,Toolbar,IconButton,Tooltip,Grid,Box,Button} from '@mui/material'
import {styled, useTheme} from '@mui/material/styles';


export const Footer=styled(Toolbar)(
    ({theme})=> `
    background-color:${theme.palette.primaryTheme.shade07};
    height:32px;
    position:fixed;
    justify-content:center;
    text-align:center;
    z-index:999;
    ${theme.breakpoints.up('mobile')}{
      min-height:32px;
    }
   `
  )
 // width:${props.canvasWidth}+"px;
//    bottom:32px;
   

function MergeFooter(props) {
  const theme=useTheme();
  const mergeProfiles=()=>{
    props.mergeProfiles();
 
  }
  const unmergeProfiles=()=>{
    props.unmergeProfiles();
}
  return (
    <>
      <Footer sx={{left:props.chartStateData.left,top:props.chartStateData.top+props.chartStateData.height+20-32, width:props.chartStateData.width,borderTop: `1px solid ${theme.palette.primaryTheme.shade03}` }} >
      <Grid  container >
                <Grid item mobile={8} tablet={8} laptop={8} >
                    <Box display="flex" justifyContent="flex-start" sx={{width:"100%",height:"100%",textAlign:'center'}}>
                        {props.selectedIndex.length>1?
                        <Typography  variant="copyRight" align="center" sx={{alignSelf:"center",color:'primaryTheme.shade01'}} >
                            Merge Selected Profiles (Only adjacent profiles will be merged)
                        </Typography>:
                        <Typography  variant="copyRight" align="center" sx={{alignSelf:"center",color:'primaryTheme.shade01'}}>
                          Unmerge Selected Profile
                    </Typography>}
                    
                    </Box>
                </Grid>
                
                <Grid item mobile={4} tablet={4} laptop={4} >
                  <Box display="flex" sx={{height:"100%"}} justifyContent="end" alignItems="center">
                  {props.selectedIndex.length>1?
                  <>
                    <Button variant="contained" onClick={()=>mergeProfiles()} sx={{mr:1,fontSize:"12px",height:26,borderRadius:"0px"}}>
                      Merge
                    </Button>

                    <Button variant="outline" onClick={()=>props.unselectAll()} sx={{mr:1,fontSize:"12px",height:26,borderRadius:"1px",fontColor:"#0d203f",color:"#0d203f",border:'1px solid #0d203f'}}>
                      Unselect All
                    </Button>
                </>:
                  <Button variant="contained" onClick={()=>unmergeProfiles()} sx={{mr:1,height:26,borderRadius:"0px",fontSize:"12px"}}>
                  Unmerge
                </Button>}
                  </Box>
                </Grid>
            </Grid>
      </Footer>
    </>
  )
}

export default MergeFooter