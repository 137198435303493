import React, { useEffect } from 'react'
import { Typography, Container,Toolbar,IconButton,Tooltip,Grid,Box,Button} from '@mui/material'
import {styled, useTheme} from '@mui/material/styles';


export const Footer=styled(Toolbar)(
    ({theme})=> `
    background-color:${theme.palette.primaryTheme.shade07};
    height:44px;
    position:fixed;
    bottom:0px;
    justify-content:center;
    text-align:center;
    z-index:999;
    ${theme.breakpoints.up('mobile')}{
      min-height:32px;
    }
   `
  )
 
function SettingsFooter(props) {
  const theme=useTheme();
  
  return (
    <>
      <Footer sx={{borderTop: `1px solid ${theme.palette.primaryTheme.shade03}`,width:`calc(100vw)` }} >
      <Grid  container >
                <Grid item mobile={4} tablet={6} laptop={6} >
                    <Box display="flex" justifyContent="flex-start" sx={{width:"100%",height:"100%",textAlign:'center'}}>
                    <Tooltip title="To save the values, click Save Settings">
                      <Button variant="outline" onClick={()=>props.resetDefault()} sx={{mr:1,ml:1,fontSize:"12px",height:36,borderRadius:"1px",fontColor:"#0d203f",color:"#0d203f",border:'1px solid #0d203f'}}>
                        Reset Default
                      </Button>
                    </Tooltip>
                    </Box>
                </Grid>
                
                <Grid item mobile={8} tablet={6} laptop={6} >
                  <Box display="flex" sx={{height:"100%"}} justifyContent="end" alignItems="center">
                    <Button variant="outline" onClick={()=>props.discardChanges()} sx={{mr:1,fontSize:"12px",height:36,borderRadius:"1px",fontColor:"#0d203f",color:"#0d203f",border:'1px solid #0d203f'}}>
                      Discard Changes
                    </Button>
                    <Button variant="contained" onClick={()=>props.saveSettings()} sx={{mr:0,fontSize:"12px",height:36,borderRadius:"0px"}}>
                      Save Settings
                    </Button>
                  </Box>
                </Grid>
            </Grid>
      </Footer>
    </>
  )
}

export default SettingsFooter