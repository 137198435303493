import * as React from 'react';
import { useState,useRef,useEffect } from 'react';
import Button from '@mui/material/Button';
import {IconButton} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Header from '../components/header';
import { Formik, Form, Field } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MuiTextFieldFormik } from '../../../components/CustomTextField/TextField';
import { validateLoginForm } from '../../../common/utility/validators'
import { useAxios } from '../../../common/api/hooks/useAxios';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from  '../../../common/hooks/useAuth';
import { useTheme, Snackbar, Alert } from '@mui/material';
// import WelcomeLayout from '../components/welcome-layout';
// import FormContainer from '../components/form-container';
// import MainLayoutContainer from '../components/main-layout';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION,REFRESH_POLLING_INTERVAL } from '../../../common/utility/constant';
// import bannerImg from '../../../assets/images/diwali-frontpage-banner.jpeg';
import bannerImg from '../../../assets/images/hny-frontpage-banner1.jpeg';
import miniBannerImg from '../../../assets/images/mini-banner1.jpeg';
import Modal from '@mui/material/Modal';
import useRefreshToken from '../../../common/hooks/useRefreshToken';


 //form initial values for login
 const initialValues = {
  email_id: '',
  password: '',
  remember_me: true
};

const imgStyle = {
  width: "100%",
  // height: "50%",
  // align: "middle",
  marginLeft: 0, 
  marginRight: 40, 
  marginTop: 20, 
  marginBottom: 20
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function LoginModal(props) {
  const theme=useTheme();
  const refresh = useRefreshToken();
  const { setAuth, persist, setPersist } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [openModal, setOpenModal] = React.useState(props.open);
  const [isPollingAllowed, setIsPollingAllowed] = useState(true);
  
  const { cancel,responseData, error, loaded, reset,executeAPI } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const [apiError,setAPIError]=useState(undefined);

  const from = location.state?.from?.pathname && location.state?.from?.search?location.state?.from?.pathname +location.state?.from?.search: "/";
  const formikRef = useRef();
  
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
   const { open, msg,severity } = msgState;
   console.log("TestRefresh Login from location=",from,location);


 //polling or live chart update handler
useEffect(() => {
            
    console.log("Refresh call from login pop up=");
    console.log("TEST LOGIN Login popup 1")
    
    const checkRefresh=async()=>{
        try{
            console.log("TEST LOGIN Login popup 2")
        const newAccessToken = await refresh();
        console.log("login pop Refresh token reponse =",newAccessToken)
        console.log("TEST LOGIN Login popup 4")
        props.onClose();
        }catch (error) {
            console.log("TEST LOGIN Login popup 4")
            console.log("login pop Refresh token error=",error);
        }
    }
    const timeout = setInterval(() => {
        if(isPollingAllowed )   {
            console.log("login pop upcalling refresh from mp chart")
             checkRefresh();
        } 
                
            
    }, REFRESH_POLLING_INTERVAL);
      
    return () => {
            // just to clear the timeout when component unmounts
            // console.log("login pop clear polling");
            clearInterval(timeout);
            };
          }, []);
  
 /**
 * API response handler  
 */  
  useEffect(() => {
    if(loaded){
      if(responseData!=null){
        const accessToken = responseData?.token;
        console.log("Login popup responseData=",responseData)
        setAuth({accessToken:accessToken,name:responseData.first_name+" "+responseData.last_name,role:responseData.role,resources:responseData.resources,email_id:responseData.email_id});
        props.onClose();
        // navigate(from, { replace: true });
      }
      else if(error!==null){
        formikRef?.current.setSubmitting(false);
        if(error?.response?.status===404){
          formikRef?.current.setErrors({email_id:"Email id not registered"})
        }else if(error?.response?.status===400){
          formikRef?.current.setErrors({password:"Password is incorrect"})
        }else{
          // setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          setAPIError(error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`)
        
        }
        reset();
      }
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loaded,responseData,error,navigate,from]);


  const handleClickShowPassword = () => setShowPassword(!showPassword);
  
  
/**
 * API caller  
 */  
 const onSubmit = (values, props) => {
  setAPIError(undefined);
    values.remember_me=rememberMe;
    executeAPI(URL.LOGIN,"POST",JSON.stringify(values)); 
 }

 /**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

const handleCloseModal = (event,reason) => {
  // backdropClick, escapeKeyDown
  // console.log("HO HO",event,reason)
  if(reason && reason=="backdropClick") return;
    setOpenModal(false)
    props.onClose();
}

  return (
     
    <Modal
    open={openModal}
    onClose={handleCloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    disableEscapeKeyDown={true}
  >

<Box sx={style}>
          
            <Typography  variant="descriptionLarge" >
              {props.msg?props.msg:TEXT_MSGS.LOGIN_POPUP_MSG}
            </Typography>
            <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} validate={validateLoginForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
              <Form>
  
                {/* Email field */}
                <Box>
                      <Field
                      component={MuiTextFieldFormik}
                      fullWidth
                      required
                      variant="outlined" 
                      size="small" 
                      color="inputFieldColor"
                      sx={{ mt: 3, mb: 3, }}
                      name="email_id"
                      type="email"
                      label="Email"
                    />
              </Box>
             
             {/* Password field */}
              <Box>
                <Field
                  component={MuiTextFieldFormik}
                  fullWidth
                  required
                  variant="outlined" 
                  size="small" 
                  color="inputFieldColor"
                  sx={{ mb: 2 }}
                  type={showPassword ? "text" : "password"} 
                  label="Password"
                  name="password"
                  InputProps={{ 
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              
              {/* Remember Me */}
              <Box display="flex" sx={{ mb: 2 }} justifyContent="space-between" alignItems="center">
                <Field as={FormControlLabel}
                    name='remember_me'
                    size='small'
                    control={<Checkbox 
                      checked={rememberMe}
                      onChange={()=>setRememberMe(!rememberMe)}
                      sx={{
                        color: theme.palette.secondaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.secondaryTheme.shade03,
                        },
                      }}/>
                    }
                    label={<Typography variant="linkSupportText" >
                        Remember Me
                      </Typography>
                    }
                />
                               
                {/*Forgot/reset password link  */}
                <Link href="/forgot-password" sx={{alignContent:"flex-end"}} variant="link">Forgot/Reset password?</Link>
                </Box>           
                  
                {/* submit log in button */}
                <Button type='submit' sx={{mb:2,mt:2}} variant="contained" disabled={isSubmitting}
                      fullWidth>{isSubmitting ? "Loading" : "Log In"}
                </Button>
                {apiError? 
              <Box sx={{mt:-1,pb:0}}>
                <Typography variant="warningText">{apiError}</Typography>
              </Box>
              :
              <></>
              }
              <Box sx={{mt:1,pb:0}}>

                <Typography variant="subtitle2">Note: If you have multiple tabs open, you can login only once in any 1 of the tabs and other tabs should resume automatically.</Typography>
              </Box>
              
              </Form>
                )}
            </Formik>
 
              {/* <Grid item>
                  <Typography variant="link">{"Don't have an account yet? "}  
                    <Link href="/register" variant="link" sx={{pb:6}}>  {"Register"} </Link>
                  </Typography>
              </Grid> */}
              <br/><br/><br/><br/>
 
         
 
       
 
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
         <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
        </Snackbar>  
        </Box>   
 
        </Modal> 
     
  );
}