import React, { useEffect, useLayoutEffect, useRef,useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import dark_theme from "@amcharts/amcharts5/themes/Dark";
import { Container, Box, Typography, useTheme, IconButton, Tooltip,Select,FormControl,Menu,MenuItem,Checkbox,InputLabel,Snackbar,Alert } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { WATERMARK_CONFIG,DateFormat,USER_ROLE_FIELDS,NO_OF_DAYS,MenuProps,SNAKE_CHART_PLOT_TYPE,TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION_SHORT,MIN_SNAKE_CHART_WIDTH } from '../../../common/utility/constant';
import dayjs from 'dayjs';
import { DatePicker} from 'antd';
import useAuth from '../../../common/hooks/useAuth';

const OptSnakesLineChartContainer = (props) => {
  const seriesStraddle1Ref = useRef(null);
  const seriesStraddle2Ref = useRef(null);
  const seriesStraddle3Ref = useRef(null);
  const seriesStraddle4Ref = useRef(null);
  const seriesStraddle5Ref = useRef(null);
  const seriesIv1Ref = useRef(null);
  const seriesIv2Ref = useRef(null);
  const seriesIv3Ref = useRef(null);
  const seriesIv4Ref = useRef(null);
  const seriesIv5Ref = useRef(null);
  const seriesIv6Ref = useRef(null);
  const seriesOi1Ref = useRef(null);
  const seriesOi2Ref = useRef(null);
  const seriesOi3Ref = useRef(null);
  const seriesOi4Ref = useRef(null);
  const seriesOi5Ref = useRef(null);
  const seriesOi6Ref = useRef(null);
  const seriesStraddleOi1Ref = useRef(null);
  const seriesStraddleOi2Ref = useRef(null);
  const seriesStraddleOi3Ref = useRef(null);
  const candlestickSeriesRef = useRef(null);
  const seriesWcashRef = useRef(null);
  const seriesWcashOtmRef = useRef(null);
  const seriesWcashPeRef = useRef(null);
  const seriesWcashCeRef = useRef(null);
  const seriesWcashPeOtmRef = useRef(null);
  const seriesWcashCeOtmRef = useRef(null);
  const seriesVixRef = useRef(null);
  const seriesPremRef = useRef(null);

  const seriesRefList_ref = useRef(null);

  const { auth } = useAuth();
  const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
  const[nDays,setnDays]=useState(props.nDays);
  const [startDate,setStartDate]=useState(props.startDate!=""? dayjs(props.startDate,DateFormat):"");
  const[plotType,setPlotType]=useState(props.plotType);
  const [expiry,setExpiry]=useState(props.expiry);
  // console.log("props", props)
  const [strikes,setStrikes]=useState(props.activeStrikes && props.activeStrikes[props.symbol]);
  const [selectedStrikeList,setSelectedStrikeList]=useState(props.selectedStrikeList);
  // console.log(strikes)
  // console.log("activeStrikes", props.activeStrikes)
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  
  const { open, msg, severity } = msgState;
  
  let seriesRefList = [];
  let seriesList = [];

  // const theme = useTheme();
  const navigate = useNavigate();

  // console.log("CONTAINER BEGINS")
  // console.log(props)

  useEffect(()=>{
    setStrikes(props.activeStrikes && props.activeStrikes[props.symbol]);
  },[props.symbol])

  
  useLayoutEffect(() => {
    // if(props.changePlotTypeNew){
    //   console.log("Plottype in Snake container=",props.params.plot)
    //   props.changePlotTypeNew(props.params.plot);
    // }

    if(props.isError)
    return;
    

    let root = am5.Root.new(props.id, {
      // useSafeResolution: false  TODO: itest incase charts break on iOS device uncomment it
    });

    root.setThemes([dark_theme.new(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        // wheelX: "zoomX",
        wheelZoomPositionX: 1,
        pinchZoomX: true,
        pinchZoomY: true,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        layout: root.verticalLayout,
        // fill: am5.color("#132d5a"),
      
      })
    );

    chart.set("background", am5.Rectangle.new(root, {
      // stroke: am5.color(0x297373),
      // strokeOpacity: 1,
      // fill: am5.color("#132d5a"),
      // fillOpacity: 0.5  
    }));
    chart.plotContainer.get("background").setAll({
      // stroke: am5.color(0x297373),
      // strokeOpacity: 0.5,
      // fill: am5.color("#132d5a"),
      // fillOpacity: 1
    });

    //Refere for zoom and pan https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/

    // uncomment the below line is to hide the chart zoom button
    // chart.zoomOutButton.set("forceHidden", true); 

    // add scrollbar to control
    chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal"
    }));

    let rendrerDateTime = am5xy.AxisRendererX.new(root, {
      opposite: true
    });
    rendrerDateTime.grid.template.setAll({
      location: 0,
      disabled: true,
    });
    rendrerDateTime.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    });
    rendrerDateTime.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99
    });

    //create date axis 
    let dateTimeAxis = am5xy.GaplessDateAxis.new(root, {
      baseInterval: { timeUnit: "minute", count: 1 },
      dateFormats: {
        "minute": "d MMM, HH:mm",
        "hour": "d MMM, HH:mm",
        "day": "d MMM"
      },
      markUnitChange: false,
      tooltipDateFormat: "d MMM, HH:mm",
      minHeight: 30,
      groupData: true,
      groupCount: 400,
      minZoomCount: 1,
      groupIntervals: [
        { timeUnit: "minute", count: 1 },
        { timeUnit: "minute", count: 5 },
        { timeUnit: "minute", count: 15 },
        // { timeUnit: "minute", count: 30 },
        // { timeUnit: "hour", count: 1 },
      ],
      renderer: rendrerDateTime
    });

    //push the datetime axis in the chart
    let xAxis = chart.xAxes.push(
      dateTimeAxis
    );

    // let tooltip = am5.Tooltip.new(root, {
    //   getFillFromSprite: false,
    //   getStrokeFromSprite: true,
    //   autoTextColor: false,
    //   getLabelFillFromSprite: true,
    //   // labelText: "{name}: {valueY}"
    // });
    
    // tooltip.get("background").setAll({
    //   fill: am5.color(0xffffff),
    //   fillOpacity: 0.4,
    // });

    // % height of each panel
    let y1, y2;
    [y1, y2] = [60, 40];
    if(props.plotParams.get("plot") === "" || props.plotParams.get("plot") === "pair_price")
      [y1, y2] = [100, 0];
    else if(props.plotParams.get("plot") === "synth_fut")
      [y1, y2] = [80, 20];

    let valueAxisLhs1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        height: am5.percent(y1),
        renderer: am5xy.AxisRendererY.new(root, {
        }),
      })
    );
    
    let valueAxisLhs2, valueAxisRhs2;
    if(props.plotParams.get("plot") !== "pair_price") {
      valueAxisLhs2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y2),
          renderer: am5xy.AxisRendererY.new(root, {
          }),
        })
      );

      valueAxisRhs2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y2),
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        })
      );
    }

    let valueAxisRhs1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // syncWithAxis: valueAxisLhs1,
        height: am5.percent(y1),
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    );

    // let valueAxisFuturesRenderer = valueAxisRhs1.get("renderer");
    // valueAxisFuturesRenderer.labels.template.setAll({
    //   paddingLeft: 2,
    //   paddingTop: 2,
    //   paddingBottom: 2,
    //   paddingRight: 5,
    //   verticalCenter: "bottom",
    //   fontSize: "0.8em",
    //   minPosition: 0.01,
    //   maxPosition: 0.99
    // });
    
    let seriesVix, seriesPrem, candlestickSeries, seriesWcash, seriesWcashOtm, seriesWcashPe, seriesWcashCe, seriesWcashPeOtm, seriesWcashCeOtm;
    if(props.plotParams.get("plot") === "" || props.plotParams.get("plot") === "pair_price") {
      seriesRefList = [seriesStraddle1Ref, seriesStraddle2Ref, seriesStraddle3Ref, seriesStraddle4Ref, seriesStraddle5Ref];
      let colorList = ["#F58634", "#FEC35D", "#A8CF45", "#07ABE3", "#186AB2"];
      let n = 2; // can be 1 max

      let selectedStrikes = props.plotParams.get("selectedStrikes");
      if(selectedStrikes === "")
        selectedStrikes = props.selectedStrikes;

      if(selectedStrikes === "") {
        let midStrike = parseInt(props.plotParams.get("midStrike"));
        let jump = parseInt(props.plotParams.get("jump"));
        
        for(let i=-1*n; i<=n; i++) {
          let namestr = (midStrike + (i * jump)).toString();
          let seriesStraddle = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: namestr,
              xAxis: xAxis,
              yAxis: valueAxisRhs1,
              valueYField: namestr+"_close",
              valueXField: "time",
              stroke: am5.color(colorList[i+n]),
              tooltip: am5.Tooltip.new(root, {labelText: "{name}: [bold]{valueY}[/]"}),
            })
          );
          seriesList.push(seriesStraddle);
        }
      }  else {
        let strikeList = selectedStrikes.split("_");
        console.log(strikeList);
        if(strikeList.length <= 5) {
          for(let i=0; i<strikeList.length; i++) {
            let seriesStraddle = chart.series.push(
              am5xy.LineSeries.new(root, {
                name: strikeList[i],
                xAxis: xAxis,
                yAxis: valueAxisRhs1,
                valueYField: strikeList[i]+"_close",
                valueXField: "time",
                stroke: am5.color(colorList[i]),
                tooltip: am5.Tooltip.new(root, {labelText: "{name}: [bold]{valueY}[/]"}),
              })
            );
            seriesList.push(seriesStraddle);
          }
        }
      }

      seriesVix = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "VIX",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "vix_close",
          valueXField: "time",
          stroke: am5.color("#FFFFFF"),
          tooltip: am5.Tooltip.new(root, {labelText: "VIX: [bold]{valueY}[/]"}),
        })
      );

      // seriesVix.strokes.template.setAll({
      //   strokeWidth: 2,
      // });

      for(const seriesStraddle of seriesList) {
        seriesStraddle.data.processor = am5.DataProcessor.new(root, {
          dateFormat: "dd-MM-yyyy HH:mm",
          dateFields: ["time"]
        });
      }

      seriesVix.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

    } else if(props.plotParams.get("plot") === "synth_fut") {
      seriesRefList = [seriesStraddle1Ref, seriesStraddle2Ref, seriesStraddle3Ref];
      let colorList = ["#FEC35D", "#A8CF45", "#07ABE3"];
      let n = 2; // can be 1 max

      let selectedStrikes = props.plotParams.get("selectedStrikes");
      if(selectedStrikes === "")
        selectedStrikes = props.selectedStrikes;

      let selectedStrike;
      if(selectedStrikes === "") {
        let midStrike = parseInt(props.plotParams.get("midStrike"));
        let jump = parseInt(props.plotParams.get("jump"));
        selectedStrike = midStrike;
        
        for(let i=-1*n; i<=n; i++) {
          let namestr = (midStrike + (i * jump)).toString();
          let seriesStraddle = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: namestr + " SynthFut",
              xAxis: xAxis,
              yAxis: valueAxisRhs1,
              valueYField: namestr+"_synth_fut",
              valueXField: "time",
              stroke: am5.color(colorList[i+n]),
              tooltip: am5.Tooltip.new(root, {labelText: "{name}: [bold]{valueY}[/]"}),
            })
          );
          seriesList.push(seriesStraddle);
        }
      }  else {
        let strikeList = selectedStrikes.split("_");
        console.log(strikeList);
        selectedStrike = strikeList[0];
        if(strikeList.length <= 3) {
          for(let i=0; i<strikeList.length; i++) {
            let seriesStraddle = chart.series.push(
              am5xy.LineSeries.new(root, {
                name: strikeList[i] + " SynthFut",
                xAxis: xAxis,
                yAxis: valueAxisRhs1,
                valueYField: strikeList[i]+"_synth_fut",
                valueXField: "time",
                stroke: am5.color(colorList[i]),
                tooltip: am5.Tooltip.new(root, {labelText: "{name}: [bold]{valueY}[/]"}),
              })
            );
            seriesList.push(seriesStraddle);
          }
        }
      }

      seriesVix = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Spot",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "spot_close",
          valueXField: "time",
          stroke: am5.color("#FFFFFF"),
          tooltip: am5.Tooltip.new(root, {labelText: "Spot: [bold]{valueY}[/]"}),
        })
      );

      seriesVix.strokes.template.setAll({
        strokeWidth: 2,
      });

      for(const seriesStraddle of seriesList) {
        seriesStraddle.data.processor = am5.DataProcessor.new(root, {
          dateFormat: "dd-MM-yyyy HH:mm",
          dateFields: ["time"]
        });
      }

      seriesVix.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

      // seriesPrem = chart.series.push(
      //   am5xy.StepLineSeries.new(root, {
      //     name: selectedStrike + " SynthPrem",
      //     xAxis: xAxis,
      //     yAxis: valueAxisRhs2,
      //     valueYField: selectedStrike + "_synth_fut_prem",
      //     valueXField: "time",
      //     stroke: am5.color("#FEC35D"),
      //     tooltip: am5.Tooltip.new(root, {labelText: "SynthPrem: {valueY}"}),
      //   })
      // );

      seriesPrem = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: selectedStrike + " SynthPrem",
          xAxis: xAxis,
          yAxis: valueAxisRhs2,
          valueYField: selectedStrike + "_synth_fut_prem",
          valueXField: "time",
          valueYGrouped: "extreme",
          fillOpacity: 0.5,
          strokeWidth: 0,
          clustered: false,
          tooltip: am5.Tooltip.new(root, {labelText: "SynthPrem: {valueY}"}),
        })
      );

      // seriesPrem.strokes.template.setAll({
      //   strokeWidth: 2,
      // });

      seriesPrem.columns.template.setAll({
        // fillOpacity: 0.6,
        // strokeWidth: 0,
        width: am5.percent(100)
      });

      seriesPrem.columns.template.adapters.add("fill", function(fill, target) {
        if (target.dataItem && target.dataItem.get("valueY") > 0) {
            return am5.color("#01DF01");  // green
        }
        else {
            return am5.color("#FF0000");  // red
        }
      });

      seriesPrem.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

    } else if(props.plotParams.get("plot") === "iv_wcash") {
      seriesRefList = [seriesIv1Ref, seriesIv2Ref, seriesIv3Ref, seriesIv4Ref, seriesIv5Ref, seriesIv6Ref];
      let peColorList = ["#D96438", "#F89D40", "#FEC35D"]
      let ceColorList = ["#346359", "#5B8558", "#A8CF45"]
      let n = 0; // can be 1 max

      let selectedStrikes = props.plotParams.get("selectedStrikes");
      if(selectedStrikes == "")
        selectedStrikes = props.selectedStrikes;

      if(selectedStrikes == "") {
        let midStrike = parseInt(props.plotParams.get("midStrike"));
        let jump = parseInt(props.plotParams.get("jump"));
        
        for(let i=-1*n; i<=n; i++) {
          let namestr = (midStrike + (i * jump)).toString();
          let seriesIvPe = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: namestr+"_PE",
              xAxis: xAxis,
              yAxis: valueAxisRhs1,
              valueYField: namestr+"_PE_iv",
              valueXField: "time",
              stroke: am5.color(peColorList[i+n]),
              tooltip: am5.Tooltip.new(root, {labelText: "{name}: [bold]{valueY}[/]"}),
            })
          );
          let seriesIvCe = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: namestr+"_CE",
              xAxis: xAxis,
              yAxis: valueAxisRhs1,
              valueYField: namestr+"_CE_iv",
              valueXField: "time",
              stroke: am5.color(ceColorList[i+n]),
              tooltip: am5.Tooltip.new(root, {labelText: "{name}: [bold]{valueY}[/]"}),
            })
          );
          seriesList.push(seriesIvPe);
          seriesList.push(seriesIvCe);
        }
      } else {
        let strikeList = selectedStrikes.split("_");
        if(strikeList.length <= 3) {
          for(let i=0; i<strikeList.length; i++) {
            let seriesIvPe = chart.series.push(
              am5xy.LineSeries.new(root, {
                name: strikeList[i]+"_PE",
                xAxis: xAxis,
                yAxis: valueAxisRhs1,
                valueYField: strikeList[i]+"_PE_iv",
                valueXField: "time",
                stroke: am5.color(peColorList[i]),
                tooltip: am5.Tooltip.new(root, {labelText: "{name}: [bold]{valueY}[/]"}),
              })
            );
            let seriesIvCe = chart.series.push(
              am5xy.LineSeries.new(root, {
                name: strikeList[i]+"_CE",
                xAxis: xAxis,
                yAxis: valueAxisRhs1,
                valueYField: strikeList[i]+"_CE_iv",
                valueXField: "time",
                stroke: am5.color(ceColorList[i]),
                tooltip: am5.Tooltip.new(root, {labelText: "{name}: [bold]{valueY}[/]"}),
              })
            );
            seriesList.push(seriesIvPe);
            seriesList.push(seriesIvCe);
          }
        }
      }

      candlestickSeries = chart.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: "Price",
          clustered: false,
          xAxis: xAxis,
          yAxis: valueAxisRhs2,
          openValueYField: "open",
          highValueYField: "high",
          lowValueYField: "low",
          valueYField: "close",
          valueXField: "time",
          // tooltip: am5.Tooltip.new(root, {})
        })
      );
  
      candlestickSeries.columns.template.states.create("riseFromOpen", {
        fill: am5.color(0x76b041),
        stroke: am5.color(0x76b041)
      });
      candlestickSeries.columns.template.states.create("dropFromOpen", {
        fill: am5.color(0xe4572e),
        stroke: am5.color(0xe4572e)
      });
  
      // candlestickSeries.get("tooltip").label.set("text", "Close: {valueY}");
      // candlestickSeries.get("tooltip").label.set("text", "Open: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")

      seriesVix = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "VIX",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "vix_close",
          valueXField: "time",
          stroke: am5.color("#FFFFFF"),
          tooltip: am5.Tooltip.new(root, {labelText: "VIX: [bold]{valueY}[/]"}),
        })
      );

      // seriesVix.strokes.template.setAll({
      //   strokeWidth: 2,
      // });

      seriesWcash = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "WCASH",
          xAxis: xAxis,
          yAxis: valueAxisLhs2,
          valueYField: "total_wcash",
          valueXField: "time",
          stroke: am5.color("#FFFFFF"),
          tooltip: am5.Tooltip.new(root, {labelText: "WCASH: {valueY} crores"}),
        })
      );

      seriesWcash.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesWcashOtm = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "OTM_WCASH",
          xAxis: xAxis,
          yAxis: valueAxisLhs2,
          valueYField: "total_otm_wcash",
          valueXField: "time",
          stroke: am5.color("#FFFFFF"),
          tooltip: am5.Tooltip.new(root, {labelText: "OTM_WCASH: {valueY} crores"}),
        })
      );

      seriesWcashOtm.strokes.template.setAll({
        strokeWidth: 2,
        strokeDasharray: [5, 3],
      });

      seriesWcashPe = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "PUT_WCASH",
          xAxis: xAxis,
          yAxis: valueAxisLhs2,
          valueYField: "put_wcash",
          valueXField: "time",
          stroke: am5.color("#FF0000"),
          tooltip: am5.Tooltip.new(root, {labelText: "PUT_WCASH: {valueY} crores"}),
        })
      );

      seriesWcashCe = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "CALL_WCASH",
          xAxis: xAxis,
          yAxis: valueAxisLhs2,
          valueYField: "call_wcash",
          valueXField: "time",
          stroke: am5.color("#128E0B"),
          tooltip: am5.Tooltip.new(root, {labelText: "CALL_WCASH: {valueY} crores"}),
        })
      );

      seriesWcashPeOtm = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "OTM_PUT_WCASH",
          xAxis: xAxis,
          yAxis: valueAxisLhs2,
          valueYField: "put_otm_wcash",
          valueXField: "time",
          stroke: am5.color("#FF0000"),
          tooltip: am5.Tooltip.new(root, {labelText: "OTM_PUT_WCASH: {valueY} crores"}),
        })
      );

      seriesWcashPeOtm.strokes.template.setAll({
        strokeDasharray: [5, 3],
      });

      seriesWcashCeOtm = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "OTM_CALL_WCASH",
          xAxis: xAxis,
          yAxis: valueAxisLhs2,
          valueYField: "call_otm_wcash",
          valueXField: "time",
          stroke: am5.color("#128E0B"),
          tooltip: am5.Tooltip.new(root, {labelText: "OTM_CALL_WCASH: {valueY} crores"}),
        })
      );

      seriesWcashCeOtm.strokes.template.setAll({
        strokeDasharray: [5, 3],
      });

      for(const seriesIv of seriesList) {
        seriesIv.data.processor = am5.DataProcessor.new(root, {
          dateFormat: "dd-MM-yyyy HH:mm",
          dateFields: ["time"]
        });
      }

      seriesVix.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
  
      candlestickSeries.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

      seriesWcash.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesWcashOtm.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesWcashPe.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesWcashCe.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesWcashPeOtm.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesWcashCeOtm.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

    } else if(props.plotParams.get("plot") === "oi") {
      seriesRefList = [seriesOi1Ref, seriesOi2Ref, seriesStraddleOi1Ref, seriesOi3Ref, seriesOi4Ref, seriesStraddleOi2Ref, seriesOi5Ref, seriesOi6Ref, seriesStraddleOi3Ref];
      let peColorList = ["#D96438", "#F89D40", "#FEC35D"]
      let ceColorList = ["#346359", "#5B8558", "#A8CF45"]
      let colorList = ["#F58634", "#FEC35D", "#A8CF45"];
      let n = 1; // can be 1 max

      let selectedStrikes = props.plotParams.get("selectedStrikes");
      if(selectedStrikes == "")
        selectedStrikes = props.selectedStrikes;

      if(selectedStrikes == "") {
        let midStrike = parseInt(props.plotParams.get("midStrike"));
        let jump = parseInt(props.plotParams.get("jump"));
        
        for(let i=-1*n; i<=n; i++) {
          let namestr = (midStrike + (i * jump)).toString();
          let seriesOiPe = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: namestr+"_PE",
              xAxis: xAxis,
              yAxis: valueAxisRhs1,
              valueYField: namestr+"_PE_oi",
              valueXField: "time",
              stroke: am5.color(peColorList[i+n]),
              tooltip: am5.Tooltip.new(root, {labelText: "{name}: {valueY}"}),
            })
          );
          let seriesOiCe = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: namestr+"_CE",
              xAxis: xAxis,
              yAxis: valueAxisRhs1,
              valueYField: namestr+"_CE_oi",
              valueXField: "time",
              stroke: am5.color(ceColorList[i+n]),
              tooltip: am5.Tooltip.new(root, {labelText: "{name}: {valueY}"}),
            })
          );
          let seriesOiStraddle = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: namestr,
              xAxis: xAxis,
              yAxis: valueAxisRhs2,
              valueYField: namestr+"_oi",
              valueXField: "time",
              stroke: am5.color(colorList[i+n]),
              tooltip: am5.Tooltip.new(root, {labelText: "{name}: {valueY}"}),
            })
          );
          seriesList.push(seriesOiPe);
          seriesList.push(seriesOiCe);
          seriesList.push(seriesOiStraddle);
        }
      } else {
        let strikeList = selectedStrikes.split("_");
        if(strikeList.length <= 3) {
          for(let i=0; i<strikeList.length; i++) {
            let seriesOiPe = chart.series.push(
              am5xy.LineSeries.new(root, {
                name: strikeList[i]+"_PE",
                xAxis: xAxis,
                yAxis: valueAxisRhs1,
                valueYField: strikeList[i]+"_PE_oi",
                valueXField: "time",
                stroke: am5.color(peColorList[i]),
                tooltip: am5.Tooltip.new(root, {labelText: "{name}: {valueY}"}),
              })
            );
            let seriesOiCe = chart.series.push(
              am5xy.LineSeries.new(root, {
                name: strikeList[i]+"_CE",
                xAxis: xAxis,
                yAxis: valueAxisRhs1,
                valueYField: strikeList[i]+"_CE_oi",
                valueXField: "time",
                stroke: am5.color(ceColorList[i]),
                tooltip: am5.Tooltip.new(root, {labelText: "{name}: {valueY}"}),
              })
            );
            let seriesOiStraddle = chart.series.push(
              am5xy.LineSeries.new(root, {
                name: strikeList[i],
                xAxis: xAxis,
                yAxis: valueAxisRhs2,
                valueYField: strikeList[i]+"_oi",
                valueXField: "time",
                stroke: am5.color(colorList[i]),
                tooltip: am5.Tooltip.new(root, {labelText: "{name}: {valueY}"}),
              })
            );
            seriesList.push(seriesOiPe);
            seriesList.push(seriesOiCe);
            seriesList.push(seriesOiStraddle);
          }
        }
      }
      seriesVix = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "VIX",
          xAxis: xAxis,
          yAxis: valueAxisLhs2,
          valueYField: "vix_close",
          valueXField: "time",
          stroke: am5.color("#FFFFFF"),
          tooltip: am5.Tooltip.new(root, {labelText: "VIX: [bold]{valueY}[/]"}),
        })
      );

      // seriesVix.strokes.template.setAll({
      //   strokeWidth: 2,
      // });

      for(const seriesOi of seriesList) {
        seriesOi.data.processor = am5.DataProcessor.new(root, {
          dateFormat: "dd-MM-yyyy HH:mm",
          dateFields: ["time"]
        });
      }

      seriesVix.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

    }

    // Stack Y-axes
    chart.leftAxesContainer.set("layout", root.verticalLayout);
    chart.rightAxesContainer.set("layout", root.verticalLayout);

    // console.log("list", seriesList)
    // console.log("ref list", seriesRefList)
    for(let i=0; i<seriesList.length; i++) {
      seriesRefList[i].current = seriesList[i];
    }

    seriesVixRef.current = seriesVix;
    seriesPremRef.current = seriesPrem;
    // seriesOIRef.current = seriesOI;
    if(props.plotParams.get("plot") === "iv_wcash") {
      candlestickSeriesRef.current = candlestickSeries;
      seriesWcashRef.current = seriesWcash;
      seriesWcashOtmRef.current = seriesWcashOtm;
      seriesWcashPeRef.current = seriesWcashPe;
      seriesWcashCeRef.current = seriesWcashCe;
      seriesWcashPeOtmRef.current = seriesWcashPeOtm;
      seriesWcashCeOtmRef.current = seriesWcashCeOtm;
    }

    seriesRefList_ref.current = seriesRefList;
    
    //create chart legend
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50)
    }));
    legend.data.setAll(chart.series.values);

    // //create chart title
    // chart.children.unshift(am5.Label.new(root, {
    //   text: "NIFTY Futures Line Chart",
    //   fontSize: 24,
    //   fontWeight: "500",
    //   textAlign: "center",
    //   x: am5.percent(50),
    //   centerX: am5.percent(50),
    //   paddingTop: 0,
    //   paddingBottom: 4
    // }));

    //add watermark text
    chart.plotContainer.children.push(am5.Label.new(root, {
      text: WATERMARK_CONFIG.LC_TEXT,
      y: am5.percent(100),
      centerY: am5.percent(100),
      opacity: 0.3,
      marginLeft: 10,
      marginBottom: 10,
      fontSize: 24,
      fontWeight: "400",
    }));

    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#000000"),
      strokeOpacity: 0.5,
      fill: am5.color("#000000"),
      fillOpacity: 0.7
    });

    // Add cursor for date time x axis
    chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomXY",
      xAxis: xAxis
    }));

    //add tooltip for date time x axis
    xAxis.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    valueAxisRhs1.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    valueAxisLhs1.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    if(props.plotParams.get("plot") !== "pair_price") {
      valueAxisRhs2.set("tooltip", am5.Tooltip.new(root, {
        themeTags: ["axis"]
      }));

      valueAxisLhs2.set("tooltip", am5.Tooltip.new(root, {
        themeTags: ["axis"]
      }));
    }

    return () => {
      root.dispose();
    };

  }, []);

  //this will handle the props data change and set/update the chart
  useLayoutEffect(() => {
    // console.log("uselayout effect child ===========>>>>>>>>>>>>>>>");
    //1st load
    if(props.isError)
    return;

    if (props.isIncrementalData && props.chartData != undefined) {
      console.log("incremental data updates");
      // console.log("list", seriesList)
      // console.log("ref list", seriesRefList)
      // console.log("ref list", seriesRefList_ref.current)
      for(let i=0; i<seriesRefList.length; i++) {
        seriesRefList[i].current?.data.pushAll(props.chartData);
      }
      if(seriesRefList.length > 0)
        seriesRefList_ref.current = seriesRefList;
      else{
        for(let i=0; i<seriesRefList_ref.current?.length; i++) {
          seriesRefList_ref.current[i].current?.data.pushAll(props.chartData);
        }
      }
      
      if(props.plotParams.get("plot") === "iv_wcash") {
        candlestickSeriesRef.current.data.pushAll(props.chartData);
        seriesWcashRef.current.data.pushAll(props.chartData);
        seriesWcashOtmRef.current.data.pushAll(props.chartData);
        seriesWcashPeRef.current.data.pushAll(props.chartData);
        seriesWcashCeRef.current.data.pushAll(props.chartData);
        seriesWcashPeOtmRef.current.data.pushAll(props.chartData);
        seriesWcashCeOtmRef.current.data.pushAll(props.chartData);
      }
      seriesVixRef.current?.data.pushAll(props.chartData);
      seriesPremRef.current?.data.pushAll(props.chartData);
      
      // if(props.plotParams.get("oiPlot") !== "") {
      //   seriesOIRef.current.data.pushAll(props.chartData);
      //   seriesVolRef.current.data.pushAll(props.chartData);
      // }
      
    } else {
      console.log("initial chart load");
      // console.log("ref list", seriesRefList_ref)
      for(let i=0; i<seriesRefList.length; i++) {
        seriesRefList[i].current?.data.setAll(props.chartData);
      }
      if(seriesRefList.length > 0)
        seriesRefList_ref.current = seriesRefList;
      else{
        for(let i=0; i<seriesRefList_ref.current?.length; i++) {
          seriesRefList_ref.current[i].current?.data.setAll(props.chartData);
        }
      }

      if(props.plotParams.get("plot") === "iv_wcash") {
        candlestickSeriesRef.current.data.setAll(props.chartData);
        seriesWcashRef.current.data.setAll(props.chartData);
        seriesWcashOtmRef.current.data.setAll(props.chartData);
        seriesWcashPeRef.current.data.setAll(props.chartData);
        seriesWcashCeRef.current.data.setAll(props.chartData);
        seriesWcashPeOtmRef.current.data.setAll(props.chartData);
        seriesWcashCeOtmRef.current.data.setAll(props.chartData);
      }
      seriesVixRef.current?.data.setAll(props.chartData);
      seriesPremRef.current?.data.setAll(props.chartData);

      // if(props.plotParams.get("oiPlot") !== "") {
      //   seriesOIRef.current.data.setAll(props.chartData);
      //   seriesVolRef.current.data.setAll(props.chartData);
      // }
      
    }

  }, [props.plotParams, props.chartData, props.isIncrementalData]);


  function toggleFullScreen() {
    let chartdiv = document.querySelector('#chartdiv')
    if (!document.fullscreenElement) {
      chartdiv.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  const handlenDaysChange=(event)=>{
    setnDays(event.target.value);
    props.changenDays(event.target.value);
  }
  
  const disabledDate=(current)=> {
    return (current && current >= dayjs().endOf('day'))||dayjs(current).day()==6 || dayjs(current).day()==0
  }
  const onDateChange = (date, dateString) => {
    setStartDate(dayjs(date))
    console.log("Date changed",dayjs(date),dateString);
    props.handleStartDateChange(dateString);
  }

  const handlePlotTypeChange=(event)=>{
    setPlotType(event.target.value);
    props.changePlotType(event.target.value);
  }
  
  const handleExpiryChange=(event)=>{
    setExpiry(event.target.value);
    props.handleExpiryChange(event.target.value);
  }

  const handleStrikeCheckbox=(event)=>{
    console.log('value:' + event.target.value + ' checked: ' + event.target.checked);
    if(event.target.checked){
      if(selectedStrikeList.length>2){
        //show msg
        setMsgState({open:true,msg:TEXT_MSGS.MAX_ALLOWED_STRIKES,severity:"info"});
      }
      else{
        let updatedData= [...selectedStrikeList];
        updatedData.push(event.target.value);
        console.log("updatedData",updatedData);
        setSelectedStrikeList(updatedData);
        props.handleSelectedStrikesChange(updatedData);
      }

    }else{
      let updatedData= selectedStrikeList.filter(item => item!=event.target.value);
      setSelectedStrikeList(updatedData);
      props.handleSelectedStrikesChange(updatedData);
    }

  }

  // useEffect(()=>{
  //   console.log("selectedStrikeList",selectedStrikeList,selectedStrikeList.includes(18000),selectedStrikeList.includes("18000"),selectedStrikeList.includes(strikes[5].toString()));
  // },[selectedStrikeList])

  /**
   * autoclosing of the snackbar msg bar 
   */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
  };



  return (
    <>
    <div id="container" style={{ backgroundColor: "#111111", zIndex: "99", marginTop: "0px", padding: "20px", width: "100%",minWidth:`${MIN_SNAKE_CHART_WIDTH}`, minHeight: "calc(110vh - 256px)" }}>
      {/* <div id="container" style={{ backgroundColor: "#111111", zIndex: "99", marginTop: "0px", padding: "20px", width: "100%", minHeight: "calc(110vh - 256px)" }}> */}
        <div id="chartContainer">
        {!isBasicUser?
          <>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4}}>
         {/* <Typography sx={{mt:1,mr:0,fontSize:16,width:"100px"}}>No. of days</Typography> */}
         
        <FormControl size="small" sx={{ width:"100px",minWidth:'100px',ml:0,mt:0 }}>
        <InputLabel id="demo-simple-select-label">No. Of Days</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={nDays}
          MenuProps={MenuProps}
          label="No. of days"
          sx={{height:"40px"}}
          onChange={(event)=>handlenDaysChange(event)}
        >
          {NO_OF_DAYS.map((item,index) => (
          <MenuItem value={item.value}>{item.key}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* <Typography sx={{mt:1,ml:3,fontSize:16,width:"50px"}}>Expiry</Typography> */}
        
        {props.expiryList != undefined ? 
        <>
        <FormControl size="small" sx={{ width:"80px",minWidth:'120px',ml:3,mt:0 }}>
        <InputLabel id="demo-simple-select-label">Expiry</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={expiry}
          label="Expiry"
          MenuProps={MenuProps}
          sx={{height:"40px"}}
          onChange={(event)=>handleExpiryChange(event)}
        >
          {props.expiryList.map((item,index) => (
          <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
      </> : <></> }
      <DatePicker onChange={onDateChange} 
            allowClear={false}
            disabledDate={disabledDate}
            value={startDate}
            placeholder="Select Date"
            format={DateFormat}
            style={{border: "1px solid #484848",background:"#111111",
            borderRadius: "4px",width:160,marginLeft:24,marginTop:"0px"}}
            /> 
        {/* <Typography sx={{mt:1,ml:2,fontSize:16,width:"90px"}}>Plot Type</Typography> */}
        
        <FormControl size="small" sx={{ width:"180px",minWidth:'220px',ml:3,mt:0 }}>
        <InputLabel id="demo-simple-select-label">Plot Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={plotType}
          label="Plot Type"
          MenuProps={MenuProps}
          sx={{height:"40px"}}
          onChange={(event)=>handlePlotTypeChange(event)}
        >
          {SNAKE_CHART_PLOT_TYPE.map((item,index) => (
          <MenuItem value={item.value}>{item.key}</MenuItem>
          ))}
        </Select>
      </FormControl>    
      </Box>
      <Box display="flex" sx={{pl:2,pt:1}}>
      <Typography sx={{mt:1,ml:2,fontSize:16}}>Selected Strikes (Max. 3)</Typography>
      {strikes.map((strike,index) => (
            //Store the the student id in the value of each check box
           <>
              <Checkbox
                // label={"strike"}
                value={strike}
                onChange={(event)=>handleStrikeCheckbox(event)}
                checked={selectedStrikeList.includes(strike.toString())}
                iconStyle={{
                  fill: '#ffb400', //hex color values (yellow)
                }}
              />
              <Typography sx={{mr:1,marginTop:"10px"}}>{strike}</Typography>
              </>
            
          ))}
      </Box>
          </>
          :<></>}
          {!props.isError?
          <>
            <div id="chartcontrols" style={{ padding: "10px", marginTop: "16px", maxWidth: "100%" }}></div>
            <div id={props.id} style={{ width: "100%", maxWidth: "100%", height: "900px" }} />
          </>
          :
          <></>
          }
        </div>
      </div>
      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
    </>
  )
}

export default OptSnakesLineChartContainer

