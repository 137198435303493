import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

export default function SavePositionDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const [posName, setPosName] = React.useState("");
  const [prefix,setPrefix]=React.useState(props.name);
  

  const handleClose = () => {
    // setOpen(false);
    props.cancelHandler();
  };

  const handleSave = () => {
    // setOpen(true);
    console.log("handleSave=",posName);
    props.saveHandler(prefix+posName);
   
  };

 
  const handlePosChange=(event)=>{
    setPosName(event.target.value)
   
  }
  return (
    <div>
     
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Save Position</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* To subscribe to this website, please enter your email address here.
            We will send updates occasionally. */}
          </DialogContentText>
          <TextField
            autoFocus
            required
            variant="outlined"
            value={posName}
            sx={{mt:4,minWidth:300}}
            onChange={(event) => handlePosChange(event)}
            // margin="dense"
            id="name"
            label="Position Name"
            error={posName === ""}
            helperText={posName === "" ? 'Position name is required' : ' '}
            // fullWidth
            
          />
          <Typography sx={{mt:1,fontSize:14}}>
            Will be saved as: {prefix}{posName}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button sx={{mr:2}}onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
