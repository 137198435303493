import React from 'react'
import { useEffect,useState,useMemo,useCallback,useRef,useContext } from 'react'
import { URL } from '../../../common/api/urls';
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import useAuth from '../../../common/hooks/useAuth';
import { USER_ROLE_FIELDS,CHART_RELOAD_TIME } from '../../../common/utility/constant';
import { CircularProgress } from '@mui/material';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../../common/utility/constant';
import {Snackbar, Alert} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import {Tab,Tabs} from '@mui/material';

import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { getColumneDef,InstrumentRenderer
} from '../../../common/utility/idxTicker_columdDefs';
import '../styles/options.scss';
import { processIdxTicker } from '../../../common/utility/preProcessor';
import { UserSettingsContext } from '../../../setup/routes-manager/RequireAuth'
import { INDEX_TICKER_COLUMNS_LIST } from '../../../common/utility/constant';
import SubscriptionPopup from './subscription_popup';
import TickerSettings from './ticker_settings';

const ROW_HEIGHT=46;
const HEADER_HEIGHT=44;
const GROUP_HEADER_HEIGHT=30;
// import { CallCheckBoxRenderer,PutCheckBoxRenderer } from './components/options_table_renderer';
const gridOptions = {
  suppressColumnVirtualisation:true,
  suppressMovableColumns:true,
  suppressColumnMoveAnimation:true,
  suppressRowVirtualisation:true,
  enableCellTextSelection:true,
  ensureDomOrder:true
}


const frameworkComponents= {
  instrumentComponent: InstrumentRenderer,
} 
function TICKER_TABLE(props) {
  const navigate = useNavigate(); 
  const from = "/login";
  const { auth } = useAuth();
  const [showPopup,setShowPopup]=useState(false);
  const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
  const [cancelLive,responseDataLive,errorLive,loadedLive,resetLive,executeLive] = useAxiosWithAuth();
  const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
  const [gridAPIOptions,setGridAPIOptions]=useState();
  const [columnAPIOptions,setColumnAPIOptions]=useState();
  const gridRef = useRef();
  const [idxTickerState,setIdxTickerState]=useState({
    idxTickeHeight:2*ROW_HEIGHT,
    idxTickerData:undefined,
    idxLoaded:false,
    isInstrumentLive:false,
    reloadInerval:(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?CHART_RELOAD_TIME.TICKER_DATA_FAST:CHART_RELOAD_TIME.SLOW_DATA,
    pollingInterval:CHART_RELOAD_TIME.POLLING_TIME,
    refreshInterval:-1,
    isPollingAllowed:false,
    isProUser:auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA)?true:false,
    isTradingSessionActive:false,
    isStatusCheckAllowed:false,
    statusCheckPollingInterval:CHART_RELOAD_TIME.POLLING_TIME,
    columns:undefined,

  });
  const {isProUser,idxTickerData,idxLoaded,isInstrumentLive,reloadInerval,pollingInterval,refreshInterval,isPollingAllowed,idxTickeHeight,
    isTradingSessionActive,isStatusCheckAllowed,statusCheckPollingInterval}=idxTickerState;
  
  const idxTickerStateRef=useRef(idxTickerState);
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [showSettings,setShowSettings]=useState(false);

 
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  
  const { open, msg, severity } = msgState;
  
  const openMPChart=(symbol)=>{
    window.open(`${process.env.REACT_APP_URL}market-profile?symbol=${symbol}`, '_blank', 'noreferrer');
  }   
  
  const openTcikerSettings=()=>{
    console.log("OPENSETTINGS TICKER")
    openSettings();
  
}

  

  const openSettings=()=>{
    setShowSettings(true);
    console.log("COOKIE=",document.cookie);
   }
  
   const cancelSettings=()=>{
    setShowSettings(false);
   }
  
   const saveSettings=(settings)=>{
    setShowSettings(false);
    console.log("INDEXSettings save settings in chart container",settings);
    setUserSettings(settings);
   }

   const [columnDefs,setColumnDefs] = useState(getColumneDef(auth,openMPChart,userSettings.data.page_wise_hidden_columns.INDEX_TICKER,openTcikerSettings))
  useEffect(()=>{
    executeAPI(URL.IDX_TICKER,"POST",{});
  },[]);
  
  useEffect(()=>{
    // setIdxTickerState((prevState) => ({...prevState,idxLoaded:false}));  
    let newColDefs=getColumneDef(auth,openMPChart,userSettings.data.page_wise_hidden_columns.INDEX_TICKER,openTcikerSettings);
    console.log("INDEXSETTINGS useEffect userSettings========>",userSettings,newColDefs,columnDefs); 
    setColumnDefs(newColDefs);
    // setIdxTickerState((prevState) => ({...prevState,idxLoaded:true}));  
  },[userSettings]);

  useEffect(()=>{
    console.log("useEffect idxTiccker state=",idxTickerData)
    // idxTickerStateRef.current=idxTickerState;
    if(gridRef!=undefined && gridRef.current!=undefined && gridRef.current.api!=undefined){
    let params = {
        force: true,
        suppressFlash: false,
        // rowNodes: changedRow,
      }; 
      gridRef.current.api.refreshCells(params);
    }
    
  },[idxTickerState])

/**
 * API response handler  Idx Ticker 
 */  
    useEffect(() => {
      if(loaded){
        console.log("idxTicker loaded=true",responseData);
        if(responseData!=null){
          const processedData=processIdxTicker(responseData);
          let idxHeight=processedData.length*ROW_HEIGHT+HEADER_HEIGHT+GROUP_HEADER_HEIGHT+8;
          console.log(props);
          props.setTickerHeight(idxHeight+30);
          console.log("processIdxTicker ht,data=",idxHeight,processedData)
          
          let updateInterval=pollingInterval;
          if(responseData.isTradingSessionActive!=undefined && responseData.isTradingSessionActive){
            updateInterval=reloadInerval;
          }
          setIdxTickerState((prevState) => ({...prevState,
            refreshInterval:updateInterval,
            isPollingAllowed:true,
            idxTickeHeight:idxHeight,
            idxLoaded:true,idxTickerData:processedData,
            isTradingSessionActive:responseData.isTradingSessionActive,
            isStatusCheckAllowed:(!responseData.isTradingSessionActive),
            statusCheckPollingInterval:(responseData.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
            isInstrumentLive:responseData.isInstrumentLive}));            
        }
        else if(error!==null){
          setIdxTickerState((prevState) => ({
            ...prevState,
            idxLoaded:true}));

          console.log("Error data=",error);

            setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
            //if unauthorized then redirec it to login page
            if(error?.response?.status === 401 || error?.response?.status === 403){
              console.log("status received =",error?.response?.status)
              navigate(from, { replace: true });
            }
        }
        reset();
      }
      },[loaded,responseData]);
  
    //live data polling
    //polling or live chart update handler
    useEffect(() => {
      //set the ref to current state
      idxTickerStateRef.current = idxTickerState;
      // console.log("Live use effect called=",idxTickerStateRef.current.refreshInterval);
      const timeout = setInterval(() => {
        // if(idxTickerStateRef.current.isPollingAllowed && idxTickerStateRef.current.isTradingSessionActive){
        if(idxTickerStateRef.current.isPollingAllowed){
            console.log("--- calling IDX TICKER ----", idxTickerStateRef.current.refreshInterval)
            executeLive(URL.IDX_TICKER,"POST",{});
          }
        }, idxTickerStateRef.current.refreshInterval);
       
      //market status polling
     const timeoutStatusCheck=setInterval(() => {
      if(idxTickerStateRef.current.isStatusCheckAllowed && !idxTickerStateRef.current.isTradingSessionActive)   {
        console.log("--- calling STATUS API ----", idxTickerStateRef.current.statusCheckPollingInterval)  
        executeAPIStatus(URL.CHART_STATUS,"POST",{symbol:"NIFTY"});
      }
      },idxTickerStateRef.current.statusCheckPollingInterval);   
      
      return () => {
        // just to clear the timeout when component unmounts
         clearInterval(timeout);
         clearInterval(timeoutStatusCheck);
      };
    }, [idxTickerState]);

 /**
 * Market live status check response handler
 */  
useEffect(() => {
  if(loadedStatus){
    if(responseDataStatus!=null){
              //if instrument is live then load the live data else set the status as per the response
              setShowPopup(false);
              if(responseDataStatus.isTradingSessionActive!=undefined && responseDataStatus.isTradingSessionActive){
                console.log("====== idx ticker =====")
                executeLive(URL.IDX_TICKER,"POST",{});
              }else{
                setIdxTickerState((prevState) => ({
                  ...prevState,
                  isStatusCheckAllowed:(!responseDataStatus.isTradingSessionActive),
                  statusCheckPollingInterval:(responseDataStatus.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
                  isTradingSessionActive:responseDataStatus.isTradingSessionActive,
                  isInstrumentLive:responseDataStatus.isInstrumentLive,
                }));
              }
              if(responseDataStatus.upgrade_notify!=undefined && responseDataStatus.upgrade_notify){
                setShowPopup(true);
              }
          
    }
    else if(errorStatus!==null){
      console.log("Error data status check=",errorStatus);

      // setIdxTickerState((prevState) => ({
      //   ...prevState,
      //   idxLoaded:true}));
        
        if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
          console.log("status received =",errorStatus?.response?.status)
          // navigate(from, { replace: true });
          if(props.showLoginPopup){
            props.showLoginPopup(true,errorStatus?.response?.data?.message);
          }
        }
    }
    resetStatus();
  }
  },[loadedStatus,responseDataStatus]);
   
      
 /**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};    
/**
 * API Live response handler  Idx Ticker 
 */  
    useEffect(() => {
      if(loadedLive){
        console.log("idxTicker live loaded=true",responseDataLive);
        if(responseDataLive!=null){
          const processedData=processIdxTicker(responseDataLive);
          console.log("processIdxTicker data=",processedData)
          let idxHeight=processedData.length*ROW_HEIGHT+HEADER_HEIGHT+GROUP_HEADER_HEIGHT+8;
          // props.setTickerHeight(idxHeight+30);
          let updateInterval=pollingInterval;
              if(responseDataLive.isTradingSessionActive!=undefined && responseDataLive.isTradingSessionActive){
                updateInterval=reloadInerval;
              }
              setIdxTickerState((prevState) => ({...prevState,
                refreshInterval:updateInterval,
                idxTickeHeight:idxHeight,
                idxLoaded:true,idxTickerData:processedData,
                isTradingSessionActive:responseDataLive.isTradingSessionActive,
                isStatusCheckAllowed:(!responseDataLive.isTradingSessionActive),
                statusCheckPollingInterval:(responseDataLive.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
                isInstrumentLive:responseDataLive.isInstrumentLive}));            
        }
        else if(errorLive!==null){
          setIdxTickerState((prevState) => ({
            ...prevState,
            idxLoaded:true}));

          console.log("Error data=",errorLive);

            
            //if unauthorized then redirec it to login page
            if(errorLive?.response?.status === 401 || errorLive?.response?.status === 403){
              console.log("status received =",errorLive?.response?.status)
              // navigate(from, { replace: true });
              if(props.showLoginPopup){
                props.showLoginPopup();
              }
            }
            // else 
            // setMsgState({open:true,msg:errorLive?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
        resetLive();
      }
      },[loadedLive,responseDataLive]);
        
    const onGridReady = params => {
      setGridAPIOptions(params.api);
      setColumnAPIOptions(params.columnApi);
    };
    
   
    
  return (
    <div style={{width:isProUser?"100%": 'fit-content',height:  `calc(${idxTickeHeight+30}px)`}}>
        {idxLoaded?
       <div className="ag-theme-alpine-dark" style={{height: `calc(${idxTickeHeight+14}px)`, minWidth: "634px"}}>
           <AgGridReact
           ref={gridRef}
            onGridReady={onGridReady}
            components={frameworkComponents}
            rowHeight={ROW_HEIGHT}
            headerHeight={HEADER_HEIGHT}
            groupHeaderHeight={GROUP_HEADER_HEIGHT}
            gridOptions={gridOptions}
            rowData={idxTickerData}
            suppressScrollOnNewData={true}
            columnDefs={columnDefs}/>
           
       </div>:
      <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
      </div>
        }
      
      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>

       {showPopup?
         <SubscriptionPopup open={showPopup} proMessage={false} onClose={()=>setShowPopup(false)}></SubscriptionPopup>  :
         <></>
      } 
       {showSettings?
     <TickerSettings open={true}  cancelHandler={cancelSettings} saveHandler={saveSettings} ></TickerSettings>    :
     <></>
    }    
    </div>
  )
}

export default TICKER_TABLE;