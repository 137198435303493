import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlusIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/HorizontalRule';
import { Typography,Link, Tooltip } from '@mui/material'
import React from 'react'
import { TABLE_COLORS } from './table_constants';
import { USER_ROLE_FIELDS,INDEX_TICKER_COLUMNS_LIST } from './constant';
import SettingsIcon from '@mui/icons-material/Settings';

export const DefaultRenderer=(props)=>{
    return <div style={{alignSelf:"center",alignItems:"center !important"}}>
        {props.value!=undefined?
          <span>{props.value}</span>:
          <span>-</span>
        }   
        </div>
}

const CustomHeaderComponent = (props) => {
    const handleSettings=()=>{
        props.handleSettings()
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <img src={iconName} alt="Icon" style={{ marginRight: '5px' }} /> */}
        {/* <span>Test</span> */}
        <Tooltip title="Ticker Table Settings">
              <SettingsIcon onClick={handleSettings}sx={{ml:0,marginRight:"4px", marginTop:"-3px",height:20,width:20,cursor:'pointer'}}/>
          </Tooltip>
      </div>
    );
  };

export const SpotFut3DCompVWAPVPOCRenderer=(props)=> {
    // console.log("SpotFut3DCompVWAPVPOCRenderer=",props.data.spot,props.data.spot[3dva]);
    return <div style={{alignSelf:"center"}}>
            
            {/* <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end", marginLeft:"-8px"}} title="3D: VAL-VAH [VPOC-VWAP]">
                {props.data.spot['3dva']!=undefined && props.data.spot['3dva']!=null? 
                 <>
                     [&nbsp;<span style={{fontStyle:'italic'}}>{props.data.spot['3dva'].val}</span>-
                     <span style={{fontStyle:'italic'}}>{props.data.spot['3dva'].vah}</span>&nbsp;/&nbsp;
                    <span  style={{color: TABLE_COLORS.VPOC,fontWeight:500,fontStyle:'italic'}} >{props.data.spot['3dva'].vpoc}</span>- 
                    <span  style={{color: TABLE_COLORS.VWAP,fontWeight:500,fontStyle:'italic'}} >{props.data.spot['3dva'].vwap}</span>&nbsp;]
                </>:
                <span>-</span>}
            </div> */}
            <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}} title="Today: VAH-VAL / VPOC-VWAP">
                {props.data.spot!=undefined? 
                 <>
                     <span>{props.data.spot.vah}</span>- 
                     <span >{props.data.spot.val}</span>&nbsp;/&nbsp;
                    <span  style={{color: TABLE_COLORS.VPOC,fontWeight:700}} >{props.data.spot.vpoc}</span>- 
                    <span  style={{color: TABLE_COLORS.VWAP,fontWeight:700}} >{props.data.spot.vwap}</span>
                </>:
                <>
                    <span>-</span>
                </>}
            </div>
            
        </div>;
}

export const SpotOpenHighLowRenderer=(props)=> {
    return <div style={{alignSelf:"center",width:"100%"}}>
             <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                <span>{props.data.spot.open}</span> 
                </div>
                <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                 
                <span>{props.data.spot.high}</span>&nbsp;/&nbsp;
                <span>{props.data.spot.low}</span> 

            </div>
         </div>;
}


export const FutOpenHighLowRenderer=(props)=> {
    return <div style={{alignSelf:"center",width:"100%"}}>
               {props.data.fut!=undefined && props.data.fut!=null?
            <> 
             <div style={{display:'flex',alignItems:'end',justifyContent:"flex-end"}}>
              
                
                    <span>{props.data.fut.open}</span> 
                    </div>
                
                    <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                
                    <span>{props.data.fut.high}</span>&nbsp;/&nbsp;
                    <span>{props.data.fut.low}</span> 
                    </div>
                
            </>:
            <div style={{display:'flex',justifyContent:'center'}}>-</div>}
         </div>
}

export const FutTRATRRenderer=(props)=> {
    return <div style={{alignSelf:"center"}}>
         {props.data.fut!=undefined && props.data.fut!=null?
                <>
             <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end", marginLeft:"5px"}}>
               
                    <span>{props.data.fut.tr}{props.data.fut.tr=="GIFTNIFTY"?<>&nbsp;(vols)<br/></>:<></>}</span> 
                    </div><div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                
                    <span>{props.data.fut.atr5}</span>&nbsp;/&nbsp;
                    <span>{props.data.fut.atr14}</span> 
                           
            </div>
            </>:<>-</>}
         </div>
}

export const FutHVHighLowRenderer=(props)=> {
    return <div style={{alignSelf:"center"}}>
         {props.data.fut!=undefined && props.data.fut!=null?
                <>
             <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end", marginLeft:"-8px"}}>
               
                    <span>1&sigma;:&nbsp;{props.data.fut.sd_1_high}</span>&nbsp;&nbsp;
                    <span>2&sigma;:&nbsp;{props.data.fut.sd_2_high}</span>&nbsp;&nbsp;
                    <span>3&sigma;:&nbsp;{props.data.fut.sd_3_high}</span>    
                    </div><div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                
                    <span>1&sigma;:&nbsp;{props.data.fut.sd_1_low}</span>&nbsp;&nbsp;
                    <span>2&sigma;:&nbsp;{props.data.fut.sd_2_low}</span>&nbsp;&nbsp;
                    <span>3&sigma;:&nbsp;{props.data.fut.sd_3_low}</span> 
                           
            </div>
            </>:<>-</>}
         </div>
}
               
export const SpotCHGLTPRenderer=(props)=> {
    let close = props.data.spot.close!=undefined && props.data.spot.close!=0 ? props.data.spot.close : props.data.spot.ltp
    let change=parseFloat((close-props.data.spot.pClose).toFixed(2))
    let changePerc=parseFloat(((close-props.data.spot.pClose)/props.data.spot.pClose).toFixed(2))
        return <div style={{alignSelf:"center",width:"100%"}}>
                   <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                    {change>=0?
                        <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>:
                        <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>}
                        <span style={{color: change>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >
                            {change}
                        </span>
                        {props.data.key=="INDIAVIX"?
                        <span style={{color: change>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >
                            &nbsp;({''+(typeof changePerc) != 'number' || changePerc == undefined || ''+changePerc=="NaN" ? 0 : changePerc}%)
                        </span>:
                        <></>}
                    </div>
                    <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                        {props.data.spot.close!=undefined && props.data.spot.close!=0?
                        <span style={{fontWeight:700}}>({props.data.spot.close})&nbsp;</span>
                        :<></>
                        }
                        <span style={{fontWeight:700}}>{props.data.spot.ltp}</span>
                    </div>
                </div>;
}
                

export const FutCHGLTPRenderer=(props)=> {
    return   <div style={{alignSelf:"center",width:"100%"}}>
                {props.data.fut!=undefined && props.data.fut!=null && props.data.fut.open!=null?
                <>
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end", marginLeft:"-8px"}}>
                    {props.data.fut.ltp-props.data.fut.pClose>=0?
                        <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>:
                        <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>}
                        <span style={{color: props.data.fut.ltp-props.data.fut.pClose>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >
                            {(props.data.fut.ltp-props.data.fut.pClose).toFixed(2)}
                        </span>
                    </div>
                    <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                        {props.data.fut.close!=undefined && props.data.fut.close!=0?
                        <span style={{fontWeight:700}}>({props.data.fut.close})&nbsp;</span>
                        :<></>
                        }
                        <span style={{fontWeight:700}}>{props.data.fut.ltp}</span>
                    </div>
                </>:
                 <div style={{display:'flex',justifyContent:'center'}}>-</div>
                }
            
            </div>
}



export const FutPremRenderer=(props)=>{
    return <div style={{alignSelf:"center",alignItems:"center !important"}}>
        {props.data.fut!=undefined && props.data.fut.lastPrem!=undefined?
           <span>{props.data.fut.tr=="GIFTNIFTY"?<>&nbsp;&nbsp;(vwap)<br/></>:<></>}{props.data.fut.lastPrem}</span>:
            <>-</>
        }

        </div>
}
export const SynthPremRenderer=(props)=> {
    return <div style={{alignSelf:"right",width:"100%"}}>
            {props.data.fut!=undefined && props.data.spot!=undefined && props.data.fut?.synth_fut!=undefined && props.data.fut?.synth_fut!=null 
                && Math.abs(props.data.fut?.synth_fut-props.data.spot?.ltp) < (0.004*Math.abs(props.data.spot?.ltp))?
            <> 
                <div style={{display:'flex',alignItems:'right',justifyContent:"flex-end", marginLeft:"0px", marginTop: "5px", marginBottom: "-2px"}}>
                    {/* {props.data.fut.synth_fut-props.data.spot.ltp>=0?
                        <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>:
                        <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>} */}
                        {props.data.spot.close!=undefined && props.data.spot.close!=0?
                            <span style={{color: props.data.fut.synth_fut-props.data.spot.close>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED,fontSize:"11px"}} >
                                {(props.data.fut.synth_fut-props.data.spot.close).toFixed(2)}
                            </span>
                        :
                            <span style={{color: props.data.fut.synth_fut-props.data.spot.ltp>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED,fontSize:"11px"}} >
                                {(props.data.fut.synth_fut-props.data.spot.ltp).toFixed(2)}
                            </span>
                        }

                        
                </div>
                <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                    <span style={{fontWeight:700}}>{props.data.fut.synth_fut}</span>
                </div>
                
            </>: <div style={{display:'flex',justifyContent:'center'}}>-</div>
            }
         </div>
}
export const FutMaxMinPremRenderer=(props)=> {
    return <div style={{alignSelf:"center",width:"100%"}}>
               {props.data.fut!=undefined && props.data.fut!=null?
            <> 
                <div style={{display:'flex',alignItems:'end',justifyContent:"flex-end"}}>
                    <span>{props.data.fut.maxPremVal}</span>&nbsp;/&nbsp; 
                    <span>{props.data.fut.maxPremTime}</span> 
                </div>
                    
                <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                    <span>{props.data.fut.minPremVal}</span>&nbsp;/&nbsp;
                    <span>{props.data.fut.minPremTime}</span> 
                </div>
                
            </>: <div style={{display:'flex',justifyContent:'center'}}>-</div>}
         </div>
}


export const FutSMAWMARenderer=(props)=> {
    return <div style={{alignSelf:"center",width:"100%"}}>
               {props.data.fut!=undefined && props.data.fut!=null?
            <> 
                <div style={{display:'flex',alignItems:'end',justifyContent:"flex-end"}}>
                   
                    <span>{props.data.fut.smaPrem}</span> 
                </div>
                    
                <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                    <span>{props.data.fut.wmaPrem}</span> 
                </div>
                
            </>: <div style={{display:'flex',justifyContent:'center'}}>-</div>}
         </div>
}

export const SpotUnchangedAdvDecRenderer=(props)=> {
    return <div style={{alignSelf:"center",width:"100%"}}>
               {props.data.spot!=undefined && props.data.spot.unchanged!=undefined?
            <> 
                <div style={{display:'flex',alignItems:'end',justifyContent:"flex-end"}}>
                    <span>{props.data.spot.unchanged}</span> 
                </div>
                
                <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                    <span>{props.data.spot.advances}</span>&nbsp;/&nbsp; 
                    <span>{props.data.spot.declines}</span> 
                </div>
                
            </>: <div style={{display:'flex',justifyContent:'center'}}>-</div>}
         </div>
}

export const InstrumentRenderer=(props)=> {
    
    const handleSpot=()=>{
        props.handleMPChart(props.data.key+"_SPT")
    }
    const handleFutures=()=>{
        props.handleMPChart(props.data.key)
    }

    return <div style={{alignSelf:"center",width:"100%"}}>
                <div style={{display:'flex',alignItems:'end',justifyContent:"flex-start"}}>
                    <span>{props.data.key}</span> 
                </div>
                
                <div style={{height:"20px",display:"flex",marginTop:"-5px",justifyContent:"flex-start"}}>
                    {props.data.spot!=undefined?
                    <>
                        <Typography variant='link'sx={{cursor:'pointer',fontSize:"13px",textDecoration:"underline",color:TABLE_COLORS.CHART_LINK_TEXT}} onClick={handleSpot}>Spot</Typography>&nbsp;
                    </>
                    :
                    <></>
                    }
                    {props.data.fut!=undefined?
                    <>
                        /&nbsp;<Typography variant='link' sx={{cursor:'pointer',fontSize:"13px",textDecoration:"underline", color:TABLE_COLORS.CHART_LINK_TEXT}} onClick={handleFutures}>Fut</Typography>
                    </>
                    :
                    <></>
                    }
                </div>
                
         </div>
}

export const getColumneDef=(auth,openMPChart,hidden_cols,openSettings)=>{
    console.log("INDEXSETTINGS hidden_cols",hidden_cols);
    if(!hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.hv_high_low))
        hidden_cols.push(INDEX_TICKER_COLUMNS_LIST.hv_high_low);
    let proUser=auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA)?true:false;
    let def;
    if(proUser){
         def=[
            {
                headerName:"",
                groupId:"instrument",
                headerGroupComponent:CustomHeaderComponent,
                headerGroupComponentParams:{handleSettings:openSettings},
                children:[
                { field: 'key',headerName:"Instrument" ,maxWidth: 110,cellRenderer:InstrumentRenderer,
                    cellRendererParams: {
                        handleMPChart: openMPChart,
                    }
                },
                ]
            },
     
            {
                headerName:"Spot",
                groupId:"spot_group",
                children:[
                    { field: 'spot_3D_comp_va',headerName:"Today TPO VA",minWidth: 280,maxWidth: 290,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.spot_3D_comp_va),
                        cellRenderer:SpotFut3DCompVWAPVPOCRenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px"};
                        }
                    },
                    { field: 'spot_open_high_low',headerName:"Open\nHigh/Low",maxWidth: 150,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.spot_open_high_low),
                        cellRenderer:SpotOpenHighLowRenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px"};
                        }
                    },
                    { field: 'spot_open_change_ltp',headerName:"Change\n(Close) LTP",maxWidth: 160,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.spot_open_change_ltp),
                        cellRenderer:SpotCHGLTPRenderer,
                        cellStyle: function(params) {
                            return {backgroundColor:params.data.spot!=undefined && params.data.spot.ltp!=undefined?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                    { field: 'synth_fut',headerName:"Synth Fut",maxWidth:80,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.prem_stats),
                        cellRenderer:SynthPremRenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px"};
                        }    
                    },
                    { field: 'spot.timestamp',headerName:"Time" ,maxWidth: 90,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.spot_timestamp),
                        cellRenderer:DefaultRenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px"};
                        }
                    },
                ]
            },
            {
                headerName:"Futures",
                groupId:"fut_group",
                children:[
                    { field: 'fut_tr_atr',headerName:"TR\nATR 5/14",maxWidth: 120,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.fut_tr_atr),
                        cellRenderer:FutTRATRRenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px",backgroundColor:params.data.fut?.tr=="GIFTNIFTY"?TABLE_COLORS.HIGHLIGHT_ROW_COLOR:null};
                        }
                    },
                    { field: 'fut_open_high_low',headerName:"Open\nHigh/Low",maxWidth: 150,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.fut_open_high_low),
                        cellRenderer:FutOpenHighLowRenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px",backgroundColor:params.data.fut?.tr=="GIFTNIFTY"?TABLE_COLORS.HIGHLIGHT_ROW_COLOR:null};
                        }
                     },
                     { field: 'hv_high_low',headerName:"HV: High/Low\n(volatility based range)",minWidth:230,maxWidth: 230,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.hv_high_low),
                        cellRenderer:FutHVHighLowRenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px",backgroundColor:params.data.fut?.tr=="GIFTNIFTY"?TABLE_COLORS.HIGHLIGHT_ROW_COLOR:null};
                        }
                    },
                    { field: 'pe_chg_ltp',headerName:"Change\n(Close) Fut LTP",maxWidth: 160,cellRenderer:FutCHGLTPRenderer,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.pe_chg_ltp),
                        cellStyle: function(params) {
                            return {backgroundColor:params.data.fut!=undefined && params.data.fut.ltp!=undefined?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                    { field: 'fut.timestamp',headerName:"Fut Time" ,maxWidth: 90,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.fut_timestamp),
                    cellRenderer:DefaultRenderer,
                    cellStyle: function(params) {
                        return{fontSize:"12px",backgroundColor:params.data.fut?.tr=="GIFTNIFTY"?TABLE_COLORS.HIGHLIGHT_ROW_COLOR:null};
                    }    
                    },
                ]
          
            },
            {
                headerName:"Future Premium Stats",
                groupId:"fut_prem_group",
                children:[
                    { field: 'prem',headerName:"Prem",maxWidth:80,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.prem_stats),
                        cellRenderer:FutPremRenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px",backgroundColor:params.data.fut?.tr=="GIFTNIFTY"?TABLE_COLORS.HIGHLIGHT_ROW_COLOR:null};
                        }    
                     },
                    { field: 'max_min_prem',headerName:"Max Prem\nMin Prem",maxWidth:120,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.prem_stats),
                        cellRenderer:FutMaxMinPremRenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px",backgroundColor:params.data.fut?.tr=="GIFTNIFTY"?TABLE_COLORS.HIGHLIGHT_ROW_COLOR:null};
                        }    
                    },
                    { field: 'sma_wma',headerName:"SMA\nWMA",maxWidth:100,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.prem_stats),
                        cellRenderer:FutSMAWMARenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px",backgroundColor:params.data.fut?.tr=="GIFTNIFTY"?TABLE_COLORS.HIGHLIGHT_ROW_COLOR:null};
                        }    
                    },
                    // { field: 'pcr_straddle',headerName:"PCR\nStraddle",maxWidth:100,cellRenderer:PCRStraddleRenderer },
                   
                ]
            },
            {
                headerName:"",
                groupId:"uch_adv_dec",
                children:[
                    { field: 'unchanged_adv_dec',headerName:"Unchanged\nAdv / Dec",maxWidth:120,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.idx_ad),
                    cellRenderer:SpotUnchangedAdvDecRenderer,
                    cellStyle: function(params) {
                        return{fontSize:"12px",backgroundColor:params.data.fut?.tr=="GIFTNIFTY"?TABLE_COLORS.HIGHLIGHT_ROW_COLOR:null};
                    }    
                    },
                ]
            }
       
          
            ]
    }else{
        def=[
            {
                headerName:"",
                groupId:"instrument",
                headerGroupComponent:CustomHeaderComponent,
                children:[
                { field: 'key',headerName:"Instrument" ,maxWidth: 110,cellRenderer:InstrumentRenderer,
                    cellRendererParams: {
                        handleMPChart: openMPChart,
                    }
                },
                ]
            },
     
            {
                headerName:"Spot",
                groupId:"spot_group",
                children:[
                    { field: 'spot_open_high_low',headerName:"Open\nHigh/Low",maxWidth: 150,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.spot_open_high_low),
                        cellRenderer:SpotOpenHighLowRenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px"};
                        }
                    },
                    { field: 'spot_open_change_ltp',headerName:"Change\n(Close) LTP",maxWidth: 160,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.spot_open_change_ltp),
                        cellRenderer:SpotCHGLTPRenderer,
                        cellStyle: function(params) {
                            return {backgroundColor:params.data.spot!=undefined && params.data.spot.ltp!=undefined?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                    { field: 'spot.timestamp',headerName:"Time" ,maxWidth: 90,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.spot_timestamp),
                        cellRenderer:DefaultRenderer,
                        cellStyle: function(params) {
                            return{fontSize:"12px"};
                        }
                    },
                ]
            },
        //     { field: 'unchanged_adv_dec',headerName:"Unchanged\nAdv / Dec",maxWidth:120,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.idx_ad),
        //     cellRenderer:SpotUnchangedAdvDecRenderer,
        //     cellStyle: function(params) {
        //         return{fontSize:"12px"};
        //     }    
        // },
        {
            headerName:"",
            groupId:"uch_adv_dec",
            children:[
                { field: 'unchanged_adv_dec',headerName:"Unchanged\nAdv / Dec",maxWidth:120,hide:hidden_cols.includes(INDEX_TICKER_COLUMNS_LIST.idx_ad),
                cellRenderer:SpotUnchangedAdvDecRenderer,
                cellStyle: function(params) {
                    return{fontSize:"12px",backgroundColor:params.data.fut?.tr=="GIFTNIFTY"?TABLE_COLORS.HIGHLIGHT_ROW_COLOR:null};
                }    
                },
            ]
        }
       
          
            ]
    }

            return def;
    }
