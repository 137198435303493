// import { axiosPrivate } from "../axios";
// import { useEffect,useState } from "react";
// import useRefreshToken from "../../hooks/useRefreshToken";
// import useAuth from "../../hooks/useAuth";
// import axios from "../axios";

// const NO_RETRY_HEADER = 'x-no-retry'
// /**
//  * this hook will be used to call api once the user is on dashboard after login/registeration
//  * @returns axios instance with interceptor which will handle token expiry as well
//  */
// const useAxiosPrivate = () => {
//     const refresh = useRefreshToken();
//     const { auth } = useAuth();
//     const [refreshCall, setRefreshCall] = useState(false)

//     useEffect(() => {
//         let isRefreshing = false; // Flag to prevent multiple refresh attempts

//         //request interceptor: will add the Authorization header if not present
//         const requestIntercept = axiosPrivate.interceptors.request.use(
//             config => {
//                 if (!config.headers['Authorization']) {
//                     config.headers['Authorization'] = `${auth?.accessToken}`;
//                 }
//                 return config;
//             }, (error) => Promise.reject(error)
//         );
        
//          //response interceptor: it will try to get the new token if the access token has expired
//          //TODO: need to check the status code from the in case of expired token.
//         const responseIntercept = axiosPrivate.interceptors.response.use(
//             response => response,
//             async (error) => {
//                 const prevRequest = error?.config;
//                 if ((error?.response?.status === 401 ||error?.response?.status === 403) && !prevRequest._retry && !isRefreshing) {
//                     console.log("useRefreshToken04 token error =",prevRequest,prevRequest.url)
//                     // prevRequest.sent = true;
//                     isRefreshing = true;
//                     try {
//                         // axiosPrivate.interceptors.response.eject();
//                         setRefreshCall(true);
//                         console.log("useRefreshToken4 token calling=");
//                         // prevRequest.headers[NO_RETRY_HEADER] = 'true'
//                         const newAccessToken = await refresh();
//                         console.log("useRefreshToken5 token reponse =",newAccessToken)
//                         prevRequest.headers['Authorization'] = `${newAccessToken}`;
//                         return axiosPrivate(prevRequest);
//                     }catch (error) {
//                         console.error("useRefreshToken6 Refresh token error in useAxiosPrivate refresh call 1=",error);
//                         if(error?.response?.status === 400 ||error?.response?.status === 403){
//                             console.error("useRefreshToken7 Refresh token error in useAxiosPrivate refresh call 1=",error);
//                             error.response.status=401;
//                         }
//                         console.error("useRefreshToken8 Refresh token error in useAxiosPrivate refresh call 1=",error);
//                         return Promise.reject(error);
                        
//                     }finally {
//                         isRefreshing = false;
//                     }
                    
//                 }
//                 // console.log("Reject =",error)
//                 console.error("useRefreshToken9 Refresh token error in useAxiosPrivate refresh call 1=",error);
//                 return Promise.reject(error);
//             }
//         );

//         return () => {
//             axiosPrivate.interceptors.request.eject(requestIntercept);
//             axiosPrivate.interceptors.response.eject(responseIntercept);
//         }
//     }, [auth, refresh])

//     return axiosPrivate;
// }

// export default useAxiosPrivate;

import { axiosPrivate } from "../axios";
import { useEffect, useState } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";

const NO_RETRY_HEADER = 'x-no-retry';

/**
 * This hook will be used to call api once the user is on dashboard after login/registeration
 * @returns axios instance with interceptor which will handle token expiry as well
 */
const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const [isRefreshing, setIsRefreshing] = useState(false);

    useEffect(() => {
        let requestIntercept;
        let responseIntercept;
        // console.log("useRefreshToken UseEffect Called=",isRefreshing)
        const setupInterceptors = () => {
            // Request interceptor: add the Authorization header if not present
            setIsRefreshing(false);
            requestIntercept = axiosPrivate.interceptors.request.use(
                config => {
                    if (!config.headers['Authorization']) {
                        config.headers['Authorization'] = `${auth?.accessToken}`;
                    }
                    return config;
                }, (error) => Promise.reject(error)
            );

            // Response interceptor: handle token expiration and refresh
            responseIntercept = axiosPrivate.interceptors.response.use(
                response => response,
                async (error) => {
                    const prevRequest = error?.config;
                    // console.log("useRefreshToken0004  =",isRefreshing,prevRequest._retry)
                    if (error?.response?.status === 401 && !prevRequest._retry && !prevRequest.url.includes("refresh")) {
                        // console.log("useRefreshToken04 token error =",prevRequest,prevRequest.url,isRefreshing)
                        prevRequest._retry = true; // Prevent retrying the same request
                        // setIsRefreshing(true);
                        // console.log("useRefreshToken04 token error =",prevRequest,prevRequest.url,isRefreshing,prevRequest._retry)
                        try {
                            // console.log("useRefreshToken4 token calling=");
                            const newAccessToken = await refresh();
                            // console.log("useRefreshToken5 token reponse =",newAccessToken,isRefreshing)
                         
                            prevRequest.headers['Authorization'] = `${newAccessToken}`;
                            return axiosPrivate(prevRequest);
                        } catch (error) {
                            console.error("useRefreshToken6 CatchError in token refresh:", error,isRefreshing,prevRequest._retry);
                            return Promise.reject(error);
                        } finally {
                            console.log("useRefreshToken6 finally block=",isRefreshing,prevRequest._retry);
                            setIsRefreshing(false);
                        }
                    }
                    return Promise.reject(error);
                }
            );
        };

        setupInterceptors();

        // Clean up the interceptors when unmounting
        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        };
    }, [auth, refresh]);

    return axiosPrivate;
}

export default useAxiosPrivate;
