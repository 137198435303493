import React,{useEffect, useState} from 'react'
import {Link, Grid,Tab,Tabs } from "@mui/material"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Paper} from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player/vimeo'
import ReactPlayerYT from 'react-player/youtube'
import ReactPlayerVimeo from 'react-player/vimeo';
import Carousel from 'react-material-ui-carousel'
import mpImg from '../../assets/images/mp-chart.png';
import mp1Img from '../../assets/images/mp-chart1.png';
import mp2Img from '../../assets/images/mp-chart2.png';
import optableImg from '../../assets/images/op-table.png';
import optable1Img from '../../assets/images/op-table1.png';
import idxtableImg from '../../assets/images/index-table.png';
import greektableImg from '../../assets/images/greeks-table.png';
import spectrumImg from '../../assets/images/spectrum.png';
import oichgImg from '../../assets/images/oi-change.png';
import ntmoichgImg from '../../assets/images/ntm-oi-change.png';
import hvImg from '../../assets/images/hv-charts.png';
import ivizImg from '../../assets/images/iviz-1.png';
import ivcurveImg from '../../assets/images/iv-curve.png';
import oisnakesImg from '../../assets/images/oi-snakes.png';
import MainLayoutContainer from '../login-register/components/main-layout';
import Header from '../login-register/components/header';
import { USER_MANUAL_TAB_LIST,INTRO_VIDIEOS } from '../../common/utility/constant';
import { experimentalStyled as styled } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { contentQuotesLinter } from '@ant-design/cssinjs/lib/linters';
import { useWindowSize } from '../../common/hooks/useWindowSize';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useSearchParams} from 'react-router-dom';
import { useQuery } from '../../common/utility/helperFunc';
import UserManual from './user_manual';
import DashboardHeader from '../../components/AppHeader/header';
import DashboardLayoutContainer from './components/main_layout';



const TabPanel=(props)=> {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{width:"100%",backgroundColor:"#0d203f",overflowY:'scroll'}}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3,width:"100%" }}>
            {children}
          </Box>
        )}
      </div>
    );
    }

    const  a11yProps=(index)=> {
        return {
          id: `vertical-tab-${index}`,
          'aria-controls': `vertical-tabpanel-${index}`,
        };
      }

     
      
      const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
      
const style = {
    position: 'absolute',
    top: '7%',
    left: '5%',
    height: "90%",
    //   transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
  };

  const imgStyle = {
    width: "100%",
    height: "50%",
    // align: "middle",
    marginLeft: 0, 
    marginTop: 20, 
    marginBottom: 20
  }

  const items = [
    {
        name: "16/03 Orientation Webinar 1",
        src:"https://vimeo.com/809435827",
        yt_src: "https://youtu.be/7J5CAqumqHk"
    },
    {
        name: "31/03 Orientation Webinar 2",
        src:"https://vimeo.com/813574929",
        yt_src: "https://youtu.be/vjzd8uSKRlI"
    }
  ]

  const guide_items = [
    {
        name: "Revolutionize Your Options Trading with Sensitivity Value - The Leading Indicator You Can't Ignore",
        yt_src: "https://youtu.be/7w4ThmNx4YA"
    },
    {
        name: "Mastering Spectrum: Second Order Greeks & Gamma Positioning for NSE Option Traders",
        yt_src: "https://youtu.be/KBwaH3LUI4E"
    },
    {
        name: "Intraday Open Interest Analysis: Tracking Call & Put OI Changes on Charts",
        yt_src: "https://youtu.be/hItCJb7WX-M"
    },
    {
        name: "Using Market Profile & VWAP/POC with Strangle Charts: A Complete Guide",
        yt_src: "https://youtu.be/3fHhVxuPPyw"
    }
    
  ]

  

const UserManualPageBase = () => {

 
  const id = useQuery("id");
  const index=USER_MANUAL_TAB_LIST.findIndex((item)=>item.id==id);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [selectedIndex,setSelectedIndex]=React.useState(-1);        //keeps tracks of the playing video
  const [value, setValue] = React.useState(index!=-1?index:0);
  const [videoWidth,setVideoWidth]=useState((window.innerWidth-340)/3-30);
  const windowSize=useWindowSize();
  const isBigScreen = useMediaQuery('(min-width:1024px)');
 
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedIndex(-1);
  };
  useEffect(() => {
    document.title = "User Manual: "+USER_MANUAL_TAB_LIST[value].name;
    var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
    // console.log(link);
    document.head.appendChild(link);
    console.log("location=",location)
  }, []);

 
//   useEffect(()=>{
//     setVideoWidth((window.innerWidth-340)/3-30);
//   },[windowSize])

  useEffect(()=>{
    setSearchParams({"id":USER_MANUAL_TAB_LIST[value].id})
    document.title = "User Manual: "+USER_MANUAL_TAB_LIST[value].name;
  },[value])
  

    return (
        
    <>
        {location.pathname=="/info-manual"?
            <MainLayoutContainer>
                <Header showLogin={true} showIntro={false}/>
                <Box sx={{  bgcolor: 'background.paper', display: 'flex',mt:8,height: `calc(100vh - 120px)`,pt:0,background:"#132d5a",width:"100%",flexDirection: isBigScreen?'row':'column'}} >
                    <UserManual/>
            </Box>
            </MainLayoutContainer>
            :
            <Box sx={{  bgcolor: 'background.paper', display: 'flex',mt:0,height: `calc(100vh - 48px)`,pt:0,background:"#132d5a",width:"100%",flexDirection: isBigScreen?'row':'column'}}>
                <UserManual/>
            </Box>
        }
    </>

                
    )
}

export default UserManualPageBase
