import React, { useLayoutEffect, useRef,useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import dark_theme from "@amcharts/amcharts5/themes/Dark";
import { Container, Box, Typography, useTheme, IconButton, Tooltip,Select,FormControl,MenuItem,Menu,InputLabel } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { WATERMARK_CONFIG,DateFormat,USER_ROLE_FIELDS,NO_OF_DAYS,MenuProps,MIN_CHART_WIDTH } from '../../../common/utility/constant';
import dayjs from 'dayjs';
import { DatePicker} from 'antd';
import useAuth from '../../../common/hooks/useAuth';
import InstrumentsDropdown from "./InstrumentsDropdown"
// import { Percent, PortableWifiOffSharp } from '@mui/icons-material';

const OptLineChartContainer = (props) => {
  const seriesVWAPRef = useRef(null);
  const seriesVPOCRef = useRef(null);
  const seriesOIRef = useRef(null);
  const seriesIvRef = useRef(null);
  const seriesVolRef = useRef(null);
  const candlestickSeriesRef = useRef(null);
  const seriesDoiRef = useRef(null);

  const { auth } = useAuth();
  const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
  const[nDays,setnDays]=useState(props.nDays);
  const [startDate,setStartDate]=useState(props.startDate!=""? dayjs(props.startDate,DateFormat):"");


  // const theme = useTheme();
  const navigate = useNavigate();

  // console.log("CONTAINER BEGINS")
  // console.log(props)

  useLayoutEffect(() => {
    let root = am5.Root.new(props.id, {
      // useSafeResolution: false  TODO: itest incase charts break on iOS device uncomment it
    });

    root.setThemes([dark_theme.new(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        // wheelX: "zoomX",
        wheelZoomPositionX: 1,
        pinchZoomX: true,
        pinchZoomY: true,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        layout: root.verticalLayout
      })
    );

    //Refere for zoom and pan https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/

    // uncomment the below line is to hide the chart zoom button
    // chart.zoomOutButton.set("forceHidden", true); 

    // add scrollbar to control
    chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal"
    }));

    let rendrerDateTime = am5xy.AxisRendererX.new(root, {
      opposite: true,
      // minGridDistance: 1
    });
    rendrerDateTime.grid.template.setAll({
      location: 0,
      disabled: true,
    });
    rendrerDateTime.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    });
    rendrerDateTime.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99
    });

    //create date axis 
    let dateTimeAxis = am5xy.GaplessDateAxis.new(root, {
      baseInterval: { timeUnit: "minute", count: 1 },
      dateFormats: {
        "minute": "d MMM, HH:mm",
        "hour": "d MMM, HH:mm",
        "day": "d MMM"
      },
      markUnitChange: false,
      tooltipDateFormat: "d MMM, HH:mm",
      minHeight: 30,
      groupData: true,
      groupCount: 800,
      minZoomCount: 1,
      groupIntervals: [
        { timeUnit: "minute", count: 1 },
        { timeUnit: "minute", count: 2 },
        // { timeUnit: "minute", count: 5 },
        // { timeUnit: "minute", count: 15 },
        // { timeUnit: "minute", count: 30 },
        // { timeUnit: "hour", count: 1 },
      ],
      renderer: rendrerDateTime
    });

    //push the datetime axis in the chart
    let xAxis = chart.xAxes.push(
      dateTimeAxis
    );

    // let tooltip = am5.Tooltip.new(root, {
    //   getFillFromSprite: false,
    //   getStrokeFromSprite: true,
    //   autoTextColor: false,
    //   getLabelFillFromSprite: true,
    //   // labelText: "{name}: {valueY}"
    // });
    
    // tooltip.get("background").setAll({
    //   fill: am5.color(0xffffff),
    //   fillOpacity: 0.4,
    // });

    // % height of each panel
    let y1, y2, y3;
    [y1, y2, y3] = [60, 20, 20];
    if(props.plotParams.get("prem") === "") {
      if(props.plotParams.get("oivol") || props.plotParams.get("oiPlot") !== "")
        [y1, y2, y3] = [70, 30, 0];
      else if(props.plotParams.get("doi"))
        [y1, y2, y3] = [60, 40, 0];
    }

    let valueAxisLhs1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        height: am5.percent(y1),
        renderer: am5xy.AxisRendererY.new(root, {
        }),
      })
    );
    
    let valueAxisLhs2;
    if(props.plotParams.get("oiPlot") !== "") {
      valueAxisLhs2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y2),
          renderer: am5xy.AxisRendererY.new(root, {
          }),
        })
      );
    }
    
    let valueAxisRhs3;
    let valueAxisLhs3;
    if(props.plotParams.get("prem") !== "") {
      valueAxisLhs3 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y3),
          renderer: am5xy.AxisRendererY.new(root, {
          }),
        })
      );
  
      valueAxisRhs3 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y3),
          marginTop: 10,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        })
      );
    }

    let valueAxisRhs2;
    if(props.plotParams.get("oiPlot") !== "") {
      valueAxisRhs2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y2),
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        })
      );
    }

    let valueAxisRhs1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // syncWithAxis: valueAxisLhs1,
        height: am5.percent(y1),
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    );

    let valueAxisFuturesRenderer = valueAxisRhs1.get("renderer");
    valueAxisFuturesRenderer.labels.template.setAll({
      paddingLeft: 2,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 5,
      verticalCenter: "bottom",
      fontSize: "0.8em",
      minPosition: 0.01,
      maxPosition: 0.99
    });
    
    let seriesVWAP, seriesVPOC, seriesOI;
    if(props.plotParams.get("plot") === "opt") {
      seriesVWAP = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "VWAP",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "vwap",
          valueXField: "time",
          stroke: am5.color("#128E0B"),
          fill: am5.color("#128E0B"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesVWAP.strokes.template.setAll({
        strokeWidth: 2,
      });

      // seriesVWAP.get("tooltip").label.set("text", "VWAP: {valueY}")

      seriesVPOC = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "VPOC",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "vpoc",
          valueXField: "time",
          stroke: am5.color("#AB039D"),
          fill: am5.color("#AB039D"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesVPOC.strokes.template.setAll({
        strokeWidth: 2,
      });

      // seriesVPOC.get("tooltip").label.set("text", "VPOC: {valueY}")

      seriesOI = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "OI",
          xAxis: xAxis,
          zIndex: 3,
          syncWithAxis: valueAxisLhs1,
          yAxis: valueAxisLhs1,
          // stroke: am5.color("#FF9933"),
          // fill: am5.color("#FF9933"),
          valueYField: "oi",
          valueXField: "time",
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesOI.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesOI.get("tooltip").label.set("text", "OI: {valueY}")
    }

    let seriesVol;
    if(props.plotParams.get("oiPlot") !== "") {
      seriesOI = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "OI",
          xAxis: xAxis,
          zIndex: 3,
          syncWithAxis: valueAxisLhs2,
          yAxis: valueAxisLhs2,
          stroke: am5.color("#FF9933"),
          fill: am5.color("#FF9933"),
          valueYField: props.plotParams.get("oiPlot"),
          valueXField: "time",
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesOI.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesVol = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Vol",
          xAxis: xAxis,
          syncWithAxis: valueAxisRhs2,
          yAxis: valueAxisRhs2,
          valueYField: "vol",
          valueXField: "time",
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesVol.columns.template.setAll({
        fillOpacity: 0.2,
        strokeWidth: 0,
        width: am5.percent(70)
      });

      seriesOI.get("tooltip").label.set("text", "OI: {valueY}")
      seriesVol.get("tooltip").label.set("text", "Vol: {valueY}")
    }

    let candlestickSeries = chart.series.push(
      am5xy.CandlestickSeries.new(root, {
        name: props.plotParams.get("displayInstr") + " Price",
        clustered: false,
        xAxis: xAxis,
        yAxis: valueAxisRhs1,
        openValueYField: "open",
        highValueYField: "high",
        lowValueYField: "low",
        valueYField: "close",
        valueXField: "time",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    candlestickSeries.columns.template.states.create("riseFromOpen", {
      fill: am5.color(0x76b041),
      stroke: am5.color(0x76b041)
    });
    candlestickSeries.columns.template.states.create("dropFromOpen", {
      fill: am5.color(0xe4572e),
      stroke: am5.color(0xe4572e)
    });

    // candlestickSeries.get("tooltip").label.set("text", "Close: {valueY}");
    // candlestickSeries.get("tooltip").label.set("text", "Open: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")

    candlestickSeries.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "dd-MM-yyyy HH:mm",
      dateFields: ["time"]
    });
    

    seriesVWAP.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "dd-MM-yyyy HH:mm",
      dateFields: ["time"]
    });
    seriesVPOC.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "dd-MM-yyyy HH:mm",
      dateFields: ["time"]
    });


    if(props.plotParams.get("oiPlot") !== "") {
      seriesOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
      seriesVol.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });
    }
    // candlestickSeries.get("tooltip").label.set("text", "[{column.fill}]Open: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")

    let seriesDoi, seriesIv;
    if(props.plotParams.get("prem") === "doi") {
      let valueAxisDoiRenderer = valueAxisRhs3.get("renderer");
      valueAxisDoiRenderer.labels.template.setAll({
        paddingLeft: 2,
        paddingTop: 2,
        paddingBottom: 2,
        paddingRight: 5,
        verticalCenter: "bottom",
        fontSize: "0.8em",
        maxPosition: 0.95,
        inside: false
      });

      let valueAxisIvRenderer = valueAxisLhs3.get("renderer");
      valueAxisIvRenderer.labels.template.setAll({
        paddingLeft: 2,
        paddingTop: 4,
        paddingBottom: 2,
        paddingRight: 5,
        verticalCenter: "bottom",
        fontSize: "0.8em",
        maxPosition: 0.95,
        inside: false
      });

      seriesDoi = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "OI_Spike",
          xAxis: xAxis,
          yAxis: valueAxisRhs3,
          valueXField: "time",
          valueYField: "doi",
          valueYGrouped: "extreme",
          fillOpacity: 0.5,
          strokeWidth: 0,
          clustered: false,
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesDoi.columns.template.setAll({
        // fillOpacity: 1,
        // strokeWidth: 0,
        width: am5.percent(100),
      });

      seriesDoi.columns.template.adapters.add("fill", function(fill, target) {
        if (target.dataItem && ((target.dataItem.get("valueY") > 130) || (target.dataItem.get("valueY") < 0 && target.dataItem.get("valueY") > -130))) {
            return am5.color("#01DF01");  // green
        }
        else {
            return am5.color("#FF0000");  // red
        }
      });
      
      seriesDoi.get("tooltip").label.set("text", "OI_Spike: {valueY}")

      seriesDoi.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

      seriesIv = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "IV",
          xAxis: xAxis,
          yAxis: valueAxisLhs3,
          valueYField: "iv",
          valueXField: "time",
          stroke: am5.color("#ffffff"),
          fill: am5.color("#ffffff"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesIv.strokes.template.setAll({
        strokeWidth: 1,
        strokeOpacity: 0.5,
      });

      // seriesIv.get("tooltip").label.set("text", "IV: {valueY}")

      seriesIv.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"]
      });

      seriesDoiRef.current = seriesDoi;
      seriesIvRef.current = seriesIv;
    }


    // Stack Y-axes
    chart.leftAxesContainer.set("layout", root.verticalLayout);
    chart.rightAxesContainer.set("layout", root.verticalLayout);

    seriesVWAPRef.current = seriesVWAP;
    seriesVPOCRef.current = seriesVPOC;
    seriesOIRef.current = seriesOI;
    seriesVolRef.current = seriesVol;
    candlestickSeriesRef.current = candlestickSeries;
    seriesDoiRef.current = seriesDoi;
    seriesIvRef.current = seriesIv;
    
    //create chart legend
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50)
    }));
    legend.data.setAll(chart.series.values);

    // //create chart title
    // chart.children.unshift(am5.Label.new(root, {
    //   text: "NIFTY Futures Line Chart",
    //   fontSize: 24,
    //   fontWeight: "500",
    //   textAlign: "center",
    //   x: am5.percent(50),
    //   centerX: am5.percent(50),
    //   paddingTop: 0,
    //   paddingBottom: 4
    // }));

    //add watermark text
    chart.plotContainer.children.push(am5.Label.new(root, {
      text: WATERMARK_CONFIG.LC_TEXT,
      y: am5.percent(100),
      centerY: am5.percent(100),
      opacity: 0.3,
      marginLeft: 10,
      marginBottom: 10,
      fontSize: 24,
      fontWeight: "400",
    }));

    chart.plotContainer.children.push(am5.Label.new(root, {
      text: props.plotParams.get("displayInstr"),
      y: am5.percent(50),
      x: am5.percent(40),
      centerY: am5.percent(50),
      opacity: 0.3,
      marginLeft: 10,
      marginBottom: 10,
      fontSize: 24,
      fontWeight: "400",
    }));

    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#000000"),
      strokeOpacity: 0.5,
      fill: am5.color("#000000"),
      fillOpacity: 0.7
    });

    // Add cursor for date time x axis
    chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomXY",
      xAxis: xAxis
    }));

    //add tooltip for date time x axis
    xAxis.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    valueAxisRhs1.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    if(props.plotParams.get("oivol")) {
      valueAxisRhs2.set("tooltip", am5.Tooltip.new(root, {
        themeTags: ["axis"]
      }));

      //add tooltip for date time x axis
      valueAxisLhs2.set("tooltip", am5.Tooltip.new(root, {
        themeTags: ["axis"]
      }));
    }

    if(props.plotParams.get("prem") !== "") {
      valueAxisLhs3.set("tooltip", am5.Tooltip.new(root, {
        themeTags: ["axis"]
      }));
    }


    return () => {
      root.dispose();
    };

  }, []);

  //this will handle the props data change and set/update the chart
  useLayoutEffect(() => {
    // console.log("uselayout effect child ===========>>>>>>>>>>>>>>>");
    //1st load
    if (props.isIncrementalData && props.chartData != undefined) {
      console.log("incremental data updates");
      candlestickSeriesRef.current.data.pushAll(props.chartData);
      seriesVWAPRef.current.data.pushAll(props.chartData);
      seriesVPOCRef.current.data.pushAll(props.chartData);
      seriesOIRef.current.data.pushAll(props.chartData);
      
      if(props.plotParams.get("oiPlot") !== "") {
        seriesVolRef.current.data.pushAll(props.chartData);
      }

      if(props.plotParams.get("prem") === "doi"){
        seriesDoiRef.current.data.pushAll(props.chartData);
        seriesIvRef.current.data.pushAll(props.chartData);
      }
        
      // else if(props.plotParams.get("prem") === "fut")
      //   seriesPremiumRef.current.data.pushAll(props.chartData);
      // seriesSpotRef.current.data.pushAll(props.chartData);
      
    } else {
      if(props.chartData != undefined) {

        console.log("initial chart load");
        candlestickSeriesRef.current.data.setAll(props.chartData);
        seriesVWAPRef.current.data.setAll(props.chartData);
        seriesVPOCRef.current.data.setAll(props.chartData);
        seriesOIRef.current.data.setAll(props.chartData);

        if(props.plotParams.get("oiPlot") !== "") {
          seriesVolRef.current.data.setAll(props.chartData);
        }
        
        if(props.plotParams.get("prem") === "doi"){
          seriesDoiRef.current.data.setAll(props.chartData);
          seriesIvRef.current.data.setAll(props.chartData);
        }
        
      // else if(props.plotParams.get("prem") === "fut")
      //   seriesPremiumRef.current.data.setAll(props.chartData);
      }
    }

  }, [props.plotParams, props.chartData, props.isIncrementalData]);


  function toggleFullScreen() {
    let chartdiv = document.querySelector('#chartdiv')
    if (!document.fullscreenElement) {
      chartdiv.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  const handlenDaysChange=(event)=>{
    setnDays(event.target.value);
    props.changenDays(event.target.value);
  }
  
  const disabledDate=(current)=> {
    return (current && current >= dayjs().endOf('day'))||dayjs(current).day()==6 || dayjs(current).day()==0
  }
  const onDateChange = (date, dateString) => {
    setStartDate(dayjs(date))
    console.log("Date changed",dayjs(date),dateString);
    props.handleStartDateChange(dateString);
  } 
  

  return (
    <>
      <div id="container" style={{ backgroundColor: "#111111", zIndex: "99", marginTop: "0px", padding: "20px", width: "100%",minWidth:`${MIN_CHART_WIDTH}`, minHeight: "calc(110vh - 256px)" }}>
        {/* <LineChartHeader/> */}
        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: "row", justifyContent: "space-evenly" }}>
          {/* <Tooltip title="Go Back">
            <IconButton
              onClick={() => navigate(-1)}
              size='large'>
              <ArrowBackIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
          {/* <Container sx={{ width: "80%" }}>
            <Typography variant='h4' color='primary' sx={{ alignSelf: "center" }}>{props.plotParams.get("displayInstr")} {props.plotParams.get("chartPlotType")} Line Chart</Typography>
          </Container>
          <Tooltip title="Full Screen">
            <IconButton
              onClick={() => toggleFullScreen()}
              size='large'>
              <FullscreenIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
        </Box>
        <div id="chartContainer">
        {!isBasicUser?
          <>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4}}>
            {/* <Typography sx={{mt:1,mr:0,fontSize:16,width:"100px"}}>No. of days</Typography> */}
            {console.log("InstrumentsDropdown Opt=",props.selectedInstrument,props.params)}
            {props.params.plot=="opt" || props.params.plot=="fut"?
                <InstrumentsDropdown
                  width="250"
                  MenuProps={MenuProps}
                  selectedInstrument={props.selectedInstrument}
                  instrumentChange={props.instrumentChange}
                  instrumentsData={props.instrumentsData}
                />:
                <></>
              }
            <FormControl size="small" sx={{ width:"90px",minWidth:'90px',ml:0,mt:0 }}>
              <InputLabel id="demo-simple-select-label">No. of days</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={nDays}
                MenuProps={MenuProps}
                sx={{height:"40px"}}
                label="No. of days"
                onChange={(event)=>handlenDaysChange(event)}
              >
                {NO_OF_DAYS.map((item,index) => (
                <MenuItem value={item.value}>{item.key}</MenuItem>
                ))}
              </Select>
          </FormControl>
          <DatePicker onChange={onDateChange} 
                allowClear={false}
                disabledDate={disabledDate}
                placeholder="Select Start Date"
                value={startDate}
                format={DateFormat}
                style={{border: "1px solid #484848",background:"#111111",
                borderRadius: "4px",width:200,marginLeft:40,marginTop:"0px"}}
                /> 
      </Box>
          </>
          :<></>}
          <div id="chartcontrols" style={{ padding: "10px", marginTop: "16px", maxWidth: "100%" }}></div>
          <div id={props.id} style={{ width: "100%", maxWidth: "100%", height: "800px" }} />
        </div>
      </div>
    </>
  )
}

export default OptLineChartContainer
