import React,{useEffect} from 'react'
import {Link, Grid } from "@mui/material"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FaqContainer from './faq_container';

const style = {
  position: 'absolute',
  top: '7%',
  left: '5%',
  height: "90%",
  //   transform: 'translate(-50%, -50%)',
  width: "90%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
};

const FaqPage = () => {

  useEffect(() => {
    document.title = "FAQ and Glossary";
    var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
    document.head.appendChild(link);
  }, []);

    return (
      <FaqContainer></FaqContainer>
        // <Box sx={style} align='justify'>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h4">
        //     Glossary of some common terms and short forms we use
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     <span style={{ fontWeight: 700 }}>MP:&nbsp;</span> Market Profile
        //     <br/><br/><span style={{ fontWeight: 700 }}>TPO:&nbsp;</span> Time Price Opportunity - All of the Intraday trading time is divided into 30 mins interval and each interval is given a character representation starting with A.
        //     <br/><br/><span style={{ fontWeight: 700 }}>IB:&nbsp;</span> Initial Balance period - Normally the first 2 TPO periods A and B, which represent institutional activity. Generally marked as yellow line boundaries in daily profile.
        //     <br/><br/><span style={{ fontWeight: 700 }}>VA:&nbsp;</span> Value Area - Value Area is normally defined as the price range that contains 70% of the day's trading volume (or time for TPO based VA). The value area represents a range of prices where the market has found fair value, or where the most business has been conducted during the trading day. Marked as dark shaded region on all profiles.
        //     <br/><br/><span style={{ fontWeight: 700 }}>VWAP:&nbsp;</span> Volume Weighted Average Price - Average price of all trades taken in the concerned time period, based on Volumes. Generally marked as thick Green band on all profiles.
        //     <br/><br/><span style={{ fontWeight: 700 }}>VPOC:&nbsp;</span> Volume based Point Of Control - Price at which volumes were highest during the concerned time period. Generally marked as thick Pink band on all profiles.
        //     <br/><br/><span style={{ fontWeight: 700 }}>HVN:&nbsp;</span> High Volume Node - Levels at which volumes were almost similar to VPOC. Generally marked as thick Brown band on profiles wherever applicable.
        //     <br/><br/><span style={{ fontWeight: 700 }}>VAH:&nbsp;</span> Volume based Value Area High. Marked as upper level of Value Area shaded region.
        //     <br/><br/><span style={{ fontWeight: 700 }}>VAL:&nbsp;</span> Volume based Value Area Low. Marked as lower level of Value Area shaded region.
        //     <br/><br/><span style={{ fontWeight: 700 }}>TWAP:&nbsp;</span> Time (or TPO) Weighted Average Price - Center price where the time spent by market was equal both above and below that price. Marking is same as VWAP on all profiles.
        //     <br/><br/><span style={{ fontWeight: 700 }}>TPOC:&nbsp;</span> Time (or TPO) based Point Of Control - Price at which time spent by market was highest during the concerned time period. Marking is same as VPOC on all profiles.
        //     <br/><br/><span style={{ fontWeight: 700 }}>TPO-VAH:&nbsp;</span> Time (or TPO) based Value Area High. Marking is same as VAH on all profiles.
        //     <br/><br/><span style={{ fontWeight: 700 }}>TPO-VAL:&nbsp;</span> Time (or TPO) based Value Area Low. Marking is same as VAL on all profiles.
        //     <br/><br/><span style={{ fontWeight: 700 }}>IV:&nbsp;</span> Implied Volatility - of an Option price of a given strike at a given time
        //     <br/><br/><span style={{ fontWeight: 700 }}>HV:&nbsp;</span> Historical Volatility - measured using statistical analysis of daily basis price changes
        //   </Typography>
        //   <br/><hr/>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 8, fontWeight: 600 }} variant="h4">
        //     FAQ - General
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     What Subscription level do I need?
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     The Subscription level you need will be based on the style of analysis your trading strategies use (Volume Profile, TPO Profile, Volume Analysis, etc).. Use the <Link href="/subscription">pricing table</Link> to compare features and pricing.
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     What are Vtrender Charts system requirements?
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     Vtrender Charts is a browser based application and has a very small footprint. It can run on any modern Windows 7 or Windows 10 machine with atleast 4GB of RAM but having 8GB RAM is preferred. We recommened Windows 10 and we no longer support Windows XP.
        //     <br/>As this is a browser based application, it can run on any other OS capable of hosting recent versions of popular web browsers like Chrome, Edge or Firefox.
        //     <br/>This means that the application can run seamlessly on Mac OS and also other Unix based systems along with mobile phones which run Chrome browser.
        //     <br/>For some charts to show information clearly, a minimum screen resolution of 1080p is required.
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     What are the differences between different subscription plans?
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     All plans provide access to different tools. You can do a quick comparison at <Link href="/subscription">subscription</Link> page.
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     How long does it take to activate the subscription after I made the payment?
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     If you make payment via our payment gateway then in most cases the subscription gets activated immediately after payment. If you are unable to access the tools as per the subscribed plan, after making the payment, please try to relogin once and if the issue still persists, email us at <Link href="mailto:charts@vtrender.com">charts@vtrender.com</Link> and we will try and activate it as early as possible.
        //     If you make the payment by scanning our UPI code then please email us the details at <Link href="mailto:charts@vtrender.com">charts@vtrender.com</Link> and also mention your login Email ID, Plan Name along with the screenshot of successful payment that has the Payment Reference number, as scan and pay payments via UPI are not automatically processed. It may take us 12-24 hours to process payments using this option and activate the corresponding subscription privleges.
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     Can I upgrade my subscription?
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     You can upgrade your subscription anytime. Please email us at <Link href="mailto:charts@vtrender.com">charts@vtrender.com</Link> for more details on how that will work out, in case you have an active subscription for a lower plan.
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     Do you have a mobile app?
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     Currently we do not have a mobile app but our website is mobile browser compatible. You can use the website in Chrome on mobile, however do note that the information on a few charts may get distorted due to the minimum resolution requirement of that specific chart.
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     I have paid by scanning your UPI code, why is my subscription not yet active?
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     For payments made by scanning our UPI code, you will have to send an email to <Link href="mailto:charts@vtrender.com">charts@vtrender.com</Link> with a screenshot of the transfer or UTR. Please include your login email ID. Our accounts team will verify the payment and activate your subscription. 
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     How much delay can be expected in the data updates?
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     On average the delay you would experience is about 15-20 seconds for premium and premium plus. Also, do note that OI data updates every 3 mins only and that gets updated only once every few minutes.
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     How do I create or open a New MarketProfile chart
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     Finish your registration and then do a login. Once you are logged in go to the dashboard on the left and you can use the menu to open up any MarketProfile chart or an index charts or option tables or anything else from the menu as per your plan privileges.
        //   </Typography>
        //   <br/><hr/>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 8, fontWeight: 600 }} variant="h4">
        //     FAQ - Technical 
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     There is an a Overwrite Bookmark popup that shows up every few minutes. How to get rid of it?
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     This situation is mainly related to Auto-Save feature, which is enabled by default. You can change the settings or even make it into a Manual-Save only feature.
        //     <br/>It happens when you open the same instrument chart in different tabs and the system detects an inconsistent state in those tabs. Normally you should avoid opening same instrument chart in multiple tabs.
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     Why am I not able to click the bottom footer buttons to change timeframe and other settings?
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     Happens if you happen to change the browser zoom to 75% or below on a Windows machine. We are trying to find a permanent fix for this issue, but till then try to use the zoom feauture only inside chart area, instead of browser level, esp when on a Windows machine.
        //   </Typography>
        //   <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h6">
        //     What to do when sometimes when I click a button or change a dropdown value or click on a link, I get a fully blank page?
        //   </Typography>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     Reason could be anything - either a bug or an issue with some old cached data or something similar. Try to wait a few mins and refresh page to see if it was a temporary issue with that page. If the issue persists, please drop an email to <Link href="mailto:charts@vtrender.com">charts@vtrender.com</Link> and we will try to resolve it on priority.
        //   </Typography>
        //   <br/><hr/>
        //   <Typography sx={{ ml:1, mr:1, mt: 4 }}>
        //     For any other issues or techincal queries feel free to drop an email to <Link href="mailto:charts@vtrender.com">charts@vtrender.com</Link> and we will try to address your queries at earliest possible.
        //   </Typography>
        // </Box>
    )
}

export default FaqPage

