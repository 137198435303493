import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from '@mui/material';
import {TEXT_MSGS,PREMIUM_UPGRADE_MSGS_LIST} from '../../../common/utility/constant';
import { useNavigate } from "react-router-dom";
import WarnIcon from '@mui/icons-material/Warning';
import { useLocation } from 'react-router-dom';
import { USER_MANUAL_TAB_LIST } from '../../../common/utility/constant';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReactPlayerYT from 'react-player/youtube'
import ReactPlayerVimeo from 'react-player/vimeo';
import Grid from '@mui/material/Grid';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const style = {
  position: 'absolute',
  top: '10%',
  left: '10%',
  height: "80%",
  //   transform: 'translate(-50%, -50%)',
  width: "80%",
//   transform: 'translate(-50%, -50%)',
//   width: 500,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
//   overflowY:"scroll",
//   p: 3,
};

export default function DemoPopup(props) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false)
    props.onClose();
}
const location = useLocation();

const index=USER_MANUAL_TAB_LIST.findIndex((item)=>(item?.path?.includes(location.pathname)));
const videoList=(index>=0&&USER_MANUAL_TAB_LIST[index].demoVideo && USER_MANUAL_TAB_LIST[index].demoVideo.length>0)?USER_MANUAL_TAB_LIST[index].demoVideo:USER_MANUAL_TAB_LIST[0].demoVideo;
const [value, setValue] = React.useState(index!=-1?index:0);
const isBigScreen = useMediaQuery('(min-width:1024px)');
const [selectedIndex,setSelectedIndex]=React.useState(-1); 

React.useEffect(()=>{
console.log("location in pop up=",location,location.pathname,index)
},[])


    

  return (
    
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        <CancelOutlinedIcon
            onClick={() => handleClose()}
            sx={{
              ml: 0, mt: 0, top: `calc(10% - 12px)`,left: `calc(90% - 13px)`,
              height: 24, width: 26, cursor: 'pointer', position: 'fixed', zIndex: 9999
            }}
          />
            <Box sx={{  bgcolor: 'background.paper', display: 'flex',mt:0,pt:2,mb:2,pb:2,background:"#132d5a",width:"100%",height:"100%", flexDirection: 'column',justifyContent:'center',alignItems:'center',pl:2,pr:2, overflow:'scroll'}}>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, pt:isBigScreen?2:6,mb:2,fontWeight: 600 }} variant="h5">DEMO VIDEO</Typography>
            <Box sx={{ flexGrow: 1,width:"100%",height:'100%',mt:4 }}>
                <Grid container spacing={4} >
                    {videoList.map((item, index) => (
                    <Grid item mobile={12} tablet={12} laptop={videoList.length>1?6:12} key={index}>
                    {/* <Box> */}
                      {item.name == "Orderflow Charts 1" || item.name == "Orderflow Charts 2" || item.name == "Intro and Registration 1" || item.name == "Intro and Registration 2" ?
                        <ReactPlayerVimeo style={{border:"1px solid #000",borderRadius:4,backgroundColor:"#132d5a"}}url={item.src} controls pause={selectedIndex!=index} playing={selectedIndex==index?true:false} onPause={()=>console.log("onPause=",index)}  width="100%" onPlay={()=>{{ console.log("onplay=",index);setSelectedIndex(index)}}} />
                        :
                        <ReactPlayerYT style={{border:"1px solid #000",borderRadius:4,backgroundColor:"#132d5a"}}url={item.yt_src} controls pause={selectedIndex!=index} playing={selectedIndex==index?true:false} onPause={()=>console.log("onPause=",index)}  width="100%" onPlay={()=>{{ console.log("onplay=",index);setSelectedIndex(index)}}} />
                      }
                       
                      {/* <ReactPlayerVimeo style={{border:"1px solid #000",borderRadius:4,backgroundColor:"#132d5a"}}url={item.src} controls pause={selectedIndex!=index} playing={selectedIndex==index?true:false} onPause={()=>console.log("onPause=",index)}  width="100%" onPlay={()=>{{ console.log("onplay=",index);setSelectedIndex(index)}}} /> */}
                      <Typography sx={{mt:1,textAlign:'center'}}>{item.name}</Typography>
                    {/* </Box> */}
                </Grid>
                ))}
            </Grid>
        </Box>
                {/* <Box display='flex' sx={{width:"100%",flexDirection:'column',justifyContent:'center',alignItems:'center',height:"100%"}}>
                    <Typography sx={{mt:1,textAlign:'center',mb:1,fontSize:"20px"}}>{videoList[0].name}</Typography>
            
                    <ReactPlayerVimeo style={{border:"1px solid #000",borderRadius:4,backgroundColor:"#132d5a"}}url={videoList[0].src} controls pause={selectedIndex!=index} playing={selectedIndex==index?true:false} onPause={()=>console.log("onPause=",index)}  height="90%" width="90%" onPlay={()=>{{ console.log("onplay=",index);setSelectedIndex(index)}}} />
            
                </Box> */}
            </Box>

         </Box>
      </Modal>

  );
}