import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Checkbox,Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import { TABLE_COLORS,OPTIONS_TABLE_CHARTS } from './table_constants';
import AbcIcon from '@mui/icons-material/Abc';
import { ShowChartOutlined } from '@mui/icons-material';
import { USER_ROLE_FIELDS } from './constant';


export const DefaultRenderer=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
        {props.value!=undefined && props.value!==""?
            <span>{props.value}</span>:
            <>-</>
        }
        </div>
}

export const DefaultRendererWithLocale=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
        {props.value!=undefined?
            <span>{props.value.toLocaleString(props.locale_string)}</span>:
            <>-</>
        }
        </div>
}

export const DateRenderer=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
    <span>{props.value.date}</span>
    </div>
}

export const DefaultBooleanRenderer=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
        {props.value!=undefined && props.value!==""?
            <span>{props.value ? "True" : "False" }</span>:
            <>-</>
        }
        </div>
}

export const SubscriberRenderer=(props)=>{
  return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
      {props.value!=undefined && props.value!==""?
          <span>{props.value ? "PAID" : "-" }</span>:
          <>-</>
      }
      </div>
}

export const RolesRenderer=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
    {/* <span>{props.value.length} Roles: {props.value.map(item => <div>{item}</div>)}</span> */}
    <span>{props.value.join(",")}</span>
    </div>
}

export const ConsumedOrderRenderer=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
    {/* <span>{props.value.length} Roles: {props.value.map(item => <div>{item}</div>)}</span> */}
    <span>{props.value[props.value.length-1]}</span>
    </div>
}

export const SubscriptionListRenderer=(props)=>{
    return <div align="left" style={{display:'flex',width:"100%",alignSelf:"left", height:"100%"}}>
        {props.value!=undefined && props.value!==""?
            props.value.map(order => (
            //   order.order_status == "SUCCESS"? 
              order.subscription_status == "ACTIVE"? 
                <div>
                  {order.subscription_name} : {order.order_id} : {order.order_status} / {order.subscription_status}
                  &nbsp;
                  <br/>{order.subscription_age}
                </div>
              : "" 

                
            ))
          :
          <>-</>
        }
        </div>
}

export const TimeRenderer=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
            <span>{parseInt(props.value.split(":")[0])} Hours, {props.value.split(":")[1]} Mins</span>
        </div>
}


// cellRendererParams: {
//     locale_string: locale_string,

// },
export const getUserListColumnDef=(auth,locale_string)=>{
   
    let def;

    def=[ 
        
        { field: 'id',headerName:"#" ,minWidth: 50,maxWidth:50,cellRenderer:DefaultRenderer,
        cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'name',headerName:"User Name" ,minWidth: 180,maxWidth:200,cellRenderer:DefaultRenderer,
        cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'email',headerName:"Email" ,minWidth: 260,maxWidth:280,cellRenderer:DefaultRenderer,
        cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'mobile',headerName:"Mobile" ,minWidth:90,maxWidth:120,cellRenderer:DefaultRenderer,
        cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'active',headerName:"Active" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultBooleanRenderer,
        cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'active_subscriber',headerName:"Subscriber" ,minWidth: 90,maxWidth:120,cellRenderer:SubscriberRenderer,
        cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'roles',headerName:"Roles List" ,minWidth: 220,maxWidth:280,cellRenderer:RolesRenderer,
            cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'resources',headerName:"Resources" ,minWidth: 520,maxWidth:540,cellRenderer:RolesRenderer,
            cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'last_login_lapse',headerName:"Login Since" ,minWidth: 120,maxWidth:160,cellRenderer:TimeRenderer,
        cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'n_devices',headerName:"# Devices" ,minWidth: 60,maxWidth:100,cellRenderer:DefaultRenderer,
        cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'last_login',headerName:"Last Login On" ,minWidth: 120,maxWidth:160,cellRenderer:DefaultRenderer,
        cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'account_created',headerName:"Account Created On" ,minWidth: 120,maxWidth:160,cellRenderer:DefaultRenderer,
        cellStyle: function(params) {
            return { fontSize:12}
            }
        },
        { field: 'consumed_orders',headerName:"Last Consumed Order" ,minWidth: 400,maxWidth:450,cellRenderer:ConsumedOrderRenderer,
        cellStyle: function(params) {
            return { fontSize:12, alignSelf: "left", align:"left"}
            }
        },
        { field: 'order_records',headerName:"Active Order Record (Order Status / Subscription Status)" ,minWidth: 950,maxWidth:1000,cellRenderer:SubscriptionListRenderer,
        cellStyle: function(params) {
            return { fontSize:12, alignSelf: "left", align:"left"}
            }
        },
    ];

    return def;
}

