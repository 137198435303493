import React, { useLayoutEffect, useRef,useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import dark_theme from "@amcharts/amcharts5/themes/Dark";
import { Container, Box, Typography, useTheme, IconButton, Tooltip,Select,Menu,MenuItem,FormControl,InputLabel } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { WATERMARK_CONFIG,DateFormat,USER_ROLE_FIELDS,NO_OF_DAYS,MenuProps,HV_CHART_PLOT_TYPES,HV_CHART_V_TYPES,HV_OI_TYPES, MIN_HV_CHART_WIDTH } from '../../../common/utility/constant';
import dayjs from 'dayjs';
import { DatePicker} from 'antd';
import useAuth from '../../../common/hooks/useAuth';


const HvStockChartContainer = (props) => {
  const seriesAtrRef = useRef(null);
  const seriesStfRef = useRef(null);
  const seriesLtfRef = useRef(null);
  const candlestickSeriesRef = useRef(null);
  const seriesVolRef = useRef(null);
  // const sbSeriesRef = useRef(null);

  const theme = useTheme();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
  const [startDate,setStartDate]=useState(props.startDate!=""? dayjs(props.startDate,DateFormat):"");
  const [vType,setVType]=useState(props.vType);
  const [oiType,setOiType]=useState(props.oiType);
  const [plotType,setPlotType]=useState(props.plotType);

  // console.log(props);

  useLayoutEffect(() => {
    let root = am5.Root.new(props.id, {
      // useSafeResolution: false  TODO: itest incase charts break on iOS device uncomment it
    });

    root.setThemes([dark_theme.new(root)]);
    // Create a stock chart
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Instantiating_the_chart
    let stockChart = root.container.children.push(am5stock.StockChart.new(root, {
    }));

    // Create a main stock panel (chart)
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Adding_panels
    let panelTop = stockChart.panels.push(am5stock.StockPanel.new(root, {
      wheelY: "zoomX",
      panX: true,
      panY: true,
      height: am5.percent(100),
      paddingTop: 10,
      paddingRight: 15,
      paddingBottom: 0,
      paddingLeft: 15,
      layout: root.verticalLayout
    }));
    panelTop.panelControls.closeButton.set("forceHidden", true);

    let panelB = stockChart.panels.push(am5stock.StockPanel.new(root, {
      wheelY: "zoomX",
      panX: true,
      panY: true,
      height: am5.percent(40),
      paddingTop: 10,
      paddingRight: 15,
      paddingBottom: 0,
      paddingLeft: 15,
      layout: root.verticalLayout
    }));
    panelB.panelControls.closeButton.set("forceHidden", true);

    let panelC = stockChart.panels.push(am5stock.StockPanel.new(root, {
      wheelY: "zoomX",
      panX: true,
      panY: true,
      height: am5.percent(40),
      paddingTop: 10,
      paddingRight: 15,
      paddingBottom: 0,
      paddingLeft: 15,
      layout: root.verticalLayout
    }));
    panelC.panelControls.closeButton.set("forceHidden", true);

    let rendrerDateTimeTop = am5xy.AxisRendererX.new(root, {
      opposite: true
    });
    rendrerDateTimeTop.grid.template.setAll({
      location: 0,
      disabled: true,
    });
    rendrerDateTimeTop.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    });
    rendrerDateTimeTop.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99
    });

    //create date axis 
    let dateTimeAxisTop = am5xy.GaplessDateAxis.new(root, {
      baseInterval: { timeUnit: "day", count: 1 },
      dateFormats: {
        "day": "d MMM",
        "month": "MMM, yy",
        "year": "yyyy"
      },
      markUnitChange: false,
      tooltipDateFormat: "d MMM, yy",
      minHeight: 30,
      groupData: true,
      groupCount: 700,
      minZoomCount: 1,
      groupIntervals: [
        { timeUnit: "day", count: 1 },
        // { timeUnit: "month", count: 1 },
        // { timeUnit: "year", count: 1 }
      ],
      renderer: rendrerDateTimeTop
    });

    //push the datetime axis in the chart
    let xAxisTop = panelTop.xAxes.push(
      dateTimeAxisTop
    );
    
    let rendrerDateTimeB = am5xy.AxisRendererX.new(root, {
      opposite: true
    });
    rendrerDateTimeB.grid.template.setAll({
      location: 0,
      disabled: true,
    });
    rendrerDateTimeB.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    });
    rendrerDateTimeB.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99
    });
    let dateTimeAxisB =  am5xy.GaplessDateAxis.new(root, {
      baseInterval: { timeUnit: "day", count: 1 },
      dateFormats: {
        "day": "d MMM",
        "month": "MMM, yy",
        "year": "yyyy"
      },
      markUnitChange: false,
      tooltipDateFormat: "d MMM, yy",
      minHeight: 30,
      groupData: true,
      groupCount: 700,
      minZoomCount: 1,
      groupIntervals: [
        { timeUnit: "day", count: 1 },
        // { timeUnit: "month", count: 1 },
        // { timeUnit: "year", count: 1 }
      ],
      renderer: rendrerDateTimeB
    });

    let xAxisB= panelB.xAxes.push(
      dateTimeAxisB
    );

    let rendrerDateTimeC = am5xy.AxisRendererX.new(root, {
      opposite: true
    });
    rendrerDateTimeC.grid.template.setAll({
      location: 0,
      disabled: true,
    });
    rendrerDateTimeC.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    });
    rendrerDateTimeC.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99
    });
    let dateTimeAxisC =  am5xy.GaplessDateAxis.new(root, {
      baseInterval: { timeUnit: "day", count: 1 },
      dateFormats: {
        "day": "d MMM",
        "month": "MMM, yy",
        "year": "yyyy"
      },
      markUnitChange: false,
      tooltipDateFormat: "d MMM, yy",
      minHeight: 30,
      groupData: true,
      groupCount: 700,
      minZoomCount: 1,
      groupIntervals: [
        { timeUnit: "day", count: 1 },
        // { timeUnit: "month", count: 1 },
        // { timeUnit: "year", count: 1 }
      ],
      renderer: rendrerDateTimeC
    });

    let xAxisC= panelC.xAxes.push(
      dateTimeAxisC
    );

    let valueAxisLhs1Renderer = am5xy.AxisRendererY.new(root, {});
    valueAxisLhs1Renderer.labels.template.setAll({
      paddingLeft: 2,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 2,
      verticalCenter: "bottom",
      fontSize: "0.8em",
      // minPosition: 0.01,
      maxPosition: 0.95
    });
    valueAxisLhs1Renderer.grid.template.set("forceHidden", true);

    let valueAxisLhs1 = panelTop.yAxes.push(
      am5xy.ValueAxis.new(root, {
        //   height:am5.percent(40),
        marginTop: 10,
        marginLeft: 5,
        renderer: valueAxisLhs1Renderer
      })
    );

    let valueAxisRhs2;
    valueAxisRhs2 = panelB.yAxes.push(
      am5xy.ValueAxis.new(root, {
        height: am5.percent(100),
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    );

    let valueAxisRhs1 = panelTop.yAxes.push(
      am5xy.ValueAxis.new(root, {
        marginTop: 10,
        // height:am5.percent(40),
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    );

    let valueAxisRhs1Renderer = valueAxisRhs1.get("renderer");
    valueAxisRhs1Renderer.grid.template.set("forceHidden", true);
    valueAxisRhs1Renderer.labels.template.setAll({
      paddingLeft: 2,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 2,
      verticalCenter: "bottom",
      fontSize: "0.8em",
      maxPosition: 0.95,
      inside: false
    });

    let valueAxisLhs2;
    valueAxisLhs2 = panelC.yAxes.push(
      am5xy.ValueAxis.new(root, {
        marginTop: 10,
        renderer: am5xy.AxisRendererY.new(root, {
        }),
      })
    );


    let seriesAtr = panelTop.series.push(
      am5xy.LineSeries.new(root, {
        name: props.plotParams.get("plot")?.toUpperCase(),
        xAxis: xAxisTop,
        yAxis: valueAxisRhs1,
        valueYField: props.plotParams.get("plot"),
        valueXField: "date",
        stroke: am5.color("#ffffff"),
        fill: am5.color("#ffffff"),
        legendValueText: "[#81DAF5 bold]{valueY}[/]",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesAtr.strokes.template.setAll({
      strokeWidth: 2,
    });

    // seriesAtr.get("tooltip").label.set("text", props.plotParams.get("plot").toUpperCase() + ": {valueY}")

    // console.log("5_" + props.plotParams.get("vtype"));

    let seriesStf = panelB.series.push(
      am5xy.LineSeries.new(root, {
        name: "5_" + props.plotParams.get("vtype")?.toUpperCase(),
        xAxis: xAxisB,
        yAxis: valueAxisRhs2,
        valueYField: "5_" + props.plotParams.get("vtype"),
        valueXField: "date",
        stroke: am5.color("#F5C681"),
        fill: am5.color("#F5C681"),
        legendValueText: "[#F5C681 bold]{valueY}[/]",
        // height: am5.percent(80),
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesStf.strokes.template.setAll({
      strokeWidth: 1,
    });

    // seriesStf.get("tooltip").label.set("text", "5 " + props.plotParams.get("vtype").toUpperCase() + ": {valueY}")

    let seriesLtf = panelB.series.push(
      am5xy.LineSeries.new(root, {
        name: "20_" + props.plotParams.get("vtype")?.toUpperCase(),
        xAxis: xAxisB,
        yAxis: valueAxisRhs2,
        valueYField: "20_" + props.plotParams.get("vtype"),
        valueXField: "date",
        stroke: am5.color("#81DAF5"),
        fill: am5.color("#81DAF5"),
        legendValueText: "[#81DAF5 bold]{valueY}[/]",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesLtf.strokes.template.setAll({
      strokeWidth: 1,
    });

    // seriesLtf.get("tooltip").label.set("text", "20 " + props.plotParams.get("vtype").toUpperCase() + ": {valueY}")

    let seriesVol = panelC.series.push(
      am5xy.ColumnSeries.new(root, {
        name: props.plotParams.get("oitype")?.toUpperCase(),
        xAxis: xAxisC,
        // syncWithAxis: valueAxisLhs2,
        yAxis: valueAxisLhs2,
        valueYField: props.plotParams.get("oitype"),
        valueXField: "date",
        // tooltip: am5.Tooltip.new(root, {}),
        // stroke: am5.color("#81DAF5"),
        fill: am5.color("#9CD2E3"),
        legendValueText: "[#81DAF5 bold]{valueY}[/]",
      })
    );

    seriesVol.columns.template.setAll({
      fillOpacity: 0.8,
      strokeWidth: 0,
      width: am5.percent(80)
    });

    // seriesVol.get("tooltip").label.set("text", props.plotParams.get("oitype") + ": {valueY}")


    let candlestickSeries = panelTop.series.push(
      am5xy.CandlestickSeries.new(root, {
        name: "Price",
        clustered: false,
        xAxis: xAxisTop,
        yAxis: valueAxisLhs1,
        openValueYField: "open",
        highValueYField: "high",
        lowValueYField: "low",
        valueYField: "close",
        valueXField: "date",
        legendValueText: "O: [#81DAF5 bold]{openValueY}[/] \nH: [#81DAF5 bold]{highValueY}[/] \nL: [#81DAF5 bold]{lowValueY}[/] \nC: [#81DAF5 bold]{valueY}[/]",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    candlestickSeries.columns.template.states.create("riseFromOpen", {
      fill: am5.color(0x76b041),
      stroke: am5.color(0x76b041)
    });
    candlestickSeries.columns.template.states.create("dropFromOpen", {
      fill: am5.color(0xe4572e),
      stroke: am5.color(0xe4572e)
    });

    // candlestickSeries.get("tooltip").label.set("text", "Open: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")

    candlestickSeries.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd",
      dateFields: ["date"]
    });

    seriesAtr.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd",
      dateFields: ["date"]
    });
    seriesStf.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd",
      dateFields: ["date"]
    });
    seriesLtf.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd",
      dateFields: ["date"]
    });
    seriesVol.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd",
      dateFields: ["date"]
    });

    // candlestickSeries.get("tooltip").label.set("text", "[{column.fill}]Open: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")
    stockChart.set("stockSeries", candlestickSeries);
    var valueLegend = panelTop.topPlotContainer.children.push(am5stock.StockLegend.new(root, {
      stockChart: stockChart,
    }));
    valueLegend.data.setAll([candlestickSeries, seriesAtr]);

    var valueLegend1 = panelB.topPlotContainer.children.push(am5stock.StockLegend.new(root, {
      // stockChart: stockChart
    }));
    valueLegend1.data.setAll([seriesStf, seriesLtf]);

    var valueLegend2 = panelC.topPlotContainer.children.push(am5stock.StockLegend.new(root, {
      // stockChart: stockChart
    }));
    valueLegend2.data.setAll([seriesVol]);


    // //create chart title
    // stockChart.panelsContainer.children.unshift(am5.Label.new(root, {
    //   text: "NIFTY Futures Line Chart",
    //   fontSize: 24,
    //   fontWeight: "500",
    //   textAlign: "center",
    //   x: am5.percent(50),
    //   centerX: am5.percent(50),
    //   paddingTop: 0,
    //   paddingBottom: 4
    // }));

    // Add cursor for date time x axis
    panelTop.set("cursor", am5xy.XYCursor.new(root, {
      // behavior: "zoomXY",
      xAxis: xAxisTop
    }));

    panelB.set("cursor", am5xy.XYCursor.new(root, {
      // behavior: "zoomXY",
      xAxis: xAxisB
    }));

    panelC.set("cursor", am5xy.XYCursor.new(root, {
      // behavior: "zoomXY",
      xAxis: xAxisC
    }));

    //add tooltip for date time x axis
    xAxisTop.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    xAxisB.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    xAxisC.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));


    //add tooltip for date time x axis
    let tooltipRhs1 = valueAxisRhs1.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"],
    }));
    tooltipRhs1.get("background").setAll({
      fill: am5.color("#fff"),
      marginLeft: -5,
      marginRight: 5,
      fillOpacity: 1
    });

    //add tooltip for date time x axis
    let tooltipLhs1 = valueAxisLhs1.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"],
    }));
    tooltipLhs1.get("background").setAll({
      fill: am5.color("#00f"),
      marginLeft: 5,
      fillOpacity: 0.8
    });

    let tooltipRhs2 = valueAxisRhs2.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"],
    }));
    tooltipRhs2.get("background").setAll({
      fill: am5.color("#00f"),
      fillOpacity: 0.8
    });

    let tooltipLhs2 = valueAxisLhs2.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"],
    }));
    tooltipLhs2.get("background").setAll({
      fill: am5.color("#00f"),
      fillOpacity: 0.8
    });


    //add watermark text
    let copyright = panelTop.plotContainer.children.push(am5.Label.new(root, {
      text: WATERMARK_CONFIG.LC_TEXT,
      x: 10,
      y: am5.p100,
      centerY: am5.p100,
      dy: -10,
      opacity: 0.3,
      marginLeft: 10,
      marginBottom: 10,
      fontSize: 24,
      fontWeight: "400",
    }));
    
    //add watermark text
    let copyright1 = panelB.plotContainer.children.push(am5.Label.new(root, {
      text: WATERMARK_CONFIG.LC_TEXT,
      x: 10,
      y: am5.p100,
      centerY: am5.p100,
      dy: -10,
      opacity: 0.3,
      marginLeft: 10,
      marginBottom: 10,
      fontSize: 24,
      fontWeight: "400",
    }));

    //add watermark text
    let copyright2 = panelC.plotContainer.children.push(am5.Label.new(root, {
      text: WATERMARK_CONFIG.LC_TEXT,
      x: 10,
      y: am5.p100,
      centerY: am5.p100,
      dy: -10,
      opacity: 0.3,
      marginLeft: 10,
      marginBottom: 10,
      fontSize: 24,
      fontWeight: "400",
    }));

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    let scrollbar = panelTop.set("scrollbarX", am5xy.XYChartScrollbar.new(root, {
      orientation: "horizontal",
      height: 20
    }));
    stockChart.toolsContainer.children.push(scrollbar);

    // let sbRendrerDateTime = am5xy.AxisRendererX.new(root, {
    // });
    // sbRendrerDateTime.grid.template.setAll({
    //     location: 0,
    //     disabled: true,
    // });
    // sbRendrerDateTime.ticks.template.setAll({
    //     length: 8,
    //     strokeOpacity: 0.2,
    //     disabled: false,
    // });
    // sbRendrerDateTime.labels.template.setAll({
    //     minPosition: 0.01,
    //     maxPosition: 0.99
    // });

    // //create date axis 
    // let sbDateTimeAxis = am5xy.GaplessDateAxis.new(root, {
    //     baseInterval: { timeUnit: "day", count: 1 },
    //     dateFormats: {
    //         "day": "d MMM",
    //         "month": "MMM, yy",
    //         "year": "yyyy"
    //     },
    //     markUnitChange: false,
    //     tooltipDateFormat: "d MMM, yy",
    //     // minHeight:30,
    //     groupData: true,
    //     groupCount: 700,
    //     minZoomCount: 1,
    //     groupIntervals: [
    //         { timeUnit: "day", count: 1 },
    //         { timeUnit: "month", count: 1 },
    //         { timeUnit: "year", count: 1 }
    //     ],
    //     renderer: sbRendrerDateTime
    // });

    // //push the datetime axis in the chart
    // let sbDateAxis = scrollbar.chart.xAxes.push(
    //     sbDateTimeAxis
    // );


    // let sbValueAxis = scrollbar.chart.yAxes.push(am5xy.ValueAxis.new(root, {
    //     renderer: am5xy.AxisRendererY.new(root, {})
    // }));

    // let sbSeries = scrollbar.chart.series.push(am5xy.LineSeries.new(root, {
    //     valueYField: "close",
    //     valueXField: "time",
    //     xAxis: sbDateAxis,
    //     yAxis: sbValueAxis
    // }));

    // sbSeries.fills.template.setAll({
    //     visible: true,
    //     fillOpacity: 0.3
    // });


    // add Bollinger Bands indicator
    // let bollingerBands = stockChart.indicators.push(am5stock.BollingerBands.new(root, {
    //     stockChart: stockChart,
    //     stockSeries: candlestickSeries,
    //     legend: valueLegend
    // }));

    //https://www.amcharts.com/docs/v5/charts/stock/toolbar/
    let toolbar = am5stock.StockToolbar.new(root, {
      container: document.getElementById("chartcontrols"+props.id),
      stockChart: stockChart,
      controls: [
        am5stock.IndicatorControl.new(root, {
          stockChart: stockChart,
          legend: valueLegend
        }),
        am5stock.DateRangeSelector.new(root, {
          stockChart: stockChart
        }),
        am5stock.PeriodSelector.new(root, {
          stockChart: stockChart
        }),

        am5stock.DrawingControl.new(root, {
          stockChart: stockChart
        }),
        am5stock.ResetControl.new(root, {
          stockChart: stockChart
        }),
        am5stock.SettingsControl.new(root, {
          stockChart: stockChart
        })
      ]
    })

    seriesAtrRef.current = seriesAtr;
    seriesLtfRef.current = seriesLtf;
    seriesStfRef.current = seriesStf;
    candlestickSeriesRef.current = candlestickSeries;
    seriesVolRef.current = seriesVol;
    // sbSeriesRef.current = sbSeries;

    return () => {
      root.dispose();
    };

  }, []);


  //this will handle the props data change and set/update the chart
  useLayoutEffect(() => {
    // console.log("uselayout effect child ===========>>>>>>>>>>>>>>>");
    //1st load
    if (props.chartData != undefined) {
      console.log("If uselayout effect child ===========>>>>>>>>>>>>>>>", props.chartData);
      seriesAtrRef.current.data.setAll(props.chartData);
      seriesLtfRef.current.data.setAll(props.chartData);
      seriesStfRef.current.data.setAll(props.chartData);
      candlestickSeriesRef.current.data.setAll(props.chartData);
      seriesVolRef.current.data.setAll(props.chartData);
      // sbSeriesRef.current.data.setAll(props.chartData);
    }
  }, [props.chartData]);

  function toggleFullScreen() {
    let chartdiv = document.querySelector(`#${props.id}`)
    if (!document.fullscreenElement) {
      chartdiv.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }


  const handleVTypeChange=(event)=>{
    setVType(event.target.value);
    props.changeVType(event.target.value);
  }

  const handleOiTypeChange=(event)=>{
    setOiType(event.target.value);
    props.changeOiType(event.target.value);
  }

  const handlePlotTypeChange=(event)=>{
    setPlotType(event.target.value);
    props.changePlotType(event.target.value);
  }
  const disabledDate=(current)=> {
    return (current && current >= dayjs().endOf('day'))||dayjs(current).day()==6 || dayjs(current).day()==0
  }
  const onDateChange = (date, dateString) => {
    setStartDate(dayjs(date))
    console.log("Date changed",dayjs(date),dateString);
    props.handleStartDateChange(dateString);
  } 

  return (
    <>
      <div id="container" style={{ backgroundColor: "#111111", zIndex: "99", marginTop: "0px", padding: "20px", width: "100%",minWidth:`${MIN_HV_CHART_WIDTH}`, minHeight: "calc(110vh - 256px)" }}>
        {/* <LineChartHeader/> */}
        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: "row", justifyContent: "space-evenly" }}>
          {/* <Tooltip title="Go Back">
            <IconButton
              onClick={() => navigate(-1)}
              size='large'>
              <ArrowBackIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
          {/* <Container sx={{ width: "80%" }}>
            <Typography variant='h4' color='primary' sx={{ alignSelf: "center" }}>{props.symbol} Historical Volatility Line Chart</Typography>
          </Container>
          <Tooltip title="Full Screen">
            <IconButton
              onClick={() => toggleFullScreen()}
              size='large'>
              <FullscreenIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
        </Box>
        <div id="chartContainer">
          <div id={"chartcontrols"+props.id} style={{ padding: "0px", marginTop: "16px", maxWidth: "100%",display:"flex" }}>
          {!isBasicUser?
          <>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4}}>
            {/* <Typography sx={{mt:1,mr:0,fontSize:16,width:"80px"}}>V Type</Typography> */}
        
            <FormControl size="small" sx={{ width:"120px",minWidth:'160px',ml:0,mt:0 }}>
              <InputLabel id="demo-simple-select-label">V Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={vType}
                MenuProps={MenuProps}
                sx={{height:"40px"}}
                label="V Type"
                onChange={(event)=>handleVTypeChange(event)}
              >
                {HV_CHART_V_TYPES.map((item,index) => (
                <MenuItem value={item.value}>{item.key}</MenuItem>
                ))}
              </Select>
          </FormControl>
           {/* <Typography sx={{mt:1,ml:2,fontSize:16,width:"90px"}}>Plot Type</Typography> */}
        
        <FormControl size="small" sx={{ width:"80px",minWidth:'100px',ml:3,mt:0 }}>
          <InputLabel id="demo-simple-select-label">Plot Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={plotType}
            MenuProps={MenuProps}
            sx={{height:"40px"}}
            label="Plot Type"
            onChange={(event)=>handlePlotTypeChange(event)}
          >
            {HV_CHART_PLOT_TYPES.map((item,index) => (
            <MenuItem value={item.value}>{item.key}</MenuItem>
            ))}
          </Select>
      </FormControl>

      <FormControl size="small" sx={{ width:"80px",minWidth:'100px',ml:3,mt:0 }}>
          <InputLabel id="demo-simple-select-label">OI/Vol Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={oiType}
            MenuProps={MenuProps}
            sx={{height:"40px"}}
            label="OI Type"
            onChange={(event)=>handleOiTypeChange(event)}
          >
            {HV_OI_TYPES.map((item,index) => (
            <MenuItem value={item.value}>{item.key}</MenuItem>
            ))}
          </Select>
      </FormControl>
      
      <DatePicker onChange={onDateChange} 
            allowClear={false}
            disabledDate={disabledDate}
            value={startDate}
            format={DateFormat}
            placeholder="Select Start Date"
            style={{border: "1px solid #484848",background:"#111111",
            borderRadius: "4px",width:200,marginLeft:24,marginTop:"0px",marginRight:"10px"}}
            /> 
      </Box>
      </>
      :<></>}
          </div>
          <div id={props.id} style={{ width: "100%", maxWidth: "100%", height: "800px" }} />
        </div>
      </div>
    </>
  )
}

export default HvStockChartContainer
