import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Checkbox,Tooltip,TextField,Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TABLE_COLORS,OPTIONS_TABLE_CHARTS,GREEKS_TABLE_CHARTS } from './table_constants';
import AbcIcon from '@mui/icons-material/Abc';
import { ShowChartOutlined } from '@mui/icons-material';
import { USER_ROLE_FIELDS } from './constant';
import { height } from '@mui/system';

const COLORS={

    GREEN:"#49BD38",
    RED:"#EA1529",
    ORANGE:"#b0946b",
    BLUE:"#3c75dc",
}

export const CallCheckBoxRenderer = (props) => {
    const checkedHandler=(event)=> {
        let checked = event.target.checked;
        let colId = props.column.colId;
        console.log("checkbox clicked call=",checked,colId,props)
        props.node.setDataValue(colId, checked);
        props.handleChange(colId,checked,props.rowIndex);
      }
    return (
        <>
        {props.data.ce_instr!=undefined?
        <Checkbox 
            checked={props.data.ce_checked}
            onClick={checkedHandler}
        />:
        <></>
        } 
        </>  
  )
}

export const PutCheckBoxRenderer = (props) => {
    const checkedHandler=(event)=> {
        let checked = event.target.checked;
        let colId = props.column.colId;
        console.log("checkbox clicked put=",checked,colId,props)
        props.node.setDataValue(colId, checked);
        props.handleChange(colId,checked,props.rowIndex,props.data);
      }
    return (
        <>
        {props.data.pe_instr!=undefined?
            <Checkbox 
                checked={props.data.pe_checked}
                onClick={checkedHandler}
            />:
            <></>
        } 
        </>   
  )
}


export const DefaultRenderer=(props)=>{
    return <div style={{alignSelf:"center",alignItems:"center !important"}}>
        {props.value!=undefined?
            <span>{props.value}</span>:
            <>-</>
        }
        </div>
}



export const StrikeRenderer=(props)=>{
    // const handleMP=()=>{
    //     props.handleMPChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL_PUT_BOTH,props.data);
    // }
    // const handleLine=()=>{
    //     props.handleLineChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL_PUT_BOTH,props.data); 
    // }
   
    // console.log("Strike row index=",props.rowIndex);
    return <div style={{alignSelf:"center",display:"flex",width:"100%",justifyContent:"center", alignItems:"center !important"}}>
                <span>{props.value}</span>
                {/* <div>
                {props.data.pe_instr!=undefined && props.data.ce_instr!=undefined?
                <>        
                    <Tooltip title={props.value + " Straddle MP chart"}>
                    <AbcIcon onClick={handleMP}sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}></AbcIcon>
                    </Tooltip>
                    <Tooltip title={props.value + " Straddle Line chart"}>
                    <ShowChartOutlined onClick={handleLine} sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}/>
                    </Tooltip>
                </>:
                <></>
                }
                </div> */}
            </div>
}


//Greeks call renderer
export const CallCellRendrer=(props)=>{
   
    
    // const data=props.data.data.get(props.colID);
    const [data,setData]=useState(props.data.data.get(props.colID));
    const [qunatity,setQuantity]=useState(data.ce_qty?data.ce_qty:0);
    const [cost,setCost]=useState(data.ce_cost?data.ce_cost:0);
    const [costChangeVal,setCostChangeVal]=useState(props.isCalculateTarget?((data.ce_price-data.ce_cost)*Math.sign(data.ce_qty)).toFixed(2):((data.ce_ltp-data.ce_cost)*Math.sign(data.ce_qty)).toFixed(2));
    const [ltpVal,setLtpVal]=useState(props.isCalculateTarget?data.ce_price:data.ce_ltp);
    const [ltpChangeVal,setLtpChangeVal]=useState(props.isCalculateTarget?(data.ce_price-data.ce_ltp).toFixed(2):(data.ce_ltp-data.ce_pprice).toFixed(2));
    useEffect(()=>{
        if(props.rowIndex==0)
        console.log("useEffect call value=",props.data.data.get(props.colID),props.colID);
        setData(props.data.data.get(props.colID))
        setQuantity(props.data.data.get(props.colID).ce_qty)
        setCost(props.data.data.get(props.colID).ce_cost)
        setCostChangeVal(props.isCalculateTarget?((props.data.data.get(props.colID).ce_price-props.data.data.get(props.colID).ce_cost)*Math.sign(data.ce_qty)).toFixed(2):((props.data.data.get(props.colID).ce_ltp-props.data.data.get(props.colID).ce_cost)*Math.sign(data.ce_qty)).toFixed(2))
        setLtpVal(props.isCalculateTarget?props.data.data.get(props.colID).ce_price:props.data.data.get(props.colID).ce_ltp);
        setLtpChangeVal(props.isCalculateTarget?(props.data.data.get(props.colID).ce_price-props.data.data.get(props.colID).ce_ltp).toFixed(2):(props.data.data.get(props.colID).ce_ltp-props.data.data.get(props.colID).ce_pprice).toFixed(2))

    },[props,props.data,props.data.data])
    // if(props.rowIndex==3)
    // console.log("Call row index=",props.rowIndex,data.ce_qty,data.ce_vega,data.ce_gamma)
    
    
    const handleChange = (event) => {
        let newValue=event.target.value.replace(".","");
        // console.log("call cell renderer=",props.data,props.colID,newValue)
        setQuantity(newValue);
        props.handleChange(props.data,props.colID,newValue)
    };

    const handleMP=()=>{
        props.handleMPChart(GREEKS_TABLE_CHARTS.CHART_TYPE_CALL, data);
    }
    const handleLine=()=>{
        props.handleLineChart(GREEKS_TABLE_CHARTS.CHART_TYPE_CALL, data);
    }
    const increment=()=>{
        if(qunatity==0 || qunatity==undefined || qunatity==""){
            setQuantity(1)
            props.handleChange(props.data,props.colID,1)
        }
           
        else{
            setQuantity(qunatity+1)
            props.handleChange(props.data,props.colID,qunatity+1)
        }
    }
    const decrement=()=>{
        if(qunatity==0 || qunatity==undefined || qunatity==""){
            setQuantity(-1)
            props.handleChange(props.data,props.colID,-1)
        }
            
        else{
            setQuantity(qunatity-1)
            props.handleChange(props.data,props.colID,qunatity-1)
        }
            

    }

    // let ltp=data.ce_ltp;
    // let ltpChange=(data.ce_ltp-data.ce_pprice).toFixed(2);
    // if(props.isCalculateTarget){
       
    //     ltp=data.ce_price;
    //     ltpChange=(data.ce_price-data.ce_ltp).toFixed(2);
    //     if(props.rowIndex==0)
    //     console.log("iscalculate target is true call renderer",ltp,ltpChange,props.colID)
    // }
    // console.log("props and key Renderer function=",props,props.colID,props.data.data.get(props.colID))
    return <div style={{display:"flex", justifyContent:"flex-start",width:"100%",flexDirection:"column", backgroundColor:qunatity!=0?TABLE_COLORS.SELECTED_ROW_COLOR:null}}>
        <div>
            {!props.isBasicUser?
            <div style={{marginTop:15}}>
            
                <TextField id="outlined-basic" size="small" label="" variant="outlined" 
                    value={qunatity}
                    onChange={handleChange}
                    type="number"
                    inputProps={{
                        style: {
                        padding:'0 4px 0 4px',  
                        height: "28px",
                        },
                    }}
                    sx={{width:80,height:28,minHeight:28}} />

            <Tooltip title="Sell"> 
                <Button onClick={decrement} variant="outline"  sx={{ml:1,fontSize:"14px",height:28,width:28,padding:0,minWidth:28,borderRadius:"1px",background:TABLE_COLORS.RED,border:'1px solid #fff'}}>S</Button>
                </Tooltip>
            <Tooltip title="Buy">     
                <Button onClick={increment} variant="outline"  sx={{ml:1,fontSize:"14px",height:28,width:28,padding:0,minWidth:28,borderRadius:"1px",background:COLORS.GREEN,border:'1px solid #fff'}}>B</Button>
            </Tooltip>
            
            </div>:<>
        </>}
        </div>
        <div style={{display:"flex",flexDirection:'row',justifyContent:"flex-start", marginTop:props.isBasicUser?"20px":"20px"}}>
          <div style={{display:"flex",flexDirection:'column',width:30}}>
          {data.ce_instr!=undefined ?
                <>  
            <Tooltip title={props.data.key + " Call MP chart"}>
                    <AbcIcon onClick={handleMP}sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:0,height:24,cursor:'pointer'}}></AbcIcon>
                    </Tooltip>
                    <Tooltip title={props.data.key + " Call Line chart"}>
                    <ShowChartOutlined onClick={handleLine} sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:0,mt:1,height:24,cursor:'pointer'}}/>
                    </Tooltip>
                    </>:<>
                    </>}
          </div>
          <div style={{display:"flex",flexDirection:'column',justifyContent:"flex-end",marginLeft:"10px",marginTop:qunatity!=0?"-12px":"-4px"}}>
            {qunatity!=0
              ? <div style={{display:"flex",flexDirection:'row'}}>
                <span>{qunatity<0?"Sell ":"Buy "}Cost:&nbsp;{cost}</span>
                    {costChangeVal>=0?
                        <>
                            <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20,marginTop:"-2px"}}/>
                        </>:
                        <>
                            <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20,marginTop:"-2px"}}/>
                        </>
                        }
                        <span style={{color: costChangeVal>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{costChangeVal}</span>
                </div>
              : <></>
            }
            <div style={{display:"flex",flexDirection:'row'}}>
            <span>{props.isCalculateTarget?"Price":"LTP"}:&nbsp;{ltpVal}</span>
                {ltpChangeVal>=0?
                    <>
                        <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20,marginTop:"-2px"}}/>
                    </>:
                    <>
                        <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20,marginTop:"-2px"}}/>
                    </>
                    }
                    <span style={{color: ltpChangeVal>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{ltpChangeVal}</span>
            </div>
            <div style={{display:"flex",flexDirection:'row'}}>
                <span title='Implied Volatility'>IV:&nbsp;{data.ce_iv},&nbsp;</span>
                <span title='Vega: Change in price, wrt change in IV - if IV increases or decreases, how sensitive is the option price to increase or decrease'>Vg:&nbsp;{data.ce_vega}</span>    
            </div>
            <div style={{display:"flex",flexDirection:'row'}}>
                <span title="Delta: Change in price with change in underlying">D:&nbsp;{data.ce_delta},&nbsp;</span>
                <span title="Gamma: Change in Delta with change in underlying - rate of acceleration in price">G:&nbsp;{data.ce_gamma},&nbsp;</span>
                <span title="Theta: Change in price as time passes, all other things remain same - price decay">T:&nbsp;{data.ce_theta}</span>       
            </div>
            <div style={{display:"flex",flexDirection:'row'}}>
                <span title="Open Interest">OI:&nbsp;{data.ce_oi.toLocaleString(data.locale_string)}&nbsp;&nbsp;</span>
                <span title='Change in Open Interest'>dOI:&nbsp;</span> 
                {data.ce_doi>=0?
                    <>
                        <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20,marginTop:"-2px",marginLeft:"-5px"}}/>
                    </>:
                    <>
                        <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20,marginTop:"-2px",marginLeft:"-5px"}}/>
                    </>
                    }
                    <span style={{color: data.ce_doi>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{data.ce_doi.toLocaleString(data.locale_string)}</span> 
               
            </div>
          
          </div>
        </div>
    </div>
}


//Greeks put renderer
export const PutCellRendrer=(props)=>{
    const [data,setData]=useState(props.data.data.get(props.colID));
    const [qunatity,setQuantity]=useState(data.pe_qty?data.pe_qty:0);
    const [cost,setCost]=useState(data.pe_cost?data.pe_cost:0);
    const [costChangeVal,setCostChangeVal]=useState(props.isCalculateTarget?((data.pe_price-data.pe_cost)*Math.sign(data.pe_qty)).toFixed(2):((data.pe_ltp-data.pe_cost)*Math.sign(data.pe_qty)).toFixed(2));
    const [ltpVal,setLtpVal]=useState(props.isCalculateTarget?data.pe_price:data.pe_ltp);
    const [ltpChangeVal,setLtpChangeVal]=useState(props.isCalculateTarget?(data.pe_price-data.pe_ltp).toFixed(2):(data.pe_ltp-data.pe_pprice).toFixed(2));
    useEffect(()=>{
        if(props.rowIndex==0)
        console.log("useEffect put value=",props.data.data.get(props.colID),props.colID);
        setData(props.data.data.get(props.colID));
        setQuantity(props.data.data.get(props.colID).pe_qty);
        setCost(props.data.data.get(props.colID).pe_cost)
        setCostChangeVal(props.isCalculateTarget?((props.data.data.get(props.colID).pe_price-props.data.data.get(props.colID).pe_cost)*Math.sign(data.pe_qty)).toFixed(2):((props.data.data.get(props.colID).pe_ltp-props.data.data.get(props.colID).pe_cost)*Math.sign(data.pe_qty)).toFixed(2))
        setLtpVal(props.isCalculateTarget?props.data.data.get(props.colID).pe_price:props.data.data.get(props.colID).pe_ltp);
        setLtpChangeVal(props.isCalculateTarget?(props.data.data.get(props.colID).pe_price-props.data.data.get(props.colID).pe_ltp).toFixed(2):(props.data.data.get(props.colID).pe_ltp-props.data.data.get(props.colID).pe_pprice).toFixed(2))

    },[props])
   
    // useEffect(()=>{
    //     props.handleChange(props.data,props.colID,qunatity)
    // },[qunatity])
   
   
    
    const handleChange = (event) => {
        let newValue=event.target.value.replace(".","");
        // console.log("putcell renderer=",props.data,props.colID,newValue,event.target.value,newValue)
        setQuantity(newValue);
        props.handleChange(props.data,props.colID,newValue)

     
    };

    const handleMP=()=>{
        props.handleMPChart(GREEKS_TABLE_CHARTS.CHART_TYPE_PUT, data);
    }
    const handleLine=()=>{
        props.handleLineChart(GREEKS_TABLE_CHARTS.CHART_TYPE_PUT, data);
    }
    const increment=()=>{
        if(qunatity==0 || qunatity==undefined || qunatity==""){
            setQuantity(1)
            props.handleChange(props.data,props.colID,1)
        }
           
        else{
            setQuantity(qunatity+1)
            props.handleChange(props.data,props.colID,qunatity+1)
        }
    }
    const decrement=()=>{
        if(qunatity==0 || qunatity==undefined || qunatity==""){
            setQuantity(-1)
            props.handleChange(props.data,props.colID,-1)
        }
            
        else{
            setQuantity(qunatity-1)
            props.handleChange(props.data,props.colID,qunatity-1)
        }
            

    }

  
    // let ltp=data.pe_ltp;
    // let ltpChange=(data.pe_ltp-data.pe_pprice).toFixed(2);
    // if(props.isCalculateTarget){
    //     // console.log("iscalculate target is true put renderer")
    //     ltp=data.pe_price;
    //     ltpChange=(data.pe_price-data.pe_ltp).toFixed(2);
    // }
    // console.log("props and key Renderer function=",props,props.colID,props.data.data.get(props.colID))
    return  <div style={{display:"flex", justifyContent:"flex-start",width:"100%",flexDirection:"column", backgroundColor:qunatity!=0?TABLE_COLORS.SELECTED_ROW_COLOR:null}}>
        <div>
        {!props.isBasicUser?
            <div style={{marginTop:15}}>
              <TextField id="outlined-basic" size="small" label="" variant="outlined" 
                    value={qunatity}
                    onChange={handleChange}
                    type="number"
                    inputProps={{
                        style: {
                        padding:'0 4px 0 4px',  
                        height: "28px",
                        },
                    }}
                    sx={{width:80,height:28,minHeight:28}} />
                    
            <Tooltip title="Sell"> 
                <Button onClick={decrement} variant="outline"  sx={{ml:1,fontSize:"14px",height:28,width:28,padding:0,minWidth:28,borderRadius:"1px",background:TABLE_COLORS.RED,border:'1px solid #fff'}}>S</Button>
                </Tooltip>
            <Tooltip title="Buy">     
                <Button onClick={increment} variant="outline"  sx={{ml:1,fontSize:"14px",height:28,width:28,padding:0,minWidth:28,borderRadius:"1px",background:COLORS.GREEN,border:'1px solid #fff'}}>B</Button>
            </Tooltip>
        
            </div>:<></>}
        </div>
        <div style={{display:"flex",flexDirection:'row',justifyContent:"start",marginTop:props.isBasicUser?"20px":"20px"}}>
          <div style={{display:"flex",flexDirection:'column',width:30}}>
          {data.ce_instr!=undefined ?
                <>  
                    <Tooltip title={props.data.key + " Put MP chart"}>
                        <AbcIcon onClick={handleMP}sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:0,height:24,cursor:'pointer'}}></AbcIcon>
                    </Tooltip>
                    <Tooltip title={props.data.key + " Put Line chart"}>
                        <ShowChartOutlined onClick={handleLine} sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:0,mt:1,height:24,cursor:'pointer'}}/>
                    </Tooltip>
                    </>:
                    <></>}
          </div>
          <div style={{display:"flex",flexDirection:'column',justifyContent:"flex-start",marginLeft:"10px",marginTop:qunatity!=0?"-12px":"-4px"}}>
            {qunatity!=0
              ? <div style={{display:"flex",flexDirection:'row'}}>
                <span>{qunatity<0?"Sell ":"Buy "}Cost:&nbsp;{cost}</span>
                    {costChangeVal>=0?
                        <>
                            <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20,marginTop:"-2px"}}/>
                        </>:
                        <>
                            <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20,marginTop:"-2px"}}/>
                        </>
                        }
                        <span style={{color: costChangeVal>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{costChangeVal}</span>
                </div>
              : <></>
            }
            <div style={{display:"flex",flexDirection:'row'}}>
                <span>{props.isCalculateTarget?"Price":"LTP"}:&nbsp;{ltpVal}</span>
                {ltpChangeVal>=0?
                    <>
                        <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20,marginTop:"-2px"}}/>
                    </>:
                    <>
                        <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20,marginTop:"-2px"}}/>
                    </>
                    }
                    <span style={{color: ltpChangeVal>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{ltpChangeVal}</span>
            </div>
            <div style={{display:"flex",flexDirection:'row'}}>
                <span title='Implied Volatility'>IV:&nbsp;{data.pe_iv},&nbsp;</span>
                <span title='Vega: Change in price, wrt change in IV - if IV increases or decreases, how sensitive is the option price to increase or decrease'>Vg:&nbsp;{data.pe_vega}</span>    
            </div>
            <div style={{display:"flex",flexDirection:'row'}}>
                <span title="Delta: Change in price with change in underlying">D:&nbsp;{data.pe_delta},&nbsp;</span>
                <span title="Gamma: Change in Delta with change in underlying - rate of acceleration in price">G:&nbsp;{data.pe_gamma},&nbsp;</span>
                <span title="Theta: Change in price as time passes, all other things remain same - price decay">T:&nbsp;{data.pe_theta}</span>       
            </div>
            <div style={{display:"flex",flexDirection:'row'}}>
                <span title="Open Interest">OI:&nbsp;{data.pe_oi.toLocaleString(data.locale_string)}&nbsp;&nbsp;</span>
                <span title='Change in Open Interest'>dOI:&nbsp;</span> 
                {data.pe_doi>=0?
                    <>
                        <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20,marginTop:"-2px",marginLeft:"-5px"}}/>
                    </>:
                    <>
                        <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20,marginTop:"-2px",marginLeft:"-5px"}}/>
                    </>
                    }
                    <span style={{color: data.pe_doi>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{data.pe_doi.toLocaleString(data.locale_string)}</span> 
            </div>
          
          </div>
        </div>
    </div>
}

const getCall=()=>{
    let func=CallCellRendrer;
    return func;
}
const getColumnList=(expiry,prefix,openLineChart,openMPChart,isCalculateTarget,changeHandler,isBasicUser)=>{
    let columnData=[];
    if(prefix=="call"){
        for (let i=expiry.length-1;i>=0;i--) {
            let key= prefix+expiry[i]  
            let column={};
            column.field=prefix+expiry[i];
            column.headerName=expiry[i];
            column.minWidth=240;
            column.maxWidth=260;
            column.cellRenderer=getCall();
            column.cellRendererParams={
                colID: key,
                handleLineChart: openLineChart,
                handleMPChart:openMPChart,
                isCalculateTarget:isCalculateTarget,
                handleChange:changeHandler,
                isBasicUser:isBasicUser
            };
            column.cellStyle=function(params){
                return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};  
            }
            columnData.push(column);
    }
    }
    else{
        for (let i=0;i<expiry.length;i++) {
            let column={};
            let key= prefix+expiry[i]  
            column.field=prefix+expiry[i];
            column.headerName=expiry[i];
            column.minWidth=240;
            column.maxWidth=260;
            column.cellRenderer=PutCellRendrer;
            column.cellRendererParams={
                    colID: key,
                    handleLineChart: openLineChart,
                    handleMPChart:openMPChart,
                    isCalculateTarget:isCalculateTarget,
                    handleChange:changeHandler,
                    isBasicUser:isBasicUser
                };
            column.cellStyle=function(params){
                return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};  
            }
            columnData.push(column);
        }  
    }
    // console.log("columnData= ",columnData)
    return columnData;
}
 export const getColumneDef=(funCallChangeHandler,funPutChangeHandler,auth,openLineChart,openMPChart,expiryList,data,isCalculateTarget,isBasicUser,lastDataTime)=>{
    // console.log("getColumneDef expiry, data",expiryList)
    let callChildren=getColumnList(expiryList,"call",openLineChart,openMPChart,isCalculateTarget,funCallChangeHandler,isBasicUser);
    let putChildren=getColumnList(expiryList,"put",openLineChart,openMPChart,isCalculateTarget,funPutChangeHandler,isBasicUser);
    // console.log("callchildren=",callChildren)
    // console.log("putchildren=",putChildren)
    let proUser=auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA)?true:false;
    let time="";
    if(lastDataTime!=undefined){
        let data=lastDataTime.split(" ");
        time=data[1]?data[1]:""
    }
    const def=[
            {
                headerName:"Calls",
                groupId:"call_group",
                children:callChildren
            },
            {
                // headerName:time,
                headerName:"",
                children:[
                    { field: 'key',headerName:"Strike",minWidth:100,maxWidth:100,cellRenderer:StrikeRenderer,
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR: TABLE_COLORS.STRIKE_BACKGROUND_COLOR};
                    },
                    cellRendererParams: {
                        handleLineChart: openLineChart,
                        handleMPChart:openMPChart
                    },  
                },
                   
                   
                ]
            },
          
            
            {
                headerName:"Puts",
                groupId:"puts_group",
                children:putChildren
            },
           
          
            ]
            console.log("final column def=",def);
            return def;
    }
    
