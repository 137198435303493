import {Link, Grid } from "@mui/material"

/**
 * Component that will render in case user has typed some wrong url inside the app
 * @returns 
 */
const Missing = () => {
    return (
        <Grid component="main" container display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{height: `calc(100vh - 64px)` }}>
            
            <h1>Hello!</h1>
            <p>This page is currently under construction. Please check back again in some time.</p>
            <div className="flexGrow">
                <Link href="/" variant="link">Back to Homepage</Link>
            </div>
        </Grid>
    )
}

export default Missing