import React from 'react'
import { useEffect,useState,useMemo,useCallback,useContext,useRef } from 'react'
import { URL } from '../../common/api/urls';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS,CHART_RELOAD_TIME, USER_RESOURCES_LIST } from '../../common/utility/constant';
import { CircularProgress ,Button,Tooltip} from '@mui/material';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION_SHORT,MIN_TABLE_CONTAINER_WIDTH} from '../../common/utility/constant';
import {Snackbar, Alert} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import {Tab,Tabs} from '@mui/material';
import TICKER_TABLE from './components/ticker_table';
import OptionsFooter from './components/options_footer';
import {OPTIONS_TABLE_CHARTS} from '../../common/utility/table_constants';
import { useWindowSize } from '../../common/hooks/useWindowSize';
import { useOutletContext } from "react-router-dom";
import { ToolbarFixed } from '../../layouts/component.js';
import { Typography, Container,Select,FormControl,Menu,MenuItem,Checkbox,InputLabel,Box,Slider} from '@mui/material'
import {styled, useTheme} from '@mui/material/styles';
import { UserSettingsContext } from '../../setup/routes-manager/RequireAuth'
import { WATERMARK_CONFIG,DateFormat,NO_OF_DAYS,MenuProps,SNAKE_CHART_PLOT_TYPE,MARKET_CLOSE_TIME,MARKET_OPEN_TIME } from '../../common/utility/constant';
import dayjs from 'dayjs';
import { DatePicker} from 'antd';
import {useSearchParams} from 'react-router-dom';
import { createDictList } from '../../common/utility/helperFunc';
import SubscriptionPopup from './components/subscription_popup';
import SettingsIcon from '@mui/icons-material/Settings';
import LoginModal from './components/login_popups';



import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { getColumneDef,getColumneDefMetadata, CallCheckBoxRenderer,PutCheckBoxRenderer,
  CallCHGLTPRenderer,PutCHGLTPRenderer,StrikeRenderer
  } from '../../common/utility/options_columnDefs';

  import '../dashboard/styles/options.scss'
import { processMetaData, processOptionsTableData } from '../../common/utility/preProcessor';
import OptionsSettings from './components/options_settings';

// import { CallCheckBoxRenderer,PutCheckBoxRenderer } from './components/options_table_renderer';
const gridOptions = {
  suppressColumnVirtualisation:true,
  suppressMovableColumns:true,
  suppressColumnMoveAnimation:true,
  suppressRowVirtualisation:true,
  enableCellTextSelection:true,
  ensureDomOrder:true
}
const gridOptionsMetaData = {
  suppressColumnVirtualisation:true,
  suppressMovableColumns:true,
  suppressColumnMoveAnimation:true,
  suppressRowVirtualisation:true,
  enableCellTextSelection:true,
  ensureDomOrder:true
}
const frameworkComponents= {
  checkBoxComponentCall: CallCheckBoxRenderer,
  checkBoxComponentPut: PutCheckBoxRenderer,
  chgLtpComponentCall:CallCHGLTPRenderer,
  chgLtpComponentPut:PutCHGLTPRenderer,
  strikeComponent:StrikeRenderer
}

const METADATA_ROW_HEIGHT=46;
const META_DATA_HEADER_HEIGHT=28;
const TAB_CONTAINER_HEIGHT=48;
const MIN_TABLE_HEIGHT=300;

const FooterToolbar=styled(ToolbarFixed)(
  ({theme})=> `
  bottom:0px;
  justify-content:center;
  text-align:center;
  `
)


const TPO_TIME=30;
const startTime = MARKET_OPEN_TIME;
const endTime = MARKET_CLOSE_TIME
const interval = 15;
const gap=TPO_TIME/interval;    //it means every two interval represents one TPO and label will be created based on it. gap=30/interval

export default function OPTIONS_TABLE() {
  const navigate = useNavigate(); 
  const theme=useTheme();
  const from = "/login";
  const { auth } = useAuth();
  const isEliteUser=(auth.resources.find(el=>el==USER_RESOURCES_LIST.TBT_FEED) && auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2))
  const [showPopup,setShowPopup]=useState(false);
  const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
  const [isProUser,setIsProUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))?true:false)
  const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
  const [cancelLive,responseDataLive,errorLive,loadedLive,resetLive,executeLive] = useAxiosWithAuth();
  const [cancelTab,responseDataTab,errorTab,loadedTab,resetTab,executeAPITab] = useAxiosWithAuth();
  const [cancelOptions,responseDataOptions,errorOptions,loadedOptions,resetOptions,executeAPIOptions] = useAxiosWithAuth();
  const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
  const [gridAPIOptions,setGridAPIOptions]=useState();
  const [columnAPIOptions,setColumnAPIOptions]=useState();
  const [selectedRows,setSelectedRows]=useState({call:undefined,put:undefined});
  const [tickerHeight,setTickerTableHeight]=useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const gridRef = useRef();
  const gridMetaDataRef = useRef();
  const windowSize=useWindowSize();
  const [tabState,setTabState]=useState({
    tabData:undefined,
    tabLoaded:false,
    selectedInstrument:undefined
  });
  const {tabData,tabLoaded,selectedInstrument}=tabState;

  const [optionsState,setOptionsState]=useState({
    optionsData:undefined,
    metaData:undefined,
    metaDataRowCount:2,
    metaDataHeight:2*METADATA_ROW_HEIGHT,  //36*2
    optionsLoaded:false,
    instrument:undefined,
    isInstrumentLive:false,
    reloadInerval:(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?CHART_RELOAD_TIME.OPTIONS_DATA:CHART_RELOAD_TIME.SLOW_DATA,
    pollingInterval:CHART_RELOAD_TIME.POLLING_TIME,
    refreshInterval:-1,
    isPollingAllowed:false,
    tableHeight:0,
    actualHeight:0,
    isTradingSessionActive:false,
    isStatusCheckAllowed:false,
    statusCheckPollingInterval:CHART_RELOAD_TIME.POLLING_TIME,
    errorMsg:TEXT_MSGS.ERROR_MSG_TABLE_NO_DATA
  });

  const {optionsData,metaData,metaDataRowCount,optionsLoaded,isInstrumentLive,reloadInerval,pollingInterval,refreshInterval,isPollingAllowed,metaDataHeight,
    tableHeight,actualHeight,isStatusCheckAllowed,isTradingSessionActive,statusCheckPollingInterval,errorMsg}=optionsState;
  const optionsStateRef=useRef(optionsState);
  
  const [timeMinValue,setTimeMinValue]=useState(0)
  const [timeInterval,setTimeInterval]=useState(createDictList(startTime, endTime, interval,gap));
  const [targetTime,setTargetTime]=useState(timeInterval[0])

  const [startDate,setStartDate]=useState("");      //daysjs date for selected date on calnder control
  const [dateParams,setDateParams]=useState("");      //this is will be used for api calling
  const [dateList,setDateList]=useState();
  const [isDrity,setIsDirty]=useState(false);         //will keep track if user has selected the custom date and time
  const [showSettings,setShowSettings]=useState(false);
  const [showLoginPopup,setShowLoginPopup]=useState(false);
  const [loginMsg,setLoginMsg]=useState(TEXT_MSGS.LOGIN_POPUP_MSG);

  useEffect(()=>{
    // console.log("window size=",windowSize);
    // setOptionsState((prevState) => ({
    //   ...prevState,
    //   tableHeight:parseInt((actualHeight/windowSize.zoomLevel).toFixed(1))}));
  },[windowSize])

  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  
  const { open, msg, severity } = msgState;

  useEffect(() => {
    document.title = "Options Table";
    var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
    document.head.appendChild(link);

    var meta = !document.querySelector("meta[name='description']") ? document.querySelector("meta[name='description']") : document.createElement('meta');
    meta.setAttribute('name', 'description');
    meta.setAttribute('content', 'Options Table. Options Chain. VWAP, VPOC, Open Interest, IV. NIFTY. BANKNIFTY. FINNIFTY. MIDCPNIFTY.');
    document.head.appendChild(meta);
  }, []);
  
const handleCallCheckBoxChange=(id,checked,rowIndex)=>{
  console.log("handleCallCheckBoxChange called",id,checked,rowIndex,optionsStateRef.current.optionsData);
  let data=optionsStateRef.current.optionsData;
  
    for(let i=0;i<data.length;i++){
      data[i].ce_checked=false;  
    }
  
  data[rowIndex].ce_checked=checked;
  if(checked){
    setSelectedRows((prev)=>({
      ...prev,
      call:data[rowIndex]
    }))
  }else{
    setSelectedRows((prev)=>({
      ...prev,
      call:undefined
    }))
  }
  setOptionsState((prevState) => ({
    ...prevState,
    optionsData:data}));
  let changedRow=[];
  changedRow.push(gridRef.current.api.getDisplayedRowAtIndex(rowIndex)); 
  
  // let params = {
  //     force: true,
  //     suppressFlash: false,
  //     // rowNodes: changedRow,
  //   }; 
  //   gridRef.current.api.refreshCells(params);
    // console.log("Row node=",gridAPIOptions.getRowNode());


}
const handlePutCheckBoxChange=(id,checked,rowIndex)=>{
  console.log("handlePutCheckBoxChange called",id,checked,rowIndex,optionsStateRef.current.optionsData);
  let data=optionsStateRef.current.optionsData;
  for(let i=0;i<data.length;i++){
    data[i].pe_checked=false;  
  }

  data[rowIndex].pe_checked=checked;
  setOptionsState((prevState) => ({
    ...prevState,
    optionsData:data}));
  let changedRow=[];
  // console.log("rownode=",gridAPIOptions.getRowNode(rowIndex));
  changedRow.push(gridRef.current.api.getDisplayedRowAtIndex(rowIndex)); 
  if(checked){
    setSelectedRows((prev)=>({
      ...prev,
      put:data[rowIndex]
    }))
  }else{
    setSelectedRows((prev)=>({
      ...prev,
      put:undefined
    }))
  }
  // let params = {
  //     force: true,
  //     suppressFlash: false,
  //     // rowNodes: changedRow,
  //   }; 
  //   gridRef.current.api.refreshCells(params);
    // gridRef.current.api.redrawRows()
    // console.log("Row node=",gridAPIOptions.getRowNode());


}

const openLineChart=(type,data)=>{
  if(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL==type){
    const symbol=(data.ce_instr)
    if(isEliteUser)
      window.open( `${process.env.REACT_APP_URL}candle-line-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
    else 
      window.open( `${process.env.REACT_APP_URL}line-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
   
  }else if(OPTIONS_TABLE_CHARTS.CHART_TYPE_PUT==type){
    const symbol=(data.pe_instr)
    if(isEliteUser)
    window.open( `${process.env.REACT_APP_URL}candle-line-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
    else
    window.open( `${process.env.REACT_APP_URL}line-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
  
  }
  else if(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL_PUT_BOTH==type){
    const symbol=data.pe_instr+"_"+(data.ce_instr.slice(2))
    if(isEliteUser)
      window.open( `${process.env.REACT_APP_URL}candle-line-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
    else 
      window.open( `${process.env.REACT_APP_URL}line-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
  
  }
}

const openMPChart=(type,data)=>{
  if(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL==type){
    const symbol=(data.ce_instr)
    window.open(`${process.env.REACT_APP_URL}market-profile?symbol=${symbol}`, '_blank', 'noreferrer');
   
  }else if(OPTIONS_TABLE_CHARTS.CHART_TYPE_PUT==type){
    const symbol=(data.pe_instr)
    window.open(`${process.env.REACT_APP_URL}market-profile?symbol=${symbol}`, '_blank', 'noreferrer');
   
  }
  else if(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL_PUT_BOTH==type){
    const symbol=data.pe_instr+"_"+(data.ce_instr.slice(2))
    window.open(`${process.env.REACT_APP_URL}market-profile?symbol=${symbol}`, '_blank', 'noreferrer');
   
  }
}

const [columnDefs,setColumnDefs] = useState(getColumneDef(handleCallCheckBoxChange,handlePutCheckBoxChange,auth,openLineChart,openMPChart,userSettings.data.page_wise_hidden_columns.OPTCHAIN))
const [columnDefsMetaData] = useState(getColumneDefMetadata(auth)) 
  
useEffect(()=>{
    console.log("useEffect options state=",optionsState.optionsData)
    // optionsStateRef.current=optionsState;
    if(gridRef!=undefined && gridRef.current!=undefined && gridRef.current.api!=undefined){
      let params = {
          force: true,
          suppressFlash: false,
          // rowNodes: changedRow,
        }; 
        gridRef.current.api.refreshCells(params);
        gridMetaDataRef.current.api.refreshCells(params);
      }
  },[optionsState])

  useEffect(()=>{
    let data={
      "symbol": "NIFTY",
      "instruments": 0,
      "tpoList": 1,
      "optchain_instruments": 1
    }
    // console.log("searchParams", searchParams);
    if(searchParams.has('addInstr')){
      data["additional_instruments"] = [searchParams.get("addInstr")]
    }
    executeAPITab(URL.MPCHART_UIDATA,"POST",data);
  },[]);

  /**
 * API response handler tab data
 */  
   useEffect(() => {
    if(loadedTab){
      console.log("loadedtab=true",responseDataTab);
      if(responseDataTab!=null){
        setTabState({tabLoaded:true,tabData:responseDataTab.optchain_instruments,selectedInstrument:responseDataTab.optchain_instruments[0].instr});    
        setDateList(responseDataTab.optchain_tdates);        
      }
      else if(errorTab!==null){
        setTabState((prevState) => ({
          ...prevState,
          tabLoaded:true}));

        console.log("Error data=",errorTab);

          setMsgState({open:true,msg:errorTab?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorTab?.response?.status === 401 || errorTab?.response?.status === 403){
            console.log("status received =",errorTab?.response?.status)
            navigate(from, { replace: true });
          }
      }
      resetTab();
    }
    },[loadedTab,responseDataTab]);

    useEffect(()=>{
      if(selectedInstrument!=undefined && tickerHeight!=undefined){
        setSelectedRows({call:undefined,put:undefined})
        setOptionsState((prevState) => ({
          ...prevState,
          isPollingAllowed:false,
          instrument:selectedInstrument}));
        
        let data={"instr": selectedInstrument,"c": userSettings.data.data_in_contracts}
        executeAPIOptions(URL.OPTIONS_TABLE,"POST",data);
      }
    },[selectedInstrument,tickerHeight]);

    
    const handleTabChange = (event, newValue) => {
      setTabState(prev=>({
        ...prev,
        selectedInstrument:newValue
      }));
    };

/**
 * API response handler options data
 */  
   useEffect(() => {
    if(loadedOptions){
      console.log("loadedOptions=true",responseDataOptions);
      if(responseDataOptions!=null){

        if(responseDataOptions.METADATA!=undefined && Object.entries(responseDataOptions.METADATA).length != 0 ){

        
        const processedData=processOptionsTableData(responseDataOptions.OPTCHAIN,selectedRows,responseDataOptions.METADATA,userSettings.data.number_format_locale);
        const processedMetaData=processMetaData(responseDataOptions.METADATA,userSettings.data.number_format_locale);
        let length=1;
        if(responseDataOptions.METADATA.max_pe_oi!=undefined &&responseDataOptions.METADATA.min_pe_oi!=undefined && responseDataOptions.METADATA.max_ce_oi!=undefined &&responseDataOptions.METADATA.min_ce_oi!=undefined)
        length=2;

        let metaDataHeight=length*METADATA_ROW_HEIGHT+META_DATA_HEADER_HEIGHT+22;
        console.log("Metadata  length=",length,metaDataHeight)
        
        let tableHeight=window.innerHeight-tickerHeight-metaDataHeight-TAB_CONTAINER_HEIGHT-15-48-18-32-32-32;//margin top, top header, scroll heights, bottom bar,copyright footer
        tableHeight=Math.max(tableHeight,MIN_TABLE_HEIGHT);
        // let actualHeight=tableHeight;
        // tableHeight=parseInt((actualHeight/windowSize.zoomLevel).toFixed(1))
        console.log("innerheight real=",window.innerHeight,tableHeight);

       
        let index=timeInterval.findIndex((item)=>(item.time>=responseDataOptions.lastDataTime.toString()));
        if(index!=-1)
          setTargetTime(timeInterval[index]);
        else
          setTargetTime(timeInterval[timeInterval.length-1]);


        let pollingAllowed=!isDrity;  
        let updateInterval=pollingInterval;
              if(responseDataOptions.isInstrumentLive!=undefined && responseDataOptions.isInstrumentLive){
                updateInterval=reloadInerval;
              }
              setOptionsState((prevState) => ({...prevState,
                metaDataRowCount:length,
                refreshInterval:updateInterval,
                isPollingAllowed:pollingAllowed,
                metaDataHeight:metaDataHeight,
                tableHeight:tableHeight,
                actualHeight:actualHeight,
                metaData:processedMetaData,
                isTradingSessionActive:responseDataOptions.isTradingSessionActive,
                isStatusCheckAllowed:(!responseDataOptions.isInstrumentLive),
                statusCheckPollingInterval:(responseDataOptions.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
                optionsLoaded:true,optionsData:processedData,
                isInstrumentLive:responseDataOptions.isInstrumentLive}));
              }  
        else{
          let pollingAllowed=!isDrity;
          setTargetTime(timeInterval[0]);
          setOptionsState((prevState) => ({...prevState,
            errorMsg:(responseDataOptions.message!=undefined?responseDataOptions.message:TEXT_MSGS.ERROR_MSG_TABLE_NO_DATA),
            isPollingAllowed:false,
            metaData:[],
            isTradingSessionActive:responseDataOptions.isTradingSessionActive,
            isStatusCheckAllowed:(!responseDataOptions.isInstrumentLive),
            statusCheckPollingInterval:(responseDataOptions.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
            optionsLoaded:true,
            optionsData:[],
            isInstrumentLive:responseDataOptions.isInstrumentLive}));  

        }     
                  
      }
      else if(errorOptions!==null){
        setOptionsState((prevState) => ({
          ...prevState,
          errorMsg:errorOptions?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,
          metaData:[],
          optionsData:[],
          optionsLoaded:true}));

        console.log("Error data=",errorOptions);

          setMsgState({open:true,msg:errorOptions?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorOptions?.response?.status === 401 || errorOptions?.response?.status === 403){
            console.log("status received =",errorOptions?.response?.status)
            navigate(from, { replace: true });
          }
      }
      resetOptions();
    }
    },[loadedOptions,responseDataOptions]);
    
  //live data polling
 //polling or live chart update handler
  useEffect(() => {
   //set the ref to current state
   
   optionsStateRef.current = optionsState;
    
      const timeout = setInterval(() => {
        if(optionsStateRef.current.instrument!=undefined){
          console.log("Live use effect api called=",optionsStateRef.current.refreshInterval);
          if(optionsStateRef.current.isPollingAllowed && optionsStateRef.current.isInstrumentLive){
            let data={"instr": optionsStateRef.current.instrument,"c": userSettings.data.data_in_contracts}
            executeLive(URL.OPTIONS_TABLE,"POST",data);
          }
        }
      }, optionsStateRef.current.refreshInterval);
         
      //market status polling
      const timeoutStatusCheck=setInterval(() => {
        if(optionsStateRef.current.isStatusCheckAllowed && !optionsStateRef.current.isInstrumentLive)   {
            executeAPIStatus(URL.CHART_STATUS,"POST",{symbol:optionsStateRef.current.instrument});
        }
      },optionsStateRef.current.statusCheckPollingInterval);  
        
          return () => {
            // just to clear the timeout when component unmounts
            clearInterval(timeout);
            clearInterval(timeoutStatusCheck)
          };
}, [optionsState]);

/**
 * Market live status check response handler
 */  
useEffect(() => {
  if(loadedStatus){
    if(responseDataStatus!=null){
              //if instrument is live then load the live data else set the status as per the response
              setShowPopup(false);
              if(responseDataStatus.isInstrumentLive!=undefined && responseDataStatus.isInstrumentLive){
                let data={"instr": optionsStateRef.current.instrument,"c": userSettings.data.data_in_contracts}
                executeLive(URL.OPTIONS_TABLE,"POST",data);
              }else{
                setOptionsState((prevState) => ({
                  ...prevState,
                  isStatusCheckAllowed:(!responseDataStatus.isInstrumentLive),
                  statusCheckPollingInterval:(responseDataStatus.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
                  isTradingSessionActive:responseDataStatus.isTradingSessionActive,
                  isInstrumentLive:responseDataStatus.isInstrumentLive,
                }));
              }
              if(responseDataStatus.upgrade_notify!=undefined && responseDataStatus.upgrade_notify){
                setShowPopup(true);
              }
          
    }
    else if(errorStatus!==null){
      console.log("Error data status check=",errorStatus);

      // setOptionsState((prevState) => ({
      //   ...prevState,
      //   optionsLoaded:true}));
        
        if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
          console.log("status received =",errorStatus?.response?.status)
          // navigate(from, { replace: true });
          setLoginMsg(errorStatus?.response?.data?.message);
          setShowLoginPopup(true);
        }
    }
    resetStatus();
  }
  },[loadedStatus,responseDataStatus]);

    
  
 /**
 * API Live response handler options data
 */  
  useEffect(() => {
    if(loadedLive){
      console.log("loadedOptions Live=true",responseDataLive);
      if(responseDataLive!=null){
        const processedData=processOptionsTableData(responseDataLive.OPTCHAIN,selectedRows,responseDataLive.METADATA,userSettings.data.number_format_locale);
        const processedMetaData=processMetaData(responseDataLive.METADATA,userSettings.data.number_format_locale);
        let length=1;
        if(responseDataLive.METADATA.max_pe_oi!=undefined &&responseDataLive.METADATA.min_pe_oi!=undefined && responseDataLive.METADATA.max_ce_oi!=undefined &&responseDataLive.METADATA.min_ce_oi!=undefined)
        length=2;

        // console.log("Metadata live length=",length)
        let metaDataHeight=length*METADATA_ROW_HEIGHT+META_DATA_HEADER_HEIGHT+22;
        //  console.log("Metadata live length=",length,metaDataHeight)
        let tableHeight=window.innerHeight-tickerHeight-metaDataHeight-TAB_CONTAINER_HEIGHT-15-48-18-32-32-32;//margin top, top header, scroll heights
        tableHeight=Math.max(tableHeight,MIN_TABLE_HEIGHT);
        
        let index=timeInterval.findIndex((item)=>(item.time>=responseDataLive.lastDataTime.toString()));
        if(index!=-1)
          setTargetTime(timeInterval[index]);
        else
          setTargetTime(timeInterval[timeInterval.length-1]);
        let pollingAllowed=!isDrity;  
        let updateInterval=pollingInterval;
              if(responseDataLive.isInstrumentLive!=undefined && responseDataLive.isInstrumentLive){
                updateInterval=reloadInerval;
              }
              setOptionsState((prevState) => ({...prevState,
                isPollingAllowed:pollingAllowed,
                refreshInterval:updateInterval,
                metaDataRowCount:length,
                metaDataHeight:metaDataHeight,
                tableHeight:tableHeight,
                metaData:processedMetaData,
                optionsLoaded:true,optionsData:processedData,
                isTradingSessionActive:responseDataLive.isTradingSessionActive,
                isStatusCheckAllowed:(!responseDataLive.isInstrumentLive),
                statusCheckPollingInterval:CHART_RELOAD_TIME.POLLING_TIME,
                isInstrumentLive:responseDataLive.isInstrumentLive}));
                  
      }
      else if(errorLive!==null){
        setOptionsState((prevState) => ({
          ...prevState,
          optionsLoaded:true}));

        console.log("Error data=",errorLive);

          // setMsgState({open:true,msg:errorLive?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorLive?.response?.status === 401 || errorLive?.response?.status === 403){
            console.log("status received =",errorLive?.response?.status)
            // navigate(from, { replace: true });
            setLoginMsg(errorLive?.response?.data?.message);
            setShowLoginPopup(true);
          }
      }
      resetLive();
    }
    },[loadedLive,responseDataLive]);
       
    const onButtonClick = () => {
      const selectedNodes = gridAPIOptions.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      const selectedDataString = selectedData
        .map(node => `${node}`)
        .join(', ');
      alert(`Selected Nodes: ${selectedDataString}`);
    };

    const onGridReady = params => {
      setGridAPIOptions(params.api);
      setColumnAPIOptions(params.columnApi);
    };

    // const pinColumn=()=>{
    //   console.log("col def=",gridAPIOptions.getColumnDefs());
    //   columnAPIOptions.setColumnPinned("ce_doi_oi", "left");
    //   columnAPIOptions.setColumnVisible("ce_iv", false);
    //   const group = columnAPIOptions.getColumnGroup("call_group");
    //   console.log("group=====>",group);
    //   group.children.forEach(child => columnAPIOptions.setColumnVisible(child, false));
    // }

  
   const openStraddleSnakeChart=()=>{
    if(selectedInstrument!=undefined){
      const symbol=selectedInstrument.split("_E")
      window.open( `${process.env.REACT_APP_URL}line-chart?type=SNAKES&symbol=${symbol[0].replace("O_", "")}&expiry=${symbol[1]}&plot=pair_price`, '_blank', 'noreferrer');
    }  
   }

   const openOISnakeChart=()=>{
    if(selectedInstrument!=undefined){
      const symbol=selectedInstrument.split("_E")
      window.open( `${process.env.REACT_APP_URL}line-chart?type=SNAKES&symbol=${symbol[0].replace("O_", "")}&expiry=${symbol[1]}&plot=oi&c=${userSettings.data.data_in_contracts}`, '_blank', 'noreferrer');
    }
   }

   const openIVSnakeChart=()=>{
    if(selectedInstrument!=undefined){
      const symbol=selectedInstrument.split("_E")
      // window.open( `${process.env.REACT_APP_URL}line-chart?type=SNAKES&symbol=${symbol[1]}&plot=oi`, '_blank', 'noreferrer');
    
      window.open( `${process.env.REACT_APP_URL}line-chart?type=SNAKES&symbol=${symbol[0].replace("O_", "")}&expiry=${symbol[1]}&plot=iv_wcash`, '_blank', 'noreferrer');
    } 
  }

   const openStraddleChart=()=>{
    const symbol=selectedRows.put.pe_instr+"_"+(selectedRows.call.ce_instr.slice(2))
    console.log("open chart is called",process.env.REACT_APP_URL,symbol)
    window.open(`${process.env.REACT_APP_URL}market-profile?symbol=${symbol}`, '_blank', 'noreferrer');
   }
  
   /**
 * autoclosing of the snackbar msg bar 
 */ 
    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };
  
  const setTickerHeight=(height)=>{
    console.log("ticker height=",height)
    setTickerTableHeight(height);
  }


  const resetTimeSlider=()=>{
    //reset time
    // setTimeMinValue(0);
    // setTargetTime(timeInterval[0]);
  }

  const disabledDate=(current)=> {
    return (current && current >= dayjs().endOf('day'))||dayjs(current).day()==6 || dayjs(current).day()==0
  }

  //for date control
  const onDateChange = (date, dateString) => {
    setIsDirty(true);
    setStartDate(dayjs(date))
    setDateParams(dateString);
    console.log("Date changed",dayjs(date),dateString);
    setOptionsState((prevState)=>({
      ...prevState,
      isPollingAllowed:false,
    }))
   
    let data={"instr": selectedInstrument,"c": userSettings.data.data_in_contracts,"date":dateString};
    executeAPIOptions(URL.OPTIONS_TABLE,"POST",data);
    resetTimeSlider();
   
  }

  //for dropdown control
  const handleDateChange=(event)=>{
    setIsDirty(true);
    setDateParams(event.target.value);
    setOptionsState((prevState)=>({
      ...prevState,
      isPollingAllowed:false,
    }))
    let data={"instr": selectedInstrument,"c": userSettings.data.data_in_contracts,"date":event.target.value};
    executeAPIOptions(URL.OPTIONS_TABLE,"POST",data);

    resetTimeSlider();
    
  }

  const handleTargetTimeChange=(event)=>{
    let index=timeInterval.findIndex((item)=>item.value==event.target.value)
    if(index!=-1){
      setIsDirty(true);
      console.log("changed time=",timeInterval[index]); 
      setOptionsState((prevState)=>({
        ...prevState,
        isPollingAllowed:false,
      }))
        setTargetTime(timeInterval[index]);
        let data={"instr": selectedInstrument,"c": userSettings.data.data_in_contracts,"time":timeInterval[index].time};
        if(dateParams!=undefined && dateParams!="")
          data.date=dateParams;
        
        executeAPIOptions(URL.OPTIONS_TABLE,"POST",data);
  }
  }

  const resetDateTime=()=>{
    setIsDirty(false);
    setStartDate("");
    setDateParams("");
    let data={"instr": selectedInstrument,"c": userSettings.data.data_in_contracts}
    executeAPIOptions(URL.OPTIONS_TABLE,"POST",data);

  }

  const openSettings=()=>{
    setShowSettings(true);
   }
  
   const cancelSettings=()=>{
    setShowSettings(false);
   }
  
   const saveSettings=(settings)=>{
    setShowSettings(false);
    console.log("OPTIONSSettings save settings in chart container",settings);
    setUserSettings(settings);
   }
  
   useEffect(()=>{
    setColumnDefs(getColumneDef(handleCallCheckBoxChange,handlePutCheckBoxChange,auth,openLineChart,openMPChart,userSettings.data.page_wise_hidden_columns.OPTCHAIN))
    console.log("OPTIONSSettings useEffect called");
   },[userSettings])

  return (
    <div style={{width:"100%",backgroundColor:"#022D5A",zIndex:9,minWidth:`${MIN_TABLE_CONTAINER_WIDTH}`}}>
      {/* <TICKER_TABLE setTickerHeight={setTickerHeight} setHeight={setTickerHeight}/> */}
      <TICKER_TABLE setTickerHeight={setTickerHeight} setHeight={setTickerHeight} showLoginPopup={()=>setShowLoginPopup(true)}/>
       {tabLoaded?
      <div id="tabs" style={{display:"flex"}}>
        <Tooltip title="Options Table Settings">
              <SettingsIcon onClick={openSettings}sx={{ml:1,marginRight:"12px", marginTop:"12px",height:20,width:20,cursor:'pointer'}}/>
          </Tooltip>
      <Tabs
        value={selectedInstrument}
        onChange={handleTabChange}
        size='small'
        sx={{height:24}}
        textColor="primary"
        indicatorsColor="primary"
        aria-label="secondary tabs example"
    >
      {tabData.map((item,index) => (
        <Tab sx={{height:24}} key={item.instr} value={item.instr} label={item.name} />
      ))}
   </Tabs>
      </div>:
      <></>
      }

      {/* <button   type="button"   onClick={() => pinColumn()}>Pin dOI OI</button> */}
      {optionsLoaded?
      <div style={{ display:"flex",width: `calc(100vw - 18px)`,height: `calc(${metaDataHeight}px)`,marginTop:10}}>
         <div className="ag-theme-alpine-dark" style={{height: `calc(${metaDataHeight}px)`, width: `calc(100vw)`}}>
           <AgGridReact
            ref={gridMetaDataRef}
            rowHeight={METADATA_ROW_HEIGHT*metaDataRowCount}
            headerHeight={META_DATA_HEADER_HEIGHT}
            gridOptions={gridOptionsMetaData}
            rowData={metaData}
            overlayNoRowsTemplate={errorMsg}
            columnDefs={columnDefsMetaData}>
           </AgGridReact>
       </div>
       
       </div>:
       <></>}
       {optionsLoaded?
          <>
            {/* <Button  variant="contained" onClick={openStraddleSnakeChart} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
              Straddle Snakes
            </Button>
            <Button  variant="contained" onClick={openOISnakeChart} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}>
              OI Snakes
            </Button>
            <Button  variant="contained" onClick={openIVSnakeChart} sx={{width:"115px",fontSize:12,height:30,mr:2,pl:1,pr:1,mt:1}}>
              IV Snakes
            </Button> */}

            {isProUser?
              <div style={{display:'flex',zIndex:9,marginTop:10,paddingLeft:"10px",paddingRight:"10px",flexDirection:"row"}}>
                <Typography sx={{ml:2, mt:3,fontSize:"20"}}>Check Old Date/Time data:</Typography>
                <FormControl size="small" sx={{ width:"110px",minWidth:'140px',ml:3,mt:2 }}>
                <InputLabel id="demo-simple-select-label">Select Date</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dateParams}
                  label="Date"
                  MenuProps={MenuProps}
                  sx={{height:"40px"}}
                  onChange={(event)=>handleDateChange(event)}
                >
                  {dateList.map((item,index) => (
                  <MenuItem key ={item} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <DatePicker onChange={onDateChange} 
-                allowClear={false}
-                disabledDate={disabledDate}
-                value={startDate}
-                placeholder="Select Date"
-                format={DateFormat}
-                style={{border: "1px solid #415e81",background:"#022D5A",height:"40px",
-                borderRadius: "4px",width:160,marginLeft:8,marginTop:"12px"}}
-                />  */}
              <Box sx={{ width: 500,ml:8,mt:1}}>
              <Slider
                defaultValue={timeMinValue}
                value={targetTime.value}
                onChange={handleTargetTimeChange}
                step={1}
                marks={timeInterval}
                min={0}
                max={timeInterval[timeInterval.length-1].value}
              />  
            </Box>
            <Box>
              <Typography sx={{ml:4,marginTop:"14px",fontSize:"12px"}}>Select Time</Typography>
              <Typography sx={{ml:6,marginTop:"6px"}}>{targetTime.time}</Typography>
            </Box>
            <Button  variant="contained" disabled={!isDrity} onClick={resetDateTime} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,ml:4,pr:1,mt:2}}  >
              Reset
            </Button>
          </div>
            :
                <></>
            }
          
          </>
        
        :
        <></>
      } 
       

      {optionsLoaded && tickerHeight!=undefined?
            
      // <div id="optionsTable" style={{marginTop:8,height:`calc(${tableHeight+32}px)`}}>
      // <div className="ag-theme-alpine-dark" style={{height:`calc(${tableHeight}px)`, width: `calc(100% - 0px)`}}>
      <div id="optionsTable" style={{marginTop:12,marginBottom:"40px", height:`calc(100vh - 90px)`}}>
      <div className="ag-theme-alpine-dark" style={{height:`calc(100vh - 90px)`, width: `calc(100% - 0px)`}}>
           <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            // sideBar={true}
            rowHeight={52}
            headerHeight={44}
            gridOptions={gridOptions}
            rowData={optionsData}
            groupHeaderHeight={30}
            suppressScrollOnNewData={true}
            components={frameworkComponents}
            overlayNoRowsTemplate={errorMsg}
            columnDefs={columnDefs}>
           </AgGridReact>
       </div>
      </div>:
      
      <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
      </div>}
    
    {selectedRows.call!=undefined || selectedRows.put!=undefined?
      <OptionsFooter selectedRows={selectedRows} openChart={openStraddleChart}></OptionsFooter>:
      <></>
    }
    <FooterToolbar sx={{borderTop: `1px solid ${theme.palette.primaryTheme.shade03}` }} >
    
        <Container >
        <Typography  variant="copyRight" align="center" >
          {WATERMARK_CONFIG.TABLE_TEXT}
          </Typography>
        </Container>
      </FooterToolbar>
    <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{marginTop:"48px", width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>  

       {showPopup?
         <SubscriptionPopup open={showPopup} proMessage={false} onClose={()=>setShowPopup(false)}></SubscriptionPopup>  :
         <></>
      } 
       {showSettings?
     <OptionsSettings open={true}  cancelHandler={cancelSettings} saveHandler={saveSettings} ></OptionsSettings>    :
     <></>
    }
     {showLoginPopup?
         <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}       
    </div>
  )
}

