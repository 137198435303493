// import {axiosPrivate} from '../api/axios';

// import useAuth from './useAuth';
// import { URL } from '../api/urls';
// const useRefreshToken = () => {
//     const { setAuth } = useAuth();

//     const refresh = async (reload=false) => {
//         try {
//             let url=URL.REFRESH;
//             console.log("TestRefresh useRefreshToken 1");
//             const response = await axiosPrivate.post(url, {
//                 withCredentials: true,
//                 reload: reload
//             });
//             console.log("TestRefresh useRefreshToken2 response=",response);
//             setAuth({accessToken:response.data.token,name:response.data.first_name+" "+response.data.last_name,role:response.data.role,resources:response.data.resources,email_id:response.data.email_id});
          
           
//             return response.data.token;
//         } catch (error) {
//             console.error("error in useRefreshToken3 =",error);
//             // if(error?.response?.status === 400){
//             //     error.response.status=401;
//             // }
//             // return Promise.reject(error);
            
//         } 
//         finally {
//             console.error("error in useRefreshToken33 finally =");
//         }
//     }
//     return refresh;
// };

// export default useRefreshToken;

import { axiosRefresh } from '../api/axios';
import useAuth from './useAuth';
import { URL } from '../api/urls';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        try {
            console.log("TestRefresh useRefreshToken 1");
            const response = await axiosRefresh.post(URL.REFRESH, { withCredentials: true,reload:false });
            console.log("TestRefresh useRefreshToken2 response=",response);
            setAuth({
                accessToken: response.data.token,
                name: response.data.first_name + " " + response.data.last_name,
                role: response.data.role,
                resources: response.data.resources,
                email_id: response.data.email_id
            });
            return response.data.token;
        } catch (error) {
            // console.error("Error refreshing token:", error);
            console.error("error in useRefreshToken3 =",error);
            throw error;
        }
    };

    return refresh;
};

export default useRefreshToken;
