import MarketProfileCharts from "../../pages/dashboard/market_profile";
import LineChart from "../../pages/dashboard/line_chart";
import BarChart from "../../pages/dashboard/bar_chart";
import StockChartHV from "../../pages/dashboard/stock_chart_hv"
import StockChart from "../../pages/dashboard/stock_chart";
import OPTIONS_TABLE from "../../pages/dashboard/options_table";
import { ALLOWED_ROLES_ORDERFLOW, USER_RESOURCES_LIST, USER_ROLE_FIELDS } from "./constant";
import { ALLOWED_ROLES_ADMIN,ALLOWED_ROLES_ALL,ALLOWED_ROLES_PRO_1,ALLOWED_ROLES_PRO_2,ALLOWED_ROLES_BASIC,ALLOWED_ROLES_FAST_DATA,RESOURCE_LIST }from "./constant";
import GREEKS_TABLE from "../../pages/dashboard/greeks_table";
import INDEX_TABLE from "../../pages/dashboard/index_table";
import Bookmark from "@mui/icons-material/Bookmark";
import OrderflowCharts from "../../pages/dashboard/orderflow";
import LineChartMain from "../../pages/dashboard/LineChartMain";
import CandlestickCharts from "../../pages/dashboard/candlestick_main";
import LineChartMainNew from "../../pages/dashboard/LineChartMainNew";
import LineChartNew from "../../pages/dashboard/line_chart_new";
// import SubscriptionNew from "../../pages/dashboard/subscription_new";

export const NIFTY_SPOT_OIX_CHART_DATA={
  key:"NF Options Strike Spectrum Charts",
  name: "NF Options Strike Spectrum Charts",
  title:"NIFTY Options Strike Spectrum Charts",
      data:{
        chart_type:"lc",
        symbol:"NIFTY",
        type:"SINGLE",
        startDate:"",
        nDays:4,
        prem:"fut",
        plot:"spt",
        fvol:"0",
        doix:"0",
        trp:"0",
        gf:"1",
        gr:"1",
        gm:"1"
      }
}

export const BANKNIFTY_SPOT_OIX_CHART_DATA={
  key:"BN Options Strike Spectrum Charts",
  name:"BN Options Strike Spectrum Charts",
  title:"BANKNIFTY Options Strike Spectrum Charts",
  data:{
    chart_type:"lc",
    symbol:"BANKNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:4,
    prem:"fut",
    plot:"spt",
    fvol:"0",
    doix:"0",
    trp:"0",
    gf:"1",
    gr:"1",
    gm:"1"
  }
}

export const FINNIFTY_SPOT_OIX_CHART_DATA={
  key:"FN Options Strike Spectrum Charts",
  name:"FN Options Strike Spectrum Charts",
  title:"FINNIFTY Options Strike Spectrum Charts",
  data:{
    chart_type:"lc",
    symbol:"FINNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:4,
    prem:"fut",
    plot:"spt",
    fvol:"0",
    doix:"0",
    trp:"0",
    gf:"1",
    gr:"1",
    gm:"1"
  }
}

export const MIDCPNIFTY_SPOT_OIX_CHART_DATA={
  key:"MN Options Strike Spectrum Charts",
  name:"MN Options Strike Spectrum Charts",
  title:"MIDCPNIFTY Options Strike Spectrum Charts",
  data:{
    chart_type:"lc",
    symbol:"MIDCPNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:4,
    prem:"fut",
    plot:"spt",
    fvol:"0",
    doix:"0",
    trp:"0",
    gf:"1",
    gr:"1",
    gm:"1"
  }
}

export const NIFTY_ALLOPT_DOI_CHART_DATA={
  key: "NF Options OI Change Charts",
  name: "NF Options OI Change Charts",
  title: "NIFTY Options OI Change Charts",
  data:{
    chart_type:"lc",
    symbol:"NIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_doi",
  }
}

export const BANKNIFTY_ALLOPT_DOI_CHART_DATA={
  key: "BN Options OI Change Charts",
  name: "BN Options OI Change Charts",
  title: "BANKNIFTY Options OI Change Charts",
  data:{
    chart_type:"lc",
    symbol:"BANKNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_doi",
  }
}

export const FINNIFTY_ALLOPT_DOI_CHART_DATA={
  key: "FN Options OI Change Charts",
  name: "FN Options OI Change Charts",
  title: "FINNIFTY Options OI Change Charts",
  data:{
    chart_type:"lc",
    symbol:"FINNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_doi",
  }
}

export const MIDCPNIFTY_ALLOPT_DOI_CHART_DATA={
  key: "MN Options OI Change Charts",
  name: "MN Options OI Change Charts",
  title: "MIDCPNIFTY Options OI Change Charts",
  data:{
    chart_type:"lc",
    symbol:"MIDCPNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_doi",
  }
}

export const NIFTY_ALLOPT_VOL_CHART_DATA={
  key: "NF Options Volume Charts",
  name: "NF Options Volume Charts",
  title: "NIFTY Options Volume Charts",
  data:{
    chart_type:"lc",
    symbol:"NIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_vol",
  }
}

export const BANKNIFTY_ALLOPT_VOL_CHART_DATA={
  key: "BN Options Volume Charts",
  name: "BN Options Volume Charts",
  title: "BANKNIFTY Options Volume Charts",
  data:{
    chart_type:"lc",
    symbol:"BANKNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_vol",
  }
}

export const FINNIFTY_ALLOPT_VOL_CHART_DATA={
  key: "FN Options Volume Charts",
  name: "FN Options Volume Charts",
  title: "FINNIFTY Options Volume Charts",
  data:{
    chart_type:"lc",
    symbol:"FINNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_vol",
  }
}

export const MIDCPNIFTY_ALLOPT_VOL_CHART_DATA={
  key: "MN Options Volume Charts",
  name: "MN Options Volume Charts",
  title: "MIDCPNIFTY Options Volume Charts",
  data:{
    chart_type:"lc",
    symbol:"MIDCPNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_vol",
  }
}

export const NIFTY_ALLOPT_DVOLX_CHART_DATA={
  key: "NF Options NTM Volume Charts",
  name: "NF Options NTM Volume Charts",
  title: "NIFTY Options NTM Volume Charts",
  data:{
    chart_type:"lc",
    symbol:"NIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_dvolx",
  }
}

export const BANKNIFTY_ALLOPT_DVOLX_CHART_DATA={
  key: "BN Options NTM Volume Charts",
  name: "BN Options NTM Volume Charts",
  title: "BANKNIFTY NTM Options Volume Charts",
  data:{
    chart_type:"lc",
    symbol:"BANKNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_dvolx",
  }
}

export const FINNIFTY_ALLOPT_DVOLX_CHART_DATA={
  key: "FN Options NTM Volume Charts",
  name: "FN Options NTM Volume Charts",
  title: "FINNIFTY Options NTM Volume Charts",
  data:{
    chart_type:"lc",
    symbol:"FINNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_dvolx",
  }
}

export const MIDCPNIFTY_ALLOPT_DVOLX_CHART_DATA={
  key: "MN Options NTM Volume Charts",
  name: "MN Options NTM Volume Charts",
  title: "MIDCPNIFTY Options NTM Volume Charts",
  data:{
    chart_type:"lc",
    symbol:"MIDCPNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_dvolx",
  }
}

export const NIFTY_ALLOPT_VOLX_CHART_DATA={
  key: "NF Options NTM Volx Charts",
  name: "NF Options NTM Volx Charts",
  title: "NIFTY Options NTM Volx Charts",
  data:{
    chart_type:"lc",
    symbol:"NIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_volx",
  }
}

export const BANKNIFTY_ALLOPT_VOLX_CHART_DATA={
  key: "BN Options NTM Volx Charts",
  name: "BN Options NTM Volx Charts",
  title: "BANKNIFTY NTM Options Volx Charts",
  data:{
    chart_type:"lc",
    symbol:"BANKNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_volx",
  }
}

export const FINNIFTY_ALLOPT_VOLX_CHART_DATA={
  key: "FN Options NTM Volx Charts",
  name: "FN Options NTM Volx Charts",
  title: "FINNIFTY Options NTM Volx Charts",
  data:{
    chart_type:"lc",
    symbol:"FINNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_volx",
  }
}

export const MIDCPNIFTY_ALLOPT_VOLX_CHART_DATA={
  key: "MN Options NTM Volx Charts",
  name: "MN Options NTM Volx Charts",
  title: "MIDCPNIFTY Options NTM Volx Charts",
  data:{
    chart_type:"lc",
    symbol:"MIDCPNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_volx",
  }
}

export const NIFTY_ALLOPT_DOIX_CHART_DATA={
  key: "NF Options NTM OI Change Charts",
  name: "NF Options NTM OI Change Charts",
  title: "NIFTY Options NTM OI Change Charts",
  data:{
    chart_type:"lc",
    symbol:"NIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_doix",
  }
}

export const BANKNIFTY_ALLOPT_DOIX_CHART_DATA={
  key: "BN Options NTM OI Change Charts",
  name: "BN Options NTM OI Change Charts",
  title: "BANKNIFTY Options NTM OI Change Charts",
  data:{
    chart_type:"lc",
    symbol:"BANKNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_doix",
  }
}

export const FINNIFTY_ALLOPT_DOIX_CHART_DATA={
  key: "FN Options NTM OI Change Charts",
  name: "FN Options NTM OI Change Charts",
  title: "FINNIFTY Options NTM OI Change Charts",
  data:{
    chart_type:"lc",
    symbol:"FINNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_doix",
  }
}

export const MIDCPNIFTY_ALLOPT_DOIX_CHART_DATA={
  key: "MN Options NTM OI Change Charts",
  name: "MN Options NTM OI Change Charts",
  title: "MIDCPNIFTY Options NTM OI Change Charts",
  data:{
    chart_type:"lc",
    symbol:"MIDCPNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    plot:"all_doix",
  }
}

export const NIFTY_FUTURES_OIX_CHART_DATA={
  key: "NF Futures mViz Charts",
  name: "NF Futures mViz Charts",
  title: "NIFTY Futures mViz Charts",
      data:{
        chart_type:"lc",
        symbol:"NIFTY",
        type:"SINGLE",
        startDate:"",
        nDays:1,
        prem:"bias",
        plot:"fut",
        doix:"1",
        gf:"1",
        trp:"1",
        fvol:"1"
      }
}

export const BANKNIFTY_FUTURES_OIX_CHART_DATA={
  key: "BN Futures mViz Charts",
  name: "BN Futures mViz Charts",
  title: "BANKNIFTY Futures mViz Charts",
  data:{
    chart_type:"lc",
    symbol:"BANKNIFTY",
    type:"SINGLE",
    startDate:"",
    nDays:1,
    prem:"bias",
    plot:"fut",
    doix:"1",
    gf:"1",
    trp:"1",
    fvol:"1"
  }
}

export const NIFTY_HV_CHART_DATA={
  key: "NF HV Charts",
  name: "NF HV Charts",
  title: "NIFTY HV Charts",
  data:{
    chart_type:"hv",
    symbol:"NIFTY",
   
  }
}

export const BANKNIFTY_HV_CHART_DATA={
  key: "BN HV Charts",
  name: "BN HV Charts",
  title: "BankNifty HV Charts",
  data:{
    chart_type:"hv",
    symbol:"BANKNIFTY",
   
  }
}

export const NIFTY_GCASH_BAR_CHART_DATA={
  key: "NF Options Strikewise Gamma Charts",
  name: "NF Options Strikewise Gamma Charts",
  title: "NIFTY Options Strikewise Gamma Charts",
  data:{
    chart_type:"bc",
    symbol:"NIFTY",
    plot:"_igex",
    menu_type:"inventory"
  }
}

export const BANKNIFTY_GCASH_BAR_CHART_DATA={
  key: "BN Options Strikewise Gamma Charts",
  name: "BN Options Strikewise Gamma Charts",
  title: "BANKNIFTY Options Strikewise Gamma Charts",
  data:{
    chart_type:"bc",
    symbol:"BANKNIFTY",
    plot:"_igex",
    menu_type:"inventory"
  }
}

export const FINNIFTY_GCASH_BAR_CHART_DATA={
  key: "FN Options Strikewise Gamma Charts",
  name: "FN Options Strikewise Gamma Charts",
  title: "FINNIFTY Options Strikewise Gamma Charts",
  data:{
    chart_type:"bc",
    symbol:"FINNIFTY",
    plot:"_igex",
    menu_type:"inventory"
  }
}

export const MIDCPNIFTY_GCASH_BAR_CHART_DATA={
  key: "MN Options Strikewise Gamma Charts",
  name: "MN Options Strikewise Gamma Charts",
  title: "MIDCPNIFTY Options Strikewise Gamma Charts",
  data:{
    chart_type:"bc",
    symbol:"MIDCPNIFTY",
    plot:"_igex",
    menu_type:"inventory"
  }
}

export const NIFTY_WCASH_BAR_CHART_DATA={
  key: "NF Options Strikewise Wcash Charts",
  name: "NF Options Strikewise Wcash Charts",
  title: "NIFTY Options Strikewise Wcash Charts",
  data:{
    chart_type:"bc",
    symbol:"NIFTY",
    plot:"_wcash",
    menu_type:"inventory"
  }
}

export const BANKNIFTY_WCASH_BAR_CHART_DATA={
  key: "BN Options Strikewise Wcash Charts",
  name: "BN Options Strikewise Wcash Charts",
  title: "BANKNIFTY Options Strikewise Wcash Charts",
  data:{
    chart_type:"bc",
    symbol:"BANKNIFTY",
    plot:"_wcash",
    menu_type:"inventory"
  }
}

export const FINNIFTY_WCASH_BAR_CHART_DATA={
  key: "FN Options Strikewise Wcash Charts",
  name: "FN Options Strikewise Wcash Charts",
  title: "FINNIFTY Options Strikewise Wcash Charts",
  data:{
    chart_type:"bc",
    symbol:"FINNIFTY",
    plot:"_wcash",
    menu_type:"inventory"
  }
}

export const MIDCPNIFTY_WCASH_BAR_CHART_DATA={
  key: "MN Options Strikewise Wcash Charts",
  name: "MN Options Strikewise Wcash Charts",
  title: "MIDCPNIFTY Options Strikewise Wcash Charts",
  data:{
    chart_type:"bc",
    symbol:"MIDCPNIFTY",
    plot:"_wcash",
    menu_type:"inventory"
  }
}

export const NIFTY_OI_BAR_CHART_DATA={
  key: "NF Options Strikewise oiViz Charts",
  name: "NF Options Strikewise oiViz Charts",
  title: "NIFTY Options Strikewise oiViz Charts",
  data:{
    chart_type:"bc",
    symbol:"NIFTY",
    plot:"_oi",
    menu_type:"oi"
  }
}

export const BANKNIFTY_OI_BAR_CHART_DATA={
  key: "BN Options Strikewise oiViz Charts",
  name: "BN Options Strikewise oiViz Charts",
  title: "BANKNIFTY Options Strikewise oiViz Charts",
  data:{
    chart_type:"bc",
    symbol:"BANKNIFTY",
    plot:"_oi",
    menu_type:"oi"
  }
}

export const FINNIFTY_OI_BAR_CHART_DATA={
  key: "FN Options Strikewise oiViz Charts",
  name: "FN Options Strikewise oiViz Charts",
  title: "FINNIFTY Options Strikewise oiViz Charts",
  data:{
    chart_type:"bc",
    symbol:"FINNIFTY",
    plot:"_oi",
    menu_type:"oi"
  }
}

export const MIDCPNIFTY_OI_BAR_CHART_DATA={
  key: "MN Options Strikewise oiViz Charts",
  name: "MN Options Strikewise oiViz Charts",
  title: "MIDCPNIFTY Options Strikewise oiViz Charts",
  data:{
    chart_type:"bc",
    symbol:"MIDCPNIFTY",
    plot:"_oi",
    menu_type:"oi"
  }
}

export const NIFTY_IV_CHART_DATA={
  key: "NF IV Curve Charts",
  name: "NF IV Curve Charts",
  title: "NIFTY IV Curve Charts",
  data:{
    chart_type:"bc",
    symbol:"NIFTY",
    plot:"iv",
    menu_type:"oi"
  }
}

export const BANKNIFTY_IV_CHART_DATA={
  key: "BN IV Curve Charts",
  name: "BN IV Curve Charts",
  title: "BANKNIFTY IV Curve Charts",
  data:{
    chart_type:"bc",
    symbol:"BANKNIFTY",
    plot:"iv",
    menu_type:"oi"
  }
}

export const NIFTY_SNAKE_CHART_DATA={
  key: "NF Options Snake Charts",
  name: "NF Options Snake Charts",
  title: "NIFTY Options Snake Charts",
      data:{
        chart_type:"lc",
        symbol:"NIFTY",
        type:"SNAKES",
        plot:"oi",
      }
}

export const BANKNIFTY_SNAKE_CHART_DATA={
  key: "BN Options Snake Charts",
  name: "BN Options Snake Charts",
  title: "BANKNIFTY Options Snake Charts",
      data:{
        chart_type:"lc",
        symbol:"BANKNIFTY",
        type:"SNAKES",
        plot:"oi",
      }
}

export const FINNIFTY_SNAKE_CHART_DATA={
  key: "FN Options Snake Charts",
  name: "FN Options Snake Charts",
  title: "FINNIFTY Options Snake Charts",
      data:{
        chart_type:"lc",
        symbol:"FINNIFTY",
        type:"SNAKES",
        plot:"oi",
      }
}

export const MIDCPNIFTY_SNAKE_CHART_DATA={
  key: "MN Options Snake Charts",
  name: "MN Options Snake Charts",
  title: "MIDCPNIFTY Options Snake Charts",
      data:{
        chart_type:"lc",
        symbol:"MIDCPNIFTY",
        type:"SNAKES",
        plot:"oi",
      }
}

const BROKER_LOGIN_COMPONENT={
  name:"Broker Integration",
  route:undefined,
  isBrokerPopup:true,
  roles:ALLOWED_ROLES_ALL
}

const MP_CHART_COMP= {
  name: "Market Profile Charts",
  route: "/market-profile",
  component: <MarketProfileCharts/>,
  roles:ALLOWED_ROLES_ALL
}

const OF_CHART_COMP= {
  name: "Orderflow Charts",
  route: "/orderflow",
  component: <OrderflowCharts/>,
  roles:ALLOWED_ROLES_PRO_1,
  resources:RESOURCE_LIST.ORDERFLOW
}

const LINE_CHART_COMP= {
  name: "Line Charts",
  route: "/line-chart",
  component: <LineChartMain/>,
  roles:ALLOWED_ROLES_FAST_DATA
}

const LINE_CHART_COMP_NEW= {
  name: "Candlestick Charts",
  route: "/candle-line-chart",
  component: <LineChartMainNew/>,
  roles:ALLOWED_ROLES_PRO_1
}

// const CANLDE_CHART_COMP= {
//   name: "Candlestick Charts",
//   route: "/candle",
//   component: <CandlestickCharts/>,
//   roles:ALLOWED_ROLES_PRO_2
// }


const NIFTY_SPOT_OIX_CHART_COMP=  {
  name: "NF Options Strike Spectrum Charts",
  // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&prem=fut&plot=spt&gf=1&gr=1&gm=1",
  route: "/line-chart/NF_Options_Strike_Spectrum_Charts",
  component: <LineChart id="nspotOixLineChart" stateData={NIFTY_SPOT_OIX_CHART_DATA}/>,
  stateData:NIFTY_SPOT_OIX_CHART_DATA,
  roles:ALLOWED_ROLES_PRO_1
}

const BANKNIFTY_SPOT_OIX_CHART_COMP={
  name: "BN Options Strike Spectrum Charts",
  // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&prem=fut&plot=spt&gf=1&gr=1&gm=1",
  route: "/line-chart/BN_Options_Strike_Spectrum_Charts",
  component: <LineChart id="bnspotOixLineChart"/>,
  stateData:BANKNIFTY_SPOT_OIX_CHART_DATA,
  roles:ALLOWED_ROLES_PRO_1
}

const FINNIFTY_SPOT_OIX_CHART_COMP={
  name: "FN Options Strike Spectrum Charts",
  // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&prem=fut&plot=spt&gf=1&gr=1&gm=1",
  route: "/line-chart/FN_Options_Strike_Spectrum_Charts",
  component: <LineChart id="fnspotOixLineChart"/>,
  stateData:FINNIFTY_SPOT_OIX_CHART_DATA,
  roles:ALLOWED_ROLES_PRO_1
}

export const SPOT_OIX_CHART_DATA={
  name:"Options Strike Spectrum Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&prem=fut&plot=spt&gf=1&gr=1&gm=1",
      route: "/line-chart/NF_Options_Strike_Spectrum_Charts",
      component: <LineChart id="nspotOixLineChart" stateData={NIFTY_SPOT_OIX_CHART_DATA}/>,
      stateData:NIFTY_SPOT_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&prem=fut&plot=spt&gf=1&gr=1&gm=1",
      route: "/line-chart/BN_Options_Strike_Spectrum_Charts",
      component: <LineChart id="bnspotOixLineChart"/>,
      stateData:BANKNIFTY_SPOT_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&prem=fut&plot=spt&gf=1&gr=1&gm=1",
      route: "/line-chart/FN_Options_Strike_Spectrum_Charts",
      component: <LineChart id="fnspotOixLineChart"/>,
      stateData:FINNIFTY_SPOT_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&prem=fut&plot=spt&gf=1&gr=1&gm=1",
      route: "/line-chart/MN_Options_Strike_Spectrum_Charts",
      component: <LineChart id="mnspotOixLineChart"/>,
      stateData:MIDCPNIFTY_SPOT_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    }

      
  ]

}

export const SPOT_OIX_CHART_DATA_NEW={
  name:"Options Strike Spectrum Charts Beta",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&prem=fut&plot=spt&gf=1&gr=1&gm=1",
      route: "/candle-line-chart/NF_Options_Strike_Spectrum_Charts",
      component: <LineChartNew id="nspotOixLineChart" stateData={NIFTY_SPOT_OIX_CHART_DATA}/>,
      stateData:NIFTY_SPOT_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&prem=fut&plot=spt&gf=1&gr=1&gm=1",
      route: "/candle-line-chart/BN_Options_Strike_Spectrum_Charts",
      component: <LineChartNew id="bnspotOixLineChart"/>,
      stateData:BANKNIFTY_SPOT_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&prem=fut&plot=spt&gf=1&gr=1&gm=1",
      route: "/candle-line-chart/FN_Options_Strike_Spectrum_Charts",
      component: <LineChartNew id="fnspotOixLineChart"/>,
      stateData:FINNIFTY_SPOT_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&prem=fut&plot=spt&gf=1&gr=1&gm=1",
      route: "/candle-line-chart/MN_Options_Strike_Spectrum_Charts",
      component: <LineChartNew id="mnspotOixLineChart"/>,
      stateData:MIDCPNIFTY_SPOT_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    }

      
  ]

}

const NIFTY_ALLOPT_DOI_CHART_COMP=  {
  name: "NF Options OI Change Charts",
  // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
  route: "/line-chart/NF_Options_OI_Change_Charts",
  component: <LineChart id="nAllOptDoiLineChart"/>,
  stateData:NIFTY_ALLOPT_DOI_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA
}

const BANKNIFTY_ALLOPT_DOI_CHART_COMP={
  name: "BN Options OI Change Charts",
  // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doi",
  route: "/line-chart/BN_Options_OI_Change_Charts",
  component: <LineChart id="bnAllOptDoiLineChart"/>,
  stateData:BANKNIFTY_ALLOPT_DOI_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA,
  
}

const FINNIFTY_ALLOPT_DOI_CHART_COMP={
  name: "FN Options OI Change Charts",
  // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doi",
  route: "/line-chart/FN_Options_OI_Change_Charts",
  component: <LineChart id="fnAllOptDoiLineChart"/>,
  stateData:FINNIFTY_ALLOPT_DOI_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA,
  
}

export const ALLOPT_DOI_CHART_DATA={
  name:"Options OI Change Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/NF_Options_OI_Change_Charts",
      component: <LineChart id="nAllOptDoiLineChart"/>,
      stateData:NIFTY_ALLOPT_DOI_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/BN_Options_OI_Change_Charts",
      component: <LineChart id="bnAllOptDoiLineChart"/>,
      stateData:BANKNIFTY_ALLOPT_DOI_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA,
      
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/FN_Options_OI_Change_Charts",
      component: <LineChart id="fnAllOptDoiLineChart"/>,
      stateData:FINNIFTY_ALLOPT_DOI_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA,
      
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/MN_Options_OI_Change_Charts",
      component: <LineChart id="mnAllOptDoiLineChart"/>,
      stateData:MIDCPNIFTY_ALLOPT_DOI_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA,
      
    }

      
  ]

}

export const ALLOPT_DOI_CHART_DATA_NEW={
  name:"Options OI Change Charts Beta",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/NF_Options_OI_Change_Charts",
      component: <LineChartNew id="nAllOptDoiLineChart"/>,
      stateData:NIFTY_ALLOPT_DOI_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/BN_Options_OI_Change_Charts",
      component: <LineChartNew id="bnAllOptDoiLineChart"/>,
      stateData:BANKNIFTY_ALLOPT_DOI_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1,
      
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/FN_Options_OI_Change_Charts",
      component: <LineChartNew id="fnAllOptDoiLineChart"/>,
      stateData:FINNIFTY_ALLOPT_DOI_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1,
      
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/MN_Options_OI_Change_Charts",
      component: <LineChartNew id="mnAllOptDoiLineChart"/>,
      stateData:MIDCPNIFTY_ALLOPT_DOI_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1,
      
    }

      
  ]

}

export const ALLOPT_VOL_CHART_DATA={
  name:"Options Volume Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/NF_Options_Vol_Charts",
      component: <LineChart id="nAllOptVolLineChart"/>,
      stateData:NIFTY_ALLOPT_VOL_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/BN_Options_Vol_Charts",
      component: <LineChart id="bnAllOptVolLineChart"/>,
      stateData:BANKNIFTY_ALLOPT_VOL_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/FN_Options_Vol_Charts",
      component: <LineChart id="fnAllOptVolLineChart"/>,
      stateData:FINNIFTY_ALLOPT_VOL_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/MN_Options_Vol_Charts",
      component: <LineChart id="mnAllOptVolLineChart"/>,
      stateData:MIDCPNIFTY_ALLOPT_VOL_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
  ]
}

export const ALLOPT_VOL_CHART_DATA_NEW={
  name:"Options Volume Charts Beta",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/NF_Options_Vol_Charts",
      component: <LineChartNew id="nAllOptVolLineChart"/>,
      stateData:NIFTY_ALLOPT_VOL_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/BN_Options_Vol_Charts",
      component: <LineChartNew id="bnAllOptVolLineChart"/>,
      stateData:BANKNIFTY_ALLOPT_VOL_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/FN_Options_Vol_Charts",
      component: <LineChartNew id="fnAllOptVolLineChart"/>,
      stateData:FINNIFTY_ALLOPT_VOL_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/MN_Options_Vol_Charts",
      component: <LineChartNew id="mnAllOptVolLineChart"/>,
      stateData:MIDCPNIFTY_ALLOPT_VOL_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
  ]
}
export const ALLOPT_DVOLX_CHART_DATA={
  name:"Options NTM Volume Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/NF_Options_NTM_Vol_Charts",
      component: <LineChart id="nAllOptNtmVolLineChart"/>,
      stateData:NIFTY_ALLOPT_DVOLX_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/BN_Options_NTM_Vol_Charts",
      component: <LineChart id="bnAllOptNtmVolLineChart"/>,
      stateData:BANKNIFTY_ALLOPT_DVOLX_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/FN_Options_NTM_Vol_Charts",
      component: <LineChart id="fnAllNtmOptVolLineChart"/>,
      stateData:FINNIFTY_ALLOPT_DVOLX_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/MN_Options_NTM_Vol_Charts",
      component: <LineChart id="mnAllOptNtmVolLineChart"/>,
      stateData:MIDCPNIFTY_ALLOPT_DVOLX_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
  ]
}

export const ALLOPT_DVOLX_CHART_DATA_NEW={
  name:"Options NTM Volume Charts Beta",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/NF_Options_NTM_Vol_Charts",
      component: <LineChartNew id="nAllOptNtmVolLineChart"/>,
      stateData:NIFTY_ALLOPT_DVOLX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/BN_Options_NTM_Vol_Charts",
      component: <LineChartNew id="bnAllOptNtmVolLineChart"/>,
      stateData:BANKNIFTY_ALLOPT_DVOLX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/FN_Options_NTM_Vol_Charts",
      component: <LineChartNew id="fnAllNtmOptVolLineChart"/>,
      stateData:FINNIFTY_ALLOPT_DVOLX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/MN_Options_NTM_Vol_Charts",
      component: <LineChartNew id="mnAllOptNtmVolLineChart"/>,
      stateData:MIDCPNIFTY_ALLOPT_DVOLX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
  ]
}

export const ALLOPT_VOLX_CHART_DATA={
  name:"Options NTM Volx Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/NF_Options_NTM_Volx_Charts",
      component: <LineChart id="nAllOptNtmVolxLineChart"/>,
      stateData:NIFTY_ALLOPT_VOLX_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/BN_Options_NTM_Volx_Charts",
      component: <LineChart id="bnAllOptNtmVolxLineChart"/>,
      stateData:BANKNIFTY_ALLOPT_VOLX_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/FN_Options_NTM_Volx_Charts",
      component: <LineChart id="fnAllNtmOptVolxLineChart"/>,
      stateData:FINNIFTY_ALLOPT_VOLX_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/line-chart/MN_Options_NTM_Volx_Charts",
      component: <LineChart id="mnAllOptNtmVolxLineChart"/>,
      stateData:MIDCPNIFTY_ALLOPT_VOLX_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
  ]
}

export const ALLOPT_VOLX_CHART_DATA_NEW={
  name:"Options NTM Volx Charts Beta",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/NF_Options_NTM_Volx_Charts",
      component: <LineChartNew id="nAllOptNtmVolxLineChart"/>,
      stateData:NIFTY_ALLOPT_VOLX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/BN_Options_NTM_Volx_Charts",
      component: <LineChartNew id="bnAllOptNtmVolxLineChart"/>,
      stateData:BANKNIFTY_ALLOPT_VOLX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/FN_Options_NTM_Volx_Charts",
      component: <LineChartNew id="fnAllNtmOptVolxLineChart"/>,
      stateData:FINNIFTY_ALLOPT_VOLX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doi",
      route: "/candle-line-chart/MN_Options_NTM_Volx_Charts",
      component: <LineChartNew id="mnAllOptNtmVolxLineChart"/>,
      stateData:MIDCPNIFTY_ALLOPT_VOLX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
  ]
}

const NIFTY_ALLOPT_DOIX_CHART_COMP=  {
  name: "NF Options NTM OI Change Charts",
  // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
  route: "/line-chart/NF_Options_NTM_OI_Change_Charts",
  component: <LineChart id="nAllOptDoixLineChart" />,
  stateData:NIFTY_ALLOPT_DOIX_CHART_DATA,
  roles:ALLOWED_ROLES_PRO_1
}

const BANKNIFTY_ALLOPT_DOIX_CHART_COMP={
  name: "BN Options NTM OI Change Charts",
  // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
  route: "/line-chart/BN_Options_NTM_OI_Change_Charts",
  component: <LineChart id="bnAllOptDoixLineChart" />,
  stateData:BANKNIFTY_ALLOPT_DOIX_CHART_DATA,
  roles:ALLOWED_ROLES_PRO_1
}

const FINNIFTY_ALLOPT_DOIX_CHART_COMP={
  name: "FN Options NTM OI Change Charts",
  // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
  route: "/line-chart/FN_Options_NTM_OI_Change_Charts",
  component: <LineChart id="fnAllOptDoixLineChart" />,
  stateData:FINNIFTY_ALLOPT_DOIX_CHART_DATA,
  roles:ALLOWED_ROLES_PRO_1
}

export const ALLOPT_DOIX_CHART_DATA={
  name:"Options NTM OI Change Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/line-chart/NF_Options_NTM_OI_Change_Charts",
      component: <LineChart id="nAllOptDoixLineChart" />,
      stateData:NIFTY_ALLOPT_DOIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/line-chart/BN_Options_NTM_OI_Change_Charts",
      component: <LineChart id="bnAllOptDoixLineChart" />,
      stateData:BANKNIFTY_ALLOPT_DOIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/line-chart/FN_Options_NTM_OI_Change_Charts",
      component: <LineChart id="fnAllOptDoixLineChart" />,
      stateData:FINNIFTY_ALLOPT_DOIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/line-chart/MN_Options_NTM_OI_Change_Charts",
      component: <LineChart id="mnAllOptDoixLineChart" />,
      stateData:MIDCPNIFTY_ALLOPT_DOIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    }

      
  ]

}


export const ALLOPT_DOIX_CHART_DATA_NEW={
  name:"Options NTM OI Change Charts Beta",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/candle-line-chart/NF_Options_NTM_OI_Change_Charts",
      component: <LineChartNew id="nAllOptDoixLineChart" />,
      stateData:NIFTY_ALLOPT_DOIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/candle-line-chart/BN_Options_NTM_OI_Change_Charts",
      component: <LineChartNew id="bnAllOptDoixLineChart" />,
      stateData:BANKNIFTY_ALLOPT_DOIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/candle-line-chart/FN_Options_NTM_OI_Change_Charts",
      component: <LineChartNew id="fnAllOptDoixLineChart" />,
      stateData:FINNIFTY_ALLOPT_DOIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/candle-line-chart/MN_Options_NTM_OI_Change_Charts",
      component: <LineChartNew id="mnAllOptDoixLineChart" />,
      stateData:MIDCPNIFTY_ALLOPT_DOIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    }

      
  ]

}

const NIFTY_FUTURES_OIX_CHART_COMP=  {
  name: "NF Futures mViz Charts",
  // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&fvol=1&prem=bias&doix=1&plot=fut&gf=1&trp=1",
  route: "/line-chart/NF_Futures_MViz_Chart",
  component: <LineChart id="noixLineChart"/>,
  stateData:NIFTY_FUTURES_OIX_CHART_DATA,
  roles:ALLOWED_ROLES_PRO_1
}

const BANKNIFTY_FUTURES_OIX_CHART_COMP= {
  name: "BN Futures mViz Charts",
  // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&fvol=1&prem=bias&doix=1&plot=fut&gf=1&trp=1",
  route: "/line-chart/BN_Futures_MViz_Chart",
  component: <LineChart id="bnoixLineChart"/>,
  stateData:BANKNIFTY_FUTURES_OIX_CHART_DATA,
  roles:ALLOWED_ROLES_PRO_1
}

export const FUTURES_OIX_CHART_DATA={
  name:"Futures mViz Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/line-chart/NF_Futures_MViz_Chart",
      component: <LineChart id="noixLineChart"/>,
      stateData:NIFTY_FUTURES_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/line-chart/BN_Futures_MViz_Chart",
      component: <LineChart id="bnoixLineChart"/>,
      stateData:BANKNIFTY_FUTURES_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
   

      
  ]

}

export const FUTURES_OIX_CHART_DATA_NEW={
  name:"Futures mViz Charts Beta",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/candle-line-chart/NF_Futures_MViz_Chart",
      component: <LineChartNew id="noixLineChart"/>,
      stateData:NIFTY_FUTURES_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/candle-line-chart/BN_Futures_MViz_Chart",
      component: <LineChartNew id="bnoixLineChart"/>,
      stateData:BANKNIFTY_FUTURES_OIX_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
   

      
  ]

}

const NIFTY_HV_CHART_COMP={
  name: "NF HV Charts",
  // route: "/hv-stock-chart?symbol=NIFTY",
  route: "/hv-stock-chart/NIFTY",
  component: <StockChartHV id="nhv"/>,
  stateData:NIFTY_HV_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA
}
const BANKNIFTY_HV_CHART_COMP={
  name: "BN HV Charts",
  // route: "/hv-stock-chart?symbol=BANKNIFTY",
  route: "/hv-stock-chart/BANKNIFTY",
  component: <StockChartHV id="bnhv"/>,
  stateData:BANKNIFTY_HV_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA
}

export const HV_CHART_DATA={
  name:"HV Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/hv-stock-chart/NIFTY",
      component: <StockChartHV id="nhv"/>,
      stateData:NIFTY_HV_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/hv-stock-chart/BANKNIFTY",
      component: <StockChartHV id="bnhv"/>,
      stateData:BANKNIFTY_HV_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
   

      
  ]

}
const NIFTY_GCASH_BAR_CHARTS_COMP={
  name: "NF Options Strikewise Gamma Charts",
  // route: "/bar-chart?symbol=NIFTY&plot=net_gcash",
  route: "/bar-chart/NIFTY_Strike_Value",
  component: <BarChart id="nbarsv"/>,
  stateData:NIFTY_GCASH_BAR_CHART_DATA,
  roles:ALLOWED_ROLES_PRO_1
}

const BANKNIFTY_GCASH_BAR_CHARTS_COMP={
  name: "BN Options Strikewise Gamma Charts",
  // route: "/bar-chart?symbol=BANKNIFTY&plot=net_gcash",
  route: "/bar-chart/BANKNIFTY_Strike_Value",
  component: <BarChart id="bnbarsv"/>,
  stateData:BANKNIFTY_GCASH_BAR_CHART_DATA,
  roles:ALLOWED_ROLES_PRO_1
}

const FINNIFTY_GCASH_BAR_CHARTS_COMP={
  name: "FN Options Strikewise Gamma Charts",
  // route: "/bar-chart?symbol=BANKNIFTY&plot=net_gcash",
  route: "/bar-chart/FINNIFTY_Strike_Value",
  component: <BarChart id="bnbarsv"/>,
  stateData:FINNIFTY_GCASH_BAR_CHART_DATA,
  roles:ALLOWED_ROLES_PRO_1
}

export const GCASH_BAR_CHARTS_DATA={
  name:"Options Strikewise Gamma Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/NIFTY_Strike_Value",
      component: <BarChart id="nbarsv"/>,
      stateData:NIFTY_GCASH_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/BANKNIFTY_Strike_Value",
      component: <BarChart id="bnbarsv"/>,
      stateData:BANKNIFTY_GCASH_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/FINNIFTY_Strike_Value",
      component: <BarChart id="fnbarsv"/>,
      stateData:FINNIFTY_GCASH_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/MIDCPNIFTY_Strike_Value",
      component: <BarChart id="mnbarsv"/>,
      stateData:MIDCPNIFTY_GCASH_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    }

      
  ]

}

export const WCASH_BAR_CHARTS_DATA={
  name:"Options Strikewise WCash Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/NIFTY_Strike_Wcash",
      component: <BarChart id="nbarsw"/>,
      stateData:NIFTY_WCASH_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/BANKNIFTY_Strike_Wcash",
      component: <BarChart id="bnbarsw"/>,
      stateData:BANKNIFTY_WCASH_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/FINNIFTY_Strike_Wcash",
      component: <BarChart id="fnbarsw"/>,
      stateData:FINNIFTY_WCASH_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/MIDCPNIFTY_Strike_Wcash",
      component: <BarChart id="mnbarsw"/>,
      stateData:MIDCPNIFTY_WCASH_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_PRO_1
    }

      
  ]

}

const NIFTY_OI_BAR_CHARTS_COMP={
  name: "NF Options Strikewise oiViz Charts",
  // route: "/bar-chart?symbol=NIFTY&plot=net_gcash",
  route: "/bar-chart/NIFTY_Strike_OI",
  component: <BarChart id="nbarsv"/>,
  stateData:NIFTY_OI_BAR_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA
}

const BANKNIFTY_OI_BAR_CHARTS_COMP={
  name: "BN Options Strikewise oiViz Charts",
  // route: "/bar-chart?symbol=BANKNIFTY&plot=net_gcash",
  route: "/bar-chart/BANKNIFTY_Strike_OI",
  component: <BarChart id="bnbarsv"/>,
  stateData:BANKNIFTY_OI_BAR_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA
}

const FINNIFTY_OI_BAR_CHARTS_COMP={
  name: "FN Options Strikewise oiViz Charts",
  // route: "/bar-chart?symbol=BANKNIFTY&plot=net_gcash",
  route: "/bar-chart/FINNIFTY_Strike_OI",
  component: <BarChart id="fnbarsv"/>,
  stateData:FINNIFTY_OI_BAR_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA
}

export const OI_BAR_CHARTS_DATA={
  name:"Options Strikewise oiViz Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/NIFTY_Strike_OI",
      component: <BarChart id="nbarsv"/>,
      stateData:NIFTY_OI_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/BANKNIFTY_Strike_OI",
      component: <BarChart id="bnbarsv"/>,
      stateData:BANKNIFTY_OI_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/FINNIFTY_Strike_OI",
      component: <BarChart id="fnbarsv"/>,
      stateData:FINNIFTY_OI_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/MIDCPNIFTY_Strike_OI",
      component: <BarChart id="mnbarsv"/>,
      stateData:MIDCPNIFTY_OI_BAR_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    }

      
  ]

}

const NIFTY_IV_CHARTS_COMP={
  name: "NF IV Curve Charts",
  // route: "/bar-chart?symbol=NIFTY&plot=net_gcash",
  route: "/bar-chart/NIFTY_Strike_IV",
  component: <BarChart id="nbariv"/>,
  stateData:NIFTY_IV_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA
}

const BANKNIFTY_IV_CHARTS_COMP={
  name: "BN IV Curve Charts",
  // route: "/bar-chart?symbol=BANKNIFTY&plot=net_gcash",
  route: "/bar-chart/BANKNIFTY_Strike_IV",
  component: <BarChart id="bnbariv"/>,
  stateData:BANKNIFTY_IV_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA
}

export const IV_CHARTS_DATA={
  name:"IV Curve Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/NIFTY_Strike_IV",
      component: <BarChart id="nbariv"/>,
      stateData:NIFTY_IV_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/bar-chart/BANKNIFTY_Strike_IV",
      component: <BarChart id="bnbariv"/>,
      stateData:BANKNIFTY_IV_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
      
  ]

}

const NIFTY_SNAKES_CHARTS_COMP={
  name: "NF Options Snake Charts",
  // route: "/bar-chart?symbol=NIFTY&plot=net_gcash",
  route: "/line-chart/NIFTY_Snakes",
  component: <LineChart id="nfsnake"/>,
  stateData:NIFTY_SNAKE_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA
}

const BANKNIFTY_SNAKES_CHARTS_COMP={
  name: "BN Options Snake Charts",
  // route: "/bar-chart?symbol=NIFTY&plot=net_gcash",
  route: "/line-chart/BANKNIFTY_Snakes",
  component: <LineChart id="bnsnake"/>,
  stateData:BANKNIFTY_SNAKE_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA
}

const FINNIFTY_SNAKES_CHARTS_COMP={
  name: "FN Options Snake Charts",
  // route: "/bar-chart?symbol=NIFTY&plot=net_gcash",
  route: "/line-chart/FINNIFTY_Snakes",
  component: <LineChart id="fnfsnake"/>,
  stateData:FINNIFTY_SNAKE_CHART_DATA,
  roles:ALLOWED_ROLES_FAST_DATA
}

export const SNAKES_CHARTS_DATA={
  name:"Options Snake Charts",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/line-chart/NIFTY_Snakes",
      component: <LineChart id="nfsnake"/>,
      stateData:NIFTY_SNAKE_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/line-chart/BANKNIFTY_Snakes",
      component: <LineChart id="bnsnake"/>,
      stateData:BANKNIFTY_SNAKE_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/line-chart/FINNIFTY_Snakes",
      component: <LineChart id="fnfsnake"/>,
      stateData:FINNIFTY_SNAKE_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/line-chart/MIDCPNIFTY_Snakes",
      component: <LineChart id="mcpnsnake"/>,
      stateData:MIDCPNIFTY_SNAKE_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    }

      
  ]

}

export const SNAKES_CHARTS_DATA_NEW={
  name:"Options Snake Charts Beta",
  open:false,
  items:[
    {
      name: "NIFTY",
      // route: "/line-chart?type=SINGLE&symbol=NIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/candle-line-chart/NIFTY_Snakes",
      component: <LineChartNew id="nfsnake"/>,
      stateData:NIFTY_SNAKE_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "BANKNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/candle-line-chart/BANKNIFTY_Snakes",
      component: <LineChartNew id="bnsnake"/>,
      stateData:BANKNIFTY_SNAKE_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "FINNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/candle-line-chart/FINNIFTY_Snakes",
      component: <LineChartNew id="fnfsnake"/>,
      stateData:FINNIFTY_SNAKE_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    },
    {
      name: "MIDCPNIFTY",
      // route: "/line-chart?type=SINGLE&symbol=BANKNIFTY&startDate=&nDays=1&plot=all_doix",
      route: "/candle-line-chart/MIDCPNIFTY_Snakes",
      component: <LineChartNew id="mcpnsnake"/>,
      stateData:MIDCPNIFTY_SNAKE_CHART_DATA,
      roles:ALLOWED_ROLES_FAST_DATA
    }

      
  ]

}

export const ADMIN_PAGES_PRO2_DATA={
  name:"System Admin",
  open:false,
  items:[
    {
      name: "User Admin",
      route: "/user-admin",
      roles:ALLOWED_ROLES_PRO_2
    },
      
  ]

}

export const ADMIN_PAGES_DATA={
  name:"System Admin",
  open:false,
  items:[
    {
      name: "Feed Admin",
      route: "/feed-admin",
      roles:ALLOWED_ROLES_ADMIN
    },
    {
      name: "User Admin",
      route: "/user-admin",
      roles:ALLOWED_ROLES_PRO_2
    },
      
  ]

}




const OPTIONS_TABLE_COMP={
  name: "Options Table",
  route: "/options-table",
  component:<OPTIONS_TABLE/>,
  roles:ALLOWED_ROLES_ALL
}

const GREEKS_TABLE_COMP={
  name: "Greeks Table",
  route: "/greek-chart",
  component:<GREEKS_TABLE/>,
  roles:ALLOWED_ROLES_ALL
}

const INDEX_TABLE_COMP={
  name: "Index Table",
  route: "/index-table",
  component:<INDEX_TABLE/>,
  roles:ALLOWED_ROLES_ALL
}

const FII_DATA_COMP={
  name: "FII Data",
  route: "/fii-data",
  roles:ALLOWED_ROLES_ALL
}

const FEED_ADMIN_COMP={
  name: "Feed Admin",
  route: "/feed-admin",
  roles:ALLOWED_ROLES_ADMIN
}

const USER_ADMIN_COMP={
  name: "User Admin",
  route: "/user-admin",
  roles:ALLOWED_ROLES_PRO_2
}

export const BOOKMARK_COMP={
  name: "Bookmarks",
  route:"/bookmarks",
  component:<Bookmark/>,
  roles:ALLOWED_ROLES_PRO_1
}

const PROFILE_DETAILS_COMP={
    name: "Profile Details",
    route: "/profile",
    roles:ALLOWED_ROLES_ALL
}

const SETTINGS_COMP={
    name: "User Settings",
    route: "/settings",
    roles:ALLOWED_ROLES_ALL
}

const USER_MANUAL_COMP={
    name: "User Manual",
    route: "/user-manual",
    roles:ALLOWED_ROLES_ALL
}

const FAQ_COMP={
  name: "FAQ and Glossary",
  route: "/faq",
  roles:ALLOWED_ROLES_ALL
}

const INTRO_COMP={
  name: "Intro Videos",
  route: "/intros",
  roles:ALLOWED_ROLES_ALL
}

const HELP_COMP={
    name: "Help",
    route: "/help",
    roles:ALLOWED_ROLES_ALL
}

const SUBSCRIPTION_COMPONENT={
  name:"Subscription Plans",
  route:"/subscription",
  roles:ALLOWED_ROLES_ALL
}

// const SUBSCRIPTION_COMPONENT_NEW={
//   name:"Subscription Plans New",
//   route:"/subscription-new",
//   roles:ALLOWED_ROLES_PRO_2
// }

export const getDrawerList=(auth)=>{
  console.log("auth=",auth);
  let drawerList=[];
  const isAdmin=(auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN))?true:false;
  const isPRO_1=(auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))?true:false;
  const isPRO_2=(auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2))?true:false;
  const isFastData=(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?true:false;
  const isBasic=true;
  const isEliteUser=auth.resources.find(el=>el==USER_RESOURCES_LIST.TBT_FEED)
  
  if(isAdmin){
    drawerList.push(SUBSCRIPTION_COMPONENT);
    // drawerList.push(SUBSCRIPTION_COMPONENT_NEW);
    drawerList.push(BROKER_LOGIN_COMPONENT);
    drawerList.push(MP_CHART_COMP);
    drawerList.push(OF_CHART_COMP);
    // drawerList.push(CANLDE_CHART_COMP);
    if(isEliteUser)
      drawerList.push(LINE_CHART_COMP_NEW);
    else
      drawerList.push(LINE_CHART_COMP);
    
    drawerList.push(OPTIONS_TABLE_COMP);
    drawerList.push(GREEKS_TABLE_COMP);
    drawerList.push(INDEX_TABLE_COMP);
    // if(isEliteUser){
      drawerList.push(SPOT_OIX_CHART_DATA);
      drawerList.push(SPOT_OIX_CHART_DATA_NEW);
      drawerList.push(ALLOPT_DOIX_CHART_DATA);
      drawerList.push(ALLOPT_DOIX_CHART_DATA_NEW);
      drawerList.push(ALLOPT_DOI_CHART_DATA);
      drawerList.push(ALLOPT_DOI_CHART_DATA_NEW);
      drawerList.push(ALLOPT_VOL_CHART_DATA);
      drawerList.push(ALLOPT_VOL_CHART_DATA_NEW);
      drawerList.push(ALLOPT_DVOLX_CHART_DATA);
      drawerList.push(ALLOPT_DVOLX_CHART_DATA_NEW);
      drawerList.push(ALLOPT_VOLX_CHART_DATA);
      drawerList.push(ALLOPT_VOLX_CHART_DATA_NEW);
      drawerList.push(FUTURES_OIX_CHART_DATA);
      drawerList.push(FUTURES_OIX_CHART_DATA_NEW);
    // }else{
     
      
     
      
      
      
     
    // }
    
   
   
    drawerList.push(GCASH_BAR_CHARTS_DATA);
    drawerList.push(WCASH_BAR_CHARTS_DATA);
    drawerList.push(OI_BAR_CHARTS_DATA);
    drawerList.push(IV_CHARTS_DATA);
    if(isEliteUser)
      drawerList.push(SNAKES_CHARTS_DATA_NEW);
    else 
    drawerList.push(SNAKES_CHARTS_DATA);

    drawerList.push(HV_CHART_DATA);
    drawerList.push(FII_DATA_COMP);
    // drawerList.push(FEED_ADMIN_COMP);
    // drawerList.push(USER_ADMIN_COMP);
    drawerList.push(BOOKMARK_COMP);
    drawerList.push(ADMIN_PAGES_DATA);
    drawerList.push(PROFILE_DETAILS_COMP)
    drawerList.push(SETTINGS_COMP);
    drawerList.push(USER_MANUAL_COMP);
    drawerList.push(FAQ_COMP);
    drawerList.push(INTRO_COMP);
    // drawerList.push(HELP_COMP);
  }else if(isPRO_2){
    drawerList.push(SUBSCRIPTION_COMPONENT);
    // drawerList.push(SUBSCRIPTION_COMPONENT_NEW);
    drawerList.push(BROKER_LOGIN_COMPONENT);
    drawerList.push(MP_CHART_COMP);
    drawerList.push(OF_CHART_COMP);
    // drawerList.push(CANLDE_CHART_COMP);
    if(isEliteUser)
      drawerList.push(LINE_CHART_COMP_NEW);
    else
      drawerList.push(LINE_CHART_COMP);
    
    drawerList.push(OPTIONS_TABLE_COMP);
    drawerList.push(GREEKS_TABLE_COMP);
    drawerList.push(INDEX_TABLE_COMP);
    
    if(isEliteUser){
      drawerList.push(SPOT_OIX_CHART_DATA_NEW);
      drawerList.push(ALLOPT_DOIX_CHART_DATA_NEW);
      drawerList.push(ALLOPT_DOI_CHART_DATA_NEW);
      drawerList.push(ALLOPT_VOL_CHART_DATA_NEW);
      drawerList.push(ALLOPT_DVOLX_CHART_DATA_NEW);
      drawerList.push(ALLOPT_VOLX_CHART_DATA_NEW);
      drawerList.push(FUTURES_OIX_CHART_DATA_NEW);
    }else{
      drawerList.push(SPOT_OIX_CHART_DATA);
      drawerList.push(ALLOPT_DOIX_CHART_DATA);
      drawerList.push(ALLOPT_DOI_CHART_DATA);
      drawerList.push(ALLOPT_VOL_CHART_DATA);
      drawerList.push(ALLOPT_DVOLX_CHART_DATA);
      drawerList.push(ALLOPT_VOLX_CHART_DATA);
      drawerList.push(FUTURES_OIX_CHART_DATA);
    }
    
   
    drawerList.push(GCASH_BAR_CHARTS_DATA);
    drawerList.push(WCASH_BAR_CHARTS_DATA);
    drawerList.push(OI_BAR_CHARTS_DATA);
    drawerList.push(IV_CHARTS_DATA);
    drawerList.push(HV_CHART_DATA);
    if(isEliteUser)
      drawerList.push(SNAKES_CHARTS_DATA_NEW);
    else 
      drawerList.push(SNAKES_CHARTS_DATA);

    drawerList.push(FII_DATA_COMP);
    // drawerList.push(USER_ADMIN_COMP);
    drawerList.push(BOOKMARK_COMP);
    drawerList.push(ADMIN_PAGES_PRO2_DATA);
    drawerList.push(PROFILE_DETAILS_COMP)
    drawerList.push(SETTINGS_COMP);
    drawerList.push(USER_MANUAL_COMP);
    drawerList.push(FAQ_COMP);
    drawerList.push(INTRO_COMP);
    // drawerList.push(HELP_COMP);
  }else{
    drawerList.push(SUBSCRIPTION_COMPONENT);
    // drawerList.push(BROKER_LOGIN_COMPONENT);
    drawerList.push(MP_CHART_COMP);
    drawerList.push(OF_CHART_COMP);
    // if(isEliteUser)
    //   drawerList.push(LINE_CHART_COMP_NEW);
    // else
      drawerList.push(LINE_CHART_COMP);
    
    // drawerList.push(CANLDE_CHART_COMP);
    drawerList.push(OPTIONS_TABLE_COMP);
    drawerList.push(GREEKS_TABLE_COMP);
    drawerList.push(INDEX_TABLE_COMP);
    // if(isEliteUser){
    //   drawerList.push(SPOT_OIX_CHART_DATA_NEW);
    //   drawerList.push(ALLOPT_DOIX_CHART_DATA_NEW);
    //   drawerList.push(ALLOPT_DOI_CHART_DATA_NEW);
    // }else
    {
      drawerList.push(SPOT_OIX_CHART_DATA);
      drawerList.push(ALLOPT_DOIX_CHART_DATA);
      drawerList.push(ALLOPT_DOI_CHART_DATA);
    }
   

    drawerList.push(GCASH_BAR_CHARTS_DATA);
    drawerList.push(WCASH_BAR_CHARTS_DATA);
    drawerList.push(OI_BAR_CHARTS_DATA);
    drawerList.push(IV_CHARTS_DATA);
    // if(isEliteUser)
    //   drawerList.push(SNAKES_CHARTS_DATA_NEW);
    //   else
    drawerList.push(SNAKES_CHARTS_DATA);

   
    
    drawerList.push(HV_CHART_DATA);
    drawerList.push(FII_DATA_COMP);
    drawerList.push(BOOKMARK_COMP);
    drawerList.push(PROFILE_DETAILS_COMP)
    drawerList.push(SETTINGS_COMP);
    drawerList.push(USER_MANUAL_COMP);
    drawerList.push(FAQ_COMP);
    drawerList.push(INTRO_COMP);
    // drawerList.push(HELP_COMP);
  }
console.log("configdata drawerlist=",drawerList)
  return drawerList;
}


export const TIME_FRAME=[
  {
    key:"D",
    value:"daily",
    displayText:"Daily"
  },
  {
    key:"W",
    value:"weekly",
    displayText:"Calendar Week"
  },
  {
    key:"W_S",
    value:"weekly_s",
    displayText:"Weekly Series (Next day of Expiry to next Expiry day. Eg. Fri to Thu for NIFTY; Thu to Wed for BANKNIFTY and so on..)"
  },
  {
    key:"M",
    value:"monthly",
    displayText:"Calendar Month"
  },
  {
    key:"M_S",
    value:"monthly_s",
    displayText:"Monthly Series (Starting Friday post Expiry)"
  },{
    key:"Y",
    value:"yearly_d",
    displayText:"Calendar Year"
  },

]


export const getChartOptionsData=(auth)=>{
  console.log("auth=",auth);
  let chartList=[];
  const isAdmin=(auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN))?true:false;
  const isPRO_1=(auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))?true:false;
  const isPRO_2=(auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2))?true:false;
  const isFastData=(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?true:false;
  const isBasic=(auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN))?true:false;

  if(isAdmin){
    chartList.push(NIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(BANKNIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(FINNIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(MIDCPNIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_VOL_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_VOL_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_VOL_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_VOL_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_DVOLX_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_DVOLX_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_DVOLX_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_DVOLX_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_VOLX_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_VOLX_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_VOLX_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_VOLX_CHART_DATA);
    chartList.push(NIFTY_FUTURES_OIX_CHART_DATA);
    chartList.push(BANKNIFTY_FUTURES_OIX_CHART_DATA);
    chartList.push(NIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(BANKNIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(FINNIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(MIDCPNIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(NIFTY_OI_BAR_CHART_DATA);
    chartList.push(BANKNIFTY_OI_BAR_CHART_DATA);
    chartList.push(FINNIFTY_OI_BAR_CHART_DATA);
    chartList.push(MIDCPNIFTY_OI_BAR_CHART_DATA);
    chartList.push(NIFTY_IV_CHART_DATA);
    chartList.push(BANKNIFTY_IV_CHART_DATA);
    chartList.push(NIFTY_SNAKE_CHART_DATA);
    chartList.push(BANKNIFTY_SNAKE_CHART_DATA);
    chartList.push(FINNIFTY_SNAKE_CHART_DATA);
    chartList.push(MIDCPNIFTY_SNAKE_CHART_DATA);
    chartList.push(NIFTY_HV_CHART_DATA);
    chartList.push(BANKNIFTY_HV_CHART_DATA);

  }else if(isPRO_2){
    chartList.push(NIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(BANKNIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(FINNIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(MIDCPNIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_VOL_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_VOL_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_VOL_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_VOL_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_VOLX_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_VOLX_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_VOLX_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_VOLX_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_DVOLX_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_DVOLX_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_DVOLX_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_DVOLX_CHART_DATA);
    chartList.push(NIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(BANKNIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(FINNIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(MIDCPNIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(NIFTY_OI_BAR_CHART_DATA);
    chartList.push(BANKNIFTY_OI_BAR_CHART_DATA);
    chartList.push(FINNIFTY_OI_BAR_CHART_DATA);
    chartList.push(MIDCPNIFTY_OI_BAR_CHART_DATA);
    chartList.push(NIFTY_IV_CHART_DATA);
    chartList.push(BANKNIFTY_IV_CHART_DATA);
    chartList.push(NIFTY_SNAKE_CHART_DATA);
    chartList.push(BANKNIFTY_SNAKE_CHART_DATA);
    chartList.push(FINNIFTY_SNAKE_CHART_DATA);
    chartList.push(MIDCPNIFTY_SNAKE_CHART_DATA);
    chartList.push(NIFTY_HV_CHART_DATA);
    chartList.push(BANKNIFTY_HV_CHART_DATA);
  }else if(isPRO_1){
    chartList.push(NIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(BANKNIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(FINNIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(MIDCPNIFTY_SPOT_OIX_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_DOIX_CHART_DATA);
    chartList.push(NIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(NIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(BANKNIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(FINNIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(MIDCPNIFTY_GCASH_BAR_CHART_DATA);
    chartList.push(NIFTY_OI_BAR_CHART_DATA);
    chartList.push(BANKNIFTY_OI_BAR_CHART_DATA);
    chartList.push(FINNIFTY_OI_BAR_CHART_DATA);
    chartList.push(MIDCPNIFTY_OI_BAR_CHART_DATA);
    chartList.push(NIFTY_IV_CHART_DATA);
    chartList.push(BANKNIFTY_IV_CHART_DATA);
    chartList.push(NIFTY_SNAKE_CHART_DATA);
    chartList.push(BANKNIFTY_SNAKE_CHART_DATA);
    chartList.push(FINNIFTY_SNAKE_CHART_DATA);
    chartList.push(MIDCPNIFTY_SNAKE_CHART_DATA);
    chartList.push(NIFTY_HV_CHART_DATA);
    chartList.push(BANKNIFTY_HV_CHART_DATA);
  }
  else if(isFastData){
    chartList.push(NIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(BANKNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(FINNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(MIDCPNIFTY_ALLOPT_DOI_CHART_DATA);
    chartList.push(NIFTY_OI_BAR_CHART_DATA);
    chartList.push(BANKNIFTY_OI_BAR_CHART_DATA);
    chartList.push(FINNIFTY_OI_BAR_CHART_DATA);
    chartList.push(MIDCPNIFTY_OI_BAR_CHART_DATA);
    chartList.push(NIFTY_IV_CHART_DATA);
    chartList.push(BANKNIFTY_IV_CHART_DATA);
    chartList.push(NIFTY_SNAKE_CHART_DATA);
    chartList.push(BANKNIFTY_SNAKE_CHART_DATA);
    chartList.push(FINNIFTY_SNAKE_CHART_DATA);
    chartList.push(MIDCPNIFTY_SNAKE_CHART_DATA);
    chartList.push(NIFTY_HV_CHART_DATA);
    chartList.push(BANKNIFTY_HV_CHART_DATA);
  }else{
    // basic user has no options to display
  }
  return chartList;
  
}

