import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Typography,Select,MenuItem,FormControl,InputLabel,Checkbox,useTheme,Box } from "@mui/material";
import { BOOKMARKT_CATEGORY_DATA ,BOOKMARK_NAME_MAX_LENGTH,BOOKMARK_CATEGORY_MAX_LENGTH} from "../../../common/utility/constant";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function SaveBookmarkDialog(props) {
  const theme=useTheme();
  const [open, setOpen] = React.useState(props.open);
  const [title, setTitle] = React.useState("");
  const [bookmarkCategoryList,setBookmarkCategoryList]=React.useState([]);
  const [copyKRL,setCopyKrl]=React.useState(false);
 

  const [category, setCategory] = React.useState(BOOKMARKT_CATEGORY_DATA[0].value);
  const [categoryCustom, setCategoryCustom] = React.useState("");
  React.useEffect(()=>{
    const items = JSON.parse(localStorage.getItem('bookmark_category'));
    console.log("items=",items);
    let list=[]
    // list=[...BOOKMARKT_CATEGORY_DATA];
    if(items!=null && items!=undefined && items.length>0)
        list=[...list,...items];
    console.log("list of bookmarks=",list);
    if(list.length==0)
    list.push({
      name:"New",
      value:"New"
    })
    setBookmarkCategoryList(list);
  },[])

 
  

  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  

  const handleClose = () => {
    // setOpen(false);
    props.cancelHandler();
  };

  const handleSave = () => {
    
    console.log("handleSave=",title);
    if(category=="New")
        props.saveHandler(title,categoryCustom,copyKRL);
    else 
        props.saveHandler(title,category,copyKRL);
    
    setOpen(false);
   
  };

 
  const handleTitleChange=(event)=>{
    setTitle(event.target.value)
   
  }
  return (
    <div>
     
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Save Bookmark</DialogTitle>
        <DialogContent sx={{width:"440px",ml:0,mr:2 }}>
            <FormControl size="small" sx={{ width:"350px",minWidth:'80px'}}>
          <TextField
            autoFocus
            required
            variant="outlined"
            value={title}
            sx={{mt:4,minWidth:400}}
            onChange={(event) => handleTitleChange(event)}
            inputProps={{ maxLength: BOOKMARK_NAME_MAX_LENGTH }}
            // margin="dense"
            id="name"
            label="Title"
            // error={title .trim()=== ""}
            // helperText={title.trim() === "" ? 'Bookmark title is required' : ' '}
            // fullWidth
            
          /> 
        
        </FormControl>
        <FormControl required size="medium" sx={{ mt: 3, minWidth: 350 }}>
        <InputLabel id="demo-simple-select-required-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={category}
          label="Category *"
          onChange={handleChange}
        >
          {bookmarkCategoryList.map((pos,index) => (
          <MenuItem value={pos.value} key={pos.name}>{pos.name}</MenuItem>
          ))}
        </Select>
       
      </FormControl>
      {category=="New"?
      <TextField
    //   autoFocus
      required
      variant="outlined"
      value={categoryCustom}
      sx={{mt:3,minWidth:400}}
      onChange={(event) => setCategoryCustom(event.target.value)}
      inputProps={{ maxLength: BOOKMARK_CATEGORY_MAX_LENGTH }}
      // margin="dense"
      id="category"
      label="Category"
    //   error={categoryCustom.trim() === ""}
    //   helperText={categoryCustom.trim() === "" ? 'Bookmark category is required' : ' '}
      // fullWidth
      
    /> :<></>}
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
    <Tooltip title={"All Custom and Anchored KRLs that were created for this Instrument in Global scope, will be copied to this Bookmark as well. Do not select this option if you want a clean bookmark without any prior Custom KRLs."} arrow>
     
             <Checkbox 
                      checked={copyKRL}
                      onChange={()=>{setCopyKrl(!copyKRL)}}
                      // disabled={!props.proUser || props.krlData || !props.selectedInstrumentData.akrl}
                      sx={{
                        mt:2,
                        color: theme.palette.secondaryTheme.shade03,
                        '&.Mui-checked': {
                          color:theme.palette.secondaryTheme.shade03,
                        },
                      }}/>
                 
                      </Tooltip>
                      <Typography variant="linkSupportText" sx={{mt:2}} >
                       Copy existing Custom KRLs for the Instrument&nbsp;&nbsp;
                      </Typography> 
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">All Custom and Anchored KRLs that were created for this Instrument in Global scope, will be copied to this Bookmark as well. <br/>Do not select this option if you want a clean bookmark without any prior Custom KRLs.</Typography>
                          </React.Fragment>
                        }
                      >
                      <InfoIcon sx={{mt:2}}></InfoIcon>
                    </HtmlTooltip>
                      </Box>
        </DialogContent>
        <DialogActions>
       
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={title.trim()=="" || (category=="New" && categoryCustom.trim()=="")} sx={{mr:2}}onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
