import React, { useLayoutEffect, useRef } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import dark_theme from "@amcharts/amcharts5/themes/Dark";
import { Container, Box, Typography, useTheme, IconButton, Tooltip } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { WATERMARK_CONFIG } from '../../../common/utility/constant';


const StockChartContainer = (props) => {
  const seriesVWAPRef = useRef(null);
  const seriesVPOCRef = useRef(null);
  const seriesOIRef = useRef(null);
  const candlestickSeriesRef = useRef(null);
  const seriesPremiumRef = useRef(null);
  const seriesSpotRef = useRef(null);
  const seriesSyntheticPremRef = useRef(null);
  const sbSeriesRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv", {
      // useSafeResolution: false  TODO: itest incase charts break on iOS device uncomment it
    });

    root.setThemes([dark_theme.new(root)]);
    // Create a stock chart
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Instantiating_the_chart
    let stockChart = root.container.children.push(am5stock.StockChart.new(root, {
    }));

    /**
     * Main (value) panel
     */

    // Create a main stock panel (chart)
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Adding_panels
    let panelTop = stockChart.panels.push(am5stock.StockPanel.new(root, {
      wheelY: "zoomX",
      panX: true,
      panY: true,
      height: am5.percent(40),
      paddingTop: 10,
      paddingRight: 15,
      paddingBottom: 0,
      paddingLeft: 15,
      layout: root.verticalLayout
    }));
    panelTop.panelControls.closeButton.set("forceHidden", true);

    let panelMiddle = stockChart.panels.push(am5stock.StockPanel.new(root, {
      wheelY: "zoomX",
      panX: true,
      panY: true,
      height: am5.percent(30),
      paddingTop: 10,
      paddingRight: 15,
      paddingBottom: 0,
      paddingLeft: 15,
      layout: root.verticalLayout
    }));
    panelMiddle.panelControls.closeButton.set("forceHidden", true);

    let panelBottom = stockChart.panels.push(am5stock.StockPanel.new(root, {
      wheelY: "zoomX",
      panX: true,
      panY: true,
      height: am5.percent(30),
      paddingTop: 10,
      paddingRight: 15,
      paddingBottom: 0,
      paddingLeft: 15,
      layout: root.verticalLayout
    }));
    panelBottom.panelControls.closeButton.set("forceHidden", true);

    let rendrerDateTimeTop = am5xy.AxisRendererX.new(root, {
    });
    rendrerDateTimeTop.grid.template.setAll({
      location: 0,
      disabled: true,
    });
    rendrerDateTimeTop.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    });
    rendrerDateTimeTop.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99
    });

    //create date axis 
    let dateTimeAxisTop = am5xy.GaplessDateAxis.new(root, {
      baseInterval: { timeUnit: "minute", count: 1 },
      dateFormats: {
        "minute": "d MMM HH:mm",
        "hour": "d MMM HH:mm",
        "day": "d MMM"
      },
      markUnitChange: false,
      tooltipDateFormat: "HH:mm, d MMM",
      minHeight: 30,
      groupData: true,
      groupCount: 400,
      minZoomCount: 1,
      groupIntervals: [
        { timeUnit: "minute", count: 1 },
        { timeUnit: "minute", count: 5 },
        { timeUnit: "minute", count: 15 },
        { timeUnit: "minute", count: 30 },
        { timeUnit: "hour", count: 1 },
        { timeUnit: "day", count: 1 }
      ],
      renderer: rendrerDateTimeTop
    });

    //push the datetime axis in the chart
    let xAxisTop = panelTop.xAxes.push(
      dateTimeAxisTop
    );


    let rendrerDateTimeMddle = am5xy.AxisRendererX.new(root, {
    });
    rendrerDateTimeMddle.grid.template.setAll({
      location: 0,
      disabled: true,
    });
    rendrerDateTimeMddle.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    });
    rendrerDateTimeMddle.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99
    });

    //create date axis 
    let dateTimeAxisMiddle = am5xy.GaplessDateAxis.new(root, {
      baseInterval: { timeUnit: "minute", count: 1 },
      dateFormats: {
        "minute": "d MMM HH:mm",
        "hour": "d MMM HH:mm",
        "day": "d MMM"
      },
      markUnitChange: false,
      tooltipDateFormat: "HH:mm, d MMM",
      minHeight: 30,
      groupData: true,
      groupCount: 400,
      minZoomCount: 1,
      groupIntervals: [
        { timeUnit: "minute", count: 1 },
        { timeUnit: "minute", count: 5 },
        { timeUnit: "minute", count: 10 },
        { timeUnit: "minute", count: 15 },
        { timeUnit: "minute", count: 30 },
        { timeUnit: "hour", count: 1 },
        { timeUnit: "day", count: 1 }
      ],
      renderer: rendrerDateTimeMddle
    });

    //push the datetime axis in the chart
    let xAxisMiddle = panelMiddle.xAxes.push(
      dateTimeAxisMiddle
    );


    let rendrerDateTimeBottom = am5xy.AxisRendererX.new(root, {
    });
    rendrerDateTimeBottom.grid.template.setAll({
      location: 0,
      disabled: true,
    });
    rendrerDateTimeBottom.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    });
    rendrerDateTimeBottom.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99
    });

    //create date axis 
    let dateTimeAxisBottom = am5xy.GaplessDateAxis.new(root, {
      baseInterval: { timeUnit: "minute", count: 1 },
      dateFormats: {
        "minute": "d MMM HH:mm",
        "hour": "d MMM HH:mm",
        "day": "d MMM"
      },
      markUnitChange: false,
      tooltipDateFormat: "HH:mm, d MMM",
      // minHeight:30,
      groupData: true,
      groupCount: 400,
      minZoomCount: 1,
      groupIntervals: [
        { timeUnit: "minute", count: 1 },
        { timeUnit: "minute", count: 5 },
        { timeUnit: "minute", count: 10 },
        { timeUnit: "minute", count: 15 },
        { timeUnit: "minute", count: 30 },
        { timeUnit: "hour", count: 1 },
        { timeUnit: "day", count: 1 }
      ],
      renderer: rendrerDateTimeBottom
    });


    //push the datetime axis in the chart
    let xAxisBottom = panelBottom.xAxes.push(
      dateTimeAxisBottom
    );


    let valueAxisOIRenderer = am5xy.AxisRendererY.new(root, {});
    valueAxisOIRenderer.labels.template.setAll({
      paddingLeft: 2,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 2,
      verticalCenter: "bottom",
      fontSize: "0.8em",
      // minPosition: 0.01,
      maxPosition: 0.95
    });

    let valueAxisOI = panelTop.yAxes.push(
      am5xy.ValueAxis.new(root, {
        //   height:am5.percent(40),
        marginTop: 10,
        renderer: valueAxisOIRenderer
      })
    );

    valueAxisOIRenderer.grid.template.set("forceHidden", true);
    //dummy y axis required as the value axis is stack on the left and right both.
    //left axis is stacked in order they are pushed into the charts but right axis in reverse 
    let valueAxisDummyRequired = panelBottom.yAxes.push(
      am5xy.ValueAxis.new(root, {
        //   height:am5.percent(30),
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    );

    let valueAxisSpot = panelMiddle.yAxes.push(
      am5xy.ValueAxis.new(root, {
        marginTop: 10,
        // height:am5.percent(30),
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    );


    let valueAxisSpotRenderer = valueAxisSpot.get("renderer");
    valueAxisSpotRenderer.grid.template.set("forceHidden", true);
    valueAxisSpotRenderer.labels.template.setAll({
      paddingLeft: 2,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 2,
      verticalCenter: "bottom",
      fontSize: "0.8em",
      maxPosition: 0.95,
      inside: false
    });


    let valueAxisCandleVwapVpoc = panelTop.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // syncWithAxis:valueAxisOI,
        // height:am5.percent(40),
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    );

    let valueAxisCandleVwapVpocRenderer = valueAxisCandleVwapVpoc.get("renderer");
    valueAxisCandleVwapVpocRenderer.labels.template.setAll({
      paddingLeft: 2,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 2,
      verticalCenter: "bottom",
      fontSize: "0.8em",
      // minPosition: 0.01,
      maxPosition: 0.95
    });



    let seriesVWAP = panelTop.series.push(
      am5xy.LineSeries.new(root, {
        name: "VWAP",
        xAxis: xAxisTop,
        yAxis: valueAxisCandleVwapVpoc,
        valueYField: "vwap",
        valueXField: "time",
        stroke: am5.color("#128E0B"),
        fill: am5.color("#128E0B"),
        legendValueText: "{valueY}",
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesVWAP.strokes.template.setAll({
      strokeWidth: 2,
    });

    seriesVWAP.get("tooltip").label.set("text", "VWAP: {valueY}")

    let seriesVPOC = panelTop.series.push(
      am5xy.LineSeries.new(root, {
        name: "VPOC",
        xAxis: xAxisTop,
        yAxis: valueAxisCandleVwapVpoc,
        valueYField: "vpoc",
        valueXField: "time",
        stroke: am5.color("#AB039D"),
        fill: am5.color("#AB039D"),
        legendValueText: "{valueY}",
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesVPOC.strokes.template.setAll({
      strokeWidth: 2,
    });

    seriesVPOC.get("tooltip").label.set("text", "VPOC: {valueY}")

    let seriesOI = panelTop.series.push(
      am5xy.LineSeries.new(root, {
        name: "OI",
        xAxis: xAxisTop,
        // syncWithAxis:valueAxisCandleVwapVpoc,
        yAxis: valueAxisOI,
        valueYField: "oi",
        valueXField: "time",
        legendValueText: "{valueY}",
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesOI.strokes.template.setAll({
      strokeWidth: 1,
    });

    seriesOI.get("tooltip").label.set("text", "OI: {valueY}")



    let candlestickSeries = panelTop.series.push(
      am5xy.CandlestickSeries.new(root, {
        name: "Price",
        clustered: false,
        xAxis: xAxisTop,
        yAxis: valueAxisCandleVwapVpoc,
        openValueYField: "open",
        highValueYField: "high",
        lowValueYField: "low",
        valueYField: "close",
        valueXField: "time",
        legendValueText: "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
        tooltip: am5.Tooltip.new(root, {})
      })
    );


    candlestickSeries.columns.template.states.create("riseFromOpen", {
      fill: am5.color(0x76b041),
      stroke: am5.color(0x76b041)
    });
    candlestickSeries.columns.template.states.create("dropFromOpen", {
      fill: am5.color(0xe4572e),
      stroke: am5.color(0xe4572e)
    });

    candlestickSeries.get("tooltip").label.set("text", "Open: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")

    candlestickSeries.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "dd-MM-yyyy HH:mm",
      dateFields: ["time"]
    });

    seriesVWAP.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "dd-MM-yyyy HH:mm",
      dateFields: ["time"]
    });
    seriesVPOC.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "dd-MM-yyyy HH:mm",
      dateFields: ["time"]
    });
    seriesOI.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "dd-MM-yyyy HH:mm",
      dateFields: ["time"]
    });

    candlestickSeries.get("tooltip").label.set("text", "[{column.fill}]Open: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")
    stockChart.set("stockSeries", candlestickSeries);
    var valueLegend = panelTop.topPlotContainer.children.push(am5stock.StockLegend.new(root, {
      stockChart: stockChart
    }));
    valueLegend.data.setAll([candlestickSeries, seriesOI, seriesVPOC, seriesVWAP]);

    var seriesSpot = panelMiddle.series.push(
      am5xy.LineSeries.new(root, {
        name: "Spot",
        xAxis: xAxisBottom,
        yAxis: valueAxisSpot,
        valueYField: "undy_close",
        valueXField: "time",
        legendValueText: "{valueY}",
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesSpot.strokes.template.setAll({
      strokeWidth: 2,
    });

    seriesSpot.get("tooltip").label.set("text", "Spot: {valueY}")

    seriesSpot.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "dd-MM-yyyy HH:mm",
      dateFields: ["time"]
    });

    let valueAxisPremium = panelMiddle.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // height:am5.percent(30),
        renderer: am5xy.AxisRendererY.new(root, {
        }),
      })
    );

    let valueAxisPremiumRenderer = valueAxisPremium.get("renderer");
    valueAxisPremiumRenderer.labels.template.setAll({
      paddingLeft: 2,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 2,
      verticalCenter: "bottom",
      fontSize: "0.8em",
      maxPosition: 0.95,
      inside: false
    });

    var seriesPremium = panelMiddle.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Premium",
        xAxis: xAxisBottom,
        yAxis: valueAxisPremium,
        valueYField: "undy_prem",
        valueXField: "time",
        fillOpacity: 0.3,
        legendValueText: "{valueY}",
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesPremium.columns.template.setAll({
      fillOpacity: 0.5,
      strokeWidth: 2,
      width: am5.percent(70)
    });
    seriesPremium.get("tooltip").label.set("text", "Fut Prem: {valueY}")
    seriesPremium.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "dd-MM-yyyy HH:mm",
      dateFields: ["time"]
    });

    var valueLegendMiddle = panelMiddle.topPlotContainer.children.push(am5stock.StockLegend.new(root, {
      stockChart: stockChart
    }));
    valueLegendMiddle.data.setAll([seriesPremium, seriesSpot]);

    let valueAxisSyntheticPremium = panelBottom.yAxes.push(
      am5xy.ValueAxis.new(root, {
        marginTop: 10,
        // height:am5.percent(30),
        renderer: am5xy.AxisRendererY.new(root, {
        }),
      })
    );

    let valueAxisSyntheticPremiumRenderer = valueAxisSyntheticPremium.get("renderer");
    valueAxisSyntheticPremiumRenderer.labels.template.setAll({
      paddingLeft: 2,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 2,
      verticalCenter: "bottom",
      fontSize: "0.8em",
      maxPosition: 0.95
    });


    var seriesSyntheticPrem = panelBottom.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Synthetic Premium Fut",
        xAxis: xAxisBottom,
        yAxis: valueAxisSyntheticPremium,
        valueYField: "synthetic_fut_diff",
        valueXField: "time",
        fillOpacity: 0.4,
        legendValueText: "{valueY}",
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesSyntheticPrem.get("tooltip").label.set("text", "Synthetic Prem: {valueY}")

    seriesSyntheticPrem.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "dd-MM-yyyy HH:mm",
      dateFields: ["time"]
    });

    var valueLegendBottom = panelBottom.topPlotContainer.children.push(am5stock.StockLegend.new(root, {
      stockChart: stockChart
    }));
    valueLegendBottom.data.setAll([seriesSyntheticPrem]);

    // Stack Y-axes
    //   chart.leftAxesContainer.set("layout", root.verticalLayout);
    //   chart.rightAxesContainer.set("layout", root.verticalLayout);

    //create chart legend at the bottom of each panel
    //   let legendTop = panelTop.children.push(am5.Legend.new(root, {
    //     centerX: am5.percent(50),
    //     x: am5.percent(50)
    //   }));
    //   legendTop.data.setAll(panelTop.series.values);

    // let legendMiddle = panelMiddle.children.push(am5.Legend.new(root, {
    //   centerX: am5.percent(50),
    //   x: am5.percent(50)
    // }));
    // legendMiddle.data.setAll(panelMiddle.series.values);

    // let legendBottom = panelBottom.children.push(am5.Legend.new(root, {
    //   centerX: am5.percent(50),
    //   x: am5.percent(50)
    // }));
    // legendBottom.data.setAll(panelBottom.series.values);


    // //create chart title
    // stockChart.panelsContainer.children.unshift(am5.Label.new(root, {
    //   text: "NIFTY Futures Line Chart",
    //   fontSize: 24,
    //   fontWeight: "500",
    //   textAlign: "center",
    //   x: am5.percent(50),
    //   centerX: am5.percent(50),
    //   paddingTop: 0,
    //   paddingBottom: 4
    // }));

    // Add cursor for date time x axis
    panelTop.set("cursor", am5xy.XYCursor.new(root, {
      // behavior: "zoomXY",
      xAxis: xAxisTop
    }));

    // Add cursor for date time x axis
    panelMiddle.set("cursor", am5xy.XYCursor.new(root, {
      // behavior: "zoomXY",
      xAxis: xAxisMiddle
    }));

    // Add cursor for date time x axis
    panelBottom.set("cursor", am5xy.XYCursor.new(root, {
      // behavior: "zoomXY",
      xAxis: xAxisBottom
    }));

    //add tooltip for date time x axis
    xAxisTop.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    //add tooltip for date time x axis
    //  xAxisMiddle.set("tooltip", am5.Tooltip.new(root, {
    //     themeTags: ["axis"]     
    //     }));

    //add tooltip for date time x axis
    xAxisBottom.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    //add tooltip for date time x axis
    let tooltipOI = valueAxisOI.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"],

    }));
    tooltipOI.get("background").setAll({
      fill: am5.color("#00f"),
      fillOpacity: 0.8
    });

    //add tooltip for date time x axis
    let tooltipVwap = valueAxisCandleVwapVpoc.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"],
    }));
    tooltipVwap.get("background").setAll({
      fill: am5.color("#0f0"),
      fillOpacity: 0.8
    });


    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    let scrollbar = panelTop.set("scrollbarX", am5xy.XYChartScrollbar.new(root, {
      orientation: "horizontal",
      height: 50
    }));
    stockChart.toolsContainer.children.push(scrollbar);

    let sbRendrerDateTime = am5xy.AxisRendererX.new(root, {
    });
    sbRendrerDateTime.grid.template.setAll({
      location: 0,
      disabled: true,
    });
    sbRendrerDateTime.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    });
    sbRendrerDateTime.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99
    });

    //create date axis 
    let sbDateTimeAxis = am5xy.GaplessDateAxis.new(root, {
      baseInterval: { timeUnit: "minute", count: 1 },
      dateFormats: {
        "minute": "d MMM HH:mm",
        "hour": "d MMM HH:mm",
        "day": "d MMM"
      },
      markUnitChange: false,
      tooltipDateFormat: "HH:mm, d MMM",
      // minHeight:30,
      groupData: true,
      groupCount: 400,
      minZoomCount: 1,
      groupIntervals: [
        // { timeUnit: "minute", count: 1 },
        { timeUnit: "minute", count: 5 },
        { timeUnit: "minute", count: 10 },
        { timeUnit: "minute", count: 15 },
        { timeUnit: "minute", count: 30 },
        { timeUnit: "hour", count: 1 },
        { timeUnit: "day", count: 1 }
      ],
      renderer: sbRendrerDateTime
    });

    //push the datetime axis in the chart
    let sbDateAxis = scrollbar.chart.xAxes.push(
      sbDateTimeAxis
    );


    let sbValueAxis = scrollbar.chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    let sbSeries = scrollbar.chart.series.push(am5xy.LineSeries.new(root, {
      valueYField: "close",
      valueXField: "time",
      xAxis: sbDateAxis,
      yAxis: sbValueAxis
    }));

    sbSeries.fills.template.setAll({
      visible: true,
      fillOpacity: 0.3
    });


    // add Bollinger Bands indicator
    // let bollingerBands = stockChart.indicators.push(am5stock.BollingerBands.new(root, {
    //     stockChart: stockChart,
    //     stockSeries: candlestickSeries,
    //     legend: valueLegend
    // }));

    //https://www.amcharts.com/docs/v5/charts/stock/toolbar/
    let toolbar = am5stock.StockToolbar.new(root, {
      container: document.getElementById("chartcontrols"),
      stockChart: stockChart,
      controls: [
        am5stock.IndicatorControl.new(root, {
          stockChart: stockChart,
          legend: valueLegend
        }),
        am5stock.DateRangeSelector.new(root, {
          stockChart: stockChart
        }),
        am5stock.PeriodSelector.new(root, {
          stockChart: stockChart
        }),

        am5stock.DrawingControl.new(root, {
          stockChart: stockChart
        }),
        am5stock.ResetControl.new(root, {
          stockChart: stockChart
        }),
        am5stock.SettingsControl.new(root, {
          stockChart: stockChart
        })
      ]
    })

    seriesVWAPRef.current = seriesVWAP;
    seriesVPOCRef.current = seriesVPOC;
    seriesOIRef.current = seriesOI;
    candlestickSeriesRef.current = candlestickSeries;
    seriesPremiumRef.current = seriesPremium;
    seriesSpotRef.current = seriesSpot;
    seriesSyntheticPremRef.current = seriesSyntheticPrem;
    sbSeriesRef.current = sbSeries;

    return () => {
      root.dispose();
    };

  }, []);


  //this will handle the props data change and set/update the chart
  useLayoutEffect(() => {
    // console.log("uselayout effect child ===========>>>>>>>>>>>>>>>");
    //1st load
    if (props.updateData == undefined) {
      console.log("If uselayout effect child ===========>>>>>>>>>>>>>>>", props.updateData);
      seriesVWAPRef.current.data.setAll(props.chartData);
      seriesVPOCRef.current.data.setAll(props.chartData);
      seriesOIRef.current.data.setAll(props.chartData);
      candlestickSeriesRef.current.data.setAll(props.chartData);
      seriesPremiumRef.current.data.setAll(props.chartData);
      seriesSpotRef.current.data.setAll(props.chartData);
      seriesSyntheticPremRef.current.data.setAll(props.chartData);
      sbSeriesRef.current.data.setAll(props.chartData);
    } else {
      console.log("Else uselayout effect child ===========>>>>>>>>>>>>>>>", props.updateData);
      seriesVWAPRef.current.data.pushAll(props.updateData);
      seriesVPOCRef.current.data.pushAll(props.updateData);
      seriesOIRef.current.data.pushAll(props.updateData);
      candlestickSeriesRef.current.data.pushAll(props.updateData);
      seriesPremiumRef.current.data.pushAll(props.updateData);
      seriesSpotRef.current.data.pushAll(props.updateData);
      seriesSyntheticPremRef.current.data.pushAll(props.updateData);
      sbSeriesRef.current.data.pushAll(props.updateData);
    }

  }, [props.chartData, props.updateData]);

  function toggleFullScreen() {
    let chartdiv = document.querySelector('#chartdiv')
    if (!document.fullscreenElement) {
      chartdiv.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  return (
    <>
      <div id="container" style={{ backgroundColor: "#111111", zIndex: "99", marginTop: "0px", padding: "20px", width: "100%", minHeight: "calc(110vh - 256px)" }}>
        {/* <LineChartHeader/> */}
        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: "row", justifyContent: "space-evenly" }}>
          {/* <Tooltip title="Go Back">
            <IconButton
              onClick={() => navigate(-1)}
              size='large'>
              <ArrowBackIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
          <Container sx={{ width: "80%" }}>
            <Typography variant='h4' color='primary' sx={{ alignSelf: "center" }}>NIFTY Futures Line Chart</Typography>
          </Container>
          <Tooltip title="Full Screen">
            <IconButton
              onClick={() => toggleFullScreen()}
              size='large'>
              <FullscreenIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip>
        </Box>
        <div id="chartContainer">
          <div id="chartcontrols" style={{ padding: "10px", marginTop: "16px", maxWidth: "100%" }}></div>
          <div id="chartdiv" style={{ width: "100%", maxWidth: "100%", height: "900px" }} />
        </div>
      </div>
    </>
  )
}

export default StockChartContainer
