import React, { useState,useEffect } from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from '@mui/material';
import {TEXT_MSGS,PREMIUM_UPGRADE_MSGS_LIST, USER_ROLE_FIELDS} from '../../../common/utility/constant';
import { useNavigate } from "react-router-dom";
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import fyers_logo from '../../../assets/images/fyers-logo.svg';
import zerodha_logo from '../../../assets/images/zerodha-logo.svg';

// ?broker_name=zerodha


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BrokerPopup(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(props.open);
  const isPRO_2=(props.role.find(el=>el==USER_ROLE_FIELDS.PRO2))?true:false;

 
 const [cancel,responseData, error, loaded, reset,executeAPI ] = useAxiosWithAuth();
//   const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    props.onClose();
}

React.useEffect(()=>{
  
},[])

const brokerLogin=(name)=>{
  // props.onClose();
  // navigate('/subscription')
  executeAPI(URL.BORKER_LOGIN+"?broker_name="+name,"GET",{})
 
}

useEffect(() => {
  if(loaded){
    if(responseData!=null && responseData.success){
      window.location.href =responseData.redirect_url;
    }
    else if(error!==null){
      // setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      //if unauthorized then redirec it to login page
      if(error?.response?.status === 401 || error?.response?.status === 403){
        console.log("status received =",error?.response?.status)
        // navigate(from, { replace: true });
      }
  }
  reset();
  }
},[loaded,responseData]);


    

  return (
    
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Login with your broker account (work-in-progress)
          </Typography>
          <Typography id="modal-modal-title" component="h4">
            This will enable options for you to place buy/sell orders while staying on charts page.
          </Typography>

          <Box sx={{mt:4}}>
          {true?
          <Typography variant="linkSupportText" sx={{mt:4}}>
              {/* <Link onClick={()=>openSubscription()} sx={{fontSize:14, pt:1,cursor:"pointer"}} variant="h4">Upgrade Now</Link> */}
              <Button variant="containedWhite" onClick={()=>brokerLogin("zerodha")} sx={{mr:2, bgcolor:"#fff"}}>
              {/* ZERODHA */}
              <img src={zerodha_logo} alt="Zerodha Logo" width={120} height={20}/>
              </Button>
          </Typography>
          :<></>}
          <Typography variant="linkSupportText" sx={{mt:2}}>
              {/* <Link onClick={()=>openSubscription()} sx={{fontSize:14, pt:1,cursor:"pointer"}} variant="h4">Upgrade Now</Link> */}
              <Button variant="containedWhite" onClick={()=>brokerLogin("fyers")} sx={{mr:2, bgcolor:"#fff"}}>
              {/* FYERS <Box display="flex" sx={{height:"100%"}} justifyContent="center" alignItems="center"> */}
                    <img src={fyers_logo} alt="Fyers Logo" width={120} height={20}/>
                  {/* </Box> */}
              </Button>
          </Typography>
          </Box>
        </Box>
      </Modal>

  );
}