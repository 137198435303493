import React, { useLayoutEffect, useRef, useState,useEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import dark_theme from "@amcharts/amcharts5/themes/Dark";
import { Container, Box, Typography, useTheme, IconButton, Tooltip,Select,FormControl,Menu,MenuItem ,Slider,InputLabel,Button} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { WATERMARK_CONFIG,DateFormat,USER_ROLE_FIELDS,MenuProps,BAR_CHART_ADMIN_PLOT_TYPE, BAR_CHART_OI_PLOT_TYPE, BAR_CHART_MI_PLOT_TYPE,MIN_BAR_CHART_WIDTH } from '../../../common/utility/constant';
import dayjs from 'dayjs';
import { DatePicker} from 'antd';
import useAuth from '../../../common/hooks/useAuth';


const OptChainChartContainer = (props) => {
  const seriesGexRef = useRef(null);
  const seriesGexMaxRef = useRef(null);
  const seriesGexMinRef = useRef(null);
  const seriesGexCeRef = useRef(null);
  const seriesCeMaxRef = useRef(null);
  const seriesCeMinRef = useRef(null);
  const seriesPeMaxRef = useRef(null);
  const seriesPeMinRef = useRef(null);
  const seriesGexPeRef = useRef(null);
  const seriesDGexRef = useRef(null);
  const seriesGcashRef = useRef(null);
  const seriesWcashRef = useRef(null);
  const seriesOIRef = useRef(null);
  const seriesVolRef = useRef(null);
  const seriesDoiRef = useRef(null);
  const seriesIvRef = useRef(null);
  const seriesIvcRef = useRef(null);
  const seriesIvpRef = useRef(null);

  // const spotCloseRef = useRef(null);
  // const spotHighRef = useRef(null);
  // const spotLowRef = useRef(null);

  const range1Ref = useRef(null);
  const range2Ref = useRef(null);
  const range2DataItemRef = useRef(null);

  // console.log("props", props);

  const { auth } = useAuth();
  const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
  const [isFastDataUser,setIsFastDataUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?true:false)
  const [isProUser,setIsProUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))?true:false)
  const [isPro2User,setIsPro2User]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2))?true:false)
  const [isAdminUser,setIsAdminUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN))?true:false)
  const[nDays,setnDays]=useState(props.nDays);
  const [startDate,setStartDate]=useState(props.startDate!=""? dayjs(props.startDate,DateFormat):"");
  const[plotType,setPlotType]=useState(props.plotType);
  const[menuType,setMenuType]=useState(props.menuType);
  const [expiry,setExpiry]=useState(props.expiry?props.expiry:props.expiryList?props.expiryList[0]:"");
  const [dateParams,setDateParams]=useState(props.startDate?props.startDate:props.dateList?props.dateList[0]:"");
  const [timeMinValue,setTimeMinValue]=useState(0)
  const [timeInterval,setTimeInterval]=useState(props.timeInterval);
  const [targetTime,setTargetTime]=useState(props.targetTime)

  // console.log("+++++++", expiry, props)

  // const [spotClose,setSpotClose]=useState(props.metadata.spot)
  // const [spotHigh,setSpotHigh]=useState(props.metadata.spot_high)
  // const [spotLow,setSpotLow]=useState(props.metadata.spot_low)

  let spotClose =  props.metadata?.spot;
  let spotHigh =  props.metadata?.spot_high;
  let spotLow =  props.metadata?.spot_low;

  const d_list = ['_gcash', '_dgcash', '_igex', '_ngex', '_wcash', '_oi', '_doi', '_vol']
  const dlist = ['net_gcash', 'net_dgcash', 'total_gcash', 'total_dgcash', 'net_igex', 'net_ngex', 'total_igex', 'total_ngex', 'pcr', 'net_oi', 'total_oi', 'straddle', 'oi_pain']

  // console.log("-------menuType", menuType)
  let plot = props.plotParams.get("plot");
  let plot_name = plot;
  let green_color = "#01DF01";
  let red_color = "#FF0000";
  if(plot == "net_gcash" || plot == "total_gcash" || plot == "_gcash"){
    plot_name = plot.split("_")[0].charAt(0).toUpperCase() + plot.split("_")[0].slice(1) + " Gamma Exposure Value";
    green_color = "#6DD9E5";
    red_color = "#CC80F9";
    if(plot == "net_gcash"){
      green_color = "#CC80F9";
    }
  }
  else if(plot == "net_igex" || plot == "total_igex" || plot == "_igex"){
    plot_name = plot.split("_")[0].charAt(0).toUpperCase() + plot.split("_")[0].slice(1) + " Gamma Value";
    green_color = "#6DD9E5";
    red_color = "#CC80F9";
    if(plot == "net_gcash"){
      green_color = "#CC80F9";
    }
  }
  else if(plot == "net_dgcash" || plot == "total_dgcash" || plot == "_dgcash"){
    plot_name = plot.split("_")[0].charAt(0).toUpperCase() + plot.split("_")[0].slice(1) + " Inventory Resistance Estimate";
    green_color = "#6DD9E5";
    red_color = "#CC80F9";
    if(plot == "net_dgcash"){
      green_color = "#CC80F9";
    }
  }
  else if(plot == "net_ngex" || plot == "total_ngex" || plot == "_ngex"){
    plot_name = plot.split("_")[0].charAt(0).toUpperCase() + plot.split("_")[0].slice(1) + " Inventory Value";
    green_color = "#6DD9E5";
    red_color = "#CC80F9";
    if(plot == "net_dgcash"){
      green_color = "#CC80F9";
    }
  }
  else if(plot == "_wcash")
    plot_name = "Writers' Unrealized Cash"
  // console.log(props)

  // const theme = useTheme();
  const navigate = useNavigate();

  // console.log("CONTAINER BEGINS")
  // console.log(props)

 
useEffect(()=>{
  console.log("Bar chart: target time useEffect= ",props.targetTime);
  if(props.targetTime!=undefined)
  setTargetTime(props.targetTime)
},[props.targetTime])


  useLayoutEffect(() => {
    if(props.isError)
    return;
    
    let root = am5.Root.new(props.id, {
      // useSafeResolution: false  TODO: itest incase charts break on iOS device uncomment it
    });

    root.setThemes([dark_theme.new(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        // wheelX: "zoomX",
        wheelZoomPositionX: 1,
        pinchZoomX: false,
        pinchZoomY: false,
        paddingTop: 20,
        paddingRight: 15,
        paddingBottom: 30,
        paddingLeft: 15,
        layout: root.verticalLayout
      })
    );

    //Refere for zoom and pan https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/

    // uncomment the below line is to hide the chart zoom button
    // chart.zoomOutButton.set("forceHidden", true); 

    // add scrollbar to control
    // chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //   orientation: "horizontal"
    // }));

    let rendrerStrikes = am5xy.AxisRendererX.new(root, {
    });
    rendrerStrikes.grid.template.setAll({
      location: 0,
      disabled: false,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9
    });
    rendrerStrikes.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    });
    rendrerStrikes.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
    });

    //create strike axis 
    let strikesAxis = am5xy.ValueAxis.new(root, {
      height: am5.percent(100),
      renderer: rendrerStrikes
    });

    // let strikesAxis = am5xy.CategoryAxis.new(root, {
    //   categoryField: "strike",
    //   // height: am5.percent(100),
    //   renderer: am5xy.AxisRendererX.new(root, {})
    // });

    let xAxis = chart.xAxes.push(
      strikesAxis
    );

    // let xAxis = chart.xAxes.push(
    //   am5xy.CategoryAxis.new(root, {
    //     categoryField: "strike",
    //     renderer: am5xy.AxisRendererX.new(root, {})
    //   })
    // );

    let valueAxisLhs1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        height: am5.percent(100),
        renderer: am5xy.AxisRendererY.new(root, {
        }),
      })
    );

    let valueAxisRhs1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // syncWithAxis: valueAxisLhs1,
        height: am5.percent(100),
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    );
    
    let seriesGex, seriesGexMax, seriesGexMin;
    if(dlist.includes(plot)) {
      seriesGex = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: plot_name,
          xAxis: xAxis,
          centerX: 10,
          // syncWithAxis: valueAxisRhs1,
          yAxis: valueAxisRhs1,
          valueYField: plot,
          valueXField: "strike",
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesGex.columns.template.setAll({
        fillOpacity: 0.9,
        strokeWidth: 0,
        strokeOpacity: 0,
        minWidth: 20,
        maxWidth: 20,
        width: am5.percent(100)
      });

      seriesGex.columns.template.adapters.add("fill", function(fill, target) {
        if (target.dataItem && target.dataItem.get("valueY") > 0) {
            return am5.color(green_color);  // green
        }
        else {
            return am5.color(red_color);  // red
        }
      });

      seriesGex.get("tooltip").label.set("text", plot_name + ": {valueY}")

      seriesGexMax = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: plot_name + " Max (Intra)",
          xAxis: xAxis,
          centerX: 15,
          // syncWithAxis: valueAxisRhs1,
          yAxis: valueAxisRhs1,
          valueYField: plot + "_max",
          openValueYField: plot + "_min",
          valueXField: "strike",
          stroke: am5.color("#FFFFFF"),
          fill: am5.color("#B9F6FE"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesGexMax.columns.template.setAll({
        fillOpacity: 0.3,
        strokeWidth: 0.5,
        strokeOpacity: 0.8,
        minWidth: 30,
        maxWidth: 30,
        width: am5.percent(100)
      });

      // seriesGexMax.get("tooltip").label.set("text", plot_name + " Max: {valueY}")

      seriesGexMin = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: plot_name + " Min (Intra)",
          xAxis: xAxis,
          // centerX: 10,
          // syncWithAxis: valueAxisRhs1,
          yAxis: valueAxisRhs1,
          valueYField: plot + "_min",
          valueXField: "strike",
          // stroke: am5.color("#CE40FB"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesGexMin.columns.template.setAll({
        fillOpacity: 0,
        strokeWidth: 0,
        strokeOpacity: 0,
        minWidth: 30,
        maxWidth: 30,
        width: am5.percent(100)
      });

      // seriesGexMin.get("tooltip").label.set("text", plot_name + " Min: {valueY}")
    }

    let seriesGexCe, seriesGexPe;
    let seriesCeMin, seriesPeMin, seriesCeMax, seriesPeMax;
    if(d_list.includes(plot)) {
    // if(props.plotParams.get("plot") === "_gex") {
      seriesGexCe = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "CE " + plot_name,
          xAxis: xAxis,
          // syncWithAxis: valueAxisRhs1,
          yAxis: valueAxisRhs1,
          valueYField: "ce" + plot,
          valueXField: "strike",
          clustered: true,
          // stacked: true,
          // height: am5.percent(80),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesGexCe.columns.template.setAll({
        fillOpacity: 0.6,
        strokeWidth: 0,
        strokeOpacity: 0,
        minWidth: 10,
        // maxWidth: 40,
        // width: am5.percent(50),
        // height: am5.percent(80),
        stroke: am5.color(green_color),
        fill: am5.color(green_color),
      });

      seriesGexCe.get("tooltip").label.set("text", "ce: {valueY}")

      seriesGexPe = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "PE " + plot_name,
          xAxis: xAxis,
          centerX: 15,
          // syncWithAxis: valueAxisRhs1,
          yAxis: valueAxisRhs1,
          valueYField: "pe" + plot,
          valueXField: "strike",
          clustered: true,
          // stacked: true,
          // height: am5.percent(80),
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesGexPe.columns.template.setAll({
        fillOpacity: 0.6,
        strokeWidth: 0,
        strokeOpacity: 0,
        minWidth: 10,
        // maxWidth: 40,
        // width: am5.percent(50),
        // height: am5.percent(80),
        stroke: am5.color(red_color),
        fill: am5.color(red_color),
      });

      seriesGexPe.get("tooltip").label.set("text", "pe: {valueY}")
      
      if(plot == "_oi") {
        seriesCeMax = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "CE OI Max (Intra)",
            xAxis: xAxis,
            centerX: 0,
            // syncWithAxis: valueAxisRhs1,
            yAxis: valueAxisRhs1,
            valueYField:  "ce_oi_max",
            openValueYField: "ce_oi_min",
            valueXField: "strike",
            stroke: am5.color("#FFFFFF"),
            fill: am5.color("#B9F6FE"),
            tooltip: am5.Tooltip.new(root, {})
          })
        );
  
        seriesCeMax.columns.template.setAll({
          fillOpacity: 0.2,
          strokeWidth: 0.5,
          strokeOpacity: 0.8,
          minWidth: 10,
          maxWidth: 10,
          width: am5.percent(100)
        });
  
        seriesCeMax.get("tooltip").label.set("text", "ce Max: {valueY}")
  
        seriesCeMin = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "CE OI Min (Intra)",
            xAxis: xAxis,
            // centerX: 10,
            // syncWithAxis: valueAxisRhs1,
            yAxis: valueAxisRhs1,
            valueYField: "ce_oi_min",
            valueXField: "strike",
            // stroke: am5.color("#CE40FB"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        );
  
        seriesCeMin.columns.template.setAll({
          fillOpacity: 0,
          strokeWidth: 0,
          strokeOpacity: 0,
          minWidth: 10,
          maxWidth: 10,
          width: am5.percent(100)
        });

        seriesPeMax = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "PE OI Max (Intra)",
            xAxis: xAxis,
            centerX: 15,
            // syncWithAxis: valueAxisRhs1,
            yAxis: valueAxisRhs1,
            valueYField:  "pe_oi_max",
            openValueYField: "pe_oi_min",
            valueXField: "strike",
            stroke: am5.color("#FFFFFF"),
            fill: am5.color("#B9F6FE"),
            tooltip: am5.Tooltip.new(root, {})
          })
        );
  
        seriesPeMax.columns.template.setAll({
          fillOpacity: 0.2,
          strokeWidth: 0.5,
          strokeOpacity: 0.8,
          minWidth: 10,
          maxWidth: 10,
          width: am5.percent(100)
        });
  
        seriesPeMax.get("tooltip").label.set("text", "pe Max: {valueY}")
  
        seriesPeMin = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "PE OI Min (Intra)",
            xAxis: xAxis,
            // centerX: 10,
            // syncWithAxis: valueAxisRhs1,
            yAxis: valueAxisRhs1,
            valueYField: "pe_oi_min",
            valueXField: "strike",
            // stroke: am5.color("#CE40FB"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        );
  
        seriesPeMin.columns.template.setAll({
          fillOpacity: 0,
          strokeWidth: 0,
          strokeOpacity: 0,
          minWidth: 10,
          maxWidth: 10,
          width: am5.percent(100)
        });
      }
    }

    let seriesIv, seriesIvp, seriesIvc;
    if(props.plotParams.get("plot") === "iv") {
      seriesIv = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "IV",
          xAxis: xAxis,
          zIndex: 3,
          // syncWithAxis: valueAxisRhs1,
          yAxis: valueAxisRhs1,
          stroke: am5.color("#FF9933"),
          // fill: am5.color("#FF9933"),
          valueYField: "iv",
          valueXField: "strike",
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesIv.strokes.template.setAll({
        strokeWidth: 2,
      });
      // seriesIv.get("tooltip").label.set("text", "IV: {valueY}")

      seriesIvp = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Puts IV",
          xAxis: xAxis,
          zIndex: 3,
          // syncWithAxis: valueAxisRhs1,
          yAxis: valueAxisRhs1,
          stroke: am5.color("#FF0000"),
          // fill: am5.color("#FF0000"),
          valueYField: "pe_iv",
          valueXField: "strike",
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesIvp.strokes.template.setAll({
        strokeWidth: 2,
      });

      seriesIvc = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Calls IV",
          xAxis: xAxis,
          zIndex: 3,
          // syncWithAxis: valueAxisRhs1,
          yAxis: valueAxisRhs1,
          stroke: am5.color("#01DF01"),
          // fill: am5.color("#01DF01"),
          valueYField: "ce_iv",
          valueXField: "strike",
          // tooltip: am5.Tooltip.new(root, {})
        })
      );

      seriesIvc.strokes.template.setAll({
        strokeWidth: 2,
      });
    }

    console.log("spot: ", props.metadata.spot)
    
    let range1DataItem = xAxis.makeDataItem({
      value: spotLow,
      endValue: spotHigh,
    });

    let range1 = xAxis.createAxisRange(range1DataItem);

    // range1.get("grid").setAll({
    //   stroke: am5.color("#FFDD33"),
    //   strokeOpacity: 1,
    // });

    range1DataItem.get("axisFill").setAll({
      fill: am5.color("#FFDD33"),
      fillOpacity: 0.2,
      visible: true
    });

    let range2DataItem = xAxis.makeDataItem({
      value: spotClose,
    });

    let range2 = xAxis.createAxisRange(range2DataItem);

    range2.get("grid").setAll({
      stroke: am5.color("#FFFFFF"),
      strokeOpacity: 1,
      strokeWidth: 2,
    });

    range2DataItem.get("label").setAll({
      fill: am5.color("#ECF539"),
      text: "Spot: " + spotClose,
      inside: true,
      dy: -700,
      dx: 15,
      // background: am5.RoundedRectangle.new(root, {
      //   fill: am5.color("#FFFFFF"),
      // })
    });

    console.log("range2DataItem", range2DataItem);

    // Stack Y-axes
    chart.leftAxesContainer.set("layout", root.verticalLayout);
    chart.rightAxesContainer.set("layout", root.verticalLayout);

    seriesGexRef.current = seriesGex;
    seriesGexMaxRef.current = seriesGexMax;
    seriesGexMinRef.current = seriesGexMin;
    seriesGexCeRef.current = seriesGexCe;
    seriesCeMaxRef.current = seriesCeMax;
    seriesCeMinRef.current = seriesCeMin;
    seriesPeMaxRef.current = seriesPeMax;
    seriesPeMinRef.current = seriesPeMin;
    seriesGexPeRef.current = seriesGexPe;
    seriesIvRef.current = seriesIv;
    seriesIvcRef.current = seriesIvc;
    seriesIvpRef.current = seriesIvp;

    // spotCloseRef.current = spotClose;
    // spotHighRef.current = spotHigh;
    // spotLowRef.current = spotLow;

    range1Ref.current = range1;
    range2Ref.current = range2;
    range2DataItemRef.current = range2DataItem;

    //create chart legend
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      marginTop:20
    }));
    legend.data.setAll(chart.series.values);

    // //create chart title
    // chart.children.unshift(am5.Label.new(root, {
    //   text: "NIFTY Futures Line Chart",
    //   fontSize: 24,
    //   fontWeight: "500",
    //   textAlign: "center",
    //   x: am5.percent(50),
    //   centerX: am5.percent(50),
    //   paddingTop: 0,
    //   paddingBottom: 4
    // }));

    //add watermark text
    chart.plotContainer.children.push(am5.Label.new(root, {
      text: WATERMARK_CONFIG.LC_TEXT,
      y: am5.percent(100),
      centerY: am5.percent(100),
      opacity: 0.3,
      marginLeft: 10,
      marginBottom: 10,
      fontSize: 24,
      fontWeight: "400",
    }));

    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#000000"),
      strokeOpacity: 0.5,
      fill: am5.color("#000000"),
      fillOpacity: 0.7
    });

    // Add cursor for x axis
    chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomXY",
      xAxis: xAxis
    }));

    //add tooltip for x axis
    xAxis.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    strikesAxis.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    valueAxisRhs1.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    return () => {
      root.dispose();
    };

  }, [props.chartData]);

  //this will handle the props data change and set/update the chart
  useLayoutEffect(() => {
    // console.log("uselayout effect child ===========>>>>>>>>>>>>>>>");
    //1st load
    if(props.isError)
    return;
    if (props.chartData != undefined) {
      console.log("initial chart load");
      // console.log(range1Ref.current);
      if(props.metadata != undefined && range1Ref.current != undefined){
        // spotCloseRef.current = props.metadata.spot;
        // spotHighRef.current = props.metadata.spot_high;
        // spotLowRef.current = props.metadata.spot_low;

        range1Ref.current._settings.value = props.metadata.spot_low;
        range1Ref.current._settings.endValue = props.metadata.spot_high;

        range2Ref.current._settings.value = props.metadata.spot;

        range2DataItemRef.current.get("label").setAll({
          text: "Spot: " + props.metadata.spot,
        });

        // console.log(range1Ref.current);
      }
      if(dlist.includes(props.plotParams.get("plot")) && seriesGexRef.current != undefined && seriesGexRef.current.data != undefined) {
        seriesGexRef.current.data.setAll(props.chartData);
        // console.log("target Time", targetTime);
        seriesGexMaxRef.current.data.setAll(props.chartData);
        seriesGexMinRef.current.data.setAll(props.chartData);
      } else if(d_list.includes(props.plotParams.get("plot")) && seriesGexCeRef.current != undefined && seriesGexCeRef.current.data != undefined) {
        seriesGexCeRef.current.data.setAll(props.chartData);
        seriesGexPeRef.current.data.setAll(props.chartData);
        if(props.plotParams.get("plot") == "_oi" && seriesCeMaxRef.current?.data != undefined && seriesPeMaxRef.current?.data != undefined) {
          seriesCeMaxRef.current.data.setAll(props.chartData);
          seriesCeMinRef.current.data.setAll(props.chartData);
          seriesPeMaxRef.current.data.setAll(props.chartData);
          seriesPeMinRef.current.data.setAll(props.chartData);
        }
      } else if(props.plotParams.get("plot") == "iv" && seriesIvRef.current != undefined && seriesIvRef.current.data != undefined) {
        seriesIvRef.current.data.setAll(props.chartData);
        seriesIvpRef.current.data.setAll(props.chartData);
        seriesIvcRef.current.data.setAll(props.chartData);
      }
    }

  }, [props.plotParams, props.chartData, props.metadata]);


  function toggleFullScreen() {
    let chartdiv = document.querySelector(`#${props.id}`)
    if (!document.fullscreenElement) {
      chartdiv.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  const disabledDate=(current)=> {
    return (current && current >= dayjs().endOf('day'))||dayjs(current).day()==6 || dayjs(current).day()==0
  }
  const onDateChange = (date, dateString) => {
    setStartDate(dayjs(date))
    console.log("Date changed",dayjs(date),dateString);
    props.handleStartDateChange(dateString);
  }

  //for dropdown control
  const handleDateChange=(event)=>{
    setStartDate(event.target.value);
    setDateParams(event.target.value);
    //console.log("Date changed",dayjs(date),event.target.value);
    props.handleStartDateChange(event.target.value);
  }

  const handlePlotTypeChange=(event)=>{
    setPlotType(event.target.value);
    props.changePlotType(event.target.value);
  }
  
  const handleExpiryChange=(event)=>{
    setExpiry(event.target.value);
    props.handleExpiryChange(event.target.value);
  }

  const handleTargetTimeChange=(event)=>{
    let index=timeInterval.findIndex((item)=>item.value==event.target.value)
    if(index!=-1){
      console.log("changed time=",timeInterval[index]);
      // if(timeInterval[index].value>=timeMinValue){
        setTargetTime(timeInterval[index])
        props.handleTimeChange(timeInterval[index]);
      // }
  }
  }

  return (
    <>
      <div id="container" style={{ backgroundColor: "#111111", zIndex: "99", marginTop: "0px", padding: "20px", width: "100%",minWidth:`${MIN_BAR_CHART_WIDTH}`, minHeight: "calc(110vh - 256px)" }}>
      {/* <div id="container" style={{ backgroundColor: "#111111", zIndex: "99", marginTop: "0px", padding: "20px", width: "100%", minHeight: "calc(110vh - 256px)" }}> */}
        {/* <LineChartHeader/> */}
        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: "row", justifyContent: "space-evenly" }}>
          {/* <Tooltip title="Go Back">
            <IconButton
              onClick={() => navigate(-1)}
              size='large'>
              <ArrowBackIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
          {/* <Container sx={{ width: "80%" }}>
            <Typography variant='h4' color='primary' sx={{ alignSelf: "center" }}>{props.symbol} Options Chain Bar Chart at {props.metadata.time}</Typography>
          </Container>
          <Tooltip title="Full Screen">
            <IconButton
              onClick={() => toggleFullScreen()}
              size='large'>
              <FullscreenIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
        </Box>
        <div id="chartContainer">
        {isProUser?
          <>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:1}}>
        
          {/* < Typography sx={{mt:1,ml:3,fontSize:16,width:"50px"}}>Expiry</Typography> */}
        
            <FormControl size="small" sx={{ width:"80px",minWidth:'120px',ml:2,mt:0 }}>
              <InputLabel id="demo-simple-select-label">Expiry</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={expiry}
                MenuProps={MenuProps}
                placeholder="Select Expiry"
                sx={{height:"40px"}}
                label="Expiry"
                onChange={(event)=>handleExpiryChange(event)}
              >
                {props.expiryList?.map((item,index) => (
                <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
          </FormControl>
          <FormControl size="small" sx={{ width:"80px",minWidth:'120px',ml:2,mt:0 }}>
            <InputLabel id="demo-simple-select-label">Date</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dateParams}
              MenuProps={MenuProps}
              sx={{height:"40px"}}
              label="Date"
              onChange={(event)=>handleDateChange(event)}
            >
              {props.dateList?.map((item,index) => (
              <MenuItem key ={item} value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <DatePicker onChange={onDateChange} 
                allowClear={false}
                disabledDate={disabledDate}
                value={startDate}
                placeholder="Select Date"
                format={DateFormat}
                style={{border: "1px solid #484848",background:"#111111",height:"40px",
                borderRadius: "4px",width:160,marginLeft:24,marginTop:"0px"}}
                />  */}
        
          {/* <Typography sx={{mt:1,ml:2,fontSize:16,width:"90px"}}>Plot Type</Typography> */}
          
          {menuType == "oi"
              ?
          <FormControl size="small" sx={{ width:"220px",minWidth:'250px',ml:3,mt:0 }}>
          <InputLabel id="demo-simple-select-label">Plot Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={plotType}
            MenuProps={MenuProps}
            sx={{height:"40px"}}
            label="Plot Type"
            onChange={(event)=>handlePlotTypeChange(event)}
          >
            
          {BAR_CHART_OI_PLOT_TYPE.map((item,index) => (
            <MenuItem key={item.key} value={item.value}>{item.key}</MenuItem>
          ))};
            
            
          </Select>
          </FormControl>  
          : 
          <>
          {isAdminUser ?
            <>
            <FormControl size="small" sx={{ width:"240px",minWidth:'250px',ml:3,mt:0 }}>
            <InputLabel id="demo-simple-select-label">Plot Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={plotType}
              MenuProps={MenuProps}
              sx={{height:"40px"}}
              label="Plot Type"
              onChange={(event)=>handlePlotTypeChange(event)}
            >
            {BAR_CHART_ADMIN_PLOT_TYPE.map((item,index) => (
              <MenuItem key={item.key} value={item.value}>{item.key}</MenuItem>
            ))};
            </Select>
            </FormControl>  
            </>
            :
            <>
            <FormControl size="small" sx={{ width:"220px",minWidth:'250px',ml:3,mt:0 }}>
            <InputLabel id="demo-simple-select-label">Plot Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={plotType}
              MenuProps={MenuProps}
              sx={{height:"40px"}}
              label="Plot Type"
              onChange={(event)=>handlePlotTypeChange(event)}
            >
            {BAR_CHART_MI_PLOT_TYPE.map((item,index) => (
              <MenuItem key={item.key} value={item.value}>{item.key}</MenuItem>
            ))};
            </Select>
            </FormControl>  
            </>
          }
          </>
          
        }
            <Box sx={{ width: 500,ml:8}}>
            <Slider
              // aria-label="Always visible"
              defaultValue={timeMinValue}
              value={targetTime.value}
              onChange={handleTargetTimeChange}
              step={1}
              marks={timeInterval}
              min={0}
              max={timeInterval[timeInterval.length-1].value}
            />  
            </Box>
            <Box>
              <Typography sx={{ml:4,marginTop:"4px",fontSize:"12px"}}>Time</Typography>
              <Typography sx={{ml:4,marginTop:"6px"}}>{targetTime.time}</Typography>
          </Box>
          <Button  variant="contained" disabled={!props.isDrity} onClick={()=>props.resetDateTime()} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,ml:4,pr:1,mt:1}}  >
              Reset
            </Button>
        </Box>
        </>
        :
        isFastDataUser?
          <>
          {menuType == "oi"?
            <FormControl size="small" sx={{ width:"220px",minWidth:'250px',ml:3,mt:0 }}>
            <InputLabel id="demo-simple-select-label">Plot Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={plotType}
              MenuProps={MenuProps}
              sx={{height:"40px"}}
              label="Plot Type"
              onChange={(event)=>handlePlotTypeChange(event)}
            >
              
            {BAR_CHART_OI_PLOT_TYPE.map((item,index) => (
              <MenuItem key={item.key} value={item.value}>{item.key}</MenuItem>
            ))};
            </Select>
            </FormControl>
          : 
          <></>  
          }
          </>
        :
        <></>}
        {!props.isError?
        <>
          <div id="chartcontrols" style={{ padding: "10px", marginTop: "16px", maxWidth: "100%" }}></div>
          <div id={props.id} style={{ width: "100%", maxWidth: "100%", height: "800px" }} />
          </>:
          <>
          {/* Error state */}
          </>}
        </div>
      </div>
    </>
  )
}

export default OptChainChartContainer
