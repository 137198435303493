import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress,Typography,Container,Box,IconButton, Tooltip } from '@mui/material';
import LineChartHeader from './components/line_chart_header';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import HvStockChartContainer from './components/hv_stock_chart_container';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS, CHART_RELOAD_TIME,MIN_HV_CHART_WIDTH } from '../../common/utility/constant';
import { useQuery } from '../../common/utility/helperFunc';
import { useNavigate, useLocation } from 'react-router-dom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';


const StockChartHV = (props) => {
  const [cancel, responseData, error, loaded, reset, executeAPI ] = useAxiosWithAuth();
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate(); 
  const from = "/login";
  const [paramsLoaded,setParamsLoaded]=useState(false);
 

  const [state, setState] = useState({
    isLoading: true,
    chartData: undefined,
    plotParams: new Map(),
    chartLoaded:false
  });

  const { isLoading, chartData, plotParams,chartLoaded } = state;

  const type = "HV";
  const symbol = useQuery("symbol");
  const startDate = useQuery("startDate");
  const nDays = useQuery("nDays");
  const plot = useQuery("plot");
  const vtype = useQuery("vtype");
  const oitype = useQuery("oitype");

  const [queryParams, setQueryParams] = useState({
    symbol: symbol ? symbol : "NIFTY",
    startDate: startDate ? startDate : "",
    nDays: nDays ? nDays : 500,
    plot: plot ? plot : "20_atr",  // 5_atr
    vtype: vtype ? vtype : "o2c_mean",  // gap_mean etc
    oitype: oitype ? oitype : "coi"  // coi, dcoi, vol etc
  });

  // console.log("queryparams in stock chart, isLoading  ", queryParams, isLoading);

  //state ref is reuired to keep track of lastLoadTime value in the state.
  const stateRef = useRef(state);

  useEffect(()=>{
    // if(props.stateData!=undefined){
    //   const paramObject={
    //     type: "HV",
    //     symbol: props.stateData.data.symbol? props.stateData.data.symbol : "NIFTY",
    //     startDate: props.stateData.data.startDate ? props.stateData.data.startDate : "",
    //     nDays: props.stateData.data.nDays ? props.stateData.data.nDays : 500,
    //     plot: props.stateData.data.plot ? props.stateData.data.plot : "20_atr",
    //     vtype: props.stateData.data.vtype ? props.stateData.data.vtype : "o2c_mean",
    //     }
    //     setParamsLoaded(true); 
    //     setQueryParams(paramObject);
    // }
  },[])

  useEffect(() => {
    // ReloadChart(location.search);
    
  }, [location]);
  
  const ReloadChart=(searchVal)=>{
    setState((prevState) => ({
      ...prevState,
      isLoading: true}));

  const searchParams = new URLSearchParams(searchVal);

  const paramObject={
    type: "HV",
    symbol: searchParams.get('symbol') ? searchParams.get('symbol') : "NIFTY",
    startDate: searchParams.get('startDate') ? searchParams.get('startDate') : "",
    nDays: searchParams.get('nDays') ? searchParams.get('nDays') : 500,
    plot: searchParams.get('plot') ? searchParams.get('plot') : "20_atr",
    vtype: searchParams.get('vtype') ? searchParams.get('vtype') : "o2c_mean",
    oitype: searchParams.get('oitype') ? searchParams.get('oitype') : "coi",
    }
    setQueryParams(paramObject);
  }

  useEffect(() => {
    if(props.stateData!=undefined){
      
      if(props.stateData?.title)
        document.title = props.stateData.title;
    
    setState((prevState) => ({
      ...prevState,
      isLoading: true}));
      const paramObject={
        type: "HV",
        symbol: props.stateData.data.symbol? props.stateData.data.symbol : "NIFTY",
        startDate: props.stateData.data.startDate ? props.stateData.data.startDate : "",
        nDays: props.stateData.data.nDays ? props.stateData.data.nDays : 500,
        plot: props.stateData.data.plot ? props.stateData.data.plot : "20_atr",
        vtype: props.stateData.data.vtype ? props.stateData.data.vtype : "o2c_mean",
        oitype: props.stateData.data.oitype ? props.stateData.data.oitype : "coi",
        }
        setParamsLoaded(true); 
        setQueryParams(paramObject);
      }

    
  }, [props.stateData]);



  //execute the data fetch on the component mount
  useEffect(() => {
    if(!paramsLoaded)
    return;

    executeAPI(URL.LINE_CHART_DATA + `?type=${type}&symbol=${queryParams.symbol}&startDate=${queryParams.startDate}&nDays=${queryParams.nDays}&vtype=${queryParams.vtype}&oitype=${queryParams.oitype}&plot=${queryParams.plot}`, "GET", {});
  }, [queryParams]);


  const handleStartDateChange=(val)=>{
    setState((prevState) => ({
      ...prevState,
      // isLoading: true, 
      chartLoaded:false,
      isIncrementalData: false,isPollingAllowed:false}));

      setQueryParams((prevState)=>({
        ...prevState,
        startDate:val
      }))

  }

  const changePlotType=(val)=>{
    setState((prevState) => ({
      ...prevState,
      // isLoading: true, 
      chartLoaded:false,
      isIncrementalData: false,isPollingAllowed:false}));

      setQueryParams((prevState)=>({
        ...prevState,
        plot:val
      }))

  }

  const changeVType=(val)=>{
    setState((prevState) => ({
      ...prevState,
      // isLoading: true,
      chartLoaded:false, 
      isIncrementalData: false,isPollingAllowed:false}));

      setQueryParams((prevState)=>({
        ...prevState,
        vtype:val
      }))

  }

  const changeOiType=(val)=>{
    setState((prevState) => ({
      ...prevState,
      // isLoading: true,
      chartLoaded:false, 
      isIncrementalData: false,isPollingAllowed:false}));

      setQueryParams((prevState)=>({
        ...prevState,
        oitype:val
      }))

  }


  /**
 * API response handler  
 */
  useEffect(() => {
    if (loaded) {
      if (responseData != null) {
        if (responseData.chart_data !== undefined && responseData.chart_data?.length > 0) {
          plotParams.set("vtype", queryParams.vtype);
          plotParams.set("oitype", queryParams.oitype);
          plotParams.set("plot", queryParams.plot);
          setState((prevState) => ({
            ...prevState,
            chartLoaded:true,
            isLoading: false, chartData: responseData.chart_data, plotParams: plotParams
          }));
        }
        else {
          console.log("no data found");
          // TODO: need to show message to user about no data
          setState((prevState) => ({
            ...prevState,
            chartLoaded:true,
            isLoading: false
          }));
        }
      }
      else if (error !== null) {
        console.log("Error data=", error);
        setState((prevState) => ({
          ...prevState,
          chartLoaded:true,
          isLoading: false
        }));
      }
      reset();
    }
  }, [loaded, responseData]);

  // console.log("plotParams", plotParams);

  function toggleFullScreen() {
    let chartdiv = document.querySelector('#'+props.id)
    if (!document.fullscreenElement) {
      chartdiv.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  return (
    <>
      {/* <LineChartHeader/> */}
      {isLoading ? <div style={{ textAlign: "center", width: "100%", height: "100%" }}><CircularProgress sx={{ marginTop: "20%" }} /></div> :
      <>
      <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: "row", justifyContent: "space-evenly" ,width:"100%",minWidth:`${MIN_HV_CHART_WIDTH}`, backgroundColor:"#111111"}}>
        <Container sx={{ width: "80%",mt:2 }}>
        <Typography variant='h4' color='primary' sx={{ alignSelf: "center" }}>{queryParams.symbol} Historical Volatility Line Chart</Typography> </Container>
     <Tooltip title="Full Screen">
        <IconButton
          onClick={() => toggleFullScreen()}
          sx={{mr:4,mt:1}}
          size='large'>
          <FullscreenIcon sx={{ width: "36px", height: "36px"}} />
        </IconButton>
      </Tooltip>
     </Box>
     {chartLoaded?
        <HvStockChartContainer id={props.id} symbol={queryParams.symbol} chartData={chartData} plotParams={plotParams} startDate={queryParams.startDate} 
        handleStartDateChange={handleStartDateChange} plotType={queryParams.plot} changePlotType={changePlotType}
        vType={queryParams.vtype} changeVType={changeVType} oiType={queryParams.oitype} changeOiType={changeOiType}
        />
        :
        <div style={{ textAlign: "center", width: "100%", height: "100%",backgroundColor:"#111111" }}><CircularProgress sx={{ marginTop: "20%" }} />
        </div>
       }
        </>
      }
    </>

  )
}

export default StockChartHV
