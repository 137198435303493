import { Rnd } from "react-rnd";
import React,{useCallback,useEffect, useState} from "react";
import { Box,Tabs,Tab,Divider,Tooltip ,Typography,Container} from '@mui/material';
import {useTheme} from "@mui/material";
import { RIGHT_PANEL_CONTAINER_WIDTH,CHART_COLORS } from '../../../common/utility/mp_chart_constants';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TABLE_COLORS } from '../../../common/utility/table_constants';
import ReactSpeedometer from "react-d3-speedometer"

const style = {
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 0.5px #285fbd",
    background: "#0d203fdd",
    zIndex:9999,
    borderRadius:5
  };


  const OFLiveModal = (props) => {
    const [width,setWidth]=useState(264);
    const [height,setHeight]=useState(props.metaData?.speedo?440:300);
    const theme=useTheme();  
    // const []
    const [xCord,setXCord]=useState(10);
    const [yCord,setYCord]=useState(36);
    // const [xCord,setXCord]=useState(20);
    // const [yCord,setYCord]=useState(24);

    useEffect(()=>{
        console.log("OFLiveModal props=",props)
    },[props.metaData])
  
    const handleClose = () => {
      console.log("OFLiveModal handleClose");
      props.closeModal();
      // setOpen(false)
    };
   
  
        
   
  
   
   
    
    return (
      <>
    <Rnd
        style={style}
        resizeHandleStyles={{
          topRight:{cursor: 'default'},
          bottomRight:{cursor: 'default'},
          topLeft:{cursor: 'default'},
          bottomLeft:{cursor: 'default'},
          right:{cursor: 'default'},
          left:{cursor: 'default'},
          // top: {cursor: 'default'},
          // bottom:{cursor: 'default'},

        }}
        size={{ width: width, height: height }}
        position={{ x: xCord, y: yCord }}
        onDragStop={(e, d) => {
        //   this.setState({ xCord: d.x, yCord: d.y });
        setXCord(d.x);
        setYCord(d.y);
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
        //   this.setState({
        //     width: ref.style.width,
        //     height: ref.style.height,
        //     ...position
        //   });
        // setWidth(width);
        console.log("width=",ref.style.width)
        setWidth(ref.style.width);
        setHeight(ref.style.height);
        setXCord(position.x);
        // setYCord(position.y);

        }}
        // minWidth={props.metaData.speedo&& props.chartWidth>490? 480:260}
        minWidth={284}
        maxWidth={284}
        // maxWidth={props.metaData.speedo&& props.chartWidth>490? 480:260}
        minHeight={135}
        maxHeight={440}
        bounds={props.boundClassName}
        cancel=".cancel"
      >
        <div className="cancel" onClick={() => handleClose()}>
<CancelOutlinedIcon
            // className="cancel"
            // onClick={() => handleClose()}
            sx={{
              ml: 0, mt: 0, top: '-12px',
              left:"270px",
              // left: `${width}`, 
              // left: props.metaData.speedo&& props.chartWidth>490?'466px':"246px", 
              height: 24, width: 26, cursor: 'pointer', position: 'fixed', zIndex: 99999
            }}
          />
          </div>
          {props.metaData?
          <>
<Box sx={{display:"flex",flexDirection:'column',paddingRight:"4px",width:"100%",height:"100%",overflowY:'auto',overflowX:'hidden'}}> 
<Box sx={{display:"flex",flexDirection:'column',pt:1,minWidth:"280px"}}>



            {/* <Box sx={{display:"flex"}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"3px",ml:1,color:CHART_COLORS.VPOC}}></FiberManualRecordIcon>
              
              <Tooltip title="VPOC">
              <Typography sx={{fontSize:14,ml:1}}>
                  {props.metaData.vpoc}
              </Typography>
              </Tooltip>
              </Box>
              <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"3px",color:CHART_COLORS.VWAP}}></FiberManualRecordIcon>
             
              <Tooltip title="VWAP">
              <Typography sx={{fontSize:14,ml:1}}>
                  {props.metaData.vwap}
              </Typography>
              </Tooltip>
            </Box> 

            <Box sx={{display:"flex",mt:1}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"2px",ml:1,color:CHART_COLORS.VA_LINE}}></FiberManualRecordIcon>
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  VAH
              </Typography>
              </Box>
              <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"2px",color:CHART_COLORS.VA_LINE}}></FiberManualRecordIcon>
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  VAL
              </Typography>
            </Box>   */}

<Box sx={{display:"flex",mt:0,ml:1}}> 
                  <Box sx={{display:"flex",width:"50%"}}>
                
                  {/* <Tooltip title="VPOC"> */}
                  <Typography sx={{fontSize:12,ml:0,minWidth:"50px",fontWeight:400}}>
                      Time:
                  </Typography>
                  <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
                      {props.lastDataTime?props.lastDataTime:"NA"}
                  </Typography>
                  {/* </Tooltip> */}
                  </Box>
                
                  {props.latency!=undefined?
                  <>
                  <Typography sx={{fontSize:12,ml:0,minWidth:"55px",fontWeight:400}}>
                    Latency:
                  </Typography>
                  <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
                  {props.latency}
                  </Typography>
                  </>:
                  <></>
  }

                  {/* </Tooltip> */}
            </Box> 

            {props.metaData?.spot_ltp!=undefined?
            <Box sx={{display:"flex",marginTop:"4px",ml:1}}> 
                <Box sx={{display:"flex",width:"50%"}}>
              
                {/* <Tooltip title="VPOC"> */}
                <Typography sx={{fontSize:12,ml:0,minWidth:"60px",fontWeight:400}}>
                    Spot LTP:
                </Typography>
                <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
                    {props?.metaData?.spot_ltp?props.metaData.spot_ltp.toFixed(2):"NA"}
                </Typography>
                {/* </Tooltip> */}
                </Box>
              {props?.metaData?.spot_close!=undefined?
              <>
              {/* <Tooltip title="VWAP"> */}
              <Typography sx={{fontSize:12,ml:0,minWidth:"65px",fontWeight:400}}>
                Avg Close:
                </Typography>
                <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
                {props?.metaData?.spot_close?props.metaData.spot_close.toFixed(2):"NA"}
                </Typography>
                {/* </Tooltip> */}</>
              :<></>}
                
          </Box> 
            :
            <></>
            }

            {props.rotatingCot!=undefined?
          <Box sx={{display:"flex",marginTop:"4px",ml:1}}> 
              <Box sx={{display:"flex",width:"80%"}}>
                <Tooltip title={"Running Sum of last 10 bars COT"}>
              <Typography sx={{fontSize:12,ml:0,minWidth:"60px",fontWeight:400}}>
                10 Bar COT:
                </Typography>
                </Tooltip>
                <Typography sx={{fontSize:14,ml:0.5,fontWeight:700}}>
            <span style={{color: props.rotatingCot >= 0? TABLE_COLORS.GREEN : TABLE_COLORS.RED}}>{props.rotatingCot}{/* / {props?.metaData?.tbcot?props.metaData.tbcot:"NA"} */} </span>
                </Typography>
            </Box>
          </Box>
          :
          <></>
          }

            <Box sx={{display:"flex",marginTop:"4px",ml:1}}> 
                  <Box sx={{display:"flex",width:"50%"}}>
                
                  {/* <Tooltip title="VPOC"> */}
                  <Typography sx={{fontSize:12,ml:0,minWidth:"50px",fontWeight:400}}>
                      DPOC:
                  </Typography>
                  <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
                      {props?.metaData?.dpoc?props.metaData.dpoc.toFixed(2):"NA"}
                  </Typography>
                  {/* </Tooltip> */}
                  </Box>
                
                  {/* <Tooltip title="VWAP"> */}
                  <Typography sx={{fontSize:12,ml:0,minWidth:"55px",fontWeight:400}}>
                  VWAP:
                  </Typography>
                  <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
                  {props?.metaData?.vwap?props.metaData.vwap.toFixed(2):"NA"}
                  </Typography>
                  {/* </Tooltip> */}
            </Box> 
            
            
            
            <Box sx={{display:"flex",marginTop:"4px",ml:1}}> 
              <Box sx={{display:"flex",width:"50%"}}>
             
              {/* <Tooltip title="Volume area high"> */}
              <Typography sx={{fontSize:12,ml:0,minWidth:"50px",fontWeight:400}}>
              VAH:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.vah?props.metaData.vah.toFixed(2):"NA"}
              </Typography>
              {/* </Tooltip> */}
              </Box>
             
              {/* <Tooltip title="Volume area low"> */}
              <Typography sx={{fontSize:12,ml:0,minWidth:"55px",fontWeight:400}}>
              VAL:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.val?props.metaData.val.toFixed(2):"NA"}
              </Typography>
              {/* </Tooltip> */}
            </Box>  

            {props?.metaData?.pclose && props?.metaData?.close? 
            <>
              <Box sx={{display:"flex",marginTop:"4px",ml:1}}> 
                <Box sx={{display:"flex",width:"50%"}}>
              
                {/* <Tooltip title="Open"> */}
                <Typography sx={{fontSize:12,ml:-0.5,minWidth:"40px",fontWeight:400}}>
                    Change:
                </Typography>
                <Typography sx={{fontSize:14,ml:1,fontWeight:700}}>
                <span style={{color: props.metaData.close >= props.metaData.pclose ? TABLE_COLORS.GREEN : TABLE_COLORS.RED}}>{(props.metaData.close - props.metaData.pclose).toFixed(2)}</span>
                {props.metaData.close >= props.metaData.pclose ? <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,ml:-0.5,mt:-10,mb:-1.5,height:32,width:30}}/> : <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,ml:-0.5,mt:-10,mb:-1.5,height:32,width:30}}/>}
                </Typography>
                {/* </Tooltip> */}
                </Box>
              
                {/* <Tooltip title="Close"> */}
                <Typography sx={{fontSize:12,ml:-0.5,minWidth:"60px",fontWeight:400}}>
                    Change %:
                </Typography>
                <Typography sx={{fontSize:14,ml:0.5,fontWeight:700}}>
                <span style={{color: props.metaData.close >= props.metaData.pclose ? TABLE_COLORS.GREEN : TABLE_COLORS.RED}}>{((props.metaData.close - props.metaData.pclose) * 100 / props.metaData.pclose).toFixed(2)} %</span>
                </Typography>
                {/* </Tooltip> */}
              </Box>  
            </> 
            : <></>}

            <Box sx={{display:"flex",marginTop:"4px",ml:1}}> 
              <Box sx={{display:"flex",width:"50%"}}>
             
              {/* <Tooltip title="Open"> */}
              <Typography sx={{fontSize:12,ml:0,minWidth:"50px",fontWeight:400}}>
                  Open:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.open?props.metaData.open.toFixed(2):"NA"}
              </Typography>
              {/* </Tooltip> */}
              </Box>
             
              {/* <Tooltip title="Close"> */}
              <Typography sx={{fontSize:12,ml:0,minWidth:"55px",fontWeight:400}}>
                  Close:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.close?props.metaData.close.toFixed(2):"NA"}
              </Typography>
              {/* </Tooltip> */}
            </Box>  

             <Box sx={{display:"flex",marginTop:"4px",ml:1}}> 
              <Box sx={{display:"flex",width:"50%"}}>
             
              {/* <Tooltip title="Low"> */}
              <Typography sx={{fontSize:12,ml:0,minWidth:"50px",fontWeight:400}}>
                  High:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.high?props.metaData.high.toFixed(2):"NA"}
              
              </Typography>
              {/* </Tooltip> */}
              </Box>
             
              {/* <Tooltip title="High"> */}
              <Typography sx={{fontSize:12,ml:0,minWidth:"55px",fontWeight:400}}>
                  Low:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.low?props.metaData.low.toFixed(2):"NA"}
              </Typography>
              {/* </Tooltip> */}
            </Box>  

            <Box sx={{display:"flex",marginTop:"4px",ml:1}}>  
              <Box sx={{display:"flex",width:"50%"}}>
             
              {/* <Tooltip title="IB High"> */}
              <Typography sx={{fontSize:12,ml:0,minWidth:"50px",fontWeight:400}}>
                  IBH:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.ib_high?props.metaData.ib_high.toFixed(2):"NA"}
              </Typography>
              {/* </Tooltip> */}
              </Box>
             
              {/* <Tooltip title="IB Low"> */}
              <Typography sx={{fontSize:12,ml:0,minWidth:"55px",fontWeight:400}}>
                  IBL:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.ib_low?props.metaData.ib_low.toFixed(2):"NA"}
              </Typography>
              {/* </Tooltip> */}
            </Box>  

            <Box sx={{display:"flex",marginTop:"4px",ml:1}}>  
              <Box sx={{display:"flex",width:"50%"}}>
             
              {/* <Tooltip title="IB High"> */}
              <Typography sx={{fontSize:12,ml:0,minWidth:"50px",fontWeight:400}}>
                  Volume:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.vol?(props.metaData.vol/props.divisionFactor).toLocaleString(props.locale_string):"NA"}
              </Typography>
              {/* </Tooltip> */}
              </Box>
             
              <Typography sx={{fontSize:12,ml:-0.5,minWidth:"60px",fontWeight:400}}>
                  VPOC Vol:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.vpoc_vol?(props.metaData.vpoc_vol/props.divisionFactor).toLocaleString(props.locale_string):"NA"}
              </Typography>
            </Box>  

            {props?.metaData?.vol5_percent?
            <Box sx={{display:"flex",marginTop:"4px",ml:1}}>  
              <Box sx={{display:"flex",width:"50%"}}>
             
              {/* <Tooltip title="IB High"> */}
              <Typography sx={{fontSize:12,ml:0,minWidth:"60px",fontWeight:400}}>
              5dAvgVol:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.vol5_percent?(props.metaData.vol5_percent)+"%":"NA"}
              </Typography>
              {/* </Tooltip> */}
              </Box>
             
              <Typography sx={{fontSize:12,ml:-0.5,minWidth:"62px",fontWeight:400}}>
              14dAvgVol:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:700}}>
              {props?.metaData?.vol14_percent?(props.metaData.vol14_percent)+"%":"NA"}
              </Typography>
            </Box>:<></>}  
            </Box>
                  {props.metaData?.speedo?
                  <Box sx={{ml:4,mt:2,width:"220px"}}>
                    <ReactSpeedometer
                      textColor="#FFF"
                      forceRender={false}
                      maxValue={props.metaData.speedo_max}
                      minValue={50}
                      value={props.metaData.speedo}
                      width={200}
                      height={130}
                      segments={3}
                      // fluidWidth={true}
                      needleColor="red"
                      startColor="#346359"
                      // segments={10}
                      endColor="#A8CF45"
                    />
                  </Box>:
                  <></>}
        </Box>
        </>:<></>
  }
      </Rnd>
        
      </>
    );
  };
  
  export default OFLiveModal;
  