import React from 'react'
import { useEffect,useState,useMemo,useCallback,useRef } from 'react'
import useAuth from '../../../common/hooks/useAuth';
import { USER_ROLE_FIELDS,CHART_RELOAD_TIME } from '../../../common/utility/constant';
import { CircularProgress } from '@mui/material';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../../common/utility/constant';
import {Snackbar, Alert} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { getColumneDef,InstrumentRenderer
} from '../../../common/utility/idxTicker_columdDefs';
import '../styles/options.scss';
import { getPriceTableColumneDef } from '../../../common/utility/price_table_columnDef';

const ROW_HEIGHT=46;
const HEADER_HEIGHT=44;
const GROUP_HEADER_HEIGHT=30;
// import { CallCheckBoxRenderer,PutCheckBoxRenderer } from './components/options_table_renderer';
const gridOptions = {
  suppressColumnVirtualisation:true,
  suppressMovableColumns:true,
  suppressColumnMoveAnimation:true,
  // suppressRowVirtualisation:true,
  enableCellTextSelection:true,
  ensureDomOrder:true
}


const frameworkComponents= {
  instrumentComponent: InstrumentRenderer,
} 
function PRICE_TABLE(props) {
  const navigate = useNavigate(); 
  const from = "/login";
  const { auth } = useAuth();
  const [gridAPIOptions,setGridAPIOptions]=useState();
  const [columnAPIOptions,setColumnAPIOptions]=useState();
  const gridRef = useRef();
  

 
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  
  const { open, msg, severity } = msgState;

  const [rowData,setRowData]=useState(props.tableData);
  const [priceColumnDef,setPriceColumnDef]=useState(getPriceTableColumneDef(auth,props.locale_string))

  useEffect(()=>{
    console.log("Price table props.rowdata=",props.tableData);
    setRowData(props.tableData);
  },[props.tableData]);

  
  

  useEffect(()=>{
    if(gridRef!=undefined && gridRef.current!=undefined && gridRef.current.api!=undefined){
    let params = {
        force: true,
        suppressFlash: false,
        // rowNodes: changedRow,
      }; 
      gridRef.current.api.refreshCells(params);
    }
    
  },[rowData])

      
 /**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};    
        
    const onGridReady = params => {
      setGridAPIOptions(params.api);
      setColumnAPIOptions(params.columnApi);
    };
    
   
  return (
    <div style={{width:"100%",height:  `calc(100% - 64px)`,marginTop:"4px",marginLeft:"4px",marginRight:"10px"}}>
        {rowData!=undefined?
       <div className="ag-theme-alpine-dark" style={{height:  `calc(100% - 8px)`,marginLeft:"4px",marginRight:"4px"}}>
           <AgGridReact
           ref={gridRef}
            onGridReady={onGridReady}
            components={frameworkComponents}
            rowHeight={ROW_HEIGHT}
            headerHeight={HEADER_HEIGHT}
            groupHeaderHeight={GROUP_HEADER_HEIGHT}
            gridOptions={gridOptions}
            rowData={rowData}
            columnDefs={priceColumnDef}/>
           
       </div>:
      <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
      </div>
        }
      
      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>

    </div>
  )
}

export default PRICE_TABLE;