import React,{useCallback,useEffect, useState} from "react";
import "../styles/styles.scss"
import CallSplitIcon from '@mui/icons-material/CallSplit';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import { Splitscreen } from "@mui/icons-material";
import { CHART_SCREEN_TYPE, VA_TYPE } from "../../../common/utility/mp_chart_constants";
import { TIME_FRAME_VALUES } from "../../../common/utility/constant";

const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';

const KRLContextMenu = (props) => {
  let {
    xPosition,
    yPosition,
    krlData,
    exitMenu,
    key,
    editKRL,
    deleteKRL
  } = props;

      
  const handleEscKey = useCallback((event) => {
    if (event.key === KEY_NAME_ESC) {
      exitMenu();
    }
  }, [exitMenu]);
 
  useEffect(() => {
   console.log("Save KRL cont4ext menu krlData=",krlData)
  }, []);
  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
   return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);
 
  
  return (
    <>
    <ul
          className="context-menu"
          style={{
            top: yPosition,
            left: xPosition,
            width: "280px",
          }}
        >
            {krlData.map((item,index) => (
            <>
                <li  title={krlData[index].description} onClick={(e) => editKRL(krlData[index])}>
                    Edit KRL [{krlData[index].start_date}] {krlData[index].level}: {krlData[index].description}
                </li>
                
                <hr className="hr-menu"/>
                
                <li   title={krlData[index].description} onClick={(e) => deleteKRL(krlData[index])}>
                    Delete  KRL [{krlData[index].start_date}] {krlData[index].level}: {krlData[index].description}
                </li>
                
                {index!=krlData.length-1?
                    <hr className="hr-menu"/>:
                    <></>
                }

            </>
           ))}
       

       
       
         
      
       

      



       
        </ul>
   </>
  );
};

export default KRLContextMenu;
