import React from 'react'

const Notifications = () => {
  return (
    <div>
        Welcome to notifications
    </div>
  )
}

export default Notifications
    