import React, { useState,useEffect } from 'react'
import {Link, Grid ,Snackbar,Alert,Popover,CircularProgress,Checkbox,useTheme} from "@mui/material"
import Typography from '@mui/material/Typography';

import { URL } from '../../common/api/urls';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';

import useAuth from '../../common/hooks/useAuth';
import axios from 'axios';
import useRefreshToken from '../../common/hooks/useRefreshToken';
import { useNavigate, useLocation } from 'react-router-dom';
import { SUBSCRIPTION_PLAN_LIST,SNACKBAR_AUTO_HIDE_DURATION_SHORT,TEXT_MSGS,PAYMENT_STATUS,SUBSCRIPTION_PLANS_PRICE,SUBSCRIPTION_PLANS } from '../../common/utility/constant';

const FyersHandler = () => {
  const from = "/login";
  const theme=useTheme();
  const navigate = useNavigate(); 
  const location = useLocation();
  const { auth } = useAuth();
  const refresh = useRefreshToken();
  const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
  const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
  const [isLoaded,setIsLoaded]=useState(true);  
  const [isVerified,setIsVerified]=useState(false);


  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  const { open, msg, severity } = msgState;

  useEffect(() => {
    document.title = "BrokerHandler";
  }, []);


 

  /**
   * autoclosing of the snackbar msg bar 
   */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
  };

  
  
    useEffect(()=>{
     
      //executeAPI(URL.GET_SUBSCRIPTION_PLANS,"GET",{}); 
      // verifyRefreshToken();
    },[]);



  //handle location change for accessing the status of fyers token
  useEffect(() => {
    console.log('Location changed======>',location);
    if(location.search!=undefined && location.search!=""){
        setIsVerified(false);
        executeAPIStatus(URL.FYERS_TOKEN+location.search,"GET",{});
     
    }
  }, [location]);


  /**
   * API response handler token verification data
   */  
  useEffect(() => {
    if(loadedStatus){
        
        setIsLoaded(true);
      if(responseDataStatus!=null){
        setIsVerified(true);
        setMsgState({open:true,msg:`${TEXT_MSGS.BROKER_AUTHENTICATION_SUCCESS}`,severity:"success"});
        // verifyRefreshToken();
      }
      else if(errorStatus!==null){
        setMsgState({open:true,msg:errorStatus?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
          console.log("status received =",error?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetStatus();
    }
  },[loadedStatus,responseDataStatus]);

  const verifyRefreshToken = async () => {
    try {
        await refresh(true);
    }
    catch (err) {
        console.error(err);
    }
    finally {
        // do a reload here
        let reload_url=`${process.env.REACT_APP_URL}subscription`
        window.location=reload_url
        
    }
}



    return (
      <>
      {isLoaded?
      <Grid component="main" container display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{height: `calc(100vh - 64px)` }}>
      {/* <Box sx={{ml:4}}> */}
        <Typography variant="h3" component="div" sx={{mb:1,mt:3,textAlign:'center'}}>Fyers Integration</Typography>
       
        {/* <Typography variant="h5" component="div" sx={{mb:1,mt:3,textAlign:'center'}}></Typography> */}
        {isVerified?
            <>
            <Typography variant="h5" component="div" sx={{mb:1,mt:3,textAlign:'center'}}>Fyers Integration Completed Successfully</Typography>
            <Link href="/" variant="link">Back to Homepage</Link>
            </>:
            <></>
        }
        
  
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
      
      {/* </Box> */}
      </Grid> :
        <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
        </div>} 
      </>          
       
    )
}

export default FyersHandler
