import React, { useState } from 'react'
import { Typography, Container,Link} from '@mui/material'
import {styled, useTheme} from '@mui/material/styles';
import { ToolbarFixed } from '../component.js';


const FooterToolbar=styled(ToolbarFixed)(
  ({theme})=> `
  bottom:0px;
  justify-content:center;
  text-align:center;
  `
)


function Copyright(props) {
  return (
   
    <Typography  variant="copyRight" align="center" {...props}>
      {'Copyright © '}
      {new Date().getFullYear() } Vtrender. All Rights Reserved. 
      </Typography>
  );
}

/**
 * 
 * @returns fixed toolbar for copyright text
 */
function Footer(props) {
  const theme=useTheme();
 
  return (
    <>
      <FooterToolbar sx={{borderTop: `1px solid ${theme.palette.primaryTheme.shade03}` }} >
        <Container >
          <Copyright/>
          <Typography variant="linkSupportText" >
              <Link onClick={props.openTermModal} sx={{ml:1,cursor:"pointer"}} variant="link">Terms of use</Link>
          </Typography>
          &nbsp;.&nbsp;
          <Typography variant="linkSupportText" >
              <Link onClick={props.openPrivacyModal} sx={{ml:0,cursor:"pointer"}} variant="link">Privacy Policy</Link>
          </Typography>
          &nbsp;.&nbsp;
          <Typography variant="linkSupportText" >
              <Link onClick={props.openContactInfoModal} sx={{ml:0,cursor:"pointer"}} variant="link">Contact Us</Link>
          </Typography>
          &nbsp;.&nbsp;
          <Typography variant="linkSupportText" >
              <Link onClick={props.openRefundModal} sx={{ml:0,cursor:"pointer"}} variant="link">Refund & Canellation Policy</Link>
          </Typography>
          &nbsp;.&nbsp;
          <Typography variant="linkSupportText" >
              <Link onClick={props.openAboutUsModal} sx={{ml:0,cursor:"pointer"}} variant="link">About Us</Link>
          </Typography>
        </Container>
      </FooterToolbar>
     
    </>
  )
}

export default Footer