import React,{useEffect} from 'react'
import {Link, Grid } from "@mui/material"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Paper} from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player/vimeo'
import ReactPlayerYT from 'react-player/youtube'
import Carousel from 'react-material-ui-carousel'
import mpImg from '../../assets/images/mp-chart.png';
import mp1Img from '../../assets/images/mp-chart1.png';
import mp2Img from '../../assets/images/mp-chart2.png';
import optableImg from '../../assets/images/op-table.png';
import optable1Img from '../../assets/images/op-table1.png';
import idxtableImg from '../../assets/images/index-table.png';
import greektableImg from '../../assets/images/greeks-table.png';
import spectrumImg from '../../assets/images/spectrum.png';
import oichgImg from '../../assets/images/oi-change.png';
import ntmoichgImg from '../../assets/images/ntm-oi-change.png';
import hvImg from '../../assets/images/hv-charts.png';
import ivizImg from '../../assets/images/iviz-1.png';
import ivcurveImg from '../../assets/images/iv-curve.png';
import oisnakesImg from '../../assets/images/oi-snakes.png';
import Header from '../login-register/components/header';
import MainLayoutContainer from '../login-register/components/main-layout';

const style = {
    position: 'absolute',
    top: '7%',
    left: '5%',
    height: "90%",
    //   transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
  };

  const imgStyle = {
    width: "100%",
    height: "50%",
    // align: "middle",
    marginLeft: 0, 
    marginTop: 20, 
    marginBottom: 20
  }

  const items = [
    {
        name: "16/03 Orientation Webinar 1",
        src:"https://vimeo.com/809435827",
        yt_src: "https://youtu.be/7J5CAqumqHk"
    },
    {
        name: "31/03 Orientation Webinar 2",
        src:"https://vimeo.com/813574929",
        yt_src: "https://youtu.be/vjzd8uSKRlI"
    }
  ]

  const guide_items = [
    {
        name: "Revolutionize Your Options Trading with Sensitivity Value - The Leading Indicator You Can't Ignore",
        yt_src: "https://youtu.be/7w4ThmNx4YA"
    },
    {
        name: "Mastering Spectrum: Second Order Greeks & Gamma Positioning for NSE Option Traders",
        yt_src: "https://youtu.be/KBwaH3LUI4E"
    },
    {
        name: "Intraday Open Interest Analysis: Tracking Call & Put OI Changes on Charts",
        yt_src: "https://youtu.be/hItCJb7WX-M"
    },
    {
        name: "Using Market Profile & VWAP/POC with Strangle Charts: A Complete Guide",
        yt_src: "https://youtu.be/3fHhVxuPPyw"
    }
    
  ]

const IntroManual = () => {

  useEffect(() => {
    document.title = "User Manual";
    var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
    // console.log(link);
    document.head.appendChild(link);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [selectedIndex,setSelectedIndex]=React.useState(0);
 
  const goNext=()=>{
    if(selectedIndex<(items.length-1))
        setSelectedIndex(selectedIndex+1);
    else
        setSelectedIndex(0);
  }

  const goPrevious=()=>{
    if(selectedIndex==0)
        setSelectedIndex(items.length-1);
    else
        setSelectedIndex(selectedIndex-1);
  }

    return (
      <MainLayoutContainer>
        {/* Header component */}
        <Header showLogin={true} showIntro={true}/>
      <Box sx={{marginTop:10, marginLeft:3, marginBottom:10, marginRight:2}} align='justify'>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            An Introduction to Vtrender Charts
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            It is our pleasure to introduce to you our new charting program based on the MarketProfile and the Options Market. Our team has been working tirelessly to create a revolutionary tool that will provide traders and investors with a comprehensive view of market activity.
            <br/>For those who may not be familiar, MarketProfile is a trading technique that allows users to identify price levels and patterns that are important for decision-making. Our new service takes this to the next level, providing users with powerful tools to analyze and interpret this information.
            <br/>With our MarketProfile charting service, users will be able to visualize market data in a variety of ways, including horizontal and vertical profiles, histograms, and volume distribution charts. This will give traders a clear view of the market's activity and direction.
            <br/><br/>
            Our Options suite is intuitive and modern and will give you powerful new tools to help analyze the fast growing and fast moving option markets of the NSE. With 10 CR- 40 CR contracts traded everyday, the trader of today needs sophistication and institutional level analytics to trade markets better. 
            <br/><br/>
            Our suite is designed to give you the edge you need in today's highly competitive financial markets. With the latest tools and analytics at your fingertips, you can stay ahead of the competition and make profitable trades.
            <br/>Our service is user-friendly and intuitive, making it accessible to traders of all levels of experience. Additionally, we offer comprehensive support to ensure that our users get the most out of the service.
            <br/>In today's fast-paced financial world, having the right tools is essential for success. We believe that our MarketProfile and the Options charting service is a game-changer, providing traders with the edge they need to make informed decisions.
            <br/>Thank you for your attention, and we look forward to helping you achieve your financial goals with the Vtrender Charting program
          </Typography>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            Orientation Webinar Recordings
          </Typography>
          <Box sx={{ display: 'flex',flexDirection: 'column', justifyContent:"center", alignItems:'center',
                width:{mobile:"100%", tablet:"100%", laptop:"100%"} }}> 
            
              <Carousel sx={{position:'relative',width:'100%',pb:0,mt:10,mb:0,maxWidth:"1280px"}} cycleNavigation={false} autoPlay={false}
              next={ () => goNext() }
              prev={ () => goPrevious() }
              onChange={(now,previous)=>{setSelectedIndex(now)}}
              >
                  {
                      items.map( (item, index) => 
                      <>
                      <Typography variant="h4" align="center">{item.name}</Typography>
                      <br />
                      <Paper sx={{position:'relative', marginTop:"10px",marginBottom:"10px",display:'flex',justifyContent:'center',width:"100%"}}>
                      <ReactPlayerYT url={item.yt_src} controls playing={selectedIndex==index?false:false} height="720px" width="1280px" />
                      </Paper>
                      </>
                      )
                  }
              </Carousel>
          </Box>
          <br/>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            User Guide Videos: Information in different charts
          </Typography>
          <Box sx={{ display: 'flex',flexDirection: 'column', justifyContent:"center", alignItems:'center',
                width:{mobile:"100%", tablet:"100%", laptop:"100%"} }}> 
            
              <Carousel sx={{position:'relative',width:'100%',pb:0,mt:10,mb:0,maxWidth:"1280px"}} cycleNavigation={false} autoPlay={false}
              next={ () => goNext() }
              prev={ () => goPrevious() }
              onChange={(now,previous)=>{setSelectedIndex(now)}}
              >
                  {
                      guide_items.map( (item, index) => 
                      <>
                      <Typography variant="h4" align="center">{item.name}</Typography>
                      <br />
                      <Paper sx={{position:'relative', marginTop:"10px",marginBottom:"10px",display:'flex',justifyContent:'center',width:"100%"}}>
                      <ReactPlayerYT url={item.yt_src} controls playing={selectedIndex==index?false:false} height="720px" width="1280px" />
                      </Paper>
                      </>
                      )
                  }
              </Carousel>
          </Box>
          <br/>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            On the MarketProfile
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            MarketProfile is a trading technique that allows users to identify price levels and patterns that are important for decision-making
            <br/><br/>
            In the world of trading  information is potential power. With the right information at their fingertips, traders can make informed decisions that can lead to substantial profits. On the other hand, trading without sufficient information can be like driving blindfolded - it's dangerous and can lead to costly mistakes.
            <br/>Market Profile is a powerful tool that can help derivative traders identify key areas of support and resistance, track trends and spot potential market imbalances. With its visual representation of price and time distribution, traders can easily spot key levels of value and fair price, and make informed trading decisions that stay ahead of the market. By leveraging the insights provided by Market Profile, traders can improve their chances of success and increase their profits.
            <br/><br/>
            The Market Profile works on Price, Volume and Time - all together
            <br/><br/>
            A basic Market Profile chart consists of a histogram that shows the volume of trading activity at each price level over a specified period of time. The chart is divided into sections based on the price levels at which the most trading activity occurred, creating a bell-shaped curve that represents the "value area". The top of the bell curve represents the most traded price level, while the tails of the curve represent the prices where trading activity was less.
            <br/>Here are a few examples of Market Profile charts with a few important references marked:
            <br/>
            {/* <img src={mpImg} alt="Market Profile" width={1000} height={500} style={{marginLeft:20, marginTop:20, marginBottom:20}} /><br/> */}
            <img src={mpImg} alt="Market Profile" style={imgStyle} /><br/>
            <img src={mp1Img} alt="Market Profile" style={imgStyle} /><br/>
            <img src={mp2Img} alt="Market Profile" style={imgStyle} /><br/>
            <br/>
            This chart shows the volume of trading activity over a period of one day. The horizontal axis shows the price levels, and the vertical axis shows the volume of trading activity.. This is where the majority of the trading activity occurred during the day.
            <br/>Market Profile charts can also include additional information, such as the range of prices that were traded during the period, the highest and lowest price levels, and the point of control (the price level with the highest volume of trading activity).
            <br/><br/>
            <Typography sx={{ fontWeight: 600 }} variant="subtitle1">The Value Area</Typography>
            The value area is a central concept in MarketProfile analysis. It is the price range in which a specified percentage of the day's trading volume took place. Typically, the value area is defined as the price range that contains 70% of the day's trading volume. The value area represents a range of prices where the market has found fair value, or where the most business has been conducted during the trading day.
            <br/>Traders who use MarketProfile analysis will often look for opportunities to enter the market when the price is trading outside of the value area. If the price moves above the value area, it suggests that buyers are in control of the market and that prices are likely to continue to rise. If the price moves below the value area, it suggests that sellers are in control of the market and that prices are likely to continue to fall.
            <br/>MarketProfile charts are often color-coded to help traders easily identify the value area. The value area is typically shaded in a different color from the rest of the chart to make it stand out. This makes it easy for traders to quickly identify where the most trading activity is taking place and to make trading decisions based on that information.
            <br/><br/>
            <Typography sx={{ fontWeight: 600 }} variant="subtitle1">The POC</Typography>
            POC stands for Point of Control, which is a key element in Market Profile analysis. POC represents the price level at which the highest volume of trading activity occurred during a particular period, usually a trading session or a specific time period. The POC is calculated by finding the price level at which the most TPO (Time Price Opportunity) letters have been recorded.
            <br/>POC is an important level of support and resistance, as it often serves as a magnet for prices, attracting them towards it. It is also used as a reference point for determining the market's bias. If the market is trading above the POC, it is considered bullish, while if it is trading below the POC, it is considered bearish.
            <br/>POC is often used in conjunction with other Market Profile levels such as the value area and the range extension lines to identify potential trading opportunities and manage risk effectively.
          </Typography>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            The Options Table
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            {/* <img src={optableImg} alt="Options Table" style={imgStyle} /><br/> */}
            <img src={optable1Img} alt="Options Table" style={imgStyle} /><br/>
            Option tables are a powerful tool for traders who are looking to analyze options data and make informed decisions. These tables provide a wealth of information, including the maximum and minimum Open Interest (OI) for each strike, as well as the time of maximum and minimum OI.
            <br/>In addition, option tables typically include other important metrics such as Implied Volatility (IV), Volume Weighted Average Price (VWAP), Point of Control (POC), Developing Open Interest, and Total OI for all strikes.
            <br/>By analyzing this data, traders can gain valuable insights into the behavior of the options market, and make informed decisions about when to enter or exit a trade. For example, the maximum and minimum OI for a particular strike can indicate strong support or resistance levels, while the VWAP and POC can provide insight into the price range that is most attractive to traders.
            <br/>Developing Open Interest is also an important metric to watch, as it can indicate changes in market sentiment and help traders anticipate future price movements. Similarly, Total OI for all strikes can provide a broad overview of the options market and give traders a sense of overall market activity.
            <br/>Option tables are a powerful tool for traders who are looking to analyze options data and make informed decisions. By understanding the various metrics included in these tables, traders can gain valuable insights into the behavior of the options market, and use this information to make profitable trades.
          </Typography>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            Greeks Table
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <img src={greektableImg} alt="Greeks Table" style={imgStyle} /><br/>
            A Greeks table is a must-have tool for traders who want to analyze options data and make informed decisions. This table provides a wealth of information about option greeks, including Delta, Gamma, Vega, and Theta. Additionally, it typically includes other important metrics such as Implied Volatility (IV), Developing Open Interest (DOI), and Total Open Interest (OI).
            <br/>Delta is a measure of an option's sensitivity to changes in the underlying asset price, while Gamma measures the rate of change of Delta. Vega measures an option's sensitivity to changes in Volatility, while Theta measures the option's sensitivity to the passage of time.
            <br/>By analyzing the option greeks in a Greeks table, traders can gain valuable insights into the behavior of the options market and make informed decisions about when to enter or exit a trade. For example, a high Delta indicates that an option is likely to move in the same direction as the underlying asset, while a high Gamma indicates that Delta is likely to change rapidly in response to small changes in the underlying asset price.
            <br/>The Greeks table can also help traders predict the future price of an option by incorporating additional information such as the current price of the underlying asset. This information can be used to calculate the option's theoretical value, which can help traders determine whether the option is overpriced or underpriced.
            <br/>The Greeks table is an essential tool for options traders who want to analyze options data and make informed decisions. By understanding the various option greeks and other important metrics included in this table, traders can gain valuable insights into the behavior of the options market and use this information to make profitable trades.
          </Typography>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            Index Table
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <img src={idxtableImg} alt="Index Table" style={imgStyle} /><br/>
            An index is a group of stocks that are used to track the performance of a particular market or sector. The composition of stocks within an index can vary depending on the methodology used to construct the index..
            <br/>In addition to understanding the composition of stocks within an index, traders can also use technical analysis to gain insights into individual stocks within the index. This can include analyzing metrics such as the 5 and 14 day Average True Range (ATR), which measures the volatility of a stock over a given period of time.
            <br/>Other important technical metrics to consider include the maximum and minimum volumes for a particular stock, as well as its weightage price move wise within the index. By analyzing these metrics, traders can gain valuable insights into the behavior of individual stocks within the index and make informed trading decisions.
            <br/>For example, a stock with a high ATR may be more volatile and risky, while a stock with a low ATR may be less volatile and less risky. Similarly, a stock with a high weightage price move wise within the index may have a greater impact on the overall performance of the index, and may therefore be of greater interest to traders.
            <br/>Understanding the composition of stocks within an index is important for traders who want to track the performance of a particular market or sector. Additionally, by incorporating technical analysis of individual stocks within the index, traders can gain valuable insights into the behavior of individual stocks and make informed trading decisions.
          </Typography>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            Spectrum Charts
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <img src={spectrumImg} alt="Spectrum Charts" style={imgStyle} /><br/>
            Traders can analyze the Spectrum to gain insight into the potential range of price moves for the index over the next week. A wider Spectrum indicates that the underlying asset is expected to experience a larger range of price movements, while a narrower Spectrum suggests that the asset is likely to experience more stable price movements.
            <br/>We use options data to calculate the Spectrum for each expiration date. The spectrum shows the expected range of price moves for the index for that weekly settlement
            <br/>By using this approach, traders can gain valuable insights into the potential range of price moves for the index and make informed trading decisions. For example, they may use this information to adjust their positions or to place new trades based on their expectations for the index's price movements over the coming week.
            <br/>Analyzing the Spectrum can be a useful tool for traders who want to plot an expected range of price moves for an index in the weekly settlement. By using this approach, traders can gain valuable insights into the potential range of price moves
          </Typography>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            OI Change Charts
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <img src={oichgImg} alt="OI Change Charts" style={imgStyle} /><br/>
            Open Interest is a key metric used by options traders to measure the number of contracts that are outstanding for a particular strike price. By analyzing the change of Open Interest per strike over time, traders can gain valuable insights into market sentiment and make informed trading decisions.
            <br/>One way to visualize the change of Open Interest per strike is to calculate it as moving lines for both calls and puts and plot them on separate graphs. This approach can help traders to quickly identify trends in Open Interest changes for each strike price and determine which options are seeing the most interest from buyers and sellers.
            <br/>For example, a trader may observe that the Open Interest for a particular call option is increasing rapidly, while the Open Interest for the corresponding put option is decreasing. This could indicate that traders are bullish on the underlying asset and are more interested in buying calls than puts.
            <br/>By using moving lines to plot the change of Open Interest per strike for both calls and puts, traders can also identify patterns in the data that may be indicative of upcoming price movements. For instance, if the Open Interest for both calls and puts at a particular strike price is decreasing rapidly, it could suggest that the market is expecting a period of low volatility in the near future.
            <br/>In conclusion, analyzing the change of Open Interest per strike as moving lines for calls and puts can be a powerful tool for options traders looking to make informed trading decisions. By visualizing the data in separate graphs, traders can quickly identify trends in market sentiment and identify potential opportunities for profit.
          </Typography>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            NTM OI Change Charts
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <img src={ntmoichgImg} alt="NTM OI Change Charts" style={imgStyle} /><br/>
            This is the same logic as the OI Changes but calculated for the closest few strikes which can be ITM or ATM. The calculations and the logic wise is similar, just a little more focus on near money strikes gives a sligthly different perspective of OI Change information.
          </Typography>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            Historical Volatility Line Charts
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <img src={hvImg} alt="HV Charts" style={imgStyle} /><br/>
            Plotting historical volatility levels can be a useful tool for options traders because it allows them to gain insights into how the market has behaved in the past and make informed decisions about future trades.
            <br/>Historical volatility measures how much the price of an asset has fluctuated over a given period of time. By plotting historical volatility levels over time, traders can observe patterns in the market's behavior and identify potential opportunities for profit.
            <br/>For example, if a trader observes that historical volatility levels tend to increase during certain periods of the year, they may adjust their trading strategy to take advantage of this trend. Similarly, if historical volatility levels for a particular asset are low, traders may consider using strategies that involve selling options in order to generate income.
          </Typography>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            OI and Inventory Visualization Charts
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <img src={ivizImg} alt="iViz Charts" style={imgStyle} /><br/>
            Inventory visualization charts are a powerful tool for options traders looking to gain a better understanding of market dynamics and make informed trading decisions. These charts typically use key metrics such as Open Interest (OI), gamma, and delta to name only a few and to plot charts and visualize market trends.
            For example, a inventory visualization chart may use OI to show the number of outstanding contracts at different strike prices. By plotting this data on a chart, traders can quickly identify which strike prices are seeing the most interest from buyers and sellers, and use this information to alter their trading decisions.
            Similarly, a inventory visualization chart may use gamma to show how the Delta of an option changes with respect to changes in the underlying asset price. By plotting gamma based data on a chart, traders can observe how overall options inventory is positioned, and use this information to adjust their trading strategies accordingly.
            <br/><br/>

            <Typography id="modal-modal-description" sx={{fontWeight: 600 }} variant="h6">
              OI Visualization
            </Typography>
            <br/>Different Charts under this page are only OI based mainly. 
            <br/>These charts visualize the Open Interest situation across different strikes indicating how the writers are positioned OI wise. 
            <br/>Different visualizations help in analysing the same information by highlighting a different aspect of OI data in each and you can watch any visualization as per your individual trading style.
            <br/><br/><span style={{ fontWeight: 700 }}>OI - Split:&nbsp;</span> Visualise the strike wise Total OI of Put and Call Options individually at a given date/time.
            <br/><br/><span style={{ fontWeight: 700 }}>OI Change - Split:&nbsp;</span> Visualise the strike wise Change in OI since start of that day, for Put and Call Options individually at a given date/time.
            <br/><br/><span style={{ fontWeight: 700 }}>OI Difference:&nbsp;</span> Visualise the strike wise net difference of (Call OI - Put OI) at a given date/time.
            <br/><br/><span style={{ fontWeight: 700 }}>Straddle OI:&nbsp;</span> Visualise the strike wise Total OI of a Straddle (Call OI + Put OI) at a given date/time.
            <br/><br/><span style={{ fontWeight: 700 }}>Volume - Split:&nbsp;</span> Visualise the strike wise Volumes of Put and Call Options individually at a given date/time.
            <br/><br/><span style={{ fontWeight: 700 }}>OI Pain Value:&nbsp;</span> Visualise the strike wise OI based Option Pain value at a given date/time.
            <br/><br/><br/>

            <Typography id="modal-modal-description" sx={{fontWeight: 600 }} variant="h6">
              Inventory Visualization
            </Typography>
            <br/>These charts are curated using our highly researched propriety concepts of Options inventory visualization. 
            <br/>They can add another dimension to your Options Inventory analysis as they combine both the Open Interest along with different Greeks and IV values of individual option strikes.
            <br/>These help you visualise not just how much OI is there, but with what confidence the writers and buyers are positioned. 
            <br/><br/>Sensitivity value is a dynamic feature in the iViz section.
            <br/>It is a leading indicator of the open interest of the market and shows the direction of change.
            <br/>Sensitivity value is expressed as a histogram of calls and puts for a specific expiry date.
            <br/>It is shown for every strike
            <br/>The sensitivity value that can be compared to the open interest change chart to see the strength of resistance or support levels.
            <br/>Both sensitivity value and open interest change charts can be viewed on one screen using the Market profile chart 2 window option.
            <br/>
            <br/>Inventory value expresses the open positions of the market in a rupee number ( dollar denominated value) 
            <br/>The inventory value can change with a change in the underlying and it tells us how many contracts are needed to adjust for the change
            <br/>High inventory values serve as a "pin point" and keep the market attracted or glued to it
            <br/>This is extremely useful for settlement days
            <br/>The split option within the Inventory value can show where the market is adjusting to new movements of the underlying
            <br/>
            <br/>Use both SV and IV in conjunction with the Option Spectrum charts. To summarize the above:
            <br/><br/><span style={{ fontWeight: 600 }}>Inventory Value:&nbsp;</span> This value represents the rupee notional value of the Open Interest that is there in that strike for options writers.
            <br/><span style={{ fontWeight: 600 }}>Sensitivity Value:&nbsp;</span> This value corresponds to a price sensitivity measure of the OI built-up at a given strike.
            <br/><span style={{ fontWeight: 600 }}>Writers' Unrealized Cash:&nbsp;</span> This is the most intuitive one - it represents the total options premium in Rs. that is available to all the writers of the options of that strike at that given instant.
            <br/><br/>The different chart visualizations that are available here:
            <br/><br/><span style={{ fontWeight: 700 }}>Inventory Value - Sum:&nbsp;</span> Visualise the strike wise Total Inventory values (Call value + Put value) at a given date/time.
            <br/><br/><span style={{ fontWeight: 700 }}>Inventory Value - Split:&nbsp;</span> Visualise the strike wise Inventory values of Put and Call Options individually at a given date/time.
            <br/><br/><span style={{ fontWeight: 700 }}>Sensitivity Value - Sum:&nbsp;</span> Visualise the strike wise Total Sensitivity values (Call value + Put value) at a given date/time.
            <br/><br/><span style={{ fontWeight: 700 }}>Sensitivity Value - Split:&nbsp;</span> Visualise the strike wise Sensitivity values of Put and Call Options individually at a given date/time.
            <br/><br/><span style={{ fontWeight: 700 }}>Writers' Unrealized Cash - Split:&nbsp;</span>  Visualise the strike wise unrealised cash value with Writers of Put and Call Options individually at a given date/time.

          </Typography>
          <br/>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            IV Curve Charts
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <img src={ivcurveImg} alt="IV Curves" style={imgStyle} /><br/>
            Plotting Implied Volatility (IV) curves for each strike can be a useful tool for options traders looking to gain insights into market dynamics and make informed trading decisions. IV is a measure of the expected volatility of an underlying asset, as implied by the prices of its options.
            <br/>By plotting IV curves for each strike on a chart, traders can observe how the level of expected volatility changes as the strike price of an option varies. Typically, IV tends to be higher for options that are at-the-money (ATM), as there is a greater degree of uncertainty around where the underlying asset price will settle at expiration. As the strike price moves further away from the ATM point, IV tends to decrease.
            <br/>By analyzing IV curves, traders can gain insights into market expectations regarding potential price movements of the underlying asset. If the IV curve is flat or has a U-shape, it suggests that the market expects the asset price to remain relatively stable. Conversely, if the IV curve is sloping upwards, it suggests that the market expects the asset price to experience greater volatility in the near future.
            <br/>Furthermore, by comparing IV curves across different expiration dates, traders can gain insights into the impact of time on option prices. As expiration approaches, the level of expected volatility typically increases, which can be reflected in the shape of the IV curve.
            <br/>Overall, plotting IV curves for each strike can provide valuable insights into market expectations and help traders make informed trading decisions. By analyzing changes in IV over time and comparing IV curves across different strikes and expiration dates, traders can identify potential opportunities and risks in the market and adjust their strategies accordingly.
          </Typography>
          <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 4, fontWeight: 600 }} variant="h5">
            Options Snakes Line Charts
          </Typography>
          <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <img src={oisnakesImg} alt="Options Snakes" style={imgStyle} /><br/>
            Plotting the net Open Interest (OI) across strikes can be a powerful tool for options traders looking to identify market trends and make informed trading decisions. Net OI refers to the total number of outstanding contracts across all strike prices or at any single strike for a particular option.
            <br/>By plotting net straddle OI strike wise on a chart, traders can observe how the level of interest in a particular option is changing over time
          </Typography>
          <br/><br/><br/><br/>
        </Box>
      </MainLayoutContainer>
        // <Grid component="main" container display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{height: `calc(100vh - 64px)` }}>
            
        //     <h1>Hello!</h1>
        //     <p>This page is currently under construction.</p>
        //     {/* <div className="flexGrow">
        //         <Link href="/" variant="link">Go Back to Homepage</Link>
        //     </div> */}
        // </Grid>
    )
}

export default IntroManual
