import { Rnd } from "react-rnd";
import React,{useCallback,useEffect, useState} from "react";
import { Box,Tabs,Tab,Divider,Tooltip ,Typography,Container} from '@mui/material';
import {useTheme} from "@mui/material";
import { RIGHT_PANEL_CONTAINER_WIDTH,CHART_COLORS, INFO_PANEL_VIEWS, CANDLE_BOTTOM_VIEW_TYPE } from '../../../common/utility/mp_chart_constants';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TABLE_COLORS } from '../../../common/utility/table_constants';
import ReactSpeedometer from "react-d3-speedometer"
import { number } from "prop-types";
import { getInfoPanelViewName } from "../../../common/utility/helperFunc";
import MinimizeIcon from '@mui/icons-material/ExpandLessOutlined';
import MaximizeIcon from '@mui/icons-material/ExpandMoreOutlined';

const style = {
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 0.5px #285fbd",
    background: "#0d203fdd",
    zIndex:9999,
    borderRadius:5
  };

const WIDTH=440;
const HEIGHT=64;
const COLLAPSED_WIDTH=130;
const COLLAPSED_HEIGHT=24;

  const LCLiveModal = (props) => {
    const [infoPanelView,setInfoPanelView]=useState(props.livePanelState?props.livePanelState:props.userSettings.data[getInfoPanelViewName(props.plot)]?props.userSettings.data[getInfoPanelViewName(props.plot)]:INFO_PANEL_VIEWS.EXPANDED);
    const [expanded,setExpanded]=useState(infoPanelView==INFO_PANEL_VIEWS.EXPANDED?true:false)
    const [width,setWidth]=useState(!expanded?COLLAPSED_WIDTH:(props.plot=="opt"|| props.plot=="spt")? WIDTH+30+(props.isAdmin &&props.plot=="spt"?120:0):props.plot=="candle"?WIDTH+90:WIDTH+20);
    const [height,setHeight]=useState(!expanded?COLLAPSED_HEIGHT:(props.plot=="spt"|| (props.plot=="candle")?HEIGHT+24:props.plot=="all_doix"?HEIGHT+24:HEIGHT));
    const [panelView1, setPanelView1] = useState(props.userSettings.data.candle_map && props.userSettings.data.candle_map[props.tpo_group] && props.userSettings.data.candle_map[props.tpo_group]?.panel1_bottom_col ? props.userSettings.data.candle_map[props.tpo_group].panel1_bottom_col : (props.isOPT ? CANDLE_BOTTOM_VIEW_TYPE.VOLUME : CANDLE_BOTTOM_VIEW_TYPE.VOLUME))
    const [panelView2, setPanelView2] = useState(props.userSettings.data.candle_map && props.userSettings.data.candle_map[props.tpo_group] && props.userSettings.data.candle_map[props.tpo_group]?.panel2_bottom_col ? props.userSettings.data.candle_map[props.tpo_group].panel2_bottom_col : (props.isOPT ? CANDLE_BOTTOM_VIEW_TYPE.DOI : CANDLE_BOTTOM_VIEW_TYPE.COT))
  
    const theme=useTheme();  
    // const []
    const [xCord,setXCord]=useState(2);
    const [yCord,setYCord]=useState(26);
    // const [xCord,setXCord]=useState(20);
    // const [yCord,setYCord]=useState(24);
    const [visibilityMatrix,setVisibilityMatrix]=useState(props.visibilityMatrix);

    useEffect(()=>{
        // console.log("LCLiveModal props=",props.boundClassName,props.pro2User,(props.plot=="candle"&& props.pro2User))
        // console.log("LCLivepanel props=",props,infoPanelView,expanded);
    },[props.data])


    useEffect(()=>{
      console.log("LCLIVE MODAL usersettings= before",props.userSettings.data[getInfoPanelViewName(props.plot)],props.userSettings.data,infoPanelView,expanded,width,height)
      if(props.livePanelState==undefined && props.userSettings.data[getInfoPanelViewName(props.plot)] && infoPanelView!=(props.userSettings.data[getInfoPanelViewName(props.plot)])){
        setInfoPanelView(props.userSettings.data[getInfoPanelViewName(props.plot)]);
        setExpanded(!expanded)
      }else if(props.livePanelState && infoPanelView!=props.livePanelState){
        setInfoPanelView(props.livePanelState)
        setExpanded(!expanded)
      }
      setPanelView1(props.userSettings.data.candle_map && props.userSettings.data.candle_map[props.tpo_group] && props.userSettings.data.candle_map[props.tpo_group]?.panel1_bottom_col ? props.userSettings.data.candle_map[props.tpo_group].panel1_bottom_col : (props.isOPT ? CANDLE_BOTTOM_VIEW_TYPE.VOLUME : CANDLE_BOTTOM_VIEW_TYPE.VOLUME))
      setPanelView2(props.userSettings.data.candle_map && props.userSettings.data.candle_map[props.tpo_group] && props.userSettings.data.candle_map[props.tpo_group]?.panel2_bottom_col ? props.userSettings.data.candle_map[props.tpo_group].panel2_bottom_col : (props.isOPT ? CANDLE_BOTTOM_VIEW_TYPE.DOI : CANDLE_BOTTOM_VIEW_TYPE.COT))
     
     console.log("LCLIVE MODAL usersettings=",props.userSettings.data,infoPanelView,expanded,width,height)
  },[props.userSettings,props.livePanelState])

    const toggleView = () => {
      console.log("LCLIVE MODAL usersettings= toggleView",props.userSettings,infoPanelView,expanded,width,height)
      let temp=!expanded;
      setExpanded(!expanded)
    };
  
    useEffect(()=>{
      setWidth(!expanded?COLLAPSED_WIDTH:(props.plot=="opt"|| props.plot=="spt")? WIDTH+30+(props.isAdmin && props.plot=="spt"?120:0):props.plot=="candle"?WIDTH+90:WIDTH+20);
      setHeight(!expanded?COLLAPSED_HEIGHT:(props.plot=="spt"|| (props.plot=="candle")?HEIGHT+24:props.plot=="all_doix"?HEIGHT+24:HEIGHT));
   
    },[expanded])
 
 
    const handleClose = () => {
      console.log("CandleLiveModal handleClose");
      props.closeModal();
      // setOpen(false)
    };
   
  
        
    const handleClick = (key) => {
      // // event.stopPropagation();
      // console.log('Box clicked!',key);
      // if (visibilityMatrix.hasOwnProperty(key)) {
      //   props.visibilityMatrix[key] = !props.visibilityMatrix[key];
      // }
      // props.changeVisibilityMatrix(props)

      setVisibilityMatrix(prevMatrix => ({
        ...prevMatrix,
        [key]: !prevMatrix[key]
      }));

    };
  
   useEffect(()=>{
    console.log("Visibility Matrix=",visibilityMatrix);
    props.changeVisibilityMatrix(visibilityMatrix);
   },[visibilityMatrix])
   
    
    return (
      <>
    <Rnd
        style={style}
        resizeHandleStyles={{
          topRight:{cursor: 'default'},
          bottomRight:{cursor: 'default'},
          topLeft:{cursor: 'default'},
          bottomLeft:{cursor: 'default'},
          right:{cursor: 'default'},
          left:{cursor: 'default'},
          // top: {cursor: 'default'},
          // bottom:{cursor: 'default'},

        }}
        size={{ width: width, height: height }}
        position={{ x: xCord, y: yCord }}
        onDragStop={(e, d) => {
        //   this.setState({ xCord: d.x, yCord: d.y });
        setXCord(d.x);
        setYCord(d.y);
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
        //   this.setState({
        //     width: ref.style.width,
        //     height: ref.style.height,
        //     ...position
        //   });
        // setWidth(width);
        console.log("width=",ref.style.width)
        setWidth(ref.style.width);
        setHeight(ref.style.height);
        setXCord(position.x);
        // setYCord(position.y);

        }}
        // minWidth={props.metaData.speedo&& props.chartWidth>490? 480:260}
        minWidth={width}
        maxWidth={width}
        // maxWidth={props.metaData.speedo&& props.chartWidth>490? 480:260}
        minHeight={height}
        maxHeight={height}
        bounds={props.boundClassName}
        cancel=".cancel"
      >
          {expanded?
          
          <></>
          :
          <div className="cancel" >
             <Box display={'flex'} flexDirection={'row'}>
            <Typography sx={{ml:1,mt:0.2,fontSize:14}}>Info Panel</Typography>
          <MaximizeIcon
          onClick={() => toggleView()}
            sx={{
              ml: 2, mt: 0.2, background: "#285fbd", opacity: 0.9,
            
              height: 20, width: 22, cursor: 'pointer', zIndex: 99999
            }}
          />
          </Box>
          </div>
        }
          {expanded && props.data?
          <>
<Box sx={{display:"flex",flexDirection:'column',paddingRight:"4px",width:"100%",height:"100%",overflowY:'auto',overflowX:'hidden'}}> 
<Box sx={{display:"flex",flexDirection:'column',pt:1,minWidth:"400px"}}>

            
            <Box sx={{display:"flex",marginTop:"0px",ml:1}}> 
              <Box sx={{display:"flex",width:"100%"}}>
              <Typography sx={{fontSize:12,ml:0,mt:0.3,mr:0,minWidth:"16px",fontWeight:500}}>
              Time:&nbsp;</Typography>
              <Typography sx={{fontSize:14,ml:0,mr:1,fontWeight:600}}>
                {props?.data?.time?props?.data?.time:"NA"}
              </Typography>

              <Typography sx={{fontSize:12,ml:0,mt:0.3,minWidth:"16px",fontWeight:500}}>
              O:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:props?.data?.open>props?.data?.close?CHART_COLORS.CANDLE_RED:CHART_COLORS.CANDLE_GREEN}}>
              {props?.data?.open?props.data.open.toFixed(2):"NA"}
              </Typography>

              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500}}>
              H:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:props?.data?.open>props?.data?.close?CHART_COLORS.CANDLE_RED:CHART_COLORS.CANDLE_GREEN}}>
              {props?.data?.high?props.data.highVal.toFixed(2):"NA"}
              </Typography>

              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500}}>
              L:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:props?.data?.open>props?.data?.close?CHART_COLORS.CANDLE_RED:CHART_COLORS.CANDLE_GREEN}}>
              {props?.data?.low?props.data.lowVal.toFixed(2):"NA"}
              </Typography>

              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500}}>
              C:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:props?.data?.open>props?.data?.close?CHART_COLORS.CANDLE_RED:CHART_COLORS.CANDLE_GREEN}}>
              {props?.data?.close?props.data.close.toFixed(2):"NA"}
              </Typography>

              
               
              </Box>
              <div className="cancel" >
            <MinimizeIcon
             onClick={() => toggleView()}
             sx={{
               ml: 1, mt: -1, background: "#285fbd", opacity: 0.9,
            
               height: 20, width: 22, cursor: 'pointer', zIndex: 99999
             }}
           />
           </div>
             
            </Box>
            {props.plot=="opt" || props.plot=="fut"?  
            <Box sx={{display:"flex",marginTop:"2px",ml:1}}> 
            
              <Box  sx={{display:"flex",width:"100%"}}>
             <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("vol")}>
              <Typography sx={{fontSize:12,ml:0,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["vol"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              Volume:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["vol"]==false?CHART_COLORS.MODAL_TEXT_DISABLED: CHART_COLORS.LC_VOL_BAR}}>
              {props?.data?.vol!=null?props.data.vol:"NA"}
              </Typography>
              </Box>

              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("vpoc")}>
              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["vpoc"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              VPOC:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["vpoc"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.CANDLE_VPOC}}>
              {props?.data?.cumu_vpoc?props.data.cumu_vpoc.toFixed(2):"NA"}
              </Typography>
              </Box>

              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("vwap")}>  
              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["vwap"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              VWAP:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["vwap"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.VWAP}}>
              {props?.data?.cumu_vwap?props.data.cumu_vwap.toFixed(2):"NA"}
              </Typography>
              </Box>

              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("oi")}>  
            
              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["oi"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              OI:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["oi"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_OI}}>
              {props?.data?.oi?props.data.oi:"NA"}
              </Typography>
              </Box>

              {props.plot=="opt"?
              <>
               <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("doi")}>  
              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["doi"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              dOI:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["doi"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:props?.data?.doi>=0?CHART_COLORS.LC_OPT_DOI_BAR_GREEN:CHART_COLORS.LC_OPT_DOI_BAR_RED}}>
              {props?.data?.doi!=null?props.data.doi:"NA"}
              </Typography>
              </Box>

              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("iv")}>  
              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["iv"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              IV:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["iv"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_OPT_IV}}>
              {props?.data?.iv!=null?props.data.iv.toFixed(2):"NA"}
              </Typography>
              </Box>
              </>
              

              :
              <></>
            }

             
              
              </Box>
             
            </Box>:
            <></>
          }  
          

        {props.plot=="spt" || props.plot=="all_doix"?  
            <Box sx={{display:"flex",marginTop:"2px",ml:1}}> 
              <Box sx={{display:"flex",width:"100%"}}>

              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("gwall_high")}>  
                <Typography sx={{fontSize:12,ml:0,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["gwall_high"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
                 CE Wall:&nbsp;
                </Typography>
                <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["gwall_high"]==false?CHART_COLORS.MODAL_TEXT_DISABLED: CHART_COLORS.LC_SPT_GWALL_HIGH}}>
                {props?.data?.gwall_high?props.data.gwall_high.toFixed(2):"NA"}
                </Typography>
                </Box>

                <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("gwall_low")}>  
                <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["gwall_low"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
                PE Wall:&nbsp;
                </Typography>
                <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["gwall_low"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_SPT_GWALL_LOW}}>
                {props?.data?.gwall_low?props.data.gwall_low.toFixed(2):"NA"}
                </Typography>
                </Box>

                <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("gzero")}>  
                <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["gzero"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
                Gamma Ref:&nbsp;
                </Typography>
                <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["gzero"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_SPT_GZERO}}>
                {props?.data?.gzero?props.data.gzero.toFixed(2):"NA"}
                </Typography>
                </Box>
             </Box>
             </Box>
             :
             <></>
            }

          {props.plot=="spt" || props.plot=="all_doix"?  
            <Box sx={{display:"flex",marginTop:"2px",ml:1}}> 
                <Box sx={{display:"flex",width:"100%"}}>

                <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("mviz_high")}>  
              <Typography sx={{fontSize:12,ml:0,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["mviz_high"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              mViz High:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["mviz_high"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_SPT_UPPER_MVIZ}}>
              {props?.data?.upper_mviz?props.data.upper_mviz.toFixed(2):"NA"}
              </Typography>
              </Box>

              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("mviz_low")}>  
              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["mviz_low"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              mViz Low:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["mviz_low"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_SPT_LOWER_MVIZ}}>
              {props?.data?.lower_mviz?props.data.lower_mviz.toFixed(2):"NA"}
              </Typography>
              </Box>

              {props.plot=="spt"?
              <>
              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("prem")}>  
              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["prem"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              Prem:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["prem"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_SPT_PREM_BAR}}>
              {props?.data?.undy_prem?props.data.undy_prem.toFixed(2):"NA"}
              </Typography>
              </Box>
              </>:
              <></>
              }

            {props.plot=="spt" && props.isAdmin?
              <>
              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("inv_bias")}>  
              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["inv_bias"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              Inv_B:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["inv_bias"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:props?.data?.inv_bias>=0?CHART_COLORS.CANDLE_BAR_POSITIVE:CHART_COLORS.CANDLE_BAR_NEGATIVE}}>
              {props?.data?.inv_bias?props.data.inv_bias:"NA"}
              </Typography>
              </Box>

              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("mviz_level")}>  
              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["mviz_level"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              mViz Level:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["mviz_level"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_SPT_MVIZ_LEVEL}}>
              {props?.data?.mviz_level?props.data.mviz_level.toFixed(2):"NA"}
              </Typography>
              </Box>
              </>:
              <></>
              }
              {props.plot=="all_doix"?
              <>
              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("net_doix_change")}>  
              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["net_doix_change"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              Net NTM dOI:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["net_doix_change"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:props?.data?.net_doix_change>=0?CHART_COLORS.CANDLE_BAR_POSITIVE:CHART_COLORS.CANDLE_BAR_NEGATIVE}}>
              
   
              {props?.data?.net_doix_change?props.data.net_doix_change.toFixed(2):"NA"}
              </Typography>
              </Box>
              </>:
              <></>
              }
                </Box>

            

            </Box>
            :
            <></>
           }
           
           {/* {props.plot=="all_doix"?  
            <Box sx={{display:"flex",marginTop:"4px",ml:1}}> 
              <Box sx={{display:"flex",width:"100%"}}>
                <Typography sx={{fontSize:14,ml:0,minWidth:"16px",fontWeight:500}}>
                 Call DOIX:&nbsp;
                </Typography>
                <Typography sx={{fontSize:14,ml:0,fontWeight:600}}>
                {props?.data?.call_doix?props.data.call_doix.toFixed(2):"NA"}
                </Typography>

                <Typography sx={{fontSize:14,ml:1,minWidth:"16px",fontWeight:500}}>
                Put DOIX:&nbsp;
                </Typography>
                <Typography sx={{fontSize:14,ml:0,fontWeight:600}}>
                {props?.data?.put_doix?props.data.put_doix.toFixed(2):"NA"}
                </Typography>


                <Typography sx={{fontSize:14,ml:1,minWidth:"16px",fontWeight:500}}>
                Net DOIX:&nbsp;
                </Typography>
                <Typography sx={{fontSize:14,ml:0,fontWeight:600}}>
                {props?.data?.net_doix?props.data.net_doix.toFixed(2):"NA"}
                </Typography>
             </Box>
             </Box>
             :
             <></>
            } */}

        {props.plot=="all_doi"?  
            <Box sx={{display:"flex",marginTop:"2px",ml:1}}> 
              <Box sx={{display:"flex",width:"100%"}}>

              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("call_oi")}>  
                <Typography sx={{fontSize:12,ml:0,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["call_oi"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
                 Call OI:&nbsp;
                </Typography>
                <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["call_oi"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_DOI_CALL}}>
                {props?.data?.call_doi?props.data.call_doi:"NA"}
                </Typography>
                </Box>

                <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("put_oi")}>  
                <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["put_oi"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
                Put OI:&nbsp;
                </Typography>
                <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["put_oi"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_DOI_PUT}}>
                {props?.data?.put_doi?props.data.put_doi:"NA"}
                </Typography>
                </Box>


                <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("net_oi")}>  
                <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["net_oi"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
                Net OI:&nbsp;
                </Typography>
                <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["net_oi"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_DOI_NET}}>
                {props?.data?.net_doi?props.data.net_doi:"NA"}
                </Typography>
                </Box>
             </Box>
             </Box>
             :
             <></>
            }
 {props.plot=="candle"?  
            <Box sx={{display:"flex",ml:1}}> 
              <Box sx={{display:"flex",width:"100%"}}>
              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("vol")}>
              <Typography sx={{fontSize:12,ml:0,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["vol"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              Volume:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["vol"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.MODAL_TEXT_ENABLED}}>
              {props?.data?.vol?props.data.vol:"NA"}
              </Typography>
              </Box>

              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("doi")}>
              <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["doi"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              DOI:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["doi"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:props?.data?.doi>=0?CHART_COLORS.CANDLE_BAR_POSITIVE:CHART_COLORS.CANDLE_BAR_NEGATIVE}}>
              {props?.data?.doi!=undefined?props.data.doi:"NA"}
              </Typography>
              </Box>

              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("vpoc")}>
             
             <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["vpoc"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
             VPOC:&nbsp;
             </Typography>
             <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["vpoc"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.CANDLE_VPOC}}>
             {props?.data?.vpoc?props.data.vpoc.toFixed(2):"NA"}
             </Typography>

           </Box>

           <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("vwap")}>
            
            <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["vwap"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
            VWAP:&nbsp;
            </Typography>
            <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["vwap"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.VWAP}}>
            {props?.data?.vpoc?props.data.vwap.toFixed(2):"NA"}
            </Typography>

          </Box>

              {/* <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("vpoc")}>
             
              <Typography sx={{fontSize:14,ml:1,minWidth:"16px",fontWeight:500,color:visibilityMatrix["vpoc"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              VPOC:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["vpoc"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.VPOC}}>
              {props?.data?.vpoc?props.data.vpoc.toFixed(2):"NA"}
              </Typography>

            </Box>

            <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("vwap")}>
             
             <Typography sx={{fontSize:14,ml:1,minWidth:"16px",fontWeight:500,color:visibilityMatrix["vwap"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
             VWAP:&nbsp;
             </Typography>
             <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["vwap"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.VWAP}}>
             {props?.data?.vpoc?props.data.vwap.toFixed(2):"NA"}
             </Typography>

           </Box> */}

              {/* <Typography sx={{fontSize:14,ml:1,minWidth:"16px",fontWeight:500}}>
              VPOC VOL:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600}}>
              {props?.data?.vpoc_vol?props.data.vpoc_vol:"NA"}
              </Typography> */}
              
              {/* <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("rolling_cot")}>
              <Typography sx={{fontSize:14,ml:1,minWidth:"16px",fontWeight:500,color:visibilityMatrix["rolling_cot"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              10 Bar COT:&nbsp;
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["rolling_cot"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:props?.data?.rolling_cot>=0?CHART_COLORS.CANDLE_BAR_POSITIVE:CHART_COLORS.CANDLE_BAR_NEGATIVE}}>
              {props?.data?.rolling_cot?props.data.rolling_cot:"NA"}
              {props?.lastRollingCot?props.lastRollingCot:"NA"}
              </Typography>
              </Box> */}
 <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("oi")}>  
            
            <Typography sx={{fontSize:12,ml:1,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["oi"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
            OI:&nbsp;
            </Typography>
            <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["oi"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.LC_OI}}>
            {props?.data?.oi?props.data.oi:"NA"}
            </Typography>
            </Box>

              
              </Box>
             
            </Box>  :
            <></>
              }

{props.plot=="candle"?  
      <Box sx={{display:"flex",marginTop:"2px",ml:1,mr:1}}> 
            <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("ib_high")}>
              <Typography sx={{fontSize:12,ml:0,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["ib_high"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              {/* {props?.userSettings?.data?.rotating_cot_candle} Bar COT:&nbsp; */}
               IBH:&nbsp;
              
              </Typography>
              <Typography sx={{fontSize:14,ml:0,mr:1,fontWeight:600,color:visibilityMatrix["ib_high"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.CC_IB_LINE}}>
              {props?.mdata?.ib_high?props.mdata.ib_high.toFixed(2):"NA"}
              </Typography>

              
              </Box>

              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("ib_low")}>
              <Typography sx={{fontSize:12,ml:0,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["ib_low"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              {/* {props?.userSettings?.data?.rotating_cot_candle} Bar COT:&nbsp; */}
               IBL:&nbsp;
              
              </Typography>
              <Typography sx={{fontSize:14,ml:0,mr:1,fontWeight:600,color:visibilityMatrix["ib_low"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:CHART_COLORS.CC_IB_LINE}}>
              {props?.mdata?.ib_low?props.mdata.ib_low.toFixed(2):"NA"}
              </Typography>

              
              </Box>
              {props.isCotPPresent?
        <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("cot")}>
            
            <Typography sx={{fontSize:12,ml:0,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["cot"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
            COT:&nbsp;
            </Typography>
            <Typography sx={{fontSize:14,ml:0,mr:1,fontWeight:600,color:visibilityMatrix["cot"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:props?.data?.cot>=0?CHART_COLORS.CANDLE_BAR_POSITIVE:CHART_COLORS.CANDLE_BAR_NEGATIVE}}>
            {props?.data?.cot?props.data.cot:"NA"}
            </Typography>
            </Box>:
            <></>
              }

    {(props.isCotPPresent &&(panelView1==CANDLE_BOTTOM_VIEW_TYPE.COT || panelView2==CANDLE_BOTTOM_VIEW_TYPE.COT))?
              <Box sx={{display:"flex",width:"100%"}}>
             { panelView1==CANDLE_BOTTOM_VIEW_TYPE.COT?
              <Box sx={{display:"flex",cursor:'pointer'}} onClick={()=>handleClick("rolling_cot")}>
              <Typography sx={{fontSize:12,ml:0,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["rolling_cot"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              {/* {props?.userSettings?.data?.rotating_cot_candle} Bar COT:&nbsp; */}
              {props?.cotCount} Bar COT:&nbsp;
              
              </Typography>
              <Typography sx={{fontSize:14,ml:0,mr:1,fontWeight:600,color:visibilityMatrix["rolling_cot"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:props?.lastRollingCot>=0?CHART_COLORS.CANDLE_BAR_POSITIVE:CHART_COLORS.CANDLE_BAR_NEGATIVE}}>
              {props?.lastRollingCot?props.lastRollingCot:"NA"}
              </Typography>

              
              </Box>
              :
              <></>
             }

      { props.isCotPPresent && panelView2==CANDLE_BOTTOM_VIEW_TYPE.COT?
              <Box sx={{display:"flex",cursor:'pointer',ml:0}} onClick={()=>handleClick("rolling_cot2")}>
              <Typography sx={{fontSize:12,ml:0,mt:0.3,minWidth:"16px",fontWeight:500,color:visibilityMatrix["rolling_cot2"]?CHART_COLORS.MODAL_TEXT_ENABLED:CHART_COLORS.MODAL_TEXT_DISABLED}}>
              {/* {props?.userSettings?.data?.rotating_cot_candle} Bar COT:&nbsp; */}
              {props?.cotCount2} Bar COT:&nbsp;
              
              </Typography>
              <Typography sx={{fontSize:14,ml:0,fontWeight:600,color:visibilityMatrix["rolling_cot2"]==false?CHART_COLORS.MODAL_TEXT_DISABLED:props?.lastRollingCot>=0?CHART_COLORS.CANDLE_BAR_POSITIVE:CHART_COLORS.CANDLE_BAR_NEGATIVE}}>
              {props?.lastRollingCot2?props.lastRollingCot2:"NA"}
              </Typography>

              
              </Box>
            :
            <></>
            }
         
          </Box>:<></>}
          </Box>:
          <></>
}


           
            

             
 

           
            </Box>

            
        </Box>
        </>:<></>
  }
      </Rnd>
        
      </>
    );
  };
  
  export default LCLiveModal;
  