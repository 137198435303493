import React from 'react'
import {Grid,Typography,Box} from '@mui/material'
import BackgroundImage from "../../../../assets/images/welcome-banner.png";
import BackgroundImageMobile from "../../../../assets/images/welcome-banner-mobile.png";

/**
 * 
 * @returns The left component for welcome layout to be used in all the screens of login.register flow
 */
const WelcomeLayout = () => {
  return (
   
        <Grid
                item
                mobile={false}
                tablet={4}
                laptop={5}
              
                sx={{
                  mt:8,
                  // width:{mobile:"100%"},
                  backgroundImage: {laptop:`url(${BackgroundImage})`,tablet:`url(${BackgroundImage})`},
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'left',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  textAlign:"center"
                
                }}
              >
      
                <Box                   
                  sx={{mt: 4,mx: 4,mr: 4,mb:4,
                    
                    display:{mobile:'flex',tablet:'flex'},
                    flexDirection: 'column',
                  }}>
                    <Typography variant="titleMedium" align="left">Welcome to Vtrender</Typography>
                    <Typography variant="titleSmall" align="left">Your home for Orderflow, MarketProfile and the daily 50,000 Cr Options market. All in 1 place.
                    </Typography>
                </Box> 
                
                </Grid>
      
      );
}

export default WelcomeLayout
