import {Routes as ReactRoutes, Route, Navigate } from "react-router-dom";
import React,{useEffect} from "react";

import RequireAuth from "./RequireAuth";
import Login from "../../pages/login-register/login";
import MarketProfile from "../../pages/dashboard";
import Home from "../../pages/dashboard";
import MarketProfileCharts from "../../pages/dashboard/market_profile";
import Unauthorized from "../../components/Unauthorized";
import PersistLogin from "../../components/PersistLogin";
import Missing from "../../components/Missing";
import Register from '../../pages/login-register/register';
import FORGOT_PASSWORD from "../../pages/login-register/forgot-password/forgot_password";
import FORGOT_PASSWORD_VERIFICATION from "../../pages/login-register/forgot-password/forgot_password_verfication";
import RESET_PASSWORD from "../../pages/login-register/forgot-password/reset-password"; 
import EMAIL_VERIFICATION from "../../pages/login-register/register/email_verification"; 
import ACTIVATE_USER from "../../pages/login-register/register/activate_user";
import LineChart from "../../pages/dashboard/line_chart";
import BarChart from "../../pages/dashboard/bar_chart";
import StockChart from "../../pages/dashboard/stock_chart";
import StockChartHV from "../../pages/dashboard/stock_chart_hv";
import Settings from "../../pages/dashboard/settings";
import Bookmarks from "../../pages/dashboard/bookmarks";
import Notifications from "../../pages/dashboard/notifications";
import UserProfile from "../../pages/dashboard/user_profile";
import OPTIONS_TABLE from "../../pages/dashboard/options_table";
import HelpPage from "../../pages/dashboard/help_page";
import { ALLOWED_ROLES_ADMIN,ALLOWED_ROLES_ALL,ALLOWED_ROLES_PRO_1,ALLOWED_ROLES_PRO_2,ALLOWED_ROLES_FAST_DATA, ALLOWED_ROLES_ORDERFLOW, USER_RESOURCES_LIST } from "../../common/utility/constant";
import UserManualPage from "../../pages/dashboard/user_manual";
import GREEKS_TABLE from "../../pages/dashboard/greeks_table";
import FII_DATA from "../../pages/dashboard/fii";
import INDEX_TABLE from "../../pages/dashboard/index_table";
import FeedAdmin from "../../pages/dashboard/feed_admin";
import Subscription from "../../pages/dashboard/subscription";
import { NIFTY_SPOT_OIX_CHART_DATA,BANKNIFTY_SPOT_OIX_CHART_DATA,FINNIFTY_SPOT_OIX_CHART_DATA,MIDCPNIFTY_SPOT_OIX_CHART_DATA,NIFTY_ALLOPT_DOI_CHART_DATA,BANKNIFTY_ALLOPT_DOI_CHART_DATA,FINNIFTY_ALLOPT_DOI_CHART_DATA,MIDCPNIFTY_ALLOPT_DOI_CHART_DATA,
  NIFTY_ALLOPT_VOL_CHART_DATA,BANKNIFTY_ALLOPT_VOL_CHART_DATA,FINNIFTY_ALLOPT_VOL_CHART_DATA,MIDCPNIFTY_ALLOPT_VOL_CHART_DATA,NIFTY_ALLOPT_VOLX_CHART_DATA,BANKNIFTY_ALLOPT_VOLX_CHART_DATA,FINNIFTY_ALLOPT_VOLX_CHART_DATA,MIDCPNIFTY_ALLOPT_VOLX_CHART_DATA,NIFTY_ALLOPT_DOIX_CHART_DATA,
  NIFTY_ALLOPT_DVOLX_CHART_DATA,BANKNIFTY_ALLOPT_DVOLX_CHART_DATA,FINNIFTY_ALLOPT_DVOLX_CHART_DATA,MIDCPNIFTY_ALLOPT_DVOLX_CHART_DATA,
  BANKNIFTY_ALLOPT_DOIX_CHART_DATA,FINNIFTY_ALLOPT_DOIX_CHART_DATA,MIDCPNIFTY_ALLOPT_DOIX_CHART_DATA,NIFTY_FUTURES_OIX_CHART_DATA,BANKNIFTY_FUTURES_OIX_CHART_DATA,NIFTY_HV_CHART_DATA,BANKNIFTY_HV_CHART_DATA,
  NIFTY_GCASH_BAR_CHART_DATA,BANKNIFTY_GCASH_BAR_CHART_DATA,FINNIFTY_GCASH_BAR_CHART_DATA,MIDCPNIFTY_GCASH_BAR_CHART_DATA, NIFTY_WCASH_BAR_CHART_DATA,BANKNIFTY_WCASH_BAR_CHART_DATA,FINNIFTY_WCASH_BAR_CHART_DATA,MIDCPNIFTY_WCASH_BAR_CHART_DATA, 
  NIFTY_OI_BAR_CHART_DATA, BANKNIFTY_OI_BAR_CHART_DATA, FINNIFTY_OI_BAR_CHART_DATA, MIDCPNIFTY_OI_BAR_CHART_DATA, NIFTY_IV_CHART_DATA, BANKNIFTY_IV_CHART_DATA,NIFTY_SNAKE_CHART_DATA,BANKNIFTY_SNAKE_CHART_DATA,FINNIFTY_SNAKE_CHART_DATA,MIDCPNIFTY_SNAKE_CHART_DATA
   } from "../../common/utility/configData";
import UserAdmin from "../../pages/dashboard/user_admin";
import Pricing from "../../pages/dashboard/pricing";
import FaqPage from "../../pages/dashboard/faq";
import INTRO from "../../pages/login-register/intro";
import IntroPage from "../../pages/dashboard/intro";
import IntroManual from "../../pages/dashboard/intro_manual";
import OrderflowCharts from "../../pages/dashboard/orderflow";
import UserManualPageBase from "../../pages/dashboard/user_manual_base";
import ZerodhaHandler from "../../pages/dashboard/zerodha_handler";
import FyersHandler from "../../pages/dashboard/fyers_handler";
import LineChartMain from "../../pages/dashboard/LineChartMain";
import BarChartMain from "../../pages/dashboard/BarChartMain";
import CandlestickCharts from "../../pages/dashboard/candlestick_main";
import LineChartMainNew from "../../pages/dashboard/LineChartMainNew";
// import SubscriptionNew from "../../pages/dashboard/subscription_new";


/**
 * 
 * @returns component for the specific route w.r.tp the app base url
 */
const Routes = () => {

  useEffect(() => {
   console.log("pageview",window.location.pathname + window.location.search);
  }, [window.location.pathname,window.location.search]);

  return (
    <>
      <ReactRoutes>
       {/* public routes  */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/email-verification" element={<EMAIL_VERIFICATION/>}/>
      <Route path="/activate-user" element={<ACTIVATE_USER/>}/>
      <Route path="/forgot-password" element={<FORGOT_PASSWORD />} />
      <Route path="/forgot-password-verification" element={<FORGOT_PASSWORD_VERIFICATION />} />
      <Route path="/reset-password" element={<RESET_PASSWORD />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/intro" element={<INTRO />} />
      <Route path="/intro-manual" element={<IntroManual />} />
      <Route path="/info-manual" element={<UserManualPageBase/>}/>
      
      
      {/* <Route path="/help" element={<HelpPage />} /> */}
      
      
      {/* Protected routes accessible only when accessToken is present else redirect to login page */}
      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
        <Route path="/" element={(<Navigate replace to="/market-profile" />)} />
        </Route>
            
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/market-profile" element={<MarketProfileCharts />} />
        </Route> 
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ORDERFLOW}/>}>
          <Route path="/orderflow" element={<OrderflowCharts />} />
        </Route> 
        
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/zerodha-token" element={<ZerodhaHandler/>}/>
        </Route>
        
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/fyers-token" element={<FyersHandler/>}/>
        </Route>

        {/* use to handle line chart from tables
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
           <Route path="/line-chart/external" element={<LineChartMain id="lineChart" />} />
        </Route>  
         */}
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart" element={<LineChartMain/>} />
        </Route> 

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart" element={<LineChartMainNew/>} />
        </Route> 
       

        {/* <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2}/>}>
          <Route path="/candle" element={<CandlestickCharts/>} />
        </Route>  */}

    

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/line-chart/NF_Options_Strike_Spectrum_Charts" element={<LineChartMain id="lineChart" stateData={NIFTY_SPOT_OIX_CHART_DATA}/>} />
        </Route>
        

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/line-chart/BN_Options_Strike_Spectrum_Charts" element={<LineChartMain id="lineChart" stateData={BANKNIFTY_SPOT_OIX_CHART_DATA}/>} />
        </Route>
        

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/line-chart/FN_Options_Strike_Spectrum_Charts" element={<LineChartMain id="lineChart" stateData={FINNIFTY_SPOT_OIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/line-chart/MN_Options_Strike_Spectrum_Charts" element={<LineChartMain id="lineChart" stateData={MIDCPNIFTY_SPOT_OIX_CHART_DATA}/>} />
        </Route>

          {/* Options strike spectrum New charts on d3 */}
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/NF_Options_Strike_Spectrum_Charts" element={<LineChartMainNew id="lineChart" stateData={NIFTY_SPOT_OIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/BN_Options_Strike_Spectrum_Charts" element={<LineChartMainNew id="lineChart" stateData={BANKNIFTY_SPOT_OIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/FN_Options_Strike_Spectrum_Charts" element={<LineChartMainNew id="lineChart" stateData={FINNIFTY_SPOT_OIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/MN_Options_Strike_Spectrum_Charts" element={<LineChartMainNew id="lineChart" stateData={MIDCPNIFTY_SPOT_OIX_CHART_DATA}/>} />
        </Route>
          {/* END */}
       
         <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/NF_Options_OI_Change_Charts" element={<LineChartMain id="lineChart" stateData={NIFTY_ALLOPT_DOI_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/BN_Options_OI_Change_Charts" element={<LineChartMain id="lineChart" stateData={BANKNIFTY_ALLOPT_DOI_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/FN_Options_OI_Change_Charts" element={<LineChartMain id="lineChart" stateData={FINNIFTY_ALLOPT_DOI_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/MN_Options_OI_Change_Charts" element={<LineChartMain id="lineChart" stateData={MIDCPNIFTY_ALLOPT_DOI_CHART_DATA}/>} />
        </Route>

        {/* OI Change */}
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/NF_Options_OI_Change_Charts" element={<LineChartMainNew id="lineChart" stateData={NIFTY_ALLOPT_DOI_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/BN_Options_OI_Change_Charts" element={<LineChartMainNew id="lineChart" stateData={BANKNIFTY_ALLOPT_DOI_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/FN_Options_OI_Change_Charts" element={<LineChartMainNew id="lineChart" stateData={FINNIFTY_ALLOPT_DOI_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/MN_Options_OI_Change_Charts" element={<LineChartMainNew id="lineChart" stateData={MIDCPNIFTY_ALLOPT_DOI_CHART_DATA}/>} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/NF_Options_Vol_Charts" element={<LineChartMain id="lineChart" stateData={NIFTY_ALLOPT_VOL_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/BN_Options_Vol_Charts" element={<LineChartMain id="lineChart" stateData={BANKNIFTY_ALLOPT_VOL_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/FN_Options_Vol_Charts" element={<LineChartMain id="lineChart" stateData={FINNIFTY_ALLOPT_VOL_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/MN_Options_Vol_Charts" element={<LineChartMain id="lineChart" stateData={MIDCPNIFTY_ALLOPT_VOL_CHART_DATA}/>} />
        </Route>

         {/* D3 Options Vol charts    */}
         <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/NF_Options_Vol_Charts" element={<LineChartMainNew id="lineChart" stateData={NIFTY_ALLOPT_VOL_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/BN_Options_Vol_Charts" element={<LineChartMainNew id="lineChart" stateData={BANKNIFTY_ALLOPT_VOL_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/FN_Options_Vol_Charts" element={<LineChartMainNew id="lineChart" stateData={FINNIFTY_ALLOPT_VOL_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/MN_Options_Vol_Charts" element={<LineChartMainNew id="lineChart" stateData={MIDCPNIFTY_ALLOPT_VOL_CHART_DATA}/>} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/NF_Options_Ntm_Vol_Charts" element={<LineChartMain id="lineChart" stateData={NIFTY_ALLOPT_DVOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/BN_Options_Ntm_Vol_Charts" element={<LineChartMain id="lineChart" stateData={BANKNIFTY_ALLOPT_DVOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/FN_Options_Ntm_Vol_Charts" element={<LineChartMain id="lineChart" stateData={FINNIFTY_ALLOPT_DVOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/MN_Options_Ntm_Vol_Charts" element={<LineChartMain id="lineChart" stateData={MIDCPNIFTY_ALLOPT_DVOLX_CHART_DATA}/>} />
        </Route>

        {/* D3 Options NTM Vol charts    */}
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/NF_Options_Ntm_Vol_Charts" element={<LineChartMainNew id="lineChart" stateData={NIFTY_ALLOPT_DVOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/BN_Options_Ntm_Vol_Charts" element={<LineChartMainNew id="lineChart" stateData={BANKNIFTY_ALLOPT_DVOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/FN_Options_Ntm_Vol_Charts" element={<LineChartMainNew id="lineChart" stateData={FINNIFTY_ALLOPT_DVOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/MN_Options_Ntm_Vol_Charts" element={<LineChartMainNew id="lineChart" stateData={MIDCPNIFTY_ALLOPT_DVOLX_CHART_DATA}/>} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/NF_Options_Ntm_Volx_Charts" element={<LineChartMain id="lineChart" stateData={NIFTY_ALLOPT_VOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/BN_Options_Ntm_Volx_Charts" element={<LineChartMain id="lineChart" stateData={BANKNIFTY_ALLOPT_VOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/FN_Options_Ntm_Volx_Charts" element={<LineChartMain id="lineChart" stateData={FINNIFTY_ALLOPT_VOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/MN_Options_Ntm_Volx_Charts" element={<LineChartMain id="lineChart" stateData={MIDCPNIFTY_ALLOPT_VOLX_CHART_DATA}/>} />
        </Route>

      {/* D3 Options NTM Volx charts    */}
      <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/NF_Options_Ntm_Volx_Charts" element={<LineChartMainNew id="lineChart" stateData={NIFTY_ALLOPT_VOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/BN_Options_Ntm_Volx_Charts" element={<LineChartMainNew id="lineChart" stateData={BANKNIFTY_ALLOPT_VOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/FN_Options_Ntm_Volx_Charts" element={<LineChartMainNew id="lineChart" stateData={FINNIFTY_ALLOPT_VOLX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/MN_Options_Ntm_Volx_Charts" element={<LineChartMainNew id="lineChart" stateData={MIDCPNIFTY_ALLOPT_VOLX_CHART_DATA}/>} />
        </Route>

       
         <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/line-chart/NF_Options_NTM_OI_Change_Charts" element={<LineChartMain id="lineChart" stateData={NIFTY_ALLOPT_DOIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/line-chart/BN_Options_NTM_OI_Change_Charts" element={<LineChartMain id="lineChart" stateData={BANKNIFTY_ALLOPT_DOIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/line-chart/FN_Options_NTM_OI_Change_Charts" element={<LineChartMain id="lineChart" stateData={FINNIFTY_ALLOPT_DOIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/line-chart/MN_Options_NTM_OI_Change_Charts" element={<LineChartMain id="lineChart" stateData={MIDCPNIFTY_ALLOPT_DOIX_CHART_DATA}/>} />
        </Route>

        {/* NTM OI change chart new */}
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/NF_Options_NTM_OI_Change_Charts" element={<LineChartMainNew id="lineChart" stateData={NIFTY_ALLOPT_DOIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/BN_Options_NTM_OI_Change_Charts" element={<LineChartMainNew id="lineChart" stateData={BANKNIFTY_ALLOPT_DOIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/FN_Options_NTM_OI_Change_Charts" element={<LineChartMainNew id="lineChart" stateData={FINNIFTY_ALLOPT_DOIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/MN_Options_NTM_OI_Change_Charts" element={<LineChartMainNew id="lineChart" stateData={MIDCPNIFTY_ALLOPT_DOIX_CHART_DATA}/>} />
        </Route>

       

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2}/>}>
          <Route path="/line-chart/NF_Futures_MViz_Chart" element={<LineChartMain id="lineChart" stateData={NIFTY_FUTURES_OIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2}/>}>
          <Route path="/line-chart/BN_Futures_MViz_Chart" element={<LineChartMain id="lineChart" stateData={BANKNIFTY_FUTURES_OIX_CHART_DATA}/>} />
        </Route>

         {/* D3 Futures mViz cahrts  */}
         <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/NF_Futures_MViz_Chart" element={<LineChartMainNew id="lineChart" stateData={NIFTY_FUTURES_OIX_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/BN_Futures_MViz_Chart" element={<LineChartMainNew id="lineChart" stateData={BANKNIFTY_FUTURES_OIX_CHART_DATA}/>} />
        </Route>
        
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
            <Route path="/hv-stock-chart/NIFTY" element={<StockChartHV id="bnstockChartHV" stateData={NIFTY_HV_CHART_DATA} />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
            <Route path="/hv-stock-chart/BANKNIFTY" element={<StockChartHV id="bnstockChartHV" stateData={BANKNIFTY_HV_CHART_DATA} />} />
        </Route>

        


    
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/bar-chart/NIFTY_Strike_Value" element={<BarChartMain id="nfbarChartSv" stateData={NIFTY_GCASH_BAR_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/bar-chart/BANKNIFTY_Strike_Value" element={<BarChartMain id="bnbarChartSv" stateData={BANKNIFTY_GCASH_BAR_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/bar-chart/FINNIFTY_Strike_Value" element={<BarChartMain id="fnbarChartSv" stateData={FINNIFTY_GCASH_BAR_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/bar-chart/MIDCPNIFTY_Strike_Value" element={<BarChartMain id="mnbarChartSv" stateData={MIDCPNIFTY_GCASH_BAR_CHART_DATA}/>} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/bar-chart/NIFTY_Strike_Wcash" element={<BarChartMain id="nfbarChartSw" stateData={NIFTY_WCASH_BAR_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/bar-chart/BANKNIFTY_Strike_Wcash" element={<BarChartMain id="bnbarChartSw" stateData={BANKNIFTY_WCASH_BAR_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/bar-chart/FINNIFTY_Strike_Wcash" element={<BarChartMain id="fnbarChartSw" stateData={FINNIFTY_WCASH_BAR_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/bar-chart/MIDCPNIFTY_Strike_Wcash" element={<BarChartMain id="mnbarChartSw" stateData={MIDCPNIFTY_WCASH_BAR_CHART_DATA}/>} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/bar-chart/NIFTY_Strike_OI" element={<BarChartMain id="nfbarChartOI" stateData={NIFTY_OI_BAR_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/bar-chart/BANKNIFTY_Strike_OI" element={<BarChartMain id="bnbarChartOI" stateData={BANKNIFTY_OI_BAR_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/bar-chart/FINNIFTY_Strike_OI" element={<BarChartMain id="fnbarChartOI" stateData={FINNIFTY_OI_BAR_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/bar-chart/MIDCPNIFTY_Strike_OI" element={<BarChartMain id="mnbarChartOI" stateData={MIDCPNIFTY_OI_BAR_CHART_DATA}/>} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/bar-chart/NIFTY_Strike_IV" element={<BarChartMain id="nfbarChartIV" stateData={NIFTY_IV_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/bar-chart/BANKNIFTY_Strike_IV" element={<BarChartMain id="bnbarChartIV" stateData={BANKNIFTY_IV_CHART_DATA}/>} />
        </Route>

       
          <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/NIFTY_Snakes" element={<LineChartMain id="nfSnakeChart" stateData={NIFTY_SNAKE_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/BANKNIFTY_Snakes" element={<LineChartMain id="bnSnakeChart" stateData={BANKNIFTY_SNAKE_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/FINNIFTY_Snakes" element={<LineChartMain id="fnfSnakeChart" stateData={FINNIFTY_SNAKE_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
          <Route path="/line-chart/MIDCPNIFTY_Snakes" element={<LineChartMain id="mcpnSnakeChart" stateData={MIDCPNIFTY_SNAKE_CHART_DATA}/>} />
        </Route>

        {/* D3 Snakes charts */}
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/NIFTY_Snakes" element={<LineChartMainNew id="nfSnakeChart" stateData={NIFTY_SNAKE_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/BANKNIFTY_Snakes" element={<LineChartMainNew id="bnSnakeChart" stateData={BANKNIFTY_SNAKE_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/FINNIFTY_Snakes" element={<LineChartMainNew id="fnfSnakeChart" stateData={FINNIFTY_SNAKE_CHART_DATA}/>} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2} allowedResource={USER_RESOURCES_LIST.TBT_FEED}/>}>
          <Route path="/candle-line-chart/MIDCPNIFTY_Snakes" element={<LineChartMainNew id="mcpnSnakeChart" stateData={MIDCPNIFTY_SNAKE_CHART_DATA}/>} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_FAST_DATA}/>}>
           <Route path="/stock-chart" element={<StockChart id="stockChart" />} />
        </Route>  
        
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/settings" element={<Settings />} />
        </Route>
        
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
            <Route path="/notifications" element={<Notifications />} />
        </Route>
        
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_1}/>}>
          <Route path="/bookmarks" element={<Bookmarks />} />
        </Route>
        
        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/profile" element={<UserProfile />} />
       </Route>

       <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
         <Route path="/options-table" element={<OPTIONS_TABLE />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
         <Route path="/greek-chart" element={<GREEKS_TABLE />} />
        </Route>
      

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
         <Route path="/index-table" element={<INDEX_TABLE />} />
        </Route>


        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
         <Route path="/fii-data" element={<FII_DATA />} />
        </Route> 

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ADMIN}/>}>
         <Route path="/feed-admin" element={<FeedAdmin />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2}/>}>
         <Route path="/user-admin" element={<UserAdmin />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
         <Route path="/subscription" element={<Subscription />} />
        </Route>

        {/* <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_PRO_2}/>}>
         <Route path="/subscription-new" element={<SubscriptionNew />} />
        </Route> */}

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/user-manual" element={<UserManualPageBase />} />
        </Route>



        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/faq" element={<FaqPage />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/intros" element={<IntroPage />} />
        </Route>
        
       <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
          <Route path="/help" element={<HelpPage />} />
        </Route>
    
      
      </Route>
      
      {/* incase of url not matched redirect to page not found */}
      <Route path="*" element={<Missing />} />
      
      
      </ReactRoutes>
    </>
  );
};

export default Routes;

