import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function SaveExistingPositionDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const [posName, setPosName] = React.useState(props.name);
  

  const handleClose = () => {
    // setOpen(false);
    props.cancelHandler();
  };

  const handleSave = () => {
    // setOpen(true);
    console.log("handleSave=",posName);
    props.saveHandler(posName);
  };

 
  const handlePosChange=(event)=>{
    setPosName(event.target.value)
  }
  return (
    <div>
     
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Save Position</DialogTitle>
        <DialogContent>
          <DialogContentText>
            What do you want to do with existing saved position?
          </DialogContentText>
          {/* <TextField
            autoFocus
            value={posName}
            sx={{mt:4}}
            onChange={(event) => handlePosChange(event)}
            // margin="dense"
            id="name"
            label="Position Name"
            fullWidth
            variant="standard"
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Don't Save</Button>
          <Button onClick={handleSave}>Overwrite</Button>
          {props.isTarget?
          <Button onClick={()=>props.saveAsNew()}>Save As New</Button>:
          <></>
            }
        </DialogActions>
      </Dialog>
    </div>
  );
}
