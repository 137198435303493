import React,{useCallback,useEffect, useState} from "react";
import "../styles/styles.scss"
import CallSplitIcon from '@mui/icons-material/CallSplit';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import { Splitscreen } from "@mui/icons-material";
import { CHART_SCREEN_TYPE } from "../../../common/utility/mp_chart_constants";
import { TIME_FRAME_VALUES } from "../../../common/utility/constant";

const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';

const Tooltip = (props) => {
  let {
    xPosition,
    yPosition,
    exitMenu,
    data,
    key,
    chartScreenType
    
  } = props;

      
  const handleEscKey = useCallback((event) => {
    if (event.key === KEY_NAME_ESC) {
      exitMenu();
    }
  }, [exitMenu]);
 
  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
 
    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);

 
 
  
  return (
    <>
    <ul
        className={data[data.length-1].key!="KRL"?"context-menu":"context-menu-krl"}
        style={{
            top: yPosition,
            left: xPosition,
            paddingLeft:"10px",
            paddingRight:data[data.length-1].key!="KRL"?"10px":"50px"
        }}>
    
            {data.map((item,index) => (
                // <div style={{borderBottom:"1px solid white", width:"100%"}}>
                <div>
                  {item.key!="KRL"?
                  <>
                    {item.color?
                      <span style={{height:"10px",width:"10px",ml:1,backgroundColor:item.color,borderRadius:"50%",display:"inline-block",marginRight:"4px"}}></span>:
                      <></>
                    }
                    <span style={{marginLeft:"0px",width:item.color?"71px":"85px",display:'inline-block'}}>{item.key}:</span>
                    <span>{item.value}</span>
                    </>:
                    <>
                    <span style={{height:"10px",width:"10px",ml:1,backgroundColor:item.color,borderRadius:"50%",display:"inline-block"}}></span>
                    <span style={{marginLeft:"10px",marginRight:"20px",fontWeight:700}}>{item.value}</span>
                    </>
                  }

                </div>
            ))}
       
    </ul>
      
    </>
  );
};

export default Tooltip;
