import React,{useContext,useEffect,useState} from 'react'
import { UserSettingsContext } from '../../setup/routes-manager/RequireAuth'
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import { Snackbar,Alert,CircularProgress,IconButton, InputLabel,Switch,Radio,RadioGroup,FormControlLabel,FormLabel, Link } from '@mui/material';
import {Grid, Box,Typography,Select,ListSubheader,TextField,InputAdornment,FormControl,MenuItem} from "@mui/material";
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
// import GstIcon from '@mui/icons-material/Store';
// import CompanyIcon from '@mui/icons-material/Store';
// import AddressIcon from '@mui/icons-material/Store';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LockIcon from '@mui/icons-material/Lock';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import VerifiedIcon from '@mui/icons-material/Verified';


const UserProfile = () => {
  const from = "/login";
  const navigate = useNavigate(); 

  const [userDetailsState,setUserDetailsState]=useState({
    isLoaded:false,
    data:undefined,
    currentContact:"",
    currentEmail:"",
    currentPassword:"",
    updatedPassword:"",
    currentFirstName:"",
    currentLastName:"" ,
    currentGstNum:"" ,
    currentCompanyName:"" ,
    currentAddress:"" ,
  })

  const {isLoaded,data,currentContact,currentEmail,currentPassword,updatedPassword,currentFirstName,currentLastName,currentGstNum,currentCompanyName,currentAddress}=userDetailsState;

  const [editName,setEditName]=useState(false);
  const [editEmail,setEditEmail]=useState(false);
  const [editContact,setEditContact]=useState(false);
  const [editPassword,setEditPassword]=useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [editGstNum, setEditGstNum] = useState(false);
  const [editCompanyName, setEditCompanyName] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  
  const { open, msg, severity } = msgState;
  const [cancel,responseData, error, loaded, reset,executeAPI ] = useAxiosWithAuth();
  const [cancelSave,responseDataSave, errorSave, loadedSave, resetSave,executeAPISave ] = useAxiosWithAuth();
  const [cancelPassword,responseDataPassword, errorPassword, loadedPassword, resetPassword,executeAPIPassword ] = useAxiosWithAuth();
  

  /**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

useEffect(() => {
  document.title = "Profile Details";
}, []);
  
  useEffect(()=>{
    executeAPI(URL.USER_DETAILS,"GET",{});
  },[]);
  /**
 * API response handler  for user details
 */  
  useEffect(() => {
    if(loaded){
    
      if(responseData!=null){
        setUserDetailsState((prevState)=>({
            ...prevState,
           isLoaded:true,
           data:responseData.data,
           currentContact:responseData.data.contact,
           currentEmail:responseData.data.email_id,
           currentFirstName:responseData.data.first_name,
           currentLastName:responseData.data.last_name,
           currentGstNum:responseData.data.gstin,
           currentCompanyName:responseData.data.company_name,
           currentAddress:responseData.data.address,
          }));
      }
      else if(error!==null){
        console.log("Error data=",error);
        setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirect it to login page
        if(error?.response?.status === 401 || error?.response?.status === 403){
          console.log("status received =",error?.response?.status)
          navigate(from, { replace: true });
        }
    }
    reset();
    }
  },[loaded,responseData]);

  const handleNameEdit=()=>{
    setEditName(true);
  }

  const handleEmailEdit=()=>{
    setEditEmail(true);
  }
  const handleContactEdit=()=>{
    setEditContact(true);
  }

  const handlePasswordEdit=()=>{
    setEditPassword(true);
  }

  const handleGstDetailsEdit=()=>{
    setEditGstNum(true);
    setEditCompanyName(true);
    setEditAddress(true);
  }

  const handleFirstNameChange=(event)=>{
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentFirstName:event.target.value
    }))
  }

  const handleLastNameChange=(event)=>{
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentLastName:event.target.value
    }))
  }
  
  const handleEmailChange=(event)=>{
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentEmail:event.target.value
    }))
  }

  const handleContactChange=(event)=>{
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentContact:event.target.value
    }))
  }

  const handleGstNumChange=(event)=>{
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentGstNum:event.target.value
    }))
  }

  const handleCompanyNameChange=(event)=>{
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentCompanyName:event.target.value
    }))
  }

  const handleAddressChange=(event)=>{
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentAddress:event.target.value
    }))
  }

  const cancleProfileDetailsEdit=()=>{
    setEditEmail(false);
    setEditContact(false);
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentContact:data.contact,
      currentEmail:data.email_id
    }))
  }

  const cancleGstDetailsEdit=()=>{
    setEditGstNum(false);
    setEditCompanyName(false);
    setEditAddress(false);
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentGstNum:data.gstin,
      currentCompanyName:data.company_name,
      currentAddress:data.address
    }))
  }

  const cancleNameEdit=()=>{
    setEditName(false);
    
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentFirstName:data.first_name,
      currentLastName:data.last_name
    }))
  }

  const handlePasswordChange=(event)=>{
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentPassword:event.target.value
    }))
  }

  const handleConfirmPasswordChange=(event)=>{
    setUserDetailsState((prevState)=>({
      ...prevState,
      updatedPassword:event.target.value
    }))
  }

  const cancelUpdatePassword=()=>{
    setEditPassword(false)
    setUserDetailsState((prevState)=>({
      ...prevState,
      currentPassword:"",
      updatedPassword:""
    }))
  }

  const validateEmailId=()=>{
    if (!currentEmail.trim()) {
     return false;
    }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(currentEmail.trim())) {
      return false;
    }
    return true;
  }

  const validateContact=()=>{
    if(!currentContact.trim()){
    return false
   }else if(!/^[0-9]\d{9}$/i.test(currentContact.trim())){
    return false;
   }
   return true;
  }

  const validateTextData=()=>{
    // if (!currentEmail.trim()) {
    //  return false;
    // }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(currentEmail.trim())) {
    //   return false;
    // }
    return true;
  }

  const saveUserDetails=()=>{
    let newData={
      first_name:currentFirstName,
      last_name:currentLastName,
      email_id:currentEmail,
      contact:currentContact
    }
    executeAPISave(URL.UPDATE_USER,"POST",newData);
  }

  const saveGstDetails=()=>{
    let newData={
      gstin:currentGstNum,
      company_name:currentCompanyName,
      address:currentAddress,
    }
    executeAPISave(URL.UPDATE_USER,"POST",newData);
  }

  /**
 * API response handler save user details
 */  
  useEffect(() => {
    if(loadedSave){
      if(responseDataSave!=null){
        let updatedData=data;
        // updatedData.email_id=currentEmail;
        updatedData.contact=currentContact;
        updatedData.address=currentAddress;
        updatedData.company_name=currentCompanyName;
        updatedData.gstin=currentGstNum;

        setUserDetailsState((prevState)=>({
            ...prevState,
           isLoaded:true,
           data:updatedData,
           currentContact:currentContact,
           currentEmail:currentEmail,
           currentFirstName:currentFirstName,
           currentLastName:currentLastName,
           currentGstNum:currentGstNum,
           currentCompanyName:currentCompanyName,
           currentAddress:currentAddress,
          }));
          setMsgState({open:true,msg:`${TEXT_MSGS.USER_DETAILS_UPDATED_SUCCESSFULLY}`,severity:"success"});
          setEditContact(false);
          setEditEmail(false);
          setEditName(false);
          setEditGstNum(false);
          setEditCompanyName(false);
          setEditAddress(false);
      }
      else if(errorSave!==null){
        setMsgState({open:true,msg:errorSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirect it to login page
        if(errorSave?.response?.status === 401 || errorSave?.response?.status === 403){
          navigate(from, { replace: true });
        }
    }
    resetSave();
    }
  },[loadedSave,responseDataSave]);

  const savePassword=()=>{
    let newData={
      current_password:currentPassword,
      new_password:updatedPassword
    }
    executeAPIPassword(URL.UPDATE_PASSWORD,"POST",newData);

  }

  /**
 * API response handler update user password
 */  
  useEffect(() => {
    if(loadedPassword){
      if(responseDataPassword!=null){
        setMsgState({open:true,msg:`${TEXT_MSGS.PASSWORD_UPDATED_SUCCESSFULLY}`,severity:"success"});
         setEditPassword(false)
      }
      else if(errorPassword!==null){
        setMsgState({open:true,msg:errorPassword?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirect it to login page
        if(errorPassword?.response?.status === 401 || errorPassword?.response?.status === 403){
          navigate(from, { replace: true });
        }
    }
    resetSave();
    }
  },[loadedPassword,responseDataPassword]);




  return (
    <>
    {isLoaded?
    <>      
      <Grid container spacing={2}>
        <Grid item mobile={1} tablet={2} laptop={3}>
        
        </Grid>
        
        <Grid item mobile={10} tablet={8} laptop={6}>
        <Typography sx={{mt:2,fontSize:18}}>Profile Details</Typography>
        {/* <Box sx={{backgroundColor:'primaryTheme.shade03',zIndex:999,mt:2,borderRadius:5,height:"100px",width:"100%"}}> */}
        <Box sx={{backgroundColor:'primaryTheme.shade03',zIndex:999,mt:2,borderRadius:5,width:"100%",display:'flex',flexDirection:"column"}}>
         <Typography sx={{pt:2,ml:3,fontSize:14,color:"#92a9a9"}}>Full Name</Typography>
        <Box display="flex" justifyContent="flex-start" sx={{mt:1,ml:2}} flexDirection="row">
          <PersonIcon sx={{ml:0}}></PersonIcon>
          {editName?
          <Box display="flex" justifyContent="flex-start" sx={{mt:0,ml:1,pb:2,width:"100%"}} flexDirection="column">
            <TextField id="standard-basic" sx={{width:"80%"}}value={currentFirstName} onChange={handleFirstNameChange} label="Enter First Name" variant="filled" type="email"
             error={currentFirstName.trim()==""}
             helperText={currentFirstName.trim()=="" ? 'Enter valid fist name' : ' '} 
            />
            <TextField id="standard-basic" sx={{width:"80%"}}value={currentLastName} onChange={handleLastNameChange} label="Enter Last Name" variant="filled" type="email"
             error={currentLastName.trim()==""}
             helperText={currentLastName.trim()=="" ? 'Enter valid last name' : ' '} 
            />
            </Box>
          :
          <Box display="flex" justifyContent="space-between" width="100%" sx={{mt:0,pb:2}}>
               <Typography sx={{ml:1,fontSize:16}}>{currentFirstName}&nbsp;{currentLastName}</Typography>
              <ModeEditIcon onClick={handleNameEdit}sx={{alignSelf:'flex-end',mr:2,cursor:'pointer'}}></ModeEditIcon>
           </Box>
          }
          </Box>
          
          
          {editName ?
          <Box display="flex" justifyContent="flex-end" sx={{mt:1,ml:2,pb:2}} flexDirection="row">
          <Button sx={{mr:2}} onClick={cancleNameEdit}>Cancel</Button>
          <Button disabled={currentFirstName.trim()=="" || currentLastName.trim()==""} onClick={saveUserDetails}sx={{backgroundColor:'primaryTheme.shade04',mr:2}}>Save Changes</Button>
          </Box>
          :<></>
          }

        </Box>

        <Box sx={{backgroundColor:'primaryTheme.shade03',zIndex:999,mt:2,borderRadius:5,width:"100%",display:'flex',flexDirection:"column"}}>
         <Typography sx={{pt:2,ml:3,fontSize:14,color:"#92a9a9"}}>Contact Details</Typography>
        <Box display="flex" justifyContent="flex-start" sx={{mt:1,ml:2}} flexDirection="row">
          <EmailIcon sx={{ml:0}}></EmailIcon>
          {editEmail?
          <Box display="flex" justifyContent="flex-start" sx={{mt:0,ml:1,pb:1,width:"100%"}} flexDirection="column">
            <TextField id="standard-basic" sx={{width:"80%"}}value={currentEmail} onChange={handleEmailChange} label="Enter New Email Id" variant="filled" type="email"
             error={!validateEmailId()}
             helperText={!validateEmailId() ? 'Enter valid email id' : ' '} 
            />
            </Box>
            :
            <Box display="flex" justifyContent="flex-start" width="100%">
              <Typography sx={{ml:1,fontSize:16}}>{data.email_id}</Typography>
              {data.active?
              <VerifiedIcon onClick={handleEmailEdit}sx={{ml:1,height:20,width:20,marginTop:"2px"}}/> :
              <></>   
              }

              {/* <ModeEditIcon onClick={handleEmailEdit}sx={{alignSelf:'flex-end',mr:2,cursor:'pointer'}}></ModeEditIcon> */}
           </Box>
          }
          </Box>
          
          <Box display="flex" justifyContent="flex-start" sx={{mt:2,ml:2,pb:2}} flexDirection="row">
          <PhoneAndroidIcon sx={{ml:0}}></PhoneAndroidIcon>
          {editContact?
          <Box display="flex" justifyContent="flex-start" sx={{mt:0,ml:1,pb:2,width:"100%"}} flexDirection="column">
            <TextField id="standard-basic" sx={{width:"200px"}} value={currentContact} onChange={handleContactChange} label="Enter New Number" variant="filled"
             error={!validateContact()}
             helperText={!validateContact() ? 'Enter valid mobile number' : ' '} 
            />
            </Box>
            :
            <Box display="flex" justifyContent="space-between" width="100%" sx={{mt:0}}>
              <Typography sx={{ml:1,fontSize:16}}>{data.contact!=""?data.contact:"Not Available"}</Typography>
              <ModeEditIcon onClick={handleContactEdit}sx={{alignSelf:'flex-end',mr:2,cursor:'pointer'}}></ModeEditIcon>
           </Box>
          }
          </Box>

          {editEmail ||editContact?
          <Box display="flex" justifyContent="flex-end" sx={{mt:1,ml:2,pb:2}} flexDirection="row">
          <Button sx={{mr:2}} onClick={cancleProfileDetailsEdit}>Cancel</Button>
          <Button disabled={!validateContact()|| !validateEmailId()} onClick={saveUserDetails}sx={{backgroundColor:'primaryTheme.shade04',mr:2}}>Save Changes</Button>
          </Box>
          :<></>
          }


        </Box>
        <Box sx={{backgroundColor:'primaryTheme.shade03',zIndex:999,mt:2,pb:2,borderRadius:5,width:"100%"}}>
        <Typography sx={{pt:2,ml:3,fontSize:14,color:"#92a9a9"}}>Password</Typography>
        <Box display="flex" justifyContent="flex-start" sx={{mt:1,ml:2}} flexDirection="row">
          <LockIcon sx={{ml:0}}></LockIcon>
          {editPassword?
          <Box display="flex" justifyContent="flex-start" sx={{mt:0,ml:1,pb:2,width:"100%"}} flexDirection="column">
            <TextField id="standard-basic"  sx={{mt:0,width:"70%"}}value={currentPassword} onChange={handlePasswordChange} label="Enter Current Password" variant="filled"
            type={showPassword ? 'text' : 'password'}
            error={currentPassword .trim()=== ""}
            helperText={currentPassword.trim() === "" ? 'Current password is required' : ' '}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" >
                  <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }} />
            <TextField id="standard-basic-1" sx={{mt:1,width:"70%"}} value={updatedPassword} onChange={handleConfirmPasswordChange} label="Enter New Password" variant="filled"
            type={showConfirmPassword ? 'text' : 'password'}
            error={updatedPassword .trim()=== ""}
            helperText={updatedPassword.trim() === "" ? 'New password is required' : ' '}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" >
                  <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}  />
            </Box>
            :
            <Box display="flex" justifyContent="space-between" width="100%">
               <Typography sx={{ml:1,fontSize:16,marginTop:"4px"}}>**********</Typography>
              <ModeEditIcon onClick={handlePasswordEdit}sx={{alignSelf:'flex-end',mr:2,cursor:'pointer'}}></ModeEditIcon>
           </Box>
          }
         
          </Box>
          {editPassword?
          <Box display="flex" justifyContent="flex-end" sx={{mt:1,ml:2,pb:0}} flexDirection="row">
          <Button sx={{mr:2}} onClick={cancelUpdatePassword}>Cancel</Button>
          <Button disabled={currentPassword.trim()=="" || updatedPassword.trim()==""} onClick={savePassword} sx={{backgroundColor:'primaryTheme.shade04',mr:2}}>Save Changes</Button>
          </Box>
          :<></>
          }
        </Box>

        <Box sx={{backgroundColor:'primaryTheme.shade03',zIndex:999,mt:2,borderRadius:5,width:"100%",display:'flex',flexDirection:"column"}}>
         <Typography sx={{pt:2,ml:3,fontSize:14,color:"#92a9a9"}}>GST Details for Invoicing (Optional - please email us on <Link href="mailto:charts@vtrender.com">charts@vtrender.com</Link> if you need a GST invoice)</Typography>
        <Box display="flex" justifyContent="flex-start" sx={{mt:1,ml:2}} flexDirection="row">
          <Typography sx={{ml:0,mr:2,fontSize:14,width:"200px"}}>GSTIN</Typography>
          {editGstNum?
          <Box display="flex" justifyContent="flex-start" sx={{mt:0,ml:1,pb:1,width:"100%"}} flexDirection="column">
            <TextField id="standard-basic" sx={{width:"80%"}}value={currentGstNum} onChange={handleGstNumChange} label="Update your Registered GST Number" variant="filled" type="email"
             error={!validateTextData()}
             helperText={!validateTextData() ? 'GST Number' : ' '} 
            />
            </Box>
            :
            <Box display="flex" justifyContent="space-between" width="100%" sx={{mt:0}}>
              <Typography sx={{ml:1,fontSize:16}}>{data.gstin!=undefined?data.gstin:"-"}</Typography>
              {/* <ModeEditIcon onClick={handleGstNumEdit}sx={{alignSelf:'flex-end',mr:2,cursor:'pointer'}}></ModeEditIcon> */}
           </Box>
          }
          </Box>
          
          <Box display="flex" justifyContent="flex-start" sx={{mt:1,ml:2}} flexDirection="row">
          <Typography sx={{ml:0,mr:2,fontSize:14,width:"200px"}}>Company Name</Typography>
          {editCompanyName?
          <Box display="flex" justifyContent="flex-start" sx={{mt:0,ml:1,pb:1,width:"100%"}} flexDirection="column">
            <TextField id="standard-basic" sx={{width:"80%"}} value={currentCompanyName} onChange={handleCompanyNameChange} label="Update your Company Name" variant="filled"
             error={!validateTextData()}
             helperText={!validateTextData() ? 'Registered Company Name' : ' '} 
            />
            </Box>
            :
            <Box display="flex" justifyContent="space-between" width="100%" sx={{mt:0}}>
              <Typography sx={{ml:1,fontSize:16}}>{data.company_name!=undefined?data.company_name:"-"}</Typography>
              {/* <ModeEditIcon onClick={handleCompanyNameEdit}sx={{alignSelf:'flex-end',mr:2,cursor:'pointer'}}></ModeEditIcon> */}
           </Box>
          }
          </Box>

          <Box display="flex" justifyContent="flex-start" sx={{mt:1,ml:2,mb:2}} flexDirection="row">
          <Typography sx={{ml:0,mr:2,fontSize:14,width:"200px"}}>Company Address with Pincode</Typography>
          {editAddress?
          <Box display="flex" justifyContent="flex-start" sx={{mt:0,ml:1,pb:1,width:"100%"}} flexDirection="column">
            <TextField id="standard-basic" sx={{width:"80%"}} value={currentAddress} onChange={handleAddressChange} label="Update your Company Address with valid Pincode" variant="filled"
             error={!validateTextData()}
             helperText={!validateTextData() ? 'Company Address' : ' '} 
            />
            </Box>
            :
            <Box display="flex" justifyContent="space-between" width="100%" sx={{mt:0}}>
              <Typography sx={{ml:1,fontSize:16}}>{data.address!=undefined?data.address:"-"}</Typography>
              <ModeEditIcon onClick={handleGstDetailsEdit}sx={{alignSelf:'flex-end',mr:2,cursor:'pointer'}}></ModeEditIcon>
           </Box>
          }
          </Box>

          {editGstNum || editAddress || editCompanyName?
          <Box display="flex" justifyContent="flex-end" sx={{mt:1,ml:2,pb:2}} flexDirection="row">
          <Button sx={{mr:2}} onClick={cancleGstDetailsEdit}>Cancel</Button>
          <Button disabled={!validateTextData()|| !validateTextData()} onClick={saveGstDetails}sx={{backgroundColor:'primaryTheme.shade04',mr:2}}>Save Changes</Button>
          </Box>
          :<></>
          }


        </Box>
       
        
        
        </Grid>
        
        <Grid item mobile={1} tablet={2} laptop={3}>
     
        </Grid>
      </Grid>
      </>
      :
      <CircularProgress sx={{marginTop:"20%",marginLeft:"45%"}}/>}
       <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
      </>
  )
}

export default UserProfile
