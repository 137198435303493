import React from 'react'
import { useEffect,useState,useMemo,useCallback,useRef,useContext } from 'react'
import { URL } from '../../common/api/urls';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS,CHART_RELOAD_TIME } from '../../common/utility/constant';
import { CircularProgress ,Button,Tooltip} from '@mui/material';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION_SHORT,USER_RESOURCES_LIST} from '../../common/utility/constant';
import {Snackbar, Alert} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import {Tab,Tabs} from '@mui/material';
import TICKER_TABLE from './components/ticker_table';
import OptionsFooter from './components/options_footer';
import {OPTIONS_TABLE_CHARTS,INDEX_TABLE_TAB_DATA,getIndexTableTabData} from '../../common/utility/table_constants';
import { useWindowSize } from '../../common/hooks/useWindowSize';
import { useOutletContext } from "react-router-dom";
import { ToolbarFixed } from '../../layouts/component.js';
import { Typography, Container,Box} from '@mui/material'
import {styled, useTheme} from '@mui/material/styles';
import { WATERMARK_CONFIG } from '../../common/utility/constant';
import { UserSettingsContext } from '../../setup/routes-manager/RequireAuth'
import SubscriptionPopup from './components/subscription_popup';
import SettingsIcon from '@mui/icons-material/Settings';
import LoginModal from './components/login_popups';





import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { getColumneDef,getColumneDefFut } from '../../common/utility/index_columnDefs';
  import '../dashboard/styles/options.scss'
import {processIndexData } from '../../common/utility/preProcessor';
import IndexSettings from './components/index_settings';

// import { CallCheckBoxRenderer,PutCheckBoxRenderer } from './components/options_table_renderer';
const gridOptions = {
  suppressColumnVirtualisation:true,
  suppressMovableColumns:true,
  suppressColumnMoveAnimation:true,
  suppressRowVirtualisation:true,
  enableCellTextSelection:true,
  ensureDomOrder:true
}

const frameworkComponents= {
 
}

const TAB_CONTAINER_HEIGHT=48;
const MIN_TABLE_HEIGHT=300;

const FooterToolbar=styled(ToolbarFixed)(
  ({theme})=> `
  bottom:0px;
  justify-content:center;
  text-align:center;
  `
)


export default function INDEX_TABLE() {
  const navigate = useNavigate(); 
  const theme=useTheme();
  const from = "/login";
  const { auth } = useAuth();
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [cancelLive,responseDataLive,errorLive,loadedLive,resetLive,executeLive] = useAxiosWithAuth();
  const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
  const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
  const [gridAPIOptions,setGridAPIOptions]=useState();
  const [columnAPIOptions,setColumnAPIOptions]=useState();
  const [selectedRows,setSelectedRows]=useState({call:undefined,put:undefined});
  const [showPopup,setShowPopup]=useState(false);
  const basicUser=(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true
  const [tickerHeight,setTickerTableHeight]=useState();
  const gridRef = useRef();
  const windowSize=useWindowSize();
  const [tabState,setTabState]=useState({
    tabData:getIndexTableTabData(auth),
    tabLoaded:true,
    selectedInstrument:(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?"NIFTY_FUT":"NIFTY",
    isFut:(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?true:false,
  });
  const {tabData,tabLoaded,selectedInstrument,isFut}=tabState;
  const [isStocksAllowed,setIsStocksAllowed]=useState((auth.resources.find(el=>el==USER_RESOURCES_LIST.MP_STOCKS))?true:false)
  const [subscriptionPopupData,setSubscriptionPopupData]=useState({isCustom:false,msg:""});
  const [showSettings,setShowSettings]=useState(false);

  const showSubscribeModal=(isCustom=false)=>{
    if(isCustom){
      setSubscriptionPopupData({isCustom:true,msg:TEXT_MSGS.MP_CHART_STOCK_RESOURCE_NOT_SUBSCRIBED_DESC});
      setShowPopup(true);
    }else{
      setSubscriptionPopupData({isCustom:false,msg:""});
      setShowPopup(true);
    }
   
  }

  const [indexState,setIndexState]=useState({
    indexData:undefined,
    pinnedData:undefined,
    indexLoaded:false,
    instrument:undefined,
    isInstrumentLive:false,
    reloadInerval:(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?CHART_RELOAD_TIME.INDEX_DATA_FAST:CHART_RELOAD_TIME.INDEX_DATA_SLOW,
    pollingInterval:CHART_RELOAD_TIME.POLLING_TIME,
    refreshInterval:-1,
    isPollingAllowed:false,
    tableHeight:0,
    actualHeight:0,
    columns:undefined,
    lastDataTime:undefined,
    isTradingSessionActive:false,
    isStatusCheckAllowed:false,
    statusCheckPollingInterval:CHART_RELOAD_TIME.POLLING_TIME
  
  });
  const {indexData,pinnedData,indexLoaded,isInstrumentLive,reloadInerval,pollingInterval,refreshInterval,isPollingAllowed,metaDataHeight,tableHeight,actualHeight,columns,
    lastDataTime,isTradingSessionActive,isStatusCheckAllowed,statusCheckPollingInterval}=indexState;
  const indexStateRef=useRef(indexState);
  const [showLoginPopup,setShowLoginPopup]=useState(false);
  const [loginMsg,setLoginMsg]=useState(TEXT_MSGS.LOGIN_POPUP_MSG);

  useEffect(()=>{
    console.log("window size=",windowSize);
    setIndexState((prevState) => ({
      ...prevState,
      tableHeight:window.innerHeight-tickerHeight-TAB_CONTAINER_HEIGHT-15-48-30
    }));
  },[windowSize])
 
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  
  const { open, msg, severity } = msgState;

  useEffect(() => {
    document.title = "Index Table";
  }, []);



const openMPChart=(symbol)=>{
    window.open(`${process.env.REACT_APP_URL}market-profile?symbol=${symbol}`, '_blank', 'noreferrer');
  
}

// const [columnDefs] = useState(getColumneDef(auth,openMPChart,userSettings.data.number_format_locale))
const [columnDefs,setColumnDefs] = useState()
  
useEffect(()=>{
    console.log("useEffect options state=",indexState.indexData)
    // optionsStateRef.current=optionsState;
    if(gridRef!=undefined && gridRef.current!=undefined && gridRef.current.api!=undefined){
      let params = {
          force: true,
          suppressFlash: false,
          // rowNodes: changedRow,
        }; 
        gridRef.current.api.refreshCells(params);
       
      }
  },[indexState])

  
  
    useEffect(()=>{
      if(selectedInstrument!=undefined && tickerHeight!=undefined){
        setIndexState((prevState) => ({
          ...prevState,
          isPollingAllowed:false,
          instrument:selectedInstrument}));
        
        let data={"symbol": selectedInstrument}
        executeAPI(URL.INDEX_DATA,"POST",data);
      }
    },[selectedInstrument,tickerHeight]);

    // useEffect(()=>{
    //     executeAPI("/chart/indextable","POST",{
    //       "symbol": "NIFTY_FUT"
    //   }) 
    //   },[])
    
    
    const handleTabChange = (event, newValue) => {
      let isFutInstrument=newValue.includes("FUT");
      if(isFutInstrument && !isStocksAllowed)
        showSubscribeModal(true)
      else
        setTabState(prev=>({
          ...prev,
          indexLoaded:false,
          selectedInstrument:newValue,
          isFut:isFutInstrument
        }));
    };

/**
 * API response handler index data
 */  
   useEffect(() => {
    if(loaded){
      // console.log("loadedOptions=true",responseDataOptions);
      if(responseData!=null){
        let colDef=getColumneDef(auth,openMPChart,showSubscribeModal,userSettings.data.number_format_locale,userSettings.data.page_wise_hidden_columns.INDEX_COMPONENTS)
        if(isFut)
          colDef=getColumneDefFut(auth,openMPChart,showSubscribeModal,userSettings.data.number_format_locale,userSettings.data.page_wise_hidden_columns.INDEX_FUT_COMPONENTS)
        // setColumnDefs(colDef)
        let processedData=processIndexData(responseData.data,userSettings.data.number_format_locale);
        let index=processedData.findIndex((item)=>item.key.includes("NIFTY"))
        let pinnedList=[];
        if(index!=-1){
            pinnedList.push(processedData[index])
            processedData.splice(index,1);
        }
        
       
        
        let tableHeight=window.innerHeight-tickerHeight-TAB_CONTAINER_HEIGHT-15-48-30;//margin top, top header, scroll heights, bottom bar,copyright footer
        // let tableHeight=window.innerHeight-48-40-TAB_CONTAINER_HEIGHT;//margin top, top header, scroll heights, bottom bar,copyright footer
        tableHeight=Math.max(tableHeight,MIN_TABLE_HEIGHT);
        // let actualHeight=tableHeight;
        // tableHeight=parseInt((actualHeight/windowSize.zoomLevel).toFixed(1))
        console.log("innerheight real=",window.innerHeight,tableHeight);
        let updateInterval=pollingInterval;
              if(responseData.isInstrumentLive!=undefined && responseData.isInstrumentLive){
                updateInterval=reloadInerval;
              }
              setIndexState((prevState) => ({
                ...prevState,
                refreshInterval:updateInterval,
                isPollingAllowed:true,
                tableHeight:tableHeight,
                actualHeight:actualHeight,
                indexLoaded:true,
                indexData:processedData,
                columns:colDef,
                pinnedData:pinnedList,
                lastDataTime:responseData.lastDataTime,
                isTradingSessionActive:responseData.isTradingSessionActive,
                isStatusCheckAllowed:(!responseData.isInstrumentLive),
                statusCheckPollingInterval:(responseData.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
                isInstrumentLive:responseData.isInstrumentLive}));
                  
      }
      else if(error!==null){
        setIndexState((prevState) => ({
          ...prevState,
          indexLoaded:true}));

        console.log("Error data=",error);

          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            navigate(from, { replace: true });
          }
      }
      reset();
    }
    },[loaded,responseData]);
    
  //live data polling
 //polling or live chart update handler
  useEffect(() => {
   //set the ref to current state
   
   indexStateRef.current = indexState;
    
    const timeout = setInterval(() => {
      if(indexStateRef.current.instrument!=undefined){
        // console.log("Live use effect api called=",optionsStateRef.current.refreshInterval);
        if(indexStateRef.current.isPollingAllowed){
          let data={"symbol": indexStateRef.current.instrument}
          executeLive(URL.INDEX_DATA,"POST",data);
        }
      }
    }, indexStateRef.current.refreshInterval);

     //market status polling
     const timeoutStatusCheck=setInterval(() => {
      if(indexStateRef.current.isStatusCheckAllowed && !indexStateRef.current.isInstrumentLive)   {
          executeAPIStatus(URL.CHART_STATUS,"POST",{symbol:indexStateRef.current.instrument});
      }
    },indexStateRef.current.statusCheckPollingInterval); 
        
    return () => {
      // just to clear the timeout when component unmounts
      clearInterval(timeout);
      clearInterval(timeoutStatusCheck);
    };
}, [indexState]);
  
/**
 * Market live status check response handler
 */  
useEffect(() => {
  if(loadedStatus){
    if(responseDataStatus!=null){
              //if instrument is live then load the live data else set the status as per the response
              setShowPopup(false);
              if(responseDataStatus.isInstrumentLive!=undefined && responseDataStatus.isInstrumentLive){
                let data={"symbol": indexStateRef.current.instrument}
                executeLive(URL.INDEX_DATA,"POST",data);
              }else{
                setIndexState((prevState) => ({
                  ...prevState,
                  isStatusCheckAllowed:(!responseDataStatus.isInstrumentLive),
                  statusCheckPollingInterval:(responseDataStatus.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
                  isTradingSessionActive:responseDataStatus.isTradingSessionActive,
                  isInstrumentLive:responseDataStatus.isInstrumentLive,
                }));
              }
              if(responseDataStatus.upgrade_notify!=undefined && responseDataStatus.upgrade_notify){
                setShowPopup(true);
              }
          
    }
    else if(errorStatus!==null){
      console.log("Error data status check=",errorStatus);

      // setIndexState((prevState) => ({
      //   ...prevState,
      //   indexLoaded:true}));
        
        if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
          console.log("status received =",errorStatus?.response?.status)
          // navigate(from, { replace: true });
          setLoginMsg(errorStatus?.response?.data?.message);
          setShowLoginPopup(true);
        }
    }
    resetLive();
  }
  },[loadedStatus,responseDataStatus]);

  
 /**
 * API Live response handler options data
 */  
  useEffect(() => {
    if(loadedLive){
      console.log("loadedOptions Live=true",responseDataLive);
      if(responseDataLive!=null){
        const processedData=processIndexData(responseDataLive.data,userSettings.data.number_format_locale);
        let index=processedData.findIndex((item)=>item.key.includes("NIFTY"))
        let pinnedList=[];
        if(index!=-1){
            pinnedList.push(processedData[index])
            processedData.splice(index,1);
        }
      
       
        let tableHeight=window.innerHeight-tickerHeight-TAB_CONTAINER_HEIGHT-15-48-30;
        // let tableHeight=window.innerHeight-48-40-TAB_CONTAINER_HEIGHT;//margin top, top header, scroll heights, bottom bar,copyright footer
        tableHeight=Math.max(tableHeight,MIN_TABLE_HEIGHT);
        
        
        let updateInterval=pollingInterval;
              if(responseDataLive.isInstrumentLive!=undefined && responseDataLive.isInstrumentLive){
                updateInterval=reloadInerval;
              }
              setIndexState((prevState) => ({
                ...prevState,
                refreshInterval:updateInterval,
                tableHeight:tableHeight,
                indexLoaded:true,
                indexData:processedData,
                pinnedData:pinnedList,
                lastDataTime:responseDataLive.lastDataTime,
                isTradingSessionActive:responseDataLive.isTradingSessionActive,
                isStatusCheckAllowed:(!responseDataLive.isInstrumentLive),
                statusCheckPollingInterval:(responseDataLive.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
                isInstrumentLive:responseDataLive.isInstrumentLive}));
                  
      }
      else if(errorLive!==null){
        setIndexState((prevState) => ({
          ...prevState,
          indexLoaded:true}));

        console.log("Error data=",errorLive);

          // setMsgState({open:true,msg:errorLive?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorLive?.response?.status === 401 || errorLive?.response?.status === 403){
            console.log("status received =",errorLive?.response?.status)
            // navigate(from, { replace: true });
            setLoginMsg(errorLive?.response?.data?.message);
            setShowLoginPopup(true);
          }
      }
      resetStatus();
    }
    },[loadedLive,responseDataLive]);
       
    
    const onGridReady = params => {
      setGridAPIOptions(params.api);
      setColumnAPIOptions(params.columnApi);
    };

  
   /**
 * autoclosing of the snackbar msg bar 
 */ 
    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };
  
  const setTickerHeight=(height)=>{
    console.log("ticker height=",height)
    setTickerTableHeight(height);
  }

  const openSettings=()=>{
    setShowSettings(true);
   }
  
   const cancelSettings=()=>{
    setShowSettings(false);
   }
  
   const saveSettings=(settings)=>{
    setShowSettings(false);
    console.log("INDEXSettings save settings in chart container",settings);
    setUserSettings(settings);
   }
  
   useEffect(()=>{
    let colDef=getColumneDef(auth,openMPChart,showSubscribeModal,userSettings.data.number_format_locale,userSettings.data.page_wise_hidden_columns.INDEX_COMPONENTS)
    if(isFut)
      colDef=getColumneDefFut(auth,openMPChart,showSubscribeModal,userSettings.data.number_format_locale,userSettings.data.page_wise_hidden_columns.INDEX_FUT_COMPONENTS)
    setIndexState((prevState) => ({
      ...prevState,
      columns:colDef,
      }));
    console.log("INDEX Settings useEffect called");
   },[userSettings])

  return (
    <div style={{width:"100%",backgroundColor:"#022D5A",zIndex:9}}>
      <TICKER_TABLE setTickerHeight={setTickerHeight} setHeight={setTickerHeight} showLoginPopup={()=>setShowLoginPopup(true)}/>
       {tabLoaded?
      <div id="tabs"style={{display:"flex"}}>
        <Tooltip title="Index Table Settings">
              <SettingsIcon onClick={openSettings}sx={{ml:1,marginRight:"12px", marginTop:"12px",height:20,width:20,cursor:'pointer'}}/>
          </Tooltip>
      <Tabs
        value={selectedInstrument}
        onChange={handleTabChange}
        size='small'
        sx={{height:24}}
        textColor="primary"
        indicatorsColor="primary"
        aria-label="secondary tabs example"
    >
      {tabData.map((item,index) => (
        <Tab sx={{height:24}} key={item.value} value={item.value} label={item.name} />
      ))}
   </Tabs>
   {lastDataTime!=undefined?
   <Box sx={{ml:6,marginTop:"12px"}}>
    <Typography>Time:&nbsp;{lastDataTime}</Typography>
   </Box>:<></>}
      </div>:
      <></>
      }

      {indexLoaded?
            
      // <div id="optionsTable" style={{marginTop:8,height:`calc(${tableHeight+32}px)`}}>
         <div id="indexTable" style={{marginTop:12,marginBottom:"40px", height:`calc(100vh - 90px)`}}>
          
      {/* <div className="ag-theme-alpine-dark" style={{height:`calc(${tableHeight}px)`, width: `calc(100% - 0px)`}}> */}
      <div className="ag-theme-alpine-dark" style={{height:`calc(100vh - 90px)`, width: `calc(100% - 0px)`}}>
           <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            // sideBar={true}
            rowHeight={isFut?72:36}
            headerHeight={44}
            gridOptions={gridOptions}
            rowData={indexData}
            pinnedTopRowData={pinnedData}
            groupHeaderHeight={30}
            suppressScrollOnNewData={true}
            components={frameworkComponents}
            columnDefs={columns}>
           </AgGridReact>
       </div>
      </div>:
      
      <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
      </div>}
    
    
    <FooterToolbar sx={{borderTop: `1px solid ${theme.palette.primaryTheme.shade03}` }} >
    
        <Container >
        
        <Typography  variant="copyRight" align="center" >
          {WATERMARK_CONFIG.TABLE_TEXT}
          </Typography>
        </Container>
      </FooterToolbar>
    <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{marginTop:"48px", width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>    

      {showPopup?
         <SubscriptionPopup open={showPopup} data={subscriptionPopupData} proMessage={false} onClose={()=>setShowPopup(false)}></SubscriptionPopup>  :
         <></>
      }
      {showSettings?
     <IndexSettings open={true}  cancelHandler={cancelSettings} saveHandler={saveSettings} ></IndexSettings>    :
     <></>
    }
     {showLoginPopup?
         <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>} 

    </div>
  )
}

