import { createContext, useState } from "react";

const AuthContext = createContext({});
/**
 * 
 * @param {*} param0 
 * @returns Authprovider for the whole app
 */
export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || true);  //TODO
    console.log("TestRefresh AuthProvider Auth=",auth);

    return (
        <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;