import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from '@mui/material';
import {TEXT_MSGS,PREMIUM_UPGRADE_MSGS_LIST} from '../../../common/utility/constant';
import { useNavigate } from "react-router-dom";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SubscriptionPopup(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(props.open);
  const [msgIndex,setMsgIndex]=React.useState(0);
  const [loaded,setLoaded]=React.useState(false);
//   const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    props.onClose();
}

React.useEffect(()=>{
  let val=JSON.parse(localStorage.getItem('upgrade_msg_index'));
  let index=Math.floor(Math.random()*PREMIUM_UPGRADE_MSGS_LIST.length);
  if(val)
    index=parseInt(val);

  if(index<PREMIUM_UPGRADE_MSGS_LIST.length-1)
  index=index+1
  else 
  index=0;
  setMsgIndex(index);
  setLoaded(true);
  localStorage.setItem("upgrade_msg_index",JSON.stringify(index));
},[])

const openSubscription=()=>{
  props.onClose();
  navigate('/subscription')
}
    

  return (
    
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {/* Upgrade to {props.proMessage != undefined && props.proMessage?<span style={{color: '#E7B401', fontWeight:600}}>{TEXT_MSGS.SUBSCRIPTION_PRO_POPUP_TITLE}</span>:<><span style={{color: '#23B1BA', fontWeight:600}}>{TEXT_MSGS.SUBSCRIPTION_POPUP_TITLE}</span><span> or higher!</span></>} */}
            Upgrade now!
          </Typography>
          {props.data && props.data.isCustom?<>
            <Typography id="modal-modal-description" sx={{ mt: 2,mb:2 }}>
              {props.data.msg}
            </Typography>
          </>:<>
          {props.proMessage != undefined && props.proMessage?
            <Typography id="modal-modal-description" sx={{ mt: 2,mb:2 }}>
              {TEXT_MSGS.PREMIUM_PLUS_UPGRADE_MSG}
            </Typography>
            :
            <>
            {loaded?
            <Typography id="modal-modal-description" sx={{ mt: 2,mb:2 }}>
              {PREMIUM_UPGRADE_MSGS_LIST[msgIndex]}
            </Typography>:
            <></>
            }
            </>
          }
          </>
        }
          
          <Typography variant="linkSupportText" sx={{mt:2}}>
              {/* <Link onClick={()=>openSubscription()} sx={{fontSize:14, pt:1,cursor:"pointer"}} variant="h4">Upgrade Now</Link> */}
              <Button variant="contained" onClick={()=>openSubscription()} sx={{mr:2}}>
              Upgrade Now
              </Button>
          </Typography>
        </Box>
      </Modal>

  );
}