//OF WITH Live polling and panning issues
import React, { useEffect,useContext } from 'react'
import '../styles/styles.scss';
import { useState,useLayoutEffect,useRef,useMemo,useCallback } from 'react';
import * as d3 from 'd3';
import {CONSTANTS, SNACKBAR_AUTO_HIDE_DURATION_SHORT,ROTATING_COT_COUNT} from '../../../common/utility/constant';
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import ContextMenu from './contextMenu';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION,AUTO_CENTER_CONFIG } from '../../../common/utility/constant';
import {Snackbar, Alert,CircularProgress, Typography,Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import { TIME_FRAME_VALUES,OF_TIME_FRAME_VALUES,OF_HIGHLIGHT_CONSTANT,OF_ZOOM_TYPE,OF_BOTTOM_COL_LIST } from '../../../common/utility/constant';
import { CHART_COLORS,DIMENSIONS ,CHART_FONT_DEFAULT,CHART_FONT_ITALIC_DEFAULT,CHART_FONT_BOLD_OF_VOL,CHART_FONT_OF_VOL_DEFAULT,OF_TABLE_FONT_DEFAULT, OF_CHART_LINE_WIDTH, OF_NPOC_MIN_PROFILE_DIFFERENCE, OF_MARKER_STYLING_CATEGORY, KRL_CATEOGRY_TYPE, CHART_TYPE} from '../../../common/utility/mp_chart_constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { WATERMARK_CONFIG } from '../../../common/utility/constant';
import { UserSettingsContext } from '../../../setup/routes-manager/RequireAuth';
import ConfirmActionDialog from './confirm_dialog';
import { useInterval } from '../../../common/api/hooks/useInerval';
import Tooltip from './mptooltip';
import { getRandomHexColor, rgbToHex } from '../../../common/utility/helperFunc';
import YAxisContextMenu from './mpYaxisContextMenu';
import dayjs from 'dayjs';
import OFLiveModal from './orderflow_live_modal';
import OFFABModal from './orderflow_fab_modal';
import ASTERISK_IMAGE from '../../../assets/images/asterisk.png';
import { VWAP } from '@amcharts/amcharts5/stock';
import OFHelpPopup from './orderflow_help_popup';


const benchmarkingEnabled=process.env.NODE_ENV != "production"


//constants for MP chart
const PROFILE_RELOAD_FACTOR=1;
const DEFAULT_PROFILE_WIDTH=60;
const DEFAULT_PROFILE_WIDTH_WEEKLY=100;
const DEFAULT_PROFILE_WIDTH_MONTHLY=120;
const DEFAULT_PROFILE_WIDTH_YEARLY=400;
const LIVE_PROFILE_MIN_WIDTH=200;
const TPO_WIDTH=14;
const TPO_HEIGHT=16;
const XSCALE_HEIGHT=24;
const YSCALE_WIDTH=60;
const PROFILE_LEFT_MARGIN=20;
const PROFILE_RIGHT_MARGIN=20;
const LOAD_MORE_PAN_PERCENT=0.8;  //i.e when 80% of total profile width reached, load more profiles
const LOAD_MORE_ZOOM_OUT_PERCENT=0.8; //i.e when 80% of zoom out value reached, load more profiles 
const ZOOM_OUT_EXTREME=0.3;
const ZOOM_OUT_EXTREME_LESS_PROFILE=0.4;
const CONTEXT_MENU_HEIGHT=200;
const CONTEXT_MENU_WIDTH=160;
const CHART_TOP_SPACE=104; //header+settings+xscale height
const PROFILE_MIN_WIDTH=120;
const PROFILE_LOADING_FACTOR_ZOOM_OUT=1;
const VOLUME_PROFILE_TPO_LIMIT=0.1; //recalculate vpList with this factor and vp_tpo
const TOOLTIP_HEIGHT=100;
const TOOLTIP_MENU_WIDTH=145;
const YSCALE_MAX_ZOOM=1.9;
const XSCALE_MAX_ZOOM=1.9;
const XSCALE_ZOOM_OUT_DISPLAY=0.55;
const YSCALE_ZOOM_OUT_DISPLAY=0.55;
const DEFAULT_LTP_POSITION=0.4;     //40% from the top
const LTP_POSITION_ALLOWED_CHANGE=0.2; //20% from top or bottom


function OFChart (props) {
  // console.log("props in mp chart=",props);
    const location = useLocation();
    const from = "/login";
    const navigate = useNavigate();
    const [isFirstLoad,setIsFirstLoad]=useState(true);
    const [chartMidTPO,setChartMidTPO]=useState(-1);
    const [isRecenterAllowed,setIsRecenterAllowed]=useState(true);
    

    const [historicalData, setHistoricalData] = useState();
    const historicalDataRef = useRef(historicalData);
    const [priceTableData, setPriceTableData] = useState();
    const [metaData, setMetaData] = useState();
    const [selectedInstrument,setSelectedInstrument]=useState(props.instrument)
    const selectedInstrumentRef=useRef(selectedInstrument)
    const [selectedTPO,setSelectedTPO]=useState(props.tpo);
    const selectedTPORef=useRef(selectedTPO);
    const [isInstrumentLive,setInstrumentLive]=useState(props.isInstrumentLive)
    const [transformData,setTransformData]=useState({x:0,y:0,k:1});
    const transformDataRef=useRef(transformData);
    const [transformDataY,setTransformDataY]=useState({x:0,y:0,k:1});
    const transformDataRefY=useRef(transformDataY);
    const [transformDataAutoCenter,setTransformDataAutoCenter]=useState({x:0,y:0,k:1});
    const transformDataAutoCenterRef=useRef(transformDataAutoCenter);
    const [showContextMenu,setShowContextMenu]=useState(false);
    const [showTooltip,setShowTooltip]=useState(false);
    const [tooltipData,setTooltipData]=useState();
    const contextMenuRef=useRef(showContextMenu);
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [anchorPointTooltip, setAnchorPointTooltip] = useState({ x: 0, y: 0 });
    const [selectedProfiles,setSelectedProfiles]=useState([]);
    const [selectedProfilesIndex,setSelectedProfilesIndex]=useState([]);
    const [currentIndex,setCurrentIndex]=useState();
    const [isLoadingMoreProfiles,setIsLoadingMoreProfiles]=useState(false);
    const [cancel,responseData, error, loaded, reset,executeAPI]  = useAxiosWithAuth();
    const windowSize=useWindowSize();
    const [toggleRepaint,setToggleRepaint]=useState(true);
    const [chartInterationAllowed,setChartInteractionAllowed]=useState(true);
    const chartInterationAllowedRef=useRef(chartInterationAllowed);
    const [canvasWidth,setCanvasWidth]=useState();
    const [refreshChart, setRefreshChart]=useState(false);
    const [crossHairCord, setCrossHairCord] = useState({ x: -1, y: -1,tpo:-1 });
    const [isMoreDataLoading,setIsMoreDataLoading]=useState(false);
    const [moreDataPresent,setMoreDataPresent]=useState(true);
    const [autoCenter,setAutocenter]=useState(props.autoCenter);
    const autoCenterRef=useRef(autoCenter);
    const [compositeData,setCompositeData]=useState({
      dstr: undefined,
      nDays:0 ,
      startDate:"",
      startIndex:-1,
      endIndex:-1,
      dstrMap:[],
      liveData:false,
      liveComposite:false
    });
    const [sessionActive,setSessionActive]=useState(props.isTradingSessionActive)
    const [liveDataComposite,setLiveComposite]=useState(false);
    const [bookmarkData,setBookmarkData]=useState(props.bookmarkData);
    const [selectedInstrumentData,setSelectedInstrumentData]=useState(props.selectedInstrumentData);
    const selectedInstrumentDataRef=useRef(selectedInstrumentData);
    const [selectedTimeFrame,setSelectedTimeFrame]=useState(props.selectedTimeFrame)
    const selectedTimeFrameRef=useRef(selectedTimeFrame);
    const globalVolumeVisibleRef=useRef();
    const volumeNumberVisibleRef=useRef();
    const profileVolumeNumberVisibleRef=useRef();
    const vpTypeRef=useRef();
    const tpoBasedVARef=useRef();
    const isTradingSessionActiveRef=useRef();
    const [anchorPointYAxis, setAnchorPointYAxis] = useState({ x: 0, y: 0 });
    const [showYAxisContextMenu,setShowYAxisContextMenu]=useState(false);
    const yaxiscontextMenuRef=useRef(showYAxisContextMenu);
    const [priceVal,setPriceVal]=useState();
    const [levelData,setLevelData]=useState(props.levelData)
    const [openSetLeveleDialog,setOpenSetLevelDialog]=useState(false);
    const [openDeleteLeveleDialog,setOpenDeleteLevelDialog]=useState(false);
    const [tableVisible,setTableVisible]=useState(props.bottomTableVisible);
    const [livePanelVisible,setLivePanelVisible]=useState(props.livePanelVisible);
    const [isZooming,setIsZooming]=useState(false);
    const [savedLiveData,setSavedLiveData]=useState();
    const savedLiveDataRef=useRef(savedLiveData);
    const { userSettings, setUserSettings } = useContext(UserSettingsContext);
    const [showHelpPopup,setShowHelpPopup]=useState(false)
    const [zoomType,setZoomType]=useState(userSettings.data.of_zoom_type?userSettings.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC);
    const [showLLTMarker,setShowLLTMarker]=useState(true);
    const [rotatingCot,setRotatingCot]=useState(undefined);
    const [ofBottomTableHiddenCol,setOfBottomTableHiddenCol]=useState(userSettings.data.of_bottom_col_hidden_list?userSettings.data.of_bottom_col_hidden_list:[]);

   
    const fontFile = new FontFace(
      
      "Roboto Flex",
      
      'url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf9-EmF8tqHbE.woff2) format("woff2")',
      { stretch: "25% 151%" }
      
      // "Inconsolata",
      // 'url(https://fonts.googleapis.com12/css2?family=Inconsolata:wdth,wght@50..200,200..900)', 
  // 'url(https://fonts.gstatic.com/s/inconsolata/v31/QlddNThLqRwH-OJ1UHjlKENVzlm-WkL3GZQmAwPyya15.woff2) format("woff2")',
  // { stretch: "50% 200%" }
    );
  
   
    

    // const { userSettings, setUserSettings } = useContext(UserSettingsContext);
    // const [LOCALE_STRING, setLocaleString] = useState(userSettings.data.number_format_locale);

    // console.log(props.locale_string)
    
    //margins for chart and axis
    const margin = {top: 24, right: 60, bottom: 20, left: 0};
   
    const [msgState, setMsgState] = useState({
      open: false,
      msg:"" ,
      severity:"info"
    });
  
    const { open, msg, severity } = msgState;

    const theme=useTheme();

    const saveChartState=()=>{
      if(props.saveChartState)
        props.saveChartState(getChartStateData());
    }

    useEffect(()=>{
      document.fonts.add(fontFile);
    },[])


    useEffect(()=>{
      setLivePanelVisible(props.livePanelVisible);

      // props.setShowLivePanel(false);
    },[props.livePanelVisible])

    useEffect(()=>{
      saveChartState();
    },[livePanelVisible])

   useEffect(()=>{
    if(historicalData!=undefined && historicalData.profilesData!=undefined){
      const reversedArray=historicalData.profilesData.slice().reverse();
     
      setPriceTableData(reversedArray);
      let count=0;
      let sum=0;
    
      for(let i=historicalData.config.data.length-1;i>=0;i--){
        sum=sum+historicalData.config.data[i].cot;
        count=count+1;
         if(count==ROTATING_COT_COUNT){
         break;
        }
      }
      setRotatingCot(sum);
      console.log("Price Reversed array=======>",historicalData,reversedArray,historicalData.profilesData[historicalData.profilesData.length-1],props.selectedInstrumentTPOData,sum);
    }
   },[historicalData])

    useEffect(()=>{
      //is trading session goes true to false then reload the live profile data as historical data
      console.log("trading session changed")
      if(sessionActive==true &&props.isTradingSessionActive==false){
       console.log("refresh chart is called session change")
        setRefreshChart(true);
      }
     setSessionActive(props.isTradingSessionActive)
     
    },[props.isTradingSessionActive])


    useEffect(()=>{
      chartInterationAllowedRef.current=chartInterationAllowed;
      // console.log("Ref for chart interaction val ",chartInterationAllowedRef.current)
    },[chartInterationAllowed])

    useEffect(()=>{
      savedLiveDataRef.current=savedLiveData;
      // console.log("Ref for chart interaction val ",chartInterationAllowedRef.current)
    },[savedLiveData])

    useEffect(()=>{
      // bookmarkDataRef.current=bookmarkData;
    },[bookmarkData])

    useEffect(()=>{
      historicalDataRef.current=historicalData;
      saveChartState();
    },[historicalData])

    useEffect(()=>{
      selectedInstrumentRef.current=selectedInstrument;
      saveChartState();
    },[selectedInstrument])

    useEffect(()=>{
      selectedTPORef.current=selectedTPO;
      saveChartState();
    },[selectedTPO])
    
    useEffect(()=>{
      selectedTimeFrameRef.current=selectedTimeFrame;
      saveChartState();
    },[selectedTimeFrame])

    useEffect(()=>{
      globalVolumeVisibleRef.current=props.globalVolumeVisible;
      saveChartState();
    },[props.globalVolumeVisible])

    useEffect(()=>{
      volumeNumberVisibleRef.current=props.volumeNumberVisible;
      saveChartState();
    },[props.volumeNumberVisible])
    
    useEffect(()=>{
      profileVolumeNumberVisibleRef.current=props.profileVolumeNumberVisible;
    },[props.profileVolumeNumberVisible])

    useEffect(()=>{
      vpTypeRef.current=props.vpType;
    },[props.vpType])

    useEffect(()=>{
      tpoBasedVARef.current=props.tpoBasedVA;
    },[props.tpoBasedVA])

    useEffect(()=>{
      isTradingSessionActiveRef.current=props.isTradingSessionActive;
    },[props.isTradingSessionActive])

   
    useEffect(()=>{
      selectedInstrumentDataRef.current=selectedInstrumentData;
    },[selectedInstrumentData])

    useEffect(()=>{
      setSelectedInstrumentData(props.selectedInstrumentData);
    },[props.selectedInstrumentData])

    useEffect(()=>{
      autoCenterRef.current=props.autoCenter;
    },[props.autoCenter])

    useEffect(()=>{
      contextMenuRef.current=showContextMenu;
    },[showContextMenu])

    useEffect(()=>{
      yaxiscontextMenuRef.current=showYAxisContextMenu;
    },[showYAxisContextMenu])
   
    useEffect(()=>{
      transformDataRef.current=transformData;
      console.log("transformDataRef=",transformDataRef.current)
    },[transformData])

    useEffect(()=>{
      transformDataRefY.current=transformDataY;
      console.log("transformDataRef=",transformDataRef.current)
    },[transformDataY])
   
    useEffect(()=>{
      transformDataAutoCenterRef.current=transformDataAutoCenter;
      // console.log("transformDataRef=",transformDataRef.current)
    },[transformDataAutoCenter])
   
    useEffect(()=>{
      if(historicalData!=undefined){
      setTransformData({k:1,x:0,y:0});
      setTransformDataY({k:1,x:0,y:0});
      setToggleRepaint(!toggleRepaint);
      }
     
    },[props.resetChart])

    useEffect(()=>{
      if(historicalData!=undefined && historicalData.profilesData!=undefined){
        console.log("Reload OF Settings useEffect called OF chart",props.userSettings);
       setToggleRepaint(!toggleRepaint);
      }
     },[props.userSettings])
    
    //handler for TPO change     
   useLayoutEffect(()=>{
    if(props.tpo!=selectedTPO){
      console.log("OFSETTINGS tpo changed received by MP Chart")
      setSelectedTPO(props.tpo);
    }
   },[props.tpo,props.chartData]);


   useLayoutEffect(()=>{
    if(historicalData!=undefined){
      const processedDataOF = parseOFData(props.chartData,true,undefined);
      // setIsRecenterAllowed(true);
      setHistoricalData(processedDataOF);
      saveChartState();
    }
   },[selectedTPO]);

   //handler for instrument change
   useLayoutEffect(()=>{
    console.log("Instrument changed received by MP Chart1")
     
    //reset the chart transform when we change the instrument
      if(selectedInstrument!=props.instrument){
      setTransformData({k:1,x:0,y:0});
      setTransformDataY({k:1,x:0,y:0})
      setCrossHairCord({x:-1,y:-1,tpo:-1});
      console.log("Live panel Instrument changed received by MP Chart2")
      console.log("parseData 3===============================================>")
      // const processedVPList=parseVPList(props.chartData);
      const processedData=parseOFData(props.chartData,true,undefined);
      if(props.chartData.mdata && props.chartData.mdata.length>0)    //only use for 1st container
        setMetaData(props.chartData.mdata[props.chartData.mdata.length-1]);
      
        const isLiveSession=( processedData.config.lastDataTime!=undefined && processedData.config.lastDataTime!=null && props.isTradingSessionActive)?true:false;
        props.setRightContainerData(false,props.chartData.metadata,processedData,[processedData.profilesData.length-1],isLiveSession)



      // setProcessedVPList(processedVPList);
      setHistoricalData(processedData);
      
      setSelectedInstrument(props.instrument);
      }
   },[props.chartData,props.instrument])

   //handler for live data
   useLayoutEffect(()=>{
    if(props.liveData!=undefined && props.isPollingAllowed&& historicalData!=undefined && historicalData.profilesData!=undefined && chartInterationAllowed){
      // let processedVPList=parseVPList(props.liveData);
      
     
      
      
      //remove the duplicate live data is present and keep the latest one
      if(props.liveData.of_data && props.liveData.of_data[0]){
        let length=props.liveData.of_data[0].length;
        props.liveData.of_data[0].reduceRight((acc, obj, i) => {
          acc[obj.time]?  props.liveData.of_data[0].splice(i, 1) : acc[obj.time] = true;
          return acc;
        }, Object.create(null));  

        if(length!=props.liveData.of_data[0].length)
        console.log("ALERT OF Duplicate Data in Live OF detected");
    }

  //  let modified=false;
  //   if(props.liveData.of_data[0] && props.chartData.dateList[props.chartData.dateList.length-1]== props.liveData.dateList[0]){
  //     console.log("OF test live ",props.liveData.of_data[0])
  //     for(let i=0;i< props.liveData.of_data[0].length;i++){
  //       console.log("OF test live ",props.liveData.of_data[0][i].time<props.chartData.of_data[props.chartData.of_data.length-1][props.chartData.of_data[props.chartData.of_data.length-1].length-1].time)
  //       if(props.liveData.of_data[0][i].time< props.chartData.of_data[props.chartData.of_data.length-1][props.chartData.of_data[props.chartData.of_data.length-1].length-1].time){
  //         console.log("ALERT OF OLD Data in Live OF detected",i,props.liveData.of_data[0],props.chartData.of_data,props.liveData.of_data[0][i].time,props.chartData.of_data[props.chartData.of_data.length-1][props.chartData.of_data[props.chartData.of_data.length-1].length-1].time);
  //         props.liveData.of_data[0].shift();
  //         modified=true;
  //       }
  //     }
  //   }

  //   if(modified)
  //   console.log("ALERT OF OLD Data in Live OF detected: Modified live data",props.liveData.of_data[0]);

      
      
      const processedLiveData=parseOFData(props.liveData,false,undefined,true);

      // if(props.liveData.metadata!=null & props.liveData.metadata!=undefined && props.key==0)///only use for 1st container
      setMetaData(props.liveData.mdata[props.liveData.mdata.length-1]);

      console.log("live data received data=",props.liveData.of_data[0]);
      mergeLiveData(processedLiveData,isInstrumentLive);
      setChartInteractionAllowed(true);
      setInstrumentLive(true);
    }
   
   },[props.liveData1])
   //handler for live data
  //  useLayoutEffect(()=>{
  //   if(props.liveData!=undefined && props.isPollingAllowed&& historicalData!=undefined && historicalData.profilesData!=undefined && chartInterationAllowed){
  //     if(!isZooming){
  // //     // let processedVPList=parseVPList(props.liveData);
      
     
      
      
  // //     //remove the duplicate live data is present and keep the latest one
  // //     if(props.liveData.of_data && props.liveData.of_data[0]){
  // //       let length=props.liveData.of_data[0].length;
  // //       props.liveData.of_data[0].reduceRight((acc, obj, i) => {
  // //         acc[obj.time]?  props.liveData.of_data[0].splice(i, 1) : acc[obj.time] = true;
  // //         return acc;
  // //       }, Object.create(null));  

  // //       if(length!=props.liveData.of_data[0].length)
  // //       console.log("ALERT OF Duplicate Data in Live OF detected");
  // //   }

  // //  let modified=false;
  // //  let count=0;
  // //   if(props.liveData.of_data[0] && props.chartData.dateList[props.chartData.dateList.length-1]== props.liveData.dateList[0]){
  // //     console.log("OF test live ",props.liveData.of_data[0])
  // //     for(let i=0;i< props.liveData.of_data[0].length;i++){
  // //       console.log("OF test live ",props.liveData.of_data[0][i].time<props.chartData.of_data[props.chartData.of_data.length-1][props.chartData.of_data[props.chartData.of_data.length-1].length-1].time)
  // //       if(props.liveData.of_data[0][i].time< props.chartData.of_data[props.chartData.of_data.length-1][props.chartData.of_data[props.chartData.of_data.length-1].length-1].time){
  // //         console.log("ALERT OF OLD Data in Live OF detected",i,props.liveData.of_data[0],props.chartData.of_data,props.liveData.of_data[0][i].time,props.chartData.of_data[props.chartData.of_data.length-1][props.chartData.of_data[props.chartData.of_data.length-1].length-1].time);
  // //         // props.liveData.of_data[0].shift();
  // //         count=count+1;
  // //         modified=true;
  // //       }
  // //     }
  // //   }

  // //   if(modified)
  // //   console.log("ALERT OF OLD Data in Live OF detected: Modified live data",props.liveData.of_data[0]);

  // //   let modObj = JSON.parse(JSON.stringify(props.liveData));
  // //   if(count>0){
  // //     console.log("ALERT OF ALERT  1 OF OLD Data in Live OF detected: count,modified data=",count,props.liveData,modObj);
  // //   modObj.of_data[0].splice(0,count);
  // //   console.log("ALERT OF ALERT  2 OF OLD Data in Live OF detected: count,modified data=",count,props.liveData,modObj);
  // //   }

    

      
  // //     if(modObj.of_data[0] && modObj.of_data[0].length>0){
  // //       // const processedLiveData=parseOFData(props.liveData,false,undefined,true);
  // //       const processedLiveData=parseOFData(modObj,false,undefined,true);

  // //       // if(props.liveData.metadata!=null & props.liveData.metadata!=undefined && props.key==0)///only use for 1st container
  // //       setMetaData(props.liveData.metadata);

  // //       console.log("live data received data=",props.liveData.of_data[0],modObj);
  // //       mergeLiveData(processedLiveData,isInstrumentLive,modObj);
  // //       setChartInteractionAllowed(true);
  // //       setInstrumentLive(true);
  // //     }
  //       setSavedLiveData(undefined)
  //       console.log("OF Pan Test: livedata is drawing===>")
  //       handleLiveData(props.liveData)
  //     }else{
  //       console.log("OF Pan Test: livedata is saved for future",props.liveData)
  //       setSavedLiveData(props.liveData)
        
  //     }
  //   }
   
  //  },[props.liveData])

  useLayoutEffect(()=>{
    if(props.liveData!=undefined && props.isPollingAllowed&& historicalData!=undefined && historicalData.profilesData!=undefined && chartInterationAllowed){
      if(!isZooming){
 
        setSavedLiveData(undefined)
        
        handleLiveData(props.liveData)
      }else{
        console.log("OF Pan Test: livedata is saved for future",props.liveData)
        if(savedLiveData==undefined)
          setSavedLiveData(props.liveData)
        else{
          let oldData= JSON.parse(JSON.stringify(savedLiveData));
          let newData=JSON.parse(JSON.stringify(props.liveData));
          newData.of_data[0].forEach(newItem => {
            const index = oldData.of_data[0].findIndex(savedItem => 
              savedItem.date === newItem.date && savedItem.time === newItem.time
            );
          
            if (index !== -1) {
              // Update existing entry
              console.log("CandleIssue updating existing entry=",oldData.of_data[0][index],newItem)
              oldData.of_data[0][index] = { ...oldData.of_data[0][index], ...newItem };
            } else {
              // Append new entry
              console.log("CandleIssue append new data=",oldData.of_data[0],newItem)
              oldData.of_data[0].push(newItem);
            }
          });

          setSavedLiveData(oldData);
        }
        
      }
    }
   
   },[props.liveData])

   const handleLiveData=(liveData)=>{
    setSavedLiveData(undefined)
    if(liveData.of_data && liveData.of_data[0]){
      let length=liveData.of_data[0].length;
      liveData.of_data[0].reduceRight((acc, obj, i) => {
        acc[obj.time]?  liveData.of_data[0].splice(i, 1) : acc[obj.time] = true;
        return acc;
      }, Object.create(null));  

      if(length!=liveData.of_data[0].length)
      console.log("ALERT OF Duplicate Data in Live OF detected");
  }

 let modified=false;
 let count=0;
  if(liveData.of_data[0] && props.chartData.dateList[props.chartData.dateList.length-1]== liveData.dateList[0]){
    console.log("OF test live ",liveData.of_data[0])
    for(let i=0;i< liveData.of_data[0].length;i++){
      console.log("OF test live ",liveData.of_data[0][i].time<props.chartData.of_data[props.chartData.of_data.length-1][props.chartData.of_data[props.chartData.of_data.length-1].length-1].time)
      if(liveData.of_data[0][i].time< props.chartData.of_data[props.chartData.of_data.length-1][props.chartData.of_data[props.chartData.of_data.length-1].length-1].time){
        console.log("ALERT OF OLD Data in Live OF detected",i,liveData.of_data[0],props.chartData.of_data,liveData.of_data[0][i].time,props.chartData.of_data[props.chartData.of_data.length-1][props.chartData.of_data[props.chartData.of_data.length-1].length-1].time);
        // props.liveData.of_data[0].shift();
        count=count+1;
        modified=true;
      }
    }
  }

  if(modified)
  console.log("ALERT OF OLD Data in Live OF detected: Modified live data",liveData.of_data[0]);

  let modObj = JSON.parse(JSON.stringify(liveData));
  if(count>0){
    console.log("ALERT OF ALERT  1 OF OLD Data in Live OF detected: count,modified data=",count,liveData,modObj);
  modObj.of_data[0].splice(0,count);
  console.log("ALERT OF ALERT  2 OF OLD Data in Live OF detected: count,modified data=",count,liveData,modObj);
  }

  

    
    if(modObj.of_data[0] && modObj.of_data[0].length>0){
      // const processedLiveData=parseOFData(props.liveData,false,undefined,true);
      const processedLiveData=parseOFData(modObj,false,undefined,true);

      // if(props.liveData.metadata!=null & props.liveData.metadata!=undefined && props.key==0)///only use for 1st container
      setMetaData(liveData.metadata);

      console.log("live data received data=",liveData.of_data[0],modObj);
      mergeLiveData(processedLiveData,isInstrumentLive,modObj);
      setChartInteractionAllowed(true);
      setInstrumentLive(true);
    }
   }


     
  //processes the api response data 
  const parseOFData=(_data,isFirstLoad=false,processedVPList=undefined,isLive=false,isTPOChanged=false)=>{
    console.log("parseData= viewState=",props.viewState);
    // console.log("processedVPList in parseData =",processedVPList);
        const profileData=[];
        let tempDateList=[];
        let tempTimeList=[];
        const config={
          totalWidth:0,  
          dateList:_data.dateList,
          max:Number.MIN_VALUE,
          min:Number.MAX_VALUE,
          lastDataTime:_data.lastDataTime,
          timeList:undefined,
          widthList:[],
          data:[],
          tpo:selectedTPO
        }
        
        let tempIndex=0;
        _data.of_data.forEach((ofData, index) => {
           // if(!(ofData[0].vbd!=null && ofData[0].vbd!=undefined && ofData[0].vbd.length>0)){         //TODO OF check if it required later
           if(ofData[0].vbd!=undefined && ofData[0].vbd.length>0 ){         //TODO OF check if it required later
          console.log("ofData val= ",ofData,ofData[0])
          let tempData=[];
         
          profileData[index]={
            date:_data.dateList[index],
            vbdData:[],
            showTPO:true,
            isTPOonVolume:false,
            isVolumeProfile:false,
            showOnlyVolumeProfile:false,
            isStacked:false,
            width:0,
            maxTPO:Number.MIN_VALUE,
            minTPO:Number.MAX_VALUE,
            count:0,
            profileIndex:index,
            totalVolume:0,
            startIndex:tempIndex,
            mdata:_data.mdata[index]
            
            
          }

          let divisionFactor=1;
          if(props.contracts){
            if(!_data.ls[index])
            console.log("ALERT !!!!!   Lotsize issue, index, ls=",index,_data.ls[index],_data.ls[_data.ls.length-1])
            divisionFactor=_data.ls[index]?_data.ls[index]:_data.ls[_data.ls.length-1];
          }

          if(divisionFactor==undefined)
          divisionFactor=1;

          // console.log("test contracts =",props.contracts,_data.ls[index])
          
         

            tempDateList.push(_data.dateList[index])

            let count=0;
            let tempVolume=0;
            let lotSize=_data.ls[index]?_data.ls[index]:_data.ls[_data.ls.length-1];
            ofData.forEach((item, mainIndex) => {  
              //TODO: Testing purpose remove later
              // if(mainIndex>9) 
              //   return;

              if(item.open==0 && item.close==0 && item.high==0 && item.low==0)
              return;
              
              if(mainIndex!=0)
              tempTimeList.push(_data.dateList[index].substr(0, 5)+" "+item.time);
                // tempTimeList.push(item.time); 
              else
              tempTimeList.push(_data.dateList[index].substr(0, 5)+" "+item.time);
                // tempTimeList.push(_data.dateList[index]+" "+item.time);

              count=count+1;
              
            
         
          
         
         
          config.max=Math.max(config.max,item.high);
          config.min=Math.min(config.min,item.low);
            // console.log("isFirstload mainindex length = ",isFirstLoad,mainIndex,_data.dateList[mainIndex])
            //handling for pre open ticks
            let max=parseFloat(((item.high*10000)/10000).toFixed(4));
            let maxMod=parseFloat(((item.high*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let min=parseFloat(((item.low*10000)/10000).toFixed(4));
            let minMod=parseFloat(((item.low*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let maxTPO=parseFloat((max-maxMod).toFixed(4));
            let minTPO=parseFloat((min-minMod).toFixed(4));
            let open=parseFloat(((item.open*10000)/10000).toFixed(4));
            let openMod=parseFloat(((item.open*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let close=parseFloat(((item.close*10000)/10000).toFixed(4));
            let closeMod=parseFloat(((item.close*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let openTPO=parseFloat((open-openMod).toFixed(4));
            let closeTPO=parseFloat((close-closeMod).toFixed(4));
            
            let ibHigh=parseFloat(((item.ib_high*10000)/10000).toFixed(4));
            let ibHighMod=parseFloat(((item.ib_high*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let ibHighTPO=parseFloat(((ibHigh-ibHighMod)).toFixed(4));
           
            let ibLow=parseFloat(((item.ib_low*10000)/10000).toFixed(4));
            let ibLowMod=parseFloat(((item.ib_low*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let ibLowTPO=parseFloat((ibLow-ibLowMod).toFixed(4));
           
          

            let vpocTPO=undefined;
            if(item.vpoc!=undefined && item.vpoc!=null){
              let vpoc=parseFloat(((item.vpoc*10000)/10000).toFixed(4));
              let vpocMod=parseFloat(((item.vpoc*10000)%(selectedTPO*10000))/10000).toFixed(4);
              vpocTPO=parseFloat((vpoc-vpocMod).toFixed(4));
            }
            
            let vwapTPO=undefined;
            if(item.vwap!=undefined && item.vwap!=null){
              let vwap=parseFloat(((item.vwap*10000)/10000).toFixed(4));
              let vwapMod=parseFloat(((item.vwap*10000)%(selectedTPO*10000))/10000).toFixed(4);
              vwapTPO=parseFloat((vwap-vwapMod).toFixed(4));
            }
            

           
            tempData[mainIndex]={
                maxTPO:maxTPO,
                minTPO:minTPO,
                data:[],
                divisionFactor:divisionFactor,
                // isStacked:false,
                // showTPO:true,
                date:_data.dateList[index],
                volumeData:[],
                high:maxTPO,
                low:minTPO,
                open:item.open,
                openTPO:openTPO,
                close:item.close,
                closeTPO:closeTPO,
                vwap:item.vwap,
                vpoc:item.vpoc,
                vwapTPO:vwapTPO,
                vpocTPO:vpocTPO,
                tpo:selectedTPO,
                oi:Math.round(item.oi/divisionFactor),
                vol:Math.round(item.vol/divisionFactor),
                selected_tf:props.selectedTimeFrame,
                highVal:item.high,
                lowVal:item.low,
                time:item.time,
                cumu_vpoc_vol:item.cumu_vpoc_vol,
                cumu_vol:item.cumu_vol,
                cumu_vpoc:item.cumu_vpoc,
                cumu_vwap:item.cumu_vwap,
                vpoc_vol:Math.round(item.vpoc_vol/divisionFactor),
                vwap_sd2_up:item.vwap_sd2_up,
                vwap_sd2_dn:item.vwap_sd2_dn,
                vwap_sd3_up:item.vwap_sd3_up,
                vwap_sd3_dn:item.vwap_sd3_dn,
                signal:item.signal,
                cot:Math.round(item.cot/divisionFactor),
                width:0,
                isStacked:false,
                // doi:(mainIndex==0?"NA":(Math.round(item.oi/divisionFactor)-ofData[mainIndex-1].oi)),
                doi:(item.doi?Math.round(item.doi/divisionFactor):"NA"),
                index:mainIndex,
                profileIndex:index,
                maxBuyVol:-1,
                maxSellVol:-1,
                high_range:item.high_range,
                low_range:item.low_range
          }
         
         

         
          // console.log("parseData ===>profileData[mainIndex]=",profileData[mainIndex]);
            let j=-1;
            let maxNoOfElement=-1;  //keep track the max number of element for a particular TPO for stacked profile
            for(let i=tempData[mainIndex].minTPO;i<=tempData[mainIndex].maxTPO;i=parseFloat((i+selectedTPO).toFixed(4)))
            {   
                j=j+1;
                tempData[mainIndex].data[j]=[];
                let buy_vol=0;
                let sell_vol=0;
                // eslint-disable-next-line no-loop-func
                let bigBuyerData=[];
                let bigSellerData=[];
                item.vbd.forEach((perVBDProfile, tempIndex) => {
                 
                //  console.log("openval=", parseFloat(perTPOProfile.open.toFixed(4)) ,i) 
                let val=((perVBDProfile[0]*10000)/10000).toFixed(4);
                let valMod=parseFloat(((perVBDProfile[0]*10000)%(selectedTPO*10000))/10000).toFixed(4);
                let valTPO=parseFloat((val-valMod).toFixed(4))
                
                // console.log("per tpo val max min",i,minTPO,maxTPO);
                
                if(i==valTPO)
                    {
                      sell_vol=sell_vol+perVBDProfile[1];
                       buy_vol=buy_vol+perVBDProfile[2];
                      //  console.log("BIG BUYER SELLER  1  =======",lotSize);
                      if(props.selectedInstrumentTPOData && props.selectedInstrumentTPOData.llt_filter && (perVBDProfile[1]/lotSize)>props.selectedInstrumentTPOData.llt_filter){
                        bigSellerData.push({level:valTPO,tpo: perVBDProfile[0],vol: perVBDProfile[1],lot:perVBDProfile[1]/lotSize})
                      }
                      if(props.selectedInstrumentTPOData && props.selectedInstrumentTPOData.llt_filter && (perVBDProfile[2]/lotSize)>props.selectedInstrumentTPOData.llt_filter){
                        bigBuyerData.push({level:valTPO,tpo: perVBDProfile[0],vol: perVBDProfile[2],lot:perVBDProfile[2]/lotSize})
                      }
                     
                       
                       
                    }
                });
               
                let temp_buy_vol=buy_vol;
                let temp_sell_vol=sell_vol;
                let ls=_data.ls[index];
                // console.log("Issue test",j,mainIndex,tempData[mainIndex],tempData[mainIndex].data[j-1])
                let prevSellVol=j>0?tempData[mainIndex].data[j-1][0].sell_vol:0;
                if(j>0 && divisionFactor!=1)
                prevSellVol=tempData[mainIndex].data[j-1][0].sell_vol*ls;
               

                let temp_buy_highlight=(j>0 && temp_buy_vol>=(OF_HIGHLIGHT_CONSTANT.VOL_THRESHOLD*ls) && prevSellVol>=(OF_HIGHLIGHT_CONSTANT.VOL_THRESHOLD*ls) && (temp_buy_vol/prevSellVol)>=OF_HIGHLIGHT_CONSTANT.BUY_HIGHLIGHT_MIN_RATIO)
                let temp_sell_highlight=(j>0 && temp_buy_vol>=(OF_HIGHLIGHT_CONSTANT.VOL_THRESHOLD*ls) && prevSellVol>=(OF_HIGHLIGHT_CONSTANT.VOL_THRESHOLD*ls) && (prevSellVol/temp_buy_vol)>=OF_HIGHLIGHT_CONSTANT.SELL_HIGHLIGHT_MIN_RATIO)
               
                // if(j>0)
                // console.log("HIGHLIGHT TEST j, curr_sell,curr_buy,prev sell=",j,temp_sell_vol,temp_buy_vol,tempData[mainIndex].data[j-1][0].sell_vol)
                tempData[mainIndex].data[j].push({
                  buy_vol:(Math.floor(temp_buy_vol/divisionFactor)),
                  sell_vol:(Math.floor(temp_sell_vol/divisionFactor)),
                  total_vol:(Math.floor((buy_vol+sell_vol)/divisionFactor)),
                  isBuyHighlighted:temp_buy_highlight,
                  isSellHighlighted:false,
                  bigBuyerData,
                  bigSellerData,
                  isBigBuyer:bigBuyerData.length>0,
                  isBigSeller:bigSellerData.length>0,
                  time:item.time
                 
              })
              // if(bigBuyerData.length>0 || bigSellerData.length>0)
              // console.log("BIG BUYER SELLER  1  =======", tempData[mainIndex].data[j]);

              tempData[mainIndex].maxBuyVol=Math.max(tempData[mainIndex].maxBuyVol,(temp_buy_vol/divisionFactor));
              tempData[mainIndex].maxSellVol=Math.max(tempData[mainIndex].maxSellVol,(temp_sell_vol/divisionFactor));
              tempVolume=Math.floor(tempVolume+((buy_vol+sell_vol)/divisionFactor));
              if(j>0 && temp_sell_highlight){
                let data=tempData[mainIndex].data[j-1].pop();
                data.isSellHighlighted=true;
                tempData[mainIndex].data[j-1].push(data);
              }
                
            }
           
           
          
          
            tempData[mainIndex].stackedWidth=PROFILE_MIN_WIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN;
            tempData[mainIndex].splitWidth=PROFILE_MIN_WIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN;
            tempData[mainIndex].width=PROFILE_MIN_WIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN;
           

            config.totalWidth=config.totalWidth+ tempData[mainIndex].width;
            config.widthList.push(tempData[mainIndex].width);
            // console.log("vbddata",tempData,config.vbdData)
           
            profileData[index].vbdData=tempData;
            profileData[index].width=profileData[index].width+tempData[mainIndex].width;
            profileData[index].minTPO=Math.min(profileData[index].minTPO,tempData[mainIndex].minTPO);
            profileData[index].maxTPO=Math.max(profileData[index].maxTPO,tempData[mainIndex].maxTPO);
            profileData[index].count=count;
            profileData[index].totalVolume=tempVolume;
            config.data.push(tempData[mainIndex]);
          
           
            

          });
          console.log("parseofData=",tempIndex,count, profileData[index].tempIndex)
          tempIndex=tempIndex+ count;
          }
          // config.data=[...config.vbdData, ...profileData[index].vbdData];
        });

         
          
         

          config.dateList=tempDateList;
          config.timeList=tempTimeList;
          
          // console.log("tempDateList = ",tempDateList);
        
        // return profileData;
        console.log("parseOFData: profileData=",profileData,config, config.timeList);
        return {
          profilesData:profileData,
          config:config,
          
        }

    }  
  
  //processes the api response data 
 

    //merges the profiles when more data is loaded while zooming out or panning
    const mergeProfileData=(_data)=>{
      console.log("More data before width=",historicalData.config.totalWidth);

      for(let i=0;i<=historicalData.config.data.length-1;i++){
        historicalData.config.data[i].profileIndex= historicalData.config.data[i].profileIndex+_data.profilesData.length;
        // historicalData.config.data[i].index=historicalData.config.data[i].index+_data.config.data.length;
    }

    for(let i=0;i<historicalData.profilesData.length;i++ ){
      historicalData.profilesData[i].startIndex=historicalData.profilesData[i].startIndex+_data.config.data.length;
    }
      
      const mergedConfig={
        totalWidth:_data.config.totalWidth+historicalData.config.totalWidth,  
        dateList:(_data.config.dateList.concat(historicalData.config.dateList)),
        max:Math.max(_data.config.max,historicalData.config.max),
        min:Math.min(_data.config.min,historicalData.config.min),
        lastDataTime:historicalData.config.lastDataTime,
        timeList:(_data.config.timeList.concat(historicalData.config.timeList)),
        widthList:(_data.config.widthList.concat(historicalData.config.widthList)),
        data:(_data.config.data.concat(historicalData.config.data)),
        tpo:selectedTPO
      }
      
      console.log("More data after width=",mergedConfig.totalWidth);
      // console.log("Merged config object = ",mergedConfig);
      const mergedProfilesData=_data.profilesData.concat(historicalData.profilesData);
      // console.log("merged profiles data = ",mergedProfilesData);

      const mergedProcessedData= {
        profilesData:mergedProfilesData,
        config:mergedConfig
      }
      console.log("Mergeprofile mergedProcessedData=",mergedProcessedData);
      updateSelectedProfileIndexes(_data.profilesData.length);
      setHistoricalData(mergedProcessedData);
    }

    //update the previously selected index when more profiles is loaded
    const updateSelectedProfileIndexes=(updateVal)=>{
      if(selectedProfilesIndex.length>0){
        const updateIndexes = selectedProfilesIndex.map(index => index + updateVal);
        setSelectedProfilesIndex(updateIndexes);
      }
    }

    //merge the live data with existing profiles
    const mergeLiveData1=(_data,isLive)=>{
      // console.log("mergelivedata",_data,historicalData)
      if(_data!=undefined && historicalData!=undefined){
      let mergedConfig={};
      let mergedLiveData={};
      //if instrument is already live or date already exist(incase tradingsession active and instrumnet not live)
      //  then update the last profile with latest live data
      if(_data.profilesData[0].date==historicalData.profilesData[historicalData.profilesData.length-1].date)
      console.log("Date matches for last profile and live profile");

      if(isInstrumentLive || _data.profilesData[0].date==historicalData.profilesData[historicalData.profilesData.length-1].date){
        
        console.log("mergelivedata livedata merging",)
        const prevLiveProfile=historicalData.profilesData[historicalData.profilesData.length-1];
        let previousWidth=prevLiveProfile.width;
        let currWidth=_data.profilesData[0].width;

        console.log("mergelivedata",_data,historicalData,prevLiveProfile)
        
        //merge chart data in case of PPR change
        let liveData=props.chartData.of_data.pop();
        liveData.pop();  //remove last OF data from the live data
        liveData=[...liveData, ...props.liveData.of_data[0]]  //merge existing live data and new live data
        props.chartData.of_data.push(liveData);
       
        

        // console.log("Prev life profile found =",prevLiveProfile);
        historicalData.config.max=Math.max(historicalData.config.max,prevLiveProfile.maxTPO);
        historicalData.config.min=Math.min(historicalData.config.min,prevLiveProfile.minTPO);
        _data.profilesData[0].maxTPO=Math.max(_data.profilesData[0].maxTPO,prevLiveProfile.maxTPO)
        _data.profilesData[0].minTPO=Math.min(_data.profilesData[0].minTPO,prevLiveProfile.minTPO)
        historicalData.config.dateList.pop();
        historicalData.config.timeList.pop();
        historicalData.config.widthList.pop();
        let lastData=historicalData.config.data.pop();
       
        // console.log("dateList after pop live date",historicalData.config.dateList)  
        
        console.log("MergeLive::Total width before=============>",historicalData.config.totalWidth,_data.profilesData[0].width,prevLiveProfile.stackedWidth,prevLiveProfile.width,prevLiveProfile.isStacked)
        console.log("MergeLive::Total width before 2=============>",lastData,historicalData.config.totalWidth,currWidth,historicalData.config.widthList,historicalData.config.widthList[historicalData.config.widthList.length-1])
        //remove live data and its width
        // mergedConfig={
        //   totalWidth:historicalData.config.totalWidth+currWidth-previousWidth,
        //   dateList:(historicalData.config.dateList.concat(_data.config.dateList)),
        //   max:Math.max(_data.config.max,historicalData.config.max),
        //   min:Math.min(_data.config.min,historicalData.config.min),
        //   lastDataTime:_data.config.lastDataTime
        // }
        console.log("Lastdata val=",lastData);
        let startIndex=lastData.index;
        for(let i=0;i<=_data.config.data.length-1;i++){
          _data.config.data[i].index=startIndex;
          _data.config.data[i].profileIndex=lastData.profileIndex;
          startIndex=startIndex+1;

        }
        _data.profilesData[0].startIndex= historicalData.profilesData[ historicalData.profilesData.length-1].startIndex;
        _data.profilesData[0].totalVolume= _data.profilesData[0].totalVolume+historicalData.profilesData[ historicalData.profilesData.length-1].totalVolume;

        // console.log("doi =", historicalData.profilesData,prevLiveProfile, historicalData.profilesData[historicalData.profilesData.length-1].count,_data.config.data[0].doi,historicalData.config.data[historicalData.config.data.length-1].oi)
        // if(prevLiveProfile.count>1){
        //   _data.config.data[0].doi= _data.config.data[0].oi-historicalData.config.data[historicalData.config.data.length-1].oi;
        // }
        // console.log("doi after= ",historicalData.profilesData[historicalData.profilesData.length-1].count,_data.config.data[0].doi,historicalData.config.data[historicalData.config.data.length-1].oi)
       

        // _data.config.data[i].doi=_data.config.data[i].oi-
        console.log("MergeLive::Total width before 3=============>",_data,lastData,lastData.index,lastData.profileIndex)

         mergedConfig={
          totalWidth:(historicalData.config.totalWidth+currWidth-historicalData.config.widthList[historicalData.config.widthList.length-2]), 
          dateList:(historicalData.config.dateList.concat(_data.config.dateList)),
          max:Math.max(_data.config.max,historicalData.config.max),
          min:Math.min(_data.config.min,historicalData.config.min),
          lastDataTime:_data.config.lastDataTime,
          timeList:(historicalData.config.timeList.concat(_data.config.timeList)),
          widthList:(historicalData.config.widthList.concat(_data.config.widthList)),
          data:(historicalData.config.data.concat(_data.config.data)),
        }
        console.log("MergeLive:Total width after=============>",mergedConfig.totalWidth)
        // console.log("live mergedconfig 1= ",mergedConfig,_data.config,historicalData.config.totalWidth,prevLiveProfile);
      
        
        //  console.log("Live data after modification = ",_data);
        console.log("Dailymarker",historicalData.profilesData[historicalData.profilesData.length-1],_data.profilesData[0].count,historicalData.profilesData[historicalData.profilesData.length-1].count)
        _data.profilesData[0].count=_data.profilesData[0].count+historicalData.profilesData[historicalData.profilesData.length-1].count-1;
        historicalData.profilesData.pop();
        
        historicalData.profilesData.push(_data.profilesData[0]);
       
       
         mergedLiveData= {
          profilesData:historicalData.profilesData,
          config:mergedConfig
        }
        console.log("mergelivedata live merged data= ",mergedLiveData)
        props.setRightContainerData(false,props.liveData.metadata,mergedLiveData,[mergedLiveData.profilesData.length-1],true)
        setHistoricalData(mergedLiveData);
        setMetaData(props.liveData.mdata[props.liveData.mdata.length-1]);
      }
      //1st time live data received
      else{
        // _data.profilesData[0].isStacked=false;

        let currWidth=_data.profilesData[0].width;

        //update indexes and profile indexes accordingly
        console.log("mergelivedata 1st time live mergedconfig last data index=",historicalData.config.data[historicalData.config.data.length-1],historicalData.config.data[historicalData.config.data.length-1].profileIndex+1)
       let startIndex=historicalData.config.data[historicalData.config.data.length-1].index+1;
       _data.profilesData[0].profileIndex=historicalData.config.data[historicalData.config.data.length-1].profileIndex+1;
       _data.profilesData[0].startIndex=startIndex;
        
       for(let i=0;i<=_data.config.data.length-1;i++){
          _data.config.data[i].index=startIndex;
          _data.config.data[i].profileIndex=historicalData.config.data[historicalData.config.data.length-1].profileIndex+1;
          startIndex=startIndex+1;
       }

      //   for(let i=0;i<=historicalData.config.data.length-1;i++){
      //      historicalData.config.data[i].profileIndex= historicalData.config.data[i].profileIndex-1;
      //  }

        mergedConfig={
          totalWidth:currWidth+historicalData.config.totalWidth,  
          dateList:(historicalData.config.dateList.concat(_data.config.dateList)),
          max:Math.max(_data.config.max,historicalData.config.max),
          min:Math.min(_data.config.min,historicalData.config.min),
          lastDataTime:_data.config.lastDataTime,
          timeList:(historicalData.config.timeList.concat(_data.config.timeList)),
          widthList:(historicalData.config.widthList.concat(_data.config.widthList)),
          data:(historicalData.config.data.concat(_data.config.data)),
        }
          console.log("mergelivedata 1st time live mergedconfig = ",mergedConfig)
 
         
        historicalData.profilesData.push(_data.profilesData[0]);
        if(props.chartData && props.chartData.dateList  && props.chartData.dateList[props.chartData.dateList.length-1]!=props.liveData.dateList[props.liveData.dateList.length-1] ) {
          props.chartData.dateList=props.chartData.dateList.concat(props.liveData.dateList)
          props.chartData.of_data=props.chartData.of_data.concat(props.liveData.of_data)
          props.chartData.ls=props.chartData.ls.concat(props.liveData.ls)
          props.chartData.mdata=props.chartData.mdata.concat(props.liveData.mdata)

          console.log("PPR issue mergelive 1st time=",props.chartData)
        }  
        mergedLiveData= {
          profilesData:historicalData.profilesData,
          config:mergedConfig
        }
        // console.log("megedlivedata = ",mergedLiveData)
        console.log("mergelivedata 1st time live mergedconfig = ",mergedLiveData)
        props.setRightContainerData(false,props.liveData.metadata,mergedLiveData,[mergedLiveData.profilesData.length-1],true)
        setHistoricalData(mergedLiveData);
        setMetaData(props.liveData.mdata[props.liveData.mdata.length-1]);
      }
    }
    }

        //merge the live data with existing profiles
        const mergeLiveData=(_data,isLive,liveData)=>{
          // console.log("mergelivedata",_data,historicalData)
          if(_data!=undefined && historicalData!=undefined){
          let mergedConfig={};
          let mergedLiveData={};
          //if instrument is already live or date already exist(incase tradingsession active and instrumnet not live)
          //  then update the last profile with latest live data
          if(_data.profilesData[0].date==historicalData.profilesData[historicalData.profilesData.length-1].date)
          console.log("Date matches for last profile and live profile");
    
          if(liveData && (isInstrumentLive || _data.profilesData[0].date==historicalData.profilesData[historicalData.profilesData.length-1].date)){
            
            console.log("mergelivedata livedata merging",)
            const prevLiveProfile=historicalData.profilesData[historicalData.profilesData.length-1];
            let previousWidth=prevLiveProfile.width;
            let currWidth=_data.profilesData[0].width;
    
            console.log("mergelivedata",_data,historicalData,prevLiveProfile)
            
            //merge chart data in case of PPR change
            let modLiveData=props.chartData.of_data.pop();
            modLiveData.pop();  //remove last OF data from the live data
            modLiveData=[...modLiveData, ...liveData.of_data[0]]  //merge existing live data and new live data
            props.chartData.of_data.push(modLiveData);
           
            
    
            // console.log("Prev life profile found =",prevLiveProfile);
            historicalData.config.max=Math.max(historicalData.config.max,prevLiveProfile.maxTPO);
            historicalData.config.min=Math.min(historicalData.config.min,prevLiveProfile.minTPO);
            _data.profilesData[0].maxTPO=Math.max(_data.profilesData[0].maxTPO,prevLiveProfile.maxTPO)
            _data.profilesData[0].minTPO=Math.min(_data.profilesData[0].minTPO,prevLiveProfile.minTPO)
            historicalData.config.dateList.pop();
            historicalData.config.timeList.pop();
            historicalData.config.widthList.pop();
            let lastData=historicalData.config.data.pop();
           
            // console.log("dateList after pop live date",historicalData.config.dateList)  
            
            console.log("MergeLive::Total width before=============>",historicalData.config.totalWidth,_data.profilesData[0].width,prevLiveProfile.stackedWidth,prevLiveProfile.width,prevLiveProfile.isStacked)
            console.log("MergeLive::Total width before 2=============>",lastData,historicalData.config.totalWidth,currWidth,historicalData.config.widthList,historicalData.config.widthList[historicalData.config.widthList.length-1])
            //remove live data and its width
            // mergedConfig={
            //   totalWidth:historicalData.config.totalWidth+currWidth-previousWidth,
            //   dateList:(historicalData.config.dateList.concat(_data.config.dateList)),
            //   max:Math.max(_data.config.max,historicalData.config.max),
            //   min:Math.min(_data.config.min,historicalData.config.min),
            //   lastDataTime:_data.config.lastDataTime
            // }
            console.log("Lastdata val=",lastData);
            let startIndex=lastData.index;
            for(let i=0;i<=_data.config.data.length-1;i++){
              _data.config.data[i].index=startIndex;
              _data.config.data[i].profileIndex=lastData.profileIndex;
              startIndex=startIndex+1;
    
            }
            _data.profilesData[0].startIndex= historicalData.profilesData[ historicalData.profilesData.length-1].startIndex;
            _data.profilesData[0].totalVolume= _data.profilesData[0].totalVolume+historicalData.profilesData[ historicalData.profilesData.length-1].totalVolume;
    
            // console.log("doi =", historicalData.profilesData,prevLiveProfile, historicalData.profilesData[historicalData.profilesData.length-1].count,_data.config.data[0].doi,historicalData.config.data[historicalData.config.data.length-1].oi)
            // if(prevLiveProfile.count>1){
            //   _data.config.data[0].doi= _data.config.data[0].oi-historicalData.config.data[historicalData.config.data.length-1].oi;
            // }
            // console.log("doi after= ",historicalData.profilesData[historicalData.profilesData.length-1].count,_data.config.data[0].doi,historicalData.config.data[historicalData.config.data.length-1].oi)
           
    
            // _data.config.data[i].doi=_data.config.data[i].oi-
            console.log("MergeLive::Total width before 3=============>",_data,lastData,lastData.index,lastData.profileIndex)
    
             mergedConfig={
              totalWidth:(historicalData.config.totalWidth+currWidth-historicalData.config.widthList[historicalData.config.widthList.length-2]), 
              dateList:(historicalData.config.dateList.concat(_data.config.dateList)),
              max:Math.max(_data.config.max,historicalData.config.max),
              min:Math.min(_data.config.min,historicalData.config.min),
              lastDataTime:_data.config.lastDataTime,
              timeList:(historicalData.config.timeList.concat(_data.config.timeList)),
              widthList:(historicalData.config.widthList.concat(_data.config.widthList)),
              data:(historicalData.config.data.concat(_data.config.data)),
              tpo:selectedTPO
            }
            console.log("MergeLive:Total width after=============>",mergedConfig.totalWidth)
            // console.log("live mergedconfig 1= ",mergedConfig,_data.config,historicalData.config.totalWidth,prevLiveProfile);
          
            
            //  console.log("Live data after modification = ",_data);
            console.log("Dailymarker",historicalData.profilesData[historicalData.profilesData.length-1],_data.profilesData[0].count,historicalData.profilesData[historicalData.profilesData.length-1].count)
            _data.profilesData[0].count=_data.profilesData[0].count+historicalData.profilesData[historicalData.profilesData.length-1].count-1;
            historicalData.profilesData.pop();
            historicalData.profilesData.push(_data.profilesData[0]);
           
           
             mergedLiveData= {
              profilesData:historicalData.profilesData,
              config:mergedConfig
            }
            console.log("mergelivedata live merged data= ",mergedLiveData)
            props.setRightContainerData(false,props.liveData.metadata,mergedLiveData,[mergedLiveData.profilesData.length-1],true)
            setHistoricalData(mergedLiveData);
            setMetaData(props.liveData.metadata);
          }
          //1st time live data received
          else{
            // _data.profilesData[0].isStacked=false;
    
            let currWidth=_data.profilesData[0].width;
    
            //update indexes and profile indexes accordingly
            console.log("mergelivedata 1st time live mergedconfig last data index=",historicalData.config.data[historicalData.config.data.length-1],historicalData.config.data[historicalData.config.data.length-1].profileIndex+1)
           let startIndex=historicalData.config.data[historicalData.config.data.length-1].index+1;
           _data.profilesData[0].profileIndex=historicalData.config.data[historicalData.config.data.length-1].profileIndex+1;
           _data.profilesData[0].startIndex=startIndex;
            
           for(let i=0;i<=_data.config.data.length-1;i++){
              _data.config.data[i].index=startIndex;
              _data.config.data[i].profileIndex=historicalData.config.data[historicalData.config.data.length-1].profileIndex+1;
              startIndex=startIndex+1;
           }
           if (_data?.config?.data?.[0]) {
            _data.config.data[0].index = 0;
          }
    
          //   for(let i=0;i<=historicalData.config.data.length-1;i++){
          //      historicalData.config.data[i].profileIndex= historicalData.config.data[i].profileIndex-1;
          //  }
    
            mergedConfig={
              totalWidth:currWidth+historicalData.config.totalWidth,  
              dateList:(historicalData.config.dateList.concat(_data.config.dateList)),
              max:Math.max(_data.config.max,historicalData.config.max),
              min:Math.min(_data.config.min,historicalData.config.min),
              lastDataTime:_data.config.lastDataTime,
              timeList:(historicalData.config.timeList.concat(_data.config.timeList)),
              widthList:(historicalData.config.widthList.concat(_data.config.widthList)),
              data:(historicalData.config.data.concat(_data.config.data)),
              tpo:selectedTPO
            }
              console.log("mergelivedata 1st time live mergedconfig = ",mergedConfig)
     
            _data.profilesData[0].startIndex= historicalData.profilesData[ historicalData.profilesData.length-1].startIndex+historicalData.profilesData[ historicalData.profilesData.length-1].count;
            
            historicalData.profilesData.push(_data.profilesData[0]);
            if(props.chartData && props.chartData.dateList  && props.chartData.dateList[props.chartData.dateList.length-1]!=props.liveData.dateList[props.liveData.dateList.length-1] ) {
              props.chartData.dateList=props.chartData.dateList.concat(props.liveData.dateList)
              props.chartData.of_data=props.chartData.of_data.concat(liveData.of_data)
              props.chartData.ls=props.chartData.ls.concat(props.liveData.ls)
              props.chartData.mdata=props.chartData.mdata.concat(props.liveData.mdata)
              console.log("PPR issue mergelive 1st time=",props.chartData)
            }  
            mergedLiveData= {
              profilesData:historicalData.profilesData,
              config:mergedConfig
            }
            // console.log("megedlivedata = ",mergedLiveData)
            console.log("mergelivedata 1st time live mergedconfig = ",mergedLiveData)
            props.setRightContainerData(false,props.liveData.metadata,mergedLiveData,[mergedLiveData.profilesData.length-1],true)
            setHistoricalData(mergedLiveData);
            setMetaData(props.liveData.metadata);
          }
        }
        }
    

   
  //1st loading of chart
    useEffect(()=>{
      console.log("1st load OF chart =======================>parseData",props.chartData,props.chartData.metadata);
      setMetaData(props.chartData.mdata[props.chartData.mdata.length-1]);
        const processedDataOF = parseOFData(props.chartData,true,undefined);
       
           setHistoricalData(processedDataOF);
           saveChartState();
    },[]);

  //handles loading of more profiles data in case of zooming out or panning extremes reached
  useEffect(()=>{
    if(isLoadingMoreProfiles){
      if(props.isPollingAllowed)
      props.setPollingAllowedVal(false);
      let nprof=1;
      if(props.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_30_min){
        nprof=2
       
      }  
    
    let data={
      "symbol": selectedInstrument,
      "nDays": 0,
      "nProf": nprof,
      "liveData": false,
      "liveOnly": false,
      "startDate": "",
      "endDate": historicalData.profilesData[0].date,
      "tf": props.selectedTimeFrame,
    }
    
    setIsMoreDataLoading(true);
    // setChartInteractionAllowed(false);

    executeAPI(URL.OF_CHART,"POST",data); 
  }
  },[isLoadingMoreProfiles]);

  useEffect(() => {
    // setHistoricalData(undefined);
    setSelectedTimeFrame(props.selectedTimeFrame)
  },[props.selectedTimeFrame]  )

  /**
  * API response handler for load more data  
  */  
    useEffect(() => {
      if(loaded){
        // console.log("More data loaded=",responseData);
        if(responseData!=null){
          if(responseData.of_data!==undefined && responseData.of_data.length>0){
                
                // console.log("More data loaded modified=",responseData);
                if(responseData.of_data!=undefined && responseData.of_data.length==0){
                  setMoreDataPresent(false);
                }
                //merge with existing chart data
                if(props.chartData!=undefined && responseData.dateList!=undefined && responseData.dateList.length>0){
                  // console.log("More data:date List before concat=",props.chartData.dateList);
                  props.chartData.dateList=responseData.dateList.concat(props.chartData.dateList)
                  props.chartData.of_data=responseData.of_data.concat(props.chartData.of_data)
                  props.chartData.ls=responseData.ls.concat(props.chartData.ls)
                  props.chartData.mdata=responseData.mdata.concat(props.chartData.mdata)
                  // console.log("Load more merge data",props.chartData);
                  // console.log("More data:date List after concat=",props.chartData.dateList);              
                }
                
                const processedDataOF = parseOFData(responseData,false,undefined);
                mergeProfileData(processedDataOF);
                setIsMoreDataLoading(false);
               
                props.setPollingAllowedVal(true);
                
             
              }
              if(responseData.upgrade_notify!=undefined && responseData.upgrade_notify){
                props.openSubscribeModal();
              }
              setIsMoreDataLoading(false);
              setChartInteractionAllowed(true);
        }
        else if(error!==null){
              props.setPollingAllowedVal(true);
              console.log("Error data=",error);
              setIsMoreDataLoading(false);
              setChartInteractionAllowed(true);
         
          
          if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            // navigate(from, { replace: true });
            if(props.showLoginPopup){
              // console.log("TEST LOGIN chart container 2")
              props.showLoginPopup(true, error?.response?.data?.message);
            }
          }else 
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
        reset();
      }
      },[loaded,responseData]);
  
    //save the existing transform(zoom, x,y pan values and trigger load more profiles)
    const loadMoreData=(transform)=>{
      // setTransformData(transform);
      // return;
      if(moreDataPresent){
        // setChartInteractionAllowed(false);
        setIsLoadingMoreProfiles(true);
      }
    }
 
    //Search the profile based on mouse clicked point
    const binarySearch=(sortedArray, key)=>{
      let start = 0;
      let end = sortedArray.length - 1;
  
      while (start <= end) {
          let middle = Math.floor((start + end) / 2);
  
          if (sortedArray[middle] === key) {
              // found the key
              return middle;
          } else if (sortedArray[middle] < key) {
              // continue searching to the right
              start = middle + 1;
          } else {
              // search searching to the left
              end = middle - 1;
          }
      }
    
      return start-1;
    }

    //opens the context menu for the profiles
    const openContextMenu=(xCord,yCord,selectedIndex,canvasWidth,canvasHeight)=>{
      if(selectedIndex!=undefined && selectedIndex>=0 && selectedIndex<historicalData.profilesData.length){
      setShowContextMenu(false);
      setShowTooltip(false);
      if(historicalData!=undefined){
      //   console.log("selectedprofilesIndex =",selectedProfilesIndex);
      //   let tempIndex=[...selectedProfilesIndex];
      //   tempIndex.push(selectedIndex);
      //   console.log("temp array =",tempIndex);
      // // setSelectedProfilesIndex(tempIndex);
      // setSelectedProfilesIndex(oldArray => [...oldArray, selectedIndex]);

      // let tempProfiles=[...selectedProfiles];
      // console.log("selectedProfiles =",selectedProfiles);
      // tempProfiles.push(historicalData.profilesData[selectedIndex])
      // console.log("tempProfiles =",tempProfiles);
      
      // setSelectedProfiles(tempProfiles);
      setCurrentIndex(selectedIndex);
      //modify x and y cord for handling context menu at the extremes
      if(xCord+CONTEXT_MENU_WIDTH>canvasWidth)
        xCord=xCord-CONTEXT_MENU_WIDTH;
      if(yCord+CONTEXT_MENU_HEIGHT>canvasHeight+CHART_TOP_SPACE)
        yCord=yCord-CONTEXT_MENU_HEIGHT;
      
      setAnchorPoint({ x: xCord, y: yCord });
      setShowTooltip(false); 
      setShowContextMenu(true);  
      }
    }

    }

    const openTooltip=(xCord,yCord,canvasWidth,canvasHeight)=>{
    
      setShowTooltip(false);
     
      //modify x and y cord for handling context menu at the extremes
      if(xCord+TOOLTIP_MENU_WIDTH>canvasWidth)
        xCord=xCord-TOOLTIP_MENU_WIDTH;
      if(yCord+TOOLTIP_HEIGHT>canvasHeight+CHART_TOP_SPACE)
        yCord=yCord-TOOLTIP_HEIGHT;
      
      setAnchorPointTooltip({ x: xCord, y: yCord });
      setShowContextMenu(false); 
      setShowTooltip(true);  
    }

    const openYAxisContextMenu=(xCord,yCord,canvasWidth,canvasHeight)=>{
    
      setShowYAxisContextMenu(false);
     
      //modify x and y cord for handling context menu at the extremes
      if(xCord+TOOLTIP_MENU_WIDTH>canvasWidth)
        xCord=xCord-TOOLTIP_MENU_WIDTH;
      if(yCord+TOOLTIP_HEIGHT>canvasHeight+CHART_TOP_SPACE)
        yCord=yCord-TOOLTIP_HEIGHT;
      
      setAnchorPointYAxis({ x: xCord, y: yCord });
      setShowContextMenu(false); 
      setShowTooltip(false); 
     setShowYAxisContextMenu(true);
    
    }

 

  

   /**
   * Gets the volume profiles for the visible profiles
   */
   const getOFVolumeProfilesInRange=(leftIndex,rightIndex,startIndex)=>{
    //get the max and min tpo for the profile range
    let totalVolume=0;
    let maxTPO=Number.NEGATIVE_INFINITY;
    let minTPO=Number.POSITIVE_INFINITY;
    let maxPerc=0;
    let currTPO=historicalData.config.tpo?historicalData.config.tpo:selectedTPO;
    console.log("Test OF TPO=",selectedTPO)
   
    for(let i=leftIndex;i<=rightIndex;i++){
      totalVolume=totalVolume+historicalData.profilesData[i].totalVolume;
      maxTPO=Math.max(maxTPO,historicalData.profilesData[i].maxTPO);
      minTPO=Math.min(minTPO,historicalData.profilesData[i].minTPO);
     
    }
    // totalVolume=totalVolume/100;
    // startIndex=123;
    console.log("OF VOl totalV,=",totalVolume,minTPO,maxTPO,startIndex,leftIndex,rightIndex);
    // console.log("OF VOl totalV,minTPO, maxTPO =",historicalData.config, historicalData.profilesData,totalVolume,minTPO,maxTPO,startIndex,leftIndex,rightIndex);
    let totalPerc=0;
    let volumeData=[];
    let index=-1;
    let curr=0;
    
   

    for(let tpo=minTPO;tpo<=maxTPO;tpo=parseFloat((tpo+currTPO).toFixed(4))){
      let volume=0;
     
      
      for(let i=startIndex;i<=historicalData.config.data.length-1;i++){
        // console.log("OF vol i=",i)
        // console.log("OF vol inner index= tpo=",i,tpo,historicalData.config.data[i].minTPO,historicalData.config.data[i].maxTPO);
        if(tpo>=historicalData.config.data[i].minTPO && tpo<=historicalData.config.data[i].maxTPO){
                index=parseFloat((tpo-historicalData.config.data[i].minTPO).toFixed(4));
                // console.log("OF vol outer index=",index,tpo)
                if(index!=0){
                  index=parseFloat((index/currTPO).toFixed(4))
                  // console.log("OF vol inner index=",i,index,tpo,historicalData.config.data[i].data[index][0])
                  // console.log("Test OF ",historicalData.config.data[i],index)
                  volume=volume+historicalData.config.data[i].data[index][0].total_vol;
                }else if(index==0){
                  volume=volume+historicalData.config.data[i].data[index][0].total_vol;

                }
             
          
          }

        }

      
      // console.log("tpo, volume=",tpo,volume);
       //add the global volume at the current tpo
       if(volume!=0 && volume!=undefined){
        volumeData.push({
          val:volume,
          perc:(parseFloat((volume*100/totalVolume).toFixed(4))),
        })
        maxPerc=Math.max(maxPerc,(parseFloat((volume*100/totalVolume).toFixed(4))));
        totalPerc=totalPerc+(parseFloat((volume*100/totalVolume).toFixed(4)));
      }else{
        volumeData.push({
          val:0,
          perc:0,
        })
      }

      
     
    }
    
    //  console.log("OF vol maxPerc totalPerc final volume data=",maxPerc, totalPerc,volumeData.length,volumeData);
    return{
      data:volumeData,
      minTPO:minTPO,
      maxTPO:maxTPO,
      maxPerc:maxPerc
    }
  }  

  useEffect(()=>{
    console.log("MP Chart Props changes",props.chartStateData)
  },[props.chartStateData])
  
   

    useLayoutEffect(()=>{
      if(historicalData==undefined)
        return;
      // else return;
      let randomR=1;
      let randomG=1;
      let randomB=1;
      let redraw=true;
      chartInterationAllowedRef.current=chartInterationAllowed;
      savedLiveDataRef.current=savedLiveData;
      contextMenuRef.current=showContextMenu;
      transformDataRef.current=transformData;
      transformDataRefY.current=transformDataY;
      yaxiscontextMenuRef.current=showYAxisContextMenu;
      // let currentDate=dayjs().day()<5?dayjs().add(1,"day"):dayjs().add(3,"day");
      let currentDate=dayjs();
     
     
      let nextWeekday=(currentDate.get('date')>9?currentDate.get('date'):"0"+currentDate.get('date'))+"-"+((currentDate.get('month')+1)>9?(currentDate.get('month')+1):"0"+(currentDate.get('month')+1))+"-"+currentDate.get('year');
      // console.log("nextWeekday 0=",currentDate,props.chartData.dateList[props.chartData.dateList.length-1],nextWeekday);
      if(isInstrumentLive || nextWeekday==props.chartData.dateList[props.chartData.dateList.length-1]){
        currentDate=dayjs().day()<5?dayjs().add(1,"day"):dayjs().add(3,"day");
        // console.log("nextWeekday 1=",currentDate);
      }else if(!isInstrumentLive && nextWeekday!=props.chartData.dateList[props.chartData.dateList.length-1] && dayjs().day()<5){
        currentDate=dayjs();
      }else{
        currentDate=dayjs().day()<5?dayjs().add(1,"day"):dayjs().add(2,"day");

      }
      nextWeekday=(currentDate.get('date')>9?currentDate.get('date'):"0"+currentDate.get('date'))+"-"+((currentDate.get('month')+1)>9?(currentDate.get('month')+1):"0"+(currentDate.get('month')+1))+"-"+currentDate.get('year');
      // console.log("nextWeekday 2=",nextWeekday,currentDate.get('month'),currentDate.get('year'));
      // let nextWeekday=currentDate.get('date')+"-"+((currentDate.get('month')+1)>9?(currentDate.get('month')+1):"0"+(currentDate.get('month')+1));
      
      console.log("SCALE ------------RANGE--------DATE--->111",historicalData.config.totalWidth,historicalData.config.dateList,dayjs().day(),props.chartData.liveDate);
      // console.log("SCALE ------------RANGE--------DATE--->",historicalData.config.totalWidth,historicalData.profilesData,historicalData.config,props.chartData);
      
      let canvasHeight=props.chartStateData.height -CONSTANTS.FIXED_HEADER_HEIGHT-CONSTANTS.FIXED_FOOTER_HEIGHT-XSCALE_HEIGHT;
      
      //calculate the width of canvas
      // let canvasWidth=window.innerWidth - CONSTANTS.FIXED_RIGHT_PANEL_WIDTH-YSCALE_WIDTH-20;
      let canvasWidth=props.chartStateData.width -YSCALE_WIDTH-0;
      
      setCanvasWidth(canvasWidth);
      console.log("window  w,h,canvasWidth, canvasHeight = ",window.innerWidth,canvasWidth,window.innerHeight,canvasHeight);

      const zoomOutMinVal=(Math.max(ZOOM_OUT_EXTREME,canvasWidth/historicalData.config.totalWidth))/LOAD_MORE_ZOOM_OUT_PERCENT;
      const endProfileExtreme=(historicalData.config.totalWidth-canvasWidth)*LOAD_MORE_PAN_PERCENT;
      const zoomOutChartBased=canvasWidth/historicalData.config.totalWidth;
      const totalWidth=historicalData.config.totalWidth+canvasWidth/2;
      let ZOOM_OUT_SCALE=Math.max(ZOOM_OUT_EXTREME, canvasWidth>totalWidth? ZOOM_OUT_EXTREME_LESS_PROFILE:canvasWidth/totalWidth);

      //retrieves the initial transform if already set  
      console.log("Recenter transform data=",transformData);
      let initialTransform={k:1,x:0,y:0};
      let initialTransformY={k:1,x:0,y:0}
      if(zoomType==OF_ZOOM_TYPE.ASYMMETRIC){
          if(transformData!=undefined)
            initialTransform=transformData;
          
          if(transformDataY!=undefined)
            initialTransformY=transformDataY;  
      
        }else{
        if(transformData!=undefined){
          initialTransform=transformData;
          initialTransformY=transformData
        }
      }   
      
      //calculate the height for the canvas
      // let canvasHeight=window.innerHeight - CONSTANTS.DASHBOARD_HEADER_HEIGHT-CONSTANTS.FIXED_HEADER_HEIGHT-CONSTANTS.FIXED_FOOTER_HEIGHT-XSCALE_HEIGHT;
     

      //handles the situation where all the profiles comes under the screen due to zoom out and profile width decrement on TPO change
      if(historicalData.config.totalWidth>canvasWidth && initialTransform.k<1 && (canvasWidth/historicalData.config.totalWidth)>=initialTransform.k){
         console.log("Extreme conditions met->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",canvasWidth/historicalData.config.totalWidth,initialTransform.k);
         //loadmore data impolictly as all the profiles comes under the screen
         loadMoreData(initialTransform);
         return;
      }else{
        console.log("Condition not met >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",canvasWidth/historicalData.config.totalWidth,initialTransform.k);
      }
     
      //set zoom out and pan extremes to keep track when to load more data during user interaction
      // const zoomOutMinVal=(Math.max(ZOOM_OUT_EXTREME,canvasWidth/historicalData.config.totalWidth))/LOAD_MORE_ZOOM_OUT_PERCENT;
      // const endProfileExtreme=(historicalData.config.totalWidth-canvasWidth)*LOAD_MORE_PAN_PERCENT;
      // const zoomOutChartBased=canvasWidth/historicalData.config.totalWidth;
      
      // console.log("Zoom pan extremes =",zoomOutMinVal,endProfileExtreme)
      
      //chart construction using d3  canvas and svg
      // const container = d3.select('.chart-container_new');
      // let className="."+props.id;
      // console.log("classname=",className)
      const container = d3.select(`#${props.id}`);
              
      // Remove any existing tooltip and create the tooltips
      d3.selectAll(`#tooltipCrosshair${props.id}`).remove()
      d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).remove()
      d3.selectAll(`#tooltipLiveMarker${props.id}`).remove()
      d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).remove()
      d3.selectAll(`#tooltipUpperLevel${props.id}`).remove()
      d3.selectAll(`#tooltipLowerLevel${props.id}`).remove()
      
      
      //yaxis tooltip
      const tooltipCrossHair = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipCrossHair")
      .attr("id", "tooltipCrosshair"+props.id)
      .style("opacity", 0.9)
      .style("z-index", 9999)
      .style("left",50)
      .style('display','none');
      
      //date axis tooltip
      const tooltipCrossHairDateAxis = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipCrossHair")
      .attr("id", "tooltipCrosshairDateAxis"+props.id)
      .style("opacity", 0.9)
      .style("z-index", 9999)
      .style("left",50)
      .style('display','none');
    
      //yaxis tooltip
      const tooltipLiveMarker = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipLiveMarker")
      .attr("id", "tooltipLiveMarker"+props.id)
      .style("opacity", 1.0)
      .style("z-index", 999)
      .style("left",50)
      .style('display','none');
      
      //tolltio for live profile close_vwap
      const tooltipLiveCloseVwap = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipLiveCloseVwap")
      .attr("id", "tooltipLiveCloseVwap"+props.id)
      .style("opacity", 1.0)
      .style("z-index", 99)
      .style("left",50)
      .style('display','none');

     //level boundary fixed tooltip
     const tooltipUpperLevel = d3.select(`#${props.id}`)
     .append("div")
     .attr("class", "tooltipFrozenLine")
     .attr("id", "tooltipUpperLevel"+props.id)
     .style("opacity", 0.9)
     .style("z-index", 9999)
     .style("left",50)
     .style('display','none'); 
    
      //level boundary fixed tooltip
      const tooltipLowerLevel = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipFrozenLine")
      .attr("id", "tooltipLowerLevel"+props.id)
      .style("opacity", 0.9)
      .style("z-index", 9999)
      .style("left",50)
      .style('display','none'); 
      
    //determine the range of profiles based on the canvas and profile width
    const xScaleRange=[];
    const dummyProfileWidth=(canvasWidth/0.2);
    xScaleRange[historicalData.config.widthList.length+1]=dummyProfileWidth;
    xScaleRange[historicalData.config.widthList.length]=canvasWidth;
    for(let i=historicalData.config.widthList.length-1;i>=0;i=i-1){
      // xScaleRange[i]=(xScaleRange[i+1]- (historicalData.profilesData[i].isVolumeProfile && !historicalData.profilesData[i].showOnlyVolumeProfile && !historicalData.profilesData[i].isTPOonVolume?historicalData.profilesData[i].volumeWidth:0)
      //   -(historicalData.profilesData[i].isStacked ?historicalData.profilesData[i].stackedWidth:historicalData.profilesData[i].splitWidth));
      xScaleRange[i]=(xScaleRange[i+1]- historicalData.config.widthList[i]);
    }

    // console.log("xScaleRange=",xScaleRange)
    let modDateList=[... historicalData.config.timeList]
    modDateList.push(nextWeekday);
    // console.log("xScaleRange=",xScaleRange,modDateList)
    const xScale = d3.scaleOrdinal()
      .domain(modDateList)
      .range(xScaleRange);

    // const xScale = d3.scaleOrdinal()
    //   .domain(historicalData.config.timeList)
    //   .range(xScaleRange);
    
    //determine the range of y axis based on latest profile
    // const maxDomain=historicalData.profilesData[historicalData.profilesData.length-1].maxTPO+2*selectedTPO;
    // const maxDomain=historicalData.config.data[historicalData.config.data.length-1].maxTPO+5*selectedTPO;
    // const minDomain=maxDomain-(canvasHeight/TPO_HEIGHT)*selectedTPO;
    const midTPO=historicalData.config.data[historicalData.config.data.length-1].closeTPO;
    const totalTPOONCanvas=(canvasHeight/TPO_HEIGHT)*selectedTPO;
    let maxDomain=midTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
    let minDomain=midTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
    // if(!isFirstLoad && (transformDataRefY.current.k!=1 || transformDataRefY.current.y!=0)){
      let forceRecenter=false;
      if(chartMidTPO!=-1){
        let maxDomainSaved=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
        let minDomainSaved=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
      let topRange=maxDomainSaved- (maxDomainSaved-minDomainSaved)*(LTP_POSITION_ALLOWED_CHANGE);
      let bottomRange=minDomainSaved+(maxDomainSaved-minDomainSaved)*(LTP_POSITION_ALLOWED_CHANGE);
      if(midTPO>topRange || midTPO<bottomRange){
        console.log("Recenter force recenter is true",maxDomainSaved,minDomainSaved,maxDomain,minDomain, chartMidTPO,midTPO,topRange,bottomRange)
        forceRecenter=true;
      }else
      console.log("Recenter force recenter is false",maxDomainSaved,minDomainSaved,chartMidTPO,midTPO,topRange,bottomRange)
     
      }
    if(!isRecenterAllowed){
     
      // maxDomain=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
      // minDomain=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
      // // setChartMidTPO(midTPO)
      maxDomain=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
      minDomain=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
      console.log("Recenter is not allowed 1", isRecenterAllowed,forceRecenter,maxDomain,minDomain,chartMidTPO,midTPO,totalTPOONCanvas)
    }else{
      
      if(isFirstLoad){
        console.log("Recenter is allowed first load");
        setIsFirstLoad(false);
        setChartMidTPO(midTPO)
      }else if(forceRecenter){
        console.log("Recenter is allowed force recenter");
        setChartMidTPO(midTPO);
      }else if(!forceRecenter){
       
        
        maxDomain=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
        minDomain=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
        console.log("Recenter is not allowed 2", isRecenterAllowed,forceRecenter,maxDomain,minDomain,chartMidTPO,midTPO,totalTPOONCanvas)
      }
    }
    // const maxDomain=historicalData.config.data[historicalData.config.data.length-1].maxTPO+5*selectedTPO;
    // const minDomain=maxDomain-(canvasHeight/TPO_HEIGHT)*selectedTPO;
     console.log("Recenter maxDomain, minDomain ",maxDomain,minDomain);
    
    const yScale = d3.scaleLinear()
        .domain([minDomain, maxDomain])
        .range([canvasHeight, 0])
        .nice();
    

    //remove the previous svgs for axes if present
    d3.select(`#svgChart${props.id}`).remove()
    d3.select(`#svgChartXAxis${props.id}`).remove()
           
    // Init SVG: Y axis
    const svgChart = container.append('svg')
    .attr("id","svgChart"+props.id)
    .attr('width', xScaleRange[xScaleRange.length-2]+YSCALE_WIDTH-2)    //2 to counter the right border else scroll bar is show horizontally
    .attr('height', canvasHeight+XSCALE_HEIGHT)
    // .attr("cursor","row-resize")
    .attr('class', 'svg-plot')
    .style("cursor","ns-resize")

    // Init SVG: X axis
    const svgChartXAxis = container.append('svg')
    .attr("id","svgChartXAxis"+props.id)
    .attr('width', xScaleRange[xScaleRange.length-2])
    .attr('height', XSCALE_HEIGHT+10)
    .attr('class', 'svg-plot')
   
    // Init Axis
    const xAxis = d3.axisTop(xScale);
    const yAxis = d3.axisRight(yScale)
                .tickFormat(function(d){
                  return d<0?"":d;
                });
    
    //Remove the previous axes and  add new one Axis
    d3.select(`#xaxis${props.id}`).remove()
    d3.select(`#yaxis${props.id}`).remove()
    const gxAxis = svgChartXAxis.append('g')
        .style("font", "12px sans-serif")
        .attr("transform", `translate(0,${margin.top})scale(${1})`)
        .attr('id','xaxis'+props.id)
        .call(xAxis);
    
      
       

 
      const gyAxis = svgChart.append('g')
        .style("font", "12px sans-serif")
        .attr('transform',`translate(${canvasWidth},${margin.top})`)
        .attr('id','yaxis'+props.id)
        .call(yAxis)

      
          


    d3.selectAll(`#canvasChart${props.id}`).remove()
    d3.selectAll(`#dummyCanvas${props.id}`).remove()
    d3.selectAll(`#canvasOverlay${props.id}`).remove();
    d3.selectAll(`#canvasTooltip${props.id}`).remove();

    
     // Invosoble Tooltip canvas
     const canvasTooltip= container.append('canvas')
     .attr("id",'canvasTooltip'+props.id)
     .attr('width', canvasWidth)
     .attr('height', canvasHeight)
     .style('margin-left', margin.left + 'px')
     .style('margin-top', margin.top + 'px')
     .attr('class', 'canvas-tooltip')
                

    // Overlay Canvas
    const overlayCanvas = container.append('canvas')
    .attr("id",'canvasOverlay'+props.id)
    .attr('width', canvasWidth)
    .attr('height', canvasHeight)
    .style('margin-left', margin.left + 'px')
    .style('margin-top', margin.top + 'px')
    .attr('class', 'canvas-plot')
    

    // Init Canvas
    const canvasChart = container.append('canvas')
    .attr("id",'canvasChart'+props.id)
    .attr('width', canvasWidth)
    .attr('height', canvasHeight)
    .style('margin-left', margin.left + 'px')
    .style('margin-top', margin.top + 'px')
    .attr('class', 'canvas-plot')
    
    //dummy canvas for user interaction
    const dummyCanvas = container.append('canvas')
    .attr('id','dummyCanvas'+props.id)
    .attr('width', canvasWidth+(zoomType==OF_ZOOM_TYPE.ASYMMETRIC?0:YSCALE_WIDTH-2))
    .attr('height', canvasHeight)
    .style('margin-left', margin.left + 'px')
    .style('margin-top', margin.top + 'px')
    .attr('class', 'canvas-plot')
    .style("cursor",zoomType==OF_ZOOM_TYPE.ASYMMETRIC?"ew-resize":"all-scroll")

    
   
    
    // Prepare chart buttons: Reset
    const toolsList = container.select(`#tools${props.id}`)
    .style('margin-top', (canvasHeight-4) + 'px')
    .style('visibility', 'visible');
  
    const watermark = container.select(`#watermark${props.id}`)
    .style('margin-top', (canvasHeight/2-4) + 'px')
    .style('visibility', 'visible');
  
    
    toolsList.select(`#reset${props.id}`).on('click', () => {
    // const t = d3.zoomIdentity.translate(0, 0).scale(1);
    // setTransformData(t);
    // setToggleRepaint(!toggleRepaint);
    dummyCanvas.transition()
      .duration(200)
      .ease(d3.easeLinear)
      .call(zoom_function.transform, t)

    });
    
    /**
     * Auto center the chart
     * @param {*} transform 
     */
    const autoCenter=(transform)=>{
      console.log("Autocenter transform=",transform)
      // const t = d3.zoomIdentity.translate(transform.x, transform.y).scale(transform.k);
      // setTransformData(transform);
      // setToggleRepaint(!toggleRepaint);
      dummyCanvas.transition()
        .duration(200)
        .ease(d3.easeLinear)
        .call(zoom_function.transform, t)
    
    }
    
    toolsList.select(`#zoomin${props.id}`).on('click', () => {
      let prevTransform=transformData;
      let prevK=prevTransform.k;
      if(prevTransform!=undefined)
      prevTransform.k=Math.min(1,prevTransform.k*1.1)
      
      if(prevK!=1 && prevK!=undefined){
        // let t = d3.zoomIdentity.translate(canvasWidth*(1-prevTransform.k), canvasHeight*(1-prevTransform.k)).scale(prevTransform.k);
        // setTransformData(t);
        setToggleRepaint(!toggleRepaint);
      }
    });

    toolsList.select(`#zoomout${props.id}`).on('click', () => {
      let translateExtentUpward=canvasHeight;
    if((yScale.domain()[0]!=historicalData.config.min))
    translateExtentUpward=(Math.abs(yScale.domain()[0]-historicalData.config.min)/(yScale.domain()[1]-yScale.domain()[0]))*canvasHeight+(1.5*canvasHeight);
    
    let translateExtentDownward=-1*canvasHeight;
    if((yScale.domain()[1]!=historicalData.config.max))
    translateExtentDownward=-1*((Math.abs(yScale.domain()[1]-historicalData.config.max)/(yScale.domain()[1]-yScale.domain()[0]))*canvasHeight+(1.5*canvasHeight));
    console.log("zoomout up down width",translateExtentUpward,translateExtentDownward,historicalData.config.totalWidth);
    
      if(canvasWidth<historicalData.config.totalWidth){
      let zoomOutMax=Math.max(ZOOM_OUT_EXTREME,canvasWidth/historicalData.config.totalWidth)
      let prevTransform=transformData;
      console.log("zoomout clicked ",prevTransform,zoomOutMinVal,prevTransform.k*0.9)
      if(prevTransform!=undefined)
      prevTransform.k=Math.max(zoomOutMax, (prevTransform.k*0.9))
      // let t = d3.zoomIdentity.translate(canvasWidth*(1-prevTransform.k), canvasHeight*(1-prevTransform.k)).scale(prevTransform.k);
     
      // setTransformData(t);
      if(prevTransform.k==(zoomOutMax/LOAD_MORE_ZOOM_OUT_PERCENT)){
        loadMoreData(t)
      } 
      else{
        setToggleRepaint(!toggleRepaint);
      }
    }
  });
    
    //TODO: Check for software cpu accelration performance instead of gpu for performance
    // const context = canvasChart.node().getContext('2d', {willReadFrequently: true}
    const context = canvasChart.node().getContext('2d');
    const contextDummy = dummyCanvas.node().getContext('2d');
    const contextOverlay = overlayCanvas.node().getContext('2d');
    const contextTooltip = canvasTooltip.node().getContext('2d');
    context.clearRect(0, 0, canvasWidth, canvasHeight);
    contextDummy.clearRect(0, 0, canvasWidth, canvasHeight);
    contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
    contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
        
    //keep track of previous selected profiles
    let selectedIndexes=[...selectedProfilesIndex]
    // Draw profiles on canvas

    // z holds a copy of the previous transform, so we can track its changes
    let zX = d3.zoomIdentity;
    let zY= d3.zoomIdentity;
    // if(transformData){
    //   zX.k=transformData.k;
    //   zX.x=transformData.x;
    // }
    // console.log("zoom event zoomIdentity 1 zX, zY=",zX,zY,transformData,transformDataY);
    // if(transformDataY){
    //   zY.k=transformDataY.k;
    //   zY.y=transformDataY.y;
    // }
    console.log("zoom event zoomIdentity 2 zX, zY=",zX,zY,transformData,transformDataY);

    // const image = document.getElementById("asterisk");
    // console.log("Image laoded 1",image)
    // image.addEventListener("load", (e) => {
    //   console.log("Image laoded",image)
    // })

    const image = new Image();
    // image.src ="../../../assets/images/asterisk.png";


    const draw=(transform,yZoom=false,drawText=false,firstLoad=false)=> {
      console.log("OF ZOOM Test=",transform,yZoom)
      if(benchmarkingEnabled)
      console.time("Benchmark_OF_draw")

      if(benchmarkingEnabled)
      console.time("Benchmark_OF_draw1")
      randomR=1;
      randomG=1;
      randomB=1;
      if(zoomType==OF_ZOOM_TYPE.ASYMMETRIC){
        if(!firstLoad){
          if(yZoom) 
            zY=transform;
          else{ 
            let tx={x:transform.x,y:0,k:transform.k}
                
            zX=transform;
            // zY.y=transform.y;
          }
        }
      }else{
      zX=transform;
      zY=transform;
      }
     
     
      // console.log("draw() transform =",transform)
      
      //loads more data once the pan extreme is reached
      if(!yZoom && transform.x>=endProfileExtreme*zX.k && !drawText){
        // console.log("Load more data as pan extreme is reached ",endProfileExtreme,transform);
        loadMoreData(transform);
      }

       //loads more data once the pan zoom is reached
      if(!yZoom && zX.k<=zoomOutMinVal && zoomOutChartBased>=ZOOM_OUT_EXTREME && !drawText){
        if(zX.k>ZOOM_OUT_EXTREME){
          loadMoreData(transform);
          console.log("Load more data as zoom out extreme is reached",zoomOutMinVal,zoomOutChartBased,transform);
        }
      }
      
      
      //handles the x scale rescaling and slicing the date exis text if required
      let scaleX=undefined;
     

      if(!drawText){
      if(zX.k>=0.75){
        scaleX =xScale.copy()
        .range((xScale.range().map((d,i) => (d*zX.k+zX.x))))
        // .domain((xScale.domain().map((date,i) => (date.slice(date.length - 5)))))
      }else{
        scaleX =xScale.copy()
        .range((xScale.range().map((d,i) => (d*zX.k+zX.x))))
        // .domain((xScale.domain().map((date,i) => (date.slice(date.length - 5)))))
    
      }
    }else{
      scaleX=xScale;
    }
    // console.log("cords xscale range=",scaleX.range());
      
  
      let leftIndex=binarySearch(scaleX.copy().range(),0-props.chartStateData.left);
      let rightIndex=binarySearch(scaleX.copy().range(),canvasWidth-5);
    
      let scaleY =zY.rescaleY(yScale);
      let temlScale=zY.rescaleY(yScale)
        

      
      if(zoomType==OF_ZOOM_TYPE.ASYMMETRIC){
        if(!yZoom){
        
          let tx={x:transform.x,y:0,k:transform.k}
          // setTransformData(tx);
          console.log("Recenter <=======  DRAW FUNCTION CALLED ======>", yZoom, transform,tx);
          transform.y=0;
          setTransformData(transform);
          // console.log("zoom event XAxis setTransform=",transform)
        }
        else{
        setTransformDataY(transform);
        // console.log("zoom event YAxis setTransform=",transform)
        }
      }else{
        setTransformData(transform);
        setTransformDataY(transform);
      }
         
     
      gxAxis.call(xAxis.scale(scaleX));
      gyAxis.call(yAxis.scale(scaleY));
     
    
      gxAxis.selectAll("text").each(function(d, i) {
        
        let xT=(scaleX.range()[i + 1] - scaleX(d))/2;
        if(i+1!=scaleX.range().length-1){
        if(zX.k<0.6)
          d3.select(this).attr("transform", `translate(${xT},4) scale(${1-((1-zX.k)/2)})`)
        else
          d3.select(this).attr("transform", `translate(${xT},4)`)
        }else{
      
          xT=50;
          if(zX.k<0.6)
            d3.select(this).attr("transform", `translate(${xT},4) scale(${1-((1-zX.k)/2)})`)
          else
            d3.select(this).attr("transform", `translate(${xT},4)`)
      

        }
      })
    
      // context.clearRect(0, 0, canvasWidth, canvasHeight);
      // contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
      const isLiveSession=( historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive)?true:false;

      //performance improvement only drawing the profile under the screen view
      if(leftIndex==-1 || leftIndex==undefined)
      leftIndex=0;  
      if(rightIndex>historicalData.config.data.length-1 || rightIndex==undefined)
      rightIndex=historicalData.config.data.length-1; 
      
      console.log("leftIndex, rightIndex=",leftIndex,rightIndex,historicalData.config.data.length);

      let tooltipData={
          data:[]
      };
      

      if(props.globalVolumeVisible && props.selectedInstrumentData.vp==true && !drawText){
        //TODO: check if 60% of the profile is visible
        if(benchmarkingEnabled)
        console.time("Benchmark_OF_GLOBAL_VOLUME")
       
        
        //incase smaller chart set the left index to first profiles
        if(leftIndex===-1)
          leftIndex=0;
        // else
        //   leftIndex=historicalData.config.data[historicalData.config.data.length-1];

          console.log("OF Vol=",props.volumeNumberVisible,transform,leftIndex,rightIndex,historicalData.config.data[leftIndex].profileIndex)
        // let volumeData=getVolumeProfilesInRange(leftIndex,rightIndex);
        let volumeData=getOFVolumeProfilesInRange(historicalData.config.data[leftIndex].profileIndex,historicalData.profilesData.length-1,historicalData.profilesData[historicalData.config.data[leftIndex].profileIndex].startIndex);
        let width=Math.ceil(0.15*canvasWidth/zX.k);
         console.log("G V id<=================> li, ri = ",props.id,leftIndex,rightIndex,volumeData,transform,width,canvasWidth);
        
        for(let i=0;i<volumeData.data.length;i++){
            drawVolumes(i,(-1*(zX.x/zX.k)),volumeData.minTPO+i*selectedTPO,volumeData.data[i],volumeData.maxPerc,width,props.volumeNumberVisible,zY)
        }

        if(benchmarkingEnabled)
        console.timeEnd("Benchmark_OF_GLOBAL_VOLUME")
       
         
      
      } 

      //calculate font size and font stretch (default 14px and 16px for bigger font), letter spacing if required(default is 0px)
      let fontSizeDefaualt=14;
      let tableFont=14;
      let fontSizeBig=15;
      let fontStretch="extra-expanded";//"normal";
      let letterSpacing=0;
      let defaultFontWeight=500;
      let boldFontWeight=600;
      let bModified=false;
     
      if(zX.k==1){
        if(zY.k>1){
          fontSizeDefaualt=Math.round((fontSizeDefaualt/zY.k)+2*(zY.k-1));
          // fontSizeDefaualt=Math.ceil((fontSizeDefaualt/zY.k));
          // fontSizeBig=Math.ceil(fontSizeBig/zY.k);
          fontSizeBig=Math.round((fontSizeBig/zY.k)+2*(zY.k-1));
           defaultFontWeight=Math.min(defaultFontWeight+Math.ceil(600*(zY.k-1)),650);
          boldFontWeight=Math.min(boldFontWeight+Math.ceil(600*(zY.k-1)),1000);
          letterSpacing=3*(zY.k-1);
          fontStretch="extra-expanded";
          
      
        }else if(zY.k<1){
          fontSizeDefaualt=Math.ceil((fontSizeDefaualt*zY.k)+10*(1-zY.k));
          fontSizeBig=Math.ceil((fontSizeBig*zY.k)+10*(1-zY.k));
          // fontSizeBig=Math.ceil(fontSizeBig*zY.k);
          defaultFontWeight=defaultFontWeight+Math.ceil(300*(1-zY.k));
          boldFontWeight=boldFontWeight+Math.ceil(300*(1-zY.k));
          // boldFontWeight=boldFontWeight+Math.ceil(600*(1-zY.k));
          letterSpacing=3*(1-zY.k);

        }
        tableFont=fontSizeDefaualt;

      }else if(zX.k>1){
        if(zY.k==1){
          fontSizeDefaualt=Math.ceil((fontSizeDefaualt/zX.k)+3*(zX.k-1));
          fontSizeBig=Math.ceil((fontSizeBig/zX.k)+3*(zX.k-1));
          // fontSizeBig=Math.ceil(fontSizeBig/zY.k);
          //  defaultFontWeight=defaultFontWeight+Math.ceil(600*(zY.k-1));
          // boldFontWeight=boldFontWeight+Math.ceil(600*(zY.k-1));
          // letterSpacing=(1-zX.k);
          
      
        
      }
        else if(zY.k>1){
          fontSizeDefaualt=Math.ceil((fontSizeDefaualt/zY.k)+1*(zY.k-1));
          fontSizeBig=Math.ceil((fontSizeBig/zY.k)+1*(zY.k-1));
          //  defaultFontWeight=defaultFontWeight+Math.ceil(600*(zY.k-1));
          // boldFontWeight=boldFontWeight+Math.ceil(600*(zY.k-1));
          letterSpacing=3*(zY.k-1);
          
      
        
      }else if(zY.k<1){
        // fontSizeDefaualt=20;
        // fontSizeDefaualt=Math.min(Math.ceil((fontSizeDefaualt/zY.k)-20*(zX.k-1)),15);
        fontSizeDefaualt=Math.max(Math.ceil((fontSizeDefaualt/zX.k)+3*(zX.k-1)),13);
          fontSizeBig=Math.max(Math.ceil((fontSizeBig/zX.k)+3*(zX.k-1)),14);

        // fontSizeDefaualt=Math.ceil((fontSizeDefaualt*zY.k)+10*(1-zY.k));
        // fontSizeBig=Math.ceil((fontSizeBig*zY.k)+10*(1-zY.k));
        // fontSizeBig=Math.ceil(fontSizeBig*zY.k);
        // defaultFontWeight=defaultFontWeight+Math.ceil(100*(1-zY.k));
        // boldFontWeight=boldFontWeight+Math.ceil(100*(1-zY.k));
        // boldFontWeight=boldFontWeight+Math.ceil(600*(1-zY.k));
        // letterSpacing=-2;
        // defaultFontWeight=400;
        fontStretch="ultra-condensed";

      }
      tableFont=fontSizeDefaualt;
      
      }else{
        if(zY.k>1){
          fontSizeDefaualt=Math.ceil((fontSizeDefaualt/zY.k)+2*(zY.k-1));
          fontSizeBig=fontSizeDefaualt+1;
          // fontSizeBig=Math.ceil((fontSizeBig/zY.k)+1*(zY.k-1));
           defaultFontWeight=defaultFontWeight+Math.ceil(500*(zY.k-1));
          boldFontWeight=boldFontWeight+Math.ceil(500*(zY.k-1));
          letterSpacing=2*(zY.k-1);
          tableFont=fontSizeDefaualt;
          
      
        
      }

      }


      //zoom out limt for visibility
      //y :0.53
      //x: 
      console.log("Font values=",zX.k,zY.k,fontSizeDefaualt,fontSizeBig,fontStretch,letterSpacing,defaultFontWeight,boldFontWeight,zX.k,zY.k,tableFont);

     

      // for(let i=0;i<historicalData.profilesData.length;i=i+1){
        if(benchmarkingEnabled)
      console.time("Benchmark_OF_ALL_CNDLE")
       
      for(let i=leftIndex;i<=rightIndex;i=i+1){
        let currTooltipData=[];
        tooltipData.data[i]=[];
        
         drawCandle(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],temlScale,zX,fontSizeBig,boldFontWeight,zY,image)
        
        
        }
        if(benchmarkingEnabled)
        console.timeEnd("Benchmark_OF_ALL_CNDLE")
      
        
       
        //draw cumu vpoc and vwap marker
        // console.log("cumu =",yScale(historicalData.config.data[leftIndex+3].cumu_vpoc),historicalData.config.data[leftIndex+3].cumu_vpoc,historicalData.config.data[leftIndex+5].cumu_vpoc)
        // context.save()

         //draw daily markers(IB,Day high etc)
         let profileTooltipData=[];
         if(userSettings.data.show_metadata_marker_of || userSettings.data.show_metadata_marker_of==undefined){
          if(benchmarkingEnabled)
            console.time("Benchmark_OF_DAILY_MARKER")
         
            drawDailyMarker(xScaleRange,zX,zY,profileTooltipData);
         
         if(benchmarkingEnabled)
          console.timeEnd("Benchmark_OF_DAILY_MARKER")
         }
        
        
        context.strokeStyle=CHART_COLORS.OF_VPOC;
        // console.log("VPOC test start")
        if(benchmarkingEnabled)
        console.time("Benchmark_OF_CUM_VPOC")
        for(let i=leftIndex;i<=rightIndex;i=i+1){
          
          context.lineWidth =OF_CHART_LINE_WIDTH.VPOC/zY.k;
          let px=xScaleRange[i];
          let pxNext=xScaleRange[i+1];

          let vpoc=parseFloat(((historicalData.config.data[i].cumu_vpoc*10000)/10000).toFixed(4));
          let vpocMod=parseFloat(((historicalData.config.data[i].cumu_vpoc*10000)%(selectedTPO*10000))/10000).toFixed(4);
          let vpocTPO=parseFloat(((vpoc-vpocMod)).toFixed(4));
          
          context.beginPath();
          context.moveTo(px, yScale(vpocTPO));
          context.lineTo(px+PROFILE_MIN_WIDTH+40, yScale(vpocTPO));
          context.stroke();
          let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          let randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.strokeStyle=randomColor;
          contextTooltip.lineWidth=2*OF_CHART_LINE_WIDTH.VPOC/zY.k;
          contextTooltip.beginPath();
          contextTooltip.moveTo(px, yScale(vpocTPO));
          contextTooltip.lineTo(px+PROFILE_MIN_WIDTH+40, yScale(vpocTPO));
          contextTooltip.stroke();
          let tempData=[];
          tempData.push({key:"DPOC",value:historicalData.config.data[i].cumu_vpoc.toFixed(2)})
           tooltipData.data[i].push({
                 [randomColor]:tempData
               })
          // console.log("VPOC test",i,px,px+PROFILE_MIN_WIDTH+40,yScale(historicalData.config.data[i].cumu_vpoc),yScale(historicalData.config.data[i].cumu_vpoc),2/zY.k,context.lineWidth)
          if(i!=rightIndex){
            let vpocNext=parseFloat(((historicalData.config.data[i+1].cumu_vpoc*10000)/10000).toFixed(4));
            let vpocModNext=parseFloat(((historicalData.config.data[i+1].cumu_vpoc*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let vpocTPONext=parseFloat(((vpocNext-vpocModNext)).toFixed(4));

            context.lineWidth = OF_CHART_LINE_WIDTH.VPOC/zX.k;
            context.beginPath();
            context.moveTo(px+PROFILE_MIN_WIDTH+40, yScale(vpocTPO));
            context.lineTo(px+PROFILE_MIN_WIDTH+40, yScale(vpocTPONext));
            // console.log("VPOC test 2",i,px,px+PROFILE_MIN_WIDTH+40,yScale(historicalData.config.data[i].cumu_vpoc),yScale(historicalData.config.data[i+1].cumu_vpoc),2/zX.k,context.lineWidth);
            context.stroke();
          }else{
            context.lineWidth = OF_CHART_LINE_WIDTH.VWAP/zY.k;
            context.beginPath();
            context.moveTo(px+PROFILE_MIN_WIDTH+40, yScale(vpocTPO));
            context.lineTo(px+PROFILE_MIN_WIDTH+40+dummyProfileWidth, yScale(vpocTPO));
            context.stroke();
            val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
            randomColor=rgbToHex(randomR,randomG,randomB);
            contextTooltip.strokeStyle=randomColor;
            contextTooltip.lineWidth=2*OF_CHART_LINE_WIDTH.VPOC/zY.k;
            contextTooltip.beginPath();
            contextTooltip.moveTo(px+PROFILE_MIN_WIDTH+40, yScale(vpocTPO));
            contextTooltip.lineTo(px+PROFILE_MIN_WIDTH+40+dummyProfileWidth, yScale(vpocTPO));
            contextTooltip.stroke();
            let tempData=[];
            tempData.push({key:"VPOC",value:historicalData.config.data[i].cumu_vpoc.toFixed(2)})
            if( tooltipData.data[i+1]==undefined || tooltipData.data[i+1].length==0){
                tooltipData.data[i+1]=[];
            }
            tooltipData.data[i+1].push({
                   [randomColor]:tempData
            })    
            
          }
          
          // context.closePath();
        }
        if(benchmarkingEnabled)
        console.timeEnd("Benchmark_OF_CUM_VPOC")

        context.strokeStyle=CHART_COLORS.OF_VWAP;
        if(benchmarkingEnabled)
        console.time("Benchmark_OF_CUM_VWAP")
        for(let i=leftIndex;i<=rightIndex;i=i+1){
          context.lineWidth =OF_CHART_LINE_WIDTH.VWAP/zY.k;
          let px=xScaleRange[i];
          let pxNext=xScaleRange[i+1];
          
          let vwap=parseFloat(((historicalData.config.data[i].cumu_vwap*10000)/10000).toFixed(4));
          let vwapMod=parseFloat(((historicalData.config.data[i].cumu_vwap*10000)%(selectedTPO*10000))/10000).toFixed(4);
          let vwapTPO=parseFloat(((vwap-vwapMod)).toFixed(4));
          
          context.beginPath();
          context.moveTo(px, yScale(vwapTPO));
          context.lineTo(px+PROFILE_MIN_WIDTH+40, yScale(vwapTPO));
          context.stroke();

          let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          let randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.strokeStyle=randomColor;
          contextTooltip.lineWidth=2*OF_CHART_LINE_WIDTH.VWAP/zY.k;
          contextTooltip.beginPath();
          contextTooltip.moveTo(px, yScale(vwapTPO));
          contextTooltip.lineTo(px+PROFILE_MIN_WIDTH+40, yScale(vwapTPO));
          contextTooltip.stroke();
          let tempData=[];
          tempData.push({key:"VWAP",value:historicalData.config.data[i].cumu_vwap.toFixed(2)})
           tooltipData.data[i].push({
                 [randomColor]:tempData
               })

          if(i!=rightIndex){
            let vwapNext=parseFloat(((historicalData.config.data[i+1].cumu_vwap*10000)/10000).toFixed(4));
            let vwapModNext=parseFloat(((historicalData.config.data[i+1].cumu_vwap*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let vwapTPONext=parseFloat(((vwapNext-vwapModNext)).toFixed(4));

            context.lineWidth = OF_CHART_LINE_WIDTH.VWAP/zX.k;
            context.beginPath();
            context.moveTo(px+PROFILE_MIN_WIDTH+40, yScale(vwapTPO));
            context.lineTo(px+PROFILE_MIN_WIDTH+40, yScale(vwapTPONext));
            context.stroke();
          }else{
            context.lineWidth = OF_CHART_LINE_WIDTH.VWAP/zY.k;
            context.beginPath();
            context.moveTo(px+PROFILE_MIN_WIDTH+40, yScale(vwapTPO));
            context.lineTo(px+PROFILE_MIN_WIDTH+40+dummyProfileWidth, yScale(vwapTPO));
            context.stroke();

          val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.strokeStyle=randomColor;
          contextTooltip.lineWidth=2*OF_CHART_LINE_WIDTH.VWAP/zY.k;
          contextTooltip.beginPath();
          contextTooltip.moveTo(px+PROFILE_MIN_WIDTH+40, yScale(vwapTPO));
          contextTooltip.lineTo(px+PROFILE_MIN_WIDTH+40+dummyProfileWidth, yScale(vwapTPO));
          contextTooltip.stroke();
          let tempData=[];
          tempData.push({key:"VWAP",value:historicalData.config.data[i].cumu_vwap.toFixed(2)})
          if( tooltipData.data[i+1]==undefined || tooltipData.data[i+1].length==0){
              tooltipData.data[i+1]=[];
          }
          tooltipData.data[i+1].push({
                 [randomColor]:tempData
          })
        }
        
          // context.closePath();
        }
        if(benchmarkingEnabled)
        console.timeEnd("Benchmark_OF_CUM_VWAP")

       

        //draw NPOC
        let rightMostNPOCList=[];
       if(userSettings.data.show_ui_npoc_krl_of || userSettings.data.show_ui_npoc_krl_of==undefined){
        if(benchmarkingEnabled)
        console.time("Benchmark_OF_NPOC")
        let startIndexNPOC=historicalData.profilesData[historicalData.config.data[leftIndex].profileIndex].startIndex;
        let divisionFactorNew=zX.k>1?zX.k:1;
        let textHidden=(zX.k<=XSCALE_ZOOM_OUT_DISPLAY || zY.k<=YSCALE_ZOOM_OUT_DISPLAY)
        // console.log("NPOC Test=",scaleY.domain()[0],scaleY.domain()[1]);
        
        context.save();
       
        for(let i=0;i<=rightIndex;i=i+1){
          // console.log("NPOC Test outside tooltip=",tooltipData.data[i])

          //if vpoc of the profile is not within the scale range, dont draw it
          if(historicalData.config.data[i].vpoc>=scaleY.domain()[1] ||historicalData.config.data[i].vpoc<=scaleY.domain()[0])
          continue;

          let isRightMostNPOC=false;
         
          if(historicalData.config.data[i].vpoc){
           
           
            let count=0;
            
            for(let j=i+1;j<=historicalData.config.data.length-1;j++){
              // console.log("NPOC=",i,j,historicalData.config.data[i].vpoc,historicalData.config.data[j].highVal,historicalData.config.data[j].lowVal)
             
              if((historicalData.config.data[i].vpoc>historicalData.config.data[j].highVal && historicalData.config.data[i].vpoc>historicalData.config.data[j].lowVal)||
              (historicalData.config.data[i].vpoc<historicalData.config.data[j].highVal && historicalData.config.data[i].vpoc<historicalData.config.data[j].lowVal)){
                // console.log("NPOC= inside count")
                count=count+1;
              }else{
                break;
              }
            }
            // xCord+120+12*divisionFactorNew
            if(count>=OF_NPOC_MIN_PROFILE_DIFFERENCE){
              let npoc=parseFloat(((historicalData.config.data[i].vpoc*10000)/10000).toFixed(4));
              let npocMod=parseFloat(((historicalData.config.data[i].vpoc*10000)%(selectedTPO*10000))/10000).toFixed(4);
              let npocTPO=parseFloat(((npoc-npocMod)).toFixed(4));

              let krlCategory=OF_MARKER_STYLING_CATEGORY["npoc"]
              let lineWidth=krlCategory.width;
              if(krlCategory.type==KRL_CATEOGRY_TYPE.DASHED_LINE)
              context.setLineDash([3/zX.k, 3/zX.k]);
      
             
              context.lineWidth =lineWidth/zY.k;
              // context.setLineDash([6/zX.k,6/zX.k]);
              
              context.strokeStyle=CHART_COLORS.OF_NPOC;
              if(i+count==historicalData.config.data.length-1){
                // console.log("NPOC Test= Extended to right most",historicalData.config.data[i].vpoc.toFixed(2))
                rightMostNPOCList.push(npocTPO.toFixed(2))
                isRightMostNPOC=true;
              }
              if(!textHidden && !isRightMostNPOC){
              context.beginPath();
              
              context.moveTo(xScaleRange[i]+142+12*divisionFactorNew, yScale(npocTPO));
              context.lineTo(xScaleRange[i+count]+PROFILE_MIN_WIDTH+40-40-20/divisionFactorNew-8, yScale(npocTPO));
              context.stroke();

              context.beginPath();
              context.moveTo(xScaleRange[i+count]+PROFILE_MIN_WIDTH+40, yScale(npocTPO));
              context.lineTo(xScaleRange[i+count]+PROFILE_MIN_WIDTH+40+24/divisionFactorNew, yScale(npocTPO));
              context.stroke();
              }else{
                if(isRightMostNPOC){
                  context.beginPath();
                  context.moveTo(xScaleRange[i]+142+12*divisionFactorNew, yScale(npocTPO));
                  context.lineTo(xScaleRange[i+count]+PROFILE_MIN_WIDTH+40+dummyProfileWidth-80, yScale(npocTPO));
                  context.stroke();
                }else{
                  context.beginPath();
                context.moveTo(xScaleRange[i]+142+12*divisionFactorNew, yScale(npocTPO));
                context.lineTo(xScaleRange[i+count]+PROFILE_MIN_WIDTH+40+24/divisionFactorNew, yScale(npocTPO));
                context.stroke();
                }

              }

              let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
              let randomColor=rgbToHex(randomR,randomG,randomB);
            contextTooltip.strokeStyle=randomColor;
            contextTooltip.lineWidth=8/zY.k;
            contextTooltip.beginPath();
            contextTooltip.moveTo(xScaleRange[i]+142+12*divisionFactorNew, yScale(npocTPO));
            contextTooltip.lineTo(xScaleRange[i+count]+PROFILE_MIN_WIDTH+40+(!isRightMostNPOC? 24/divisionFactorNew:dummyProfileWidth), yScale(npocTPO));
            contextTooltip.stroke();
            profileTooltipData.push({x1:xScaleRange[i]+142+12*divisionFactorNew,x2:xScaleRange[i+count]+PROFILE_MIN_WIDTH+40+(!isRightMostNPOC? 24/divisionFactorNew:dummyProfileWidth),tpo:npocTPO,key:"NPOC",value:historicalData.config.data[i].vpoc.toFixed(2)})
       
              if(!textHidden && !isRightMostNPOC){
               
                let boldFont=`${defaultFontWeight} ${fontSizeDefaualt}px 'Roboto Flex'`;
                context.font=boldFont;
                context.fillStyle=CHART_COLORS.OF_NPOC_TEXT;
                context.textAlign='right';
                // context.fillText(historicalData.config.data[i].vpoc.toFixed(2),(xScaleRange[i]+142+12*divisionFactorNew+xScaleRange[i+count]+PROFILE_MIN_WIDTH+40)/2, yScale(historicalData.config.data[i].vpoc)-4)
                context.fillText(historicalData.config.data[i].vpoc.toFixed(2),xScaleRange[i+count]+PROFILE_MIN_WIDTH+40-4, yScale(npocTPO)+5)
               
              }
             
            }
            
          }

        }
        if(benchmarkingEnabled)
        console.timeEnd("Benchmark_OF_NPOC")
        context.restore();
      }

       
      if(benchmarkingEnabled)
      console.time("Benchmark_OF_ALL_PROFILE")
        for(let i=leftIndex;i<=rightIndex;i=i+1){
          let currTooltipData=[];
          // tooltipData.data[i]=[];
          
          //  drawCandle(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],temlScale,zX,fontSizeBig,boldFontWeight,zY)
          
           for(let j=0;j<historicalData.config.data[i].data.length;j=j+1){
              //  console.log("OFData historicalData.config.data[i].data",j,historicalData.config.data[i],xScaleRange[i])
              // if(benchmarkingEnabled)
              // console.time("Benchmark_OF_PER_PROFILE")
            
              historicalData.config.data[i].data[j].forEach( (ofData,currIndex) => {
              drawOFProfileNew(xScaleRange[i], historicalData.config.data[i].minTPO+j*selectedTPO,ofData, tooltipData.data[i],zX,zX.k/zY.k,zY,fontSizeDefaualt,fontSizeBig,fontStretch,letterSpacing,defaultFontWeight,boldFontWeight,historicalData.config.data[i])
                });
              //   if(benchmarkingEnabled)
              // console.timeEnd("Benchmark_OF_PER_PROFILE")
              }
          
          }
          if(benchmarkingEnabled)
              console.timeEnd("Benchmark_OF_ALL_PROFILE")
       
        // context.restore();
  
   
        if(benchmarkingEnabled)
        console.timeEnd("Benchmark_OF_draw1")
          //plot table and table data

          if(zX.k>=XSCALE_ZOOM_OUT_DISPLAY && zY.k>=YSCALE_ZOOM_OUT_DISPLAY && props.bottomTableVisible && getBottomTableHeight()>10){
            setTableVisible(true);
            drawTableBase(xScaleRange[leftIndex],zX,scaleY,zY,(-1*(zX.x/zX.k)),canvasWidth/zX.k);
            for(let i=leftIndex;i<=rightIndex;i=i+1){
              // console.log("Test drawtable=",scaleY.domain(),scaleY.range(),yScale.domain(),yScale.range());
              drawTable(xScaleRange[i],historicalData.config.data[i],zX,scaleY,zY,tableFont,boldFontWeight,letterSpacing,i,canvasWidth/zX.k)
            
            }
          }else{
            setTableVisible(false);
          }

           // draw all the right most NPOCs text

        d3.selectAll(`#npoc_label${props.id}`).remove()
        for(let i=0;i<rightMostNPOCList.length;i++){
        container.append("div")
             .style("left", (canvasWidth - 55) + "px")
            .attr("id", "npoc_label"+props.id)
            .style("top", scaleY(rightMostNPOCList[i])+24+TPO_HEIGHT+7 + "px")   //10=height of tooltip/2 80=Headers add height
            .style("position","absolute")
            .style("font-size","12px")
            .style("font-weight",500)
            .style("font-family","sans-serif")
            .style("background","#022D5A")
            .style("pointer-events","none")
            .style("color",`${CHART_COLORS.OF_NPOC_TEXT}`)
            .html(rightMostNPOCList[i]);
        }

            //live marker
    if( historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive){
      // if(true){
      context.save()
      context.beginPath()
      if(zY.k!=1)
       context.lineWidth=3/zY.k;
      else 
        context.lineWidth=3;
     
      context.setLineDash([1/zX.k,1/zX.k]);
      // console.log("-1*(transform.x/transform.k)")
      context.moveTo(-1*(zX.x/zX.k), yScale(historicalData.config.data[historicalData.config.data.length-1].close))
      context.lineTo(6*canvasWidth, yScale(historicalData.config.data[historicalData.config.data.length-1].close))
     
      context.strokeStyle = CHART_COLORS.LIVE_PRICE_LINE;
      context.stroke();
      // context.closePath();
      context.restore();
      
      //live marker close value tooltip
      if(scaleY(historicalData.config.data[historicalData.config.data.length-1].close)>=0 && scaleY(historicalData.config.data[historicalData.config.data.length-1].close)<=canvasHeight){
        d3.selectAll(`#tooltipLiveMarker${props.id}`).style("display", "block");
        tooltipLiveMarker.style("left", (canvasWidth - 0) + "px")
            .style("top", scaleY(historicalData.config.data[historicalData.config.data.length-1].close)+35 + "px")   //10=height of tooltip/2 80=Headers add height
            .html(historicalData.config.data[historicalData.config.data.length-1].close.toFixed(2)+"\n"+historicalData.config.lastDataTime);
      }else{
        d3.selectAll(`#tooltipLiveMarker${props.id}`).style("display", "none");
      
      
      }

      //live profile closing_vwap tooltip
      if(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP!=undefined && historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP!=0 && scaleY(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP)>=0 && scaleY(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP)<=canvasHeight){
        d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).style("display", "block");
        tooltipLiveCloseVwap.style("left", (canvasWidth - 0) + "px")
            .style("top", scaleY(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP)+50 + "px")   //10=height of tooltip/2 80=Headers add height
            .html(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP.toFixed(2));
      }else{
        d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).style("display", "none");
       
      
      }
    }else{
      d3.selectAll(`#tooltipLiveMarker${props.id}`).remove();
      d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).remove();
    }


  
        
        contextDummy.clearRect(0, 0, canvasWidth, canvasHeight);
        dummyCanvas.on("contextmenu", function(event) {
          event.preventDefault();
          return;
          
        })

        // const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
        //   const hex = x.toString(16)
        //   return hex.length === 1 ? '0' + hex : hex
        // }).join('')

        
        svgChart.on("mousemove", function(event) {
          setShowTooltip(false);
          console.log("testing1 mousemove show=",showYAxisContextMenu,yaxiscontextMenuRef.current);
          if(!yaxiscontextMenuRef.current){
          const priceVal=(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
          if(priceVal>=0){
          d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
          tooltipCrossHair.style("left", (canvasWidth - 60) + "px")
            .style("top", (event.pageY-12-48 -props.chartStateData.top) + "px")   //12=height of tooltip/2 80=Headers add height
            .html(priceVal);
          }
        }
        });
       
        const handleYAxisMouseMove=(event)=>{
          setShowTooltip(false);
          if(!props.basicUser){
          if(!yaxiscontextMenuRef.current){
            
            const priceVal=(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
            if(priceVal>=0){
              d3.selectAll(`#krlAxisTooltip${props.id}`).style("display", "none");
            d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
            tooltipCrossHair.style("left", (canvasWidth - 60) + "px")
              .style("top", (event.pageY-12-48 -props.chartStateData.top) + "px")   //12=height of tooltip/2 80=Headers add height
              .html(priceVal);
            }
          }
        }
     }
        
      //handles the mose move to display cross hairs and tooltips
      dummyCanvas.on("dblclick", function(event,d){
        event.preventDefault();
        console.log("double click on dummy canvas called event=",event )
      })

      dummyCanvas.on("mousemove", function(event,d){
        // console.log("testing mousemove canvas",event);
        setShowTooltip(false);
        // let col = context.getImageData(event.offsetX, event.offsetY, 1, 1).data;
        // console.log("mousemove event=, col",event,col,rgbToHex(col[0],col[1],col[2]));

       
       

          //Restrict the display if mouse position is outside the profiles
          // if(canvasWidth>historicalData.config.totalWidth && event.pageX<canvasWidth-historicalData.config.totalWidth){
          //   contextDummy.clearRect(0, 0, canvasWidth, canvasHeight)
          //   d3.selectAll("#tooltipCrosshair").style("display", "none");
          //   d3.selectAll("#tooltipCrosshairDateAxis").style("display", "none");
          //   console.log("Cross hair returning =====> cw, pw",canvasWidth,historicalData.config.totalWidth );
          //   return;
          // }
            contextDummy.clearRect(0, 0, canvasWidth, canvasHeight)
            if(zoomType==OF_ZOOM_TYPE.SYMMETRIC){
              if(event.pageX-props.chartStateData.left>canvasWidth){
                d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
                handleYAxisMouseMove(event);
              return;
              }
            }
            
            const index=binarySearch(scaleX.copy().range(),event.pageX-props.chartStateData.left);
            setCrossHairCord({x:event.pageX,y:event.pageY,tpo:(scaleY.invert(event.pageY-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-CHART_TOP_SPACE))%selectedTPO).toFixed(2)});
           
            const dateVal=(index>=0 && index<=modDateList.length-1)?modDateList[index]:undefined;
            // console.log("final tooltip data",index,modDateList[index],tooltipData.data[index],dateVal);
            const priceVal=(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
            if(dateVal!=undefined && contextMenuRef.current==false){
                // console.log("tooltipdata=",tooltipData);
                let col1 = contextTooltip.getImageData(event.offsetX, event.offsetY, 1, 1).data;
                let bgColor=rgbToHex(col1[0],col1[1],col1[2]) 
                let data=tooltipData.data[index]?tooltipData.data[index].filter(item => bgColor in item):[];
                // let data=tooltipData.data[index].filter(item => bgColor in item)
                // console.log("final tooltip data",data[0],bgColor,tooltipData.data,index,tooltipData.data[index]);
                let tempData=[];
                let found=false;
                if(data.length>0){
                  tempData=JSON.parse(JSON.stringify(data[0][bgColor]));
                  found=true;
                }

                  // //check for special nodes
                  // //VPOC
                  // if(historicalData.profilesData[index].volumeBasedVA){
                  //   if(priceVal==historicalData.profilesData[index].vpocTPO){
                  //     tempData.push({key:"VPOC",value:historicalData.profilesData[index].vpoc})
                  //     tempData.push({key:"VPOC Vol",value:historicalData.profilesData[index].vpoc_vol})
                  //     found=true;
                  //   }
                  //   if(priceVal==historicalData.profilesData[index].vwapTPO){
                  //     tempData.push({key:"VWAP",value:historicalData.profilesData[index].vwap})
                  //     found=true;
                  //   }
                  // }else{
                  //   if(priceVal==historicalData.profilesData[index].vpocTPOBased){
                  //     tempData.push({key:"TPOC",value:historicalData.profilesData[index].tpoc})
                  //     found=true;
                  //   }
                  //   if(priceVal==historicalData.profilesData[index].vwapTPOBased){
                  //     tempData.push({key:"TWAP",value:historicalData.profilesData[index].tpo_vwap})
                  //     found=true;
                  //   }
                  // }

                  // console.log("krl tooltip final tooltip data",bgColor,profileTooltipData);
                  if(bgColor!="#000000"){
                    for(let i=0;i<profileTooltipData.length;i++){
                     
                      let x1=profileTooltipData[i].x1*zX.k+zX.x;
                      let x2=profileTooltipData[i].x2*zX.k+zX.x;
                      console.log("krl tooltip final tooltip data mousemove krl tooltip priceval,tpo",priceVal,profileTooltipData[i],transform,x1,x2)
                      if(priceVal==profileTooltipData[i].tpo && event.pageX-props.chartStateData.left>=x1 && event.pageX-props.chartStateData.left<x2){
                        console.log("krl tooltip final tooltip data mousemove krl tooltip priceval,tpo",priceVal,profileTooltipData[i])
                        tempData.push({key:profileTooltipData[i].key,value:profileTooltipData[i].value})
                        found=true;
                        
                      }
                    }
                  }
                 

                  if(found && !props.basicUser){
                    // console.log("final tooltip data found",tempData);
                    setTooltipData(tempData)
                    openTooltip(event.pageX,event.pageY,canvasWidth,canvasHeight)
                  }
               
          }
            
            // console.log("mousemove: index, x,y,date",index,event.pageX,event.pageY,dateVal,historicalData.config.dateList)
              if(priceVal>=0){
              contextDummy.beginPath()
              contextDummy.lineWidth=1;
              contextDummy.setLineDash([2, 2]);
              contextDummy.moveTo(0, event.pageY-props.chartStateData.top-CHART_TOP_SPACE)
              contextDummy.lineTo(canvasWidth, event.pageY-props.chartStateData.top-CHART_TOP_SPACE)
              contextDummy.strokeStyle = '#fff'
              contextDummy.stroke()
              }
              if(dateVal!=undefined){
                contextDummy.beginPath()
                contextDummy.setLineDash([2, 2]);
                contextDummy.moveTo(event.pageX-props.chartStateData.left,0)
                contextDummy.lineTo(event.pageX-props.chartStateData.left, canvasHeight)
                contextDummy.strokeStyle = '#fff'
                contextDummy.stroke()
              }
              contextDummy.closePath()
              
              if(priceVal>=0){
                d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
                tooltipCrossHair.style("left", (canvasWidth - 0) + "px")
                  .style("top", (event.pageY-12-48 -props.chartStateData.top) + "px")   //12=height of tooltip/2 80=Headers add height
                  .html(priceVal);
              }else{
                d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "none");
              }
              if(dateVal!=undefined){
                d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "block");
                tooltipCrossHairDateAxis.style("left", (event.pageX -props.chartStateData.left - (dateVal.length/4)*12 ) + "px")
                  .style("top", (XSCALE_HEIGHT+32) + "px")   //10=height of tooltip/2 80=Headers add height
                  .html(dateVal);
              }else{
                d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
              }
             
          })
            
          .on("mouseover", function () {
            d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
            d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "block");
          })
          .on("mouseout", (event, d) => {
            setCrossHairCord({x:-1,y:-1});
            contextDummy.clearRect(0, 0, canvasWidth, canvasHeight)
            d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "none");
            d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
           })

           //click events for profile selection
           dummyCanvas.on("click", function(event) {
            // d3.selectAll("#shape").style("opacity",0);
            if(chartInterationAllowedRef.current==false || (canvasWidth>historicalData.config.totalWidth && event.pageX<canvasWidth-historicalData.config.totalWidth)){
              contextDummy.clearRect(0, 0, canvasWidth, canvasHeight)
              d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "none");
              d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
              return;
            }
            
            if(contextMenuRef.current){
              setShowContextMenu(false);
              return;
             }
            
             //remove the index if already present
            function removeIndex(arr, value) {
      
              return arr.filter(function(index){
                  return index!=value;
              });
             
           }
          
           const index=binarySearch(scaleX.copy().range(),event.pageX-props.chartStateData.left);
            if(historicalData!=undefined && historicalData.profilesData[index]!=undefined){
              //if shift clicked then selec all the profiles from last selected index to this index
              //take care of duplicate indexes
              let temp=[];
                 
              if (event.shiftKey) {
                console.log("This is the shift function")
                if(selectedIndexes.length>0 &&  index!=selectedIndexes){
                  const minIndex=Math.min(...selectedIndexes);
                  const maxIndex=Math.max(...selectedIndexes);
                  if(index<minIndex){
                    for(let i=index;i<minIndex;i++){
                      temp.push(i);
                    }
                  }else{
                    for(let i=maxIndex+1;i<=index;i++){
                      temp.push(i);
                    }
                  }
                  console.log("temp = ",temp);
                  selectedIndexes=[...selectedIndexes, ...temp];

                }else if (index==selectedIndexes){ //same index, don't do anything
                  
                }else{
                  temp.push(index) 
                  selectedIndexes=[...selectedIndexes, ...temp];
                  console.log("else of shift ",)
                }
             }
             else{   
                if(!selectedIndexes.includes(index)){
                  temp.push(index)
                  selectedIndexes=[...selectedIndexes, ...temp]
                }else{
                 //deselect the already selected element
                 selectedIndexes = removeIndex(selectedIndexes, index);
                }
              
              }
              setSelectedProfilesIndex(selectedIndexes);
              contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight)
                
              for(let temp=0;temp<selectedIndexes.length;temp++)
                {
                  // console.log("inside indexes loop =",temp,selectedIndexes[temp])
                contextOverlay.fillStyle = `${theme.palette.primaryTheme.shade03}`;
                contextOverlay.fillRect(scaleX.range()[selectedIndexes[temp]]+1,2, scaleX.range()[selectedIndexes[temp]+1]-scaleX.range()[selectedIndexes[temp]]-1,canvasHeight);
                contextOverlay.stroke();
                }  
           }
        })

            //draw previous crossHair
            if(crossHairCord.x!=-1 && crossHairCord.y!=-1){
              // console.log("transform in crosshair=",transform.k,transformData.k);
              // d3.selectAll("#tooltipCrosshair").remove();
              // d3.selectAll("#tooltipCrosshairDateAxis").remove();
              contextDummy.clearRect(0,0,canvasWidth,canvasHeight);
              const index=binarySearch(scaleX.copy().range(), crossHairCord.x-props.chartStateData.left);
              const dateVal=(index>=0 && index<historicalData.config.timeList.length)?historicalData.config.timeList[index]:undefined;
              console.log("Crosshair redraw index,dateval=========>",index,dateVal);
              // context.moveTo(-1*(transform.x/transform.k), yScale(historicalData.profilesData[historicalData.profilesData.length-1].closeTPO))
              // context.lineTo(canvasWidth, yScale(historicalData.profilesData[historicalData.profilesData.length-1].close))
                contextDummy.save();
                contextDummy.beginPath()
                contextDummy.lineWidth=2/zY.k;
                contextDummy.setLineDash([Math.round(2/zX.k), Math.round(2/zX.k)]);
                contextDummy.moveTo(-1*(zX.x/zX.k), yScale(crossHairCord.tpo)-props.chartStateData.top-4)
                contextDummy.lineTo(6*canvasWidth, yScale(crossHairCord.tpo)-props.chartStateData.top-4)
                contextDummy.strokeStyle = '#999'
                contextDummy.stroke()
                if(dateVal!=undefined){
                  contextDummy.beginPath()
                  contextDummy.lineWidth=2/zX.k;
                  contextDummy.setLineDash([Math.round(2/zY.k), Math.round(2/zY.k)]);
                  contextDummy.moveTo((crossHairCord.x-zX.x)/zX.k-props.chartStateData.left,-1*(zY.y/zY.k))
                  // contextDummy.lineTo((crossHairCord.x-transform.x)/transform.k, canvasHeight/transform.k+Math.abs(transform.y));
                  contextDummy.lineTo((crossHairCord.x-zX.x)/zX.k-props.chartStateData.left, 99999);
                  // contextDummy.strokeStyle = '#999'
                  contextDummy.stroke()
                }
                contextDummy.closePath()
                contextDummy.restore();
                // console.log("cross hair========>",crossHairCord.x,transform.x/transform.k);
                d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
                tooltipCrossHair.style("left", (canvasWidth - 0) + "px")
                .style("top", (crossHairCord.y-props.chartStateData.top-48-10) + "px")   //10=height of tooltip/2 80=Headers add height
                .html(crossHairCord.tpo);
                if(dateVal!=undefined){
                d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "block");
                tooltipCrossHairDateAxis.style("left", (crossHairCord.x-props.chartStateData.left-6 - (dateVal.length/4)*10) + "px")
                .style("top", (XSCALE_HEIGHT+24+8) + "px")   //10=height of tooltip/2 80=Headers add height
                .html(dateVal);
                }
            
            }
            if(benchmarkingEnabled)
            console.timeEnd("Benchmark_OF_draw")
       
    }

  
    const drawOFProfileNew=(xCord,yCord,data,tooltipData,xTransform,factor,yTransform,defaultFontSize,bigFontSize,fontStretch,letterSpacing,defaultFontWeight,boldFontWeight,ofData)=>{
      // console.log("OFdata=",data);
    //  if((xTransform.k<0.7 && yTransform.k<0.7)|| yTransform.k<0.5 ||xTransform.k<0.65) return;

    // context.save();
    let divisionFactorNew=xTransform.k>1?xTransform.k:1;
    let px = (xCord+20);
    let py = yScale(yCord);
    let mulFactor=xTransform.k>1?1:1;
      if(true){
      let defaultFont=`${defaultFontWeight} ${defaultFontSize}px 'Roboto Flex'`;
      let boldFont=`${boldFontWeight} ${bigFontSize}px 'Roboto Flex'`;
      
    //  console.log("font values 1=",defaultFont,boldFont,px,xCord,py)
     
      context.fontStretch=fontStretch;
      context.letterSpacing=`${letterSpacing}px`;

      // console.log("cords",px,py)
      // context.font=data.isSellHighlighted? CHART_FONT_BOLD_OF_VOL:CHART_FONT_OF_VOL_DEFAULT;
      
      const isZoomedOutState=xTransform.k<=XSCALE_ZOOM_OUT_DISPLAY || yTransform.k<=YSCALE_ZOOM_OUT_DISPLAY

      if(showLLTMarker){
        let sellerLength=data.sell_vol.toString().length;
        let sellerPos=60-10.1*sellerLength-10-4;
        let buyerLength=data.buy_vol.toString().length;
        let buyerPos=60+(9.6*buyerLength)+6;

        let px1 = (xCord+20+15*divisionFactorNew*mulFactor);
        // if(data.isBigBuyer || data.isBigSeller){
        //   console.log("Big Seller Buyer tooltip before= ",tooltipData)
        // }
        if(data.isBigSeller){
          if( !isZoomedOutState){
          context.beginPath();
          context.fillStyle=CHART_COLORS.SELLER_LLT_MARKER_COLOR;
          context.arc( px+7+sellerPos+12*divisionFactorNew,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
          context.fill();
          }
      
          let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          let randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.fillStyle=randomColor;
          contextTooltip.fillRect(px1, (py-TPO_HEIGHT/2-4),50,TPO_HEIGHT/2+7);
          let tempData=[];
          // console.log("Big Seller big data.vpoc=",data, data.bigBuyerData,ofData.vpocTPO,data.bigSellerData[0].level,ofData)
          if(ofData.vpoc && ofData.vpocTPO==data.bigSellerData[0].level ){
            // console.log("Big Seller big data.vpoc=",data, data.bigSellerData,ofData.vpocTPO,data.bigSellerData[0].level,ofData)
            tempData.push({key:"VPOC",value:ofData.vpoc})
            tempData.push({key:"VPOC Vol",value:ofData.vpoc_vol})
          }
          for(let i=0;i<data.bigSellerData.length;i++){
            tempData.push({key:data.bigSellerData[i].tpo,value:props.contracts? data.bigSellerData[i].lot:data.bigSellerData[i].vol,color:CHART_COLORS.SELLER_LLT_MARKER_COLOR})
           
          }
          tooltipData.push({
            [randomColor]:tempData
          })
          // console.log("Big Seller Buyer tooltip after seller= ",tooltipData,ofData.time,ofData.vpocTPO,data.bigSellerData[0].level)
        }
       
        if(data.isBigBuyer){
          if( !isZoomedOutState){
          context.beginPath();
          context.fillStyle=CHART_COLORS.BUYER_LLT_MARKER_COLOR;
          context.arc( px+buyerPos+12*divisionFactorNew,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
          context.fill();
          }

          let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          let randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.fillStyle=randomColor;
          // contextTooltip.fillStyle="grey";
          contextTooltip.fillRect(px1+60, (py-TPO_HEIGHT/2-4),50,TPO_HEIGHT/2+7);
          let tempData=[];
          // console.log("Big Buyer big data.vpoc=",data, data.bigBuyerData,ofData.vpocTPO,data.bigBuyerData[0].level,ofData)
          if(ofData.vpoc && ofData.vpocTPO==data.bigBuyerData[0].level ){
            tempData.push({key:"VPOC",value:ofData.vpoc})
            tempData.push({key:"VPOC Vol",value:ofData.vpoc_vol})
          }
          for(let i=0;i<data.bigBuyerData.length;i++){
            tempData.push({key:data.bigBuyerData[i].tpo,value:props.contracts? data.bigBuyerData[i].lot:data.bigBuyerData[i].vol,color:CHART_COLORS.BUYER_LLT_MARKER_COLOR})
           
          }
          tooltipData.push({
            [randomColor]:tempData
          })

          // console.log("Big Seller Buyer tooltip after buyer= ",tooltipData,ofData.time,ofData.vpocTPO,data.bigBuyerData[0].level)
          
        }
    }

      context.font=data.isSellHighlighted? boldFont:defaultFont;
      context.fillStyle = data.isSellHighlighted?CHART_COLORS.SELL_VOL_HIGHLIGHTED_COLOR: CHART_COLORS.OF_VOL_NUM_COLOR;
      context.textAlign = "right";
      if(xTransform.k<=XSCALE_ZOOM_OUT_DISPLAY || yTransform.k<=YSCALE_ZOOM_OUT_DISPLAY){
        if(data.isSellHighlighted){
          let px1 = (px+15*divisionFactorNew);
          let perc=Math.ceil((data.sell_vol/ofData.maxSellVol)*100);
          let width=Math.max(55*perc/100,3);
          
         
          if(data.isBigSeller){
            context.beginPath();
            context.fillStyle=CHART_COLORS.SELLER_LLT_MARKER_COLOR;
            context.arc( px1+55-width+5,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
            context.fill();
            context.fillStyle=CHART_COLORS.SELL_VOL_HIGHLIGHTED_COLOR;
            context.fillRect(px1+55-width+10, (py-TPO_HEIGHT/2-1),width-10,TPO_HEIGHT/2+1);
          }else{
            context.fillStyle=CHART_COLORS.SELL_VOL_HIGHLIGHTED_COLOR;
            context.fillRect(px1+55-width, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
          }
  
         }else{
          let px1 = (px+15*divisionFactorNew);
          let perc=Math.ceil((data.sell_vol/ofData.maxSellVol)*100);
          let width=Math.max(55*perc/100,3);
          // console.log("width, perc=",perc,width,data.maxSellVol,data.sell_vol)
          
          // context.fillStyle=CHART_COLORS.SELL_VOL_BK;
          // // context.strokeRect(px, (py-TPO_HEIGHT/2-4),120,TPO_HEIGHT/2+7);
          // context.fillRect(px1+55-width, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
            
          if(data.isBigSeller){
            context.beginPath();
            context.fillStyle=CHART_COLORS.SELLER_LLT_MARKER_COLOR;
            context.arc( px1+55-width+5,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
            context.fill();
            context.fillStyle=CHART_COLORS.SELL_VOL_BK;
            context.fillRect(px1+55-width+10, (py-TPO_HEIGHT/2-1),width-10,TPO_HEIGHT/2+1);
          }else{
            context.fillStyle=CHART_COLORS.SELL_VOL_BK;
            context.fillRect(px1+55-width, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
          }

         }
      }
      else
      context.fillText(data.sell_vol, px+54+12*divisionFactorNew, py);

    
     
      context.font=data.isBuyHighlighted?boldFont:defaultFont;
      context.fillStyle = data.isBuyHighlighted?CHART_COLORS.BUY_VOL_HIGHLIGHTED_COLOR: CHART_COLORS.OF_VOL_NUM_COLOR;
      context.textAlign = "left";
      if(xTransform.k<=XSCALE_ZOOM_OUT_DISPLAY || yTransform.k<=YSCALE_ZOOM_OUT_DISPLAY){
        if(data.isBuyHighlighted){
          let px2 = (px+60+15*divisionFactorNew);
          let perc=Math.ceil((data.buy_vol/ofData.maxBuyVol)*100);
          let width=Math.max(55*perc/100,3);
          if(data.isBigBuyer){
            context.beginPath();
            context.fillStyle=CHART_COLORS.BUYER_LLT_MARKER_COLOR;
            context.arc( px2+width-5,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
            context.fill();
            context.fillStyle=CHART_COLORS.BUY_VOL_HIGHLIGHTED_COLOR;
            context.fillRect(px2, (py-TPO_HEIGHT/2-1),width-10,TPO_HEIGHT/2+1);
          }else{
            context.fillStyle=CHART_COLORS.BUY_VOL_HIGHLIGHTED_COLOR;
            context.fillRect(px2, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
          }
          
  
         }else{
          let px2 = (px+60+15*divisionFactorNew);
          let perc=Math.ceil((data.buy_vol/ofData.maxBuyVol)*100);
          let width=Math.max(55*perc/100,3);
          // console.log("width, perc=",perc,width,data.maxSellVol,data.sell_vol)
          
          // context.fillStyle=CHART_COLORS.BUY_VOL_BK;
          // context.fillRect(px2, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
          if(data.isBigBuyer){
            context.beginPath();
            context.fillStyle=CHART_COLORS.BUYER_LLT_MARKER_COLOR;
            context.arc( px2+width-5,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
            context.fill();
            context.fillStyle=CHART_COLORS.BUY_VOL_BK;
            context.fillRect(px2, (py-TPO_HEIGHT/2-1),width-10,TPO_HEIGHT/2+1);
          }else{
            context.fillStyle=CHART_COLORS.BUY_VOL_BK;
            context.fillRect(px2, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
          }
          
         }
      }
      else
      context.fillText(data.buy_vol, px+60+14*divisionFactorNew, py);
      }

      // context.restore();
    }
    
    //per profile candles, signal backgrounds, VWAP, High/Low Range , signal texts
    const drawCandle=(xCord,data,tooltipData,scaleY,transform,fontSize,fontwt,yTransform,image)=>{
    //  console.log("drawcandle=",data.index,data.time);
      context.save();
      contextTooltip.save();
      
      let color=data.open>=data.close?CHART_COLORS.CANDLE_RED:CHART_COLORS.CANDLE_GREEN;
      let margin=data.open>=data.close?1.2:-1.2;
      let offsetOpen=data.open>=data.close?(-1*TPO_HEIGHT)+5:0;
      let offsetClose=data.open>=data.close?0:(-1*TPO_HEIGHT)+5;
      // let offsetHigh=data.high==data.highVal?0:(-1*TPO_HEIGHT)+5;
      let offsetHigh=data.high==data.highVal?(-1*TPO_HEIGHT)+8:(-1*TPO_HEIGHT)+8;
      let divisionFactor=transform.k>1?1:1;
      let mulFactor=transform.k>1?1:1;
      // let divisionFactor=transform.k>1?transform.k:1;
      // let mulFactor=transform.k>1?0.8:1;
      // let divisionFactor=transform.k>1?1:1;
      let divisionFactorNew=transform.k>1?transform.k:1;
      let px=(xCord+(24/divisionFactorNew));
      
      context.beginPath();
      context.moveTo(px, yScale(data.low));
      context.lineTo(px+1/divisionFactorNew, yScale(data.low));
      context.lineTo(px+1/divisionFactorNew, yScale(data.high)+offsetHigh);
      context.lineTo(px, yScale(data.high)+offsetHigh);
      // context.lineWidth = 1/divisionFactor;
      context.fillStyle=color
      context.fill();
      // contextDummy.closePath()

       px=xCord+(20/divisionFactorNew);
       if(data.openTPO!=data.closeTPO){
      context.beginPath();
      context.moveTo(px, yScale(data.openTPO)+offsetOpen);
      context.lineTo(px+8/divisionFactorNew, yScale(data.openTPO)+offsetOpen);
      context.lineTo(px+8/divisionFactorNew, yScale(data.closeTPO)+offsetClose);
      context.lineTo(px, yScale(data.closeTPO)+offsetClose);
     
      context.fillStyle=color
      context.fill();
       }else{
        context.beginPath();
        context.moveTo(px, yScale(data.openTPO));
        context.lineTo(px+8/divisionFactorNew, yScale(data.openTPO));
        context.lineTo(px+8/divisionFactorNew, yScale(data.closeTPO)-4);
        context.lineTo(px, yScale(data.closeTPO)-4);
       
        context.fillStyle=color
        context.fill();
       }

    
      let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
      let randomColor=rgbToHex(randomR,randomG,randomB);
      contextTooltip.fillStyle=randomColor;
      contextTooltip.beginPath();
      contextTooltip.moveTo(px, yScale(data.openTPO)-margin*TPO_HEIGHT);
      contextTooltip.lineTo(px+8/divisionFactorNew, yScale(data.openTPO)-margin*TPO_HEIGHT);
      contextTooltip.lineTo(px+8/divisionFactorNew, yScale(data.closeTPO)+margin*TPO_HEIGHT);
      contextTooltip.lineTo(px, yScale(data.closeTPO)+margin*TPO_HEIGHT);
      contextTooltip.fill();
      let tempData=[];
      tempData.push({key:"Open",value:data.open.toFixed(2)})
      tempData.push({key:"High",value:data.highVal.toFixed(2)})
      tempData.push({key:"Low",value:data.lowVal.toFixed(2)})
      tempData.push({key:"Close",value:data.close.toFixed(2)})
      if(data.signal)
      tempData.push({key:"Signal",value:data.signal})

      tooltipData.push({
        [randomColor]:tempData
      })
      // console.log("tooltipdata= 1",tooltipData);

      
      xCord=xCord+20;
      if(data.signal && (data.signal=="IS"|| data.signal=="IB")){
        px = (xCord+ 12*divisionFactorNew*mulFactor);
      context.beginPath();
      context.moveTo(px, yScale(data.low-1*selectedTPO));
      context.lineTo((px+120), yScale(data.low-1*selectedTPO));
      context.lineTo((px+120), yScale(data.high+1*selectedTPO));
      context.lineTo(px, yScale(data.high+1*selectedTPO));
      context.fillStyle=data.signal=="IS"?CHART_COLORS.SIGNAL_IS_BK:CHART_COLORS.SIGNAL_IB_BK;
      context.fill();

   
      let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
      let randomColor=rgbToHex(randomR,randomG,randomB);
      contextTooltip.beginPath();
      contextTooltip.moveTo(px, yScale(data.low-1*selectedTPO));
      contextTooltip.lineTo((px+120), yScale(data.low-1*selectedTPO));
      contextTooltip.lineTo((px+120), yScale(data.high+1*selectedTPO));
      contextTooltip.lineTo(px, yScale(data.high+1*selectedTPO));
      contextTooltip.fillStyle=randomColor;
      contextTooltip.fill();
      let tempData=[];
      tempData.push({key:"Signal",value:data.signal})
      tooltipData.push({
        [randomColor]:tempData
      })


      }

      //VPOC
      if(data.vpoc ){
        px = (xCord+12*divisionFactorNew*mulFactor);

        let py = (yScale(data.vpocTPO));
        context.lineWidth=2;
        context.strokeStyle=CHART_COLORS.VPOC_BOX_COLOR;
        context.strokeRect(px, (py-TPO_HEIGHT/2-4),120,TPO_HEIGHT/2+7);
        // context.stroke();

      let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
      let randomColor=rgbToHex(randomR,randomG,randomB);
      contextTooltip.fillStyle=randomColor;
      contextTooltip.fillRect(px, (py-TPO_HEIGHT/2-4),120,TPO_HEIGHT/2+7);
      let tempData=[];
      tempData.push({key:"VPOC",value:data.vpoc})
      tempData.push({key:"VPOC Vol",value:data.vpoc_vol})
      tooltipData.push({
        [randomColor]:tempData
      })

     
     
      }

      //VWAP for 30mins tf
      if(props.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_30_min && data.vwap ){
        context.strokeStyle=CHART_COLORS.VWAP;
        px = (xCord+12*divisionFactorNew*mulFactor);
        

      

        let py = (yScale(data.vpocTPO));
        context.lineWidth=4;
        context.beginPath();
        context.moveTo(px, yScale(data.vwap));
        context.lineTo(px+120, yScale(data.vwap));

        // context.strokeStyle=CHART_COLORS.VWAP;
        // context.strokeRect(px, (py-TPO_HEIGHT/2-2),100,TPO_HEIGHT/2+8);
        context.stroke();

        let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
        let randomColor=rgbToHex(randomR,randomG,randomB);
      contextTooltip.strokeStyle=randomColor;
      contextTooltip.lineWidth=6;
      contextTooltip.beginPath();
      contextTooltip.moveTo(px, yScale(data.vwap)-1);
      contextTooltip.lineTo(px+120, yScale(data.vwap)+1);
      contextTooltip.stroke();
      let tempData=[];
      tempData.push({key:"VWAP",value:data.vwap})
      tooltipData.push({
        [randomColor]:tempData
      })

     
     
      }

      if(data.high_range ){
        context.strokeStyle=CHART_COLORS.OF_HIGH_RANGE_MARKER;
        px = (xCord+12*divisionFactorNew*mulFactor);
        
        context.lineWidth=2/zY.k;
        context.setLineDash([3/zX.k,3/zX.k]);
        context.beginPath();
        context.moveTo(px, yScale(data.high_range));
        context.lineTo(px+120, yScale(data.high_range));
        context.stroke();
       

        let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
       let randomColor=rgbToHex(randomR,randomG,randomB);
      console.log("randomColor=",randomColor)
        contextTooltip.strokeStyle=randomColor;
        contextTooltip.lineWidth=10;
        contextTooltip.beginPath();
        contextTooltip.moveTo(px, yScale(data.high_range)+1);
        contextTooltip.lineTo(px+120, yScale(data.high_range)+1);
        contextTooltip.stroke();
        let tempData=[];
        tempData.push({key:"2SD_UP",value:data.high_range})
        tooltipData.push({
          [randomColor]:tempData
        })

      }

      if(data.low_range ){
        context.strokeStyle=CHART_COLORS.OF_LOW_RANGE_MARKER;
        px = (xCord+12*divisionFactorNew*mulFactor);
        
        context.lineWidth=2/zY.k;
        context.setLineDash([3/zX.k,3/zX.k]);
        context.beginPath();
        context.moveTo(px, yScale(data.low_range));
        context.lineTo(px+120, yScale(data.low_range));
        context.stroke();

        let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
       let randomColor=rgbToHex(randomR,randomG,randomB);
     
        contextTooltip.strokeStyle=randomColor;
        contextTooltip.lineWidth=10;
        contextTooltip.beginPath();
        contextTooltip.moveTo(px, yScale(data.low_range)+1);
        contextTooltip.lineTo(px+120, yScale(data.low_range)+1);
        contextTooltip.stroke();
        let tempData=[];
        tempData.push({key:"2SD_DN",value:data.low_range})
        tooltipData.push({
          [randomColor]:tempData
        })

      }

      

      // contextDummy.closePath()
      if(data.signal){
        
        if(transform.k>XSCALE_ZOOM_OUT_DISPLAY &&yTransform.k>YSCALE_ZOOM_OUT_DISPLAY){
        context.font=`${fontwt} ${fontSize}px sans-serif`;
        context.textAlign='left';
        px = (xCord+12*divisionFactorNew+30);
        let py =(data.signal=="IS"|| data.signal=="RS")? yScale(data.maxTPO+2*selectedTPO):yScale(data.maxTPO+2.5*selectedTPO);
        context.fillStyle = CHART_COLORS.OF_HIGH_LOW_TEXT
        context.fillText(data.highVal.toFixed(2), px, py);
        py=(data.signal=="IS"|| data.signal=="RS")?yScale(data.minTPO-2*selectedTPO):yScale(data.minTPO-2.5*selectedTPO);
        context.fillText(data.lowVal.toFixed(2), px, py)

        context.textAlign='center';
        context.font=`${fontwt} ${fontSize+2}px sans-serif`;
        //  px = (xCord+74*transform.k);
        px = (xCord+12*divisionFactorNew+50);
        py = (data.signal=="IS"|| data.signal=="RS")?yScale(data.maxTPO+4*selectedTPO):yScale(data.minTPO-4.5*selectedTPO);
        
        context.fillStyle = CHART_COLORS.OF_VOL_NUM_COLOR
        context.fillText(data.signal, px, py);

        px = (xCord+12*divisionFactorNew+70);
        context.font=`${fontwt} ${fontSize+28*divisionFactorNew}px sans-serif`;
        let offset=14
        if(zX.k>1 || zY.k>1){
          let factor=Math.max(1,zY.k)
          offset=factor==1?10:10;
          
        context.font=`${fontwt} ${fontSize+22}px sans-serif`;
        }
        context.fillStyle = (data.signal=="IS"|| data.signal=="RS")?CHART_COLORS.IS_RS_MARKER_COLOR:CHART_COLORS.IB_RB_MARKER_COLOR
        let xOffset=data.signal=="RS"||data.signal=="RB"?4:0;
        context.fillText("*", px+xOffset, py+offset+4);
        
        px = (xCord+12*divisionFactorNew+45);
        let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
        let randomColor=rgbToHex(randomR,randomG,randomB);
        contextTooltip.fillStyle=randomColor;
        contextTooltip.fillRect(px, (py-TPO_HEIGHT),50,1.5*TPO_HEIGHT);
        let tempData=[];
        tempData.push({key:"Signal",value:data.signal})
       
        tooltipData.push({
          [randomColor]:tempData
        })

        }else{
          let min=transform.k>yTransform.k?transform.k:yTransform;
        //   context.font=`${900} ${36}px sans-serif`;
        //   px = (xCord+12*divisionFactorNew+55);
        //   const py = yScale(data.minTPO-3.5*selectedTPO);
        //   context.fillStyle = CHART_COLORS.OF_VOL_NUM_COLOR
        // context.fillText("*", px, py);

        px = (xCord+12*divisionFactorNew+55);
        let py = (data.signal=="IS"|| data.signal=="RS")?yScale(data.maxTPO+2*selectedTPO):yScale(data.minTPO-3.5*selectedTPO);
        context.font=`${fontwt} ${fontSize+40*divisionFactorNew}px sans-serif`;
        context.fillStyle = (data.signal=="IS"|| data.signal=="RS")?CHART_COLORS.IS_RS_MARKER_COLOR:CHART_COLORS.IB_RB_MARKER_COLOR
        context.fillText("*", px, py+14);

        px = (xCord+12*divisionFactorNew+40);
        let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
        let randomColor=rgbToHex(randomR,randomG,randomB);
        contextTooltip.fillStyle=randomColor;
        contextTooltip.fillRect(px, (py-1.5*TPO_HEIGHT),60,2*TPO_HEIGHT);
        let tempData=[];
        tempData.push({key:"Signal",value:data.signal})
       
        tooltipData.push({
          [randomColor]:tempData
        })


          // image.src="https://loremflickr.com/cache/resized/65535_52461676408_4442828f69_320_240_nofilter.jpg"
          // image.onload = () => {
          //   context.drawImage(image, 100, py);
          // };
         
        }
    }else{
      if(transform.k>XSCALE_ZOOM_OUT_DISPLAY &&yTransform.k>YSCALE_ZOOM_OUT_DISPLAY){
       context.font=`${fontwt} ${fontSize}px sans-serif`;
        context.textAlign='left';
        px = (xCord+12*divisionFactorNew+30);
        let py = yScale(data.maxTPO+2*selectedTPO);
        context.fillStyle = CHART_COLORS.OF_HIGH_LOW_TEXT
        context.fillText(data.highVal.toFixed(2), px, py);
        py=yScale(data.minTPO-2*selectedTPO)
        context.fillText(data.lowVal.toFixed(2), px, py)
      }
    }
      
      //profile start
      if(data.index==0){
        yScale(scaleY.domain()[0])
        context.beginPath()
        // context.setLineDash([2, 2]);
        context.lineWidth=2;
        context.moveTo(xCord-20, yScale(scaleY.domain()[0]))
        context.lineTo(xCord-20,  yScale(scaleY.domain()[1]))
        context.strokeStyle =CHART_COLORS.OF_START_PROFILE_LINE;
        context.stroke()
      }
      
    
      context.restore();
      contextTooltip.restore();

    }


    
    const drawTableBase=(xCord,transform,scaleY,yTransform,cord,width)=>{
      context.save();
      let divisionFactor=yTransform.k>1?yTransform.k:1;
      let heightFactor=yTransform.k<1?yTransform.k:1;
      context.strokeStyle=CHART_COLORS.BOTTOM_TABLE_BK;
      let px=cord;
      context.beginPath();
      // context.lineWidth=0;
      // context.moveTo(px, canvasHeight*transform.k-transform.y);
      // context.lineTo(px+9999, canvasHeight*transform.k-transform.y);
      // context.lineTo(px+9999, (canvasHeight-76)*transform.k-transform.y);
      // context.lineTo(px, (canvasHeight-76)*transform.k-transform.y);
     
      let tableHeight=getBottomTableHeight()+4;
      console.log("Table height====>",tableHeight);
      let height=tableHeight/divisionFactor;
      if(props.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_1_min){
        height=tableHeight/divisionFactor;
      }
      if(yTransform.k<1)
      height=height/yTransform.k;

      let offset=yTransform.k>1.3?2:yTransform.k<1?-2:0;

      // context.moveTo(px,  yScale(scaleY.domain()[0]));
      // context.lineTo(px+9999,  yScale(scaleY.domain()[0]));
      // context.lineTo(px+9999,  yScale(scaleY.domain()[0])-height);
      // context.lineTo(px,  yScale(scaleY.domain()[0])-height);

      // context.lineWidth = height;
      context.fillStyle=CHART_COLORS.BOTTOM_TABLE_BK;
      context.fillRect(px, yScale(scaleY.domain()[0])-height,width, height);
      context.fillStyle=CHART_COLORS.OF_VOL_NUM_COLOR
      //table borders
      context.fillRect(px, yScale(scaleY.domain()[0])-height,width, 0.5/yTransform.k);
      context.fillRect(px, yScale(scaleY.domain()[0])-1,width, 0.5/yTransform.k);
      //inside table borders
      // context.fillRect(px, yScale(scaleY.domain()[0])-17/yTransform.k-offset,width, 0.5/yTransform.k);
      // context.fillRect(px, yScale(scaleY.domain()[0])-36/yTransform.k-offset,width, 0.5/yTransform.k);
      // context.fillRect(px, yScale(scaleY.domain()[0])-53/yTransform.k-offset,width, 0.5/yTransform.k);
      // if(props.selectedTimeFrame!=OF_TIME_FRAME_VALUES.tf_1_min){
      //   context.fillRect(px, yScale(scaleY.domain()[0])-70/yTransform.k-offset,width, 0.5/yTransform.k);
      //   context.fillRect(px, yScale(scaleY.domain()[0])-87/yTransform.k-offset,width, 0.5/yTransform.k);
      // }

      // context.fill();
      context.closePath();
      context.restore();
    }
    const drawTable=(xCord,data,transform,scaleY,yTransform,defaultFontSize,defaultFontWeight,letterSpacing,index,width)=>{
      // console.log("drawTable=",yScale.range(),canvasHeight,yScale.domain(),(scaleY.domain()[0]),yScale(scaleY.domain()[0]));
      let divisionFactor=transform.k>1?transform.k:1;
      let fontFactor=yTransform.k>=1?1:yTransform.k*(2-yTransform.k);
      if(transform.x<1 && yTransform.k>1){
        fontFactor=0.9;
        letterSpacing=letterSpacing+1;

      }
      let mulFactor=transform.k>1?0.85:1;
      let divisionFactorNew=yTransform.k>1?yTransform.k:yTransform.k;
      let defaultFont=`${defaultFontWeight} ${defaultFontSize/fontFactor}px 'Roboto Flex'`;
      let px=xCord+20+10*divisionFactor+3;
      context.font=defaultFont;
      context.textAlign="left"
      context.letterSpacing=`${letterSpacing}px`;
      
      
      context.fillStyle = CHART_COLORS.OF_VOL_NUM_COLOR;
      
      let offset=2;
      let isOIPresent=false;
      let isdOIPresent=false;
      let pos=2;
      if(props.selectedTimeFrame!=OF_TIME_FRAME_VALUES.tf_1_min){
        if(!isBottomColHidden("dOI")){
        if(data.doi != "NA"){
          
          if(data.doi>=0)
            context.fillStyle=CHART_COLORS.TEXT_GREEN;
            else
            context.fillStyle=CHART_COLORS.TEXT_RED; 
          context.fillText(data.doi, px, yScale(scaleY.domain()[0])-(offset)/divisionFactorNew-2);
         
        }
        else
          if(data.index!=0){

            if(data.oi-historicalData.config.data[index-1].oi>=0)
            context.fillStyle=CHART_COLORS.TEXT_GREEN;
            else
            context.fillStyle=CHART_COLORS.TEXT_RED; 
            
             context.fillText(data.oi-historicalData.config.data[index-1].oi, px, yScale(scaleY.domain()[0])-(offset)/divisionFactorNew-2);
        
          }
          else
          context.fillText("NA", px, yScale(scaleY.domain()[0])-(offset)/divisionFactorNew-2);
          isOIPresent=true;
          pos=18;
        }

        if(!isBottomColHidden("OI")){
          context.fillStyle = CHART_COLORS.OF_VOL_NUM_COLOR;  
          context.fillText(data.oi, px, yScale(scaleY.domain()[0])-pos/divisionFactorNew-3);
          isdOIPresent=true;
          pos=pos+18;
        }
     
      
      }
      else
      if(isOIPresent && isdOIPresent){
        // offset=-32/divisionFactorNew;
      }else if(isOIPresent ||isdOIPresent){
        // offset=-18/divisionFactorNew;
      }else{

      }
        
      // offset=-32/divisionFactorNew;

      if(!isBottomColHidden("COT")){
      if(data.cot>=0)
        context.fillStyle=CHART_COLORS.TEXT_GREEN;
      else
        context.fillStyle=CHART_COLORS.TEXT_RED;
      
      context.fillText(data.cot, px, (yScale(scaleY.domain()[0])-offset-pos/divisionFactorNew));
      pos=pos+17;
      }
      
      if(!isBottomColHidden("VPOC Vol")){
        context.fillStyle = CHART_COLORS.OF_VOL_NUM_COLOR
        context.fillText(data.vpoc_vol, px,  (yScale(scaleY.domain()[0])-offset-pos/divisionFactorNew));
        pos=pos+17;
      }
   
      if(!isBottomColHidden("VPOC")){
        context.fillStyle = CHART_COLORS.OF_VPOC_BOX_TEXT;
        context.fillText(data.vpoc, px,  (yScale(scaleY.domain()[0])-offset-pos/divisionFactorNew));
        pos=pos+17;
      }

      if(!isBottomColHidden("Volume")){
        context.fillStyle = CHART_COLORS.OF_VOL_NUM_COLOR
        context.fillText(data.vol, px,  (yScale(scaleY.domain()[0])-offset-pos/divisionFactorNew));
        pos=pos+17;
      }
    }

    const drawDailyMarker=(xScaleRange, xTransform,yTransform,tooltipData)=>{
     

      for(let i=0;i<historicalData.profilesData.length;i++){
        if(historicalData.profilesData[i].mdata != undefined){
          let offset=(i==historicalData.profilesData.length-1)?dummyProfileWidth:0;
          // if(offset!=0)
          let startIndex=historicalData.profilesData[i].startIndex;
          let endIndex=historicalData.profilesData[i].startIndex+historicalData.profilesData[i].count;
          console.log("drawDailyMarker",i,historicalData.profilesData[i].mdata,historicalData.profilesData[i].count,startIndex,endIndex);
         
          
          let ibHigh=parseFloat(((historicalData.profilesData[i].mdata.ib_high*10000)/10000).toFixed(4));
          let ibHighMod=parseFloat(((historicalData.profilesData[i].mdata.ib_high*10000)%(selectedTPO*10000))/10000).toFixed(4);
          let ibHighTPO=parseFloat(((ibHigh-ibHighMod)).toFixed(4));

          let ibLow=parseFloat(((historicalData.profilesData[i].mdata.ib_low*10000)/10000).toFixed(4));
          let ibLowMod=parseFloat(((historicalData.profilesData[i].mdata.ib_low*10000)%(selectedTPO*10000))/10000).toFixed(4);
          let ibLowTPO=parseFloat(((ibLow-ibLowMod)).toFixed(4));

          let dayHigh=parseFloat(((historicalData.profilesData[i].mdata.high*10000)/10000).toFixed(4));
          let dayHighMod=parseFloat(((historicalData.profilesData[i].mdata.high*10000)%(selectedTPO*10000))/10000).toFixed(4);
          let dayHighTPO=parseFloat(((dayHigh-dayHighMod)).toFixed(4));

          let dayLow=parseFloat(((historicalData.profilesData[i].mdata.low*10000)/10000).toFixed(4));
          let dayLowMod=parseFloat(((historicalData.profilesData[i].mdata.low*10000)%(selectedTPO*10000))/10000).toFixed(4);
          let dayLowTPO=parseFloat(((dayLow-dayLowMod)).toFixed(4));
          

          let krlCategory=OF_MARKER_STYLING_CATEGORY["ib_high_low"]
          let lineWidth=krlCategory.width;
          context.save();
          if(krlCategory.type==KRL_CATEOGRY_TYPE.DASHED_LINE)
          context.setLineDash([9/zX.k, 5/zX.k]);

          context.lineWidth =lineWidth/zY.k;
          context.strokeStyle=CHART_COLORS.OF_IB_LINE;
          context.beginPath();
          context.moveTo(xScaleRange[startIndex], yScale(ibHighTPO));
          context.lineTo(xScaleRange[endIndex]+offset,  yScale(ibHighTPO));
          context.stroke();

          
        

          let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          let randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.strokeStyle=randomColor;
          contextTooltip.lineWidth = 2*lineWidth.IB/zY.k;
          contextTooltip.beginPath();
          contextTooltip.moveTo(xScaleRange[startIndex], yScale(ibHighTPO));
          contextTooltip.lineTo(xScaleRange[endIndex]+offset,  yScale(ibHighTPO));
          contextTooltip.stroke();
          tooltipData.push({x1:xScaleRange[startIndex],x2:xScaleRange[endIndex]+offset,tpo:ibHighTPO,key:"IB High",value:historicalData.profilesData[i].mdata.ib_high.toFixed(2)})
        

          context.lineWidth =lineWidth.IB/zY.k;
          context.strokeStyle=CHART_COLORS.OF_IB_LINE;
          context.beginPath();
          context.moveTo(xScaleRange[startIndex], yScale(ibLowTPO));
          context.lineTo(xScaleRange[endIndex]+offset,  yScale(ibLowTPO));
          context.stroke();

          val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.strokeStyle=randomColor;
          contextTooltip.lineWidth = 2*lineWidth.IB/zY.k;
          contextTooltip.beginPath();
          contextTooltip.moveTo(xScaleRange[startIndex], yScale(ibLowTPO));
          contextTooltip.lineTo(xScaleRange[endIndex]+offset,  yScale(ibLowTPO));
          contextTooltip.stroke();
          tooltipData.push({x1:xScaleRange[startIndex],x2:xScaleRange[endIndex]+offset,tpo:ibLowTPO,key:"IB Low",value:historicalData.profilesData[i].mdata.ib_low.toFixed(2)})
        
          context.restore();

          krlCategory=OF_MARKER_STYLING_CATEGORY["day_high_low"]
          lineWidth=krlCategory.width;
          context.save();
          if(krlCategory.type==KRL_CATEOGRY_TYPE.DASHED_LINE)
          context.setLineDash([5/zX.k, 3/zX.k]);


          context.lineWidth =lineWidth/zY.k;
          context.strokeStyle=CHART_COLORS.OF_DAY_HIGH_LOW;
          context.beginPath();
          context.moveTo(xScaleRange[startIndex], yScale(dayHighTPO));
          context.lineTo(xScaleRange[endIndex]+offset,  yScale(dayHighTPO));
          context.stroke();

          val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.strokeStyle=randomColor;
          contextTooltip.lineWidth = 2*lineWidth/zY.k;
          contextTooltip.beginPath();
          contextTooltip.moveTo(xScaleRange[startIndex], yScale(dayHighTPO));
          contextTooltip.lineTo(xScaleRange[endIndex]+offset,  yScale(dayHighTPO));
          contextTooltip.stroke();
          tooltipData.push({x1:xScaleRange[startIndex],x2:xScaleRange[endIndex]+offset,tpo:dayHighTPO,key:"Day High",value:historicalData.profilesData[i].mdata.high.toFixed(2)})

          context.lineWidth =lineWidth/zY.k;
          context.strokeStyle=CHART_COLORS.OF_DAY_HIGH_LOW;
          context.beginPath();
          context.moveTo(xScaleRange[startIndex], yScale(dayLowTPO));
          context.lineTo(xScaleRange[endIndex]+offset,  yScale(dayLowTPO));
          context.stroke();

          val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.strokeStyle=randomColor;
          contextTooltip.lineWidth = 2*lineWidth/zY.k;
          contextTooltip.beginPath();
          contextTooltip.moveTo(xScaleRange[startIndex], yScale(dayLowTPO));
          contextTooltip.lineTo(xScaleRange[endIndex],  yScale(dayLowTPO));
          contextTooltip.stroke();
          tooltipData.push({x1:xScaleRange[startIndex],x2:xScaleRange[endIndex]+offset,tpo:dayLowTPO,key:"Day Low",value:historicalData.profilesData[i].mdata.low.toFixed(2)})
          context.restore();
        
       
        
        }
      }
    }


   
    //plot volume on canvas 
    function drawVolumes(j,xCord,yCord,data,max,width,bShowProfileVolumeNumbers,transform) {
      const px = (xCord);
      const py = (yScale(yCord));
      // console.log("drawVol = ",j,bShowProfileVolumeNumbers); 
     
      let val=Math.round((data.perc/max)*width);
      let opacity=1.0;
      context.save()
      context.globalAlpha = opacity; 
      // context.fillStyle = "#2F5CDB";
      if(val/width>0.96)
      context.fillStyle = CHART_COLORS.VOLUME_BAR_ABOVE_96;
      else if(val/width>0.7)
      context.fillStyle = CHART_COLORS.VOLUME_BAR_ABOVE_70;
      else if(val/width>0.4)
      context.fillStyle = CHART_COLORS.VOLUME_BAR_ABOVE_40;
      else if(val/width>0.28)
      context.fillStyle = CHART_COLORS.VOLUME_BAR_ABOVE_28;
      else 
      context.fillStyle = CHART_COLORS.VOLUME_BAR_BELOW_28;

      context.fillRect(px, py-TPO_HEIGHT/2-2,val,14);
      // context.stroke();
      // context.globalAlpha = 1.0; 
      context.restore();
      // context.globalAlpha = 0.9; 
      // if((!props.volumeNumberVisible && bShowProfileVolumeNumbers==true && transform.k>=0.6) ){
      //   context.fillStyle = "#ffffff";
      //   context.font = "10px DM Sans"
      //   context.fillText("["+data.val.toLocaleString()+"]", px, py);
      // }else if(props.volumeNumberVisible && bShowProfileVolumeNumbers==undefined && transform.k>=0.6){
      //   context.fillStyle = "#ffffff";
      //   context.font = "10px DM Sans"
      //   context.fillText("["+data.val.toLocaleString()+"]", px, py);
     
      // }
      if(bShowProfileVolumeNumbers && transform.k>=0.6){
            context.fillStyle = "#ffffff";
        context.font = "10px DM Sans"
        context.fillText("["+data.val.toLocaleString()+"]", px, py);
      }
      // context.fillText("A", px, py);
      
    }
    
    const getYTransform=(transform,prevTransform)=>{
      let scaleX=undefined;
      if(transform.k>=0.4)
        scaleX =xScale.copy()
        .range((xScale.range().map((d,i) => (d*transform.k+transform.x))))
      else
       scaleX =xScale.copy()
        .range((xScale.range().map((d,i) => (d*transform.k+transform.x))))
        .domain((xScale.domain().map((date,i) => (date.length<13?date.slice(0,5):date.slice(0,date.length-5)))))

        let leftIndex=binarySearch(scaleX.copy().range(),0-props.chartStateData.left);
        let rightIndex=binarySearch(scaleX.copy().range(),canvasWidth-5);
        let prevY=transform.y;
        let trans = d3.zoomIdentity  
        .translate(transform.x, 0)
        .scale(transform.k)
        // JSON.parse(JSON.stringify(transform));
        // trans.y=0;
        let scaleY = trans.rescaleY(yScale);
        let yTransform=transform.y
        console.log(transformDataRef.current,prevTransform,transform,Math.round((leftIndex+rightIndex)/2))
        if(transform.x!=0){
        if(Math.abs(prevTransform.x-transform.x)>AUTO_CENTER_CONFIG.DELTA_X && Math.abs(prevTransform.y-transform.y)<AUTO_CENTER_CONFIG.DELTA_Y){
           yTransform=-1*((scaleY(historicalData.profilesData[Math.round((leftIndex+rightIndex)/2)].maxTPO)+scaleY(historicalData.profilesData[Math.round((leftIndex+rightIndex)/2)].minTPO))/2);
          // yTransform=-1*((scaleY(historicalData.profilesData[Math.round(rightIndex)].maxTPO)+scaleY(historicalData.profilesData[Math.round(leftIndex)].minTPO))/2);
          // yTransform=-1*(scaleY(historicalData.profilesData[Math.round((leftIndex+rightIndex)/2)-1].minTPO));
          
          console.log("returnning auto center yTransform=========>",yTransform);
          return yTransform;
        }else{
          return transform.y;
        }
      }else
      return transform.y; 
    }
   
    let t;
    let tY;
   if(zoomType==OF_ZOOM_TYPE.ASYMMETRIC){ 
     t = d3.zoomIdentity.translate(initialTransform.x, 0).scale(initialTransform.k);
     tY = d3.zoomIdentity.translate(initialTransformY.x, initialTransformY.y).scale(initialTransformY.k);
    // console.log("Recenter zoom event initialtransfomr x and y=",initialTransform,initialTransformY,t,tY,transformData,transformDataY)
   }else{
     t = d3.zoomIdentity.translate(initialTransform.x, initialTransform.y).scale(initialTransform.k);
   }
    
    // console.log("translateextent and zoom out scale=>",t,historicalData.config.totalWidth,(canvasWidth-historicalData.config.totalWidth),canvasWidth,canvasWidth/historicalData.config.totalWidth)
    // console.log("yScale range=",(yScale.range()[0]/TPO_HEIGHT)*selectedTPO,yScale.domain(),historicalData.config.max,historicalData.config.min,canvasHeight);
  

    let translateExtentUpward=canvasHeight;
    if((yScale.domain()[0]!=historicalData.config.min))
    translateExtentUpward=2*(Math.abs(yScale.domain()[0]-historicalData.config.min)/(yScale.domain()[1]-yScale.domain()[0]))*canvasHeight+(1.5*canvasHeight);
    
    let translateExtentDownward=-1*canvasHeight;
    if((yScale.domain()[1]!=historicalData.config.max))
    translateExtentDownward=-2*((Math.abs(yScale.domain()[1]-historicalData.config.max)/(yScale.domain()[1]-yScale.domain()[0]))*canvasHeight+(1.5*canvasHeight));
  
    // let translateExtentUpward=4*canvasHeight;
    // let translateExtentDownward=-4*canvasHeight;
    
    let translateExtentRight=canvasWidth-historicalData.config.totalWidth;
    if(canvasWidth>historicalData.config.totalWidth)
    translateExtentRight=0;
    console.log("translateExtentUpward,translateExtentDownward =", translateExtentUpward,translateExtentDownward);
    
    // let ZOOM_OUT_SCALE=Math.max(ZOOM_OUT_EXTREME, canvasWidth/historicalData.config.totalWidth);
    // if(canvasWidth>=historicalData.config.totalWidth)
    // ZOOM_OUT_SCALE=1;
  
    

    // const zoom_function = d3.zoom()
    // .scaleExtent([ZOOM_OUT_SCALE, 2])
    // .translateExtent([[translateExtentRight, translateExtentDownward], [canvasWidth,translateExtentUpward]])
    // .on('start', (event) => {
    //   console.log("zoom start=====>",event.transform)
    //   setTransformDataAutoCenter(event.transform);
    // })
    // .on('zoom', (event) => {
    //         // if((Math.round(event.transform.x))%2==0){
    //         if(chartInterationAllowedRef.current){
    //         // setToggleRepaint(false);
    //         console.log("zoom event 2 =",event.transform,zX,zY);
            
    //         let transform = event.transform;
    //         let divisionFactor=transform.k>1?1:1;
    //         // transform.y=getYTransform(transform);
    //         transform.k=parseFloat(transform.k.toFixed(4));
    //         transform.x=parseFloat((transform.x/divisionFactor).toFixed(2));
    //         // transform.y=parseFloat(transform.y.toFixed(2));
    //         // transform.y=0;
    //         let scaleY=transform.k;
    //         let yTransform=transform.y;
    //         if(transform.k!=transformDataRef.current.k)
    //         yTransform=0;
    //         // if(transform.k>1)
    //         // scaleY=1;
    //         // let xt=transform.x;
    //         // if(transform.x!=0 && !redraw){
    //         // transform.x=transform.x*transform.k;
    //         // // redraw=false;
    //         // }
            
    //         context.save();
    //         context.clearRect(0, 0, canvasWidth, canvasHeight);
    //         context.translate(transform.x, yTransform);
    //         context.scale(transform.k, scaleY);
    //         contextDummy.save();
    //         contextDummy.clearRect(0, 0, canvasWidth, canvasHeight);
    //         contextDummy.translate(transform.x, yTransform);
    //         contextDummy.scale(transform.k, scaleY);
    //         contextOverlay.save();
    //         contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
    //         contextOverlay.translate(transform.x, yTransform);
    //         contextOverlay.scale(transform.k, scaleY);
    //         contextTooltip.save();
    //         contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
    //         contextTooltip.translate(transform.x, yTransform);
    //         contextTooltip.scale(transform.k, scaleY);
        
    //         draw(transform,false);
    //         // draw(transform,false,xt);
    //         context.restore();
    //         contextDummy.restore();
    //         contextOverlay.restore();
    //         contextTooltip.restore();
    //       }
    //     })
       
    //     .on('end', (event) => {
    //       console.log("zoom END=====>",event.transform,transformDataAutoCenterRef.current,autoCenterRef.current);
            
    //       if(autoCenterRef.current){
    //         let transform=event.transform;
    //         if(transform.k !=transformDataAutoCenterRef.current.k || transform.x !=transformDataAutoCenterRef.current.x || transform.y !=transformDataAutoCenterRef.current.y){
    //           let newY=getYTransform(transform,transformDataAutoCenterRef.current);
    //           console.log("zoom END=====>",transform.y,newY)
    //           if(transform.y!=newY){
    //             transform.y=newY;
    //             autoCenter(transform)
    //           }    
    //           // setTransformDataAutoCenter(transform);
             
    //         }
    //       }
    //     })

    function constrain(transform, extent, translateExtent) {
      let dx0 = transform.invertX(extent[0][0]) - translateExtent[0][0],
          dx1 = transform.invertX(extent[1][0]) - (translateExtent[1][0]+canvasWidth/transformDataRef.current.k-historicalData.config.widthList[historicalData.config.widthList.length-1]),
          dy0 = transform.invertY(extent[0][1]) - translateExtent[0][1],
          dy1 = transform.invertY(extent[1][1]) - translateExtent[1][1];
          console.log("constrain=",ZOOM_OUT_SCALE,transformDataRef.current.k,dx0,dx1,translateExtent[0][0],translateExtent[1][0],translateExtentRight,canvasWidth/ZOOM_OUT_SCALE,translateExtent,extent)
      return transform.translate(
        dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1),
        dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1)
      );
    }

    const zoom_function = d3.zoom()
    .scaleExtent([ZOOM_OUT_EXTREME, XSCALE_MAX_ZOOM])
    .constrain(constrain)
    // .scaleExtent([ZOOM_OUT_EXTREME, 1.8])
    .translateExtent([[translateExtentRight, translateExtentDownward], [canvasWidth,translateExtentUpward]])
    .on('start', (event) => {
      setIsZooming(true);
      console.log("zoom start=====>",event.transform)
      // setTransformDataAutoCenter(event.transform);
    })
    .on('zoom', (event) => {
            // if((Math.round(event.transform.x))%2==0){
            if(chartInterationAllowedRef.current){
              if(zoomType==OF_ZOOM_TYPE.SYMMETRIC){
              console.log("zoom event 2 =",event.transform);
            let transform = event.transform;
            // transform.y=getYTransform(transform);
            // if(canvasWidth>historicalData.config.totalWidth && transform.k<1)
            //   // transform.x=-1*transform.x*transform.k;
            //   transform.x=1/transform.k*transform.x;
            // else
            transform.k=parseFloat(transform.k.toFixed(4));
            transform.x=parseFloat(transform.x.toFixed(2));
            transform.y=parseFloat(transform.y.toFixed(2));

            // transform.x=canvasWidth/2+(transform.x-canvasWidth/2)/transform.k*prevZI.k;
            // let xt=transform.x;
            // if(transform.x!=0 && !redraw){
            // transform.x=transform.x*transform.k;
            // // redraw=false;
            // }
            if(transformDataRef.current.k==transform.k &&(transformDataRef.current.x!=transform.x)){
              console.log("Recenter X set to false");
              setIsRecenterAllowed(false)
             }
            
            context.save();
            context.clearRect(0, 0, canvasWidth, canvasHeight);
            context.translate(transform.x, transform.y);
            context.scale(transform.k, transform.k);
            contextDummy.save();
            contextDummy.clearRect(0, 0, canvasWidth+YSCALE_WIDTH-2, canvasHeight);
            contextDummy.translate(transform.x, transform.y);
            contextDummy.scale(transform.k, transform.k);
            contextOverlay.save();
            contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
            contextOverlay.translate(transform.x, transform.y);
            contextOverlay.scale(transform.k, transform.k);
            contextTooltip.save();
            contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
            contextTooltip.translate(transform.x, transform.y);
            contextTooltip.scale(transform.k, transform.k);
        
            draw(transform,false);
            // draw(transform,false,xt);
            context.restore();
            contextDummy.restore();
            contextOverlay.restore();
            contextTooltip.restore();
            }
            else{
            // setToggleRepaint(false);
            console.log("zoom event xScale =",event.transform,zX,zY);
            
            let transform = event.transform;
            let divisionFactor=transform.k>1?1:1;
            transform.k=parseFloat(transform.k.toFixed(4));
            // transform.x=parseFloat((transform.x/divisionFactor).toFixed(2));
             transform.x=parseFloat(transform.x.toFixed(2));
             
             if(transformDataRef.current.k==transform.k &&transformDataRef.current.x!=transform.x){
              console.log("Recenter X set to false");
              setIsRecenterAllowed(false)
             }

            //  setTransformData()
             transform.y=zY.y;
            
           
            // let yTransform=transform.y;
            // if(transform.k!=zX.k){
            //   console.log("zoom event 2 inside =",transform.k,zX.k);
            // transform.y=zX.y;
            // transform.z=zX.x;
            // }else if(transform.k==zX.k && (transform.x!=zX.x) ){
            //   // transform.y=zX.y;
            // }else if(transform.k>=XSCALE_MAX_ZOOM){
            //   transform.y=zX.y;
            // }else if(transform.k<=ZOOM_OUT_SCALE){
            //   transform.y=zX.y;
            // }

           
           
           
            
            context.save();
            context.clearRect(0, 0, canvasWidth, canvasHeight);
            context.translate(transform.x, zY.y);
            context.scale(transform.k, zY.k);

            contextDummy.save();
            contextDummy.clearRect(0, 0, canvasWidth, canvasHeight);
            contextDummy.translate(transform.x, zY.y);
            contextDummy.scale(transform.k, zY.k);
            
            contextOverlay.save();
            contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
            contextOverlay.translate(transform.x, transform.y);
            contextOverlay.scale(transform.k, zY.k);

            contextTooltip.save();
            contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
            contextTooltip.translate(transform.x,transform.y);
            contextTooltip.scale(transform.k, zY.k);
        
            // draw(transform,false,true);
            
            draw(transform,false,false);
            // draw(transform,false,xt);
            context.restore();
            contextDummy.restore();
            contextOverlay.restore();
            contextTooltip.restore();
            // context.save();  
            // draw(transform,false);
            // context.restore();
            }
          }
        })
       
        .on('end', (event) => {
          console.log("zoom END=====>",event.transform,transformDataAutoCenterRef.current,autoCenterRef.current);
          setIsZooming(false);
          console.log("OF Pan Test: zoom handlelive END Outer===>",savedLiveData,savedLiveDataRef.current)
          if(savedLiveData!=undefined || savedLiveDataRef.current!=undefined){
            console.log("OF Pan Test: zoom handlelive END Inner=====> rendering last live profile now");
            handleLiveData(savedLiveDataRef.current)
            
            
          }
            
          // if(autoCenterRef.current){
          //   let transform=event.transform;
          //   if(transform.k !=transformDataAutoCenterRef.current.k || transform.x !=transformDataAutoCenterRef.current.x || transform.y !=transformDataAutoCenterRef.current.y){
          //     let newY=getYTransform(transform,transformDataAutoCenterRef.current);
          //     console.log("zoom END=====>",transform.y,newY)
          //     if(transform.y!=newY){
          //       transform.y=newY;
          //       autoCenter(transform)
          //     }    
          //     // setTransformDataAutoCenter(transform);
             
          //   }
          // }
        })
       
        dummyCanvas.call(zoom_function.transform, t) .on("dblclick.zoom", null);
        dummyCanvas.call(zoom_function) .on("dblclick.zoom", null);
        // canvasTooltip.call(zoom_function.transform, t) .on("dblclick.zoom", null);
        // canvasTooltip.call(zoom_function) .on("dblclick.zoom", null);
        
        // zoomed(t,false);
        
        // zoomedNew(zX,zY,false);

        // const zoom_function_scale = d3.zoom()
        // .on('zoom', (event) => {
        //   console.log("drag function=",event);
        //   // return;
        //   console.log("zoom_function_scale",event.transform,transformDataRef.current)
        //   let transform = event.transform;
        //     // transform.y=getYTransform(transform);
            
        //     transform.x=transformDataRef.current.x;
        //     transform.y=transformDataRef.current.y;
        //   context.save();
        //     context.clearRect(0, 0, canvasWidth, canvasHeight);
        //     context.translate(transform.x, transform.y);
        //     context.scale(transformDataRef.current.k, transform.k);
        //     contextDummy.save();
        //     contextDummy.clearRect(0, 0, canvasWidth, canvasHeight);
        //     contextDummy.translate(transform.x, transform.y);
        //     contextDummy.scale(transformDataRef.current.k, transform.k);
        //     contextOverlay.save();
        //     contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
        //     contextOverlay.translate(transform.x, transform.y);
        //     contextOverlay.scale(transformDataRef.current.k, transform.k);
        //     contextTooltip.save();
        //     contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
        //     contextTooltip.translate(transform.x, transform.y);
        //     contextTooltip.scale(transformDataRef.current.k, transform.k);
        
        //     draw(transform,true);
        //     // draw(transform,false,xt);
        //     context.restore();
        //     contextDummy.restore();
        //     contextOverlay.restore();
        //     contextTooltip.restore();
        // })
       
        // .on('end', (event) => {
        // })

        const zoom_function_scale = d3.zoom()
        .scaleExtent([ZOOM_OUT_SCALE, YSCALE_MAX_ZOOM])
    // .translateExtent([[0, translateExtentDownward], [0,translateExtentUpward]])
        .on('start', (event) => {
          setIsZooming(true);
        })
        .on('zoom', (event) => {
          // console.log("zoom event 2 =",event.transform,zX,zY);
          
          let transform = event.transform;
          transform.k=parseFloat(transform.k.toFixed(4));
          // transform.x=parseFloat((transform.x/divisionFactor).toFixed(2));
           transform.x=0;
           if(transformDataRefY.current.k==transform.k &&transformDataRefY.current.y!=transform.y){
            // console.log("Recenter Y set to false",transform);
            // setIsRecenterAllowed(false)
           }
           
        

           console.log("zoom event 2 yScale =",transform);
        
            context.save();
            context.clearRect(0, 0, canvasWidth, canvasHeight);
            context.translate(zX.x, transform.y);
            context.scale(zX.k, transform.k);
            contextDummy.save();
            contextDummy.clearRect(0, 0, canvasWidth, canvasHeight);
            contextDummy.translate(zX.x, transform.y);
            contextDummy.scale(zX.k, transform.k);
            contextOverlay.save();
            contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
            contextOverlay.translate(zX.x, transform.y);
            contextOverlay.scale(zX.k, transform.k);
            contextTooltip.save();
            contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
            contextTooltip.translate(zX.x, transform.y);
            contextTooltip.scale(zX.k, transform.k);
        
            draw(transform,true);
            // draw(transform,false,xt);
            context.restore();
            contextDummy.restore();
            contextOverlay.restore();
            contextTooltip.restore();
        })
       
        .on('end', (event) => {
          setIsZooming(false);
          if(savedLiveData!=undefined || savedLiveDataRef.current!=undefined){
            console.log("OF Pan Test: Y axis zoom handlelive END=====> rendering last live profile now");
            handleLiveData(savedLiveDataRef.current)
            
            
          }
          // if(savedLiveData!=undefined){
          //   handleLiveData(savedLiveData)
          //   console.log("OF Pan Test: Y axis zoom handlelive END=====> rendering last live profile now");
          //   console.log("Test zoom handlelive END=====> rendering last live profile now");
          // }
        })

       
       if(zoomType==OF_ZOOM_TYPE.ASYMMETRIC){
        svgChart.call(zoom_function_scale.transform, tY) .on("dblclick.zoom", null);
         svgChart.call(zoom_function_scale).on("dblclick.zoom", null);
       }
    
        setIsLoadingMoreProfiles(false);
        
    },[historicalData,windowSize,props.chartStateData,toggleRepaint,metaData])
    //toggles stacked and split profiles
    const updateDisplayType=(profileData,currentIndex)=>{
      setShowContextMenu(false);
      
      console.log("profile data =",profileData)
      profileData.isStacked=!(profileData.isStacked);
      console.log("profile data after =",profileData)
      historicalData.profilesData[currentIndex]=profileData
      // historicalData.profilesData[currentIndex].isStacked=!historicalData.profilesData[currentIndex].isStacked;
      historicalData.config.totalWidth=historicalData.config.totalWidth+ 
                                    (profileData.isStacked?(profileData.stackedWidth-profileData.splitWidth):
                                    (profileData.splitWidth-profileData.stackedWidth));
      let data = JSON.parse(JSON.stringify(historicalData));
      setSelectedProfilesIndex([]);
      setHistoricalData(data);
    }

     //toggles stacked and split profiles
     const toggleStackedProfiles=(show)=>{
      setShowContextMenu(false);
      const is_Monthly_Yearly=(props.selectedTimeFrame==TIME_FRAME_VALUES.monthly || props.selectedTimeFrame==TIME_FRAME_VALUES.monthly_series || props.selectedTimeFrame==TIME_FRAME_VALUES.yearly)
      for(let i=0;i<selectedProfilesIndex.length;i++){
        if( is_Monthly_Yearly || historicalData.profilesData[selectedProfilesIndex[i]].dateList.length==1 ||historicalData.profilesData[selectedProfilesIndex[i]].dateList.length>props.max_cmp_days){
         
        let prevVal=historicalData.profilesData[selectedProfilesIndex[i]].isStacked;
        historicalData.profilesData[selectedProfilesIndex[i]].isStacked=show;
        historicalData.profilesData[selectedProfilesIndex[i]].showTPO=true;
        //change the width only when previuos and current values are different
       
       if(prevVal!=show)
          historicalData.config.totalWidth=historicalData.config.totalWidth+ 
                                (!show?(historicalData.profilesData[selectedProfilesIndex[i]].stackedWidth-historicalData.profilesData[selectedProfilesIndex[i]].splitWidth):
                                 (historicalData.profilesData[selectedProfilesIndex[i]].splitWidth-historicalData.profilesData[selectedProfilesIndex[i]].stackedWidth));
        }   
      }  
      console.log("Stacked profile after width",historicalData.config.totalWidth);
      setSelectedProfilesIndex([]);                              
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
    }

    //toggles volume profiles
    const toggleVolumeProfile=(show,all=false)=>{
      setShowContextMenu(false);
      if(all==false){
      for(let i=0;i<selectedProfilesIndex.length;i++){
        let prevVal=historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile;
        let tpoOnVolume=historicalData.profilesData[selectedProfilesIndex[i]].isTPOonVolume;
       
        historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile=show;
        historicalData.profilesData[selectedProfilesIndex[i]].showTPO=true;
        historicalData.profilesData[selectedProfilesIndex[i]].showOnlyVolumeProfile=false;
        historicalData.profilesData[selectedProfilesIndex[i]].isTPOonVolume=false;
        //change the width only when previuos and current values are different
        if(prevVal!=show && !tpoOnVolume)
        historicalData.config.totalWidth=historicalData.config.totalWidth+ 
                                        (show?historicalData.profilesData[selectedProfilesIndex[i]].volumeWidth:-1*historicalData.profilesData[selectedProfilesIndex[i]].volumeWidth)     
      }  
      console.log("Volume profile after width",historicalData.config.totalWidth);
     }else{
      for(let i=0;i<historicalData.profilesData.length;i++){
        let prevVal=historicalData.profilesData[i].isVolumeProfile;
        let tpoOnVolume=historicalData.profilesData[i].isTPOonVolume;
       
        historicalData.profilesData[i].isVolumeProfile=show;
        historicalData.profilesData[i].showTPO=true;
        historicalData.profilesData[i].showOnlyVolumeProfile=false;
        historicalData.profilesData[i].isTPOonVolume=false;
        //change the width only when previuos and current values are different
        if(prevVal!=show && !tpoOnVolume)
        historicalData.config.totalWidth=historicalData.config.totalWidth+ 
                                        (show?historicalData.profilesData[i].volumeWidth:-1*historicalData.profilesData[i].volumeWidth)     
      }
    }
    setSelectedProfilesIndex([]); 
                                 
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
    }

    const showTPOonVolume=(show,all=false)=>{
      setShowContextMenu(false);
      if(all==false){
      for(let i=0;i<selectedProfilesIndex.length;i++){
        let isVolumeVisible=historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile;
        let tpoOnVolume=historicalData.profilesData[selectedProfilesIndex[i]].isTPOonVolume;
        historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile=show;
        historicalData.profilesData[selectedProfilesIndex[i]].isTPOonVolume=show;
        historicalData.profilesData[selectedProfilesIndex[i]].isVolumeNumbers=false;
        historicalData.profilesData[selectedProfilesIndex[i]].showTPO=true;
        console.log("Volume profile before width",historicalData.config.totalWidth);
       
        if(isVolumeVisible)
        historicalData.config.totalWidth=historicalData.config.totalWidth-historicalData.profilesData[selectedProfilesIndex[i]].volumeWidth;     
                                     
        }  
      console.log("Volume profile after width",historicalData.config.totalWidth);
      }
      else{
      for(let i=0;i<historicalData.profilesData.length;i++){
        let isVolumeVisible=historicalData.profilesData[i].isVolumeProfile;
        let tpoOnVolume=historicalData.profilesData[i].isTPOonVolume;
        historicalData.profilesData[i].isVolumeProfile=show;
        historicalData.profilesData[i].isTPOonVolume=show;
        historicalData.profilesData[i].isVolumeNumbers=false;
        historicalData.profilesData[i].showTPO=true;
        console.log("Volume profile before width",historicalData.config.totalWidth);
       
        if(isVolumeVisible)
        historicalData.config.totalWidth=historicalData.config.totalWidth-historicalData.profilesData[i].volumeWidth;     
                                     
        }  
      console.log("Volume profile after width",historicalData.config.totalWidth);
      }
    
      setSelectedProfilesIndex([]);                              
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
    }

    const toggleVolumeOnlyProfile=(showOnlyVolumeProfile,all=false)=>{
      setShowContextMenu(false);
      if(all==false){
      for(let i=0;i<selectedProfilesIndex.length;i++){
        let isVolumeVisible=historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile;
        historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile=showOnlyVolumeProfile;
        historicalData.profilesData[selectedProfilesIndex[i]].showOnlyVolumeProfile=showOnlyVolumeProfile;
        historicalData.profilesData[selectedProfilesIndex[i]].isTPOonVolume=!showOnlyVolumeProfile;
        historicalData.profilesData[selectedProfilesIndex[i]].showTPO=!showOnlyVolumeProfile;
        
        console.log("Volume profile before width",historicalData.config.totalWidth);
       
        if(isVolumeVisible)
        historicalData.config.totalWidth=historicalData.config.totalWidth-historicalData.profilesData[selectedProfilesIndex[i]].volumeWidth;     
                                     
        }  
      }else{
        for(let i=0;i<historicalData.profilesData.length;i++){
          let isVolumeVisible=historicalData.profilesData[i].isVolumeProfile;
          historicalData.profilesData[i].isVolumeProfile=showOnlyVolumeProfile;
          historicalData.profilesData[i].showOnlyVolumeProfile=showOnlyVolumeProfile;
          historicalData.profilesData[i].isTPOonVolume=!showOnlyVolumeProfile;
          historicalData.profilesData[i].showTPO=!showOnlyVolumeProfile;
          console.log("Volume profile before width",historicalData.config.totalWidth);
         
          if(isVolumeVisible)
          historicalData.config.totalWidth=historicalData.config.totalWidth-historicalData.profilesData[i].volumeWidth;     
                                       
          
      }
    }
      console.log("Volume profile after width",historicalData.config.totalWidth);
      setSelectedProfilesIndex([]);                              
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
   
    }
    //toggles volume numbers
    const toggleVolumeNumbers=(show,all=false)=>{
      setShowContextMenu(false);
      if(all==false){
       for(let i=0;i<selectedProfilesIndex.length;i++){
          historicalData.profilesData[selectedProfilesIndex[i]].isVolumeNumbers=show;
        }  
      }else{
        for(let i=0;i<historicalData.profilesData.length;i++){
          historicalData.profilesData[i].isVolumeNumbers=show;
        }
      }
      setSelectedProfilesIndex([]);                              
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
    }

    const toggleTPOBasedVA=(showTPOBasedVolume,all=false)=>{
      console.log("tpgg;eTPOBaed va=",showTPOBasedVolume)
      setShowContextMenu(false);
      if(all==false){
        for(let i=0;i<selectedProfilesIndex.length;i++){
          historicalData.profilesData[selectedProfilesIndex[i]].tpoBasedVA=showTPOBasedVolume;
          historicalData.profilesData[selectedProfilesIndex[i]].volumeBasedVA=!showTPOBasedVolume;
        }
        setSelectedProfilesIndex([]);                              
    }
    
    else{
      for(let i=0;i<historicalData.profilesData.length;i++){
        historicalData.profilesData[i].tpoBasedVA=showTPOBasedVolume;
          historicalData.profilesData[i].volumeBasedVA=!showTPOBasedVolume;
      }  
    }
      console.log("Volume profile after width",historicalData.config.totalWidth);
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
    }

    //closes the context menu
    const exitMenu=()=>{
      setShowContextMenu(false);
     }

     /**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    let data = JSON.parse(JSON.stringify(historicalData));
    setHistoricalData(data);
    
  }
},[props.globalVolumeVisible,props.volumeNumberVisible])

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    console.log("Reload OFchart bottomtablevisible=",props.bottomTableVisible)
    let data = JSON.parse(JSON.stringify(historicalData));
    setHistoricalData(data);
    setTableVisible(props.bottomTableVisible);
    
  }
  
},[props.bottomTableVisible])

useEffect(()=>{
  console.log("Reload OFchart bottomtablevisible=",props.bottomTableVisible,tableVisible)
  saveChartState();
},[tableVisible])

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    console.log("MP chart per profile volume visible=",props.profileVolumeVisible)
   toggleVolumeProfile(props.profileVolumeVisible,true)
  }
},[props.profileVolumeVisible])

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    toggleVolumeNumbers(props.profileVolumeNumberVisible,true)
  }
},[props.profileVolumeNumberVisible])

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    showTPOonVolume(props.tpoOnVolume,true)
  }
},[props.tpoOnVolume])

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    toggleVolumeOnlyProfile(props.onlyVolumeProfile,true)
  }
},[props.onlyVolumeProfile])



  const chartRefresh=()=>{
    console.log("refresh chart is called")
    setRefreshChart(true);
  }

  useEffect(()=>{
    if(refreshChart){
      //get the chart start date, dstr, liveData, and liveDataComposite state
      let liveData=false;
      let liveComposite=false;
      if(historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null){
        liveData=true;
        if(historicalData.profilesData[historicalData.profilesData.length-1].dateList.length>1)
        liveComposite=true;
      }
      //if live composite is present then only refresh call
      // if(liveData==true && liveComposite==true){
        let dstrMap=[];
        dstrMap.push(historicalData.profilesData[historicalData.profilesData.length-1].dateKeyList);
        setCompositeData({
          dstr:((historicalData.profilesData[historicalData.profilesData.length-1].dateList.length-1).toString()),
          nDays:(historicalData.profilesData[historicalData.profilesData.length-1].dateList.length-1),
          startDate:historicalData.profilesData[historicalData.profilesData.length-1].dateList[0],
          startIndex:(historicalData.profilesData.length-1),
          endIndex:(historicalData.profilesData.length-1),
          dstrMap:dstrMap,
          liveData:liveData,
          liveComposite:liveComposite
        })
      // }

    }
  },[refreshChart])

  const unselectProfiles=()=>{
    setShowContextMenu(false);
    setSelectedProfilesIndex([]);
  }
  
  useEffect(()=>{
   
    if(selectedProfilesIndex!=undefined && selectedProfilesIndex.length==0){
      setShowContextMenu(false);
      setToggleRepaint(!toggleRepaint)    
    }
  },[selectedProfilesIndex])

  //unselect profiles from bottom panel button
  useEffect(()=>{
    if(selectedProfilesIndex!=undefined && selectedProfilesIndex.length>0){
      setSelectedProfilesIndex([]);
      console.log("unselect profile mp chart",selectedProfilesIndex.length,selectedProfilesIndex);
      setShowContextMenu(false);
      setToggleRepaint(!toggleRepaint)    
    }
  },[props.unselectProfiles])

  useEffect(()=>{
    if(historicalData!=undefined){
    console.log("view state received in MP chart = ",props.viewState);
    handleViewState(props.viewState);
    }
  },[props.viewState])

  
  const handleViewState=(state)=>{
    //{tpo_only:true,vol_tpo_side:false,only_vol:false,tpo_on_vol:false}
    console.log("handleViewState Volume profile before width",historicalData.config.totalWidth);
    for(let i=0;i<historicalData.profilesData.length;i++){
      let isVolumeVisible=historicalData.profilesData[i].isVolumeProfile;
      let tpoOnVolume=historicalData.profilesData[i].isTPOonVolume;
      let onlyVOl=historicalData.profilesData[i].showOnlyVolumeProfile;
      let showTPO=historicalData.profilesData[i].showTPO;
      historicalData.profilesData[i].isVolumeProfile=state.vol_tpo_side ||state.tpo_on_vol ||state.only_vol;
      historicalData.profilesData[i].isTPOonVolume=state.tpo_on_vol;
      historicalData.profilesData[i].showOnlyVolumeProfile=state.only_vol;
      historicalData.profilesData[i].showTPO=state.tpo_only;
     
      if(isVolumeVisible && !tpoOnVolume && !onlyVOl && ((state.tpo_on_vol|| state.only_vol ||state.tpo_only)))
      historicalData.config.totalWidth=historicalData.config.totalWidth-historicalData.profilesData[i].volumeWidth;     
      
      else if((showTPO||tpoOnVolume ||onlyVOl) && state.vol_tpo_side)
      historicalData.config.totalWidth=historicalData.config.totalWidth+ historicalData.profilesData[i].volumeWidth
                                     
      }  
      console.log("handleViewState Volume profile after width",historicalData.config.totalWidth);
   
  
    // setSelectedProfilesIndex([]);                              
    let data = JSON.parse(JSON.stringify(historicalData));
    setHistoricalData(data);
 
  }

  useEffect(()=>{
    if(historicalData!=undefined){
    toggleTPOBasedVA(props.tpoBasedVA,true);
    }
  },[props.tpoBasedVA])

  const showInfoInRightPanel=()=>{
    const isLiveSession=( historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive)?true:false;
    console.log("Metadata in mpchart",metaData)
    props.setRightContainerData(true,metaData,historicalData,selectedProfilesIndex,isLiveSession);
    setShowContextMenu(false);
  }

  useEffect(()=>{
    console.log("Bookmark data in MP chart=",props.bookmarkData);
    setBookmarkData(props.bookmarkData);
  },[props.bookmarkData])
  //Bookmarks and auto saved related functions

 
 
  
  

  

  useEffect(()=>{
    console.log("props.showTable=",props.showTable);
    if(props.showTable){
      const container= d3.select(`#${props.id}`);
      console.log("props.showTable container=",container);
      d3.select(`#${props.id}`).style("opacity", 0);
    }else{
      d3.select(`#${props.id}`).style("opacity", 1);

    }
  },[props.showTable])

  const exitYAxisMenu=()=>{
    setShowYAxisContextMenu(false);
   }

  const setLowerLevel=(val)=>{
    console.log("Testing1 set lower level=",val)
  }

  const deleteLowerLevel=(val)=>{
    console.log("Testing1 delete lower level=",val)
  }

  const setUpperLevel=(val)=>{
    console.log("Testing1 set upper level=",val)
  }

  const deleteUpperLevel=(val)=>{
    console.log("Testing1 delete upper level=",val)
  }

  const setBoundary=(val)=>{
    console.log("Testing1 set lower level=",val)
    let boundary=[];
    boundary.push(levelData[0]);
    boundary.push(levelData[1]);
    
    if(levelData[0]==-1)
    boundary[0]=parseFloat(val);
    else if(levelData[1]==-1)
    boundary[1]=parseFloat(val);
    else{
      // if(level)
    }

    if(boundary[0]==-1 || boundary[1]==-1)
    setLevelData(boundary.sort());

    //plot chart between level only when both levels are set
    if(boundary[0]!=-1 && boundary[1]!=-1){
      setOpenSetLevelDialog(true);
      // props.setlevelBoundary(boundary.sort());
    }
    exitYAxisMenu();
    

    console.log("Boundary level data=",levelData,boundary);
  }

  const cancelSetLevelHandler=()=>{
    setOpenSetLevelDialog(false);
  }

  const saveLevelHandler=()=>{
    setOpenSetLevelDialog(false);
    let boundary=[];
    boundary.push(levelData[0]);
    boundary.push(levelData[1]);

    if(levelData[0]==-1)
    boundary[0]=parseFloat(priceVal);
    else if(levelData[1]==-1)
    boundary[1]=parseFloat(priceVal);

    props.setlevelBoundary(boundary.sort());
  }

  const cancelDeleteLevelHandler=()=>{
    setOpenDeleteLevelDialog(false);
  }

  const saveDeleteLevelHandler=()=>{
    setOpenDeleteLevelDialog(false);
    let boundary=[];
   
    if(levelData[0]==priceVal){
      boundary.push(-1);
      boundary.push(levelData[1]);
    }else{
      boundary.push(levelData[0]);
      boundary.push(-1);
    }
    props.resetlevelBoundary(boundary.sort());
    setLevelData(boundary.sort());
  }

  useEffect(()=>{
   console.log("Boundary levelData useeffect=",levelData);
   setToggleRepaint(!toggleRepaint);
  //  props.setlevelBoundary(levelData);
  },[levelData])
  
  
  const closeLiveModal=()=>{
setLivePanelVisible(false);
props.setShowLivePanel(false);
  }

  const recenterChart=()=>{
    if(historicalData!=undefined){
      setIsFirstLoad(true);
      setIsRecenterAllowed(true);
      setTransformData({k:1,x:0,y:0});
      setTransformDataY({k:1,x:0,y:0});
      setToggleRepaint(!toggleRepaint);
      }
     
  }

  const showHelp=()=>{
    setShowHelpPopup(true)
  }

 

  const getBottomTableHeight=()=>{
    let height=104;
    if(props.selectedTimeFrame!=OF_TIME_FRAME_VALUES.tf_1_min){
      if(props.userSettings.data.of_bottom_col_hidden_list && props.userSettings.data.of_bottom_col_hidden_list.length>0){
        height=height-17*props.userSettings.data.of_bottom_col_hidden_list.length;
      }
    }else {
      height=76;
      if(props.userSettings.data.of_bottom_col_hidden_list && props.userSettings.data.of_bottom_col_hidden_list.length>0){
      let filterCol=props.userSettings.data.of_bottom_col_hidden_list.filter(item=> (item!="OI" && item!="dOI"));
      // let filterCol=props.userSettings.data.of_bottom_col_hidden_list;
      console.log("Table height====>",filterCol);
      if(filterCol && filterCol.length>0){
        height=height-17*filterCol.length;
      }
      }
    }
    return height;
  }

  const isBottomColHidden=(col)=>{
    let hidden=false;
    if(props.userSettings.data.of_bottom_col_hidden_list && props.userSettings.data.of_bottom_col_hidden_list.length>0){
      let filterCol=props.userSettings.data.of_bottom_col_hidden_list.filter(item=> item==col);
      if(filterCol && filterCol.length>0){
       hidden=true;
      }
    }
    return hidden;
  }

  const getChartStateData = () => {
    let data = {
      "key":props.val,
      "instrument": selectedInstrumentRef.current,
      "type": CHART_TYPE.OF_CHART,
      "tf": selectedTimeFrame,
      "glob_vol": props.globalVolumeVisible,
      "glob_num": props.volumeNumberVisible,
      "live_panel":livePanelVisible,
      "table":tableVisible,
      "tpo":selectedTPO

    }
    return data;
  }

  
    return (
    // <div className="chart-container"  style={{height:`calc(${props.chartStateData.height - 32 - 44}px)`,width:`calc(${props.chartStateData.width})`,marginTop:`calc(${props.chartStateData.top})`,marginLeft:`calc(${props.chartStateData.left})`}} >
    <>
    {true?  
    <>
     
    <div id={props.id} className={props.id} style={{height:"100%",width:"100%"}}>
      {/* {props.livePanelVisible && metaData? */}
       {livePanelVisible && historicalData?
      <>
     <OFLiveModal rotatingCot={rotatingCot}divisionFactor={props.contracts?props.liveData?props.liveData.ls[props.liveData.ls.length-1]:props.chartData.ls[props.chartData.ls.length-1]:1}locale_string={props.locale_string}closeModal={closeLiveModal}metaData={historicalData.profilesData[historicalData.profilesData.length-1].mdata} chartStateData={props.chartStateData}  boundClassName={props.boundClass} lastDataTime={historicalData.config.lastDataTime} latency={props.liveData?props.liveData.latency: props.chartData.latency}/>
     
     </>:
     <></>
      }
      {historicalData?
        <OFFABModal recenterChart={recenterChart} showHelp={showHelp}chartStateData={props.chartStateData} boundClassName={props.boundClass} bottomMargin={props.selectedTimeFrame!=OF_TIME_FRAME_VALUES.tf_1_min?(tableVisible?88:88):(tableVisible?62:62)}></OFFABModal>
      :<></>
    }  

{showHelpPopup?
         <OFHelpPopup open={showHelpPopup} zoomType={zoomType}  data={undefined} proMessage={false} onClose={()=>setShowHelpPopup(false)}></OFHelpPopup>  :
         <></>
      }

{/* <div id={"tableCol"+props.id} style={{zIndex:999,width:"70px", position:"fixed",top:props.selectedTimeFrame!=OF_TIME_FRAME_VALUES.tf_1_min?`${props.chartStateData.top+props.chartStateData.height-92}px`:`${props.chartStateData.top+props.chartStateData.height-66}px`, left:`${props.chartStateData.left+props.chartStateData.width-60.5}px`, height:props.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_1_min?"76px":"104px",background:"#132d5a",borderLeft:"1px solid #FFF",borderTop:"1px solid #FFF"}}> */}
 {tableVisible && getBottomTableHeight()>10?
 <div id={"tableCol"+props.id} style={{zIndex:999,width:"70px", position:"fixed",top:props.selectedTimeFrame!=OF_TIME_FRAME_VALUES.tf_1_min?`${props.chartStateData.top+props.chartStateData.height-getBottomTableHeight()+12}px`:`${props.chartStateData.top+props.chartStateData.height-getBottomTableHeight()+10}px`, left:`${props.chartStateData.left+props.chartStateData.width-60.5}px`, height:`${getBottomTableHeight()}px`,background:"#132d5a",borderLeft:"1px solid #FFF",borderTop:"1px solid #FFF"}}> 
   <Box sx={{marginTop:props.selectedTimeFrame!=OF_TIME_FRAME_VALUES.tf_1_min?"0px":"8px"}}>
    {/* <Typography sx={{fontSize:"12px", fontWeight:600, marginLeft:"2px",marginTop:props.selectedTimeFrame!=OF_TIME_FRAME_VALUES.tf_1_min?"0px":"8px"}}>Volume</Typography> */}
    {isBottomColHidden("Volume")==false?
      <Typography sx={{fontSize:"12px", fontWeight:600, marginLeft:"2px"}}>Volume</Typography>:
      <></>
    }

    {isBottomColHidden("VPOC")==false?
      <Typography sx={{fontSize:"12px", fontWeight:600,marginLeft:"2px"}}>VPOC</Typography>:
      <></>
    }
    {isBottomColHidden("VPOC Vol")==false?
      <Typography sx={{fontSize:"12px", fontWeight:600,marginLeft:"2px"}}>VPOC Vol</Typography>:
      <></>
    }
    {isBottomColHidden("COT")==false?
      <Typography sx={{fontSize:"12px", fontWeight:600,marginLeft:"2px"}}>COT</Typography>:
      <></>
    }
    
    {props.selectedTimeFrame!=OF_TIME_FRAME_VALUES.tf_1_min?
      <>
       {isBottomColHidden("OI")==false?
        <Typography sx={{fontSize:"12px", fontWeight:600,marginLeft:"2px"}}>OI</Typography>:
      <></>}
      {isBottomColHidden("dOI")==false?
        <Typography sx={{fontSize:"12px", fontWeight:600,marginLeft:"2px"}}>dOI</Typography>:
      <></>}

      </>
    :<></>
    }
    </Box>
    </div> 
    :<></>}
     
    
   
    <div id={"watermark"+props.id} className="watermark">
    <text>{WATERMARK_CONFIG.TEXT}</text>
   
    </div>
  


    <div id={"tools"+props.id} className="tools">
    {/* <button className="buttonTool" id="zoomin"> + </button>
    <button className="buttonTool" id="zoomout"> - </button>
         */}
        {/* <button className="buttonTool" id={"reset"+props.id}>Reset</button> */}
        
        {/* {selectedProfilesIndex!=undefined && selectedProfilesIndex.length>0?
        <button className="buttonTool" onClick={()=>unselectProfiles()} id="unselect">Unselect Profiles</button>:
        <></> 
        } */}
        
        {/* <button className="buttonTool" id="recenter">Recenter</button> */}
        {liveDataComposite?
        <button className="buttonTool" onClick={()=>chartRefresh()} id={"refresh"+props.id}>Refresh</button>:
        <></>
        } 
    </div>
    {showContextMenu?
    <ContextMenu 
      xPosition={anchorPoint.x}
      yPosition={anchorPoint.y} 
      profileData={historicalData.profilesData}
      selectedProfilesIndex={selectedProfilesIndex}
      currentIndex={currentIndex}
      updateDisplayType={updateDisplayType}
      toggleStackedProfiles={toggleStackedProfiles}
      toggleVolumeProfile={toggleVolumeProfile}
      toggleVolumeNumbers={toggleVolumeNumbers}
      selectedInstrumentData={props.selectedInstrumentData}
      // mergeProfiles={mergeProfiles}
      // unmergeProfile={unmergeProfiles}
      exitMenu={exitMenu}
      showTPOonVolume={showTPOonVolume}
      toggleTPOBasedVA={toggleTPOBasedVA}
      toggleVolumeOnlyProfile={toggleVolumeOnlyProfile}
      showInfoInRightPanel={showInfoInRightPanel}
      key={props.key}
      chartScreenType={props.chartScreenType}
      selectedTimeFrame={props.selectedTimeFrame}
      />:
    <></>}

{showTooltip?
    <Tooltip 
      xPosition={anchorPointTooltip.x}
      yPosition={anchorPointTooltip.y} 
      data={tooltipData}
      key={props.key}
      chartScreenType={props.chartScreenType}
      selectedTimeFrame={props.selectedTimeFrame}
      />:
    <></>}

{showYAxisContextMenu?
    <YAxisContextMenu 
      xPosition={anchorPointYAxis.x}
      yPosition={anchorPointYAxis.y} 
      value={priceVal}
      key={props.key}
      chartScreenType={props.chartScreenType}
      selectedTimeFrame={props.selectedTimeFrame}
      setLowerLevel={setLowerLevel}
      removeLowerLevel={deleteLowerLevel}
      setUpperLevel={setUpperLevel}
      removeUpperLevel={deleteUpperLevel}
      exitMenu={exitYAxisMenu}
      setBoundary={setBoundary}
      levelData={levelData}
      />:
    <></>}  

    {isMoreDataLoading? 
    <div className="loading" >
      Loading additional data...
      </div>:
    <></>}

<div style={{display:'none'}}>
  {/* <img id="asterisk" src="../../../assets/images/asterisk.png" width="24" height="24" /> */}
</div>
     
 <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%"}}}>
            {msg}
          </Alert>  
       </Snackbar>
     

  {openSetLeveleDialog?
     <ConfirmActionDialog open={true} cancelHandler={cancelSetLevelHandler} saveHandler={saveLevelHandler} 
        title={TEXT_MSGS.SET_LEVEL_TITLE} description={TEXT_MSGS.SET_LEVEL_DESC}></ConfirmActionDialog>    :
     <></>
    }

{openDeleteLeveleDialog?
     <ConfirmActionDialog open={true} cancelHandler={cancelDeleteLevelHandler} saveHandler={saveDeleteLevelHandler} 
        title={TEXT_MSGS.DELETE_LEVEL_TITLE} description={TEXT_MSGS.DELETE_LEVEL_DESC}></ConfirmActionDialog>    :
     <></>
    }


</div></>:
<></>
}

</>
  )
}

export default OFChart
