import React,{useEffect,useContext} from 'react'
import {Grid} from "@mui/material";
import { CONSTANTS } from '../../../common/utility/constant';
import DashboardHeader from '../../../components/AppHeader/header';
import {useState} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import { Construction } from '@mui/icons-material';
import {UserSettingsContext} from '../../../setup/routes-manager/RequireAuth';


/**
 * 
 * @param {*} param0 
 * @returns Main container for the dashboard
 */
function DashboardLayoutContainer({children}) {
    const  [CurrentComponent,setCurrentComponent]=useState(children);
    const navigate = useNavigate();
    const location = useLocation();
    const [cancel, responseData, error, loaded, reset, executeAPI ] = useAxiosWithAuth();
    const [showPopup,setShowPopup]=useState(false)
 
    const { userSettings, setUserSettings } = useContext(UserSettingsContext);
    // console.log("userSettings from context=",userSettings)
    
    const setRoute=(route,component)=>{
      console.log("Route at main_layout=",route);
      navigate(route, {stateNew: { id: 7, color: 'green',userSetting:userSettings }, replace: true });
    //   setCurrentComponent(component);
    }

    
    return (
        <>  
            <DashboardHeader setCurrentRoute={setRoute} />
            <div style={{paddingTop:`${CONSTANTS.DASHBOARD_HEADER_HEIGHT}px`}}>
                <Grid container component="main" sx={{height: `calc(100vh - ${CONSTANTS.DASHBOARD_HEADER_HEIGHT}px)` }}>    
                    {CurrentComponent}
                </Grid>
            </div>
        </>      
  )
}

export default DashboardLayoutContainer
