import * as React from 'react';
import { useState,useEffect } from 'react';
import {Snackbar, Alert} from '@mui/material';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Header from '../components/header';
import { useAxios } from '../../../common/api/hooks/useAxios';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import WelcomeLayout from '../components/welcome-layout';
import FormContainer from '../components/form-container';
import { useQuery } from '../../../common/utility/helperFunc';
import EmailVerificationIcon from '../../../assets/images/email-verification.svg';
import MainLayoutContainer from '../components/main-layout';
import { TEXT_MSGS, RESEND_TIMER_VAL,SNACKBAR_AUTO_HIDE_DURATION } from '../../../common/utility/constant';

 
export default function EMAIL_VERIFICATION() {
  const { cancel,responseData, error, loaded, reset,executeAPI } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const emailId=useQuery("emailId");
  
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });

  const { open, msg,severity } = msgState;

  const [counter, setCounter] = React.useState(RESEND_TIMER_VAL);
  React.useEffect(() => {
      const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
  }, [counter]);

 
/**
 * API response handler  
 */  
  useEffect(() => {
  
    if(loaded){
      setCounter(RESEND_TIMER_VAL);  //reset timer after api response received
      if(responseData!=null){
        console.log("responseData=",responseData);
        setMsgState({open:true,msg:responseData.message,severity:"success"});
        // navigate(from, { replace: true });
      }
      else if(error!==null){
        setMsgState({open:true,msg:error?.response?.data?.message ??`${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"info"});
      }
      reset();
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loaded,responseData,error,navigate,from]);

/**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };

/**
 * API caller 
 */  
  const resendEmail=()=>{
    executeAPI(URL.RESEND_ACTIVATION_EMAIL,"POST",JSON.stringify({email_id:emailId})); 
  };

  return (
        <MainLayoutContainer>
        
        {/* Header component */}
        <Header showLogin={true} showIntro={true}/>
       
        {/*Welcome banner left component*/}
        <WelcomeLayout/>
       
        {/* Form container for right layut */}
        <FormContainer>
        
        <Box sx={{ display: 'flex',flexDirection: 'column', justifyContent:"center", hight:"100%", 
        width:{mobile:"90%", tablet:"75%", laptop:"60%"} }}> 

             <img src={EmailVerificationIcon} height="72" width="72" alt="Email Verification" />
            <Typography  variant="titleMedium" sx={{ mt: {laptop:2,tablet:2,mobile:2}}}>
              Activation Link is sent on your email
            </Typography>

            <Typography  variant="descriptionMedium" sx={{ mt: {laptop:1,tablet:1,mobile:1}}}>
              Please check your inbox email and click on the received email to confirm your account and log in.
            </Typography>
      
            <Typography variant="link"sx={{ mt: {laptop:2,tablet:2,mobile:2}}}>{"Didn't receive email? "}</Typography>  
              {counter>0?
                <Typography variant="link"> {"Resend  in "}  
                  <Typography variant="link" sx={{color:"secondaryTheme.shade04"}}>
                  { Math.floor(counter / 60)} : {counter -  Math.floor(counter / 60) * 60}
                  </Typography>
                </Typography>:
                <Link  variant="link" onClick={()=>resendEmail()} sx={{pb:6,cursor: "pointer"}}>{"Resend"} </Link>
              }
          
 
          </Box>
 
        </FormContainer>
        
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
        </Snackbar>
 
      </MainLayoutContainer>
  );
}
