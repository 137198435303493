import { Rnd } from "react-rnd";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Tabs, Tab, Divider, Tooltip, Typography, Container } from '@mui/material';
import { useTheme } from "@mui/material";
import { RIGHT_PANEL_CONTAINER_WIDTH, CHART_COLORS, INFO_PANEL_VIEWS, CANDLE_BOTTOM_VIEW_TYPE, BOTTOM_PANEL_VIEWS } from '../../../common/utility/mp_chart_constants';

const style = {
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    // border: "solid 0.5px #285fbd",
    // background: "#0d203fdd",
    zIndex: 9999,
    borderRadius: 5,
    // opacity:0.1
};

const BPModal = (props) => {
    const [expanded, setExpanded] = useState(true)
    const [panelView1, setPanelView1] = useState(props.userSettings.data.candle_map && props.userSettings.data.candle_map[props.tpo_group] && props.userSettings.data.candle_map[props.tpo_group]?.panel1_bottom_col ? props.userSettings.data.candle_map[props.tpo_group].panel1_bottom_col : (props.isOPT ? CANDLE_BOTTOM_VIEW_TYPE.VOLUME : CANDLE_BOTTOM_VIEW_TYPE.VOLUME))
    const [panelView2, setPanelView2] = useState(props.userSettings.data.candle_map && props.userSettings.data.candle_map[props.tpo_group] && props.userSettings.data.candle_map[props.tpo_group]?.panel2_bottom_col ? props.userSettings.data.candle_map[props.tpo_group].panel2_bottom_col : (props.isOPT ? CANDLE_BOTTOM_VIEW_TYPE.DOI : CANDLE_BOTTOM_VIEW_TYPE.COT))
    const [width, setWidth] = useState(140);
    const [height, setHeight] = useState((props.plot == "candle" && props.panelKey == 1 && panelView1 == CANDLE_BOTTOM_VIEW_TYPE.COT) || (props.plot == "candle" && props.panelKey == 2 && panelView2 == CANDLE_BOTTOM_VIEW_TYPE.COT) ? 50 : 28);

    const theme = useTheme();
    const [xCord, setXCord] = useState(2);
    const [yCord, setYCord] = useState(props.top);
    const [visibilityMatrix, setVisibilityMatrix] = useState(props.visibilityMatrix);

    useEffect(() => {
        console.log("BPModal useeffect view1, view2=", panelView1, panelView2)
    }, [])

    useEffect(() => {
    }, [props.data])


    useEffect(() => {
        setPanelView1(props.userSettings.data.candle_map && props.userSettings.data.candle_map[props.tpo_group] && props.userSettings.data.candle_map[props.tpo_group]?.panel1_bottom_col ? props.userSettings.data.candle_map[props.tpo_group].panel1_bottom_col : (props.isOPT ? CANDLE_BOTTOM_VIEW_TYPE.VOLUME : CANDLE_BOTTOM_VIEW_TYPE.VOLUME))
        setPanelView2(props.userSettings.data.candle_map && props.userSettings.data.candle_map[props.tpo_group] && props.userSettings.data.candle_map[props.tpo_group]?.panel2_bottom_col ? props.userSettings.data.candle_map[props.tpo_group].panel2_bottom_col : (props.isOPT ? CANDLE_BOTTOM_VIEW_TYPE.DOI : CANDLE_BOTTOM_VIEW_TYPE.COT))
        setYCord(props.top);
    }, [props.userSettings, props.livePanelState, props.top]);


    useEffect(() => {
        // setHeight((props.panelKey==1&& panelView1==CANDLE_BOTTOM_VIEW_TYPE.COT)||(props.panelKey==2&& panelView2==CANDLE_BOTTOM_VIEW_TYPE.COT)?50:28);
    }, [panelView1, panelView2])


    useEffect(() => {
        setVisibilityMatrix(props.visibilityMatrix)
    }, [props.visibilityMatrix])


    useEffect(() => {
        // console.log("Visibility Matrix=",visibilityMatrix);
        // props.changeVisibilityMatrix(visibilityMatrix);
        if (props.plot == "candle") {
            if ((props.panelKey == 1 && panelView1 == CANDLE_BOTTOM_VIEW_TYPE.VOLUME) || (props.panelKey == 2 && panelView2 == CANDLE_BOTTOM_VIEW_TYPE.VOLUME)) {
                if (visibilityMatrix["vol"])
                    setHeight(28);
                else
                    setHeight(0)
            } else if ((props.panelKey == 1 && panelView1 == CANDLE_BOTTOM_VIEW_TYPE.DOI) || (props.panelKey == 2 && panelView2 == CANDLE_BOTTOM_VIEW_TYPE.DOI)) {
                if (visibilityMatrix["doi"])
                    setHeight(28);
                else
                    setHeight(0)
            } else if ((props.panelKey == 1 && panelView1 == CANDLE_BOTTOM_VIEW_TYPE.COT)) {
                if (visibilityMatrix["cot"] && visibilityMatrix["rolling_cot"])
                    setHeight(50);
                else if (visibilityMatrix["cot"] || visibilityMatrix["rolling_cot"])
                    setHeight(28)
                else
                    setHeight(0)
            }
            else if ((props.panelKey == 2 && panelView2 == CANDLE_BOTTOM_VIEW_TYPE.COT)) {
                if (visibilityMatrix["cot"] && visibilityMatrix["rolling_cot2"])
                    setHeight(50);
                else if (visibilityMatrix["cot"] || visibilityMatrix["rolling_cot2"])
                    setHeight(28)
                else
                    setHeight(0)
            }
        } else if (props.plot == "spt") {
            if(props.panelKey==1){
                if (visibilityMatrix["prem"]) //net_doix_change
                    setHeight(28);
            
                else
                    setHeight(0)
            }else if(props.panelKey==2){
                if (visibilityMatrix["inv_bias"]) //net_doix_change
                    setHeight(28);
            
                else
                    setHeight(0)
            }
        } else if (props.plot == "all_doix") {
            if (visibilityMatrix["net_doix_change"]) //net_doix_change
                setHeight(28);
            else
                setHeight(0)
        }
    }, [visibilityMatrix, panelView1, panelView2, props.plot])


    return (
        <>
            <Rnd
                style={style}
                resizeHandleStyles={{
                    topRight: { cursor: 'default' },
                    bottomRight: { cursor: 'default' },
                    topLeft: { cursor: 'default' },
                    bottomLeft: { cursor: 'default' },
                    right: { cursor: 'default' },
                    left: { cursor: 'default' },
                    // top: {cursor: 'default'},
                    // bottom:{cursor: 'default'},

                }}
                size={{ width: width, height: height }}
                position={{ x: xCord, y: yCord }}
                onDragStop={(e, d) => {
                    //   this.setState({ xCord: d.x, yCord: d.y });
                    if (d.y >= props.top - 18 && d.y <= props.top + 5 + props.height - height) {
                        setXCord(d.x);
                        setYCord(d.y);
                    }
                    console.log("dragy=", d.y, props.top, props.height)
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    //   this.setState({
                    //     width: ref.style.width,
                    //     height: ref.style.height,
                    //     ...position
                    //   });
                    // setWidth(width);
                    console.log("width=", ref.style.width)
                    setWidth(ref.style.width);
                    setHeight(ref.style.height);
                    setXCord(position.x);
                    // setYCord(position.y);

                }}
                // minWidth={props.metaData.speedo&& props.chartWidth>490? 480:260}
                minWidth={width}
                maxWidth={width}
                // maxWidth={props.metaData.speedo&& props.chartWidth>490? 480:260}
                minHeight={height}
                maxHeight={height}
                bounds={props.boundClassName}
                cancel=".cancel"
            // disableDragging={true}
            >

                {expanded && props.data ?
                    <>
                        <Box sx={{ display: "flex", flexDirection: 'column', paddingRight: "4px", width: "100%", height: "100%", overflowY: 'auto', overflowX: 'hidden' }}>
                            <Box sx={{ display: "flex", flexDirection: 'column', pt: 0, minWidth: "400px" }}>


                                {props.plot == "spt" && props.panelKey==1 ?
                                    <Box sx={{ display: "flex", ml: 1 }}>
                                        <Box sx={{ display: "flex", width: "100%" }}>
                                            {(visibilityMatrix["prem"]) ?

                                                <Box sx={{ display: "flex" }} >
                                                    <Typography sx={{ fontSize: 12, ml: 0, mt: 0.3, minWidth: "16px", fontWeight: 500, color: visibilityMatrix["prem"] ? CHART_COLORS.MODAL_TEXT_ENABLED : CHART_COLORS.MODAL_TEXT_DISABLED }}>
                                                        Prem:&nbsp;
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 14, ml: 0, marginTop: "1px", fontWeight: 600, color: visibilityMatrix["prem"] == false ? CHART_COLORS.MODAL_TEXT_DISABLED : CHART_COLORS.LC_SPT_PREM_BAR }}>
                                                        {props?.data?.undy_prem ? props.data.undy_prem.toFixed(2) : "NA"}
                                                    </Typography>
                                                </Box>
                                                :
                                                <></>
                                            }
                                        </Box>
                                    </Box> :
                                    <></>
                                }

                                {props.plot == "spt" && props.panelKey == 2 ?
                                    <Box sx={{ display: "flex", ml: 1 }}>
                                        <Box sx={{ display: "flex", width: "100%" }}>
                                            {(visibilityMatrix["inv_bias"]) ?

                                                <Box sx={{ display: "flex" }} >
                                                    <Typography sx={{ fontSize: 12, ml: 0, mt: 0.3, minWidth: "16px", fontWeight: 500, color: visibilityMatrix["inv_bias"] ? CHART_COLORS.MODAL_TEXT_ENABLED : CHART_COLORS.MODAL_TEXT_DISABLED }}>
                                                        Inv Bias:&nbsp;
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 14, ml: 0, fontWeight: 600, color: visibilityMatrix["inv_bias"] == false ? CHART_COLORS.MODAL_TEXT_DISABLED : props?.data?.inv_bias >= 0 ? CHART_COLORS.CANDLE_BAR_POSITIVE : CHART_COLORS.CANDLE_BAR_NEGATIVE }}>
                                                    {props?.data?.inv_bias ? props.data.inv_bias : "NA"}
                                                    </Typography>
                                                </Box>
                                                :
                                                <></>
                                            }
                                        </Box>
                                    </Box> :
                                    <></>
                                }

                                {props.plot == "all_doix" ?
                                    <Box sx={{ display: "flex", ml: 1 }}>
                                        <Box sx={{ display: "flex", width: "100%" }}>
                                            {(visibilityMatrix["net_doix_change"]) ?

                                                <Box sx={{ display: "flex" }} >
                                                    <Typography sx={{ fontSize: 12, ml: 1, mt: 0.3, minWidth: "16px", fontWeight: 500, color: visibilityMatrix["net_doix_change"] ? CHART_COLORS.MODAL_TEXT_ENABLED : CHART_COLORS.MODAL_TEXT_DISABLED }}>
                                                        Net NTM dOI:&nbsp;
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 14, ml: 0, fontWeight: 600, color: visibilityMatrix["net_doix_change"] == false ? CHART_COLORS.MODAL_TEXT_DISABLED : props?.data?.net_doix_change >= 0 ? CHART_COLORS.CANDLE_BAR_POSITIVE : CHART_COLORS.CANDLE_BAR_NEGATIVE }}>


                                                        {props?.data?.net_doix_change ? props.data.net_doix_change.toFixed(2) : "NA"}
                                                    </Typography>
                                                </Box>
                                                :
                                                <></>
                                            }
                                        </Box>
                                    </Box> :
                                    <></>
                                }

                                {props.plot == "candle" ?
                                    <Box sx={{ display: "flex", ml: 1 }}>
                                        <Box sx={{ display: "flex", width: "100%" }}>
                                            {(!props.isOFSupported && props.straddleView==CANDLE_BOTTOM_VIEW_TYPE.VOLUME &&visibilityMatrix["vol"] ) ||(props.isOFSupported && props.panelKey == 1 && panelView1 == CANDLE_BOTTOM_VIEW_TYPE.VOLUME && visibilityMatrix["vol"]) || (props.isOFSupported && props.panelKey == 2 && panelView2 == CANDLE_BOTTOM_VIEW_TYPE.VOLUME && visibilityMatrix["vol"]) ?

                                                <Box sx={{ display: "flex" }} >
                                                    <Typography sx={{ fontSize: 12, ml: 0, mt: 0.3, minWidth: "16px", fontWeight: 500, color: visibilityMatrix["vol"] ? CHART_COLORS.MODAL_TEXT_ENABLED : CHART_COLORS.MODAL_TEXT_DISABLED }}>
                                                        Volume:&nbsp;
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 14, ml: 0, fontWeight: 600, color: visibilityMatrix["vol"] == false ? CHART_COLORS.MODAL_TEXT_DISABLED : CHART_COLORS.MODAL_TEXT_ENABLED }}>
                                                        {props?.data?.vol ? props.data.vol : "NA"}
                                                    </Typography>
                                                </Box>
                                                :
                                                <></>
                                            }


                                            {(props.isOFSupported && props.panelKey == 1 && panelView1 == CANDLE_BOTTOM_VIEW_TYPE.COT && visibilityMatrix["cot"]) || (props.isOFSupported && props.panelKey == 2 && panelView2 == CANDLE_BOTTOM_VIEW_TYPE.COT && visibilityMatrix["cot"]) ?
                                                <Box sx={{ display: "flex" }} >

                                                    <Typography sx={{ fontSize: 12, ml: 0, mt: 0.3, minWidth: "16px", fontWeight: 500, color: visibilityMatrix["cot"] ? CHART_COLORS.MODAL_TEXT_ENABLED : CHART_COLORS.MODAL_TEXT_DISABLED }}>
                                                        COT:&nbsp;
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 14, ml: 0, fontWeight: 600, color: visibilityMatrix["cot"] == false ? CHART_COLORS.MODAL_TEXT_DISABLED : props?.data?.cot >= 0 ? CHART_COLORS.CANDLE_BAR_POSITIVE : CHART_COLORS.CANDLE_BAR_NEGATIVE }}>
                                                        {props?.data?.cot ? props.data.cot : "NA"}
                                                    </Typography>
                                                </Box>
                                                :
                                                <></>
                                            }


                                            {(!props.isOFSupported && props.straddleView==CANDLE_BOTTOM_VIEW_TYPE.DOI &&visibilityMatrix["doi"] ) ||(props.isOFSupported && props.panelKey == 1 && panelView1 == CANDLE_BOTTOM_VIEW_TYPE.DOI && visibilityMatrix["doi"]) || (props.isOFSupported && props.panelKey == 2 && panelView2 == CANDLE_BOTTOM_VIEW_TYPE.DOI && visibilityMatrix["doi"]) ?

                                                <Box sx={{ display: "flex" }} >
                                                    <Typography sx={{ fontSize: 12, ml: 0, mt: 0.3, minWidth: "16px", fontWeight: 500, color: visibilityMatrix["doi"] ? CHART_COLORS.MODAL_TEXT_ENABLED : CHART_COLORS.MODAL_TEXT_DISABLED }}>
                                                        DOI:&nbsp;
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 14, ml: 0, fontWeight: 600, color: visibilityMatrix["doi"] == false ? CHART_COLORS.MODAL_TEXT_DISABLED : props?.data?.doi >= 0 ? CHART_COLORS.CANDLE_BAR_POSITIVE : CHART_COLORS.CANDLE_BAR_NEGATIVE }}>
                                                        {props?.data?.doi != undefined ? props.data.doi : "NA"}
                                                    </Typography>
                                                </Box>
                                                :
                                                <></>
                                            }

                                        </Box>

                                    </Box> :
                                    <></>
                                }

                                {props.isOFSupported && props.plot == "candle" && props.panelKey == 1 && panelView1 == CANDLE_BOTTOM_VIEW_TYPE.COT && visibilityMatrix["rolling_cot"] ?
                                    <Box sx={{ display: "flex", ml: 1 }}>
                                        <Box sx={{ display: "flex", width: "100%" }}>
                                            <Box sx={{ display: "flex" }} >
                                                <Typography sx={{ fontSize: 12, ml: 0, mt: 0.3, minWidth: "16px", fontWeight: 500, color: visibilityMatrix["rolling_cot"] ? CHART_COLORS.MODAL_TEXT_ENABLED : CHART_COLORS.MODAL_TEXT_DISABLED }}>
                                                    {/* {props?.userSettings?.data?.rotating_cot_candle} Bar COT:&nbsp; */}
                                                    {props?.cotCount} Bar COT:&nbsp;

                                                </Typography>
                                                <Typography sx={{ fontSize: 14, ml: 0, fontWeight: 600, color: visibilityMatrix["rolling_cot"] == false ? CHART_COLORS.MODAL_TEXT_DISABLED : props?.lastRollingCot >= 0 ? CHART_COLORS.CANDLE_BAR_POSITIVE : CHART_COLORS.CANDLE_BAR_NEGATIVE }}>
                                                    {props?.lastRollingCot ? props.lastRollingCot : "NA"}
                                                </Typography>
                                            </Box>


                                        </Box>
                                    </Box>
                                    :
                                    <></>
                                }

                                {props.isOFSupported && props.plot == "candle" && props.panelKey == 2 && panelView2 == CANDLE_BOTTOM_VIEW_TYPE.COT && visibilityMatrix["rolling_cot2"] ?
                                    <Box sx={{ display: "flex", ml: 1 }}>
                                        <Box sx={{ display: "flex", width: "100%" }}>
                                            <Box sx={{ display: "flex" }} >
                                                <Typography sx={{ fontSize: 12, ml: 0, mt: 0.3, minWidth: "16px", fontWeight: 500, color: visibilityMatrix["rolling_cot2"] ? CHART_COLORS.MODAL_TEXT_ENABLED : CHART_COLORS.MODAL_TEXT_DISABLED }}>
                                                    {props?.cotCount2} Bar COT:&nbsp;
                                                </Typography>
                                                <Typography sx={{ fontSize: 14, ml: 0, fontWeight: 600, color: visibilityMatrix["rolling_cot2"] == false ? CHART_COLORS.MODAL_TEXT_DISABLED : props?.lastRollingCot >= 0 ? CHART_COLORS.CANDLE_BAR_POSITIVE : CHART_COLORS.CANDLE_BAR_NEGATIVE }}>
                                                    {props?.lastRollingCot2 ? props.lastRollingCot2 : "NA"}
                                                </Typography>
                                            </Box>


                                        </Box>
                                    </Box>
                                    :
                                    <></>
                                }


                            </Box>


                        </Box>
                    </> : <></>
                }
            </Rnd>

        </>
    );
};

export default BPModal;
