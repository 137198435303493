import SearchIcon from "@mui/icons-material/Search"
import {
  FormControl,
  InputAdornment,
  ListSubheader,
  MenuItem,
  // Select,
  TextField,
} from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { Select,ConfigProvider } from 'antd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const { Option } = Select;

export default function InstrumentsDropdownLC({
  width,
  selectedInstrument,
  MenuProps,
  instrumentsData,
  instrumentChange,
}) {
  const [options, setOptions] = useState(instrumentsData) // filtered instrument data
  const [searchText, setSearchText] = useState("")
  const searchRef = useRef(searchText)
  const inputRef = useRef();
  const [currInstrument,setCurrInstrument]=useState(selectedInstrument);
  const selectRef = useRef();

  const containsText = (text, searchText) => {
    return text.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
  }

  useEffect(()=>{
    console.log("InstrumentsDropdown =",selectedInstrument)
  })
  const handleInstrumentChange = (event) => {
    let newSymbol
    newSymbol=event;
    // newSymbol = event.target.value
    // let instrument = undefined
    // for(let i=0;i<instrumentsData.length;i++){
    //   if(instrumentsData[i].instr==newSymbol){
    //     instrument=instrumentsData[i];
    //     if(!isStocksAllowed && instrument.resource && instrument.resource=="stocks"){
    //       openSunscriptionModal(true,TEXT_MSGS.MP_CHART_STOCK_RESOURCE_NOT_SUBSCRIBED_DESC);
    //       return;
    //     }
    //     setSelectedInstrumentData(instrument)
    //   }
    // }
    const newInstrumentIndex = instrumentsData.findIndex(
      (instrument) => instrument.instr === newSymbol
    )
    // const isSlowInstrumentSelected=checkIfSlowInstrument(event.target.value,slowInstruments);
    setCurrInstrument(instrumentsData[newInstrumentIndex].instr)
    instrumentChange(instrumentsData[newInstrumentIndex])
  }

  const autoFocus = () => {
    const timeout = setTimeout(() => {
      if (inputRef.current) inputRef.current.focus()
    }, 100)
  }

  useEffect(() => {
    searchRef.current = searchText
    let timeout
    if (instrumentsData != undefined) {
      if (searchText == "") {
        setOptions(instrumentsData)
        timeout = setTimeout(() => {
          if (inputRef.current) inputRef.current.focus()
        }, 100)
      } else {
        const temp = instrumentsData.filter((option) =>
          containsText(option, searchRef.current)
        )
        setOptions(temp)
      }
    }

    return () => clearTimeout(timeout)
  }, [searchText])

  return (
    <ConfigProvider
      theme={{
        // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        components: {
          
          Select: {
            colorPrimary: '#0d203f',
            colorBgContainer:"#0000ff",
            optionActiveBg:"#22314E",
            colorText:"#FFFFFF",
            optionSelectedBg:"#35445E",
            optionSelectedColor:"#FFFFFF",
            selectorBg:"#2f5cdb",
            colorTextPlaceholder:"#ffffff",
            colorIcon:"#ff0000",
            colorTextDisabled:"#899499"
            // algorithm: true, // Enable algorithm
          },
          
        },
      }}
    >
    {/* <FormControl size="small" variant="standard" sx={{ width: `${width}px` }}> */}
    <FormControl variant="standard" size="small" sx={{ minWidth:"80%" }}>
      {/* <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        sx={{ height: "70px" }}
        value={searchText == "" ? currInstrument : searchText}
        onChange={handleInstrumentChange}
        onOpen={autoFocus}
        label={selectedInstrument}
        MenuProps={MenuProps}
        onClose={() => setSearchText("")}
      >
        <ListSubheader>
          <TextField
            size="small"
            autoFocus
            inputRef={inputRef}
            variant="standard"
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchText(e.target.value)
              inputRef.current.focus()
            }}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation()
              }
            }}
          />
        </ListSubheader>
        {options?.map((item, index) => (
          <MenuItem
            key={item.instr}
            value={item.instr}
            onKeyDown={(e) => e.stopPropagation()}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select> */}
      <Select
                        variant="borderless"
                        suffixIcon={<ArrowDropDownIcon  style={{ color: 'white' }} />}
                        ref={selectRef}
                        showSearch
                        style={{
                          // width: 200,
                          // height:40,
                          // maxHeight:40,
                          // marginTop:0,
                          // color:"#FFFFFF",
                          // border: "1px solid #484848",
                          // borderRadius:"4px"
                          marginLeft:24,
                          height:24,
                          maxHeight:24,
                          // marginTop:-4,
                          color:"#FFFFFF"
                        }}
                       
                        placeholder="Search Instrument"
                        optionFilterProp="children"
                        defaultValue={"NIFTY"}
                        value={selectedInstrument}
                        dropdownStyle={{ backgroundColor: '#0d203f',color:'#00ff00',minWidth:"240px"}}
                        // onChange={handleSelectChange}
                        onChange={handleInstrumentChange}
                        onSearch={(value)=>setSearchText(value)}
                        // onDropdownVisibleChange={handleDropdownVisibleChange}
                        // filterOption={(input, option) => (option?.name ?? "").includes(input)}
                        // filterSort={(optionA, optionB) =>
                        //   (optionA?.children ?? '').toLowerCase().localeCompare((optionB?.children ?? '').toLowerCase())
                        // // console.log("option=== A B=",optionA,optionB,inputValue)
                        //   // optionA.children.toLowerCase().indexOf(inputValue.toLowerCase()) -
                        //   // optionB.children.toLowerCase().indexOf(inputValue.toLowerCase())
                        // }
                        filterOption={(input, option) => {
                          const words = input.trim().toLowerCase().split(' ');
                          return words.every(word => option.props.children.toLowerCase().includes(word));
                          
                          // return (
                          //   // option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          // );
                        }}
                      >
                      {options.map((item) => (
                        <Option key={item.instr} value={item.instr}  style={{fontSize:"12px"}}>

                          {item.name}
                        </Option>
                      ))}
                </Select> 
    </FormControl>
    </ConfigProvider>
  )
}
