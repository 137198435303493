import { useState,useRef } from 'react';
import useAxiosPrivate from './useAxiosPrivate';
/**
 * Hook for all the api calls to be made before user looged in to the home page i.e dashboard
 * @param {*} url 
 * @param {*} method 
 * @param {*} payload 
 * @returns 
 */

export const useAxiosWithAuth = (url, method, payload) => {
    const axiosPrivate=useAxiosPrivate();
    const [responseData, setResponseData] = useState(null);
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const controllerRef = useRef(new AbortController());
    
    //this will be used to abort the previous request if any
    const cancel = () => {
      controllerRef.current.abort();
    };
  
    const reset=()=>{
        setLoaded(false);
        setError(null);
        setResponseData(null);
    };

    const executeAPI = async(url,method,payload) => {
        try {
            // cancel();
            // controllerRef.current=new AbortController();
            const response = await axiosPrivate.request({
                data: payload,
                signal: controllerRef.current.signal,
                method,
                url,
              });
              console.log("useAxios success response = ",response.data);
              setResponseData(response.data);
        } catch (error) {
            console.log("useAxios hook, error response ",error);
            setError(error);
        } finally {
            setLoaded(true);
        }
    };
    
   return [ cancel,responseData, error, loaded,reset, executeAPI ];
  };