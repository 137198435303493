import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import LineChartHeader from './components/line_chart_header';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import StockChartContainer from './components/stock_chart_container';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS, CHART_RELOAD_TIME } from '../../common/utility/constant';
import { useQuery } from '../../common/utility/helperFunc';


const StockChart = () => {
  const [cancel, responseData, error, loaded, reset, executeAPI ] = useAxiosWithAuth();
  const { auth } = useAuth();

  const [state, setState] = useState({
    isLoading: true,
    updateData: undefined,
    chartData: undefined,
    isIncrementalData: false,
    lastLoadTime: -1,
    isInstrumentLive: false,
    reloadInerval: (auth.role.find(el => el == USER_ROLE_FIELDS.FAST_DATA)) ? CHART_RELOAD_TIME.FAST_DATA : CHART_RELOAD_TIME.SLOW_DATA
  });

  const { isLoading, chartData, updateData, isInstrumentLive, isIncrementalData, lastLoadTime, reloadInerval } = state;

  const type = useQuery("type");
  const symbol = useQuery("symbol");
  const startDate = useQuery("startDate");
  const nDays = useQuery("nDays");

  const [queryParams, setQueryParams] = useState({
    type: type ? type : "SINGLE",
    symbol: symbol ? symbol : "NIFTY",
    startDate: startDate ? startDate : "",
    nDays: nDays ? nDays : 0
  });

  console.log("queryparams in stock chart =  ", queryParams);

  //state ref is reuired to keep track of lastLoadTime value in the state.
  const stateRef = useRef(state);

  useEffect(() => {
    //set the ref to current state
    stateRef.current = state;
    const timeout = setInterval(() => {
      if (stateRef.current.isInstrumentLive) {
        executeAPI(URL.LINE_CHART_DATA + `?type=${queryParams.type}&symbol=${queryParams.symbol}&startDate=${queryParams.startDate}&nDays=${queryParams.nDays}&incremental=1&lastLoad=${stateRef.current.lastLoadTime}`, "GET", {});
      } else {
        console.log("instrument not live = ", stateRef.current.isInstrumentLive);
      }
    }, reloadInerval);

    return () => {
      // just to clear the timeout when component unmounts
      clearInterval(timeout);
    };
  }, [state]);

  //execute the data fetch on the component mount
  useEffect(() => {
    executeAPI(URL.LINE_CHART_DATA + `?type=${queryParams.type}&symbol=${queryParams.symbol}&startDate=${queryParams.startDate}&nDays=${queryParams.nDays}`, "GET", {});
  }, []);


  /**
 * API response handler  
 */
  useEffect(() => {
    if (loaded) {
      if (responseData != null) {
        if (responseData.chart_data !== undefined && responseData.chart_data.length > 0) {
          if (!isIncrementalData)
            setState((prevState) => ({
              ...prevState,
              isLoading: false, isIncrementalData: true, isInstrumentLive: responseData.isInstrumentLive, chartData: responseData.chart_data, lastLoadTime: responseData.last_ts
            }));
          else {
            setState((prevState) => ({
              ...prevState,
              updateData: responseData.chart_data, isInstrumentLive: responseData.isInstrumentLive, lastLoadTime: responseData.last_ts
            }));
          }
        }
      }
      else if (error !== null) {
        console.log("Error data=", error);
        setState((prevState) => ({
          ...prevState,
          isLoading: false
        }));
      }
      reset();
    }
  }, [loaded, responseData]);


  return (
    <>
      {/* <LineChartHeader/> */}
      {isLoading ? <div style={{ textAlign: "center", width: "100%", height: "100%" }}><CircularProgress sx={{ marginTop: "20%" }} /></div> :
        <StockChartContainer updateData={updateData} chartData={chartData} />
      }
    </>

  )
}

export default StockChart
