//OF WITH Live polling and panning issues
import React, { useEffect,useContext } from 'react'
import '../styles/styles.scss';
import { useState,useLayoutEffect,useRef,useMemo,useCallback } from 'react';
import * as d3 from 'd3';
import {CONSTANTS, SNACKBAR_AUTO_HIDE_DURATION_SHORT,ROTATING_COT_COUNT, USER_ROLE_FIELDS} from '../../../common/utility/constant.js';
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth.js';
import { URL } from '../../../common/api/urls.js';
import ContextMenu from './contextMenu.js';
import { useWindowSize } from '../../../common/hooks/useWindowSize.js';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION,AUTO_CENTER_CONFIG } from '../../../common/utility/constant.js';
import {Snackbar, Alert,CircularProgress, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import { TIME_FRAME_VALUES,OF_TIME_FRAME_VALUES,OF_HIGHLIGHT_CONSTANT,OF_ZOOM_TYPE } from '../../../common/utility/constant.js';
import { CHART_COLORS,DIMENSIONS ,CHART_FONT_DEFAULT,CHART_FONT_ITALIC_DEFAULT,CHART_FONT_BOLD_OF_VOL,CHART_FONT_OF_VOL_DEFAULT,OF_TABLE_FONT_DEFAULT, CC_CHART_LINE_WIDTH, OF_NPOC_MIN_PROFILE_DIFFERENCE, OF_MARKER_STYLING_CATEGORY, KRL_CATEOGRY_TYPE, CHART_FACTOR_CC, LC_CHART_LINE_WIDTH, LINE_DASHED_LIST, MAIN_PANEL_ON_BOTTOM_PANEL, CANDLE_BOTTOM_VIEW_TYPE, CHART_VISIBILITY_MATRIX, BOTTOM_PANEL_VIEWS, BOTTOM_PANEL_HEIGHT_VAL} from '../../../common/utility/mp_chart_constants.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { WATERMARK_CONFIG } from '../../../common/utility/constant.js';
import { UserSettingsContext } from '../../../setup/routes-manager/RequireAuth.js';
import ConfirmActionDialog from './confirm_dialog.js';
import { useInterval } from '../../../common/api/hooks/useInerval.js';
import Tooltip from './mptooltip.js';
import { findMaxInRangeGeneric, getBottomPanelDefaultHeight, getBottomPanelHeightName, getBottomPanelViewName, getRandomHexColor, rgbToHex } from '../../../common/utility/helperFunc.js';
import YAxisContextMenu from './mpYaxisContextMenu.js';
import dayjs from 'dayjs';
import OFHelpPopup from './orderflow_help_popup.js';
import CANDLEFABModal from './candle_fab_modal.js';
import CandleLiveModal from './candle_live_modal.js';
import { ToolbarFixed } from '../../../layouts/component.js/index.js';
import LCLiveModal from './lc_live_modal.js';
import LCFABModal from './lc_fab_modal.js';
import BPModal from './bottom_panel_modal.js';
import useAuth from '../../../common/hooks/useAuth.js';




const benchmarkingEnabled=process.env.NODE_ENV != "production"



const PROFILE_RELOAD_FACTOR=1;
const DEFAULT_PROFILE_WIDTH=60;
const DEFAULT_PROFILE_WIDTH_WEEKLY=100;
const DEFAULT_PROFILE_WIDTH_MONTHLY=120;
const DEFAULT_PROFILE_WIDTH_YEARLY=400;
const LIVE_PROFILE_MIN_WIDTH=200;
const TPO_WIDTH=14;
const TPO_HEIGHT=16;
const XSCALE_HEIGHT=24;
const YSCALE_WIDTH=60;
const PROFILE_LEFT_MARGIN=1;
const PROFILE_RIGHT_MARGIN=1;
const LOAD_MORE_PAN_PERCENT=0.8;  //i.e when 80% of total profile width reached, load more profiles
const LOAD_MORE_ZOOM_OUT_PERCENT=0.8; //i.e when 80% of zoom out value reached, load more profiles 
const ZOOM_OUT_EXTREME=0.3;
const ZOOM_OUT_EXTREME_LESS_PROFILE=0.4;
const CONTEXT_MENU_HEIGHT=200;
const CONTEXT_MENU_WIDTH=160;
const CHART_TOP_SPACE=104; //header+settings+xscale height
const PROFILE_MIN_WIDTH=4;
const CANDLEWIDTH=4;
const CANDLELINEWIDTH=1;
const PROFILE_LOADING_FACTOR_ZOOM_OUT=1;
const VOLUME_PROFILE_TPO_LIMIT=0.1; //recalculate vpList with this factor and vp_tpo
const TOOLTIP_HEIGHT=100;
const TOOLTIP_MENU_WIDTH=145;
const YSCALE_MAX_ZOOM=1.9;
const XSCALE_MAX_ZOOM=1.9;
const XSCALE_ZOOM_OUT_DISPLAY=0.55;
const YSCALE_ZOOM_OUT_DISPLAY=0.55;
const DEFAULT_LTP_POSITION=0.4;     //40% from the top
const LTP_POSITION_ALLOWED_CHANGE=0.2; //20% from top or bottom
// const BOTTOM_CONTAINER_HEIGHT=150;
const TPO_OFFSET=50;
const RIGHT_MARGIN_PX=20;
const SCALE_OFFSET=10;


function FuturesLineChartContainerNew (props) {
  // console.log("props in mp chart=",props);
  const { auth } = useAuth();
    const location = useLocation();
    const from = "/login";
    const navigate = useNavigate();
    const [isFirstLoad,setIsFirstLoad]=useState(true);
    const [chartMidTPO,setChartMidTPO]=useState(-1);
    const [isRecenterAllowed,setIsRecenterAllowed]=useState(true);
    

    const [historicalData, setHistoricalData] = useState();
    const historicalDataRef = useRef(historicalData);
    const [metaData, setMetaData] = useState();
    const [selectedInstrument,setSelectedInstrument]=useState(props.instrument)
    const selectedInstrumentRef=useRef(selectedInstrument)
    // const [selectedTPO,setSelectedTPO]=useState(props.tpo);
    const [selectedTPO,setSelectedTPO]=useState(1);
    const selectedTPORef=useRef(selectedTPO);
    const [isInstrumentLive,setInstrumentLive]=useState(props.isInstrumentLive)
    const [transformData,setTransformData]=useState({x:0,y:0,k:1});
    const transformDataRef=useRef(transformData);
    const [transformDataY,setTransformDataY]=useState({x:0,y:0,k:1});
    const transformDataRefY=useRef(transformDataY);
    const [transformDataAutoCenter,setTransformDataAutoCenter]=useState({x:0,y:0,k:1});
    const transformDataAutoCenterRef=useRef(transformDataAutoCenter);
    const [showContextMenu,setShowContextMenu]=useState(false);
    const [showTooltip,setShowTooltip]=useState(false);
    const [tooltipData,setTooltipData]=useState();
    const contextMenuRef=useRef(showContextMenu);
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [anchorPointTooltip, setAnchorPointTooltip] = useState({ x: 0, y: 0 });
    const [selectedProfiles,setSelectedProfiles]=useState([]);
    const [selectedProfilesIndex,setSelectedProfilesIndex]=useState([]);
    const [currentIndex,setCurrentIndex]=useState();
    const [isLoadingMoreProfiles,setIsLoadingMoreProfiles]=useState(false);
    const isLoadingMoreProfilesRef=useRef(isLoadingMoreProfiles);
    const [cancel,responseData, error, loaded, reset,executeAPI]  = useAxiosWithAuth();
    const windowSize=useWindowSize();
    const [toggleRepaint,setToggleRepaint]=useState(true);
    const [chartInterationAllowed,setChartInteractionAllowed]=useState(true);
    const chartInterationAllowedRef=useRef(chartInterationAllowed);
    const [canvasWidth,setCanvasWidth]=useState();
    const [refreshChart, setRefreshChart]=useState(false);
    const [crossHairCord, setCrossHairCord] = useState({ x: -1, y: -1,tpo:-1 });
    const [isMoreDataLoading,setIsMoreDataLoading]=useState(false);
    const [moreDataPresent,setMoreDataPresent]=useState(true);
    const [autoCenter,setAutocenter]=useState(props.autoCenter);
    const autoCenterRef=useRef(autoCenter);
    const [compositeData,setCompositeData]=useState({
      dstr: undefined,
      nDays:0 ,
      startDate:"",
      startIndex:-1,
      endIndex:-1,
      dstrMap:[],
      liveData:false,
      liveComposite:false
    });
    const [sessionActive,setSessionActive]=useState(props.isTradingSessionActive)
    const [liveDataComposite,setLiveComposite]=useState(false);
    const [bookmarkData,setBookmarkData]=useState(props.bookmarkData);
    const [selectedInstrumentData,setSelectedInstrumentData]=useState(props.selectedInstrumentData);
    const selectedInstrumentDataRef=useRef(selectedInstrumentData);
    const [selectedTimeFrame,setSelectedTimeFrame]=useState(props.selectedTimeFrame)
    const selectedTimeFrameRef=useRef(selectedTimeFrame);
    const globalVolumeVisibleRef=useRef();
    const volumeNumberVisibleRef=useRef();
    const profileVolumeNumberVisibleRef=useRef();
    const vpTypeRef=useRef();
    const tpoBasedVARef=useRef();
    const isTradingSessionActiveRef=useRef();
    const [anchorPointYAxis, setAnchorPointYAxis] = useState({ x: 0, y: 0 });
    const [showYAxisContextMenu,setShowYAxisContextMenu]=useState(false);
    const yaxiscontextMenuRef=useRef(showYAxisContextMenu);
    const [tableVisible,setTableVisible]=useState(props.bottomTableVisible);
    const [livePanelVisible,setLivePanelVisible]=useState(props.livePanelVisible);
    const [isZooming,setIsZooming]=useState(false);
    const [savedLiveData,setSavedLiveData]=useState();
    const savedLiveDataRef=useRef(savedLiveData);
    const { userSettings, setUserSettings } = useContext(UserSettingsContext);
    const [showHelpPopup,setShowHelpPopup]=useState(false)
    const [zoomType,setZoomType]=useState(OF_ZOOM_TYPE.SYMMETRIC);
    const [showLLTMarker,setShowLLTMarker]=useState(true);
    const [rotatingCot,setRotatingCot]=useState(undefined);
    const [candleData,setCandleData]=useState(undefined);
    const [dataLoaded,setDataLoaded]=useState(false);
    const dataLoadedRef=useRef(dataLoaded);
    // const BOTTOM_CONTAINER_HEIGHT=props.chartStateData.height/10;
    const [visibilityMatrix,setVisibilityMatrix]=useState(CHART_VISIBILITY_MATRIX);
    const [BOTTOM_CONTAINER_HEIGHT,setBottomContainerHeight]=useState(props.userSettings.data[getBottomPanelViewName(props.params.plot)]==BOTTOM_PANEL_VIEWS.HIDDEN?0:props.chartStateData.height*(props.userSettings.data[getBottomPanelHeightName(props.params.plot)]?props.userSettings.data[getBottomPanelHeightName(props.params.plot)]/100:getBottomPanelDefaultHeight(props.params.plot)/100));
    const [isNewCandleFormed,setIsNewCandleFormed]=useState(false);
    const isAdmin=(auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN))?true:false;
    const [BOTTOM_CONTAINER_HEIGHT2,setBottomContainerHeight2]=useState(isAdmin && props.params.plot=="spt"?props.chartStateData.height*BOTTOM_PANEL_HEIGHT_VAL["SPT_INV_BIAS"]/100:0);
   
   
    const fontFile = new FontFace(
      
      "Roboto Flex",
      
      'url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf9-EmF8tqHbE.woff2) format("woff2")',
      { stretch: "25% 151%" }
      
     
    );
  
   
    

  
    
    //margins for chart and axis
    const margin = {top: 24, right: 60, bottom: 20, left: 0};
   
    const [msgState, setMsgState] = useState({
      open: false,
      msg:"" ,
      severity:"info"
    });
  
    const { open, msg, severity } = msgState;

    const theme=useTheme();

    useEffect(()=>{
      document.fonts.add(fontFile);
      // if(props.changePlotType){
      //   console.log("Plottype in FUT container=",props.params.plot)
      //   props.changePlotType(props.params.plot);
      // }
      console.log("invtest height2=",BOTTOM_CONTAINER_HEIGHT2)
    },[])


    useEffect(()=>{
      dataLoadedRef.current=dataLoaded;
      // console.log("Ref for chart interaction val ",chartInterationAllowedRef.current)
    },[dataLoaded])

    useEffect(()=>{
      isLoadingMoreProfilesRef.current=isLoadingMoreProfiles;
      // console.log("Ref for chart interaction val ",chartInterationAllowedRef.current)
    },[isLoadingMoreProfiles])
    useEffect(()=>{
      setLivePanelVisible(props.livePanelVisible);
      // props.setShowLivePanel(false);
    },[props.livePanelVisible])


//    useEffect(()=>{
//     if(historicalData!=undefined && historicalData.profilesData!=undefined){
//       const reversedArray=historicalData.profilesData.slice().reverse();
     
//       setPriceTableData(reversedArray);
//       let count=0;
//       let sum=0;
    
//       for(let i=historicalData.config.data.length-1;i>=0;i--){
//         sum=sum+historicalData.config.data[i].cot;
//         count=count+1;
//          if(count==ROTATING_COT_COUNT){
//          break;
//         }
//       }
//       setRotatingCot(sum);
//       console.log("Price Reversed array=======>",historicalData,reversedArray,historicalData.profilesData[historicalData.profilesData.length-1],props.selectedInstrumentTPOData,sum);
//     }
//    },[historicalData])

    useEffect(()=>{
      //is trading session goes true to false then reload the live profile data as historical data
      console.log("trading session changed")
      if(sessionActive==true &&props.isTradingSessionActive==false){
       console.log("refresh chart is called session change")
        // setRefreshChart(true);
      }
     setSessionActive(props.isTradingSessionActive)
     
    },[props.isTradingSessionActive])


    useEffect(()=>{
      chartInterationAllowedRef.current=chartInterationAllowed;
      // console.log("Ref for chart interaction val ",chartInterationAllowedRef.current)
    },[chartInterationAllowed])

    useEffect(()=>{
      savedLiveDataRef.current=savedLiveData;
      // console.log("Ref for chart interaction val ",chartInterationAllowedRef.current)
    },[savedLiveData])

    useEffect(()=>{
      // bookmarkDataRef.current=bookmarkData;
    },[bookmarkData])

    useEffect(()=>{
      historicalDataRef.current=historicalData;
      
    },[historicalData])

    useEffect(()=>{
      selectedInstrumentRef.current=selectedInstrument;
    },[selectedInstrument])

    useEffect(()=>{
      selectedTPORef.current=selectedTPO;
    },[selectedTPO])
    
    useEffect(()=>{
      selectedTimeFrameRef.current=selectedTimeFrame;
    },[selectedTimeFrame])

    useEffect(()=>{
      globalVolumeVisibleRef.current=props.globalVolumeVisible;
    },[props.globalVolumeVisible])

    useEffect(()=>{
      volumeNumberVisibleRef.current=props.volumeNumberVisible;
    },[props.volumeNumberVisible])
    
    useEffect(()=>{
      profileVolumeNumberVisibleRef.current=props.profileVolumeNumberVisible;
    },[props.profileVolumeNumberVisible])

    useEffect(()=>{
      vpTypeRef.current=props.vpType;
    },[props.vpType])

    useEffect(()=>{
      tpoBasedVARef.current=props.tpoBasedVA;
    },[props.tpoBasedVA])

    useEffect(()=>{
      isTradingSessionActiveRef.current=props.isTradingSessionActive;
    },[props.isTradingSessionActive])

   
    useEffect(()=>{
      selectedInstrumentDataRef.current=selectedInstrumentData;
    },[selectedInstrumentData])

    useEffect(()=>{
      setSelectedInstrumentData(props.selectedInstrumentData);
    },[props.selectedInstrumentData])

    useEffect(()=>{
      autoCenterRef.current=props.autoCenter;
    },[props.autoCenter])

    useEffect(()=>{
      contextMenuRef.current=showContextMenu;
    },[showContextMenu])

    useEffect(()=>{
      yaxiscontextMenuRef.current=showYAxisContextMenu;
    },[showYAxisContextMenu])
   
    useEffect(()=>{
      transformDataRef.current=transformData;
      console.log("transformDataRef=",transformDataRef.current)
    },[transformData])

    useEffect(()=>{
      transformDataRefY.current=transformDataY;
      console.log("transformDataRef=",transformDataRef.current)
    },[transformDataY])
   
    useEffect(()=>{
      transformDataAutoCenterRef.current=transformDataAutoCenter;
      // console.log("transformDataRef=",transformDataRef.current)
    },[transformDataAutoCenter])
   
    useEffect(()=>{
      if(historicalData!=undefined){
      setTransformData({k:1,x:0,y:0});
      setTransformDataY({k:1,x:0,y:0});
      setToggleRepaint(!toggleRepaint);
      }
     
    },[props.resetChart])

    useEffect(()=>{
      // if(historicalData!=undefined && historicalData.profilesData!=undefined){
        console.log("OF Settings useEffect called OF chart",props.userSettings);
        if(props.userSettings.data[getBottomPanelViewName(props.params.plot)]==BOTTOM_PANEL_VIEWS.HIDDEN){
          setBottomContainerHeight(0);
          if(isAdmin && props.params.plot=="spt"){
            setBottomContainerHeight2(0)
          }
        }
        else{
          setBottomContainerHeight(props.chartStateData.height*(props.userSettings.data[getBottomPanelHeightName(props.params.plot)]?props.userSettings.data[getBottomPanelHeightName(props.params.plot)]/100:getBottomPanelDefaultHeight(props.params.plot)/100));
          if(isAdmin && props.params.plot=="spt"){
            setBottomContainerHeight2(props.chartStateData.height*BOTTOM_PANEL_HEIGHT_VAL["SPT_INV_BIAS"]/100)
          }
        }
        // if(isAdmin && props.params.plot=="spt"){
        //   setBottomContainerHeight2(props.chartStateData.height*BOTTOM_PANEL_HEIGHT_VAL["SPT_INV_BIAS"]/100)
        // }
       setToggleRepaint(!toggleRepaint);
      // }
     },[props.userSettings,props.chartStateData])
    
    //handler for TPO change     
   useLayoutEffect(()=>{
    if(props.tpo!=selectedTPO){
      console.log("OFSETTINGS tpo changed received by MP Chart")
      // setSelectedTPO(props.tpo);
    }
   },[props.tpo,props.chartData]);


   useLayoutEffect(()=>{
    if(historicalData!=undefined){
      const processedDataOF = parseOFData(props.chartData,true,undefined);
      // setIsRecenterAllowed(true);
      setHistoricalData(processedDataOF);
    }
   },[selectedTPO]);

   //handler for instrument change
   useLayoutEffect(()=>{
    console.log("Instrument changed received by MP Chart1")
     
    //reset the chart transform when we change the instrument
      if(selectedInstrument!=props.instrument){
      setTransformData({k:1,x:0,y:0});
      setTransformDataY({k:1,x:0,y:0})
      setCrossHairCord({x:-1,y:-1,tpo:-1});
      console.log("Live panel Instrument changed received by MP Chart2")
      console.log("parseData 3===============================================>")
      // const processedVPList=parseVPList(props.chartData);
      const processedData=parseOFData(props.chartData,true,undefined);
      if(props.chartData.mdata && props.chartData.mdata.length>0)    //only use for 1st container
        setMetaData(props.chartData.mdata[props.chartData.mdata.length-1]);
      
        const isLiveSession=( processedData.config.lastDataTime!=undefined && processedData.config.lastDataTime!=null && props.isTradingSessionActive)?true:false;
        // props.setRightContainerData(false,props.chartData.metadata,processedData,[processedData.profilesData.length-1],isLiveSession)



      // setProcessedVPList(processedVPList);
      setHistoricalData(processedData);
      
      setSelectedInstrument(props.instrument);
      }
   },[props.chartData,props.instrument])


   //handler for live data
  
   useLayoutEffect(()=>{
    if(props.liveData!=undefined && props.isPollingAllowed&& historicalData!=undefined && historicalData.profilesData!=undefined && chartInterationAllowed){
      if(!isZooming){
  //     // let processedVPList=parseVPList(props.liveData);
      
     
      
      
  //     //remove the duplicate live data is present and keep the latest one
  //     if(props.liveData.cc_data && props.liveData.cc_data[0]){
  //       let length=props.liveData.cc_data[0].length;
  //       props.liveData.cc_data[0].reduceRight((acc, obj, i) => {
  //         acc[obj.time]?  props.liveData.cc_data[0].splice(i, 1) : acc[obj.time] = true;
  //         return acc;
  //       }, Object.create(null));  

  //       if(length!=props.liveData.cc_data[0].length)
  //       console.log("ALERT OF Duplicate Data in Live OF detected");
  //   }

  //  let modified=false;
  //  let count=0;
  //   if(props.liveData.cc_data[0] && props.chartData.dateList[props.chartData.dateList.length-1]== props.liveData.dateList[0]){
  //     console.log("OF test live ",props.liveData.cc_data[0])
  //     for(let i=0;i< props.liveData.cc_data[0].length;i++){
  //       console.log("OF test live ",props.liveData.cc_data[0][i].time<props.chartData.cc_data[props.chartData.cc_data.length-1][props.chartData.cc_data[props.chartData.cc_data.length-1].length-1].time)
  //       if(props.liveData.cc_data[0][i].time< props.chartData.cc_data[props.chartData.cc_data.length-1][props.chartData.cc_data[props.chartData.cc_data.length-1].length-1].time){
  //         console.log("ALERT OF OLD Data in Live OF detected",i,props.liveData.cc_data[0],props.chartData.cc_data,props.liveData.cc_data[0][i].time,props.chartData.cc_data[props.chartData.cc_data.length-1][props.chartData.cc_data[props.chartData.cc_data.length-1].length-1].time);
  //         // props.liveData.cc_data[0].shift();
  //         count=count+1;
  //         modified=true;
  //       }
  //     }
  //   }

  //   if(modified)
  //   console.log("ALERT OF OLD Data in Live OF detected: Modified live data",props.liveData.cc_data[0]);

  //   let modObj = JSON.parse(JSON.stringify(props.liveData));
  //   if(count>0){
  //     console.log("ALERT OF ALERT  1 OF OLD Data in Live OF detected: count,modified data=",count,props.liveData,modObj);
  //   modObj.cc_data[0].splice(0,count);
  //   console.log("ALERT OF ALERT  2 OF OLD Data in Live OF detected: count,modified data=",count,props.liveData,modObj);
  //   }

    

      
  //     if(modObj.cc_data[0] && modObj.cc_data[0].length>0){
  //       // const processedLiveData=parseOFData(props.liveData,false,undefined,true);
  //       const processedLiveData=parseOFData(modObj,false,undefined,true);

  //       // if(props.liveData.metadata!=null & props.liveData.metadata!=undefined && props.key==0)///only use for 1st container
  //       setMetaData(props.liveData.metadata);

  //       console.log("live data received data=",props.liveData.cc_data[0],modObj);
  //       mergeLiveData(processedLiveData,isInstrumentLive,modObj);
  //       setChartInteractionAllowed(true);
  //       setInstrumentLive(true);
  //     }
        setSavedLiveData(undefined)
        console.log("CandleIssue OF Pan Test: livedata is drawing===>",props.liveData.cc_data[0].length,props.liveData.cc_data[0])
        if(props.liveData.cc_data[0] && props.liveData.cc_data[0].length>1)
          setIsNewCandleFormed(true)
        
        if(props.liveData.cc_data[0] && props.liveData.cc_data[0].length==1 && props.liveData.cc_data[0][0].time!=historicalData.config.data[historicalData.config.data.length-1].time)
          console.log("CandleIssue Time Mismatch Data Issue",props.liveData.cc_data[0][0],historicalData.config.data[historicalData.config.data.length-1])
        
        handleLiveData(props.liveData)
      }else{
        console.log("OF Pan Test: livedata is saved for future",props.liveData)
        if(savedLiveData==undefined)
          setSavedLiveData(props.liveData)
        else{
          let oldData= JSON.parse(JSON.stringify(savedLiveData));
          let newData=JSON.parse(JSON.stringify(props.liveData));
          newData.cc_data[0].forEach(newItem => {
            const index = oldData.cc_data[0].findIndex(savedItem => 
              savedItem.date === newItem.date && savedItem.time === newItem.time
            );
          
            if (index !== -1) {
              // Update existing entry
              console.log("CandleIssue updating existing entry=",oldData.cc_data[0][index],newItem)
              oldData.cc_data[0][index] = { ...oldData.cc_data[0][index], ...newItem };
            } else {
              // Append new entry
              console.log("CandleIssue append new data=",oldData.cc_data[0],newItem)
              oldData.cc_data[0].push(newItem);
            }
          });

          setSavedLiveData(oldData);
        }
        
      }
    }
   
   },[props.liveData])


   const handleLiveData=(liveData)=>{
    setSavedLiveData(undefined)
    if(liveData.cc_data && liveData.cc_data[0]){
      let length=liveData.cc_data[0].length;
      liveData.cc_data[0].reduceRight((acc, obj, i) => {
        acc[obj.time]?  liveData.cc_data[0].splice(i, 1) : acc[obj.time] = true;
        return acc;
      }, Object.create(null));  

      if(length!=liveData.cc_data[0].length)
      console.log("ALERT OF Duplicate Data in Live OF detected");
  }

 let modified=false;
 let count=0;
  if(liveData.cc_data[0] && props.chartData.dateList[props.chartData.dateList.length-1]== liveData.dateList[0]){
    console.log("OF test live ",liveData.cc_data[0])
    for(let i=0;i< liveData.cc_data[0].length;i++){
      console.log("OF test live ",liveData.cc_data[0][i].time<props.chartData.cc_data[props.chartData.cc_data.length-1][props.chartData.cc_data[props.chartData.cc_data.length-1].length-1].time)
      if(liveData.cc_data[0][i].time< props.chartData.cc_data[props.chartData.cc_data.length-1][props.chartData.cc_data[props.chartData.cc_data.length-1].length-1].time){
        console.log("ALERT OF OLD Data in Live OF detected",i,liveData.cc_data[0],props.chartData.cc_data,liveData.cc_data[0][i].time,props.chartData.cc_data[props.chartData.cc_data.length-1][props.chartData.cc_data[props.chartData.cc_data.length-1].length-1].time);
        // props.liveData.cc_data[0].shift();
        count=count+1;
        modified=true;
      }
    }
  }

  if(modified)
  console.log("ALERT OF OLD Data in Live OF detected: Modified live data",liveData.cc_data[0]);

  let modObj = JSON.parse(JSON.stringify(liveData));
  if(count>0){
    console.log("ALERT OF ALERT  1 OF OLD Data in Live OF detected: count,modified data=",count,liveData,modObj);
  modObj.cc_data[0].splice(0,count);
  console.log("ALERT OF ALERT  2 OF OLD Data in Live OF detected: count,modified data=",count,liveData,modObj);
  }

  

    
    if(modObj.cc_data[0] && modObj.cc_data[0].length>0){
      // const processedLiveData=parseOFData(props.liveData,false,undefined,true);
      const processedLiveData=parseOFData(modObj,false,undefined,true);

      // if(props.liveData.metadata!=null & props.liveData.metadata!=undefined && props.key==0)///only use for 1st container
      setMetaData(liveData.metadata);

      console.log("live data received data=",liveData.cc_data[0],modObj);
      mergeLiveData(processedLiveData,isInstrumentLive,modObj);
      setChartInteractionAllowed(true);
      setInstrumentLive(true);
    }
   }



  const parseOFData=(_data,isFirstLoad=false,processedVPList=undefined,isLive=false,isTPOChanged=false)=>{
    console.log("parseData= viewState=",props.viewState,_data);
    // console.log("processedVPList in parseData =",processedVPList);
        const profileData=[];
        let tempDateList=[];
        let tempTimeList=[];
        const config={
          totalWidth:0,  
          dateList:_data.dateList,
          max:Number.MIN_VALUE,
          min:Number.MAX_VALUE,
          lastDataTime:_data.lastDataTime,
          timeList:undefined,
          widthList:[],
          data:[],
          tpo:selectedTPO,
          maxVol:0,
          maxPrem:Number.MIN_VALUE,
          minPrem:Number.MAX_VALUE,
          maxInvBias:Number.MIN_VALUE,
          minInvBias:Number.MAX_VALUE
        }
     
      //   if(props.params.plot=="spt"){
      //  _data.cc_data =  _data.cc_data.map(innerArray =>
      //     innerArray.map(({ undy_open,undy_high,undy_low, undy_close, ...rest }) => ({
      //       open: undy_open,
      //     high: undy_high,
      //     low: undy_low,
      //     close: undy_close,
      //       ...rest,
      //     }))
      //   );
      //   console.log("UNDY Test =",_data.cc_data)
      // }
      // }

        let tempIndex=0;
        _data.cc_data.forEach((ofData, index) => {
          // if(index<3) return;
           // if(!(ofData[0].vbd!=null && ofData[0].vbd!=undefined && ofData[0].vbd.length>0)){         //TODO OF check if it required later
          //  if(ofData[0].vbd!=undefined && ofData[0].vbd.length>0 ){         //TODO OF check if it required later
         if(true){
          console.log("ofData val= ",index, ofData,ofData[0])
          let tempData=[];
         
          profileData[index]={
            date:_data.dateList[index],
            vbdData:[],
            showTPO:true,
            isTPOonVolume:false,
            isVolumeProfile:false,
            showOnlyVolumeProfile:false,
            isStacked:false,
            width:0,
            maxTPO:Number.MIN_VALUE,
            minTPO:Number.MAX_VALUE,
            count:0,
            profileIndex:index,
            totalVolume:0,
            startIndex:tempIndex,
            mdata:_data.mdata[index]
            
            
          }

          let divisionFactor=1;
          if(userSettings.data.data_in_contracts){
            if(!_data.ls[index])
            console.log("ALERT !!!!!   Lotsize issue, index, ls=",index,_data.ls[index],_data.ls[_data.ls.length-1])
            divisionFactor=_data.ls[index]?_data.ls[index]:_data.ls[_data.ls.length-1];
          }

          if(divisionFactor==undefined)
          divisionFactor=1;

          // console.log("test contracts =",props.contracts,_data.ls[index])
          
         

            tempDateList.push(_data.dateList[index])

            let count=0;
            let tempVolume=0;
            let lotSize=_data.ls[index]?_data.ls[index]:_data.ls[_data.ls.length-1];
            ofData.forEach((item, mainIndex) => {  
              //TODO: Testing purpose remove later
              // if(mainIndex>9) 
              //   return;

              if(item.open==0 && item.close==0 && item.high==0 && item.low==0)
              return;
              
              if(mainIndex!=0)
              tempTimeList.push(_data.dateList[index].substr(0, 5)+" "+item.time);
                // tempTimeList.push(item.time); 
              else
              tempTimeList.push(_data.dateList[index].substr(0, 5)+" "+item.time);
                // tempTimeList.push(_data.dateList[index]+" "+item.time);

              count=count+1;
              
            
         
          
         
         
              if(item.high!=null) config.max=Math.max(config.max,item.high);
              if(item.low!=null) config.min=Math.min(config.min,item.low);
          
            if(props.params.plot=="spt") {   //options strike spectrum
              if(item.gwall_high!=null) {
                config.max=Math.max(config.max,item.gwall_high);
              }
              
              if(item.gwall_low!=null) {
                config.min=Math.min(config.min,item.low);
              }

              if(item.undy_prem!=null) {
                config.maxPrem=Math.max(config.maxPrem,item.undy_prem);
              }
              
              if(item.undy_prem!=null) {
                config.minPrem=Math.min(config.minPrem,item.undy_prem);
              }
              if(item.inv_bias!=null) {
                config.maxInvBias=Math.max(config.maxInvBias,item.inv_bias);
              }
              
              if(item.inv_bias!=null) {
                config.minInvBias=Math.min(config.minInvBias,item.inv_bias);
              }
            }
          config.maxVol=Math.max(config.maxVol,Math.round(item.vol/divisionFactor));
            // console.log("isFirstload mainindex length = ",isFirstLoad,mainIndex,_data.dateList[mainIndex])
            //handling for pre open ticks
            let max=parseFloat(((item.high*10000)/10000).toFixed(4));
            let maxMod=parseFloat(((item.high*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let min=parseFloat(((item.low*10000)/10000).toFixed(4));
            let minMod=parseFloat(((item.low*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let maxTPO=parseFloat((max-maxMod).toFixed(4));
            let minTPO=parseFloat((min-minMod).toFixed(4));
            let open=parseFloat(((item.open*10000)/10000).toFixed(4));
            let openMod=parseFloat(((item.open*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let close=parseFloat(((item.close*10000)/10000).toFixed(4));
            let closeMod=parseFloat(((item.close*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let openTPO=parseFloat((open-openMod).toFixed(4));
            let closeTPO=parseFloat((close-closeMod).toFixed(4));
            
            let ibHigh=parseFloat(((item.ib_high*10000)/10000).toFixed(4));
            let ibHighMod=parseFloat(((item.ib_high*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let ibHighTPO=parseFloat(((ibHigh-ibHighMod)).toFixed(4));
           
            let ibLow=parseFloat(((item.ib_low*10000)/10000).toFixed(4));
            let ibLowMod=parseFloat(((item.ib_low*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let ibLowTPO=parseFloat((ibLow-ibLowMod).toFixed(4));
           
          

            let vpocTPO=undefined;
            if(item.vpoc!=undefined && item.vpoc!=null){
              let vpoc=parseFloat(((item.vpoc*10000)/10000).toFixed(4));
              let vpocMod=parseFloat(((item.vpoc*10000)%(selectedTPO*10000))/10000).toFixed(4);
              vpocTPO=parseFloat((vpoc-vpocMod).toFixed(4));
            }
            
            let vwapTPO=undefined;
            if(item.vwap!=undefined && item.vwap!=null){
              let vwap=parseFloat(((item.vwap*10000)/10000).toFixed(4));
              let vwapMod=parseFloat(((item.vwap*10000)%(selectedTPO*10000))/10000).toFixed(4);
              vwapTPO=parseFloat((vwap-vwapMod).toFixed(4));
            }
            
            let volume=Math.round(item.vol/divisionFactor)
            
            tempData[mainIndex]={
                maxTPO:maxTPO,
                minTPO:minTPO,
                data:[],
                divisionFactor:divisionFactor,
                // isStacked:false,
                // showTPO:true,
                date:_data.dateList[index],
                volumeData:[],
                high:maxTPO,
                low:minTPO,
                open:item.open,
                openTPO:openTPO,
                close:item.close,
                closeTPO:closeTPO,
                vwap:item.vwap,
                vpoc:item.vpoc,
                vwapTPO:vwapTPO,
                vpocTPO:vpocTPO,
                tpo:selectedTPO,
                oi:Math.round(item.oi/divisionFactor),
                vol:Math.round(item.vol/divisionFactor),
                selected_tf:props.selectedTimeFrame,
                highVal:item.high,
                lowVal:item.low,
                time:item.time,
                cumu_vpoc_vol:item.cumu_vpoc_vol,
                cumu_vol:item.cumu_vol,
                cumu_vpoc:item.cumu_vpoc,
                cumu_vwap:item.cumu_vwap,
                vpoc_vol:Math.round(item.vpoc_vol/divisionFactor),
                vwap_sd2_up:item.vwap_sd2_up,
                vwap_sd2_dn:item.vwap_sd2_dn,
                vwap_sd3_up:item.vwap_sd3_up,
                vwap_sd3_dn:item.vwap_sd3_dn,
                signal:item.signal,
                cot:Math.round(item.cot/divisionFactor),
                width:0,
                isStacked:false,
                // doi:(mainIndex==0?"NA":(Math.round(item.oi/divisionFactor)-ofData[mainIndex-1].oi)),
                doi:(item.doi?Math.round(item.doi/divisionFactor):"NA"),
                index:mainIndex,
                profileIndex:index,
                maxBuyVol:-1,
                maxSellVol:-1,
                high_range:item.high_range,
                low_range:item.low_range,
                gwall_high:item.gwall_high,
                gwall_low:item.gwall_low,
                gzero:item.gzero,
                upper_mviz:item.upper_mviz,
                lower_mviz:item.lower_mviz,
                undy_prem:item.undy_prem,
                inv_bias:item.inv_bias,
                mviz_level:item.mviz_level

          }
         
          
            tempData[mainIndex].stackedWidth=PROFILE_MIN_WIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN;
            tempData[mainIndex].splitWidth=PROFILE_MIN_WIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN;
            tempData[mainIndex].width=PROFILE_MIN_WIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN;
           

            config.totalWidth=config.totalWidth+ tempData[mainIndex].width;
            config.widthList.push(tempData[mainIndex].width);
            // console.log("vbddata",tempData,config.vbdData)
           
            profileData[index].vbdData=tempData;
            profileData[index].width=profileData[index].width+tempData[mainIndex].width;
            profileData[index].minTPO=Math.min(profileData[index].minTPO,tempData[mainIndex].minTPO);
            profileData[index].maxTPO=Math.max(profileData[index].maxTPO,tempData[mainIndex].maxTPO);
            profileData[index].count=count;
            profileData[index].totalVolume=tempVolume;
            config.data.push(tempData[mainIndex]);
          
           
            

          });
          console.log("parseofData=",tempIndex,count, profileData[index].tempIndex)
          tempIndex=tempIndex+ count;
          }
          // config.data=[...config.vbdData, ...profileData[index].vbdData];
        });

         
          
         

          config.dateList=tempDateList;
          config.timeList=tempTimeList;
          
          // console.log("tempDateList = ",tempDateList);
        
        // return profileData;
        // console.log("parseOFData: profileData=",profileData,config, config.timeList);
        console.log("SPTD3 Processed Data",profileData,config,config.min,config.max);
        if(props.params.plot=="spt")
        config.data=processConfigDataSpot(config.data);
        console.log("SPTD3 Processed Data",profileData,config,config.min,config.max);
        return {
          profilesData:profileData,
          config:config,
          
        }

    }  

    const processConfigDataSpot=(_data)=>{
      let data=_data;
      let firstIndex=0;
      // let maxPrem=Number.MIN_VALUE;
      // let minPrem=Number.MAX_VALUE;
      //process the prem
      for(let i=0;i<data.length;i++){
        if(data[i].undy_prem && data[i].undy_prem!=0){
          firstIndex=i;
          break;
        }
      }
      for(let i=firstIndex;i<data.length;i++){
        if(i==0)
          continue;
        else if(data[i].undy_prem==undefined || data[i].undy_prem==null || data[i].undy_prem==0){
         data[i].undy_prem=data[i-1].undy_prem;
        }
       
       
      }

      //process the gwall_high
      firstIndex=0;
      for(let i=0;i<data.length;i++){
        if(data[i].gwall_high && data[i].gwall_high!=0){
          firstIndex=i;
          break;
        }
      }
      for(let i=firstIndex;i<data.length;i++){
        if(i==0)
          continue;
        else if(data[i].gwall_high==undefined || data[i].gwall_high==null || data[i].gwall_high==0){
         data[i].gwall_high=data[i-1].gwall_high;
        }
      }

        //process the gwall_low
          firstIndex=0;
          for(let i=0;i<data.length;i++){
            if(data[i].gwall_low && data[i].gwall_low!=0){
              firstIndex=i;
              break;
            }
          }
          for(let i=firstIndex;i<data.length;i++){
            if(i==0)
              continue;
            else if(data[i].gwall_low==undefined || data[i].gwall_low==null || data[i].gwall_low==0){
             data[i].gwall_low=data[i-1].gwall_low;
            }
          }
        
      //process the upper_mviz
      firstIndex=0;
      for(let i=0;i<data.length;i++){
        if(data[i].upper_mviz && data[i].upper_mviz!=0){
          firstIndex=i;
          break;
        }
      }
      for(let i=firstIndex;i<data.length;i++){
        if(i==0)
          continue;
        else if(data[i].upper_mviz==undefined || data[i].upper_mviz==null || data[i].upper_mviz==0){
         data[i].upper_mviz=data[i-1].upper_mviz;
        }
      }

    //process the lower_mviz
    firstIndex=0;
    for(let i=0;i<data.length;i++){
      if(data[i].lower_mviz && data[i].lower_mviz!=0){
        firstIndex=i;
        break;
      }
    }
    for(let i=firstIndex;i<data.length;i++){
      if(i==0)
        continue;
      else if(data[i].lower_mviz==undefined || data[i].lower_mviz==null || data[i].lower_mviz==0){
      data[i].lower_mviz=data[i-1].lower_mviz;
      }
    }

    //process the mviz_level
    if(isAdmin){
    firstIndex=0;
    for(let i=0;i<data.length;i++){
      if(data[i].mviz_level && data[i].mviz_level!=0){
        firstIndex=i;
        break;
      }
    }
    for(let i=firstIndex;i<data.length;i++){
      if(i==0)
        continue;
      else if(data[i].mviz_level==undefined || data[i].mviz_level==null || data[i].mviz_level==0){
      data[i].mviz_level=data[i-1].mviz_level;
      }
    }
  }
        //process the gzero
          firstIndex=0;
          for(let i=0;i<data.length;i++){
            if(data[i].gzero && data[i].gzero!=0){
              firstIndex=i;
              break;
            }
          }
          for(let i=firstIndex;i<data.length;i++){
            if(i==0)
              continue;
            else if(data[i].gzero==undefined || data[i].gzero==null || data[i].gzero==0){
             data[i].gzero=data[i-1].gzero;
            }
          }
     


      console.log("FUTD3 configdata=",data);
      return data;

    }

    
  
  //processes the api response data 
 

    //merges the profiles when more data is loaded while zooming out or panning
    const mergeProfileData=(_data)=>{
      console.log("More data before width=",historicalData.config.totalWidth);

      for(let i=0;i<=historicalData.config.data.length-1;i++){
        historicalData.config.data[i].profileIndex= historicalData.config.data[i].profileIndex+_data.profilesData.length;
        // historicalData.config.data[i].index=historicalData.config.data[i].index+_data.config.data.length;
    }

    for(let i=0;i<historicalData.profilesData.length;i++ ){
      historicalData.profilesData[i].startIndex=historicalData.profilesData[i].startIndex+_data.config.data.length;
    }
      
      const mergedConfig={
        totalWidth:_data.config.totalWidth+historicalData.config.totalWidth,  
        dateList:(_data.config.dateList.concat(historicalData.config.dateList)),
        max:Math.max(_data.config.max,historicalData.config.max),
        min:Math.min(_data.config.min,historicalData.config.min),
        lastDataTime:historicalData.config.lastDataTime,
        timeList:(_data.config.timeList.concat(historicalData.config.timeList)),
        widthList:(_data.config.widthList.concat(historicalData.config.widthList)),
        data:(_data.config.data.concat(historicalData.config.data)),
        tpo:selectedTPO,
        maxVol:Math.max(_data.config.maxVol,historicalData.config.maxVol),
        maxPrem:Math.max(_data.config.maxPrem,historicalData.config.maxPrem),
        minPrem:Math.max(_data.config.minPrem,historicalData.config.minPrem),

      }
      
      console.log("More data after width=",mergedConfig.totalWidth);
      // console.log("Merged config object = ",mergedConfig);
      const mergedProfilesData=_data.profilesData.concat(historicalData.profilesData);
      // console.log("merged profiles data = ",mergedProfilesData);

      // if(props.params.plot=="spt")
      //   mergedConfig.data=processConfigDataSpot(mergedConfig.data);

      if(props.params.plot=="spt")
        mergedConfig.data=processConfigDataSpot(mergedConfig.data);

      const mergedProcessedData= {
        profilesData:mergedProfilesData,
        config:mergedConfig
      }
      console.log("Mergeprofile mergedProcessedData=",mergedProcessedData);
      updateSelectedProfileIndexes(_data.profilesData.length);
      setHistoricalData(mergedProcessedData);
    }

    //update the previously selected index when more profiles is loaded
    const updateSelectedProfileIndexes=(updateVal)=>{
      if(selectedProfilesIndex.length>0){
        const updateIndexes = selectedProfilesIndex.map(index => index + updateVal);
        setSelectedProfilesIndex(updateIndexes);
      }
    }


        //merge the live data with existing profiles
        const mergeLiveData=(_data,isLive,liveData)=>{
          // console.log("mergelivedata",_data,historicalData)
          if(_data!=undefined && historicalData!=undefined){
          let mergedConfig={};
          let mergedLiveData={};
          //if instrument is already live or date already exist(incase tradingsession active and instrumnet not live)
          //  then update the last profile with latest live data
          if(_data.profilesData[0].date==historicalData.profilesData[historicalData.profilesData.length-1].date)
          console.log("Date matches for last profile and live profile");
    
          if(liveData && (isInstrumentLive || _data.profilesData[0].date==historicalData.profilesData[historicalData.profilesData.length-1].date)){
            
            console.log("mergelivedata livedata merging",)
            const prevLiveProfile=historicalData.profilesData[historicalData.profilesData.length-1];
            let previousWidth=prevLiveProfile.width;
            let currWidth=_data.profilesData[0].width;
    
            console.log("mergelivedata",_data,historicalData,prevLiveProfile)
            
            //merge chart data in case of PPR change
            let modLiveData=props.chartData.cc_data.pop();
            modLiveData.pop();  //remove last OF data from the live data
            modLiveData=[...modLiveData, ...liveData.cc_data[0]]  //merge existing live data and new live data
            props.chartData.cc_data.push(modLiveData);
           
            
    
            // console.log("Prev life profile found =",prevLiveProfile);
            historicalData.config.max=Math.max(historicalData.config.max,prevLiveProfile.maxTPO);
            historicalData.config.min=Math.min(historicalData.config.min,prevLiveProfile.minTPO);
            _data.profilesData[0].maxTPO=Math.max(_data.profilesData[0].maxTPO,prevLiveProfile.maxTPO)
            _data.profilesData[0].minTPO=Math.min(_data.profilesData[0].minTPO,prevLiveProfile.minTPO)
            historicalData.config.dateList.pop();
            historicalData.config.timeList.pop();
            historicalData.config.widthList.pop();
            let lastData=historicalData.config.data.pop();
           
            // console.log("dateList after pop live date",historicalData.config.dateList)  
            
            console.log("MergeLive::Total width before=============>",historicalData.config.totalWidth,_data.profilesData[0].width,prevLiveProfile.stackedWidth,prevLiveProfile.width,prevLiveProfile.isStacked)
            console.log("MergeLive::Total width before 2=============>",lastData,historicalData.config.totalWidth,currWidth,historicalData.config.widthList,historicalData.config.widthList[historicalData.config.widthList.length-1])
            //remove live data and its width
            // mergedConfig={
            //   totalWidth:historicalData.config.totalWidth+currWidth-previousWidth,
            //   dateList:(historicalData.config.dateList.concat(_data.config.dateList)),
            //   max:Math.max(_data.config.max,historicalData.config.max),
            //   min:Math.min(_data.config.min,historicalData.config.min),
            //   lastDataTime:_data.config.lastDataTime
            // }
            console.log("Lastdata val=",lastData);
            let startIndex=lastData.index;
            for(let i=0;i<=_data.config.data.length-1;i++){
              _data.config.data[i].index=startIndex;
              _data.config.data[i].profileIndex=lastData.profileIndex;
              startIndex=startIndex+1;
    
            }
            _data.profilesData[0].startIndex= historicalData.profilesData[ historicalData.profilesData.length-1].startIndex;
            _data.profilesData[0].totalVolume= _data.profilesData[0].totalVolume+historicalData.profilesData[ historicalData.profilesData.length-1].totalVolume;
    
            // console.log("doi =", historicalData.profilesData,prevLiveProfile, historicalData.profilesData[historicalData.profilesData.length-1].count,_data.config.data[0].doi,historicalData.config.data[historicalData.config.data.length-1].oi)
            // if(prevLiveProfile.count>1){
            //   _data.config.data[0].doi= _data.config.data[0].oi-historicalData.config.data[historicalData.config.data.length-1].oi;
            // }
            // console.log("doi after= ",historicalData.profilesData[historicalData.profilesData.length-1].count,_data.config.data[0].doi,historicalData.config.data[historicalData.config.data.length-1].oi)
           
    
            // _data.config.data[i].doi=_data.config.data[i].oi-
            console.log("MergeLive::Total width before 3=============>",_data,lastData,lastData.index,lastData.profileIndex)
    
             mergedConfig={
              totalWidth:(historicalData.config.totalWidth+currWidth-historicalData.config.widthList[historicalData.config.widthList.length-2]), 
              dateList:(historicalData.config.dateList.concat(_data.config.dateList)),
              max:Math.max(_data.config.max,historicalData.config.max),
              min:Math.min(_data.config.min,historicalData.config.min),
              lastDataTime:_data.config.lastDataTime,
              timeList:(historicalData.config.timeList.concat(_data.config.timeList)),
              widthList:(historicalData.config.widthList.concat(_data.config.widthList)),
              data:(historicalData.config.data.concat(_data.config.data)),
              tpo:selectedTPO,
              maxVol:Math.max(_data.config.maxVol,historicalData.config.maxVol),
              maxPrem:Math.max(_data.config.maxPrem,historicalData.config.maxPrem),
              minPrem:Math.max(_data.config.minPrem,historicalData.config.minPrem),
            }
            console.log("MergeLive:Total width after=============>",mergedConfig.totalWidth)
            // console.log("live mergedconfig 1= ",mergedConfig,_data.config,historicalData.config.totalWidth,prevLiveProfile);
          
            
            //  console.log("Live data after modification = ",_data);
            console.log("Dailymarker",historicalData.profilesData[historicalData.profilesData.length-1],_data.profilesData[0].count,historicalData.profilesData[historicalData.profilesData.length-1].count)
            _data.profilesData[0].count=_data.profilesData[0].count+historicalData.profilesData[historicalData.profilesData.length-1].count-1;
            historicalData.profilesData.pop();
            historicalData.profilesData.push(_data.profilesData[0]);
           
            if(props.params.plot=="spt")
              mergedConfig.data=processConfigDataSpot(mergedConfig.data);

             mergedLiveData= {
              profilesData:historicalData.profilesData,
              config:mergedConfig
            }
            console.log("mergelivedata live merged data= ",mergedLiveData)
            // props.setRightContainerData(false,props.liveData.metadata,mergedLiveData,[mergedLiveData.profilesData.length-1],true)
            setHistoricalData(mergedLiveData);
            setMetaData(props.liveData.metadata);
          }
          //1st time live data received
          else{
            // _data.profilesData[0].isStacked=false;
    
            let currWidth=_data.profilesData[0].width;
    
            //update indexes and profile indexes accordingly
            console.log("mergelivedata 1st time live mergedconfig last data index=",historicalData.config.data[historicalData.config.data.length-1],historicalData.config.data[historicalData.config.data.length-1].profileIndex+1)
           let startIndex=historicalData.config.data[historicalData.config.data.length-1].index+1;
           _data.profilesData[0].profileIndex=historicalData.config.data[historicalData.config.data.length-1].profileIndex+1;
           _data.profilesData[0].startIndex=startIndex;
            
           for(let i=0;i<=_data.config.data.length-1;i++){
              _data.config.data[i].index=startIndex;
              _data.config.data[i].profileIndex=historicalData.config.data[historicalData.config.data.length-1].profileIndex+1;
              startIndex=startIndex+1;
           }
    
          //   for(let i=0;i<=historicalData.config.data.length-1;i++){
          //      historicalData.config.data[i].profileIndex= historicalData.config.data[i].profileIndex-1;
          //  }
    
            mergedConfig={
              totalWidth:currWidth+historicalData.config.totalWidth,  
              dateList:(historicalData.config.dateList.concat(_data.config.dateList)),
              max:Math.max(_data.config.max,historicalData.config.max),
              min:Math.min(_data.config.min,historicalData.config.min),
              lastDataTime:_data.config.lastDataTime,
              timeList:(historicalData.config.timeList.concat(_data.config.timeList)),
              widthList:(historicalData.config.widthList.concat(_data.config.widthList)),
              data:(historicalData.config.data.concat(_data.config.data)),
              tpo:selectedTPO,
              maxVol:Math.max(_data.config.maxVol,historicalData.config.maxVol),
              maxPrem:Math.max(_data.config.maxPrem,historicalData.config.maxPrem),
              minPrem:Math.max(_data.config.minPrem,historicalData.config.minPrem),
            }
              console.log("mergelivedata 1st time live mergedconfig = ",mergedConfig)
     
             
            historicalData.profilesData.push(_data.profilesData[0]);
            if(props.chartData && props.chartData.dateList  && props.chartData.dateList[props.chartData.dateList.length-1]!=props.liveData.dateList[props.liveData.dateList.length-1] ) {
              props.chartData.dateList=props.chartData.dateList.concat(props.liveData.dateList)
              props.chartData.cc_data=props.chartData.cc_data.concat(liveData.cc_data)
              props.chartData.ls=props.chartData.ls.concat(props.liveData.ls)
              props.chartData.mdata=props.chartData.mdata.concat(props.liveData.mdata)
              console.log("PPR issue mergelive 1st time=",props.chartData)
            }  

            if(props.params.plot=="spt")
              mergedConfig.data=processConfigDataSpot(mergedConfig.data);

            mergedLiveData= {
              profilesData:historicalData.profilesData,
              config:mergedConfig
            }
            // console.log("megedlivedata = ",mergedLiveData)
            console.log("mergelivedata 1st time live mergedconfig = ",mergedLiveData)
            // props.setRightContainerData(false,props.liveData.metadata,mergedLiveData,[mergedLiveData.profilesData.length-1],true)
            setHistoricalData(mergedLiveData);
            setMetaData(props.liveData.metadata);
          }
        }
        }
    

   
  //1st loading of chart
    useEffect(()=>{
      // console.log("1st load OF chart =======================>parseData",props.chartData,props.chartData.metadata);
    //   setMetaData(props.chartData.mdata[props.chartData.mdata.length-1]);
        const processedDataOF = parseOFData(props.chartData,true,undefined);
       
           setHistoricalData(processedDataOF);
    },[]);

  //handles loading of more profiles data in case of zooming out or panning extremes reached
  useEffect(()=>{
    if(isLoadingMoreProfiles){
      if(props.isPollingAllowed)
      props.setPollingAllowedVal(false);
      // let nprof=1;
      // if(props.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_30_min){
      //   nprof=4;
       
      // } 
      
      let nprof=2;//TODO: testing... set it to one in prod
      if(props.params.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_30_min){
        nprof=15;
      }else if(props.params.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_15_min){
        nprof=10;
      }
      else if(props.params.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_5_min){
        nprof=8;
      }
      else if(props.params.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_3_min){
        nprof=5;
      }
      else{
        nprof=2;
      }  
   
    
    let data={
      "symbol": props.selectedInstrument,
      "type":props.params.type,
      "plot":props.params.plot,
      "nDays": 0,
      "nProf": nprof,
      "liveData": false,
      "liveOnly": false,
      "startDate": "",
      "endDate": historicalData.profilesData[0].date,
      // "tf": "1",
      "tf": props.params.selectedTimeFrame,
    }
    
    setIsMoreDataLoading(true);
    // setChartInteractionAllowed(false);

    executeAPI(URL.CANDLE_CHART,"POST",data); 
  }
  },[isLoadingMoreProfiles]);

  useEffect(() => {
    // setHistoricalData(undefined);
    setSelectedTimeFrame(props.selectedTimeFrame)
  },[props.selectedTimeFrame]  )

  /**
  * API response handler for load more data  
  */  
    useEffect(() => {
      if(loaded){
        // console.log("More data loaded=",responseData);
        if(responseData!=null){
          if(responseData.cc_data!==undefined && responseData.cc_data.length>0){
                
                // console.log("More data loaded modified=",responseData);
                if(responseData.cc_data!=undefined && responseData.cc_data.length==0){
                  setMoreDataPresent(false);
                  props.setChartRenderedState(true);
                }
                //merge with existing chart data
                if(props.chartData!=undefined && responseData.dateList!=undefined && responseData.dateList.length>0){
                  // console.log("More data:date List before concat=",props.chartData.dateList);
                  props.chartData.dateList=responseData.dateList.concat(props.chartData.dateList)
                  props.chartData.cc_data=responseData.cc_data.concat(props.chartData.cc_data)
                  props.chartData.ls=responseData.ls.concat(props.chartData.ls)
                  props.chartData.mdata=responseData.mdata.concat(props.chartData.mdata)
                  // console.log("Load more merge data",props.chartData);
                  // console.log("More data:date List after concat=",props.chartData.dateList);              
                }
                
                const processedDataOF = parseOFData(responseData,false,undefined);
                mergeProfileData(processedDataOF);
                setIsMoreDataLoading(false);
               
                props.setPollingAllowedVal(true);
                
             
              }else{
                setMoreDataPresent(false);
                props.setChartRenderedState(true);
              }
              if(responseData.upgrade_notify!=undefined && responseData.upgrade_notify){
                props.openSubscribeModal();
              }
              setIsMoreDataLoading(false);
              setChartInteractionAllowed(true);
              setIsLoadingMoreProfiles(false);
              props.setPollingAllowedVal(true);
        }
        else if(error!==null){
              props.setPollingAllowedVal(true);
              console.log("Error data=",error);
              setIsMoreDataLoading(false);
              setChartInteractionAllowed(true);
              props.setChartRenderedState(true);
              setIsLoadingMoreProfiles(false);
         
          
          if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            // navigate(from, { replace: true });
            if(props.showLoginPopup){
              // console.log("TEST LOGIN chart container 2")
              props.showLoginPopup(true, error?.response?.data?.message);
            }
          }else 
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
        reset();
      }
      },[loaded,responseData]);
  
    //save the existing transform(zoom, x,y pan values and trigger load more profiles)
    const loadMoreData=(transform)=>{
        // return;
      // setTransformData(transform);
      // return;
      if(moreDataPresent){
        // setChartInteractionAllowed(false);
        setIsLoadingMoreProfiles(true);
      }
    }
 
    //Search the profile based on mouse clicked point
    const binarySearch=(sortedArray, key)=>{
      let start = 0;
      let end = sortedArray.length - 1;
  
      while (start <= end) {
          let middle = Math.floor((start + end) / 2);
  
          if (sortedArray[middle] === key) {
              // found the key
              return middle;
          } else if (sortedArray[middle] < key) {
              // continue searching to the right
              start = middle + 1;
          } else {
              // search searching to the left
              end = middle - 1;
          }
      }
    
      return start-1;
    }

    //opens the context menu for the profiles
    const openContextMenu=(xCord,yCord,selectedIndex,canvasWidth,canvasHeight)=>{
      if(selectedIndex!=undefined && selectedIndex>=0 && selectedIndex<historicalData.profilesData.length){
      setShowContextMenu(false);
      setShowTooltip(false);
      if(historicalData!=undefined){
      //   console.log("selectedprofilesIndex =",selectedProfilesIndex);
      //   let tempIndex=[...selectedProfilesIndex];
      //   tempIndex.push(selectedIndex);
      //   console.log("temp array =",tempIndex);
      // // setSelectedProfilesIndex(tempIndex);
      // setSelectedProfilesIndex(oldArray => [...oldArray, selectedIndex]);

      // let tempProfiles=[...selectedProfiles];
      // console.log("selectedProfiles =",selectedProfiles);
      // tempProfiles.push(historicalData.profilesData[selectedIndex])
      // console.log("tempProfiles =",tempProfiles);
      
      // setSelectedProfiles(tempProfiles);
      setCurrentIndex(selectedIndex);
      //modify x and y cord for handling context menu at the extremes
      if(xCord+CONTEXT_MENU_WIDTH>canvasWidth)
        xCord=xCord-CONTEXT_MENU_WIDTH;
      if(yCord+CONTEXT_MENU_HEIGHT>canvasHeight+CHART_TOP_SPACE)
        yCord=yCord-CONTEXT_MENU_HEIGHT;
      
      setAnchorPoint({ x: xCord, y: yCord });
      setShowTooltip(false); 
      setShowContextMenu(true);  
      }
    }

    }

    const openTooltip=(xCord,yCord,canvasWidth,canvasHeight)=>{
    
      setShowTooltip(false);
     
      //modify x and y cord for handling context menu at the extremes
      if(xCord+TOOLTIP_MENU_WIDTH>canvasWidth)
        xCord=xCord-TOOLTIP_MENU_WIDTH;
      if(yCord+TOOLTIP_HEIGHT>canvasHeight+CHART_TOP_SPACE)
        yCord=yCord-TOOLTIP_HEIGHT;
      
      setAnchorPointTooltip({ x: xCord, y: yCord });
      setShowContextMenu(false); 
      setShowTooltip(true);  
    }

    const openYAxisContextMenu=(xCord,yCord,canvasWidth,canvasHeight)=>{
    
      setShowYAxisContextMenu(false);
     
      //modify x and y cord for handling context menu at the extremes
      if(xCord+TOOLTIP_MENU_WIDTH>canvasWidth)
        xCord=xCord-TOOLTIP_MENU_WIDTH;
      if(yCord+TOOLTIP_HEIGHT>canvasHeight+CHART_TOP_SPACE)
        yCord=yCord-TOOLTIP_HEIGHT;
      
      setAnchorPointYAxis({ x: xCord, y: yCord });
      setShowContextMenu(false); 
      setShowTooltip(false); 
     setShowYAxisContextMenu(true);
    
    }

 

  

   /**
   * Gets the volume profiles for the visible profiles
   */
   const getOFVolumeProfilesInRange=(leftIndex,rightIndex,startIndex)=>{
    //get the max and min tpo for the profile range
    let totalVolume=0;
    let maxTPO=Number.NEGATIVE_INFINITY;
    let minTPO=Number.POSITIVE_INFINITY;
    let maxPerc=0;
    let currTPO=historicalData.config.tpo?historicalData.config.tpo:selectedTPO;
    console.log("Test OF TPO=",selectedTPO)
   
    for(let i=leftIndex;i<=rightIndex;i++){
      totalVolume=totalVolume+historicalData.profilesData[i].totalVolume;
      maxTPO=Math.max(maxTPO,historicalData.profilesData[i].maxTPO);
      minTPO=Math.min(minTPO,historicalData.profilesData[i].minTPO);
     
    }
    // totalVolume=totalVolume/100;
    // startIndex=123;
    console.log("OF VOl totalV,=",totalVolume,minTPO,maxTPO,startIndex,leftIndex,rightIndex);
    // console.log("OF VOl totalV,minTPO, maxTPO =",historicalData.config, historicalData.profilesData,totalVolume,minTPO,maxTPO,startIndex,leftIndex,rightIndex);
    let totalPerc=0;
    let volumeData=[];
    let index=-1;
    let curr=0;
    
   

    for(let tpo=minTPO;tpo<=maxTPO;tpo=parseFloat((tpo+currTPO).toFixed(4))){
      let volume=0;
     
      
      for(let i=startIndex;i<=historicalData.config.data.length-1;i++){
        // console.log("OF vol i=",i)
        // console.log("OF vol inner index= tpo=",i,tpo,historicalData.config.data[i].minTPO,historicalData.config.data[i].maxTPO);
        if(tpo>=historicalData.config.data[i].minTPO && tpo<=historicalData.config.data[i].maxTPO){
                index=parseFloat((tpo-historicalData.config.data[i].minTPO).toFixed(4));
                // console.log("OF vol outer index=",index,tpo)
                if(index!=0){
                  index=parseFloat((index/currTPO).toFixed(4))
                  // console.log("OF vol inner index=",i,index,tpo,historicalData.config.data[i].data[index][0])
                  // console.log("Test OF ",historicalData.config.data[i],index)
                  volume=volume+historicalData.config.data[i].data[index][0].total_vol;
                }else if(index==0){
                  volume=volume+historicalData.config.data[i].data[index][0].total_vol;

                }
             
          
          }

        }

      
      // console.log("tpo, volume=",tpo,volume);
       //add the global volume at the current tpo
       if(volume!=0 && volume!=undefined){
        volumeData.push({
          val:volume,
          perc:(parseFloat((volume*100/totalVolume).toFixed(4))),
        })
        maxPerc=Math.max(maxPerc,(parseFloat((volume*100/totalVolume).toFixed(4))));
        totalPerc=totalPerc+(parseFloat((volume*100/totalVolume).toFixed(4)));
      }else{
        volumeData.push({
          val:0,
          perc:0,
        })
      }

      
     
    }
    
    //  console.log("OF vol maxPerc totalPerc final volume data=",maxPerc, totalPerc,volumeData.length,volumeData);
    return{
      data:volumeData,
      minTPO:minTPO,
      maxTPO:maxTPO,
      maxPerc:maxPerc
    }
  }  

  useEffect(()=>{
    console.log("MP Chart Props changes",props.chartStateData)
  },[props.chartStateData])
  
  const findMaxMinInRange=(data, leftIndex, rightIndex)=> {
    if (leftIndex < 0 || rightIndex >= data.length || leftIndex > rightIndex) {
      // throw new Error('Invalid index range');
      const dataSummary = data.map((item, index) => `index ${index}: ${JSON.stringify(item)}`).join(', ');
      throw new Error(`Invalid index range: leftIndex (${leftIndex}) cannot be greater than rightIndex (${rightIndex}), length=(${data.length}). Data: [${dataSummary}]`);
    }
  
    const subset = data.slice(leftIndex, rightIndex + 1); // Extract subset of data
  
    // Initialize variables to hold max and min values
    let max = subset[0].high;
    let min = subset[0].low;
  
    // Iterate through the subset to find max and min values
    for (let i = 1; i < subset.length; i++) {
      if (subset[i].high > max) {
        max = subset[i].high;
      }
      if (subset[i].low < min) {
        min = subset[i].low;
      }
    }
    // max=max+TPO_OFFSET;
    // min=min-TPO_HEIGHT;
    let offset=(max-min)/10;
    max=max+offset;
    min=min-offset;
    
    return { max, min };
  } 

    useLayoutEffect(()=>{
      if(historicalData==undefined)
        return;
      // else return;
      let randomR=1;
      let randomG=1;
      let randomB=1;
      let colorCounter=1;
      let redraw=true;
      chartInterationAllowedRef.current=chartInterationAllowed;
      savedLiveDataRef.current=savedLiveData;
      contextMenuRef.current=showContextMenu;
      transformDataRef.current=transformData;
      transformDataRefY.current=transformDataY;
      yaxiscontextMenuRef.current=showYAxisContextMenu;
      // let currentDate=dayjs().day()<5?dayjs().add(1,"day"):dayjs().add(3,"day");
      let currentDate=dayjs();
     
     
      let nextWeekday=(currentDate.get('date')>9?currentDate.get('date'):"0"+currentDate.get('date'))+"-"+((currentDate.get('month')+1)>9?(currentDate.get('month')+1):"0"+(currentDate.get('month')+1))+"-"+currentDate.get('year');
      // console.log("nextWeekday 0=",currentDate,props.chartData.dateList[props.chartData.dateList.length-1],nextWeekday);
      if(isInstrumentLive || nextWeekday==props.chartData.dateList[props.chartData.dateList.length-1]){
        currentDate=dayjs().day()<5?dayjs().add(1,"day"):dayjs().add(3,"day");
        // console.log("nextWeekday 1=",currentDate);
      }else if(!isInstrumentLive && nextWeekday!=props.chartData.dateList[props.chartData.dateList.length-1] && dayjs().day()<5){
        currentDate=dayjs();
      }else{
        currentDate=dayjs().day()<5?dayjs().add(1,"day"):dayjs().add(2,"day");

      }
      nextWeekday=(currentDate.get('date')>9?currentDate.get('date'):"0"+currentDate.get('date'))+"-"+((currentDate.get('month')+1)>9?(currentDate.get('month')+1):"0"+(currentDate.get('month')+1))+"-"+currentDate.get('year');
       // console.log("nextWeekday 2=",nextWeekday,currentDate.get('month'),currentDate.get('year'));
      // let nextWeekday=currentDate.get('date')+"-"+((currentDate.get('month')+1)>9?(currentDate.get('month')+1):"0"+(currentDate.get('month')+1));
      
      console.log("SCALE ------------RANGE--------DATE--->111",historicalData.config.totalWidth,historicalData.config.dateList,dayjs().day(),props.chartData.liveDate);
      // console.log("SCALE ------------RANGE--------DATE--->",historicalData.config.totalWidth,historicalData.profilesData,historicalData.config,props.chartData);
      
      let canvasHeight=props.chartStateData.height -CONSTANTS.FIXED_HEADER_HEIGHT-CONSTANTS.FIXED_FOOTER_HEIGHT-XSCALE_HEIGHT;
      
      //calculate the width of canvas
      // let canvasWidth=window.innerWidth - CONSTANTS.FIXED_RIGHT_PANEL_WIDTH-YSCALE_WIDTH-20;
      let canvasWidth=props.chartStateData.width -YSCALE_WIDTH-0;
      
      setCanvasWidth(canvasWidth);
      console.log("window  w,h,canvasWidth, canvasHeight = ",window.innerWidth,canvasWidth,window.innerHeight,canvasHeight);

      const zoomOutMinVal=(Math.max(ZOOM_OUT_EXTREME,canvasWidth/historicalData.config.totalWidth))/LOAD_MORE_ZOOM_OUT_PERCENT;
      const endProfileExtreme=(historicalData.config.totalWidth-canvasWidth)*LOAD_MORE_PAN_PERCENT;
      const zoomOutChartBased=canvasWidth/historicalData.config.totalWidth;
      const totalWidth=historicalData.config.totalWidth+canvasWidth/2;
      let ZOOM_OUT_SCALE=Math.max(ZOOM_OUT_EXTREME, canvasWidth>totalWidth? ZOOM_OUT_EXTREME_LESS_PROFILE:canvasWidth/totalWidth);

      //retrieves the initial transform if already set  
      console.log("Recenter transform data=",transformData);
      let initialTransform={k:1,x:0,y:0};
      let initialTransformY={k:1,x:0,y:0}
      if(zoomType==OF_ZOOM_TYPE.ASYMMETRIC){
          if(transformData!=undefined)
            initialTransform=transformData;
          
          if(transformDataY!=undefined)
            initialTransformY=transformDataY;  
      
        }else{
        if(transformData!=undefined){
          initialTransform=transformData;
          initialTransformY=transformData
        }
      }   
      
      //calculate the height for the canvas
      // let canvasHeight=window.innerHeight - CONSTANTS.DASHBOARD_HEADER_HEIGHT-CONSTANTS.FIXED_HEADER_HEIGHT-CONSTANTS.FIXED_FOOTER_HEIGHT-XSCALE_HEIGHT;
     

      //handles the situation where all the profiles comes under the screen due to zoom out and profile width decrement on TPO change
      if(historicalData.config.totalWidth>canvasWidth && initialTransform.k<1 && (canvasWidth/historicalData.config.totalWidth)>=initialTransform.k){
         console.log("Extreme conditions met->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",canvasWidth/historicalData.config.totalWidth,initialTransform.k);
         //loadmore data impolictly as all the profiles comes under the screen
         loadMoreData(initialTransform);
         return;
      }else{
        console.log("Condition not met >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",canvasWidth/historicalData.config.totalWidth,initialTransform.k);
      }
     
      //set zoom out and pan extremes to keep track when to load more data during user interaction
      // const zoomOutMinVal=(Math.max(ZOOM_OUT_EXTREME,canvasWidth/historicalData.config.totalWidth))/LOAD_MORE_ZOOM_OUT_PERCENT;
      // const endProfileExtreme=(historicalData.config.totalWidth-canvasWidth)*LOAD_MORE_PAN_PERCENT;
      // const zoomOutChartBased=canvasWidth/historicalData.config.totalWidth;
      
      // console.log("Zoom pan extremes =",zoomOutMinVal,endProfileExtreme)
      
      //chart construction using d3  canvas and svg
      // const container = d3.select('.chart-container_new');
      // let className="."+props.id;
      // console.log("classname=",className)
      const container = d3.select(`#${props.id}`);
              
      // Remove any existing tooltip and create the tooltips
      d3.selectAll(`#tooltipCrosshair${props.id}`).remove()
      d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).remove()
      d3.selectAll(`#tooltipLiveMarker${props.id}`).remove()
      d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).remove()
      d3.selectAll(`#tooltipUpperLevel${props.id}`).remove()
      d3.selectAll(`#tooltipLowerLevel${props.id}`).remove()
      
      
      //yaxis tooltip
      const tooltipCrossHair = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipCrossHair")
      .attr("id", "tooltipCrosshair"+props.id)
      .style("opacity", 0.9)
      .style("z-index", 9999)
      .style("left",50)
      .style('display','none');
      
      //date axis tooltip
      const tooltipCrossHairDateAxis = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipCrossHair")
      .attr("id", "tooltipCrosshairDateAxis"+props.id)
      .style("opacity", 0.9)
      .style("z-index", 9999)
      .style("left",50)
      .style('display','none');
    
      //yaxis tooltip
      const tooltipLiveMarker = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipLiveMarker")
      .attr("id", "tooltipLiveMarker"+props.id)
      .style("opacity", 1.0)
      .style("z-index", 999)
      .style("left",50)
      .style('display','none');
      
      //tolltio for live profile close_vwap
      const tooltipLiveCloseVwap = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipLiveCloseVwap")
      .attr("id", "tooltipLiveCloseVwap"+props.id)
      .style("opacity", 1.0)
      .style("z-index", 99)
      .style("left",50)
      .style('display','none');

     //level boundary fixed tooltip
     const tooltipUpperLevel = d3.select(`#${props.id}`)
     .append("div")
     .attr("class", "tooltipFrozenLine")
     .attr("id", "tooltipUpperLevel"+props.id)
     .style("opacity", 0.9)
     .style("z-index", 9999)
     .style("left",50)
     .style('display','none'); 
    
      //level boundary fixed tooltip
      const tooltipLowerLevel = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipFrozenLine")
      .attr("id", "tooltipLowerLevel"+props.id)
      .style("opacity", 0.9)
      .style("z-index", 9999)
      .style("left",50)
      .style('display','none'); 
      
    //determine the range of profiles based on the canvas and profile width
    const xScaleRange=[];
    const dummyProfileWidth=(canvasWidth/0.2);
    xScaleRange[historicalData.config.widthList.length+1]=dummyProfileWidth;
    xScaleRange[historicalData.config.widthList.length]=canvasWidth;
    for(let i=historicalData.config.widthList.length-1;i>=0;i=i-1){
      // xScaleRange[i]=(xScaleRange[i+1]- (historicalData.profilesData[i].isVolumeProfile && !historicalData.profilesData[i].showOnlyVolumeProfile && !historicalData.profilesData[i].isTPOonVolume?historicalData.profilesData[i].volumeWidth:0)
      //   -(historicalData.profilesData[i].isStacked ?historicalData.profilesData[i].stackedWidth:historicalData.profilesData[i].splitWidth));
      xScaleRange[i]=(xScaleRange[i+1]- historicalData.config.widthList[i]);
    }

    // console.log("xScaleRange=",xScaleRange)
    let modDateList=[... historicalData.config.timeList]
    modDateList.push(nextWeekday);
    // console.log("xScaleRange=",xScaleRange,modDateList)
    const xScale = d3.scaleOrdinal()
      .domain(modDateList)
      .range(xScaleRange);

    // const xScale = d3.scaleOrdinal()
    //   .domain(historicalData.config.timeList)
    //   .range(xScaleRange);
    
    //determine the range of y axis based on latest profile
    // const maxDomain=historicalData.profilesData[historicalData.profilesData.length-1].maxTPO+2*selectedTPO;
    // const maxDomain=historicalData.config.data[historicalData.config.data.length-1].maxTPO+5*selectedTPO;
    // const minDomain=maxDomain-(canvasHeight/TPO_HEIGHT)*selectedTPO;
    const midTPO=historicalData.config.data[historicalData.config.data.length-1].closeTPO;
    const totalTPOONCanvas=(canvasHeight/TPO_HEIGHT)*selectedTPO;
    let maxDomain=midTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
    let minDomain=midTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
    // if(!isFirstLoad && (transformDataRefY.current.k!=1 || transformDataRefY.current.y!=0)){
      let forceRecenter=false;
      if(chartMidTPO!=-1){
        let maxDomainSaved=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
        let minDomainSaved=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
      let topRange=maxDomainSaved- (maxDomainSaved-minDomainSaved)*(LTP_POSITION_ALLOWED_CHANGE);
      let bottomRange=minDomainSaved+(maxDomainSaved-minDomainSaved)*(LTP_POSITION_ALLOWED_CHANGE);
      if(midTPO>topRange || midTPO<bottomRange){
        console.log("Recenter force recenter is true",maxDomainSaved,minDomainSaved,maxDomain,minDomain, chartMidTPO,midTPO,topRange,bottomRange)
        forceRecenter=true;
      }else
      console.log("Recenter force recenter is false",maxDomainSaved,minDomainSaved,chartMidTPO,midTPO,topRange,bottomRange)
     
      }
    if(!isRecenterAllowed){
     
      // maxDomain=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
      // minDomain=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
      // // setChartMidTPO(midTPO)
      maxDomain=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
      minDomain=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
      console.log("Recenter is not allowed 1", isRecenterAllowed,forceRecenter,maxDomain,minDomain,chartMidTPO,midTPO,totalTPOONCanvas)
    }else{
      
      if(isFirstLoad){
        console.log("Recenter is allowed first load");
        setIsFirstLoad(false);
        setChartMidTPO(midTPO)
      }else if(forceRecenter){
        console.log("Recenter is allowed force recenter");
        setChartMidTPO(midTPO);
      }else if(!forceRecenter){
       
        
        maxDomain=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
        minDomain=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
        console.log("Recenter is not allowed 2", isRecenterAllowed,forceRecenter,maxDomain,minDomain,chartMidTPO,midTPO,totalTPOONCanvas)
      }
    }
    // const maxDomain=historicalData.config.data[historicalData.config.data.length-1].maxTPO+5*selectedTPO;
    // const minDomain=maxDomain-(canvasHeight/TPO_HEIGHT)*selectedTPO;
     console.log("Recenter maxDomain, minDomain ",maxDomain,minDomain);
     let leftIndex=binarySearch(xScale.copy().range(),0-props.chartStateData.left);
     let rightIndex=binarySearch(xScale.copy().range(),canvasWidth-5);
      //performance improvement only drawing the profile under the screen view
      if(leftIndex==-1 || leftIndex==undefined)
       leftIndex=0;  
       if(rightIndex>historicalData.config.data.length-1 || rightIndex==undefined)
       rightIndex=historicalData.config.data.length-1; 
   
     const { max, min } = findMaxMinInRange(historicalData.config.data, leftIndex, rightIndex); 
     let finalMax=max;
     let finalMin=min;
      if(props.params.plot=="spt"){
        if(visibilityMatrix["gwall_high"]){
        const {max:gwallHighMax,min:gwallHighMin}=findMaxInRangeGeneric(historicalData.config.data, leftIndex, rightIndex,"gwall_high");
          finalMax=Math.max(finalMax,gwallHighMax);
          finalMin=Math.min(finalMin,gwallHighMin)
        }
        if(visibilityMatrix["gwall_low"]){
          const {max:gwallLowMax,min:gwallLowMin}=findMaxInRangeGeneric(historicalData.config.data, leftIndex, rightIndex,"gwall_low");
          finalMax=Math.max(finalMax,gwallLowMax);
          finalMin=Math.min(finalMin,gwallLowMin)
        }
        if(visibilityMatrix["mviz_high"]){
          const {max:mvivHighMax,min:mvizHighMin}=findMaxInRangeGeneric(historicalData.config.data, leftIndex, rightIndex,"upper_mviz");
          finalMax=Math.max(finalMax,mvivHighMax);
          finalMin=Math.min(finalMin,mvizHighMin)
        }
        if(visibilityMatrix["mviz_low"]){
          const {max:mvivLowMax,min:mvizLowMin}=findMaxInRangeGeneric(historicalData.config.data, leftIndex, rightIndex,"upper_mviz");
          finalMax=Math.max(finalMax,mvivLowMax);
          finalMin=Math.min(finalMin,mvizLowMin)

        }
      }
      
     //  let offset=Math.round((max-min)/2)
    //  console.log("SPTD3 max min",historicalData.config.max,historicalData.config.min)
    const yScale = d3.scaleLinear()
        // .domain([historicalData.config.min-20, historicalData.config.max+20])
        .domain([finalMin-SCALE_OFFSET, finalMax+SCALE_OFFSET])
        // .domain([min, max])
        // .range([canvasHeight-BOTTOM_CONTAINER_HEIGHT, 0])
        .range([canvasHeight-(BOTTOM_CONTAINER_HEIGHT+BOTTOM_CONTAINER_HEIGHT2), 0])
        .nice();

        let yScale2 = d3.scaleLinear()            //Volume Scale or Prem Scale
        .domain([props.params.plot=="spt"?historicalData.config.minPrem-2:0, props.params.plot=="spt"?historicalData.config.maxPrem+2:historicalData.config.maxVol])
        .range([BOTTOM_CONTAINER_HEIGHT, 0])
        .nice();

        const yScale3 = d3.scaleLinear()        //OI Scale
        .domain([historicalData.config.minOI - 1000, historicalData.config.maxOI])
        .range([BOTTOM_CONTAINER_HEIGHT, 0])
        .nice();

        let yScale4;
        if(isAdmin &&props.params.plot=="spt"){
        yScale4 = d3.scaleLinear()
        // .domain([0, historicalData.config.maxVol])
        .domain([historicalData.config.minInvBias,historicalData.config.minInvBias])
        .range([BOTTOM_CONTAINER_HEIGHT2, 0])
        .nice();  
        }
    

    //remove the previous svgs for axes if present
    d3.select(`#svgChart${props.id}`).remove()
    d3.select(`#svgChart2${props.id}`).remove()
    d3.select(`#svgChart3${props.id}`).remove()
    d3.select(`#svgChartXAxis${props.id}`).remove()
           
    // Init SVG: Y axis
    const svgChart = container.append('svg')
    .attr("id","svgChart"+props.id)
    .attr('width', xScaleRange[xScaleRange.length-2]+YSCALE_WIDTH-2)    //2 to counter the right border else scroll bar is show horizontally
    .attr('height', canvasHeight+XSCALE_HEIGHT-BOTTOM_CONTAINER_HEIGHT)
    // .attr("cursor","row-resize")
    .attr('class', 'svg-plot')
    .style("cursor","ns-resize")
    // .style("background","green");

    const svgChart2 = container.append('svg')
    .attr("id","svgChart2"+props.id)
    .attr('width', xScaleRange[xScaleRange.length-2]+YSCALE_WIDTH-2)    //2 to counter the right border else scroll bar is show horizontally
    .attr('height', BOTTOM_CONTAINER_HEIGHT)
    // .attr("cursor","row-resize")
    .attr('class', 'svg-plot')
    .style("cursor","ns-resize")
    // .style("background","red")
    .style("top",`${margin.top+32+canvasHeight-BOTTOM_CONTAINER_HEIGHT}px`)

    // Init SVG: X axis
    const svgChartXAxis = container.append('svg')
    .attr("id","svgChartXAxis"+props.id)
    .attr('width', xScaleRange[xScaleRange.length-2])
    .attr('height', XSCALE_HEIGHT+10)
    .attr('class', 'svg-plot')

    const svgChart3 = container.append('svg')
    .attr("id","svgChart3"+props.id)
    .attr('width', xScaleRange[xScaleRange.length-2]+YSCALE_WIDTH-2)    //2 to counter the right border else scroll bar is show horizontally
    .attr('height', BOTTOM_CONTAINER_HEIGHT2)
    // .attr("cursor","row-resize")
    .attr('class', 'svg-plot')
    .style("cursor","ns-resize")
    // .style("background","green")
    .style("top",`${margin.top+32+canvasHeight-BOTTOM_CONTAINER_HEIGHT-BOTTOM_CONTAINER_HEIGHT2}px`)

   
    // Init Axis
    // console.log("CandleDate Test=",xScale.domain(),xScale.domain().length-1)
    const lastProfileIndex=xScale.domain().length-1;
    let modFactor=30;
    if(props.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_30_min)
      modFactor=13;
    else if(props.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_15_min)
      modFactor=25;
    else if(props.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_5_min)
      modFactor=25;
    else if(props.selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_3_min)
      modFactor=25;

    const xAxis = d3.axisTop(xScale).tickFormat((d, i) => {
      // console.log("CandleDate i, lastProfileIndex=",i,lastProfileIndex)
      return (i==lastProfileIndex || (i % modFactor) === 0) ? d : ''});

    const yAxis = d3.axisRight(yScale)
                .tickFormat(function(d){
                  return d<0?"":d;
                });
    const yAxis2 = d3.axisRight(yScale2)
                .ticks(5)
                // .tickFormat(function(d){
                //   return d<0?"":d;
                // });  
    
    const yAxis3 = d3.axisRight(yScale3)
    .tickFormat((d, i) => {
        // console.log("CandleDate i, lastProfileIndex=",i,lastProfileIndex)
        return ""});           
    
        const yAxis4 = d3.axisRight(yScale4)
        .ticks(5)    


    //Remove the previous axes and  add new one Axis
    d3.select(`#xaxis${props.id}`).remove()
    d3.select(`#yaxis${props.id}`).remove()
    d3.select(`#yaxis2${props.id}`).remove()
    d3.select(`#yaxis3${props.id}`).remove()
    d3.select(`#yaxis4${props.id}`).remove()

    const gxAxis = svgChartXAxis.append('g')
        .style("font", "12px sans-serif")
        .attr("transform", `translate(0,${margin.top})scale(${1})`)
        .attr('id','xaxis'+props.id)
        .call(xAxis);
    
      
       

 
      const gyAxis = svgChart.append('g')
        .style("font", "12px sans-serif")
        .attr('transform',`translate(${canvasWidth},${margin.top})`)
        .attr('id','yaxis'+props.id)
        .call(yAxis)

        const gyAxis2 = svgChart2.append('g')
        .style("font", "12px sans-serif")
        .attr('transform',`translate(${canvasWidth},${0})`)
        .attr('id','yaxis2'+props.id)
        .call(yAxis2)  

        const gyAxis3 = svgChart2.append('g')
        .style("font", "12px sans-serif")
        .attr('transform',`translate(${canvasWidth},${0})`)
        .attr('id','yaxis3'+props.id)
        .call(yAxis3)  

        let gyAxis4;
        if(isAdmin && props.params.plot=="spt"){
        gyAxis4 = svgChart3.append('g')
        .style("font", "12px sans-serif")
        .attr('transform',`translate(${canvasWidth},${0})`)
        .attr('id','yaxis4'+props.id)
        .call(yAxis4) 
        } 
      
          


    d3.selectAll(`#canvasChart${props.id}`).remove()
    d3.selectAll(`#dummyCanvas${props.id}`).remove()
    d3.selectAll(`#canvasOverlay${props.id}`).remove();
    d3.selectAll(`#canvasTooltip${props.id}`).remove();

    
     // Invosoble Tooltip canvas
     const canvasTooltip= container.append('canvas')
     .attr("id",'canvasTooltip'+props.id)
     .attr('width', canvasWidth)
     .attr('height', canvasHeight)
     .style('margin-left', margin.left + 'px')
     .style('margin-top', margin.top + 'px')
     .attr('class', 'canvas-tooltip')
                

    // Overlay Canvas
    const overlayCanvas = container.append('canvas')
    .attr("id",'canvasOverlay'+props.id)
    .attr('width', canvasWidth)
    .attr('height', canvasHeight)
    .style('margin-left', margin.left + 'px')
    .style('margin-top', margin.top + 'px')
    .attr('class', 'canvas-plot')
    

    // Init Canvas
    const canvasChart = container.append('canvas')
    .attr("id",'canvasChart'+props.id)
    .attr('width', canvasWidth)
    .attr('height', canvasHeight)
    .style('margin-left', margin.left + 'px')
    .style('margin-top', margin.top + 'px')
    .attr('class', 'canvas-plot')
    
    //dummy canvas for user interaction
    const dummyCanvas = container.append('canvas')
    .attr('id','dummyCanvas'+props.id)
    .attr('width', canvasWidth+(zoomType==OF_ZOOM_TYPE.ASYMMETRIC?0:YSCALE_WIDTH-2))
    .attr('height', canvasHeight)
    .style('margin-left', margin.left + 'px')
    .style('margin-top', margin.top + 'px')
    .attr('class', 'canvas-plot')
    .style("cursor",zoomType==OF_ZOOM_TYPE.ASYMMETRIC?"ew-resize":"all-scroll")

    
   
    
    // Prepare chart buttons: Reset
    const toolsList = container.select(`#tools${props.id}`)
    .style('margin-top', (canvasHeight-4) + 'px')
    .style('visibility', 'visible');
  
    const watermark = container.select(`#watermark${props.id}`)
    .style('margin-top', (canvasHeight/2-4) + 'px')
    .style('visibility', 'visible');
  
    
    toolsList.select(`#reset${props.id}`).on('click', () => {
    // const t = d3.zoomIdentity.translate(0, 0).scale(1);
    // setTransformData(t);
    // setToggleRepaint(!toggleRepaint);
    dummyCanvas.transition()
      .duration(200)
      .ease(d3.easeLinear)
      .call(zoom_function.transform, t)

    });
    
    /**
     * Auto center the chart
     * @param {*} transform 
     */
    const autoCenter=(transform)=>{
      console.log("Autocenter transform=",transform)
      // const t = d3.zoomIdentity.translate(transform.x, transform.y).scale(transform.k);
      // setTransformData(transform);
      // setToggleRepaint(!toggleRepaint);
      dummyCanvas.transition()
        .duration(200)
        .ease(d3.easeLinear)
        .call(zoom_function.transform, t)
    
    }



    
    //TODO: Check for software cpu accelration performance instead of gpu for performance
    // const context = canvasChart.node().getContext('2d', {willReadFrequently: true}
    const context = canvasChart.node().getContext('2d');
    const contextDummy = dummyCanvas.node().getContext('2d');
    const contextOverlay = overlayCanvas.node().getContext('2d');
    const contextTooltip = canvasTooltip.node().getContext('2d');
    context.clearRect(0, 0, canvasWidth, canvasHeight);
    contextDummy.clearRect(0, 0, canvasWidth, canvasHeight);
    contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
    contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
        
    //keep track of previous selected profiles
    let selectedIndexes=[...selectedProfilesIndex]
    // Draw profiles on canvas

    // z holds a copy of the previous transform, so we can track its changes
    let zX = d3.zoomIdentity;
    let zY= d3.zoomIdentity;
    // if(transformData){
    //   zX.k=transformData.k;
    //   zX.x=transformData.x;
    // }
    // console.log("zoom event zoomIdentity 1 zX, zY=",zX,zY,transformData,transformDataY);
    // if(transformDataY){
    //   zY.k=transformDataY.k;
    //   zY.y=transformDataY.y;
    // }
    console.log("zoom event zoomIdentity 2 zX, zY=",zX,zY,transformData,transformDataY);

    // const image = document.getElementById("asterisk");
    // console.log("Image laoded 1",image)
    // image.addEventListener("load", (e) => {
    //   console.log("Image laoded",image)
    // })

    const image = new Image();
    // image.src ="../../../assets/images/asterisk.png";

    let loading=false;
    // if(candleData==undefined || candleData.time== historicalData.config.data[historicalData.config.data.length-1].time){
    //   console.log("Crosshairissue candleData=",candleData);
    //   setCandleData(historicalData.config.data[historicalData.config.data.length-1])
    // }

    if(candleData==undefined || candleData.time== historicalData.config.data[historicalData.config.data.length-1].time){
      setCandleData(historicalData.config.data[historicalData.config.data.length-1])
    }else if (isNewCandleFormed){
      if(candleData.time== historicalData.config.data[historicalData.config.data.length-2].time){
        setIsNewCandleFormed(false);
        setCandleData(historicalData.config.data[historicalData.config.data.length-1])
      }
    }
    const draw=(transform,yZoom=false,drawText=false,firstLoad=false)=> {
      console.log("OF ZOOM Test=",transform,yZoom)
      if(benchmarkingEnabled)
      console.time("Benchmark_FUTURE_draw")

      if(benchmarkingEnabled)
      console.time("Benchmark_CANDLE_draw1")
      randomR=1;
      randomG=1;
      randomB=1;
      colorCounter=1;
      if(zoomType==OF_ZOOM_TYPE.ASYMMETRIC){
        if(!firstLoad){
          if(yZoom) 
            zY=transform;
          else{ 
            let tx={x:transform.x,y:0,k:transform.k}
                
            zX=transform;
            // zY.y=transform.y;
          }
        }
      }else{
      zX=transform;
      zY=transform;
      }
     
     
      // console.log("draw() transform =",transform)
      
      //loads more data once the pan extreme is reached
      if(historicalData.config.totalWidth>canvasWidth && isLoadingMoreProfilesRef.current==false && loading==false &&  dataLoadedRef.current){
        console.log("CC Testing val=",historicalData.config.totalWidth,canvasWidth,isLoadingMoreProfilesRef.current,dataLoadedRef.current);
      if(!yZoom && transform.x>=endProfileExtreme*zX.k && !drawText){
        console.log("CC Testing Load more data as pan extreme is reached ",endProfileExtreme,transform);
        loadMoreData(transform);
        loading=true;
      }

       //loads more data once the pan zoom is reached
      if(!yZoom && zX.k<=zoomOutMinVal && zoomOutChartBased>=ZOOM_OUT_EXTREME && !drawText){
        if(zX.k>ZOOM_OUT_EXTREME){
          loadMoreData(transform);
          loading=true;
          console.log("CC Testing Load more data as zoom out extreme is reached",zoomOutMinVal,zoomOutChartBased,transform);
        }
      }
    }
      
      
      //handles the x scale rescaling and slicing the date exis text if required
      let scaleX=undefined;
     
    
      if(!drawText){
      if(zX.k>=0.75){
        scaleX =xScale.copy()
        .range((xScale.range().map((d,i) => (d*zX.k+zX.x))))

        // .domain((xScale.domain().map((date,i) => (date.slice(date.length - 5)))))
      }else{
        // scaleX =xScale.copy()
        // .range((xScale.range().map((d,i) => (d*zX.k+zX.x))))
        // const dates = xScale.domain();
        // const slicedDates = dates.map(date => date.slice(0, 5));
        // console.log("ScaleTest",slicedDates); // ["18-06", "19-06", "20-06"]
        scaleX =xScale.copy()
        .range((xScale.range().map((d,i) => (d*zX.k+zX.x))))
        // .domain(slicedDates);
        // .domain((xScale.domain().map((date,i) => (date.length<13?date.slice(0,5):dayjs(date.slice(-10).split("-")[2]+"-"+date.slice(-10).split("-")[1]+date.slice(-10).split("-")[0]).format("MMM, YY")))))
    
        
      
        
        // console.log("ScaleTest scaleX domain= ",scaleX.domain())
        // .domain((xScale.domain().map((date,i) => (date.slice(date.length - 5)))))
    
      }
    }else{
      scaleX=xScale;
    }
    // console.log("cords xscale range=",scaleX.range());
      
  
      let leftIndex=binarySearch(scaleX.copy().range(),0-props.chartStateData.left);
      let rightIndex=binarySearch(scaleX.copy().range(),canvasWidth-5);
       //performance improvement only drawing the profile under the screen view
       if(leftIndex==-1 || leftIndex==undefined)
        leftIndex=0;  
        if(rightIndex>historicalData.config.data.length-1 || rightIndex==undefined)
        rightIndex=historicalData.config.data.length-1; 
    
      const { max, min } = findMaxMinInRange(historicalData.config.data, leftIndex, rightIndex); 
      let finalMax=max;
      let finalMin=min;
       if(props.params.plot=="spt"){
         if(visibilityMatrix["gwall_high"]){
         const {max:gwallHighMax,min:gwallHighMin}=findMaxInRangeGeneric(historicalData.config.data, leftIndex, rightIndex,"gwall_high");
           finalMax=Math.max(finalMax,gwallHighMax);
           finalMin=Math.min(finalMin,gwallHighMin)
         }
         if(visibilityMatrix["gwall_low"]){
           const {max:gwallLowMax,min:gwallLowMin}=findMaxInRangeGeneric(historicalData.config.data, leftIndex, rightIndex,"gwall_low");
           finalMax=Math.max(finalMax,gwallLowMax);
           finalMin=Math.min(finalMin,gwallLowMin)
         }
         if(visibilityMatrix["mviz_high"]){
           const {max:mvivHighMax,min:mvizHighMin}=findMaxInRangeGeneric(historicalData.config.data, leftIndex, rightIndex,"upper_mviz");
           finalMax=Math.max(finalMax,mvivHighMax);
           finalMin=Math.min(finalMin,mvizHighMin)
         }
         if(visibilityMatrix["mviz_low"]){
           const {max:mvivLowMax,min:mvizLowMin}=findMaxInRangeGeneric(historicalData.config.data, leftIndex, rightIndex,"upper_mviz");
           finalMax=Math.max(finalMax,mvivLowMax);
           finalMin=Math.min(finalMin,mvizLowMin)
 
         }
       }
       let scaleY =zY.rescaleY(yScale)
      // let scaleY =zY.rescaleY(yScale).domain([finalMin-SCALE_OFFSET, finalMax+SCALE_OFFSET])
     
     
      let scaleY2 ;
      if(props.params.plot=="fut"){
        const { maxVol, minVol } = findMaxInRangeVol(historicalData.config.data, leftIndex, rightIndex); 
        scaleY2 =yScale2.domain([minVol,maxVol]);
      }

      if(props.params.plot=="spt"){
        const { max, min } = findMaxInRange(historicalData.config.data, leftIndex, rightIndex,"undy_prem"); 
        console.log("SPTD3 undy prem max min=",max,min)
        scaleY2 =yScale2.domain([min-5,max+5]);
      }

      let scaleY4;
      if(isAdmin && props.params.plot=="spt"){
        const { max, min } = findMaxInRangeGeneric(historicalData.config.data, leftIndex, rightIndex,"inv_bias"); 
        let offset=(max-min)/10;
        scaleY4 =yScale4.domain([min-offset,max+offset]);
      
       
      }


      const { maxOI, minOI } = findMaxInRangeOI(historicalData.config.data, leftIndex, rightIndex); 
      let scaleY3 =yScale3.domain([minOI-1000,maxOI]);
      
      let temlScale=zY.rescaleY(yScale)
      let temlScale2=zY.rescaleY(yScale2)
        

      
      if(zoomType==OF_ZOOM_TYPE.ASYMMETRIC){
        if(!yZoom){
        
          let tx={x:transform.x,y:0,k:transform.k}
          // setTransformData(tx);
          console.log("Recenter <=======  DRAW FUNCTION CALLED ======>", yZoom, transform,tx);
          transform.y=0;
          setTransformData(transform);
          // console.log("zoom event XAxis setTransform=",transform)
        }
        else{
        setTransformDataY(transform);
        // console.log("zoom event YAxis setTransform=",transform)
        }
      }else{
        setTransformData(transform);
        setTransformDataY(transform);
      }
         
      if(benchmarkingEnabled)
        console.time("Benchmark_CANDLE_AXIS")
      
      gxAxis.call(xAxis.scale(scaleX));
      gyAxis.call(yAxis.scale(scaleY));
      gyAxis2.call(yAxis2.scale(scaleY2));
      gyAxis3.call(yAxis3.scale(scaleY3));
      if(isAdmin){
      gyAxis4.call(yAxis4.scale(scaleY4));
      gyAxis4.selectAll(".tick line")
      .attr("stroke","#163569");
      }
     
      gyAxis2.selectAll(".tick line")
      .attr("stroke","#163569");
      gyAxis3.selectAll(".tick line")
      .attr("stroke","#163569");
      
     
     
     
      gxAxis.selectAll(".tick line")
      .attr("stroke","#163569");
      gxAxis.selectAll("text").each(function(d, i) {
      
        let xT=(scaleX.range()[i + 1] - scaleX(d))/2;
        if(i+1!=scaleX.range().length-1){
        if(zX.k<0.6)
          d3.select(this).attr("transform", `translate(${xT},0) scale(${1-((1-zX.k)/2)})`)
        else
          d3.select(this).attr("transform", `translate(${xT},0)`)
        }else{
      
          xT=50;
          if(zX.k<0.6)
            d3.select(this).attr("transform", `translate(${xT},0) scale(${1-((1-zX.k)/2)})`)
          else
            d3.select(this).attr("transform", `translate(${xT},0)`)
      

        }
      })
     
      if(benchmarkingEnabled)
        console.timeEnd("Benchmark_CANDLE_AXIS")
      // context.clearRect(0, 0, canvasWidth, canvasHeight);
      // contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
      const isLiveSession=( historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive)?true:false;

     
      
      // console.log("leftIndex, rightIndex=",leftIndex,rightIndex,historicalData.config.data.length);

      let tooltipData={
          data:[]
      };
      

 

      //calculate font size and font stretch (default 14px and 16px for bigger font), letter spacing if required(default is 0px)
      let fontSizeDefaualt=14;
      let tableFont=14;
      let fontSizeBig=15;
      let fontStretch="extra-expanded";//"normal";
      let letterSpacing=0;
      let defaultFontWeight=500;
      let boldFontWeight=600;
      let bModified=false;
     
      if(zX.k==1){
        if(zY.k>1){
          fontSizeDefaualt=Math.round((fontSizeDefaualt/zY.k)+2*(zY.k-1));
          // fontSizeDefaualt=Math.ceil((fontSizeDefaualt/zY.k));
          // fontSizeBig=Math.ceil(fontSizeBig/zY.k);
          fontSizeBig=Math.round((fontSizeBig/zY.k)+2*(zY.k-1));
           defaultFontWeight=Math.min(defaultFontWeight+Math.ceil(600*(zY.k-1)),650);
          boldFontWeight=Math.min(boldFontWeight+Math.ceil(600*(zY.k-1)),1000);
          letterSpacing=3*(zY.k-1);
          fontStretch="extra-expanded";
          
      
        }else if(zY.k<1){
          fontSizeDefaualt=Math.ceil((fontSizeDefaualt*zY.k)+10*(1-zY.k));
          fontSizeBig=Math.ceil((fontSizeBig*zY.k)+10*(1-zY.k));
          // fontSizeBig=Math.ceil(fontSizeBig*zY.k);
          defaultFontWeight=defaultFontWeight+Math.ceil(300*(1-zY.k));
          boldFontWeight=boldFontWeight+Math.ceil(300*(1-zY.k));
          // boldFontWeight=boldFontWeight+Math.ceil(600*(1-zY.k));
          letterSpacing=3*(1-zY.k);

        }
        tableFont=fontSizeDefaualt;

      }else if(zX.k>1){
        if(zY.k==1){
          fontSizeDefaualt=Math.ceil((fontSizeDefaualt/zX.k)+3*(zX.k-1));
          fontSizeBig=Math.ceil((fontSizeBig/zX.k)+3*(zX.k-1));
          // fontSizeBig=Math.ceil(fontSizeBig/zY.k);
          //  defaultFontWeight=defaultFontWeight+Math.ceil(600*(zY.k-1));
          // boldFontWeight=boldFontWeight+Math.ceil(600*(zY.k-1));
          // letterSpacing=(1-zX.k);
          
      
        
      }
        else if(zY.k>1){
          fontSizeDefaualt=Math.ceil((fontSizeDefaualt/zY.k)+1*(zY.k-1));
          fontSizeBig=Math.ceil((fontSizeBig/zY.k)+1*(zY.k-1));
          //  defaultFontWeight=defaultFontWeight+Math.ceil(600*(zY.k-1));
          // boldFontWeight=boldFontWeight+Math.ceil(600*(zY.k-1));
          letterSpacing=3*(zY.k-1);
          
      
        
      }else if(zY.k<1){
        // fontSizeDefaualt=20;
        // fontSizeDefaualt=Math.min(Math.ceil((fontSizeDefaualt/zY.k)-20*(zX.k-1)),15);
        fontSizeDefaualt=Math.max(Math.ceil((fontSizeDefaualt/zX.k)+3*(zX.k-1)),13);
          fontSizeBig=Math.max(Math.ceil((fontSizeBig/zX.k)+3*(zX.k-1)),14);

        // fontSizeDefaualt=Math.ceil((fontSizeDefaualt*zY.k)+10*(1-zY.k));
        // fontSizeBig=Math.ceil((fontSizeBig*zY.k)+10*(1-zY.k));
        // fontSizeBig=Math.ceil(fontSizeBig*zY.k);
        // defaultFontWeight=defaultFontWeight+Math.ceil(100*(1-zY.k));
        // boldFontWeight=boldFontWeight+Math.ceil(100*(1-zY.k));
        // boldFontWeight=boldFontWeight+Math.ceil(600*(1-zY.k));
        // letterSpacing=-2;
        // defaultFontWeight=400;
        fontStretch="ultra-condensed";

      }
      tableFont=fontSizeDefaualt;
      
      }else{
        if(zY.k>1){
          fontSizeDefaualt=Math.ceil((fontSizeDefaualt/zY.k)+2*(zY.k-1));
          fontSizeBig=fontSizeDefaualt+1;
          // fontSizeBig=Math.ceil((fontSizeBig/zY.k)+1*(zY.k-1));
           defaultFontWeight=defaultFontWeight+Math.ceil(500*(zY.k-1));
          boldFontWeight=boldFontWeight+Math.ceil(500*(zY.k-1));
          letterSpacing=2*(zY.k-1);
          tableFont=fontSizeDefaualt;
          
      
        
      }

      }


      //zoom out limt for visibility
      //y :0.53
      //x: 
      // console.log("Font values=",zX.k,zY.k,fontSizeDefaualt,fontSizeBig,fontStretch,letterSpacing,defaultFontWeight,boldFontWeight,zX.k,zY.k,tableFont);

     

      // for(let i=0;i<historicalData.profilesData.length;i=i+1){
      if(props.userSettings.data[getBottomPanelViewName(props.params.plot)]!=BOTTOM_PANEL_VIEWS.HIDDEN){
      drawBottomConatiainerOverlay(xScaleRange[leftIndex],zX,scaleY2,zY,(-1*(zX.x/zX.k)),canvasWidth/zX.k);
    
      }
      if(isAdmin)
      drawBottomConatiainerOverlay2(xScaleRange[leftIndex],zX,scaleY2,zY,(-1*(zX.x/zX.k)),canvasWidth/zX.k);

       
        if(benchmarkingEnabled)
      console.time("Benchmark_CANDLE_ALL_CNDLE_VOLUME")
       
       
      for(let i=leftIndex;i<=rightIndex;i=i+1){
        let currTooltipData=[];
        tooltipData.data[i]=[];
      
        
         drawCandle(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],temlScale,zX,fontSizeBig,boldFontWeight,zY,image)

         if(props.params.plot=="spt"){
          if(visibilityMatrix["gwall_high"])
           drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"gwall_high",CHART_COLORS.LC_SPT_GWALL_HIGH,LC_CHART_LINE_WIDTH.GWALL,"GWALL HIGH")
          
          if(visibilityMatrix["gwall_low"])
             drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"gwall_low",CHART_COLORS.LC_SPT_GWALL_LOW,LC_CHART_LINE_WIDTH.GWALL,"GWALL LOW")
          
          if(visibilityMatrix["mviz_high"])
            drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"upper_mviz",CHART_COLORS.LC_SPT_UPPER_MVIZ,LC_CHART_LINE_WIDTH.MVIZ,"UPPER MVIZ")
          
          if(visibilityMatrix["mviz_low"])
            drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"lower_mviz",CHART_COLORS.LC_SPT_LOWER_MVIZ,LC_CHART_LINE_WIDTH.MVIZ,"LOWER MVIZ")
          
          if(isAdmin && visibilityMatrix["mviz_level"])
            drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"mviz_level",CHART_COLORS.LC_SPT_LOWER_MVIZ,LC_CHART_LINE_WIDTH.MVIZ_LEVEL,"MVIZ LEVEL")
          
          // if(visibilityMatrix["gzero"])
          //    drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"gzero",CHART_COLORS.LC_SPT_GZERO,LC_CHART_LINE_WIDTH.GZERO,"GZERO",true,LINE_DASHED_LIST.GZERO)
         }else if(props.params.plot=="fut"){

          if(visibilityMatrix["vwap"])
              drawLineContinousMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"cumu_vwap",CHART_COLORS.CANDLE_VWAP,LC_CHART_LINE_WIDTH.VWAP,"VWAP")
         
          if(visibilityMatrix["vpoc"])   
              drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"cumu_vpoc",CHART_COLORS.CANDLE_VPOC,LC_CHART_LINE_WIDTH.GWALL,"VPOC")
          }

        }

        if(props.params.plot=="spt" && visibilityMatrix["gzero"])  
          drawLineStepMainDash(xScaleRange,historicalData.config.data,tooltipData.data,yScale,zX,leftIndex,historicalData.config.data,tooltipData,"gzero",CHART_COLORS.LC_SPT_GZERO,LC_CHART_LINE_WIDTH.GZERO,"GZERO",true,LINE_DASHED_LIST.GZERO,leftIndex,rightIndex,historicalData.config.data,true)
      
       

      
        console.log("TESTPANEL=",props.userSettings.data.lc_main_panel_view)
        if(props.userSettings.data[getBottomPanelViewName(props.params.plot)]!=BOTTOM_PANEL_VIEWS.HIDDEN){
        if(props.userSettings.data[getBottomPanelViewName(props.params.plot)]==undefined ||props.userSettings.data[getBottomPanelViewName(props.params.plot)]==BOTTOM_PANEL_VIEWS.TOP_LAYER){
          console.log("TESTPANEL 2=",props.userSettings.data.lc_main_panel_view)
          drawBottomConatiainerOverlay(xScaleRange[leftIndex],zX,scaleY2,zY,(-1*(zX.x/zX.k)),canvasWidth/zX.k);
          if(isAdmin)
          drawBottomConatiainerOverlay2(xScaleRange[leftIndex],zX,scaleY2,zY,(-1*(zX.x/zX.k)),canvasWidth/zX.k);

        }  
        drawBottomConatiainerLines(xScaleRange[leftIndex],zX,scaleY2,zY,(-1*(zX.x/zX.k)),canvasWidth/zX.k);
        if(isAdmin)
        drawBottomConatiainerLines2(xScaleRange[leftIndex],zX,scaleY4,zY,(-1*(zX.x/zX.k)),canvasWidth/zX.k,CANDLE_BOTTOM_VIEW_TYPE.INV_BIAS);
          

        for(let i=leftIndex;i<=rightIndex;i=i+1){
          let currTooltipData=[];
          tooltipData.data[i]=[];
          if(props.params.plot=="fut"){
            if(visibilityMatrix["vol"])
           drawVolumeBar(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],scaleY2,zX,fontSizeBig,boldFontWeight,zY,image,i)
           
            if(visibilityMatrix["oi"])
            drawLineContinousBottom(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],scaleY3,zX,i,historicalData.config.data[i+1],tooltipData,"oi",CHART_COLORS.LC_OI,LC_CHART_LINE_WIDTH.OI,"OI",false)
           
          }
          
          if(props.params.plot=="spt"){
            if(visibilityMatrix["prem"])
            drawPremBar(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],scaleY2,zX,fontSizeBig,boldFontWeight,zY,image,i,"undy_prem")
            
            if(isAdmin && visibilityMatrix["inv_bias"])
            drawBarUpDown(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],scaleY4,zX,fontSizeBig,boldFontWeight,zY,image,i,"inv_bias",BOTTOM_CONTAINER_HEIGHT+BOTTOM_CONTAINER_HEIGHT2)
           
          }
          
           
          }
        }

        // if(props.params.plot=="spt"){
        //   for(let i=leftIndex;i<=rightIndex;i=i+1){
            
        //       drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"gwall_high",CHART_COLORS.LC_SPT_GWALL_LOW,LC_CHART_LINE_WIDTH.GWALL,"GWALL HIGH")
        //       drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"gwall_low",CHART_COLORS.LC_SPT_GWALL_HIGH,LC_CHART_LINE_WIDTH.GWALL,"GWALL LOW")
        //       drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"upper_mviz",CHART_COLORS.LC_SPT_LOWER_MVIZ,LC_CHART_LINE_WIDTH.MVIZ,"UPPER MVIZ")
        //       drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"lower_mviz",CHART_COLORS.LC_SPT_UPPER_MVIZ,LC_CHART_LINE_WIDTH.MVIZ,"LOWER MVIZ")
        //       drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"gzero",CHART_COLORS.LC_SPT_GZERO,LC_CHART_LINE_WIDTH.GZERO,"GZERO",true,LINE_DASHED_LIST.GZERO)
        //   }
        // }else if(props.params.plot=="fut"){
        //   for(let i=leftIndex;i<=rightIndex;i=i+1){
        //       drawLineContinousBottom(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],scaleY3,zX,i,historicalData.config.data[i+1],tooltipData,"oi",CHART_COLORS.LC_OI,LC_CHART_LINE_WIDTH.OI,"OI")
        //       drawLineContinousMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"cumu_vwap",CHART_COLORS.CANDLE_VWAP,LC_CHART_LINE_WIDTH.VWAP,"VWAP")
        //       drawLineStepMain(xScaleRange[i],historicalData.config.data[i],tooltipData.data[i],yScale,zX,i,historicalData.config.data[i+1],tooltipData,"cumu_vpoc",CHART_COLORS.CANDLE_VPOC,LC_CHART_LINE_WIDTH.GWALL,"VPOC")
        //   }
        // }


       
        if(benchmarkingEnabled)
        console.timeEnd("Benchmark_CANDLE_ALL_CNDLE_VOLUME")
      
        
       
        //draw cumu vpoc and vwap marker
        // console.log("cumu =",yScale(historicalData.config.data[leftIndex+3].cumu_vpoc),historicalData.config.data[leftIndex+3].cumu_vpoc,historicalData.config.data[leftIndex+5].cumu_vpoc)
        // context.save()

         //draw daily markers(IB,Day high etc)
         let profileTooltipData=[];
         if(userSettings.data.show_metadata_marker_of || userSettings.data.show_metadata_marker_of==undefined){
          // if(benchmarkingEnabled)
          //   console.time("Benchmark_OF_DAILY_MARKER")
          // drawTableBase(xScaleRange[leftIndex],zX,scaleY2,zY,(-1*(zX.x/zX.k)),canvasWidth/zX.k);
            // drawDailyMarker(xScaleRange,zX,zY,profileTooltipData);
         
        //  if(benchmarkingEnabled)
        //   console.timeEnd("Benchmark_OF_DAILY_MARKER")
         }
        
        
      

       

        //draw NPOC
        let rightMostNPOCList=[];
  
    
        if(benchmarkingEnabled)
        console.timeEnd("Benchmark_CANDLE_draw1")
          //plot table and table data

        //bottom table  
        // if(zX.k>=XSCALE_ZOOM_OUT_DISPLAY && zY.k>=YSCALE_ZOOM_OUT_DISPLAY && props.bottomTableVisible){
        //     setTableVisible(true);
           
        //     for(let i=leftIndex;i<=rightIndex;i=i+1){
        //       drawTable(xScaleRange[i],historicalData.config.data[i],zX,scaleY,zY,tableFont,boldFontWeight,letterSpacing,i,canvasWidth/zX.k)
            
        //     }
        //   }else{
        //     setTableVisible(false);
        //   }

           // draw all the right most NPOCs text
           if(benchmarkingEnabled)
            console.time("Benchmark_CANDLE_draw2")
        d3.selectAll(`#npoc_label${props.id}`).remove()
        for(let i=0;i<rightMostNPOCList.length;i++){
        container.append("div")
             .style("left", (canvasWidth - 55) + "px")
            .attr("id", "npoc_label"+props.id)
            .style("top", scaleY(rightMostNPOCList[i])+24+TPO_HEIGHT+7 + "px")   //10=height of tooltip/2 80=Headers add height
            .style("position","absolute")
            .style("font-size","12px")
            .style("font-weight",500)
            .style("font-family","sans-serif")
            .style("background","#022D5A")
            .style("pointer-events","none")
            .style("color",`${CHART_COLORS.OF_NPOC_TEXT}`)
            .html(rightMostNPOCList[i]);
        }

            //live marker
            console.log("FUTD3 live marker lt=", historicalData.config.lastDataTime,props.isTradingSessionActive)
    if( historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive){
      // if(true){
      context.save()
      context.beginPath()
      if(zY.k!=1)
       context.lineWidth=3/zY.k;
      else 
        context.lineWidth=3;
     
      context.setLineDash([1/zX.k,1/zX.k]);
      // console.log("-1*(transform.x/transform.k)")
      context.moveTo(-1*(zX.x/zX.k), yScale(historicalData.config.data[historicalData.config.data.length-1].close))
      context.lineTo(6*canvasWidth, yScale(historicalData.config.data[historicalData.config.data.length-1].close))
     
      context.strokeStyle = CHART_COLORS.LIVE_PRICE_LINE;
      context.stroke();
      // context.closePath();
      context.restore();
      
      //live marker close value tooltip
      if(scaleY(historicalData.config.data[historicalData.config.data.length-1].close)>=0 && scaleY(historicalData.config.data[historicalData.config.data.length-1].close)<=canvasHeight){
        d3.selectAll(`#tooltipLiveMarker${props.id}`).style("display", "block");
        tooltipLiveMarker.style("left", (canvasWidth - 0) + "px")
            .style("top", scaleY(historicalData.config.data[historicalData.config.data.length-1].close)+35 + "px")   //10=height of tooltip/2 80=Headers add height
            .html(historicalData.config.data[historicalData.config.data.length-1].close.toFixed(2)+"\n"+historicalData.config.lastDataTime);
      }else{
        d3.selectAll(`#tooltipLiveMarker${props.id}`).style("display", "none");
      
      
      }

      //live profile closing_vwap tooltip
      if(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP!=undefined && historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP!=0 && scaleY(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP)>=0 && scaleY(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP)<=canvasHeight){
        d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).style("display", "block");
        tooltipLiveCloseVwap.style("left", (canvasWidth - 0) + "px")
            .style("top", scaleY(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP)+50 + "px")   //10=height of tooltip/2 80=Headers add height
            .html(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP.toFixed(2));
      }else{
        d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).style("display", "none");
       
      
      }
    }else{
      d3.selectAll(`#tooltipLiveMarker${props.id}`).remove();
      d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).remove();
    }


  
        
        contextDummy.clearRect(0, 0, canvasWidth, canvasHeight);
        dummyCanvas.on("contextmenu", function(event) {
          event.preventDefault();
          return;
          
        })

  
        svgChart.on("mousemove", function(event) {
          setShowTooltip(false);
          console.log("testing1 mousemove show=",showYAxisContextMenu,yaxiscontextMenuRef.current);
          if(!yaxiscontextMenuRef.current){
          const priceVal=(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
          // const volVal=(scaleY2.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY2.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
        //  console.log("CandleCrossHair price,volume=",priceVal,volVal)
          if(priceVal>=0){
          d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
          tooltipCrossHair.style("left", (canvasWidth - 60) + "px")
            .style("top", (event.pageY-12-48 -props.chartStateData.top) + "px")   //12=height of tooltip/2 80=Headers add height
            .html(priceVal);
          }
        }
        });
        if(benchmarkingEnabled)
          console.timeEnd("Benchmark_CANDLE_draw2")

        if(benchmarkingEnabled)
          console.time("Benchmark_CANDLE_draw3")
        const handleYAxisMouseMove=(event)=>{
          setShowTooltip(false);
          if(!props.basicUser){
          if(!yaxiscontextMenuRef.current){
            
            const priceVal=(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
            if(priceVal>=0){
              d3.selectAll(`#krlAxisTooltip${props.id}`).style("display", "none");
            d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
            tooltipCrossHair.style("left", (canvasWidth - 60) + "px")
              .style("top", (event.pageY-12-48 -props.chartStateData.top) + "px")   //12=height of tooltip/2 80=Headers add height
              .html(priceVal);
            }
          }
        }
     }
        
      //handles the mose move to display cross hairs and tooltips
      dummyCanvas.on("dblclick", function(event,d){
        event.preventDefault();
        console.log("double click on dummy canvas called event=",event )
      })

      dummyCanvas.on("mousemove", function(event,d){
        // console.log("testing mousemove canvas",event);
        setShowTooltip(false);
        // let col = context.getImageData(event.offsetX, event.offsetY, 1, 1).data;
        // console.log("mousemove event=, col",event,col,rgbToHex(col[0],col[1],col[2]));

       
       

          //Restrict the display if mouse position is outside the profiles
          // if(canvasWidth>historicalData.config.totalWidth && event.pageX<canvasWidth-historicalData.config.totalWidth){
          //   contextDummy.clearRect(0, 0, canvasWidth, canvasHeight)
          //   d3.selectAll("#tooltipCrosshair").style("display", "none");
          //   d3.selectAll("#tooltipCrosshairDateAxis").style("display", "none");
          //   console.log("Cross hair returning =====> cw, pw",canvasWidth,historicalData.config.totalWidth );
          //   return;
          // }
            contextDummy.clearRect(0, 0, canvasWidth, canvasHeight)
            if(zoomType==OF_ZOOM_TYPE.SYMMETRIC){
              if(event.pageX-props.chartStateData.left>canvasWidth){
                d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
                handleYAxisMouseMove(event);
              return;
              }
            }
            
            const index=binarySearch(scaleX.copy().range(),event.pageX-props.chartStateData.left);
           
            let volVal=undefined;
            if(event.pageY>CHART_TOP_SPACE+canvasHeight-BOTTOM_CONTAINER_HEIGHT){
            
            volVal=parseInt(scaleY2.invert(event.pageY-CHART_TOP_SPACE-canvasHeight+BOTTOM_CONTAINER_HEIGHT));
            setCrossHairCord({x:event.pageX,y:event.pageY,tpo:volVal});
            // console.log("CandleCrossHair volume=",volVal,parseInt(volVal),event.pageY,canvasHeight)
           
            }else if(event.pageY>CHART_TOP_SPACE+canvasHeight-BOTTOM_CONTAINER_HEIGHT-BOTTOM_CONTAINER_HEIGHT2){
            
              volVal=parseInt(scaleY4.invert(event.pageY-CHART_TOP_SPACE-canvasHeight+BOTTOM_CONTAINER_HEIGHT2+BOTTOM_CONTAINER_HEIGHT));
              setCrossHairCord({x:event.pageX,y:event.pageY,tpo:volVal});
              // console.log("CandleCrossHair volume=",volVal,parseInt(volVal),event.pageY,canvasHeight)
             
              }else{
            // setCrossHairCord({x:event.pageX,y:event.pageY,tpo:(scaleY.invert(event.pageY-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-CHART_TOP_SPACE))%selectedTPO).toFixed(2)});
            console.log("SPTCrosshair tpo=",event.pageY,(scaleY.invert(event.pageY-CHART_TOP_SPACE)))
            setCrossHairCord({x:event.pageX,y:event.pageY,tpo:(scaleY.invert(event.pageY-CHART_TOP_SPACE)).toFixed(2)});
            }
           
            let dateVal=(index>=0 && index<modDateList.length-1)?modDateList[index]:undefined;
            if(dateVal){
              // console.log("Crosshairissue mousemove 1 dateval data=",dateVal,historicalData.config.data[index],modDateList.length,historicalData.config.data.length)
              setCandleData(historicalData.config.data[index])
            // console.log("CandleData=",modDateList[index],historicalData.config.data[index]);
            }else{
              // console.log("Crosshairissue mousemove 2 dateval data=",dateVal,historicalData.config.data[historicalData.config.data.length-1])
              setCandleData(historicalData.config.data[historicalData.config.data.length-1]);
              dateVal=modDateList[modDateList.length-1];
            }

            // console.log("final tooltip data",index,modDateList[index],tooltipData.data[index],dateVal);
            const priceVal=(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
            if(dateVal!=undefined && contextMenuRef.current==false){
                // console.log("tooltipdata=",tooltipData);
                let col1 = contextTooltip.getImageData(event.offsetX, event.offsetY, 1, 1).data;
                let bgColor=rgbToHex(col1[0],col1[1],col1[2]) 
                let data=tooltipData.data[index]?tooltipData.data[index].filter(item => bgColor in item):[];
                // let data=tooltipData.data[index].filter(item => bgColor in item)
                // console.log("final tooltip data",data[0],bgColor,tooltipData.data,index,tooltipData.data[index]);
                let tempData=[];
                let found=false;
                if(data.length>0){
                  tempData=JSON.parse(JSON.stringify(data[0][bgColor]));
                  found=true;
                }

                  // //check for special nodes
                  // //VPOC
                  // if(historicalData.profilesData[index].volumeBasedVA){
                  //   if(priceVal==historicalData.profilesData[index].vpocTPO){
                  //     tempData.push({key:"VPOC",value:historicalData.profilesData[index].vpoc})
                  //     tempData.push({key:"VPOC Vol",value:historicalData.profilesData[index].vpoc_vol})
                  //     found=true;
                  //   }
                  //   if(priceVal==historicalData.profilesData[index].vwapTPO){
                  //     tempData.push({key:"VWAP",value:historicalData.profilesData[index].vwap})
                  //     found=true;
                  //   }
                  // }else{
                  //   if(priceVal==historicalData.profilesData[index].vpocTPOBased){
                  //     tempData.push({key:"TPOC",value:historicalData.profilesData[index].tpoc})
                  //     found=true;
                  //   }
                  //   if(priceVal==historicalData.profilesData[index].vwapTPOBased){
                  //     tempData.push({key:"TWAP",value:historicalData.profilesData[index].tpo_vwap})
                  //     found=true;
                  //   }
                  // }

                  // console.log("krl tooltip final tooltip data",bgColor,profileTooltipData);
                  if(bgColor!="#000000"){
                    for(let i=0;i<profileTooltipData.length;i++){
                     
                      let x1=profileTooltipData[i].x1*zX.k+zX.x;
                      let x2=profileTooltipData[i].x2*zX.k+zX.x;
                      console.log("krl tooltip final tooltip data mousemove krl tooltip priceval,tpo",priceVal,profileTooltipData[i],transform,x1,x2)
                      if(priceVal==profileTooltipData[i].tpo && event.pageX-props.chartStateData.left>=x1 && event.pageX-props.chartStateData.left<x2){
                        console.log("krl tooltip final tooltip data mousemove krl tooltip priceval,tpo",priceVal,profileTooltipData[i])
                        tempData.push({key:profileTooltipData[i].key,value:profileTooltipData[i].value})
                        found=true;
                        
                      }
                    }
                  }
                 

                  if(found && !props.basicUser){
                    // console.log("final tooltip data found",tempData);
                    setTooltipData(tempData)
                    openTooltip(event.pageX,event.pageY,canvasWidth,canvasHeight)
                  }
               
          }
            
            // console.log("mousemove: index, x,y,date",index,event.pageX,event.pageY,dateVal,historicalData.config.dateList)
              if(priceVal>=0){
              contextDummy.beginPath()
              contextDummy.lineWidth=1;
              contextDummy.setLineDash([2, 2]);
              contextDummy.moveTo(0, event.pageY-props.chartStateData.top-CHART_TOP_SPACE)
              contextDummy.lineTo(canvasWidth, event.pageY-props.chartStateData.top-CHART_TOP_SPACE)
              contextDummy.strokeStyle = '#fff'
              contextDummy.stroke()
              }
              if(dateVal!=undefined){
                contextDummy.beginPath()
                contextDummy.setLineDash([2, 2]);
                contextDummy.moveTo(event.pageX-props.chartStateData.left,0)
                contextDummy.lineTo(event.pageX-props.chartStateData.left, canvasHeight)
                contextDummy.strokeStyle = '#fff'
                contextDummy.stroke()
              }
              contextDummy.closePath()
              if(volVal){
              d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
              tooltipCrossHair.style("left", (canvasWidth - 0) + "px")
                .style("top", (event.pageY-12-48 -props.chartStateData.top) + "px")   //12=height of tooltip/2 80=Headers add height
                .html(volVal);
              }
              else if(priceVal>=0){
                d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
                tooltipCrossHair.style("left", (canvasWidth - 0) + "px")
                  .style("top", (event.pageY-12-48 -props.chartStateData.top) + "px")   //12=height of tooltip/2 80=Headers add height
                  .html(priceVal);
              }else{
                d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "none");
              }
              if(dateVal!=undefined){
                d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "block");
                tooltipCrossHairDateAxis.style("left", (event.pageX -props.chartStateData.left - (dateVal.length/4)*12 ) + "px")
                  .style("top", (XSCALE_HEIGHT+32) + "px")   //10=height of tooltip/2 80=Headers add height
                  .html(dateVal);
              }else{
                d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
              }
             
          })
            
          .on("mouseover", function () {
            d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
            d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "block");
          })
          .on("mouseout", (event, d) => {
            setCrossHairCord({x:-1,y:-1});
            contextDummy.clearRect(0, 0, canvasWidth, canvasHeight)
            d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "none");
            d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
            setCandleData(historicalData.config.data[historicalData.config.data.length-1])
           })

           //click events for profile selection
           dummyCanvas.on("click", function(event) {
            // d3.selectAll("#shape").style("opacity",0);
            if(chartInterationAllowedRef.current==false || (canvasWidth>historicalData.config.totalWidth && event.pageX<canvasWidth-historicalData.config.totalWidth)){
              contextDummy.clearRect(0, 0, canvasWidth, canvasHeight)
              d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "none");
              d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
              return;
            }
            
            if(contextMenuRef.current){
              setShowContextMenu(false);
              return;
             }
            
             //remove the index if already present
            function removeIndex(arr, value) {
      
              return arr.filter(function(index){
                  return index!=value;
              });
             
           }
          
           const index=binarySearch(scaleX.copy().range(),event.pageX-props.chartStateData.left);
            if(historicalData!=undefined && historicalData.profilesData[index]!=undefined){
              //if shift clicked then selec all the profiles from last selected index to this index
              //take care of duplicate indexes
              let temp=[];
                 
              if (event.shiftKey) {
                console.log("This is the shift function")
                if(selectedIndexes.length>0 &&  index!=selectedIndexes){
                  const minIndex=Math.min(...selectedIndexes);
                  const maxIndex=Math.max(...selectedIndexes);
                  if(index<minIndex){
                    for(let i=index;i<minIndex;i++){
                      temp.push(i);
                    }
                  }else{
                    for(let i=maxIndex+1;i<=index;i++){
                      temp.push(i);
                    }
                  }
                  console.log("temp = ",temp);
                  selectedIndexes=[...selectedIndexes, ...temp];

                }else if (index==selectedIndexes){ //same index, don't do anything
                  
                }else{
                  temp.push(index) 
                  selectedIndexes=[...selectedIndexes, ...temp];
                  console.log("else of shift ",)
                }
             }
             else{   
                if(!selectedIndexes.includes(index)){
                  temp.push(index)
                  selectedIndexes=[...selectedIndexes, ...temp]
                }else{
                 //deselect the already selected element
                 selectedIndexes = removeIndex(selectedIndexes, index);
                }
              
              }
              setSelectedProfilesIndex(selectedIndexes);
              contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight)
                
              for(let temp=0;temp<selectedIndexes.length;temp++)
                {
                  // console.log("inside indexes loop =",temp,selectedIndexes[temp])
                contextOverlay.fillStyle = `${theme.palette.primaryTheme.shade03}`;
                contextOverlay.fillRect(scaleX.range()[selectedIndexes[temp]]+1,2, scaleX.range()[selectedIndexes[temp]+1]-scaleX.range()[selectedIndexes[temp]]-1,canvasHeight);
                contextOverlay.stroke();
                }  
           }
        })

            //draw previous crossHair
            if(crossHairCord.x!=-1 && crossHairCord.y!=-1){
              // console.log("transform in crosshair=",transform.k,transformData.k);
              // d3.selectAll("#tooltipCrosshair").remove();
              // d3.selectAll("#tooltipCrosshairDateAxis").remove();
              contextDummy.clearRect(0,0,canvasWidth,canvasHeight);
              const index=binarySearch(scaleX.copy().range(), crossHairCord.x-props.chartStateData.left);
              // const dateVal=(index>=0 && index<historicalData.config.timeList.length)?historicalData.config.timeList[index]:undefined;
              const dateVal=(index>=0 && index<modDateList.length)?modDateList[index]:undefined;
              
              // console.log("Crosshair redraw index,dateval=========>",index,dateVal);
              // context.moveTo(-1*(transform.x/transform.k), yScale(historicalData.profilesData[historicalData.profilesData.length-1].closeTPO))
              // context.lineTo(canvasWidth, yScale(historicalData.profilesData[historicalData.profilesData.length-1].close))
                contextDummy.save();
                contextDummy.beginPath()
                contextDummy.lineWidth=2/zY.k;
                contextDummy.setLineDash([Math.round(2/zX.k), Math.round(2/zX.k)]);
                contextDummy.moveTo(-1*(zX.x/zX.k), yScale(crossHairCord.tpo)-props.chartStateData.top)
                contextDummy.lineTo(6*canvasWidth, yScale(crossHairCord.tpo)-props.chartStateData.top)
                contextDummy.strokeStyle = '#999'
                contextDummy.stroke()
                if(dateVal!=undefined){
                  contextDummy.beginPath()
                  contextDummy.lineWidth=2/zX.k;
                  contextDummy.setLineDash([Math.round(2/zY.k), Math.round(2/zY.k)]);
                  contextDummy.moveTo((crossHairCord.x-zX.x)/zX.k-props.chartStateData.left,-1*(zY.y/zY.k))
                  // contextDummy.lineTo((crossHairCord.x-transform.x)/transform.k, canvasHeight/transform.k+Math.abs(transform.y));
                  contextDummy.lineTo((crossHairCord.x-zX.x)/zX.k-props.chartStateData.left, 99999);
                  // contextDummy.strokeStyle = '#999'
                  contextDummy.stroke()
                }
                contextDummy.closePath()
                contextDummy.restore();
                // console.log("cross hair========>",crossHairCord.x,transform.x/transform.k);
                d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
                tooltipCrossHair.style("left", (canvasWidth - 0) + "px")
                .style("top", (crossHairCord.y-props.chartStateData.top-48-10) + "px")   //10=height of tooltip/2 80=Headers add height
                .html(crossHairCord.tpo);
                if(dateVal!=undefined){
                d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "block");
                tooltipCrossHairDateAxis.style("left", (crossHairCord.x-props.chartStateData.left-6 - (dateVal.length/4)*10) + "px")
                .style("top", (XSCALE_HEIGHT+24+8) + "px")   //10=height of tooltip/2 80=Headers add height
                .html(dateVal);
                }
            
            }
            if(benchmarkingEnabled)
              console.timeEnd("Benchmark_CANDLE_draw3")
            if(benchmarkingEnabled)
            console.timeEnd("Benchmark_FUTURE_draw")
       
    }

  
    const drawOFProfileNew=(xCord,yCord,data,tooltipData,xTransform,factor,yTransform,defaultFontSize,bigFontSize,fontStretch,letterSpacing,defaultFontWeight,boldFontWeight,ofData)=>{
      // console.log("OFdata=",data);
    //  if((xTransform.k<0.7 && yTransform.k<0.7)|| yTransform.k<0.5 ||xTransform.k<0.65) return;

    // context.save();
    let divisionFactorNew=xTransform.k>1?xTransform.k:1;
    let px = (xCord+20);
    let py = yScale(yCord);
    let mulFactor=xTransform.k>1?1:1;
      if(true){
      let defaultFont=`${defaultFontWeight} ${defaultFontSize}px 'Roboto Flex'`;
      let boldFont=`${boldFontWeight} ${bigFontSize}px 'Roboto Flex'`;
      
    //  console.log("font values 1=",defaultFont,boldFont,px,xCord,py)
     
      context.fontStretch=fontStretch;
      context.letterSpacing=`${letterSpacing}px`;

      // console.log("cords",px,py)
      // context.font=data.isSellHighlighted? CHART_FONT_BOLD_OF_VOL:CHART_FONT_OF_VOL_DEFAULT;
      
      const isZoomedOutState=xTransform.k<=XSCALE_ZOOM_OUT_DISPLAY || yTransform.k<=YSCALE_ZOOM_OUT_DISPLAY

      if(showLLTMarker){
        let sellerLength=data.sell_vol.toString().length;
        let sellerPos=60-10*sellerLength-10-2;
        let buyerLength=data.buy_vol.toString().length;
        let buyerPos=60+(9.6*buyerLength)+5;

        let px1 = (xCord+20+15*divisionFactorNew*mulFactor);
        // if(data.isBigBuyer || data.isBigSeller){
        //   console.log("Big Seller Buyer tooltip before= ",tooltipData)
        // }
        if(data.isBigSeller){
          if( !isZoomedOutState){
          context.beginPath();
          context.fillStyle=CHART_COLORS.SELLER_LLT_MARKER_COLOR;
          context.arc( px+7+sellerPos+12*divisionFactorNew,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
          context.fill();
          }
      
          let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          let randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.fillStyle=randomColor;
          contextTooltip.fillRect(px1, (py-TPO_HEIGHT/2-4),50,TPO_HEIGHT/2+7);
          let tempData=[];
          // console.log("Big Seller big data.vpoc=",data, data.bigBuyerData,ofData.vpocTPO,data.bigSellerData[0].level,ofData)
          if(ofData.vpoc && ofData.vpocTPO==data.bigSellerData[0].level ){
            // console.log("Big Seller big data.vpoc=",data, data.bigSellerData,ofData.vpocTPO,data.bigSellerData[0].level,ofData)
            tempData.push({key:"VPOC",value:ofData.vpoc})
            tempData.push({key:"VPOC Vol",value:ofData.vpoc_vol})
          }
          for(let i=0;i<data.bigSellerData.length;i++){
            tempData.push({key:data.bigSellerData[i].tpo,value:props.contracts? data.bigSellerData[i].lot:data.bigSellerData[i].vol,color:CHART_COLORS.SELLER_LLT_MARKER_COLOR})
           
          }
          tooltipData.push({
            [randomColor]:tempData
          })
          // console.log("Big Seller Buyer tooltip after seller= ",tooltipData,ofData.time,ofData.vpocTPO,data.bigSellerData[0].level)
        }
       
        if(data.isBigBuyer){
          if( !isZoomedOutState){
          context.beginPath();
          context.fillStyle=CHART_COLORS.BUYER_LLT_MARKER_COLOR;
          context.arc( px+buyerPos+12*divisionFactorNew,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
          context.fill();
          }

          let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          let randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.fillStyle=randomColor;
          // contextTooltip.fillStyle="grey";
          contextTooltip.fillRect(px1+60, (py-TPO_HEIGHT/2-4),50,TPO_HEIGHT/2+7);
          let tempData=[];
          // console.log("Big Buyer big data.vpoc=",data, data.bigBuyerData,ofData.vpocTPO,data.bigBuyerData[0].level,ofData)
          if(ofData.vpoc && ofData.vpocTPO==data.bigBuyerData[0].level ){
            tempData.push({key:"VPOC",value:ofData.vpoc})
            tempData.push({key:"VPOC Vol",value:ofData.vpoc_vol})
          }
          for(let i=0;i<data.bigBuyerData.length;i++){
            tempData.push({key:data.bigBuyerData[i].tpo,value:props.contracts? data.bigBuyerData[i].lot:data.bigBuyerData[i].vol,color:CHART_COLORS.BUYER_LLT_MARKER_COLOR})
           
          }
          tooltipData.push({
            [randomColor]:tempData
          })

          // console.log("Big Seller Buyer tooltip after buyer= ",tooltipData,ofData.time,ofData.vpocTPO,data.bigBuyerData[0].level)
          
        }
    }

      context.font=data.isSellHighlighted? boldFont:defaultFont;
      context.fillStyle = data.isSellHighlighted?CHART_COLORS.SELL_VOL_HIGHLIGHTED_COLOR: CHART_COLORS.OF_VOL_NUM_COLOR;
      context.textAlign = "right";
      if(xTransform.k<=XSCALE_ZOOM_OUT_DISPLAY || yTransform.k<=YSCALE_ZOOM_OUT_DISPLAY){
        if(data.isSellHighlighted){
          let px1 = (px+15*divisionFactorNew);
          let perc=Math.ceil((data.sell_vol/ofData.maxSellVol)*100);
          let width=Math.max(55*perc/100,3);
          
         
          if(data.isBigSeller){
            context.beginPath();
            context.fillStyle=CHART_COLORS.SELLER_LLT_MARKER_COLOR;
            context.arc( px1+55-width+5,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
            context.fill();
            context.fillStyle=CHART_COLORS.SELL_VOL_HIGHLIGHTED_COLOR;
            context.fillRect(px1+55-width+10, (py-TPO_HEIGHT/2-1),width-10,TPO_HEIGHT/2+1);
          }else{
            context.fillStyle=CHART_COLORS.SELL_VOL_HIGHLIGHTED_COLOR;
            context.fillRect(px1+55-width, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
          }
  
         }else{
          let px1 = (px+15*divisionFactorNew);
          let perc=Math.ceil((data.sell_vol/ofData.maxSellVol)*100);
          let width=Math.max(55*perc/100,3);
          // console.log("width, perc=",perc,width,data.maxSellVol,data.sell_vol)
          
          // context.fillStyle=CHART_COLORS.SELL_VOL_BK;
          // // context.strokeRect(px, (py-TPO_HEIGHT/2-4),120,TPO_HEIGHT/2+7);
          // context.fillRect(px1+55-width, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
            
          if(data.isBigSeller){
            context.beginPath();
            context.fillStyle=CHART_COLORS.SELLER_LLT_MARKER_COLOR;
            context.arc( px1+55-width+5,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
            context.fill();
            context.fillStyle=CHART_COLORS.SELL_VOL_BK;
            context.fillRect(px1+55-width+10, (py-TPO_HEIGHT/2-1),width-10,TPO_HEIGHT/2+1);
          }else{
            context.fillStyle=CHART_COLORS.SELL_VOL_BK;
            context.fillRect(px1+55-width, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
          }

         }
      }
      else
      context.fillText(data.sell_vol, px+54+12*divisionFactorNew, py);

    
     
      context.font=data.isBuyHighlighted?boldFont:defaultFont;
      context.fillStyle = data.isBuyHighlighted?CHART_COLORS.BUY_VOL_HIGHLIGHTED_COLOR: CHART_COLORS.OF_VOL_NUM_COLOR;
      context.textAlign = "left";
      if(xTransform.k<=XSCALE_ZOOM_OUT_DISPLAY || yTransform.k<=YSCALE_ZOOM_OUT_DISPLAY){
        if(data.isBuyHighlighted){
          let px2 = (px+60+15*divisionFactorNew);
          let perc=Math.ceil((data.buy_vol/ofData.maxBuyVol)*100);
          let width=Math.max(55*perc/100,3);
          if(data.isBigBuyer){
            context.beginPath();
            context.fillStyle=CHART_COLORS.BUYER_LLT_MARKER_COLOR;
            context.arc( px2+width-5,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
            context.fill();
            context.fillStyle=CHART_COLORS.BUY_VOL_HIGHLIGHTED_COLOR;
            context.fillRect(px2, (py-TPO_HEIGHT/2-1),width-10,TPO_HEIGHT/2+1);
          }else{
            context.fillStyle=CHART_COLORS.BUY_VOL_HIGHLIGHTED_COLOR;
            context.fillRect(px2, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
          }
          
  
         }else{
          let px2 = (px+60+15*divisionFactorNew);
          let perc=Math.ceil((data.buy_vol/ofData.maxBuyVol)*100);
          let width=Math.max(55*perc/100,3);
          // console.log("width, perc=",perc,width,data.maxSellVol,data.sell_vol)
          
          // context.fillStyle=CHART_COLORS.BUY_VOL_BK;
          // context.fillRect(px2, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
          if(data.isBigBuyer){
            context.beginPath();
            context.fillStyle=CHART_COLORS.BUYER_LLT_MARKER_COLOR;
            context.arc( px2+width-5,(py-TPO_HEIGHT/2+3),TPO_HEIGHT/2-3,0,2*Math.PI);
            context.fill();
            context.fillStyle=CHART_COLORS.BUY_VOL_BK;
            context.fillRect(px2, (py-TPO_HEIGHT/2-1),width-10,TPO_HEIGHT/2+1);
          }else{
            context.fillStyle=CHART_COLORS.BUY_VOL_BK;
            context.fillRect(px2, (py-TPO_HEIGHT/2-1),width,TPO_HEIGHT/2+1);
          }
          
         }
      }
      else
      context.fillText(data.buy_vol, px+60+14*divisionFactorNew, py);
      }

      // context.restore();
    }
 
    
    const findMaxInRangeVol=(data, leftIndex, rightIndex)=> {
      if (leftIndex < 0 || rightIndex >= data.length || leftIndex > rightIndex) {
        throw new Error('Invalid index range');
      }
    
      const subset = data.slice(leftIndex, rightIndex + 1); // Extract subset of data
    
      // Initialize variables to hold max and min values
      let maxVol = subset[0].vol;
      let minVol=0;
      
    
      // Iterate through the subset to find max and min values
      for (let i = 1; i < subset.length; i++) {
        if (subset[i].vol > maxVol) {
          maxVol = subset[i].vol;
        }
       
      }
    
      return { maxVol, minVol };
    }

    const findMaxInRangeOI=(data, leftIndex, rightIndex)=> {
        if (leftIndex < 0 || rightIndex >= data.length || leftIndex > rightIndex) {
          throw new Error('Invalid index range');
        }
      
        const subset = data.slice(leftIndex, rightIndex + 1); // Extract subset of data
      
        // Initialize variables to hold max and min values
        let maxOI = subset[0].oi;
        let minOI=subset[0].oi;
        
      
        // Iterate through the subset to find max and min values
        for (let i = 1; i < subset.length; i++) {
          if (subset[i].oi > maxOI) {
            maxOI = subset[i].oi;
          }
          if (subset[i].oi < minOI) {
            minOI = subset[i].oi;
          }
         
        }
      
        return { maxOI, minOI };
      }
    

    const findMaxInRange=(data, leftIndex, rightIndex,fieldName)=> {
      if (leftIndex < 0 || rightIndex >= data.length || leftIndex > rightIndex) {
        throw new Error('Invalid index range');
      }
    
      const subset = data.slice(leftIndex, rightIndex + 1); // Extract subset of data
    
      // Initialize variables to hold max and min values
      let max = subset[0][fieldName];
      let min=subset[0][fieldName];
      
    
      // Iterate through the subset to find max and min values
      for (let i = 1; i < subset.length; i++) {
        if (subset[i][fieldName] > max) {
          max = subset[i][fieldName];
        }
        if (subset[i][fieldName] < min) {
          min = subset[i][fieldName];
        }
       
      }
    
      return { max, min };
    }

    //per profile candles, signal backgrounds, VWAP, High/Low Range , signal texts
    const drawCandle=(xCord,data,tooltipData,scaleY,transform,fontSize,fontwt,yTransform,image)=>{
    //  console.log("drawcandle=",data.index,data.time);
      context.save();
      contextTooltip.save();
      
      let color=data.open>=data.close?CHART_COLORS.CANDLE_RED:CHART_COLORS.CANDLE_GREEN;
      let margin=data.open>=data.close?4:-4;
      const SINGAL_MARGIN=2;
      // let offsetOpen=data.open>=data.close?(-1*TPO_HEIGHT)+5:0;
      // let offsetClose=data.open>=data.close?0:(-1*TPO_HEIGHT)+5;
      let offsetOpen=0;
      let offsetClose=0;
      // let offsetHigh=data.high==data.highVal?0:(-1*TPO_HEIGHT)+5;
      // let offsetHigh=data.high==data.highVal?(-1*TPO_HEIGHT)+8:(-1*TPO_HEIGHT)+8;
      let offsetHigh=0;
      let px=xCord;
      let innerX1=px+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2)-(CANDLELINEWIDTH/2)
      let innerX2=px+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2)+(CANDLELINEWIDTH/2)
      context.beginPath();
      context.moveTo(innerX1, yScale(data.low));
      context.lineTo(innerX2, yScale(data.low));
      context.lineTo(innerX2, yScale(data.high)+offsetHigh);
      context.lineTo(innerX1, yScale(data.high)+offsetHigh);
      // context.lineWidth = 1/divisionFactor;
      context.fillStyle=color
      context.fill();
      // contextDummy.closePath()

      //  px=xCord+(20/divisionFactorNew);
      let outerX1=px+PROFILE_LEFT_MARGIN;
      let outerX2=px+PROFILE_LEFT_MARGIN+CANDLEWIDTH;
       if(data.open!=data.close){
      context.beginPath();
      context.moveTo(outerX1, yScale(data.open)+offsetOpen);
      context.lineTo(outerX2, yScale(data.open)+offsetOpen);
      context.lineTo(outerX2, yScale(data.close)+offsetClose);
      context.lineTo(outerX1, yScale(data.close)+offsetClose);
     
      context.fillStyle=color
      context.fill();
       }else{
        context.beginPath();
        context.moveTo(outerX1, yScale(data.open));
        context.lineTo(outerX2, yScale(data.open));
        context.lineTo(outerX2, yScale(data.close)-4);
        context.lineTo(outerX1, yScale(data.close)-4);
       
        context.fillStyle=color
        context.fill();
       }

    
      // let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
      // randomR = Math.floor(colorCounter / (255 * 255));
      // randomG = Math.floor((colorCounter / 255) % 255);
      // randomR = colorCounter % 255;
      // colorCounter=colorCounter+1;
      // let randomColor=rgbToHex(randomR,randomG,randomB);
      // contextTooltip.fillStyle=randomColor;
      // contextTooltip.beginPath();
      // contextTooltip.moveTo(px, yScale(data.open)-margin);
      // contextTooltip.lineTo(px+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+PROFILE_MIN_WIDTH, yScale(data.open)-margin);
      // contextTooltip.lineTo(px+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+PROFILE_MIN_WIDTH, yScale(data.close)+margin);
      // contextTooltip.lineTo(px, yScale(data.close)+margin);
      // contextTooltip.fill();
      // let tempData=[];
      // tempData.push({key:"Open",value:data.open.toFixed(2)})
      // tempData.push({key:"High",value:data.highVal.toFixed(2)})
      // tempData.push({key:"Low",value:data.lowVal.toFixed(2)})
      // tempData.push({key:"Close",value:data.close.toFixed(2)})
     
      // if(data.signal)
      // tempData.push({key:"Signal",value:data.signal})

      // if(data.vpoc ){
      //   tempData.push({key:"VPOC",value:data.vpoc})
      //   // tempData.push({key:"VPOC Vol",value:data.vpoc_vol}) 
      // }
      // if(data.vol ){
      //   tempData.push({key:"Volume",value:data.vol})
       
      // }
      // tempData.push({key:"OI",value:data.oi})

      // tooltipData.push({
      //   [randomColor]:tempData
      // })

     
      
    // if(data.signal){
    //   px = (xCord+0);
    //   let py = (data.signal=="IS"|| data.signal=="RS")?yScale(data.high)-12:yScale(data.low)+4;
    //   context.font=`18px sans-serif`;
    //   context.fillStyle = (data.signal=="IS"|| data.signal=="RS")?CHART_COLORS.IS_RS_MARKER_COLOR:CHART_COLORS.IB_RB_MARKER_COLOR
    //   context.fillText("*", px, py+14);

    //   px = (xCord);
    //   // let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
    //   randomR = Math.floor(colorCounter / (255 * 255));
    //   randomG = Math.floor((colorCounter / 255) % 255);
    //   randomR = colorCounter % 255;
    //   colorCounter=colorCounter+1;
    //   let randomColor=rgbToHex(randomR,randomG,randomB);
    //   contextTooltip.fillStyle=randomColor;
    //   contextTooltip.fillRect(px, (py-2),PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+CANDLEWIDTH,10);
    //   let tempData=[];
    //   tempData.push({key:"Signal",value:data.signal})
     
    //   tooltipData.push({
    //     [randomColor]:tempData
    //   })

    //   if(data.signal && (data.signal=="IS"|| data.signal=="IB")){
    //     px = (xCord+0);
    //     context.globalAlpha = 0.7;
    //     context.beginPath();
    //     context.moveTo(px, yScale(data.low)+SINGAL_MARGIN);
    //     context.lineTo((px+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+CANDLEWIDTH), yScale(data.low)+SINGAL_MARGIN);
    //     context.lineTo((px+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+CANDLEWIDTH), yScale(data.high)-SINGAL_MARGIN);
    //     context.lineTo(px, yScale(data.high)-SINGAL_MARGIN);
    //     context.fillStyle=data.signal=="IS"?CHART_COLORS.SIGNAL_IS_BK:CHART_COLORS.SIGNAL_IB_BK;
    //     context.fill();
    //   context.globalAlpha = 1.0;

   
      
    //   }

    // }

      //profile start
      if(data.index==0){
        context.fillStyle=CHART_COLORS.LC_DAY_START_LINE;
        context.fillRect(xCord-PROFILE_LEFT_MARGIN, yScale(scaleY.domain()[1]),LC_CHART_LINE_WIDTH.DAY_START_LINE, canvasHeight/yTransform.k);
      }
      
    
      context.restore();
      contextTooltip.restore();

    }
    const drawVolumeBar=(xCord,data,tooltipData,scaleY,transform,fontSize,fontwt,yTransform,image,index)=>{
      context.save();
      contextTooltip.save();
    //   let color=data.open>=data.close?"#F7A9A7":"#8FD0C9";
      let color=CHART_COLORS.LC_VOL_BAR;
      let px=xCord;
     
      let outerX1=px+PROFILE_LEFT_MARGIN;
      let outerX2=px+PROFILE_LEFT_MARGIN+CANDLEWIDTH;
      // console.log("TESTING volumebar yscale2,val=",zY,index,historicalData.config.maxVol, scaleY.domain(),scaleY.range(),data.vol,scaleY(data.vol))
      let factor=transform.k;
      let yOffset=transform.y;
      context.beginPath();
      context.moveTo(outerX1, canvasHeight/factor-yOffset/factor);
      context.lineTo(outerX2, canvasHeight/factor-yOffset/factor);
      context.lineTo(outerX2, (canvasHeight+scaleY(data.vol)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
      context.lineTo(outerX1,  (canvasHeight+scaleY(data.vol)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
      // context.moveTo(outerX1, (-1*(zY.y/zY.k))*canvasHeight);
      // context.lineTo(outerX2, (-1*(zY.y/zY.k))*canvasHeight);
      // context.lineTo(outerX2, ((-1*(zY.y/zY.k))*canvasHeight)+scaleY(data.vol)-200);
      // context.lineTo(outerX1,  ((-1*(zY.y/zY.k))*canvasHeight)+scaleY(data.vol)-200);
      // context.lineTo(outerX2, yScale2(data.vol)-200);
      // context.lineTo(outerX1, yScale2(data.vol)-200);
      // context.lineTo(outerX2, yScale2(data.vol)+canvasHeight+200);
      // context.lineTo(outerX1, yScale2(data.vol)+canvasHeight+200);
     
      context.fillStyle=color
      context.fill();

      if(data.index==0){
        // context.fillStyle="#FF0000"
        context.fillStyle=CHART_COLORS.LC_DAY_START_LINE;
        context.fillRect(px-LC_CHART_LINE_WIDTH.DAY_START_LINE, (canvasHeight+0-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor),LC_CHART_LINE_WIDTH.DAY_START_LINE, BOTTOM_CONTAINER_HEIGHT/yTransform.k);
      }

      context.restore();
      contextTooltip.restore();
    }

    const drawPremBar=(xCord,data,tooltipData,scaleY,transform,fontSize,fontwt,yTransform,image,index,fieldName)=>{
      // if(data.doi<=0) return;
      console.log("SPTd3 prem=",data[fieldName],scaleY(0))
      context.save();
      contextTooltip.save();
    //   let color=data.open>=data.close?"#F7A9A7":"#8FD0C9";
      let color=CHART_COLORS.LC_SPT_PREM_BAR;
      let px=xCord;
     
      let outerX1=px+PROFILE_LEFT_MARGIN;
      let outerX2=px+PROFILE_LEFT_MARGIN+CANDLEWIDTH;
      // console.log("TESTING volumebar yscale2,val=",zY,index,historicalData.config.maxVol, scaleY.domain(),scaleY.range(),data.vol,scaleY(data.vol))
      let factor=transform.k;
      let yOffset=transform.y;
      let scaleZeroPos=BOTTOM_CONTAINER_HEIGHT-scaleY(0);
      context.beginPath();
      context.moveTo(outerX1,(canvasHeight/factor-yOffset/factor-scaleZeroPos/factor));
      context.lineTo(outerX2, (canvasHeight/factor-yOffset/factor-scaleZeroPos/factor));
      context.lineTo(outerX2, (canvasHeight+scaleY(data[fieldName])-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
      context.lineTo(outerX1,  (canvasHeight+scaleY(data[fieldName])-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
      // context.moveTo(outerX1, (-1*(zY.y/zY.k))*canvasHeight);
      // context.lineTo(outerX2, (-1*(zY.y/zY.k))*canvasHeight);
      // context.lineTo(outerX2, ((-1*(zY.y/zY.k))*canvasHeight)+scaleY(data.vol)-200);
      // context.lineTo(outerX1,  ((-1*(zY.y/zY.k))*canvasHeight)+scaleY(data.vol)-200);
      // context.lineTo(outerX2, yScale2(data.vol)-200);
      // context.lineTo(outerX1, yScale2(data.vol)-200);
      // context.lineTo(outerX2, yScale2(data.vol)+canvasHeight+200);
      // context.lineTo(outerX1, yScale2(data.vol)+canvasHeight+200);
     
      context.fillStyle=color
      context.fill();
      if(data.index==0){
        context.fillStyle=CHART_COLORS.LC_DAY_START_LINE;
        // context.fillStyle="#FF0000"
        context.fillRect(px-LC_CHART_LINE_WIDTH.DAY_START_LINE, (canvasHeight+0-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor),LC_CHART_LINE_WIDTH.DAY_START_LINE, BOTTOM_CONTAINER_HEIGHT/yTransform.k);
      }

      context.restore();
      contextTooltip.restore();
    }

    const drawOILine=(xCord,data,tooltipData,scaleY,transform,fontSize,fontwt,yTransform,image,i,dataNext,tooltipDataLast)=>{
        context.save();
        contextTooltip.save();
        context.strokeStyle=CHART_COLORS.LC_OI;
        // if(benchmarkingEnabled)
        // console.time("Benchmark_CANDLE_CUM_VWAP")
        // for(let i=leftIndex;i<=rightIndex;i=i+1){
          context.lineWidth =CC_CHART_LINE_WIDTH.OI/zY.k;
          let px=xCord;
          
          let oi=data.oi
          let factor=transform.k;
          let yOffset=transform.y;
          
      
          let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          let randomColor=rgbToHex(randomR,randomG,randomB);
      
          if(i!=rightIndex){
           let oiNext=dataNext.oi;

            context.lineWidth = CC_CHART_LINE_WIDTH.OI/zX.k;
            context.beginPath();
            // context.moveTo(px+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), scaleY(oi));
            // context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), scaleY(oiNext));
            context.moveTo(px+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), (canvasHeight+scaleY(oi)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
            context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), (canvasHeight+scaleY(oiNext)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
            
            context.stroke();
            contextTooltip.strokeStyle=randomColor;
          contextTooltip.lineWidth=2*CC_CHART_LINE_WIDTH.OI/zY.k;
          contextTooltip.beginPath();
          contextTooltip.moveTo(px+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), (canvasHeight+scaleY(oi)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
          contextTooltip.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), (canvasHeight+scaleY(oiNext)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
           
          contextTooltip.stroke();
          let tempData=[];
          console.log("LC D3 OI tooltip =",tooltipData);
          tempData.push({key:"OI",value:oi})
           tooltipData.push({
                 [randomColor]:tempData
               })

          }else{
            context.lineWidth = CC_CHART_LINE_WIDTH.OI/zY.k;
            context.beginPath();
            context.moveTo(px, (canvasHeight+scaleY(oi)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
            context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+dummyProfileWidth,(canvasHeight+scaleY(oi)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
            context.stroke();

          val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.strokeStyle=randomColor;
          contextTooltip.lineWidth=2*CC_CHART_LINE_WIDTH.OI/zY.k;
          contextTooltip.beginPath();
          contextTooltip.moveTo(px, (canvasHeight+scaleY(oi)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
          contextTooltip.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+dummyProfileWidth, (canvasHeight+scaleY(oi)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
          contextTooltip.stroke();
          let tempData=[];
          tempData.push({key:"OI",value:oi})
          if( tooltipDataLast.data[i+1]==undefined || tooltipDataLast.data[i+1].length==0){
            tooltipDataLast.data[i+1]=[];
          }
          tooltipDataLast.data[i+1].push({
                 [randomColor]:tempData
          })
        }
        
          // context.closePath();
        // }


        context.restore();
        contextTooltip.restore();
      }

      const drawLineContinousBottom=(xCord,data,tooltipData,scaleY,transform,i,dataNext,tooltipDataLast,fieldName,color,linewidth,tooltipProp,extendLine=true)=>{
        // context.save();
        // contextTooltip.save();
        context.strokeStyle=color;
        // if(benchmarkingEnabled)
        // console.time("Benchmark_CANDLE_CUM_VWAP")
        // for(let i=leftIndex;i<=rightIndex;i=i+1){
          context.lineWidth=linewidth/zY.k;
          let px=xCord;
          
          let curr=data[fieldName]
          let factor=transform.k;
          let yOffset=transform.y;
          
      
          // let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          // randomR = Math.floor(colorCounter / (255 * 255));
          // randomG = Math.floor((colorCounter / 255) % 255);
          // randomR = colorCounter % 255;
          // colorCounter=colorCounter+1;
          // let randomColor=rgbToHex(randomR,randomG,randomB);

          // console.log("rgbToHex 01=",randomColor)
      
          if(i!=rightIndex){
           let next=dataNext[fieldName];

            context.lineWidth = linewidth/zX.k;
            context.beginPath();
            // context.moveTo(px+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), scaleY(oi));
            // context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), scaleY(oiNext));
            context.moveTo(px+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), (canvasHeight+scaleY(curr)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
            context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), (canvasHeight+scaleY(next)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
            
            context.stroke();
          //   contextTooltip.strokeStyle=randomColor;
          // contextTooltip.lineWidth=2*linewidth/zY.k;
          // contextTooltip.beginPath();
          // contextTooltip.moveTo(px+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), (canvasHeight+scaleY(curr)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
          // contextTooltip.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), (canvasHeight+scaleY(next)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
           
          // contextTooltip.stroke();
          // let tempData=[];
          // console.log("LC D3 OI tooltip =",tooltipData);
          // tempData.push({key:tooltipProp,value:curr})
          //  tooltipData.push({
          //        [randomColor]:tempData
          //      })

          }else{
            if(extendLine){
            context.lineWidth = linewidth/zY.k;
            context.beginPath();
            context.moveTo(px, (canvasHeight+scaleY(curr)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
            context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+dummyProfileWidth,(canvasHeight+scaleY(curr)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
            context.stroke();

          // val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          // randomR = Math.floor(colorCounter / (255 * 255));
          // randomG = Math.floor((colorCounter / 255) % 255);
          // randomR = colorCounter % 255;
          // colorCounter=colorCounter+1;
          // randomColor=rgbToHex(randomR,randomG,randomB);
          // console.log("rgbToHex 1=",randomColor)
          // contextTooltip.strokeStyle=randomColor;
          // contextTooltip.lineWidth=2*linewidth/zY.k;
          // contextTooltip.beginPath();
          // contextTooltip.moveTo(px, (canvasHeight+scaleY(curr)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
          // contextTooltip.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+dummyProfileWidth, (canvasHeight+scaleY(curr)-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor));
          // contextTooltip.stroke();
          // let tempData=[];
          // tempData.push({key:tooltipProp,value:curr})
          // if( tooltipDataLast.data[i+1]==undefined || tooltipDataLast.data[i+1].length==0){
          //   tooltipDataLast.data[i+1]=[];
          // }
          // tooltipDataLast.data[i+1].push({
          //        [randomColor]:tempData
          // })
        }
        
          // context.closePath();
        // }


        // context.restore();
        // contextTooltip.restore();
      }
    }

      const drawLineContinousMain=(xCord,data,tooltipData,scaleY,transform,i,dataNext,tooltipDataLast,fieldName,color,linewidth,tooltipProp)=>{
        // context.save();
        // contextTooltip.save();
        context.strokeStyle=color;
          context.lineWidth=linewidth/zY.k;
          let px=xCord;
          
          let curr=data[fieldName]
          let factor=transform.k;
          let yOffset=transform.y;
          
      
          // let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          // randomR = Math.floor(colorCounter / (255 * 255));
          // randomG = Math.floor((colorCounter / 255) % 255);
          // randomR = colorCounter % 255;
          // colorCounter=colorCounter+1;
        

          // let randomColor=rgbToHex(randomR,randomG,randomB);
          // console.log("rgbToHex 02=",randomColor)
      
          if(i!=rightIndex){
           let next=dataNext[fieldName];

            context.lineWidth = linewidth/zX.k;
            context.beginPath();
            // context.moveTo(px+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), scaleY(oi));
            // context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), scaleY(oiNext));
            context.moveTo(px+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), scaleY(curr));
            context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), scaleY(next));
            context.stroke();

            // contextTooltip.strokeStyle=randomColor;
            // contextTooltip.lineWidth=2*linewidth/zY.k;
            // contextTooltip.beginPath();
            // contextTooltip.moveTo(px+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), scaleY(curr));
            // contextTooltip.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+PROFILE_LEFT_MARGIN+(CANDLEWIDTH/2), scaleY(next));
           
            // contextTooltip.stroke();
            // let tempData=[];
            // console.log("LC D3 OI tooltip =",tooltipData);
            // tempData.push({key:tooltipProp,value:curr})
            // tooltipData.push({
            //       [randomColor]:tempData
            //     })

          }else{
            context.lineWidth = linewidth/zY.k;
            context.beginPath();
            context.moveTo(px, scaleY(curr));
            context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+dummyProfileWidth,scaleY(curr));
            context.stroke();

          // val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          // randomR = Math.floor(colorCounter / (255 * 255));
          // randomG = Math.floor((colorCounter / 255) % 255);
          // randomR = colorCounter % 255;
          // colorCounter=colorCounter+1;
          // randomColor=rgbToHex(randomR,randomG,randomB);
          // console.log("rgbToHex 2=",randomColor,leftIndex-rightIndex)

          // contextTooltip.strokeStyle=randomColor;
          // contextTooltip.lineWidth=2*linewidth/zY.k;
          // contextTooltip.beginPath();
          // contextTooltip.moveTo(px, scaleY(curr));
          // contextTooltip.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+dummyProfileWidth, scaleY(curr));
          // contextTooltip.stroke();
          // let tempData=[];
          // tempData.push({key:tooltipProp,value:curr})
          // if( tooltipDataLast.data[i+1]==undefined || tooltipDataLast.data[i+1].length==0){
          //   tooltipDataLast.data[i+1]=[];
          // }
          // tooltipDataLast.data[i+1].push({
          //        [randomColor]:tempData
          // })
        }
        
          // context.closePath();
        // }


        // context.restore();
        // contextTooltip.restore();
      }

      const drawLineStepMain=(xCord,data,tooltipData,scaleY,transform,i,dataNext,tooltipDataLast,fieldName,color,linewidth,tooltipProp,isDashLine=false,dashVal)=>{
         context.save();
        // contextTooltip.save();
          context.strokeStyle=color;
          context.lineWidth=linewidth/zY.k;

          if(isDashLine)
            context.setLineDash([dashVal[0]/transform.k,dashVal[1]/transform.k]);
         
          let px=xCord;
          
          let curr=data[fieldName]
         
      
          // let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          // randomR = Math.floor(colorCounter / (255 * 255));
          // randomG = Math.floor((colorCounter / 255) % 255);
          // randomR = colorCounter % 255;
          // colorCounter=colorCounter+1;
          // let randomColor=rgbToHex(randomR,randomG,randomB);

          context.beginPath();
          context.moveTo(px, scaleY(curr));
          context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN, scaleY(curr));
          context.stroke();
          
          // contextTooltip.strokeStyle=randomColor;
          // contextTooltip.lineWidth=2*linewidth/zY.k;
          // contextTooltip.beginPath();
          // contextTooltip.moveTo(px, scaleY(curr));
          // contextTooltip.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN, scaleY(curr));
          // contextTooltip.stroke();
          // let tempData=[];
          // console.log("LC d3 VPOC=",historicalData.config.data[i],historicalData.config.data)
          // tempData.push({key:tooltipProp,value:curr.toFixed(2)})
          //  tooltipData.push({
          //        [randomColor]:tempData
          //      })
      
          if(i!=rightIndex){
           let next=dataNext[fieldName];
           context.beginPath();
          
            context.moveTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN, scaleY(curr));
            context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN, scaleY(next));
            // console.log("VPOC test 2",i,px,px+PROFILE_MIN_WIDTH+40,yScale(historicalData.config.data[i].cumu_vpoc),yScale(historicalData.config.data[i+1].cumu_vpoc),2/zX.k,context.lineWidth);
            context.stroke();
       

          }else{
              // context.lineWidth = linewidth/zY.k;
            context.beginPath();
            
            context.moveTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN, scaleY(curr));
            context.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+dummyProfileWidth, scaleY(curr));
            context.stroke();

          // val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          // randomR = Math.floor(colorCounter / (255 * 255));
          // randomG = Math.floor((colorCounter / 255) % 255);
          // randomR = colorCounter % 255;
          // colorCounter=colorCounter+1;
          // randomColor=rgbToHex(randomR,randomG,randomB);
          // console.log("rgbToHex 3=",randomColor)
          // contextTooltip.strokeStyle=randomColor;
          // contextTooltip.lineWidth=2*linewidth/zY.k;
          // contextTooltip.beginPath();
          // contextTooltip.moveTo(px, scaleY(curr));
          // contextTooltip.lineTo(px+CANDLEWIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN+dummyProfileWidth, scaleY(curr));
          // contextTooltip.stroke();
          // let tempData=[];
          // tempData.push({key:tooltipProp,value:curr})
          // if( tooltipDataLast.data[i+1]==undefined || tooltipDataLast.data[i+1].length==0){
          //   tooltipDataLast.data[i+1]=[];
          // }
          // tooltipDataLast.data[i+1].push({
          //        [randomColor]:tempData
          // })
        }
        
          // context.closePath();
        // }


        context.restore();
        // contextTooltip.restore();
      }


      const drawBottomConatiainerLines=(xCord,transform,scaleY,yTransform,cord,width)=>{
        // return;
        context.save();
        let divisionFactor=yTransform.k>1?yTransform.k:1;
        let px=cord;
        let factor=transform.k;
        let yOffset=transform.y
        context.beginPath();
        let height=BOTTOM_CONTAINER_HEIGHT/divisionFactor;
       
        if(yTransform.k<1)
        height=height/yTransform.k;
  
         //table borders
       console.log("TESTBOTTOM")
        context.fillStyle=CHART_COLORS.LC_BOTTOM_BORDER;
        // context.fillStyle="#FF0000"
        context.fillRect(px, (canvasHeight+0-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor),width, 0.5/yTransform.k);
     
  
        context.closePath();
         
        
        let scaleZeroPos=BOTTOM_CONTAINER_HEIGHT-scaleY(0);
       
          context.beginPath();
          context.fillStyle=CHART_COLORS.LC_OPT_SPIKE_AXIS;
          context.fillRect(px, (canvasHeight/factor-yOffset/factor-scaleZeroPos/factor),width, 0.5/yTransform.k);
          context.closePath();
        
        context.restore();
      }
  
      const drawBottomConatiainerOverlay=(xCord,transform,scaleY,yTransform,cord,width)=>{
        context.save();
        let divisionFactor=yTransform.k>1?yTransform.k:1;
        let px=cord;
        let factor=transform.k;
        let yOffset=transform.y
        context.beginPath();
        let height=BOTTOM_CONTAINER_HEIGHT/divisionFactor;
       
        if(yTransform.k<1)
        height=height/yTransform.k;
  
        context.fillStyle=CHART_COLORS.LC_BOTTOM_BK;
        context.fillRect(px, (canvasHeight+0-BOTTOM_CONTAINER_HEIGHT)/factor-(yOffset/factor),width, height);
        
       context.restore();
  
      }
      const drawBottomConatiainerOverlay2=(xCord,transform,scaleY,yTransform,cord,width)=>{
        context.save();
        let divisionFactor=yTransform.k>1?yTransform.k:1;
        let px=cord;
        let factor=transform.k;
        let yOffset=transform.y
        context.beginPath();
        let height=BOTTOM_CONTAINER_HEIGHT2/divisionFactor;
       
        if(yTransform.k<1)
        height=height/yTransform.k;
  
        context.fillStyle=CHART_COLORS.LC_BOTTOM_BK;
        context.fillRect(px, (canvasHeight+0-BOTTOM_CONTAINER_HEIGHT-BOTTOM_CONTAINER_HEIGHT2)/factor-(yOffset/factor),width, height);
        
       context.restore();
  
      }
        
      const drawBottomConatiainerLines2=(xCord,transform,scaleY,yTransform,cord,width,type)=>{
        // return;
        context.save();
        let divisionFactor=yTransform.k>1?yTransform.k:1;
        let px=cord;
        let factor=transform.k;
        let yOffset=transform.y
        context.beginPath();
        let height=BOTTOM_CONTAINER_HEIGHT2/divisionFactor;
       
        if(yTransform.k<1)
        height=height/yTransform.k;
  
        let offset=yTransform.k>1.3?2:yTransform.k<1?-2:0;
  
         //table borders
       
        context.fillStyle=CHART_COLORS.LC_BOTTOM_BORDER;
        context.fillRect(px, (canvasHeight+0-BOTTOM_CONTAINER_HEIGHT-BOTTOM_CONTAINER_HEIGHT2)/factor-(yOffset/factor),width, 0.5/yTransform.k);
     
  
        context.closePath();
         
        
        // 0 axis for cot or doi bar
        if(type==CANDLE_BOTTOM_VIEW_TYPE.INV_BIAS){
            let scaleZeroPos=BOTTOM_CONTAINER_HEIGHT+BOTTOM_CONTAINER_HEIGHT2-scaleY(0);
            console.log("TESTBOTTOM Candle",(canvasHeight/factor-yOffset/factor-scaleZeroPos/factor),width,px)
            context.beginPath();
            context.fillStyle=CHART_COLORS.LC_OPT_SPIKE_AXIS;
            context.fillRect(px, (canvasHeight/factor-yOffset/factor-scaleZeroPos/factor),width, 0.5);
            context.closePath();
        }
  
        
        context.restore();
      }
      const drawLineStepMainDash=(xCord,arr,tooltipData,scaleY,transform,i,dataNext,tooltipDataLast,fieldName,color,linewidth,tooltipProp,isDashLine=false,dashVal,leftIndex,rightIndex,configData,extendLine=true)=>{
        const result = [];
        let currentValue = arr[leftIndex][fieldName];
        let count = 1;
        for (let i = leftIndex+1; i <= rightIndex; i++) {
          if (arr[i][fieldName] === currentValue) {
            count++;
          } else {
                result.push({ value: currentValue, count: count });
            currentValue = arr[i][fieldName];
           count = 1;
          }
        }
        // Push the last counted value
        result.push({ value: currentValue, count: count });
      
        //  console.log("GZERO values=",arr.slice(leftIndex, rightIndex + 1),result,arr[leftIndex][fieldName],arr[leftIndex+1][fieldName],arr[rightIndex][fieldName]);
        
        context.save();

        context.strokeStyle=color;
        context.lineWidth=linewidth/zY.k;
             if(isDashLine)
           context.setLineDash([dashVal[0]/transform.k,dashVal[1]/transform.k]);
          let startIndex=leftIndex;
          for(let i=0;i<result.length;i++){
            if(result[i].value!=null){
            context.beginPath();
            context.moveTo(xScaleRange[startIndex], scaleY(result[i].value));
            context.lineTo(xScaleRange[startIndex+result[i].count], scaleY(result[i].value));
            context.stroke();
            startIndex+=result[i].count;
            if(i!=result.length-1){
              context.beginPath();
              context.moveTo(xScaleRange[startIndex], scaleY(result[i].value));
              context.lineTo(xScaleRange[startIndex], scaleY(result[i+1].value));
              context.stroke();
            }else{
              if(extendLine){
                context.beginPath();
            
                context.moveTo(xScaleRange[startIndex], scaleY(result[i].value));
                context.lineTo(xScaleRange[startIndex]+dummyProfileWidth, scaleY(result[i].value));
                context.stroke();
              }
            }
            
           
            }else{
              startIndex+=result[i].count;
            }
          }

     

       context.restore();
       // contextTooltip.restore();
     }

     const drawBarUpDown=(xCord,data,tooltipData,scaleY,transform,fontSize,fontwt,yTransform,image,index,fieldName,height)=>{
     
      context.save();
      contextTooltip.save();
    //   let color=data.open>=data.close?"#F7A9A7":"#8FD0C9";
      let color=data[fieldName]>=0?CHART_COLORS.CANDLE_BAR_POSITIVE:CHART_COLORS.CANDLE_BAR_NEGATIVE;
      let px=xCord;
     
      let outerX1=px+PROFILE_LEFT_MARGIN;
      let outerX2=px+PROFILE_LEFT_MARGIN+CANDLEWIDTH;
      // console.log("TESTING volumebar yscale2,val=",zY,index,historicalData.config.maxVol, scaleY.domain(),scaleY.range(),data.vol,scaleY(data.vol))
      let factor=transform.k;
      let yOffset=transform.y;
      let scaleZeroPos=height-scaleY(0);
      context.beginPath();
      context.moveTo(outerX1,(canvasHeight/factor-yOffset/factor-scaleZeroPos/factor));
      context.lineTo(outerX2, (canvasHeight/factor-yOffset/factor-scaleZeroPos/factor));
      context.lineTo(outerX2, (canvasHeight+scaleY(data[fieldName])-height)/factor-(yOffset/factor));
      context.lineTo(outerX1,  (canvasHeight+scaleY(data[fieldName])-height)/factor-(yOffset/factor));
      // context.moveTo(outerX1, (-1*(zY.y/zY.k))*canvasHeight);
      // context.lineTo(outerX2, (-1*(zY.y/zY.k))*canvasHeight);
      // context.lineTo(outerX2, ((-1*(zY.y/zY.k))*canvasHeight)+scaleY(data.vol)-200);
      // context.lineTo(outerX1,  ((-1*(zY.y/zY.k))*canvasHeight)+scaleY(data.vol)-200);
      // context.lineTo(outerX2, yScale2(data.vol)-200);
      // context.lineTo(outerX1, yScale2(data.vol)-200);
      // context.lineTo(outerX2, yScale2(data.vol)+canvasHeight+200);
      // context.lineTo(outerX1, yScale2(data.vol)+canvasHeight+200);
     
      context.fillStyle=color
      context.fill();

      if(data.index==0){
        // context.fillStyle="#FF0000"
        context.fillStyle=CHART_COLORS.LC_DAY_START_LINE;
        context.fillRect(px-LC_CHART_LINE_WIDTH.DAY_START_LINE, (canvasHeight+0-height)/factor-(yOffset/factor),LC_CHART_LINE_WIDTH.DAY_START_LINE, height/yTransform.k);
      }
      context.restore();
      contextTooltip.restore();
    }   

   
   
    
   
   
    let t;
    let tY;
   if(zoomType==OF_ZOOM_TYPE.ASYMMETRIC){ 
     t = d3.zoomIdentity.translate(initialTransform.x, 0).scale(initialTransform.k);
     tY = d3.zoomIdentity.translate(initialTransformY.x, initialTransformY.y).scale(initialTransformY.k);
    // console.log("Recenter zoom event initialtransfomr x and y=",initialTransform,initialTransformY,t,tY,transformData,transformDataY)
   }else{
     t = d3.zoomIdentity.translate(initialTransform.x==0?-RIGHT_MARGIN_PX:initialTransform.x, initialTransform.y).scale(initialTransform.k);
   }
    
    // console.log("translateextent and zoom out scale=>",t,historicalData.config.totalWidth,(canvasWidth-historicalData.config.totalWidth),canvasWidth,canvasWidth/historicalData.config.totalWidth)
    // console.log("yScale range=",(yScale.range()[0]/TPO_HEIGHT)*selectedTPO,yScale.domain(),historicalData.config.max,historicalData.config.min,canvasHeight);
  

    let translateExtentUpward=canvasHeight;
    if((yScale.domain()[0]!=historicalData.config.min))
    translateExtentUpward=8*(Math.abs(yScale.domain()[0]-historicalData.config.min)/(yScale.domain()[1]-yScale.domain()[0]))*canvasHeight+(1.5*canvasHeight);
    
    let translateExtentDownward=-1*canvasHeight;
    if((yScale.domain()[1]!=historicalData.config.max))
    translateExtentDownward=-6*((Math.abs(yScale.domain()[1]-historicalData.config.max)/(yScale.domain()[1]-yScale.domain()[0]))*canvasHeight+(1.5*canvasHeight));
  
    let translateExtentRight=canvasWidth-historicalData.config.totalWidth;
    if(canvasWidth>historicalData.config.totalWidth)
    translateExtentRight=0;
    console.log("translateExtentUpward,translateExtentDownward =", translateExtentUpward,translateExtentDownward);
    
    // let ZOOM_OUT_SCALE=Math.max(ZOOM_OUT_EXTREME, canvasWidth/historicalData.config.totalWidth);
    // if(canvasWidth>=historicalData.config.totalWidth)
    // ZOOM_OUT_SCALE=1;
  
    

    // const zoom_function = d3.zoom()
    // .scaleExtent([ZOOM_OUT_SCALE, 2])
    // .translateExtent([[translateExtentRight, translateExtentDownward], [canvasWidth,translateExtentUpward]])
    // .on('start', (event) => {
    //   console.log("zoom start=====>",event.transform)
    //   setTransformDataAutoCenter(event.transform);
    // })
    // .on('zoom', (event) => {
    //         // if((Math.round(event.transform.x))%2==0){
    //         if(chartInterationAllowedRef.current){
    //         // setToggleRepaint(false);
    //         console.log("zoom event 2 =",event.transform,zX,zY);
            
    //         let transform = event.transform;
    //         let divisionFactor=transform.k>1?1:1;
    //         // transform.y=getYTransform(transform);
    //         transform.k=parseFloat(transform.k.toFixed(4));
    //         transform.x=parseFloat((transform.x/divisionFactor).toFixed(2));
    //         // transform.y=parseFloat(transform.y.toFixed(2));
    //         // transform.y=0;
    //         let scaleY=transform.k;
    //         let yTransform=transform.y;
    //         if(transform.k!=transformDataRef.current.k)
    //         yTransform=0;
    //         // if(transform.k>1)
    //         // scaleY=1;
    //         // let xt=transform.x;
    //         // if(transform.x!=0 && !redraw){
    //         // transform.x=transform.x*transform.k;
    //         // // redraw=false;
    //         // }
            
    //         context.save();
    //         context.clearRect(0, 0, canvasWidth, canvasHeight);
    //         context.translate(transform.x, yTransform);
    //         context.scale(transform.k, scaleY);
    //         contextDummy.save();
    //         contextDummy.clearRect(0, 0, canvasWidth, canvasHeight);
    //         contextDummy.translate(transform.x, yTransform);
    //         contextDummy.scale(transform.k, scaleY);
    //         contextOverlay.save();
    //         contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
    //         contextOverlay.translate(transform.x, yTransform);
    //         contextOverlay.scale(transform.k, scaleY);
    //         contextTooltip.save();
    //         contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
    //         contextTooltip.translate(transform.x, yTransform);
    //         contextTooltip.scale(transform.k, scaleY);
        
    //         draw(transform,false);
    //         // draw(transform,false,xt);
    //         context.restore();
    //         contextDummy.restore();
    //         contextOverlay.restore();
    //         contextTooltip.restore();
    //       }
    //     })
       
    //     .on('end', (event) => {
    //       console.log("zoom END=====>",event.transform,transformDataAutoCenterRef.current,autoCenterRef.current);
            
    //       if(autoCenterRef.current){
    //         let transform=event.transform;
    //         if(transform.k !=transformDataAutoCenterRef.current.k || transform.x !=transformDataAutoCenterRef.current.x || transform.y !=transformDataAutoCenterRef.current.y){
    //           let newY=getYTransform(transform,transformDataAutoCenterRef.current);
    //           console.log("zoom END=====>",transform.y,newY)
    //           if(transform.y!=newY){
    //             transform.y=newY;
    //             autoCenter(transform)
    //           }    
    //           // setTransformDataAutoCenter(transform);
             
    //         }
    //       }
    //     })

    function constrain(transform, extent, translateExtent) {
      let dx0 = transform.invertX(extent[0][0]) - translateExtent[0][0],
          dx1 = transform.invertX(extent[1][0]) - (translateExtent[1][0]+canvasWidth/transformDataRef.current.k-historicalData.config.widthList[historicalData.config.widthList.length-1]),
          dy0 = transform.invertY(extent[0][1]) - translateExtent[0][1],
          dy1 = transform.invertY(extent[1][1]) - translateExtent[1][1];
          console.log("constrain=",ZOOM_OUT_SCALE,transformDataRef.current.k,dx0,dx1,translateExtent[0][0],translateExtent[1][0],translateExtentRight,canvasWidth/ZOOM_OUT_SCALE,translateExtent,extent)
      return transform.translate(
        dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1),
        dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1)
      );
    }

    const zoom_function = d3.zoom()
    .scaleExtent([ZOOM_OUT_EXTREME, XSCALE_MAX_ZOOM])
    // .scaleExtent([1, 1])
    .constrain(constrain)
    // .scaleExtent([ZOOM_OUT_EXTREME, 1.8])
    .translateExtent([[translateExtentRight, translateExtentDownward], [canvasWidth,translateExtentUpward]])
    .on('start', (event) => {
      setIsZooming(true);
      console.log("zoom start=====>",event.transform)
      // setTransformDataAutoCenter(event.transform);
    })
    .on('zoom', (event) => {
            // if((Math.round(event.transform.x))%2==0){
            if(chartInterationAllowedRef.current){
              if(zoomType==OF_ZOOM_TYPE.SYMMETRIC){
              console.log("zoom event 2 =",event.transform);
            let transform = event.transform;
            // transform.y=getYTransform(transform);
            // if(canvasWidth>historicalData.config.totalWidth && transform.k<1)
            //   // transform.x=-1*transform.x*transform.k;
            //   transform.x=1/transform.k*transform.x;
            // else
            transform.k=parseFloat(transform.k.toFixed(4));
            transform.x=parseFloat(transform.x.toFixed(2));
            transform.y=parseFloat(transform.y.toFixed(2));

            // transform.x=canvasWidth/2+(transform.x-canvasWidth/2)/transform.k*prevZI.k;
            // let xt=transform.x;
            // if(transform.x!=0 && !redraw){
            // transform.x=transform.x*transform.k;
            // // redraw=false;
            // }
            if(transformDataRef.current.k==transform.k &&(transformDataRef.current.x!=transform.x)){
              console.log("Recenter X set to false");
              setIsRecenterAllowed(false)
             }
            
            context.save();
            context.clearRect(0, 0, canvasWidth, canvasHeight);
            context.translate(transform.x, transform.y);
            context.scale(transform.k, transform.k);
            contextDummy.save();
            contextDummy.clearRect(0, 0, canvasWidth+YSCALE_WIDTH-2, canvasHeight);
            contextDummy.translate(transform.x, transform.y);
            contextDummy.scale(transform.k, transform.k);
            contextOverlay.save();
            contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
            contextOverlay.translate(transform.x, transform.y);
            contextOverlay.scale(transform.k, transform.k);
            contextTooltip.save();
            contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
            contextTooltip.translate(transform.x, transform.y);
            contextTooltip.scale(transform.k, transform.k);
        
            draw(transform,false);
            // draw(transform,false,xt);
            context.restore();
            contextDummy.restore();
            contextOverlay.restore();
            contextTooltip.restore();
            }
           
          }
        })
       
        .on('end', (event) => {
          console.log("zoom END=====>",event.transform,transformDataAutoCenterRef.current,autoCenterRef.current);
          setIsZooming(false);
          console.log("OF Pan Test: zoom handlelive END Outer===>",savedLiveData,savedLiveDataRef.current)
          if(savedLiveData!=undefined || savedLiveDataRef.current!=undefined){
            console.log("OF Pan Test: zoom handlelive END Inner=====> rendering last live profile now");
            handleLiveData(savedLiveDataRef.current)
            
            
          }
            
          // if(autoCenterRef.current){
          //   let transform=event.transform;
          //   if(transform.k !=transformDataAutoCenterRef.current.k || transform.x !=transformDataAutoCenterRef.current.x || transform.y !=transformDataAutoCenterRef.current.y){
          //     let newY=getYTransform(transform,transformDataAutoCenterRef.current);
          //     console.log("zoom END=====>",transform.y,newY)
          //     if(transform.y!=newY){
          //       transform.y=newY;
          //       autoCenter(transform)
          //     }    
          //     // setTransformDataAutoCenter(transform);
             
          //   }
          // }
        })
       
        dummyCanvas.call(zoom_function.transform, t) .on("dblclick.zoom", null);
        dummyCanvas.call(zoom_function) .on("dblclick.zoom", null);
        // canvasTooltip.call(zoom_function.transform, t) .on("dblclick.zoom", null);
        // canvasTooltip.call(zoom_function) .on("dblclick.zoom", null);
        
        // zoomed(t,false);
        
        // zoomedNew(zX,zY,false);

        // const zoom_function_scale = d3.zoom()
        // .on('zoom', (event) => {
        //   console.log("drag function=",event);
        //   // return;
        //   console.log("zoom_function_scale",event.transform,transformDataRef.current)
        //   let transform = event.transform;
        //     // transform.y=getYTransform(transform);
            
        //     transform.x=transformDataRef.current.x;
        //     transform.y=transformDataRef.current.y;
        //   context.save();
        //     context.clearRect(0, 0, canvasWidth, canvasHeight);
        //     context.translate(transform.x, transform.y);
        //     context.scale(transformDataRef.current.k, transform.k);
        //     contextDummy.save();
        //     contextDummy.clearRect(0, 0, canvasWidth, canvasHeight);
        //     contextDummy.translate(transform.x, transform.y);
        //     contextDummy.scale(transformDataRef.current.k, transform.k);
        //     contextOverlay.save();
        //     contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
        //     contextOverlay.translate(transform.x, transform.y);
        //     contextOverlay.scale(transformDataRef.current.k, transform.k);
        //     contextTooltip.save();
        //     contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
        //     contextTooltip.translate(transform.x, transform.y);
        //     contextTooltip.scale(transformDataRef.current.k, transform.k);
        
        //     draw(transform,true);
        //     // draw(transform,false,xt);
        //     context.restore();
        //     contextDummy.restore();
        //     contextOverlay.restore();
        //     contextTooltip.restore();
        // })
       
        // .on('end', (event) => {
        // })

        const zoom_function_scale = d3.zoom()
        .scaleExtent([ZOOM_OUT_SCALE, YSCALE_MAX_ZOOM])
    // .translateExtent([[0, translateExtentDownward], [0,translateExtentUpward]])
        .on('start', (event) => {
          setIsZooming(true);
        })
        .on('zoom', (event) => {
          // console.log("zoom event 2 =",event.transform,zX,zY);
          
          let transform = event.transform;
          transform.k=parseFloat(transform.k.toFixed(4));
          // transform.x=parseFloat((transform.x/divisionFactor).toFixed(2));
           transform.x=0;
           if(transformDataRefY.current.k==transform.k &&transformDataRefY.current.y!=transform.y){
            // console.log("Recenter Y set to false",transform);
            // setIsRecenterAllowed(false)
           }
           
        

           console.log("zoom event 2 yScale =",transform);
        
            context.save();
            context.clearRect(0, 0, canvasWidth, canvasHeight);
            context.translate(zX.x, transform.y);
            context.scale(zX.k, transform.k);
            contextDummy.save();
            contextDummy.clearRect(0, 0, canvasWidth, canvasHeight);
            contextDummy.translate(zX.x, transform.y);
            contextDummy.scale(zX.k, transform.k);
            contextOverlay.save();
            contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
            contextOverlay.translate(zX.x, transform.y);
            contextOverlay.scale(zX.k, transform.k);
            contextTooltip.save();
            contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
            contextTooltip.translate(zX.x, transform.y);
            contextTooltip.scale(zX.k, transform.k);
        
            draw(transform,true);
            // draw(transform,false,xt);
            context.restore();
            contextDummy.restore();
            contextOverlay.restore();
            contextTooltip.restore();
        })
       
        .on('end', (event) => {
          setIsZooming(false);
          if(savedLiveData!=undefined || savedLiveDataRef.current!=undefined){
            console.log("OF Pan Test: Y axis zoom handlelive END=====> rendering last live profile now");
            handleLiveData(savedLiveDataRef.current)
            
            
          }
          // if(savedLiveData!=undefined){
          //   handleLiveData(savedLiveData)
          //   console.log("OF Pan Test: Y axis zoom handlelive END=====> rendering last live profile now");
          //   console.log("Test zoom handlelive END=====> rendering last live profile now");
          // }
        })

       
       if(zoomType==OF_ZOOM_TYPE.ASYMMETRIC){
        svgChart.call(zoom_function_scale.transform, tY) .on("dblclick.zoom", null);
         svgChart.call(zoom_function_scale).on("dblclick.zoom", null);
       }
    
  
         // setIsLoadingMoreProfiles(false);
         if((CHART_FACTOR_CC*canvasWidth)>historicalData.config.totalWidth && moreDataPresent && !props.chartRendered){
          // if((CHART_FACTOR_CC*canvasWidth)>historicalData.config.totalWidth && moreDataPresent){
          // setIsLoadingMoreProfiles(false);
          // loadMoreData(transform);
          // setIsLoadingMoreProfiles(false);
          console.log("CC TESTING Load more end===>",moreDataPresent,isLoadingMoreProfiles,historicalData,windowSize,props.chartStateData,toggleRepaint,metaData,canvasWidth,historicalData.config.totalWidth);
          // setIsLoadingMoreProfiles(true);
          loadMoreData();
         
        }else{
          // setIsLoadingMoreProfiles(false);
          // console.log("Load more end===> true props=",props.setChartRenderedState,props);
          props.setChartRenderedState(true);
          setDataLoaded(true);
          //props.setChartRenderedState(true);
        }
        
    },[historicalData,windowSize,props.chartStateData,toggleRepaint,metaData])
   
  
    //closes the context menu
    const exitMenu=()=>{
      setShowContextMenu(false);
     }

     /**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    let data = JSON.parse(JSON.stringify(historicalData));
    setHistoricalData(data);
    
  }
},[props.globalVolumeVisible,props.volumeNumberVisible])


  const recenterChart=()=>{
    if(historicalData!=undefined){
      setIsFirstLoad(true);
      setIsRecenterAllowed(true);
      setTransformData({k:1,x:0,y:0});
      setTransformDataY({k:1,x:0,y:0});
      setToggleRepaint(!toggleRepaint);
      }
     
  }

  const changeVisibilityMatrix=(matrix)=>{
    console.log("Visibility Matrix 1 =",matrix);
    setVisibilityMatrix(matrix)
  }

  useEffect(()=>{
    setToggleRepaint(!toggleRepaint)
    console.log("Visibility Matrix 2 =",visibilityMatrix);
  },[visibilityMatrix])

  const showHelp=()=>{
    setShowHelpPopup(true)
  }

    return (
    // <div className="chart-container"  style={{height:`calc(${props.chartStateData.height - 32 - 44}px)`,width:`calc(${props.chartStateData.width})`,marginTop:`calc(${props.chartStateData.top})`,marginLeft:`calc(${props.chartStateData.left})`}} >
    <>
    {true?  
    <>
     
    <div id={props.id} className={props.id} style={{height:"100%",width:"100%"}}>
  
        
      {/* {props.livePanelVisible && metaData? */}
       {candleData && historicalData?
      <>
     {/* <CandleLiveModal data={candleData}/> */}
     <LCLiveModal isAdmin={isAdmin} userSettings={props.userSettings} data={candleData} plot={props.params.plot} boundClassName={props.boundClass} visibilityMatrix={visibilityMatrix} changeVisibilityMatrix={changeVisibilityMatrix}/>
     
     </>:
     <></>
      }

{candleData && historicalData && BOTTOM_CONTAINER_HEIGHT>0 && props.params.plot=="spt"?
      <>
     <BPModal userSettings={props.userSettings} pro2User={false}
      tpo_group={props.selectedInstrumentData.tpo_group} isOPT={false}
      data={candleData} plot={"spt"} boundClassName={props.boundClass} visibilityMatrix={visibilityMatrix} changeVisibilityMatrix={changeVisibilityMatrix}
      panelKey={1} top={props.chartStateData.height-BOTTOM_CONTAINER_HEIGHT-60} height={BOTTOM_CONTAINER_HEIGHT}
      />
     
     </>:
     <></>
      }

{candleData && historicalData && BOTTOM_CONTAINER_HEIGHT2>0 && props.params.plot=="spt"?
      <>
     <BPModal userSettings={props.userSettings} pro2User={false}
      tpo_group={props.selectedInstrumentData.tpo_group} isOPT={false}
      data={candleData} plot={"spt"} boundClassName={props.boundClass} visibilityMatrix={visibilityMatrix} changeVisibilityMatrix={changeVisibilityMatrix}
      panelKey={2} top={props.chartStateData.height-BOTTOM_CONTAINER_HEIGHT-BOTTOM_CONTAINER_HEIGHT2-60}  height={BOTTOM_CONTAINER_HEIGHT}
    
      />
     
     </>:
     <></>
      }
      {/* <div style={{position:"fixed",top:140}}>
      <Typography>O:2400.1</Typography>
      </div> */}
      {historicalData?
        <LCFABModal recenterChart={recenterChart} showHelp={showHelp}chartStateData={props.chartStateData} boundClassName={props.boundClass} bottomMargin={props.selectedTimeFrame!=OF_TIME_FRAME_VALUES.tf_1_min?(tableVisible?4:4):(tableVisible?4:4)}></LCFABModal>
      :<></>
    }  

{showHelpPopup?
         <OFHelpPopup open={showHelpPopup} zoomType={zoomType}  data={undefined} proMessage={false} onClose={()=>setShowHelpPopup(false)}></OFHelpPopup>  :
         <></>
      }

     
    
   
    <div id={"watermark"+props.id} className="watermark">
    <text>{WATERMARK_CONFIG.TEXT}</text>
   
    </div>
  


 

{showTooltip?
    <Tooltip 
      xPosition={anchorPointTooltip.x}
      yPosition={anchorPointTooltip.y} 
      data={tooltipData}
      key={props.key}
      chartScreenType={props.chartScreenType}
      selectedTimeFrame={props.selectedTimeFrame}
      />:
    <></>}



    {isMoreDataLoading? 
    <div className="loading" >
      Loading additional data...
      </div>:
    <></>}

<div style={{display:'none'}}>
  {/* <img id="asterisk" src="../../../assets/images/asterisk.png" width="24" height="24" /> */}
</div>
     
 <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%"}}}>
            {msg}
          </Alert>  
       </Snackbar>
     


</div></>:
<></>
}

</>
  )
}

export default FuturesLineChartContainerNew
