import * as React from 'react';
import { useState,useRef,useEffect} from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Header from '../components/header';
import { Formik, Form, Field } from 'formik';
import { MuiTextFieldFormik } from '../../../components/CustomTextField/TextField';
import { validateForgotPasswordForm } from '../../../common/utility/validators';
import { useAxios } from '../../../common/api/hooks/useAxios';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';
import {Snackbar, Alert} from '@mui/material';
import WelcomeLayout from '../components/welcome-layout';
import FormContainer from '../components/form-container';
import ResetPasswordIcon from '../../../assets/images/password.svg'
import MainLayoutContainer from '../components/main-layout';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../../common/utility/constant';

 //form initial values for login
 const initialValues = {
  email_id: '',
};

export default function FORGOT_PASSWORD() {
  const theme=useTheme();
  const { cancel,responseData, error, loaded, reset,executeAPI } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const formikRef = useRef();
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });

  const { open, msg, severity } = msgState;
  const from = location.state?.from?.pathname || "/"; 
 /**
 * API response handler  
 */  
  useEffect(() => {
  
    if(loaded){
      if(responseData!=null){
        navigate(`/forgot-password-verification?emailId=${formikRef.current.values.email_id}`, { replace: true });
      }
      else if(error!==null){
        formikRef?.current.setSubmitting(false);
        if(error?.response.status===404){
          formikRef?.current.setErrors({email_id:"Email id not registered"})
        }else{
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
       
      }
      reset();
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loaded,responseData,error,navigate]);


  
/**
 * API caller  
 */  
  const onSubmit = (values, props) => {
    executeAPI(URL.FORGOT_PASSWORD,"POST",JSON.stringify(values));   
  }

/**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
  };


  return (
        <MainLayoutContainer>

        {/* Header component */}
        <Header showLogin={false} showIntro={true}/>
       
        {/*Welcome banner left component*/}
        <WelcomeLayout/>
       
        {/* Form container for right layut */}
        <FormContainer>
        <Box sx={{ display: 'flex',flexDirection: 'column', justifyContent:"center", hight:"100%", 
            width:{mobile:"90%", tablet:"75%", laptop:"60%"} }}>

            <img src={ResetPasswordIcon} height="72" width="72" alt="Email Verification" />
            
            <Typography  variant="titleMedium" sx={{ mt: {laptop:2,tablet:2,mobile:1}}}>
               Reset Password
            </Typography>
            
            <Typography  variant="descriptionMedium" sx={{ mt: {laptop:1,tablet:1,mobile:1}}}>
                Enter the email associated with you Vtrender account and we will send an email with intructions to reset your account.
            </Typography>

            <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} validate={validateForgotPasswordForm}>
              {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
              <Form>
                {/* Email field */}
                <Box>
                      <Field
                      component={MuiTextFieldFormik}
                      fullWidth
                      required
                      variant="outlined" 
                      size="small" 
                      color="inputFieldColor"
                      sx={{ mt: 3, mb: 3, }}
                      name="email_id"
                      type="email"
                      label="Email"
                    />
              </Box>
                <Button type='submit' sx={{mb:2}} variant="contained" disabled={isSubmitting}
                  fullWidth>{isSubmitting ? "Loading" : "Send Email"}</Button>
            </Form>
                    )}
          </Formik>
 
          <Grid item>
            <Typography variant="link">{"Remember Password? Back to "}  
               <Link href="/login" variant="link" sx={{pb:6}}>{"Sign in"} </Link>
            </Typography>
          </Grid>
 
          </Box>
 
        </FormContainer>

        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
  
   </MainLayoutContainer>
      
  );
}
