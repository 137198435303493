import * as React from 'react';
import Box from '@mui/material/Box';
import {Paper} from "@mui/material";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Header from '../components/header';
import { useNavigate, useLocation } from 'react-router-dom';
import MainLayoutContainer from '../components/main-layout';
import ReactPlayer from 'react-player/vimeo'
import ReactPlayerYT from 'react-player/youtube'
import Carousel from 'react-material-ui-carousel'
import { getNodeText } from '@testing-library/react';

const items = [
    {
        name: "Intro and Registration",
        src:"https://vimeo.com/802199332",
        yt_src: "https://youtu.be/QSy9oUdZxy8"
    },
    {
        name: "Options Table",
        src:"https://vimeo.com/802436176",
        yt_src: "https://youtu.be/EAhEGDhpYZg"
    },
    {
        name: "Market Profile Charts",
        src:"https://vimeo.com/802425925",
        yt_src: "https://youtu.be/Ght8jNRidPo"
    }
] 
export default function INTRO() {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [selectedIndex,setSelectedIndex]=React.useState(0);

  React.useEffect(() => {
    document.title = "Intro Videos";
  }, []);
 
  const goNext=()=>{
    if(selectedIndex<(items.length-1))
        setSelectedIndex(selectedIndex+1);
    else
        setSelectedIndex(0);
  }

  const goPrevious=()=>{
    if(selectedIndex==0)
        setSelectedIndex(items.length-1);
    else
        setSelectedIndex(selectedIndex-1);
  }

  return (
        <MainLayoutContainer>
            {/* Header component */}
            <Header showLogin={true} />
            <Box sx={{ display: 'flex',flexDirection: 'column', justifyContent:"center", height:"100%", alignItems:'center',
                width:{mobile:"100%", tablet:"100%", laptop:"100%"} }}> 
            
                <Carousel sx={{position:'relative',width:'100%',height:"100%",pb:0,mt:10,mb:0,maxWidth:"1280px"}} cycleNavigation={false} autoPlay={false}
                next={ () => goNext() }
                prev={ () => goPrevious() }
                onChange={(now,previous)=>{setSelectedIndex(now)}}
                >
                    {
                        items.map( (item, index) => 
                        <>
                        <Typography variant="h3" align="center">{item.name}</Typography>
                        <br />
                        <Paper sx={{position:'relative', marginTop:"10px",marginBottom:"40px",display:'flex',justifyContent:'center',height:"100%",width:"100%"}}>
                        {/* <ReactPlayer url={item.src} controls playing={selectedIndex==index} height="720px" width="1280px" /> */}
                        <ReactPlayerYT url={item.yt_src} controls playing={selectedIndex==index?false:false} height="720px" width="1280px" />
                        </Paper>
                        </>
                        )
                    }
                </Carousel>
            </Box>
      </MainLayoutContainer>
  );
}
