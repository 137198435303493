import React, { useLayoutEffect, useRef, useState,useEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import dark_theme from "@amcharts/amcharts5/themes/Dark";
import { Container, Box, Typography, useTheme, IconButton, Tooltip,Select,FormControl,Menu,MenuItem ,Slider,InputLabel,Button} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { WATERMARK_CONFIG,DateFormat,USER_ROLE_FIELDS,MenuProps,BAR_CHART_ADMIN_PLOT_TYPE, BAR_CHART_OI_PLOT_TYPE, BAR_CHART_MI_PLOT_TYPE,MIN_BAR_CHART_WIDTH } from '../../../common/utility/constant';
import dayjs from 'dayjs';
import { DatePicker} from 'antd';
import useAuth from '../../../common/hooks/useAuth';


const OptChainGexChartContainer = (props) => {
  // const seriesGexRef = useRef(null);
  // const seriesGexMaxRef = useRef(null);
  // const seriesGexMinRef = useRef(null);
  // const seriesGexCeRef = useRef(null);
  // const seriesCeMaxRef = useRef(null);
  // const seriesCeMinRef = useRef(null);
  // const seriesPeMaxRef = useRef(null);
  // const seriesPeMinRef = useRef(null);
  // const seriesGexPeRef = useRef(null);
  // const seriesDGexRef = useRef(null);
  // const seriesGcashRef = useRef(null);
  // const seriesWcashRef = useRef(null);
  // const seriesOIRef = useRef(null);
  // const seriesVolRef = useRef(null);
  // const seriesDoiRef = useRef(null);
  // const seriesIvRef = useRef(null);
  // const seriesIvcRef = useRef(null);
  // const seriesIvpRef = useRef(null);

  const spotCloseRef = useRef(null);
  const spotHighRef = useRef(null);
  const spotLowRef = useRef(null);

  const seriesNetGexRef = useRef(null);
  const seriesNetGex1Ref = useRef(null);
  const seriesTotalDgexRef = useRef(null);
  const seriesNetDgexRef = useRef(null);
  const seriesActiveNetGexRef = useRef(null);
  const seriesActiveTotalGexRef = useRef(null);

  const seriesNetGexFRef = useRef(null);
  const seriesNetGex1FRef = useRef(null);
  const seriesTotalDgexFRef = useRef(null);
  const seriesNetDgexFRef = useRef(null);
  const seriesActiveNetGexFRef = useRef(null);
  const seriesActiveTotalGexFRef = useRef(null);

  const range1Ref = useRef(null);
  const range2Ref = useRef(null);
  const range2DataItemRef = useRef(null);

  console.log("props", props);

  const { auth } = useAuth();
  const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
  const [isFastDataUser,setIsFastDataUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?true:false)
  const [isProUser,setIsProUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))?true:false)
  const [isPro2User,setIsPro2User]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2))?true:false)
  const [isAdminUser,setIsAdminUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN))?true:false)
  const[nDays,setnDays]=useState(props.nDays);
  const [startDate,setStartDate]=useState(props.startDate!=""? dayjs(props.startDate,DateFormat):"");
  const[plotType,setPlotType]=useState(props.plotType);
  const[menuType,setMenuType]=useState(props.menuType);
  const [expiry,setExpiry]=useState(props.expiry?props.expiry:props.expiryList?props.expiryList[0]:"");
  const [dateParams,setDateParams]=useState(props.startDate?props.startDate:props.dateList?props.dateList[0]:"");
  const [timeMinValue,setTimeMinValue]=useState(0)
  const [timeInterval,setTimeInterval]=useState(props.timeInterval);
  const [timeIncrements,setTimeIncrements]=useState(props.timeIncrements);
  const [targetTime,setTargetTime]=useState(props.targetTime)
  const [targetInterval,setTargetInterval]=useState(props.targetInterval)

  // console.log("+++++++", expiry, props)

  // const [spotClose,setSpotClose]=useState(props.metadata.spot)
  // const [spotHigh,setSpotHigh]=useState(props.metadata.spot_high)
  // const [spotLow,setSpotLow]=useState(props.metadata.spot_low)

  let spotClose =  props.metadata?.spot;
  let spotHigh =  props.metadata?.spot_high;
  let spotLow =  props.metadata?.spot_low;

  const d_list = ['_gcash', '_dgcash', '_igex', '_ngex', '_wcash', '_oi', '_doi', '_vol']
  const dlist = ['net_gcash', 'net_dgcash', 'total_gcash', 'total_dgcash', 'net_igex', 'net_ngex', 'total_igex', 'total_ngex', 'pcr', 'net_oi', 'total_oi', 'straddle', 'oi_pain']

  // console.log("-------menuType", menuType)
  let plot = props.plotParams.get("plot");
  let plot_name = plot;
  let green_color = "#01DF01";
  let red_color = "#FF0000";
  if(plot == "net_gcash" || plot == "total_gcash" || plot == "_gcash"){
    plot_name = plot.split("_")[0].charAt(0).toUpperCase() + plot.split("_")[0].slice(1) + " Gamma Exposure Value";
    green_color = "#6DD9E5";
    red_color = "#CC80F9";
    if(plot == "net_gcash"){
      green_color = "#CC80F9";
    }
  }
  else if(plot == "net_igex" || plot == "total_igex" || plot == "_igex"){
    plot_name = plot.split("_")[0].charAt(0).toUpperCase() + plot.split("_")[0].slice(1) + " Gamma Value";
    green_color = "#6DD9E5";
    red_color = "#CC80F9";
    if(plot == "net_gcash"){
      green_color = "#CC80F9";
    }
  }
  else if(plot == "net_dgcash" || plot == "total_dgcash" || plot == "_dgcash"){
    plot_name = plot.split("_")[0].charAt(0).toUpperCase() + plot.split("_")[0].slice(1) + " Inventory Resistance Estimate";
    green_color = "#6DD9E5";
    red_color = "#CC80F9";
    if(plot == "net_dgcash"){
      green_color = "#CC80F9";
    }
  }
  else if(plot == "net_ngex" || plot == "total_ngex" || plot == "_ngex"){
    plot_name = plot.split("_")[0].charAt(0).toUpperCase() + plot.split("_")[0].slice(1) + " Inventory Value";
    green_color = "#6DD9E5";
    red_color = "#CC80F9";
    if(plot == "net_dgcash"){
      green_color = "#CC80F9";
    }
  }
  else if(plot == "_wcash")
    plot_name = "Writers' Unrealized Cash"
  // console.log(props)

  // const theme = useTheme();
  const navigate = useNavigate();

  // console.log("CONTAINER BEGINS")
  // console.log(props)

 
useEffect(()=>{
  console.log("Bar chart: target time useEffect= ",props.targetTime);
  if(props.targetTime!=undefined)
  setTargetTime(props.targetTime)
},[props.targetTime])


  useLayoutEffect(() => {
    if(props.isError)
    return;
    
    let root = am5.Root.new(props.id, {
      // useSafeResolution: false  TODO: itest incase charts break on iOS device uncomment it
    });

    root.setThemes([dark_theme.new(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        // wheelX: "zoomX",
        wheelZoomPositionX: 1,
        pinchZoomX: false,
        pinchZoomY: false,
        paddingTop: 20,
        paddingRight: 15,
        paddingBottom: 30,
        paddingLeft: 15,
        layout: root.verticalLayout
      })
    );

    //Refere for zoom and pan https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/

    // uncomment the below line is to hide the chart zoom button
    // chart.zoomOutButton.set("forceHidden", true); 

    // add scrollbar to control
    // chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //   orientation: "horizontal"
    // }));

    let rendrerStrikes = am5xy.AxisRendererX.new(root, {
    });
    rendrerStrikes.grid.template.setAll({
      location: 0,
      disabled: false,
      strokeOpacity: 0.4,
      stroke: am5.color("#F9E15C"),
      strokeDasharray: [5, 3],
      cellStartLocation: 0.1,
      cellEndLocation: 0.9
    });
    rendrerStrikes.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.8,
      disabled: false,
    });
    rendrerStrikes.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
    });

    //create strike axis 
    let strikesAxis = am5xy.ValueAxis.new(root, {
      height: am5.percent(100),
      renderer: rendrerStrikes
    });

    // let strikesAxis = am5xy.CategoryAxis.new(root, {
    //   categoryField: "strike",
    //   // height: am5.percent(100),
    //   renderer: am5xy.AxisRendererX.new(root, {})
    // });

    let xAxis = chart.xAxes.push(
      strikesAxis
    );

    // let xAxis = chart.xAxes.push(
    //   am5xy.CategoryAxis.new(root, {
    //     categoryField: "strike",
    //     renderer: am5xy.AxisRendererX.new(root, {})
    //   })
    // );

    let valueAxisLhs1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        height: am5.percent(100),
        renderer: am5xy.AxisRendererY.new(root, {
        }),
      })
    );


    let rendererY= am5xy.AxisRendererY.new(root, {
      opposite: true,
    });
    rendererY.grid.template.setAll({
      disabled: false,
      // strokeOpacity: 0.8,
      // stroke: am5.color("#F9E15C"),
      // strokeDasharray: [5, 3],
    });

    let valueAxisRhs1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // syncWithAxis: valueAxisLhs1,
        height: am5.percent(100),
        renderer: rendererY,
      })
    );

    let seriesNetGex, seriesNetGex1, seriesTotalDgex, seriesNetDgex;
    let seriesNetGexF, seriesNetGex1F, seriesTotalDgexF, seriesNetDgexF;

    seriesNetGex = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "NET_GEX",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisRhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        valueYField: "net_gex",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesNetGex.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesNetGex.get("tooltip").label.set("text", "Net Gex: {valueY}")

    seriesNetGex1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "NET_GEX_SPIKES",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisRhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        valueYField: "net_gex1",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesNetGex1.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesNetGex1.get("tooltip").label.set("text", "Net Gex: {valueY}")

    seriesTotalDgex = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "TOTAL_DGEX",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisRhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        valueYField: "total_dgex",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesTotalDgex.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesTotalDgex.get("tooltip").label.set("text", "Total DGex: {valueY}")

    seriesNetDgex = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "NET_DGEX",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisRhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        valueYField: "net_dgex",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesNetDgex.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesNetDgex.get("tooltip").label.set("text", "Net DGex: {valueY}")

    // ----------
    // Fut time projected values
    seriesNetGexF = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "NET_GEX_F",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisRhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        valueYField: "net_gex_f",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesNetGexF.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesNetGexF.get("tooltip").label.set("text", "Net Gex: {valueY}")

    seriesNetGex1F = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "NET_GEX_SPIKES_F",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisRhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        valueYField: "net_gex1_f",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesNetGex1F.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesNetGex1F.get("tooltip").label.set("text", "Net Gex: {valueY}")

    seriesTotalDgexF = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "TOTAL_DGEX_F",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisRhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        valueYField: "total_dgex_f",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesTotalDgexF.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesTotalDgexF.get("tooltip").label.set("text", "Total DGex: {valueY}")

    seriesNetDgexF = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "NET_DGEX_F",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisRhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        valueYField: "net_dgex_f",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesNetDgexF.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesNetDgexF.get("tooltip").label.set("text", "Net DGex: {valueY}")

    // gwall high/low per activeNetGex and g0 per activeTotalGex
    let seriesActiveNetGex, seriesActiveTotalGex, seriesActiveNetDgex, seriesActiveTotalDgex;
    let seriesActiveNetGexF, seriesActiveTotalGexF, seriesActiveNetDgexF, seriesActiveTotalDgexF;

    seriesActiveNetGex = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "ACTIVE_TOTAL_GEX",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisRhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        // fillOpacity: 0.3,
        // valueYField: "active_exp_net_gex",
        valueYField: "active_exp_total_gex",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesActiveNetGex.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesActiveNetGex.get("tooltip").label.set("text", "Net Gex: {valueY}")

    seriesActiveTotalGex = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "TOTAL_GEX",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisLhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        // fillOpacity: 0.3,
        valueYField: "active_tgex",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesActiveTotalGex.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesActiveTotalGex.get("tooltip").label.set("text", "Net Gex: {valueY}")

    // Fut time projections
    seriesActiveNetGexF = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "ACTIVE_TOTAL_GEX_F",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisRhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        // fillOpacity: 0.3,
        // valueYField: "active_exp_net_gex",
        valueYField: "active_exp_total_gex_f",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesActiveNetGexF.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesActiveNetGexF.get("tooltip").label.set("text", "Net Gex: {valueY}")

    seriesActiveTotalGexF = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "TOTAL_GEX_F",
        xAxis: xAxis,
        zIndex: 1,
        // syncWithAxis: valueAxisRhs1,
        yAxis: valueAxisLhs1,
        // stroke: am5.color("#FF9933"),
        // fill: am5.color("#FF9933"),
        // fillOpacity: 0.3,
        valueYField: "active_tgex_f",
        valueXField: "level",
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    seriesActiveTotalGexF.strokes.template.setAll({
      strokeWidth: 1,
    });
    // seriesActiveTotalGexF.get("tooltip").label.set("text", "Net Gex: {valueY}")

    let rangeYDataItem = valueAxisRhs1.makeDataItem({
      value: 0,
    });
    let rangeY = valueAxisRhs1.createAxisRange(rangeYDataItem);
    rangeY.get("grid").setAll({
      stroke: am5.color("#ECF539"),
      strokeOpacity: 0.8,
      strokeWidth: 1,
      strokeDasharray: [3,2],
    });
    
    console.log("spot: ", props.metadata.spot)
    
    let range1DataItem = xAxis.makeDataItem({
      value: spotLow,
      endValue: spotHigh,
    });

    let range1 = xAxis.createAxisRange(range1DataItem);

    // range1.get("grid").setAll({
    //   stroke: am5.color("#FFDD33"),
    //   strokeOpacity: 1,
    // });

    range1DataItem.get("axisFill").setAll({
      fill: am5.color("#FFDD33"),
      fillOpacity: 0.2,
      strokeOpacity: 0,
      strokeWidth: 0,
      strokeDasharray: [],
      visible: true
    });

    let range2DataItem = xAxis.makeDataItem({
      value: spotClose,
    });

    let range2 = xAxis.createAxisRange(range2DataItem);

    range2.get("grid").setAll({
      stroke: am5.color("#FFFFFF"),
      strokeOpacity: 1,
      strokeWidth: 2,
      strokeDasharray: [],
    });

    range2DataItem.get("label").setAll({
      fill: am5.color("#ECF539"),
      text: "Spot: " + spotClose,
      inside: true,
      dy: -700,
      dx: 15,
      // background: am5.RoundedRectangle.new(root, {
      //   fill: am5.color("#FFFFFF"),
      // })
    });

    console.log("range2DataItem", range2DataItem);

    // Stack Y-axes
    chart.leftAxesContainer.set("layout", root.verticalLayout);
    chart.rightAxesContainer.set("layout", root.verticalLayout);

    seriesNetGexRef.current = seriesNetGex;
    seriesNetGex1Ref.current = seriesNetGex1;
    seriesTotalDgexRef.current = seriesTotalDgex;
    seriesNetDgexRef.current = seriesNetDgex;
    seriesActiveNetGexRef.current = seriesActiveNetGex;
    seriesActiveTotalGexRef.current = seriesActiveTotalGex;

    seriesNetGexFRef.current = seriesNetGexF;
    seriesNetGex1FRef.current = seriesNetGex1F;
    seriesTotalDgexFRef.current = seriesTotalDgexF;
    seriesNetDgexFRef.current = seriesNetDgexF;
    seriesActiveNetGexFRef.current = seriesActiveNetGexF;
    seriesActiveTotalGexFRef.current = seriesActiveTotalGexF;

    // seriesGexRef.current = seriesGex;
    // seriesGexMaxRef.current = seriesGexMax;
    // seriesGexMinRef.current = seriesGexMin;
    // seriesGexCeRef.current = seriesGexCe;
    // seriesCeMaxRef.current = seriesCeMax;
    // seriesCeMinRef.current = seriesCeMin;
    // seriesPeMaxRef.current = seriesPeMax;
    // seriesPeMinRef.current = seriesPeMin;
    // seriesGexPeRef.current = seriesGexPe;
    // seriesIvRef.current = seriesIv;
    // seriesIvcRef.current = seriesIvc;
    // seriesIvpRef.current = seriesIvp;

    spotCloseRef.current = spotClose;
    spotHighRef.current = spotHigh;
    spotLowRef.current = spotLow;

    range1Ref.current = range1;
    range2Ref.current = range2;
    range2DataItemRef.current = range2DataItem;

    //create chart legend
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      marginTop:20
    }));
    legend.data.setAll(chart.series.values);

    // //create chart title
    // chart.children.unshift(am5.Label.new(root, {
    //   text: "NIFTY Futures Line Chart",
    //   fontSize: 24,
    //   fontWeight: "500",
    //   textAlign: "center",
    //   x: am5.percent(50),
    //   centerX: am5.percent(50),
    //   paddingTop: 0,
    //   paddingBottom: 4
    // }));

    //add watermark text
    chart.plotContainer.children.push(am5.Label.new(root, {
      text: WATERMARK_CONFIG.LC_TEXT,
      y: am5.percent(100),
      centerY: am5.percent(100),
      opacity: 0.3,
      marginLeft: 10,
      marginBottom: 10,
      fontSize: 24,
      fontWeight: "400",
    }));

    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#000000"),
      strokeOpacity: 0.5,
      fill: am5.color("#000000"),
      fillOpacity: 0.7
    });

    // Add cursor for x axis
    chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomXY",
      xAxis: xAxis
    }));

    //add tooltip for x axis
    xAxis.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    strikesAxis.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    valueAxisRhs1.set("tooltip", am5.Tooltip.new(root, {
      themeTags: ["axis"]
    }));

    return () => {
      root.dispose();
    };

  }, [props.chartData]);

  //this will handle the props data change and set/update the chart
  useLayoutEffect(() => {
    // console.log("uselayout effect child ===========>>>>>>>>>>>>>>>");
    //1st load
    if(props.isError)
    return;
    if (props.chartData != undefined) {
      console.log("initial chart load");
      console.log(props);
      // console.log(range1Ref.current);
      if(props.metadata != undefined && range1Ref.current != undefined){
        // spotCloseRef.current = props.metadata.spot;
        // spotHighRef.current = props.metadata.spot_high;
        // spotLowRef.current = props.metadata.spot_low;

        range1Ref.current._settings.value = props.metadata.spot_low;
        range1Ref.current._settings.endValue = props.metadata.spot_high;

        range2Ref.current._settings.value = props.metadata.spot;

        range2DataItemRef.current.get("label").setAll({
          text: "Spot: " + props.metadata.spot,
        });

        // console.log(range1Ref.current);
      }
      seriesNetGexRef.current.data.setAll(props.chartData);
      seriesNetGex1Ref.current.data.setAll(props.chartData);
      seriesTotalDgexRef.current.data.setAll(props.chartData);
      seriesNetDgexRef.current.data.setAll(props.chartData);
      seriesActiveNetGexRef.current.data.setAll(props.chartData);
      seriesActiveTotalGexRef.current.data.setAll(props.chartData);

      seriesNetGexFRef.current.data.setAll(props.chartData);
      seriesNetGex1FRef.current.data.setAll(props.chartData);
      seriesTotalDgexFRef.current.data.setAll(props.chartData);
      seriesNetDgexFRef.current.data.setAll(props.chartData);
      seriesActiveNetGexFRef.current.data.setAll(props.chartData);
      seriesActiveTotalGexFRef.current.data.setAll(props.chartData);

      // if(dlist.includes(props.plotParams.get("plot")) && seriesGexRef.current != undefined && seriesGexRef.current.data != undefined) {
      //   seriesGexRef.current.data.setAll(props.chartData);
      //   // console.log("target Time", targetTime);
      //   seriesGexMaxRef.current.data.setAll(props.chartData);
      //   seriesGexMinRef.current.data.setAll(props.chartData);
      // } else if(d_list.includes(props.plotParams.get("plot")) && seriesGexCeRef.current != undefined && seriesGexCeRef.current.data != undefined) {
      //   seriesGexCeRef.current.data.setAll(props.chartData);
      //   seriesGexPeRef.current.data.setAll(props.chartData);
      //   if(props.plotParams.get("plot") == "_oi" && seriesCeMaxRef.current?.data != undefined && seriesPeMaxRef.current?.data != undefined) {
      //     seriesCeMaxRef.current.data.setAll(props.chartData);
      //     seriesCeMinRef.current.data.setAll(props.chartData);
      //     seriesPeMaxRef.current.data.setAll(props.chartData);
      //     seriesPeMinRef.current.data.setAll(props.chartData);
      //   }
      // } else if(props.plotParams.get("plot") == "iv" && seriesIvRef.current != undefined && seriesIvRef.current.data != undefined) {
      //   seriesIvRef.current.data.setAll(props.chartData);
      //   seriesIvpRef.current.data.setAll(props.chartData);
      //   seriesIvcRef.current.data.setAll(props.chartData);
      // }
    }
    // seriesNetGexRef.current?.hide();
    // seriesNetGex1Ref.current?.hide();
    // seriesActiveNetGexRef.current?.hide();
    // seriesActiveTotalGexRef.current?.hide();

    seriesTotalDgexRef.current?.hide();
    seriesNetDgexRef.current?.hide();

    seriesTotalDgexFRef.current?.hide();
    seriesNetDgexFRef.current?.hide();

  }, [props.plotParams, props.chartData, props.metadata]);


  function toggleFullScreen() {
    let chartdiv = document.querySelector(`#${props.id}`)
    if (!document.fullscreenElement) {
      chartdiv.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  const disabledDate=(current)=> {
    return (current && current >= dayjs().endOf('day'))||dayjs(current).day()==6 || dayjs(current).day()==0
  }
  const onDateChange = (date, dateString) => {
    setStartDate(dayjs(date))
    console.log("Date changed",dayjs(date),dateString);
    props.handleStartDateChange(dateString);
  }

  //for dropdown control
  const handleDateChange=(event)=>{
    setStartDate(event.target.value);
    setDateParams(event.target.value);
    //console.log("Date changed",dayjs(date),event.target.value);
    props.handleStartDateChange(event.target.value);
  }

  const handlePlotTypeChange=(event)=>{
    setPlotType(event.target.value);
    props.changePlotType(event.target.value);
  }
  
  const handleExpiryChange=(event)=>{
    setExpiry(event.target.value);
    props.handleExpiryChange(event.target.value);
  }

  const handleTargetTimeChange=(event)=>{
    let index=timeInterval.findIndex((item)=>item.value==event.target.value)
    if(index!=-1){
      console.log("changed time=",timeInterval[index]);
      // if(timeInterval[index].value>=timeMinValue){
        setTargetTime(timeInterval[index])
        props.handleTimeChange(timeInterval[index]);
      // }
  }
  }

  const handleTimeIntervalChange=(event)=>{
    let index=timeIncrements.findIndex((item)=>item.value==event.target.value)
    if(index!=-1){
      console.log("changed time interval=",timeIncrements[index]);
      // if(timeIncrements[index].value>=timeMinValue){
        setTargetInterval(timeIncrements[index])
        props.handleTimeIntervalChange(timeIncrements[index]);
      // }
  }
  }

  return (
    <>
      <div id="container" style={{ backgroundColor: "#111111", zIndex: "99", marginTop: "0px", padding: "20px", width: "100%",minWidth:`${MIN_BAR_CHART_WIDTH}`, minHeight: "calc(110vh - 256px)" }}>
      {/* <div id="container" style={{ backgroundColor: "#111111", zIndex: "99", marginTop: "0px", padding: "20px", width: "100%", minHeight: "calc(110vh - 256px)" }}> */}
        {/* <LineChartHeader/> */}
        <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: "row", justifyContent: "space-evenly" }}>
          {/* <Tooltip title="Go Back">
            <IconButton
              onClick={() => navigate(-1)}
              size='large'>
              <ArrowBackIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
          {/* <Container sx={{ width: "80%" }}>
            <Typography variant='h4' color='primary' sx={{ alignSelf: "center" }}>{props.symbol} Options Chain Bar Chart at {props.metadata.time}</Typography>
          </Container>
          <Tooltip title="Full Screen">
            <IconButton
              onClick={() => toggleFullScreen()}
              size='large'>
              <FullscreenIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
        </Box>
        <div id="chartContainer">
        {isProUser?
          <>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:1}}>
        
          {/* < Typography sx={{mt:1,ml:3,fontSize:16,width:"50px"}}>Expiry</Typography> */}
        
            <FormControl size="small" sx={{ width:"80px",minWidth:'120px',ml:2,mt:0 }}>
              <InputLabel id="demo-simple-select-label">Expiry</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={expiry}
                MenuProps={MenuProps}
                placeholder="Select Expiry"
                sx={{height:"40px"}}
                label="Expiry"
                onChange={(event)=>handleExpiryChange(event)}
              >
                {props.expiryList?.map((item,index) => (
                <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
          </FormControl>
          <FormControl size="small" sx={{ width:"80px",minWidth:'120px',ml:2,mt:0 }}>
            <InputLabel id="demo-simple-select-label">Date</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dateParams}
              MenuProps={MenuProps}
              sx={{height:"40px"}}
              label="Date"
              onChange={(event)=>handleDateChange(event)}
            >
              {props.dateList?.map((item,index) => (
              <MenuItem key ={item} value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <DatePicker onChange={onDateChange} 
                allowClear={false}
                disabledDate={disabledDate}
                value={startDate}
                placeholder="Select Date"
                format={DateFormat}
                style={{border: "1px solid #484848",background:"#111111",height:"40px",
                borderRadius: "4px",width:160,marginLeft:24,marginTop:"0px"}}
                />  */}
        
          {/* <Typography sx={{mt:1,ml:2,fontSize:16,width:"90px"}}>Plot Type</Typography> */}
          
          {menuType == "oi"
              ?
          <FormControl size="small" sx={{ width:"220px",minWidth:'250px',ml:3,mt:0 }}>
          <InputLabel id="demo-simple-select-label">Plot Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={plotType}
            MenuProps={MenuProps}
            sx={{height:"40px"}}
            label="Plot Type"
            onChange={(event)=>handlePlotTypeChange(event)}
          >
            
          {BAR_CHART_OI_PLOT_TYPE.map((item,index) => (
            <MenuItem key={item.key} value={item.value}>{item.key}</MenuItem>
          ))};
            
            
          </Select>
          </FormControl>  
          : 
          <>
          {isAdminUser ?
            <>
            <FormControl size="small" sx={{ width:"240px",minWidth:'250px',ml:3,mt:0 }}>
            <InputLabel id="demo-simple-select-label">Plot Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={plotType}
              MenuProps={MenuProps}
              sx={{height:"40px"}}
              label="Plot Type"
              onChange={(event)=>handlePlotTypeChange(event)}
            >
            {BAR_CHART_ADMIN_PLOT_TYPE.map((item,index) => (
              <MenuItem key={item.key} value={item.value}>{item.key}</MenuItem>
            ))};
            </Select>
            </FormControl>  
            </>
            :
            <>
            <FormControl size="small" sx={{ width:"220px",minWidth:'250px',ml:3,mt:0 }}>
            <InputLabel id="demo-simple-select-label">Plot Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={plotType}
              MenuProps={MenuProps}
              sx={{height:"40px"}}
              label="Plot Type"
              onChange={(event)=>handlePlotTypeChange(event)}
            >
            {BAR_CHART_MI_PLOT_TYPE.map((item,index) => (
              <MenuItem key={item.key} value={item.value}>{item.key}</MenuItem>
            ))};
            </Select>
            </FormControl>  
            </>
          }
          </>
          
        }
            <Box sx={{ width: 500,ml:8}}>
            <Slider
              // aria-label="Always visible"
              defaultValue={timeMinValue}
              value={targetTime.value}
              onChange={handleTargetTimeChange}
              step={1}
              marks={timeInterval}
              min={0}
              max={timeInterval[timeInterval.length-1].value}
            />  
            </Box>
            <Box>
              <Typography sx={{ml:4,marginTop:"4px",fontSize:"12px"}}>Time</Typography>
              <Typography sx={{ml:4,marginTop:"6px"}}>{targetTime.time}</Typography>
          </Box>
          <Box sx={{ width: 300,ml:8}}>
            <Slider
              // aria-label="Always visible"
              defaultValue={timeMinValue}
              value={targetInterval.value}
              onChange={handleTimeIntervalChange}
              step={1}
              marks={timeIncrements}
              min={0}
              max={timeIncrements[timeIncrements.length-1].value}
            />  
            </Box>
          <Button  variant="contained" disabled={!props.isDrity} onClick={()=>props.resetDateTime()} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,ml:4,pr:1,mt:1}}  >
              Reset
            </Button>
        </Box>
        </>
        :
        isFastDataUser?
          <>
          {menuType == "oi"?
            <FormControl size="small" sx={{ width:"220px",minWidth:'250px',ml:3,mt:0 }}>
            <InputLabel id="demo-simple-select-label">Plot Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={plotType}
              MenuProps={MenuProps}
              sx={{height:"40px"}}
              label="Plot Type"
              onChange={(event)=>handlePlotTypeChange(event)}
            >
              
            {BAR_CHART_OI_PLOT_TYPE.map((item,index) => (
              <MenuItem key={item.key} value={item.value}>{item.key}</MenuItem>
            ))};
            </Select>
            </FormControl>
          : 
          <></>  
          }
          </>
        :
        <></>}
        {!props.isError?
        <>
          <div id="chartcontrols" style={{ padding: "10px", marginTop: "16px", maxWidth: "100%" }}></div>
          <div id={props.id} style={{ width: "100%", maxWidth: "100%", height: "800px" }} />
          </>:
          <>
          {/* Error state */}
          </>}
        </div>
      </div>
    </>
  )
}

export default OptChainGexChartContainer
