import * as React from 'react';
import { useState,useEffect} from 'react';
import {Snackbar, Alert, CircularProgress} from '@mui/material';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Header from '../components/header';
import { useAxios } from '../../../common/api/hooks/useAxios';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from  '../../../common/hooks/useAuth';
import WelcomeLayout from '../components/welcome-layout';
import FormContainer from '../components/form-container';
import { useQuery } from '../../../common/utility/helperFunc';
import EmailVerificationIcon from '../../../assets/images/email-verification.svg';
import MainLayoutContainer from '../components/main-layout';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../../common/utility/constant';

 
export default function ACTUVATE_USER() {
  const { cancel,responseData, error, loaded, reset,executeAPI } = useAxios();
  const navigate = useNavigate();
  const { setAuth, persist, setPersist } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/login";
  const accessToken=useQuery("token");
  
  const [state, setState] = useState({
    loading:true,
    activated:false,
    open: false,
    msg:"" ,
    severity:"info"
  });

  const {loading,activated, open, msg,severity } = state;
  
  /**
   * Onload call to activate user the token present in the email link
   */
  useEffect(()=>{
    console.log("accesstoken in activate user=",accessToken);
    setAuth({accessToken});
    executeAPI(`${URL.ACTIVATE_USER}?token=${accessToken}`,"GET",null); 
  },[]);

  
  /**
 * API response handler  
 */  
  useEffect(() => {
  
    if(loaded){
        
      if(responseData!=null){
         setState({loading:false,activated:true,open:true,msg:responseData.message,severity:"success"});
      }
      else if(error!==null){
        console.log("Error=",error);
        setState({loading:false,activated:false,open:true,msg:error?.response?.data?.message ??`${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"info"});
       
      }
      reset();
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loaded]);

/**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
      setState({...state,open:false});
  };


  const navigateToHome =()=>{
    navigate(from, { replace: true });
  }

  return (
        <MainLayoutContainer>
        
        {/* Header component */}
        <Header showLogin={true} showIntro={true}/>
       
        {/*Welcome banner left component*/}
        <WelcomeLayout/>
       
        {/* Form container for right layut */}
        <FormContainer>
        
        <Box sx={{ display: 'flex',flexDirection: 'column', justifyContent:"center", hight:"100%", 
        width:{mobile:"90%", tablet:"75%", laptop:"60%"} }}> 
           
           {!loading?
            <>
            <img src={EmailVerificationIcon} height="72" width="72" alt="Email Verification" />
            {activated?
              <Typography  variant="titleMedium" sx={{ mt: {laptop:2,tablet:2,mobile:2}}}>
                  Welcome to Vtrender           
              </Typography>:
              
              <Typography  variant="descriptionLarge" sx={{ mt: {laptop:2,tablet:2,mobile:2}}}>
                {msg}           
              </Typography>
            }
            
            <Typography  variant="descriptionMedium" sx={{ mt: {laptop:1,tablet:1,mobile:1}}}>
            {activated?"Thank you for signing up with Vtrender.":""} 
            
            </Typography>
      
              <Grid item sx={{mt:2}}>
                {activated?
                  <>
                    <Typography variant="link">{"Please "}  </Typography>  
                    <Link  variant="link" onClick={navigateToHome}  sx={{pb:6,cursor: "pointer"}}>login</Link>
                    <Typography variant="link">{" to continue "}  </Typography>  
                  </>:
                  <></>
                }
              
              </Grid></>

              :<CircularProgress sx={{ml:"40%",mt:{mobile:2,laptop:8,tablet:8}}}/>}
          </Box>
        
        </FormContainer>
        
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
        </Snackbar>
 
      </MainLayoutContainer>
  );
}
