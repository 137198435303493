import * as React from 'react';
import {useState,useEffect,useRef,useContext} from 'react'
import { Box,Tabs,Tab,Divider,Tooltip } from '@mui/material';
import FeedIcon from '@mui/icons-material/Feed';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import {useTheme} from "@mui/material";
import { styled } from '@mui/material/styles';
import GridViewIcon from '@mui/icons-material/GridView';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { CHART_SCREEN_TYPE } from '../../../common/utility/mp_chart_constants';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Snackbar, Alert} from '@mui/material';
import { SNACKBAR_AUTO_HIDE_DURATION,MIN_WIDTH_3_COLS } from '../../../common/utility/constant';
import { TEXT_MSGS } from '../../../common/utility/constant';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      sx={{padding:"0px"}}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 0,
      width: '0%',
      backgroundColor: '#635ee7',
    },
  });
  
  const StyledTab = styled((props) => <Tab sx={{minWidth:'50px'}} disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(10),
      marginRight: theme.spacing(1),
      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-selected': {
        color: '#fff',
        backgroundColor:`${theme.palette.primaryTheme.shade03}`
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    }),
  );

  const StyledTabMultiChart = styled((props) => <Tab sx={{minWidth:'50px'}} disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(10),
      marginRight: theme.spacing(1),
      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-selected': {
        color: '#fff',
        backgroundColor:`${theme.palette.primaryTheme.shade03}`
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    }),
  );
export default function MPChartRightPanel(props) {
  const theme=useTheme();  
  const [value, setValue] = React.useState(-1);
  const [screenId, setScreenId] = React.useState(props.chartScreenType);
  const matches = useMediaQuery('(min-width:640px)');   //use to detect if device is tablet or desktop
  const matchesBigscreen = useMediaQuery(`(min-width:${MIN_WIDTH_3_COLS}px)`);
  
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  const { open, msg,severity } = msgState;

  /**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

  useEffect(()=>{
    console.log("handlechange RIght panel props.showLive=",props.showLive);
    if(props.showLive==false){
      setValue(-1);
    }
  },[props.showLive])

  useEffect(()=>{
    console.log("handlechange Toggle live");
      setValue(-1);
    
  },[props.toggleLiveButton])

  useEffect(()=>{
    console.log("handlechange Toggle live");
    setScreenId(props.chartScreenType);
    
  },[props.chartScreenType])

  const handleChange = (event, newValue) => {
    if(props.chartScreenType==CHART_SCREEN_TYPE.FULL_CHART){
      setValue(newValue);
    if(newValue==0 && value!=0){
      // setValue(newValue);
      props.openLivePanel(true)
    }
      else{ 

      props.closeModal();
      // props.openLivePanel(false)
      }
    }else{
      console.log("live panelhandleChange newValue= ",newValue)
      if(newValue!=0)
        props.openLivePanel(false)

        // props.closeModal();
      
    }
  };

  // const handleChange = (event, newValue) => {
  
  //   console.log("handlechange newValue=",newValue)
  //   if(props.chartScreenType==CHART_SCREEN_TYPE.FULL_CHART){
  //     console.log("handlechange newValue 2=",newValue)
  //   setValue(newValue);
    

  //   if(newValue==0)
  //     props.openLivePanel(true)
  //   }else{
  //     console.log("handlechange else resetrightpanel called=",newValue)
  //     if(newValue!=0)
  //       // props.openLivePanel(false)
  //       console.log("handlechange resetrightpanel called=",newValue)
  //       props.resetRightPanel();
      
  //   }
  // };

  

  const handleScreenChange = (event, newValue) => {
    // console.log("Prouser=",props.proUser)
    if(props.proUser){
      if(matches || newValue==0){
        if(newValue>3 && !matchesBigscreen){
          setMsgState({open:true,msg:TEXT_MSGS.MULTICHART_3_COLS_NOT_SUPPORTED,severity:"info"});
          return;
        }

        setScreenId(newValue);
        props.setChartScreenType(newValue,true)
      }else if(newValue>0){
        setMsgState({open:true,msg:TEXT_MSGS.MULTICHART_NOT_SUPPORTED,severity:"info"});
      }
      
    }else{
      props.openSubscribeModal();
    }
  };

  // const liveClick=()=>{
  //   console.log("handlechange liveclick=",value)
  //   if(value==0){
  //     setValue(-1)
  //     props.closeModal();
  //   }else{
  //     setValue(0);
  //     props.openLivePanel(true);
  //   }
  // }
  return (
    <>
    <Box
    sx={{flexGrow: 1, bgcolor: ` ${theme.palette.primaryTheme.shade01}`, display: 'flex',flexDirection:"column",
    width:"50px",
    position:"fixed",
    top:"48px",
    left:`calc(100% - 50px)`,
    // marginLeft:`calc(100% - 50px)`,
     height: `calc(100% - 4px)`}}>
      <StyledTabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="icon position tabs example"
        scrollButtons={false}
      >
        {/* onClick={()=>liveClick()} */}
          <StyledTab disabled={!props.isLiveEnabled} icon={<FeedIcon />} label="Live" /> 
          {/* <StyledTab icon={<NotificationAddIcon />}  label="Alerts" />
          <StyledTab icon={<DescriptionIcon />}  label="News" />
          <StyledTab icon={<NewspaperIcon />}  label="Notes" /> */}
      </StyledTabs>
      <Divider orientation="vertical" sx={{width:"100%",height:'1px',marginBottom:"8px",marginTop:"8px",backgroundColor:'primaryTheme.shade03'}} />
      <StyledTabs
        orientation="vertical"
        value={screenId}
        onChange={handleScreenChange}
        aria-label="icon position tabs example"
        scrollButtons={false}
      >
          {/* <Tooltip title="Full Chart"> */}
          <StyledTabMultiChart icon={<CheckBoxOutlineBlankIcon />}  />
          {/* </Tooltip> */}
          <StyledTabMultiChart icon={<VerticalSplitIcon />}   />
          <StyledTabMultiChart icon={<HorizontalSplitIcon />}  />
          <StyledTabMultiChart icon={<GridViewIcon />}   />
          <StyledTabMultiChart icon={<ViewColumnIcon />}   />
          <StyledTabMultiChart icon={<ViewModuleIcon />}   />
      </StyledTabs>
    </Box>
    <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
      <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
         {msg}
       </Alert>  
     </Snackbar>  
    </>
  );
}

