import React, { useState } from 'react'
import {Grid} from "@mui/material";
import { CONSTANTS } from '../../../../common/utility/constant';
import HeaderFixed from '../../../../layouts/header';
import Footer from '../../../../layouts/footer';
import TermsOfUseModal from '../../../dashboard/components/terms_of_use';
/**
 * 
 * @param {*} param0 
 * @returns Conatiner to be used in all the form for login/register flow
 */
function MainLayoutContainer({children}) {
   
    const padding=CONSTANTS.FIXED_HEADER_HEIGHT+CONSTANTS.FIXED_FOOTER_HEIGHT;
    const [openModal,setOpenModal]=useState(false);
    const [privacyPolicy,setPrivacyModal]=useState(false);
    const [contactInfo,setContactInfo]=useState(false);
    const [refundPolicy,setRefundModal]=useState(false);
    const [aboutUs,setAboutUs]=useState(false);
    const openTermModal=()=>{
        setOpenModal(true);
        setPrivacyModal(false);
        setContactInfo(false);
        setRefundModal(false);
        setAboutUs(false);
    }

    const openPrivacyModal=()=>{
        setOpenModal(true);
        setContactInfo(false);
        setPrivacyModal(true);
        setRefundModal(false);
        setAboutUs(false);
    }

    const openContactInfoModal=()=>{
        setOpenModal(true);
        setPrivacyModal(false);
        setContactInfo(true);
        setRefundModal(false);
        setAboutUs(false);
    }

    const openRefundModal=()=>{
        setOpenModal(true);
        setPrivacyModal(false);
        setContactInfo(false);
        setRefundModal(true);
        setAboutUs(false);
    }

    const openAboutUsModal=()=>{
        setOpenModal(true);
        setPrivacyModal(false);
        setContactInfo(false);
        setRefundModal(false);
        setAboutUs(true);
    }

    const closeModal=()=>{
        setOpenModal(false);
    }
    return (
        <>
            <HeaderFixed/>
            <div style={{paddingTop:`${CONSTANTS.FIXED_HEADER_HEIGHT}px`,paddingBottom:`${CONSTANTS.FIXED_FOOTER_HEIGHT}px`}}>
                <Grid container component="main" sx={{height: `calc(100vh - ${padding}px)` }}>    
                    {children}
                </Grid>
                {openModal?
                    <TermsOfUseModal closeModal={closeModal} privacyPolicy={privacyPolicy} contactInfo={contactInfo} refundPolicy={refundPolicy} aboutUs={aboutUs}></TermsOfUseModal>
                     : 
                <></>}
            </div>
            <Footer openTermModal={openTermModal} openPrivacyModal={openPrivacyModal} openContactInfoModal={openContactInfoModal} openRefundModal={openRefundModal} openAboutUsModal={openAboutUsModal}/>
        </>      
  )
}

export default MainLayoutContainer
