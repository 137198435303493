import React from 'react'
import { Link,Grid,Box, Select, Divider} from '@mui/material'
import { ToolbarFixed } from '../../../layouts/component.js';


/**
 * 
 * @returns Line chart header container for line chart settings.
 */

function LineChartHeader() {
  return (
    <div>
        <ToolbarFixed >
        <Grid  container >
            <Grid item mobile={4} tablet={4} laptop={4} >
              <Box display="flex"  alignItems={"center"}>
               <Link href="https://www.vtrender.com/" variant="secondaryLink"  target="_blank" rel="noopener noreferrer">Instruments</Link>
                {/* <Divider orientation="vertical" sx={{width:"2px", height:'32px'}} /> */}
              </Box>
            </Grid>
             
            <Grid item mobile={4} tablet={4} laptop={4} >
              <Box display="flex"  alignItems={"center"}>
                <Link href="https://www.vtrender.com/" variant="secondaryLink"  target="_blank" rel="noopener noreferrer">Start Data</Link>
                {/* <Divider orientation="vertical" sx={{width:"2px",height:'32px'}} /> */}
              </Box>
            </Grid>
            
            <Grid item mobile={4} tablet={4} laptop={4} >
              <Box display="flex"  alignItems={"center"}>
                <Link href="https://www.vtrender.com/" variant="secondaryLink"  target="_blank" rel="noopener noreferrer">No  of days</Link>
                {/* <Divider orientation="vertical" sx={{width:"2px",height:'32px'}} /> */}
              </Box>
            </Grid>
            
          </Grid>
        
        </ToolbarFixed>
       
    </div>
  )
}

export default LineChartHeader
