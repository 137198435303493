import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Checkbox,Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import { TABLE_COLORS,OPTIONS_TABLE_CHARTS } from './table_constants';
import AbcIcon from '@mui/icons-material/Abc';
import { ShowChartOutlined } from '@mui/icons-material';
import { USER_ROLE_FIELDS, USER_RESOURCES_LIST } from './constant';
import { INDEX_COMPONENTS_COLUMNS_LIST } from './constant';





export const CallVolumeRenderer=(props)=>{
    return <div style={{color:props.data.ce_vol_alert?TABLE_COLORS.HIGHTLIGHT_VOL:null,fontWeight:props.data.ce_vol_alert?700:null, alignSelf:"center",alignItems:"center !important"}}>
            {props.value!=undefined?
                <span>{props.value.toLocaleString(props.data.locale_string)}</span>:
                <></>
            }
        </div>
}

export const PutVolumeRenderer=(props)=>{
    return <div style={{color:props.data.pe_vol_alert?TABLE_COLORS.HIGHTLIGHT_VOL:null,fontWeight:props.data.pe_vol_alert?700:null, alignSelf:"center",alignItems:"center !important"}}>
            {props.value!=undefined?
                <span>{props.value.toLocaleString(props.data.locale_string)}</span>:
                <>-</>
             }
        </div>
}

export const StrikeRenderer=(props)=>{
    const handleMP=()=>{
        props.handleMPChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL_PUT_BOTH,props.data);
    }
    const handleLine=()=>{
        props.handleLineChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL_PUT_BOTH,props.data); 
    }
   
    // console.log("Strike row index=",props.rowIndex);
    return <div style={{alignSelf:"center",display:"flex",width:"100%",justifyContent:"flex-start", alignItems:"center !important"}}>
                <span>{props.value}</span>
                <div>
                {props.data.pe_instr!=undefined && props.data.ce_instr!=undefined?
                <>        
                    <Tooltip title={props.value + " Straddle MP chart"}>
                    <AbcIcon onClick={handleMP}sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}></AbcIcon>
                    </Tooltip>
                    <Tooltip title={props.value + " Straddle Line chart"}>
                    <ShowChartOutlined onClick={handleLine} sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}/>
                    </Tooltip>
                </>:
                <></>
                }
                </div>
            </div>
}

export const CallMaxMinOIRenderer=(props)=> {
    return  <div style={{alignSelf:"center",justifyContent:'flex-end'}}>
                {props.data.ce_max_oi!=undefined?
                    <>
                    <span >{props.data.ce_max_oi}</span>
                    <div style={{height:"20px"}}>
                        <span >{props.data.ce_min_oi}</span>
                    </div>
                    </>:
                    <div style={{display:'flex',justifyContent:'center'}}>-</div>
                }           
            </div>
}

export const CallDOIOIRenderer=(props)=> {
    if(props.data.ce_doi==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return <div style={{alignSelf:"center"}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end",marginLeft:"-8px"}}>
                    {props.data.ce_doi>=0?
                    <>
                        {props.data.ce_doi_alert?<span style={{fontSize:"14px",marginRight:"-6px", color:TABLE_COLORS.GREEN}}>**&nbsp;</span>:<></>}
                        <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>
                    </>:
                    <>
                        {props.data.ce_doi_alert?<span style={{fontSize:"14px",marginRight:"-6px", color:TABLE_COLORS.RED}}>**&nbsp;</span>:<></>}
                        <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>
                    </>
                    }
                    <span style={{color: props.data.ce_doi>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{props.data.ce_doi.toLocaleString(props.data.locale_string)}</span>
                </div>
                <div style={{height:"20px",display:"flex",marginTop:"-5px",justifyContent:"flex-end"}}>
                    <span >{props.data.ce_oi_alert?"* "+props.data.ce_oi.toLocaleString(props.data.locale_string):props.data.ce_oi.toLocaleString(props.data.locale_string)}</span>
                </div>
            </div>
}
    
export const CallCHGLTPRenderer=(props)=> {

    const handleMP=()=>{
        props.handleMPChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL,props.data);
    }
    const handleLine=()=>{
        props.handleLineChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL,props.data); 
    }
    if(props.data.ce_change==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>
   else return <div style={{alignSelf:"center"}}>
    <div style={{display:'flex',width:"110px",marginRight:"5px",alignItems:'center',justifyContent:"flex-start"}}>
     <div style={{display:'flex',width:"70px",justifyContent:"flex-end"}}>
     {props.data.ce_change>=0?
         <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>:
         <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>}
         <div>
         <span style={{color: props.data.ce_change>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{props.data.ce_change}</span>
         </div>
         </div>   
         {props.data.ce_instr!=undefined?
             <Tooltip title={props.data.key + " Call MP chart"}>
                 <AbcIcon onClick={handleMP}sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}></AbcIcon>
             </Tooltip>:
             <></>
         }

     </div>
     <div style={{height:"20px",marginRight:"5px",display:"flex",justifyContent:"flex-start"}}>
     <div style={{display:'flex',width:"70px",justifyContent:"flex-end"}}>
         <span style={{fontWeight:700}}>{props.data.ce_ltp}</span>
         </div>
         {props.data.ce_instr!=undefined?
             <Tooltip title={props.data.key + " Call Line chart"}>
                 <ShowChartOutlined onClick={handleLine} sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}/>
             </Tooltip>:
             <></>
         }
     </div>
 </div>;
   
}

export const CallVWAPVPOCRenderer=(props)=> {
    if(props.data.ce_3d_vwap==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return <div style={{alignSelf:"center"}}>
             <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end", marginLeft:"-8px"}}>
            {props.data.ce_3d_vwap!=undefined && props.data.ce_3d_vwap!=null? 
                 <>
                (&nbsp;<span style={{color: TABLE_COLORS.VPOC,fontWeight:500,fontStyle:'italic'}} >{props.data.ce_3d_vpoc}</span>&nbsp;/&nbsp;
                <span style={{color: TABLE_COLORS.VWAP,fontWeight:500,fontStyle:'italic'}} >{props.data.ce_3d_vwap}</span>&nbsp;)
                </>:
                <span></span>}
                </div>
                <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                    <span style={{color: TABLE_COLORS.VPOC,fontWeight:700}} >{props.data.ce_vpoc_alert==true?"** "+props.data.ce_vpoc:props.data.ce_vpoc}</span>&nbsp;/&nbsp;
                    <span style={{color: TABLE_COLORS.VWAP,fontWeight:700}} > {props.data.ce_vwap}</span>
                </div>
            </div>;
}

export const PutMaxMinOIRenderer=(props)=> {
    if(props.data.pe_max_oi==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>
    else return <div style={{alignSelf:"center"}}><span >{props.data.pe_max_oi}</span>
               <div style={{height:"20px"}}>
                    <span >{props.data.pe_min_oi}</span></div>
            </div>;
}
                
export const PutDOIOIRenderer=(props)=> {
    if(props.data.pe_doi==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return <div style={{alignSelf:"center"}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end",marginLeft:"-8px"}}>{props.data.pe_doi>=0?
                    <>
                    {props.data.pe_doi_alert?<span style={{fontSize:"14px",marginRight:"-6px", color:TABLE_COLORS.GREEN}}>**&nbsp;</span>:<></>}
                    <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>
                </>:
                <>
                    {props.data.pe_doi_alert?<span style={{fontSize:"14px",marginRight:"-6px", color:TABLE_COLORS.RED}}>**&nbsp;</span>:<></>}
                    <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>
                </>}
                    <span style={{color: props.data.pe_doi>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{props.data.pe_doi.toLocaleString(props.data.locale_string)}</span>
                </div>
                <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                    <span >{props.data.pe_oi_alert?"* "+props.data.pe_oi.toLocaleString(props.data.locale_string):props.data.pe_oi.toLocaleString(props.data.locale_string)}</span>
                </div>
        </div>;
}
                    
export const PutCHGLTPRenderer=(props)=> {
    const handleMP=()=>{
        props.handleMPChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_PUT,props.data);
    }
    const handleLine=()=>{
        props.handleLineChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_PUT,props.data); 
    }
    if(props.data.pe_change==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>
   
    else return <div style={{alignSelf:"center"}}>
                   <div style={{display:'flex',width:"110px",marginRight:"5px",alignItems:'center',justifyContent:"flex-start"}}>
                    <div style={{display:'flex',width:"70px",justifyContent:"flex-end"}}>
                    {props.data.pe_change>=0?
                        <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>:
                        <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>}
                        <div>
                        <span style={{color: props.data.pe_change>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{props.data.pe_change}</span>
                        </div>
                        </div>   
                        {props.data.pe_instr!=undefined?
                            <Tooltip title={props.data.key + " Put MP chart"}>
                                <AbcIcon onClick={handleMP}sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}></AbcIcon>
                            </Tooltip>:
                            <></>
                        }
     
                    </div>
                    <div style={{height:"20px",marginRight:"5px",display:"flex",justifyContent:"flex-start"}}>
                    <div style={{display:'flex',width:"70px",justifyContent:"flex-end"}}>
                        <span style={{fontWeight:700}}>{props.data.pe_ltp}</span>
                        </div>
                        {props.data.pe_instr!=undefined?
                            <Tooltip title={props.data.key + " Put Line chart"}>
                                <ShowChartOutlined onClick={handleLine} sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}/>
                            </Tooltip>:
                            <></>
                        }
                    </div>
                </div>;
}
                
export const PutVWAPVPOCRenderer=(props)=> {
    if(props.data.pe_3d_vwap==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>
   
    return <div style={{alignSelf:"center"}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end", marginLeft:"-8px"}}>
                    {props.data.pe_3d_vwap!=undefined && props.data.pe_3d_vwap!=null? 
                    <>
                        (&nbsp;<span style={{color: TABLE_COLORS.VPOC,fontWeight:500,fontStyle:'italic'}} >{props.data.pe_3d_vpoc}</span>&nbsp;/&nbsp;
                        <span style={{color: TABLE_COLORS.VWAP,fontWeight:500,fontStyle:'italic'}} >{props.data.pe_3d_vwap}</span>&nbsp;)
                    </>:
                    <span></span>}
                </div>
                <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                    <span style={{color: TABLE_COLORS.VPOC,fontWeight:700}} >{props.data.pe_vpoc_alert==true?"** "+props.data.pe_vpoc:props.data.pe_vpoc}</span>&nbsp;/&nbsp;
                    <span style={{color: TABLE_COLORS.VWAP,fontWeight:700}} > {props.data.pe_vwap}</span>
                </div>
            </div>;
}

export const OISpikeRenderer=(props)=> {
    if(props.data.ce_oi_spike==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return  <div style={{alignSelf:"center",alignItems:"center !important",width:"100%"}}>
                    <span style={{color: TABLE_COLORS.VWAP}} >CE: {props.data.ce_oi_spike}</span>
                    {/* <span >{props.data.ce_oi_spike}</span> */}
                    <div style={{height:"20px"}}>
                        <span style={{color: TABLE_COLORS.VPOC}} >PE: {props.data.pe_oi_spike}</span>
                        {/* <span >{props.data.pe_oi_spike}</span> */}
                    </div>
                </div>
}                                

export const PCRStraddleRenderer=(props)=> {
        if(props.data.pcr==undefined)
            return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

        else return  <div style={{alignSelf:"center",alignItems:"center !important",width:"100%"}}><span >{props.data.pcr}</span>
                    <div style={{height:"20px"}}>
                        <span >{props.data.straddle}</span>
                    </div>
                </div>
}

const SyntFutRenderer=(props)=>{
    if(props.data.synth_fut==undefined)
            return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return  <div style={{alignSelf:"center"}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                    <span>{props.data.synth_fut}</span>&nbsp;(
                    <span>{(props.data.spot-props.data.synth_fut).toFixed(2)}</span>
                    )&nbsp;[
                    <span>F:&nbsp;{(props.data.fut-props.data.synth_fut).toFixed(2)}</span>]
                </div>
            </div>
 }
 export const WCashLkRenderer=(props)=> {
    if(props.data.ce_wcash==undefined)
            return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return  <div style={{alignSelf:"center",alignItems:"center !important",width:"100%"}}>
                <span >CE:&nbsp;{(props.data.ce_wcash/100000).toFixed(2)}</span>
                <div style={{height:"20px"}}>
                    <span >PE:&nbsp;{(props.data.pe_wcash/100000).toFixed(2)}</span>
                </div>
            </div>
}




export const DefaultRenderer=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
        {props.value!=undefined?
            <span>{props.value.toLocaleString(props.data.locale_string)}</span>:
            <>-</>
        }
        </div>
}

export const DefaultRendererGeneric=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
        {props.value!=undefined && props.value!==""?
            <span>{props.value}</span>:
            <>-</>
        }
        </div>
}


export const StockRenderer=(props)=>{
    const handleMP=()=>{
        if(props.basicUser)
            props.openSubscriptionModal();
        else
            props.handleMPChart(props.data.Stock);

    }
    return <div style={{display:'flex',width:"100%",alignSelf:"center"}}>
            {props.value!=undefined?
            <div style={{width:"90px",minWidth:"80px"}} >   
                <span style={{color:props.data.Change>=0?TABLE_COLORS.VWAP:TABLE_COLORS.VPOC}}>{props.value}</span>
            </div> :
                <>-</>
            }
            {props.data.Stock!=undefined && props.data.Stock!=""?
            <div style={{width:"40px"}} >        
                <Tooltip title={props.data.Stock + "  MP chart"}>
                <AbcIcon onClick={handleMP}sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}></AbcIcon>
                </Tooltip>
                
            </div>:
            <></>
            }
        </div>
}

export const NetRenderer=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
        {props.value!=undefined?
            <span style={{color:props.data.Net>=0?TABLE_COLORS.VWAP:TABLE_COLORS.VPOC}}>{props.value.toLocaleString(props.data.locale_string)}</span>:
            <>-</>
        }
        </div>
}

export const AvgDailyVol5_14=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
        {props.data["AvgDailyVol5/14"]?
            <>
            <span>{props.data["AvgDailyVol5/14"]["5D"].toLocaleString(props.data.locale_string)}&nbsp;/&nbsp;</span>
            <span>{props.data["AvgDailyVol5/14"]["14D"].toLocaleString(props.data.locale_string)}</span>
            </>

            :
            <>-</>
        }
        </div>
}


export const MaxDailyVol5_14_M=(props)=>{
    return <div style={{display:'flex',width:"100%",height:"100%", alignSelf:"center",justifyContent:"flex-end",alignItems:"center"}}>
        {props.data["MaxDailyVol"]?
            <>
            <div style={{display:'flex',marginRight:"6px", justifyContent:"flex-end"}}>
            <span>{props.data["MaxDailyVol"]["5D"][0].toLocaleString(props.data.locale_string)}&nbsp;</span>
            <span style={{marginRight:"6px"}}>{props.data["MaxDailyVol"]["5D"][1]!=""?"("+props.data["MaxDailyVol"]["5D"][1]+")":"(-)"}</span>
            </div>
            <div style={{display:'flex',marginRight:"6px", justifyContent:"flex-end",borderLeft:"1px solid #fff",borderRight:"1px solid #fff",height:"100%",alignItems:'center'}}>
            <span>&nbsp;&nbsp;{props.data["MaxDailyVol"]["14D"][0].toLocaleString(props.data.locale_string)}&nbsp;</span>
            <span style={{marginRight:"6px"}}>{props.data["MaxDailyVol"]["14D"][1]!=""?"("+props.data["MaxDailyVol"]["14D"][1]+")":"(-)"}</span>
            </div>
            <div style={{display:'flex',marginRight:"6px", justifyContent:"flex-end"}}>
            <span>{props.data["MaxDailyVol"]["M"][0].toLocaleString(props.data.locale_string)}&nbsp;</span>
            <span style={{marginRight:"6px"}}>{props.data["MaxDailyVol"]["M"][1]!=""?"("+props.data["MaxDailyVol"]["M"][1]+")":"(-)"}</span>
            </div>
            </>
        

            :
            <>-</>
        }
        </div>
}


 export const getColumneDef=(auth,openMPChart,showSubscribeModal,locale_string,hidden_cols)=>{
    let proUser=auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA)?true:false;
    let def;
    if(proUser){
        def=[
            {
                headerName:"",
                groupId:"generic",
                children:[
                    { field: 'name',headerName:"Stock" ,minWidth: 140,maxWidth:150,cellRenderer:StockRenderer,sortable:true,unSortIcon:true,
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                        cellRendererParams: {
                            handleMPChart:openMPChart,
                            basicUser:false,
                            openSubscriptionModal:showSubscribeModal,
                        }, 
                    },
                    { field: 'High',headerName:"High" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.high),
                        cellStyle: function(params) {
                            return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Low',headerName:"Low" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.low),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'LTP/Close',headerName:"LTP / Close" ,minWidth: 110,maxWidth:110,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.ltp),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Change',headerName:"Change" ,minWidth: 90,maxWidth:110,cellRenderer:DefaultRendererGeneric,sortable:true,unSortIcon:true,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.change),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Change%',headerName:"Change %" ,minWidth: 100,maxWidth:130,cellRenderer:DefaultRendererGeneric,sortable:true,unSortIcon:true,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.change_percent),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'TR',headerName:"TR" ,minWidth: 60,maxWidth:80,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.tr),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'ATR5/14',headerName:"ATR 5 / 14" ,minWidth: 80,maxWidth:100,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.atr),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Vol',headerName:"Vol" ,minWidth: 90,maxWidth:110,cellRenderer:DefaultRenderer,sortable:true,unSortIcon:true,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.vol),
                        cellStyle: function(params) {
                            return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    
                        },
                        cellRendererParams: {
                            locale_string: locale_string,
                        
                        },  
                    },
                    { field: 'AvgDailyVol',headerName:"Avg Daily Vol 5 / 14" ,minWidth: 180,maxWidth:220,cellRenderer:AvgDailyVol5_14,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.avg_daily_vol),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'MaxDailyVol',headerName:"Max Daily Vol 5 / 14 / M" ,minWidth: 450,maxWidth:510,cellRenderer:MaxDailyVol5_14_M,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.max_vol),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Wt%',headerName:"Wt %" ,minWidth: 80,maxWidth:80,sortable:true,unSortIcon:true,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.wt_percent),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Time',headerName:"Time" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererGeneric,
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    
                ]
            },
            {
                headerName:"IDX Change Pts",
                children:[
                    { field: 'PerPt',headerName:"Per Pt" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.idx_change_pts),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Per%',headerName:"Per %" ,minWidth: 90,maxWidth:90,sortable:true,unSortIcon:true,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.idx_change_pts),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Net',headerName:"Net" ,minWidth: 70,maxWidth:90,cellRenderer:NetRenderer,sortable:true,unSortIcon:true,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.idx_change_pts),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                   
                   
                ]
            },
          
            ]
    }
    else{
        def=def=[
            {
                headerName:"",
                groupId:"generic",
                children:[
                    { field: 'name',headerName:"Stock" ,minWidth: 140,maxWidth:150,cellRenderer:StockRenderer,sortable:true,unSortIcon:true,
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                        cellRendererParams: {
                            handleMPChart:openMPChart,
                            basicUser:true,
                            openSubscriptionModal:showSubscribeModal,
                        }, 
                    },
                    { field: 'High',headerName:"High" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.high),
                        cellStyle: function(params) {
                            return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Low',headerName:"Low" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.low),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'LTP/Close',headerName:"LTP / Close" ,minWidth: 110,maxWidth:110,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.ltp),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Change',headerName:"Change" ,minWidth: 90,maxWidth:110,cellRenderer:DefaultRendererGeneric,sortable:true,unSortIcon:true,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.change),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Change%',headerName:"Change %" ,minWidth: 100,maxWidth:130,cellRenderer:DefaultRendererGeneric,sortable:true,unSortIcon:true,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.change_percent),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'TR',headerName:"TR" ,minWidth: 60,maxWidth:80,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.tr),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'ATR5/14',headerName:"ATR 5 / 14" ,minWidth: 80,maxWidth:100,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.atr),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Vol',headerName:"Vol" ,minWidth: 90,maxWidth:110,cellRenderer:DefaultRenderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.vol),
                        cellStyle: function(params) {
                            return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    
                        },
                        cellRendererParams: {
                            locale_string: locale_string,
                        
                        },  
                    },
                    { field: 'AvgDailyVol',headerName:"Avg Daily Vol 5 / 14" ,minWidth: 180,maxWidth:220,cellRenderer:AvgDailyVol5_14,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.avg_daily_vol),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'MaxDailyVol',headerName:"Max Daily Vol 5 / 14 / M" ,minWidth: 420,maxWidth:480,cellRenderer:MaxDailyVol5_14_M,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.max_vol),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Wt%',headerName:"Wt %" ,minWidth: 80,maxWidth:80,sortable:true,unSortIcon:true,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.wt_percent),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Time',headerName:"Time" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererGeneric,
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    
                ]
            },
            {
                headerName:"IDX Change Pts",
                children:[
                    { field: 'PerPt',headerName:"Per Pt" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.idx_change_pts),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Per%',headerName:"Per %" ,minWidth: 90,maxWidth:90,sortable:true,unSortIcon:true,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.idx_change_pts),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'Net',headerName:"Net" ,minWidth: 70,maxWidth:90,cellRenderer:NetRenderer,sortable:true,unSortIcon:true,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.idx_change_pts),
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                   
                   
                ]
            },
          
            ]
    }

            return def;
    }
    

    export const FutStockRenderer=(props)=>{
        const handleMP=()=>{
            props.handleMPChart(props.data.Stock);
    
        }
        return <div style={{display:'flex',width:"100%",alignSelf:"center"}}>
                {props.value!=undefined?
                <div style={{width:"90px",minWidth:"80px"}} >   
                    <span style={{color:props.data.Change>=0?TABLE_COLORS.VWAP:TABLE_COLORS.VPOC}}>{props.value}</span>
                </div> :
                    <>-</>
                }
                {props.data.Stock!=undefined && props.data.Stock!=""?
                <div style={{width:"40px"}} >        
                    <Tooltip title={props.data.Stock + "  MP chart"}>
                    <AbcIcon onClick={handleMP}sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}></AbcIcon>
                    </Tooltip>
                    
                </div>:
                <></>
                }
            </div>
    }
    
    export const FutOpenHighLowRenderer=(props)=> {
        return   <div style={{alignSelf:"center",width:"100%"}}>
                   
                    <>
                    <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                        <span style={{fontWeight:700}}>
                            {props.data["Open"] == props.data["High"]? <><ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:15,width:20}}/><span style={{color:TABLE_COLORS.RED}} >(O=H)</span></> 
                            : props.data["Open"] == props.data["Low"]? <><ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:15,width:20}}/><span style={{color:TABLE_COLORS.GREEN}} >(O=L)</span></> 
                            : ""} {props.data["Open"]}
                        </span>
                    </div>
                    <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                        <span style={{fontWeight:700}}>{props.data["High"]} / {props.data["Low"]}</span>
                    </div>
                    </>
                
                </div>
    }

    export const FutLtpCHGRenderer=(props)=> {
        return   <div style={{alignSelf:"center",width:"100%"}}>
                   
                    <>
                        <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end", marginLeft:"-8px"}}>
                        {props.data.Change>=0?
                            <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>:
                            <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>}
                            <span style={{color: props.data.Change>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >
                                {props.data.Change}&nbsp;({props.data["Change%"]}%)
                            </span>
                        </div>
                        <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                            <span style={{fontWeight:700}}>{props.data["LTP/Close"]}</span>
                        </div>
                    </>
                
                </div>
    }

    export const ValueAreaRenderer=(props)=>{
        return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
            {(props.data["VAH"] || props.data["VAL"])?
                <span>{props.data["VAH"]}-{props.data["VAL"]}&nbsp;/
                &nbsp;<span style={{color:TABLE_COLORS.VPOC}}>{props.data["VPOC"]}</span>-<span style={{color:TABLE_COLORS.VWAP}}>{props.data["VWAP"]}</span></span>:
                <>-</>
            }
            </div>
    }
    
    export const Vol_5_15_30_Renderer=(props)=>{
        return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
            {props.data["%Vol(-5)5/15/30mins"]?
                <span>({props.data["%Vol(-5)5/15/30mins"][0]})&nbsp;{props.data["%Vol(-5)5/15/30mins"][1]}&nbsp;/&nbsp;{props.data["%Vol(-5)5/15/30mins"][2]}&nbsp;/&nbsp;
                {props.data["%Vol(-5)5/15/30mins"][3]}</span>:
                <>-</>
            }
            </div>
    }

    export const VolContractsRenderer=(props)=>{
        return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
            {props.value!=undefined?
                <span>{parseInt(props.value/(props.data["LotSize"]?props.data["LotSize"]:1)).toLocaleString(props.data.locale_string)}</span>:
                <>-</>
            }
            </div>
    }

    export const Cot_10bar_Renderer=(props)=>{
        return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
            {props.value!=undefined && props.value[0] && props.value[1]?
                <span>
                    {props.value[0] < 0 ? 
                        <span style={{color:TABLE_COLORS.RED}}>{parseInt(props.value[0]/(props.data["LotSize"]?props.data["LotSize"]:1)).toLocaleString(props.data.locale_string)}</span>:
                        <span style={{color:TABLE_COLORS.GREEN}}>{parseInt(props.value[0]/(props.data["LotSize"]?props.data["LotSize"]:1)).toLocaleString(props.data.locale_string)}</span>}
                     &nbsp;/&nbsp; 
                     {props.value[1] < 0 ? 
                        <span style={{color:TABLE_COLORS.RED}}>{parseInt(props.value[1]/(props.data["LotSize"]?props.data["LotSize"]:1)).toLocaleString(props.data.locale_string)}</span>:
                        <span style={{color:TABLE_COLORS.GREEN}}>{parseInt(props.value[1]/(props.data["LotSize"]?props.data["LotSize"]:1)).toLocaleString(props.data.locale_string)}</span>}
                </span>:
                <>-</>
            }
            </div>
    }

    
    export const FutActiveAvgDailyVol5_14=(props)=>{
        // console.log("props.dataAvgDailyVol5/14=",props.data["AvgDailyVol5/14"]);
        return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end",flexDirection:"column",alignItems:"end"}}>
            {props.data["AvgDailyVol5/14"] && props.data["AvgDailyVol5/14"].active?
                <>
                <span>{parseInt(props.data["AvgDailyVol5/14"].active/(props.data["LotSize"]?props.data["LotSize"]:1)).toLocaleString(props.data.locale_string)}</span>
                <span>{props.data["AvgDailyVol5/14"]["5D"]?.toLocaleString(props.data.locale_string)}&nbsp;/&nbsp; {props.data["AvgDailyVol5/14"]["14D"]?.toLocaleString(props.data.locale_string)}</span>
                
                </>
    
                :
                <>-</>
            }
            </div>
    }

    export const FutAvgDailyVolPct5_14=(props)=>{
        // console.log("props.dataAvgDailyVol5/14=",props.data["AvgDailyVol5/14"]);
        return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end",flexDirection:"column",alignItems:"end"}}>
            {props.data["AvgVol5Pct"] && props.data["AvgVol14Pct"]?
                <>
                {props.data["AvgVol5Pct"] > 110 || props.data["AvgVol14Pct"] > 110 ? <span style={{color:TABLE_COLORS.HIGHTLIGHT_VOL,fontWeight:700}}>{props.data["AvgVol5Pct"]} / {props.data["AvgVol14Pct"]}</span> : <span>{props.data["AvgVol5Pct"]} / {props.data["AvgVol14Pct"]}</span>}
                </>
    
                :
                <>-</>
            }
            </div>
    }
    
    export const FutMaxDailyStats=(props)=>{
        return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end",flexDirection:"column",alignItems:"end"}}>
            {props.data["MaxDailyStats"] && props.data["MaxDailyStats"]["5D"][0] && props.data["MaxDailyStats"]["14D"][0] && props.data["MaxDailyStats"]["5D"][1] && props.data["MaxDailyStats"]["14D"][1]?
                <>
                <span>5D:&nbsp;{props.data["MaxDailyStats"]["5D"][0].toLocaleString(props.data.locale_string)}&nbsp;({props.data["MaxDailyStats"]["5D"][1].toLocaleString(props.data.locale_string)})&nbsp;:
                <span style={{color:TABLE_COLORS.VPOC}}>&nbsp;{props.data["MaxDailyStats"]["5D"][2].toLocaleString(props.data.locale_string)}&nbsp;</span>-
                <span style={{color:TABLE_COLORS.VWAP}}>&nbsp;{props.data["MaxDailyStats"]["5D"][4].toLocaleString(props.data.locale_string)}</span>
                </span>
                <span>14D:&nbsp;{props.data["MaxDailyStats"]["14D"][0].toLocaleString(props.data.locale_string)}&nbsp;({props.data["MaxDailyStats"]["14D"][1].toLocaleString(props.data.locale_string)})&nbsp;:
                <span style={{color:TABLE_COLORS.VPOC}}>&nbsp;{props.data["MaxDailyStats"]["14D"][2].toLocaleString(props.data.locale_string)}&nbsp;</span>-
                <span style={{color:TABLE_COLORS.VWAP}}>&nbsp;{props.data["MaxDailyStats"]["14D"][4].toLocaleString(props.data.locale_string)}</span>
                </span>
                {props.data["MaxDailyStats"]["M"]!=undefined  && props.data["MaxDailyStats"]["M"][0] && props.data["MaxDailyStats"]["M"][1] ?
                <>
                <span>M:&nbsp;{props.data["MaxDailyStats"]["M"][0].toLocaleString(props.data.locale_string)}&nbsp;({props.data["MaxDailyStats"]["M"][1].toLocaleString(props.data.locale_string)})&nbsp;:
                <span style={{color:TABLE_COLORS.VPOC}}>&nbsp;{props.data["MaxDailyStats"]["M"][2].toLocaleString(props.data.locale_string)}&nbsp;</span>-
                <span style={{color:TABLE_COLORS.VWAP}}>&nbsp;{props.data["MaxDailyStats"]["M"][4].toLocaleString(props.data.locale_string)}</span>
                </span>
                </>:<></>}
                </>
    
                :
                <>-</>
            }
            </div>
    }
    
    
    export const getColumneDefFut=(auth,openMPChart,showSubscribeModal,locale_string,hidden_cols)=>{
        // console.log("Fut locale string=",locale_string)
        // other roles dont have access to Fut Data
        let proUser=auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA)?true:false;
        let pro2User=auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2)?true:false;
        let isStocksAllowed=auth.resources.find(el=>el==USER_RESOURCES_LIST.OF_STOCKS);
        let isIndexAllowed=auth.resources.find(el=>el==USER_RESOURCES_LIST.OF_INDEX);
        if(hidden_cols == undefined) hidden_cols = [];
        console.log(hidden_cols);
        let def;
        if(proUser){
            if(isStocksAllowed && pro2User){
                def=[
                    {
                        headerName:"",
                        groupId:"stock",
                        children:[
                            { field: 'name',headerName:"Stock" ,minWidth: 140,maxWidth:150,cellRenderer:FutStockRenderer,sortable:true,unSortIcon:true,
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                                cellRendererParams: {
                                    handleMPChart:openMPChart
                                }, 
                            },
                        ]
                    },
                    {
                        headerName:"Open",
                        groupId:"OHL",
                        children:[
                            { field: 'open_high_low',headerName:"High / Low" ,minWidth: 120,maxWidth:140,cellRenderer:FutOpenHighLowRenderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.open_high_low),sortable:true,unSortIcon:true,
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                            },
                        ]
                    },
                    {
                        headerName:"",
                        groupId:"Change",
                        children:[
                            { field: 'change',headerName:"LTP / Change" ,minWidth: 120,maxWidth:140,cellRenderer:FutLtpCHGRenderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.change_ltp),sortable:true,unSortIcon:true,
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                            },
                        ]
                    },
                    {
                        headerName:"",
                        groupId:"VA",
                        children:[
                            { field: 'value_area',headerName:"VAH-VAL / VPOC-VWAP" ,minWidth: 250,maxWidth:280,cellRenderer:ValueAreaRenderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.value_area),
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                               
                            },
                        ]
                    },
                    // {
                    //     headerName:"",
                    //     groupId:"Spot chg",
                    //     children:[
                    //         { field: 'SpotChg',headerName:"Spot Chg" ,minWidth: 80,maxWidth:100,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.spot_chg),
                    //         cellStyle: function(params) {
                    //             return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                    //         },
                    //     ]
                    // },
                    // {
                    //     headerName:"",
                    //     groupId:"NetIdxPts",
                    //     children:[
                    //         { field: 'NetIdxPts',headerName:"NetIdxPts" ,minWidth: 80,maxWidth:100,cellRenderer:DefaultRendererGeneric,sortable:true,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.netidxpts),
                    //         cellStyle: function(params) {
                    //             return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    //         }, 
                    //     ]
                    // },
                    {
                        headerName:"",
                        groupId:"TR",
                        children:[
                            { field: 'TR',headerName:"TR" ,minWidth: 60,maxWidth:80,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.tr),
                            cellStyle: function(params) {
                            return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                            },
                        ]
                    },
                    {
                        headerName:"",
                        groupId:"ATR5/14",
                        children:[
                            { field: 'ATR5/14',headerName:"ATR 5 / 14" ,minWidth: 80,maxWidth:100,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.atr),
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                            }, 
                        ]
                    },
                    {
                    headerName:"",
                    groupId:"Vol",
                    children:[
                        { field: 'Vol',headerName:"Vol" ,minWidth: 90,maxWidth:110,cellRenderer:VolContractsRenderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.vol),
                                cellStyle: function(params) {
                                    return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    
                                },
                                cellRendererParams: {
                                    locale_string: locale_string,
                                
                                },  
                            },
                        ]
                    },
                    {
                        headerName:"",
                        groupId:"tbCot",
                        children:[
                            { field: 'TbCOT',headerName:"10 Bar COT (3/30)" ,minWidth: 150,maxWidth:180,cellRenderer:Cot_10bar_Renderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.tbcot),sortable:false,unSortIcon:false,
                                    cellStyle: function(params) {
                                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    
                                    },
                                    cellRendererParams: {
                                        locale_string: locale_string,
                                    
                                    },  
                                },
                            ]
                        },
                    {
                        headerName:"-00:01 / -00:01 / -15:01 ( M )",
                        groupId:"ATR5/14",
                        children:[
                            { field: 'Vol_5_15_30_min',headerName:"% Vol (-5) 5 / 15 / 30 mins" ,minWidth: 240,maxWidth:280,cellRenderer:Vol_5_15_30_Renderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.percent_vol_tf_stats),
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                            }, 
                        ]
                    },
                    {
                        headerName:"",
                        groupId:"% Avg Vol 5 / 14",
                        children:[
                            { field: 'AvgVolPct',headerName:"% Avg Vol 5 / 14" ,minWidth: 150,maxWidth:160,cellRenderer:FutAvgDailyVolPct5_14,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.percent_avg_vol),sortable:true,unSortIcon:true,
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                            }, 
                        ]
                    },
                    {
                        headerName:"Active Vol",
                        groupId:"ATR5/14",
                        children:[
                            { field: 'AvgDailyVol',headerName:"Avg Daily Vol 5 / 14" ,minWidth: 180,maxWidth:220,cellRenderer:FutActiveAvgDailyVol5_14,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.vol_vs_avg_vol),
                                cellStyle: function(params) {
                                    return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                                    cellRendererParams: {
                                        locale_string: locale_string,
                                    
                                    },  
                            },
                        ]
                    },
                    {
                        headerName:"Max Daily Stats 5D / 14D / M",
                        groupId:"ATR5/14",
                        children:[
                            { field: 'max_daily_stats',headerName:"Vol (Date) : VPOC - VWAP" ,minWidth: 280,maxWidth:320,cellRenderer:FutMaxDailyStats,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.max_daily_stats),
                                cellStyle: function(params) {
                                    return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                                    cellRendererParams: {
                                        locale_string: locale_string,
                                    
                                    },  
                            },
                        ]
                    },
                    {
                        headerName:"",
                        groupId:"time",
                        children:[
                            { field: 'Time',headerName:"Time" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererGeneric,
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                            },
                        ]
                    },
    
                  
                ]
            }
            else{
                def=[
                    {
                        headerName:"",
                        groupId:"stock",
                        children:[
                            { field: 'name',headerName:"Stock" ,minWidth: 140,maxWidth:150,cellRenderer:FutStockRenderer,sortable:true,unSortIcon:true,
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                                cellRendererParams: {
                                    handleMPChart:openMPChart
                                }, 
                            },
                        ]
                    },
                    {
                        headerName:"Open",
                        groupId:"OHL",
                        children:[
                            { field: 'open_high_low',headerName:"High / Low" ,minWidth: 120,maxWidth:140,cellRenderer:FutOpenHighLowRenderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.open_high_low),sortable:true,unSortIcon:true,
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                            },
                        ]
                    },
                    {
                        headerName:"",
                        groupId:"Change",
                        children:[
                            { field: 'change',headerName:"LTP / Change" ,minWidth: 120,maxWidth:140,cellRenderer:FutLtpCHGRenderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.change_ltp),sortable:true,unSortIcon:true,
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                            },
                        ]
                    },
                    {
                        headerName:"",
                        groupId:"VA",
                        children:[
                            { field: 'value_area',headerName:"VAH-VAL / VPOC-VWAP" ,minWidth: 250,maxWidth:280,cellRenderer:ValueAreaRenderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.value_area),
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                               
                            },
                        ]
                    },
                    // {
                    //     headerName:"",
                    //     groupId:"Spot chg",
                    //     children:[
                    //         { field: 'SpotChg',headerName:"Spot Chg" ,minWidth: 80,maxWidth:100,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.spot_chg),
                    //         cellStyle: function(params) {
                    //             return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                    //         },
                    //     ]
                    // },
                    // {
                    //     headerName:"",
                    //     groupId:"NetIdxPts",
                    //     children:[
                    //         { field: 'NetIdxPts',headerName:"NetIdxPts" ,minWidth: 80,maxWidth:100,cellRenderer:DefaultRendererGeneric,sortable:true,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.netidxpts),
                    //         cellStyle: function(params) {
                    //             return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    //         }, 
                    //     ]
                    // },
                    {
                        headerName:"",
                        groupId:"TR",
                        children:[
                            { field: 'TR',headerName:"TR" ,minWidth: 60,maxWidth:80,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.tr),
                            cellStyle: function(params) {
                            return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                            },
                        ]
                    },
                    {
                        headerName:"",
                        groupId:"ATR5/14",
                        children:[
                            { field: 'ATR5/14',headerName:"ATR 5 / 14" ,minWidth: 80,maxWidth:100,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.atr),
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                            }, 
                        ]
                    },
                    {
                    headerName:"",
                    groupId:"Vol",
                    children:[
                        { field: 'Vol',headerName:"Vol" ,minWidth: 90,maxWidth:110,cellRenderer:DefaultRenderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.vol),
                                cellStyle: function(params) {
                                    return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    
                                },
                                cellRendererParams: {
                                    locale_string: locale_string,
                                
                                },  
                            },
                        ]
                    },
                    {
                        headerName:"-00:01 / -00:01 / -15:01 ( M )",
                        groupId:"ATR5/14",
                        children:[
                            { field: 'Vol_5_15_30_min',headerName:"% Vol (-5) 5 / 15 / 30 mins" ,minWidth: 240,maxWidth:280,cellRenderer:Vol_5_15_30_Renderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.percent_vol_tf_stats),
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                            }, 
                        ]
                    },
                    {
                        headerName:"",
                        groupId:"% Avg Vol 5 / 14",
                        children:[
                            { field: 'AvgVolPct',headerName:"% Avg Vol 5 / 14" ,minWidth: 150,maxWidth:160,cellRenderer:FutAvgDailyVolPct5_14,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.percent_avg_vol),sortable:true,unSortIcon:true,
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                            }, 
                        ]
                    },
                    {
                        headerName:"Active Vol",
                        groupId:"ATR5/14",
                        children:[
                            { field: 'AvgDailyVol',headerName:"Avg Daily Vol 5 / 14" ,minWidth: 180,maxWidth:220,cellRenderer:FutActiveAvgDailyVol5_14,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.vol_vs_avg_vol),
                                cellStyle: function(params) {
                                    return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                                    cellRendererParams: {
                                        locale_string: locale_string,
                                    
                                    },  
                            },
                        ]
                    },
                    {
                        headerName:"Max Daily Stats 5D / 14D / M",
                        groupId:"ATR5/14",
                        children:[
                            { field: 'max_daily_stats',headerName:"Vol (Date) : VPOC - VWAP" ,minWidth: 280,maxWidth:320,cellRenderer:FutMaxDailyStats,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.max_daily_stats),
                                cellStyle: function(params) {
                                    return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                                    cellRendererParams: {
                                        locale_string: locale_string,
                                    
                                    },  
                            },
                        ]
                    },
                    {
                        headerName:"",
                        groupId:"time",
                        children:[
                            { field: 'Time',headerName:"Time" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererGeneric,
                            cellStyle: function(params) {
                                return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                            },
                        ]
                    },
                ]
            }
            
        }
        // else{
        //     def=[
        //         {
        //             headerName:"",
        //             groupId:"stock",
        //             children:[
        //                 { field: 'name',headerName:"Stock" ,minWidth: 140,maxWidth:150,cellRenderer:FutStockRenderer,sortable:true,
        //                 cellStyle: function(params) {
        //                     return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
        //                     cellRendererParams: {
        //                         handleMPChart:openMPChart
        //                     }, 
        //                 },
        //             ]
        //         },
        //         {
        //             headerName:"Change",
        //             groupId:"LTP/CLose",
        //             children:[
        //                 { field: 'change_ltp_close',headerName:"LTP / Close" ,minWidth: 120,maxWidth:140,cellRenderer:FutCHGLTPRenderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.change_ltp),
        //                 cellStyle: function(params) {
        //                     return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
                           
        //                 },
        //             ]
        //         },
        //         {
        //             headerName:"",
        //             groupId:"Spot chg",
        //             children:[
        //                 { field: 'SpotChg',headerName:"Spot Chg" ,minWidth: 80,maxWidth:100,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.spot_chg),
        //                 cellStyle: function(params) {
        //                     return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
        //                 },
        //             ]
        //         },
        //         {
        //             headerName:"",
        //             groupId:"NetIdxPts",
        //             children:[
        //                 { field: 'NetIdxPts',headerName:"NetIdxPts" ,minWidth: 80,maxWidth:100,cellRenderer:DefaultRendererGeneric,sortable:true,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.netidxpts),
        //                 cellStyle: function(params) {
        //                     return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
        //                 }, 
        //             ]
        //         },
        //         {
        //             headerName:"",
        //             groupId:"TR",
        //             children:[
        //                 { field: 'TR',headerName:"TR" ,minWidth: 60,maxWidth:80,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.tr),
        //                 cellStyle: function(params) {
        //                 return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
        //                 },
        //             ]
        //         },
        //         {
        //             headerName:"",
        //             groupId:"ATR5/14",
        //             children:[
        //                 { field: 'ATR5/14',headerName:"ATR 5 / 14" ,minWidth: 80,maxWidth:100,cellRenderer:DefaultRendererGeneric,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.atr),
        //                 cellStyle: function(params) {
        //                     return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
        //                 }, 
        //             ]
        //         },
        //         {
        //         headerName:"",
        //         groupId:"Vol",
        //         children:[
        //             { field: 'Vol',headerName:"Vol" ,minWidth: 90,maxWidth:110,cellRenderer:DefaultRenderer,sortable:true,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.vol),
        //                     cellStyle: function(params) {
        //                         return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    
        //                     },
        //                     cellRendererParams: {
        //                         locale_string: locale_string,
                            
        //                     },  
        //                 },
        //             ]
        //         },
        //         {
        //             headerName:"-00:01 / -00:01 / -15:01 ( M )",
        //             groupId:"ATR5/14",
        //             children:[
        //                 { field: 'Vol_5_15_30_min',headerName:"% Vol (-5) 5 / 15 / 30 mins" ,minWidth: 240,maxWidth:280,cellRenderer:Vol_5_15_30_Renderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.percent_vol),
        //                 cellStyle: function(params) {
        //                     return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
        //                 }, 
        //             ]
        //         },
        //         {
        //             headerName:"",
        //             groupId:"% Avg Vol 5 / 14",
        //             children:[
        //                 { field: '%AvgVol5/14',headerName:"% Avg Vol 5 / 14" ,minWidth: 100,maxWidth:140,cellRenderer:DefaultRenderer,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.percent_avg_vol),
        //                 cellStyle: function(params) {
        //                     return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
        //                 }, 
        //             ]
        //         },
        //         {
        //             headerName:"Active Vol",
        //             groupId:"ATR5/14",
        //             children:[
        //                 { field: 'AvgDailyVol',headerName:"Avg Daily Vol 5 / 14" ,minWidth: 180,maxWidth:220,cellRenderer:FutActiveAvgDailyVol5_14,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.avg_daily_vol),
        //                     cellStyle: function(params) {
        //                         return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
        //                         cellRendererParams: {
        //                             locale_string: locale_string,
                                
        //                         },  
        //                 },
        //             ]
        //         },
        //         {
        //             headerName:"Max Daily Stats 5D / 14D / M",
        //             groupId:"ATR5/14",
        //             children:[
        //                 { field: 'max_daily_stats',headerName:"Vol (Date) : VPOC - VWAP" ,minWidth: 280,maxWidth:320,cellRenderer:FutMaxDailyStats,hide:hidden_cols.includes(INDEX_COMPONENTS_COLUMNS_LIST.max_daily_stats),
        //                     cellStyle: function(params) {
        //                         return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
        //                         cellRendererParams: {
        //                             locale_string: locale_string,
                                
        //                         },  
        //                 },
        //             ]
        //         },
        //         {
        //             headerName:"",
        //             groupId:"time",
        //             children:[
        //                 { field: 'Time',headerName:"Time" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererGeneric,
        //                 cellStyle: function(params) {
        //                     return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    },
        //                 },
        //             ]
        //         },

              
        //         ]
        // }
    
                return def;
        }
        
       