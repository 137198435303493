import React,{useEffect, useState} from 'react'
import {Link, Grid,Tab,Tabs,IconButton,Drawer } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Paper} from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player/vimeo'
import ReactPlayerYT from 'react-player/youtube'
import ReactPlayerVimeo from 'react-player/vimeo';
import Carousel from 'react-material-ui-carousel';
import introImg from '../../assets/images/intro_user_manual.png';
import orderflowImg from '../../assets/images/orderflow-chart.png';
import gammaImg from '../../assets/images/gamma-chart.png';
import mpImg from '../../assets/images/mp-chart.png';
import mp1Img from '../../assets/images/mp-chart1.png';
import mp2Img from '../../assets/images/mp-chart2.png';
import optableImg from '../../assets/images/op-table.png';
import optable1Img from '../../assets/images/op-table1.png';
import idxtableImg from '../../assets/images/index-table.png';
import greektableImg from '../../assets/images/greeks-table.png';
import spectrumImg from '../../assets/images/spectrum.png';
import oichgImg from '../../assets/images/oi-change.png';
import ntmoichgImg from '../../assets/images/ntm-oi-change.png';
import hvImg from '../../assets/images/hv-charts.png';
import ivizImg from '../../assets/images/iviz-1.png';
import ivcurveImg from '../../assets/images/iv-curve.png';
import oisnakesImg from '../../assets/images/oi-snakes.png';
import { USER_MANUAL_TAB_LIST,INTRO_VIDIEOS, OF_INTRO_VIDIEOS } from '../../common/utility/constant';
import { experimentalStyled as styled } from '@mui/material/styles';
import { contentQuotesLinter } from '@ant-design/cssinjs/lib/linters';
import { useWindowSize } from '../../common/hooks/useWindowSize';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useSearchParams} from 'react-router-dom';
import { useQuery } from '../../common/utility/helperFunc';
import { ChevronLeft } from '@mui/icons-material';





const TabPanel=(props)=> {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{width:"100%",backgroundColor:"#0d203f",overflowY:'scroll'}}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3,width:"100%" }}>
            {!props.isDrawerOpen?
             <IconButton
            onClick={() => props.openDrawer()}
            size='large'
            edge='start'
            sx={{ml:0}}
            aria-label='logo'>
            <MenuIcon sx={{color:'#fff',width:"32px",height:"32px"}}/>
        </IconButton>:
        <></>
            }

            {children}
          </Box>
        )}
      </div>
    );
    }

    const  a11yProps=(index)=> {
        return {
          id: `vertical-tab-${index}`,
          'aria-controls': `vertical-tabpanel-${index}`,
        };
      }

     
      
      const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
      
const style = {
    position: 'absolute',
    top: '7%',
    left: '5%',
    height: "90%",
    //   transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
  };

  const imgStyle = {
    width: "100%",
    height: "50%",
    // align: "middle",
    marginLeft: 0, 
    marginTop: 20, 
    marginBottom: 20
  }

  const imgStyleGrid = {
    width: "100%",
    height: "100%",
    // align: "middle",
    marginLeft: 0, 
    marginTop: 20, 
    marginBottom: 20
  }

  const items = [
    {
        name: "16/03 Orientation Webinar 1",
        src:"https://vimeo.com/809435827",
        yt_src: "https://youtu.be/7J5CAqumqHk"
    },
    {
        name: "31/03 Orientation Webinar 2",
        src:"https://vimeo.com/813574929",
        yt_src: "https://youtu.be/vjzd8uSKRlI"
    }
  ]

  const guide_items = [
    {
        name: "Revolutionize Your Options Trading with Sensitivity Value - The Leading Indicator You Can't Ignore",
        yt_src: "https://youtu.be/7w4ThmNx4YA"
    },
    {
        name: "Mastering Spectrum: Second Order Greeks & Gamma Positioning for NSE Option Traders",
        yt_src: "https://youtu.be/KBwaH3LUI4E"
    },
    {
        name: "Intraday Open Interest Analysis: Tracking Call & Put OI Changes on Charts",
        yt_src: "https://youtu.be/hItCJb7WX-M"
    },
    {
        name: "Using Market Profile & VWAP/POC with Strangle Charts: A Complete Guide",
        yt_src: "https://youtu.be/3fHhVxuPPyw"
    }
    
  ]

  

const UserManual = (props) => {

 
  const id = useQuery("id");
  const index=props.value?props.value:USER_MANUAL_TAB_LIST.findIndex((item)=>item.id==id);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [selectedIndex,setSelectedIndex]=React.useState(-1);        //keeps tracks of the playing video
  const [value, setValue] = React.useState(index!=-1?index:0);
  const [videoWidth,setVideoWidth]=useState((window.innerWidth-340)/3-30);
  const windowSize=useWindowSize();
  const isBigScreen = useMediaQuery('(min-width:1024px)');
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
 
  const [searchParams, setSearchParams] = useSearchParams();
  const [drawerListData,setDrawerList]=useState(USER_MANUAL_TAB_LIST)

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedIndex(-1);
  };
  useEffect(() => {
    if(!props.popUp)
    document.title = "User Manual: "+USER_MANUAL_TAB_LIST[value].name;
    var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
    // console.log(link);
    document.head.appendChild(link);
    console.log("location=",location)
  }, []);

 
//   useEffect(()=>{
//     setVideoWidth((window.innerWidth-340)/3-30);
//   },[windowSize])

  useEffect(()=>{
    if(props.value==undefined &&  !props.popUp){
    setSearchParams({"id":USER_MANUAL_TAB_LIST[value].id})
    document.title = "User Manual: "+USER_MANUAL_TAB_LIST[value].name;
    }
  },[value])

  const openDrawer=()=>{
    // const routePath=window?.location?.pathname;
    // const search=window?.location?.search;
    // console.log("UseEffect get selected index=",getIndexOfRoute(routePath,search))
    // setSelectedIndex();
    setIsDrawerOpen(true);
  }

  const handleListItemClick = (event, index) => {
    setValue(index);
    setIsDrawerOpen(false);  
};

  

    return (
        
      

       <>
       
       
       <Box>
        {isDrawerOpen && isBigScreen?
        <>
       
            <IconButton 
             sx={{ color: 'inherit',marginLeft:"300px",mt:1}}
             onClick={() => setIsDrawerOpen(false)}>
             <ChevronLeft/>
           </IconButton>
          
             <Tabs
             orientation={isBigScreen?"vertical":"horizontal"}
             variant="scrollable"
             value={value}
             onChange={handleChange}
             aria-label="Vertical tabs example"
           //   width="390px"
             sx={{ borderRight: 1, borderColor: 'divider',alignItems:'start',justifyContent:'left',minWidth:isBigScreen? "340px":"70px",pt:0,alignItems:'center',height:`calc(100vh - 140px)` }}
           >
             {USER_MANUAL_TAB_LIST.map((item,index) => (
                             <Tab label={item.name} {...a11yProps(item.value)} sx={{alignSelf:'start',alignItems:'baseline',minWidth:isBigScreen? "340px":"70px",background:item.value==value?"#0d203f":"#132d5a",pl:2}} />
                           ))}  
            
             
           </Tabs> 
           
           </>:
           <>
           {!isBigScreen?
           <Tabs
           orientation={isBigScreen?"vertical":"horizontal"}
           variant="scrollable"
           value={value}
           onChange={handleChange}
           aria-label="Vertical tabs example"
         //   width="390px"
           sx={{ borderRight: 1, borderColor: 'divider',alignItems:'start',justifyContent:'left',minWidth:isBigScreen? "340px":"70px",pt:2,minHeight:"60px",alignItems:'center' }}
         >
           {USER_MANUAL_TAB_LIST.map((item,index) => (
                           <Tab label={item.name} {...a11yProps(item.value)} sx={{alignSelf:'start',alignItems:'baseline',minWidth:isBigScreen? "340px":"70px",background:item.value==value?"#0d203f":"#132d5a",pl:2}} />
                         ))}  
          
           
         </Tabs> :
         <></>
           }
           
        </>
            
           }
        
        </Box>
       
      
           
        {/* Intro Section */}
        <TabPanel value={value} index={0} openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
        
        <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:4,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
           <Box sx={{ flexGrow: 1,mt:2 }}>
            <Grid container spacing={2} >
                    <Grid item mobile={12} tablet={12} laptop={6} key={"intro_general"}>
                        <Box>
                        <img src={introImg} alt="intro" style={imgStyle} />
                        </Box>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={6} key={index}>
                    <Box>
                        <Typography sx={{ ml:1, mr:1, mt: 2 }}>
                        Welcome to Vtrender Charts, where the confluence of MarketProfile, Orderflow, and the Options Suite
crafts a pioneering trading experience like no other.
<br/><br/>
Our integrated approach promises unparalleled clarity and strategic prowess to both the seasoned
trader and the budding enthusiast.
<br/><br/><b style={{color:"#F3B23E"}}>Vtrender Charts: Navigating the Nexus of Trading Mastery</b><br/><br/>
<Typography sx={{ ml:1}}>Embarking on a trading journey with Vtrender Charts is akin to navigating the intricacies of the financial
seas with the most sophisticated compass - one that blends the time-tested strategies of MarketProfile
with the real-time dynamics of Orderflow, all while adding a flavor of Options expertise into the mix.
<br/><br/>
With Vtrender Charts, traders are not just equipped with tools but are indoctrinated into a holistic
trading philosophy. This triumvirate of MarketProfile, Orderflow, and the refined Options Suite is
meticulously designed to elevate your trading journey, transforming it from mere speculation to an art
form. Embrace the Vtrender way, and step into a world where trading mastery is not just an aspiration
but an attainable reality</Typography>
                        </Typography>
                    </Box>
                    </Grid>
            </Grid>
           </Box>
           <br/>
           <br/>
           <Box sx={{ flexGrow: 1,mt:4 }}>
            <Grid container spacing={2} >
                    <Grid item mobile={12} tablet={12} laptop={6} key={"intro_mp"}>
                        <Box>
                        <img src={mpImg} alt="intro" style={imgStyle} />
                        </Box>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={6} key={index}>
                        <Box>
                            <Typography sx={{ ml:1, mr:1, mt: 2 }}>
                            <b style={{color:"#F3B23E"}}>MarketProfile:</b> A beacon for strategic decision-making, MarketProfile unfolds the market's narrative in a
structured manner, revealing crucial price levels and patterns. With Vtrender's enhanced MarketProfile
charts, users can delve into horizontal and vertical profiles, recognizing significant trading ranges and
price acceptance levels, ensuring you're never trading blind.
<br/><br/>
                            <b style={{color:"#F3B23E"}}>Delving into MarketProfile</b>
                            <br/><br/>
                            <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Depth Beyond Surface:</b> MarketProfile, with its unique TPO charts, gives traders a panoramic view of
price movements and trading activity over time. It offers an insightful lens to decipher where the market
finds value, helping traders align their strategies with prevailing market sentiment.
<br/><br/>
<b style={{color:"#F3B23E"}}>Unveiling the Market's Narrative:</b> Every price movement has a story. MarketProfile helps traders
comprehend the unfolding narrative, marking key price levels and illuminating areas where the market
has spent the majority of its time.</Typography>
                            </Typography>
                        </Box>
                    </Grid>
            </Grid>
           </Box>
           <br/>
           <br/>
           <Box sx={{ flexGrow: 1,mt:2 }}>
            <Grid container spacing={2} >
                    <Grid item mobile={12} tablet={12} laptop={6} key={"intro_of"}>
                        <Box>
                        <img src={orderflowImg} alt="intro" style={imgStyle} />
                        </Box>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={6} key={index}>
                        <Box>
                            <Typography sx={{ ml:1, mr:1, mt: 2 }}>
                            <b style={{color:"#F3B23E"}}>Orderflow:</b> It's one thing to know where the market is, but understanding its momentum is a game-
changer. Through our expansive Orderflow charts, visualize the market's real-time heartbeat. Discern
the strength of buyers versus sellers, and grasp the market's expected move with intuitive color-coded

charts. From distinguishing between responsive and initiative activities to recognizing the dominant
market parties, our Orderflow insights are your compass in the ever-fluctuating financial seas.
<br/> <br/> <b style={{color:"#F3B23E"}}>Tapping into the Pulse with Orderflow</b><br/> <br/> 
<Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Real-Time Insights:</b> Orderflow, in its essence, captures the live pulse of the market. It showcases the
battle between supply and demand, revealing strengths and weaknesses in real-time, allowing traders
to make timely interventions.
<br/><br/>
<b style={{color:"#F3B23E"}}>Reading the Flow:</b> Through Orderflow, traders can ascertain aggressive buyers or sellers, identify
potential price reversals, and understand market imbalances - all crucial elements in sculpting a winning
trading strategy.</Typography>
                          </Typography>
                        </Box>
                    </Grid>
            </Grid>
           </Box>
           <br/>
           <br/>
           <Box sx={{ flexGrow: 1,mt:2 }}>
            <Grid container spacing={2} >
                    <Grid item mobile={12} tablet={12} laptop={6} key={"intro_options"}>
                        <Box>
                        <img src={spectrumImg} alt="intro" style={imgStyle} />
                        </Box>
                    </Grid>
                    <Grid item mobile={12} tablet={12} laptop={6} key={index}>
                        <Box>
                            <Typography sx={{ ml:1, mr:1, mt: 2 }}>
                            <b style={{color:"#F3B23E"}}>Options Suite:</b> In the fast-paced world of the NSE, where 10,000cr - 40,000cr in value changes hands
daily, the Options Suite is your lens into this intricate dance of demand and supply. A modern, intuitive
interface brings powerful tools at your fingertips, allowing you to gauge second order greeks, visualize
option strategies, and tap into the pulse of the market's option segment.
<br/> <br/> <b style={{color:"#F3B23E"}}>Options Suite: Deciphering Complex Dynamics</b><br/> <br/> 
<Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Options Anatomy:</b> Harnessing the power of tools like Options Snakes Line Charts, Gamma Visualization,
and Open Interest analytics, Vtrender adds an intricate layer of options intelligence. Whether you're
tracking Net OI across strikes or plotting the implied volatility curves, the Options Suite empowers
traders with nuanced insights.
<br/><br/>
<b style={{color:"#F3B23E"}}>Strategizing with Precision:</b> Integrating options analytics with MarketProfile and Orderflow paves the
way for precision-based trading decisions. Observe patterns, forecast potential market shifts, and
strategize with enhanced confidence.</Typography>
                            </Typography>
                        </Box>
                    </Grid>
            </Grid>
           </Box>

            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            Crafted with precision and a deep understanding of traders' needs, Vtrender Charts is more than just a
tool; it's a holistic trading ecosystem. Our offerings are sculpted to cater to every user's specific needs,
ensuring that, regardless of experience, everyone has an edge in today's competitive financial markets.<br/><br/>

In the digital era, where information is abundant but clarity is rare, Vtrender Charts promises the latter.
Embrace the trifecta of MarketProfile, Orderflow, and the Options Suite, and elevate your trading
journey to unparalleled heights. Join us in redefining trading excellence with Vtrender Charts - your
compass to navigating the financial markets with confidence.
            </Typography>
        
        
        </TabPanel>

        {/* Intro Videos */}
       
        <TabPanel value={value} index={1}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4} >
                    {INTRO_VIDIEOS.map((item, index) => (
                    <Grid item mobile={12} tablet={6} laptop={4} key={index}>
                    <Box>
                        <ReactPlayerVimeo style={{border:"1px solid #000",borderRadius:4,maxHeight:"240px",backgroundColor:"#132d5a"}}url={item.src} controls pause={selectedIndex!=index} playing={selectedIndex==index?true:false} onPause={()=>console.log("onPause=",index)}  height="180px" width="100%" onPlay={()=>{{ console.log("onplay=",index);setSelectedIndex(index)}}} />
                        <Typography sx={{mt:1,textAlign:'center',color:"#F3B23E",fontWeight:600}}>{item.name}</Typography>
                    </Box>
                </Grid>
                ))}
            </Grid>
        </Box>
      </TabPanel>

       {/* OF INTRO VIDEOS*/}
       
       <TabPanel value={value} index={2}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={4} >
                    {OF_INTRO_VIDIEOS.map((item, index) => (
                    <Grid item mobile={12} tablet={6} laptop={4} key={index}>
                    <Box>
                        <ReactPlayerVimeo style={{border:"1px solid #000",borderRadius:4,maxHeight:"240px",backgroundColor:"#132d5a"}}url={item.src} controls pause={selectedIndex!=index} playing={selectedIndex==index?true:false} onPause={()=>console.log("onPause=",index)}  height="180px" width="100%" onPlay={()=>{{ console.log("onplay=",index);setSelectedIndex(index)}}} />
                        <Typography sx={{mt:1,textAlign:'center',color:"#F3B23E",fontWeight:600}}>{item.name}</Typography>
                    </Box>
                </Grid>
                ))}
            </Grid>
        </Box>
      </TabPanel>

        {/* Market Profile */}
        <TabPanel value={value} index={3} openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
            <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={mpImg} alt="Market Profile" style={imgStyle} /><br/>
           
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <span style={{fontWeight: 700,color:"#F3B23E"}}>The Essence of MarketProfile:</span><br/><br/> At the heart of astute trading lies the ability to discern crucial price levels
            and patterns. This is where MarketProfile steps in, serving as a dynamic trading technique that
            illuminates pivotal points for decision-making.
             MarketProfile is a trading technique that allows users to identify price levels and patterns that are important for decision-making
             <br/><br/>
             <span style={{fontWeight: 700,color:"#F3B23E"}}>Trading Information:</span><br/><br/> The Catalyst of Triumph: Imagine navigating the intricate alleys of trading without
                a map. It's akin to driving blindfolded - perilous with a high propensity for blunders. MarketProfile
                emerges as this much-needed map. Through its unique representation of price, volume, and time, it
                unveils the market's rhythm, aiding traders in spotting support, resistance, and market imbalances. This
                visual compass empowers traders to discern value zones, ensuring decisions that are steps ahead of the
                market pulse, thereby augmenting the likelihood of profitable ventures.
             <br/><br/>
             <span style={{fontWeight: 700,color:"#F3B23E"}}>Anatomy of MarketProfile:</span><br/><br/> A quintessential MarketProfile chart paints a tale of trading volumes across
            various price tiers over a chosen time frame. Envision a histogram segmented by price levels, sculpting a
            bell-shaped distribution curve. This curve's zenith is the most frequently traded price, while its
            extremities signify lesser-traveled price paths.<br/>
            For an illustrative grasp, consider a daily trading activity chart. Its horizontal axis decodes price levels,
            while the vertical axis narrates the volume story. The densely populated zones on this chart narrate the
            day's dominant trading saga. 
            <br/><br/>
            
            <span style={{fontWeight: 700,color:"#F3B23E"}}>Unraveling Key Concepts:</span><br/><br/>     
            
            <Typography sx={{ ml:1}}>
            <b style={{color:"#F3B23E"}}>Value Area:</b> Central to MarketProfile analysis, the 'Value Area' is the price spectrum accommodating a
            set percentage (commonly 70%) of a day's trading volume. Visualize this as the market's comfort zone
            for the day - a price band witnessing the day's most frenetic trade activities. Savvy traders often scout
            for trade opportunities when prices veer outside this zone, interpreting price trajectories above or
            below as indicative of potential rises or falls, respectively. For ease of recognition, MarketProfile charts
            often don a distinct shade for the value area. <br/><br/>
            
             
            <b style={{color:"#F3B23E"}}>POC (Point of Control):</b> This is the pulse point of MarketProfile. The POC demystifies the price level
             reveling in the zenith of trading activity for a stipulated period. It's not just a numerical value but an
            indicator of market sentiment. Hovering above the POC hints at bullish undertones, while a position
            below spells a bearish mood. Integrating POC with the value area and other MarketProfile indicators
            carves out a strategic roadmap for traders.</Typography><br/><br/>
            
            In the world of trading, where every tick can spell a different tale, MarketProfile emerges as a trusted
            storyteller, weaving tales of opportunities, risks, and potential windfalls. Armed with this knowledge, a
            trader is always steps ahead, poised for success.
 </Typography>  
            </Box>
        </TabPanel>
        
        {/*Orderflow */}
        <TabPanel value={value} index={4} openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
            <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={orderflowImg} alt="orderflow" style={imgStyle} /><br/>
           
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <span style={{fontWeight: 700, color:"#F3B23E"}}>The Heartbeat of OrderFlow: </span><br/><br/>
                &nbsp;Every market pulse, every trade whisper, every subtle movement - OrderFlow captures it all. As the
                curtain rises on the theatre of trading, OrderFlow stands as the conductor, orchestrating the intricate
                ballet of buy and sell orders, revealing hidden patterns and unrivaled insights.<br/><br/>

                <span style={{fontWeight: 700, color:"#F3B23E"}}>Harmonizing Trades with Information:</span><br/><br/>
                &nbsp;In the grand orchestra of trading, the informed trader doesn't just play notes; they create symphonies.
                And what better instrument to guide this melody than OrderFlow? It's the compass that points traders
                towards the North Star of optimal decisions, ensuring they dance seamlessly with the market's rhythm.<br/><br/>
                
                <span style={{fontWeight: 700, color:"#F3B23E"}}>Anatomy of OrderFlow:</span><br/><br/>
                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>OF Structure:</b> At the core of OrderFlow lies its robust structure - a dynamic tableau that traces the
                market's DNA. It dissects each transaction, creating a tapestry of volume, direction, and momentum.
                This granular view allows traders to interpret market nuances, gauge liquidity, and predict short-term
                price movements with precision.<br/><br/>

                <b style={{color:"#F3B23E"}}>OF Imbalances:</b> In the delicate balance of supply and demand, OrderFlow imbalances emerge as pivotal.
                They spotlight instances where buying or selling pressure dramatically outweighs the other, serving as a
                beacon for potential trend reversals or continuations. These imbalances are the market's heartbeats,
                signaling its emotional peaks and troughs.<br/><br/>

                <b style={{color:"#F3B23E"}}>The Volume Clusters:</b> Like constellations in the night sky, volume clusters in OrderFlow are aggregations
                of trade activity. They hint at zones where traders have shown heightened interest, revealing areas of
                value, support, or resistance. These clusters provide traders a panoramic view of market sentiment and
                a window into potential breakout zones.<br/><br/>

                <b style={{color:"#F3B23E"}}>The '0 Print' of Dominance:</b> In the OrderFlow saga, the '0 Print' is a tale of dominance. It marks
                moments where one side - buyers or sellers - exhibits sheer dominance, leaving no room for the other.
                This unopposed strength is a signpost for traders, hinting at strong market momentum in the dominant
                direction.<br/><br/>

                <b style={{color:"#F3B23E"}}>Responsive vs. Initiative Activities:</b> The market stage witnesses two main protagonists - the Responsive
                and the Initiative traders. The former reacts, stepping in after significant price movements, akin to
                catching a falling knife or riding an upward gust. The latter acts, initiating movements, dictating the
                market's course. Through OrderFlow, the dance between these two forces unfolds, helping traders
                discern market direction and strength.<br/><br/></Typography>

                OrderFlow, in essence, is more than just a trading tool. It's the narrative of the market, unfolding in real-
                time. It's the whisper of opportunities, the shout of caution, and the song of potential success. With its
                insights, traders don't just navigate the markets; they choreograph their masterpieces, ensuring each
                 move is a step towards triumph.
            </Typography>  
            </Box>
        </TabPanel>
        
        {/* Options Table */}
        <TabPanel value={value} index={5} openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
        <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={optable1Img} alt="options_table" style={imgStyle} /><br/>
           
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
                <b style={{color:"#F3B23E"}}>Decoding Option Tables:</b><br/><br/>
                In the labyrinth of trading, Option Tables stand as an illuminated guide, shedding light on the complex
                terrains of options data. They aren't just tables; they are repositories of insight, revealing the market's
                pulse, its fears, and its ambitions.<br/><br/>

                <b style={{color:"#F3B23E"}}>Painting the Canvas with Metrics:</b><br/><br/>
                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Open Interest Dynamics:</b> Dive deep into the heart of options with the ebb and flow of Open Interest
                (OI). The crescendo of maximum OI and the subtle murmurs of minimum OI at each strike hint at
                formidable fortresses of support or resistance, guiding traders through the battleground of strikes.
                The Symphony of Implied Volatility (IV): IV is the market's heartbeat, encapsulating its anxiety and
                excitement about the future. It's the unseen breeze that can either fill a trader's sails or halt them in
                their tracks.<br/><br/>

                <b style={{color:"#F3B23E"}}>VWAP & POC:</b> As the maestros of price movement, the Volume Weighted Average Price (VWAP) and
                Point of Control (POC) narrate tales of prices that woo traders the most. They're the market's siren
                songs, luring traders to potential zones of opportunity.<br/><br/>

                <b style={{color:"#F3B23E"}}>Narrative of Developing Open Interest:</b> As the plot of the options market thickens, Developing Open
                Interest becomes its storyline. This evolving metric serves as a barometer for shifting market
                sentiments, offering traders a preview into forthcoming chapters of price movements.<br/><br/>

                <b style={{color:"#F3B23E"}}>Total OI Panorama:</b> Like an artist's sweeping brushstroke, Total OI for all strikes provides a panoramic
                view of the market, capturing its scale, depth, and dynamism in a singular frame.</Typography><br/><br/>

                Option Tables, in their essence, are the modern trader's oracle. They whisper secrets from the market's
                past, offer insights into its present, and project glimpses into its future. For traders eager to craft their
                master narrative in the market's tapestry, understanding and harnessing the power of Option Tables is
                the key to unlocking unprecedented vistas of opportunity.
            </Typography>  
            </Box>
        </TabPanel>

         {/* Greeks Table */}
        <TabPanel value={value} index={6} openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
        <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={greektableImg} alt="greeks_table" style={imgStyle} /><br/>
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
                <b style={{color:"#F3B23E"}}>Unraveling the Greeks Table:</b><br/><br/>
                Much like ancient alchemists, modern traders are on an eternal quest: to turn opportunities into gold. At
                the heart of this quest, lies the enigmatic Greeks Table, a compendium that translates the intricate
                dance of options into a language of wisdom and foresight.<br/><br/>

                <b style={{color:"#F3B23E"}}>Deciphering the Codes:</b><br/><br/>
                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Delta</b> - The Seer: As the oracle of price movement, Delta predicts the shadow an option will cast in
                response to the subtlest of breezes from the underlying asset. A potent Delta whispers of an option and
                its underlying asset moving as if they were entwined in a harmonious dance.<br/><br/>

                <b style={{color:"#F3B23E"}}>Gamma</b> - The Whisperer of Change: Hovering in Delta's shadows, Gamma tells tales of how swiftly the
                Delta will evolve, responding to the merest tremors in the underlying asset's price. A robust Gamma
                heralds a dynamic Delta, forever in flux, reacting to the market's heartbeat.<br/><br/>

                <b style={{color:"#F3B23E"}}>Vega</b> - The Enchantress of Volatility: In a market that constantly ebbs and flows, Vega captures an
                option's sensitivity to these tides of volatility. She's the siren of the sea of options, resonating with the
                waves of market volatility.<br/><br/>

                <b style={{color:"#F3B23E"}}>Theta</b>- The Timekeeper: As the sands of time slip by, Theta chronicles an option's race against time. It's
                the steady drumbeat, reminding traders of the fleeting nature of opportunities.<br/><br/>

                <b style={{color:"#F3B23E"}}>Additional Augurs</b> - IV, DOI, &amp; Total OI: Nestled within the Greeks Table are the voices of Implied
                Volatility, whispering of market expectations; Developing Open Interest, narrating the evolving saga of
                market sentiment; and Total Open Interest, portraying the grandeur of overall market participation.</Typography>
                <br/><br/>
                The Greeks Table isn't just a tool; it's a tapestry where the threads of Delta, Gamma, Vega, and Theta
                intertwine to create a vibrant mosaic of market dynamics. Traders, armed with this grimoire, can pierce
                the veil of the future, gauging the heartbeats and tremors of options, and chart a course towards
                profitable horizons. By mastering the cryptic language of the Greeks, one can truly turn market
                opportunities into golden endeavours.
            </Typography>  
            </Box>
        </TabPanel>

         {/* Index Table                */}
         <TabPanel value={value} index={7}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
         <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={idxtableImg} alt="index_table" style={imgStyle} /><br/>
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <b style={{color:"#F3B23E"}}>Embarking on the Index Odyssey:</b><br/><br/>
                Imagine the vast expanse of the financial cosmos, with galaxies made up of myriad stars, each twinkling
                with its own story. In the world of trading, indices are these galaxies, and the individual stocks are the
                radiant stars that form them. The index charts a course through a specific market or sector, illuminating
                its overall performance by the collective gleam of its constituent stars.<br/><br/>

                <b style={{color:"#F3B23E"}}>Peering into the Starlight:</b><br/><br/>
                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>The Composition Constellation:</b> Each index is a unique constellation, shaped by its distinct group of
                stocks. How this constellation is charted depends on the celestial map - the methodology - employed in
                its creation.<br/><br/>

                <b style={{color:"#F3B23E"}}>The Dance of ATR:</b> The Star's Heartbeat: Beyond merely admiring the glow of the stars, traders can
                delve deeper, examining their heartbeats. The Average True Range, with its 5 and 14-day rhythms,
                measures the fervor and temperament of a stock, revealing its tales of volatility.<br/><br/>

                <b style={{color:"#F3B23E"}}>Volume's Luminance & Weightage's Gravity:</b> Volume - the luminescence of a star, hints at its
                prominence and the attention it garners. Meanwhile, its weightage in terms of price movement within
                the index acts as the gravitational force, signifying its potential to influence the galaxy's trajectory.
                For the keen astronomer-trader, recognizing a star's luminance or the fervency of its heartbeat can offer
                insights into its future. A star with an intense ATR might be unpredictable, dancing wildly in the vast
                space, while one with a softer ATR rhythm might glide smoothly.</Typography><br/><br/>

                Yet, the magic lies not just in understanding individual stars but in appreciating the galaxy as a whole.
                For traders steering their vessels through the financial cosmos, the index serves as both a guide and a
                vantage point. By combining a macroscopic view of the index with a microscopic analysis of its stars,
                traders can chart a course that's both informed and inspired. Welcome to the celestial symphony of
                trading!
            </Typography>  
            </Box>
        </TabPanel>
        
        {/* Spectrum Charts */}
        <TabPanel value={value} index={8}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
        <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={spectrumImg} alt="index_table" style={imgStyle} /><br/>
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
                <b style={{color:"#F3B23E"}}>The Spectrum: A Trader's Crystal Ball:</b><br/><br/>
                Picture the trading landscape as a vast ocean, where the ever-shifting waves represent the price moves
                of an index. The Spectrum, in this analogy, acts as a lighthouse, illuminating the possible breadth and
                depth of these waves over the upcoming week.<br/><br/>

                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>The Expanse of the Spectrum:</b> Much like the vastness between two horizons, a wide Spectrum suggests
                a tumultuous sea with potential high tides and deep troughs, indicating anticipated substantial price
                fluctuations. Conversely, a narrow Spectrum reflects a calm sea, with gentle, consistent waves
                suggesting steadier price movements.<br/><br/>

                <b style={{color:"#F3B23E"}}>Gleaning Insights from Options Data:</b> Our lighthouse - the Spectrum - is powered by the illuminating
                data from options. For each expiration date, the Spectrum casts its light, showcasing the predicted
                bounds of price oscillations for the index in the corresponding weekly settlement.<br/><br/>

                <b style={{color:"#F3B23E"}}>Charting the Voyage with the Spectrum:</b> Equipped with this beacon, traders can not only foresee the
                possible tides but also adjust their sails accordingly. This foresight might prompt them to reinforce their
                trading vessel during stormy forecasts or set forth with full sails in calmer waters, adapting positions or
                initiating new trades based on the Spectrum's projections.</Typography><br/><br/>

                In essence, the Spectrum becomes an indispensable compass for traders, guiding them through the
                weekly ebbs and flows of the index. By harnessing its insights, traders are better equipped to navigate
                the vast waters of the market, ensuring a voyage that's both strategic and informed. Dive into the ocean
                of trading with the Spectrum as your trusted guide!
            </Typography>  
            </Box>
        </TabPanel>

        {/* OI Change */}
        <TabPanel value={value} index={9}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
         <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={oichgImg} alt="oi_change" style={imgStyle} /><br/>
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
                <b style={{color:"#F3B23E"}}>Open Interest: The Heartbeat of Options Trading:</b><br/><br/>
                Just as a seismograph detects tremors and fluctuations in the Earth's crust, Open Interest acts as the
                pulse tracker for the world of options trading. It meticulously logs the total number of outstanding
                contracts for a designated strike price, serving as an invaluable metric for those keen on gauging market
                sentiment.<br/><br/>

                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Visualizing the Oscillations:</b> Imagine visualizing Open Interest's rhythm by plotting its continuous ebb
                and flow for both call and put options using moving lines on twin graphs. This panoramic view offers
                traders an immediate grasp of shifting trends across different strike prices, revealing which options are
                capturing the market's attention.<br/><br/>

                <b style={{color:"#F3B23E"}}>Reading Between the Lines:</b> A trader might notice, for instance, that the moving line for a specific call
                option is soaring, while its put counterpart is dwindling. Such a divergence could signify a bullish aura,
                with traders seemingly favoring call purchases over puts.<br/><br/>

                <b style={{color:"#F3B23E"}}>Forecasting with Open Interest:</b> Beyond gauging prevailing market sentiment, these moving lines can
                also hint at impending market scenarios. A simultaneous plunge in Open Interest for both calls and puts
                at a given strike price might foreshadow an upcoming phase of tranquility, hinting at low market
                volatility.</Typography><br/><br/>

                To encapsulate, envisioning the change in Open Interest using moving lines for both calls and puts is akin
                to having a bird's-eye view of the market's heartbeat. This elevated perspective equips traders with a
                clearer sense of market dynamics, aiding them in making strategic, informed trades. Dive deep into
                options trading with Open Interest as your trusted sonar, guiding through the market's undercurrents!
            </Typography>  
            </Box>
        </TabPanel>

         {/* NTM OI Change*/}
        <TabPanel value={value} index={10}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
        <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={ntmoichgImg} alt="ntm_oi_change" style={imgStyle} /><br/>
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
                <b style={{color:"#F3B23E"}}>Open Interest: The Precision of Granular Examination:</b><br/><br/>
                While a map provides an overarching view of a region, sometimes a magnified look is necessary to
                discern intricate details. Similarly, in the intricate dance of options trading, while Open Interest offers a
                sweeping perspective of market sentiment, focusing on near money strikes - be it In-the-Money (ITM) or
                At-the-Money (ATM) - furnishes traders with a richer, more nuanced understanding.<br/><br/>

                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>The Intimate Close-up:</b> Envisage the OI Changes meticulously calculated, not for the broad spectrum of
                options, but honed in on those pivotal few strikes that are either ITM or ATM. This narrowed lens
                captures shifts and nuances that might be missed in a wider view, echoing the importance of details in
                trading.<br/><br/>

                <b style={{color:"#F3B23E"}}>Deciphering Micro-Movements:</b> A slight shift in Open Interest for these select strikes can suggest
                looming market decisions, maybe even before they reverberate in the broader landscape. Recognizing
                these subtleties, a trader can anticipate potential strategic moves ahead of the broader market curve.
                Harmonizing Macro and Micro: While the fundamental logic of tracking OI Changes remains consistent,
                by marrying this with a concentrated focus on near money strikes, traders amalgamate the benefits of a
                holistic view with the precision of a scalpel.</Typography><br/><br/>

                In summation, by merging the broad overview provided by Open Interest with a laser focus on ITM or
                ATM strikes, traders are equipped with a comprehensive yet sharply detailed toolkit. This zoomed-in
                approach ensures no subtle movement goes unnoticed, enabling traders to navigate the ever-fluid
                options market with dexterity and foresight. Navigate the intricacies of options trading by celebrating
                both the wide-angle and the zoomed-in details with OI Change!
            </Typography>  
            </Box>
        </TabPanel>

        {/* HV Chart */}
        <TabPanel value={value} index={11}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
        <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={hvImg} alt="hv_chart" style={imgStyle} /><br/>
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
                <b style={{color:"#F3B23E"}}>The Role of Past Tumults in Shaping Future Trades:</b><br/><br/>
                In the world of options trading, the rearview mirror can often be as illuminating as the road ahead. The
                historical volatility, or the tale of an asset's past price fluctuations, becomes that mirror. By charting this
                volatility over time, traders receive a visual history lesson, allowing them to draw patterns and prepare
                for upcoming bends and turns.<br/><br/>

                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>The Chronicles of Price Movement:</b> Historical volatility is essentially a narration of how prices have
                danced—how fiercely, how gently, and how unpredictably. Plotted on a timeline, these levels become a
                rhythmic graph, revealing the asset's temperament and its moments of calm and caprice.<br/><br/>

                <b style={{color:"#F3B23E"}}>Recognizing Rhythms:</b> Just as history often repeats itself, markets exhibit recurring behavior. Identifying
                these cyclical patterns—be it an end-of-year surge or a mid-year lull—can empower traders to adapt
                and strategize. They might tap into a trend, brace for volatility, or exploit a calm.<br/><br/>

                <b style={{color:"#F3B23E"}}>Strategizing with Perspective:</b> Observing periods when historical volatility was at a minimum might hint
                at a subdued market, tempting traders to employ option-selling strategies for consistent, albeit smaller,
                returns. On the other hand, spikes in volatility might suggest gearing up for greater market movement
                and adjusting strategies accordingly.</Typography><br/><br/>

                In conclusion, historical volatility doesn't just offer a backward glance—it's a guide, a predictor, and a
                strategy-shaper. By attentively examining the past turbulence of an asset, traders can anticipate future
                gusts, navigating their ship with informed confidence. Embracing the teachings of historical volatility
                ensures that in the ever-challenging waters of options trading, history indeed becomes the best teacher.
            </Typography>  
            </Box>
        </TabPanel>

        {/* OI VIZ Charts */}
        <TabPanel value={value} index={12}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
        <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={ivizImg} alt="oi_viz_chart" style={imgStyle} /><br/>
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
            <b style={{color:"#F3B23E"}}>Unveiling the Hidden Patterns of the Options Market:</b><br/><br/>
            To navigate the ever-evolving realm of options trading, one must have the tools to see the unseen.
            Inventory visualization charts serve as the telescope for traders, offering a detailed panorama of market
            dynamics. These charts dissect and display key metrics, painting a vivid picture of market sentiments
            and trends. Whether it's through the lens of Open Interest (OI) or the subtleties of gamma, these visual
            aids become instrumental in shaping trading strategies.<br/><br/>

            <b style={{color:"#F3B23E"}}>The Role of Open Interest (OI):</b><br/><br/>
            <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>OI's Panoramic View:</b> Visualizing OI provides an aerial view of how writers are positioned across
            different strikes. Each chart encapsulates different OI tales, shedding light on the prevailing mood of the
            market.<br/><br/>

            <b style={{color:"#F3B23E"}}>OI - Split:</b> This vantage point bifurcates the landscape, displaying the total OI for both Put and Call
            options per strike. It's akin to observing the ebb and flow of two parallel rivers at a specific moment in
            time.<br/><br/>

            <b style={{color:"#F3B23E"}}>OI Change - Split:</b> Witness the dynamic dance of the market. By capturing the daily flux in OI for both
            Puts and Calls, traders can gauge the daily momentum and direction.<br/><br/>
            
            <b style={{color:"#F3B23E"}}>OI Difference:</b> Here, the focus narrows to the tug-of-war between Call OI and Put OI, offering a snapshot
            of the net difference at any given instance.<br/><br/>

            <b style={{color:"#F3B23E"}}>Straddle OI:</b> This perspective combines the forces of both worlds, showing the total OI strength of a
            Straddle, where Call and Put OIs unite.<br/><br/>

            <b style={{color:"#F3B23E"}}>Volume - Split:</b> Delve deeper by exploring the volume waters of both Put and Call options, unveiling the
            depth of activity per strike.<br/><br/>

            <b style={{color:"#F3B23E"}}>OI Pain Value:</b> This angle reflects the pulse of the market. By visualizing the OI-based Option Pain value,
            traders can identify potential pressure points in the market's psyche.
            </Typography>
            </Typography>  
            </Box>
        </TabPanel>

        {/* Gamma Charts */}
        <TabPanel value={value} index={13}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
        <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={gammaImg} alt="gamma_chart" style={imgStyle} /><br/>
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
                    <b style={{color:"#F3B23E"}}>Navigating the Nuanced Landscape of Options with Gamma:</b><br/><br/>
                    For those seeking a deeper understanding of options inventory, our Gamma Visualization charts offer a
                    revolutionary prism, meticulously crafted from our proprietary research. They're not just about
                    displaying data; they unravel the hidden dynamics of options, shining a light on the convictions of both
                    writers and buyers.<br/><br/>

                    <b style={{color:"#F3B23E"}}>The Essence of Gamma:</b><br/><br/>
                    <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Gamma's Fluidity:</b> Gamma is more than a value; it's a dynamic beacon, signaling the shifting currents of
                    the market. Its significance lies in its capability to foreshadow the evolving sentiment encapsulated
                    within the open interest.<br/><br/>
                    <b style={{color:"#F3B23E"}}>Gamma's Blueprint:</b> Visualized as a histogram, Gamma is segmented for both calls and puts, tailored for
                    specific expiry dates. It unravels the story of every strike, offering a tapestry of market sentiment.<br/><br/>

                    <b style={{color:"#F3B23E"}}>Gamma & OI Synergy:</b> Paired with open interest change charts, Gamma serves as a compass,
                    demarcating the robustness of support and resistance levels. The Chart 2 window option facilitates a
                    simultaneous view, merging both narratives into a cohesive picture.</Typography><br/><br/>

                    <b style={{color:"#F3B23E"}}>Rupee Denominated Insights:</b><br/><br/>
                    <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Inventory Value's Dance:</b> Gamma's influence isn't static; it dynamically adjusts with shifts in the
                    underlying asset. This value, expressed in rupees, hints at the volume of contracts needed for
                    equilibrium. On settlement days, high inventory values act like magnets, drawing the market's attention
                    and influencing its trajectory.<br/><br/>

                    <b style={{color:"#F3B23E"}}>The Split Perspective:</b> This feature dissects the inventory value, shedding light on the market's
                    recalibrations in response to the underlying's movements.</Typography><br/><br/>

                    <b style={{color:"#F3B23E"}}>The Depths of Exposure and Writers:</b><br/><br/>
                    <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Exposure Value:</b> A barometer of risk and position, this value captures the rupee notional standing of a
                    specific strike for options writers.<br/><br/>

                    <b style={{color:"#F3B23E"}}>Exposure Value Split:</b> Beyond the aggregate, this dissects the sensitivity of individual calls and puts,
                    acting as a thermometer of price responsiveness.<br/><br/>

                    <b style={{color:"#F3B23E"}}>Writers' Cash:</b> Arguably the most tangible of metrics, it crystallizes the combined premium in rupees,
                    pooling together the resources of all writers associated with a particular strike at a specific moment.</Typography><br/><br/>

                    In summary, our Gamma Visualization charts are designed as a navigator for the complex waters of
                    options trading. They're not merely analytical tools; they're the bridge between data and decision-
                    making. By decoding the subtleties of Gamma and its associated metrics, traders are empowered to
                    traverse the options market with enhanced clarity and conviction
            </Typography>  
            </Box>
        </TabPanel>

         {/* IV Curve Chart                */}
        <TabPanel value={value} index={14}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
        <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={ivcurveImg} alt="iv_curve_chart" style={imgStyle} /><br/>
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
                <b style={{color:"#F3B23E"}}>Mapping the Mystique of Market Expectations with IV:</b><br/><br/>
                Options traders who aim to delve deeper into the heart of market intricacies recognize the potent tool
                that is the IV curve. Beyond mere figures, IV encapsulates the market's heartbeat, signifying its
                anticipation regarding the fluctuations of an underlying asset.<br/><br/>

                <b style={{color:"#F3B23E"}}>The Essence of IV:</b><br/><br/>
                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Understanding IV:</b> Implied Volatility is akin to the market's forecast of an asset's potential volatility,
                discerned from the prism of option prices.<br/><br/>

                <b style={{color:"#F3B23E"}}>IV Curves & Strikes:</b> Visualizing IV against different strikes forms a landscape, chronicling the oscillation
                of expected volatility as we traverse from one strike price to another. A distinctive trait observed is the
                elevation of IV at the at-the-money (ATM) juncture, where uncertainty thrives. Venturing away from this
                epicenter, IV generally mellows down.</Typography><br/><br/>

                <b style={{color:"#F3B23E"}}>Deciphering the IV Landscape:</b><br/><br/>
                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Gauging Stability with IV:</b> A flattened or U-shaped IV curve subtly conveys an anticipated tranquil
                journey for the asset price. However, an upward incline in the IV curve heralds impending storms,
                indicating heightened volatility.<br/><br/>

                <b style={{color:"#F3B23E"}}>The Temporal Dance of IV:</b> Pitting IV curves of diverse expiration dates against each other is
                enlightening. The ticking clock amplifies the drama of volatility as expiration looms, a phenomenon
                etched onto the evolving IV curve.</Typography><br/><br/>

                <b style={{color:"#F3B23E"}}>Strategy Crafting with IV:</b><br/><br/>
                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Fine-Tuning with IV:</b> An astute analysis of IV curves equips traders to fine-tune their game plan. By
                discerning the shifts in IV and juxtaposing curves across strikes and timeframes, traders can spot the
                crests and troughs of opportunities, steering clear of potential pitfalls.</Typography><br/><br/>

                In essence, IV curves are more than just graphical representations; they're the compass guiding traders
                through the volatile seas of the options market. By adeptly navigating the waves of IV, traders are better
                poised to set their sails in the direction of profitable winds.
            </Typography>  
            </Box>
        </TabPanel>

        {/* Snake Chart */}
        <TabPanel value={value} index={15}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
        <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            <img src={oisnakesImg} alt="snake_chart" style={imgStyle} /><br/>
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
                <b style={{color:"#F3B23E"}}>Decoding the Sinuous Moves of Net Open Interest (OI):</b><br/><br/>
                The realm of options trading offers a plethora of tools to those who venture into its depths. Among
                these, the Options Snakes Line Charts stand out, offering a visual narrative of the ebb and flow of
                market interest.<br/><br/>

                <b style={{color:"#F3B23E"}}>Understanding Net OI:</b><br/><br/>
                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Deciphering Net OI:</b> Net Open Interest is an aggregate representation, a sum total if you will, of all the
                active contracts, either across the spectrum of strikes or honed into a specific strike for a chosen option.<br/><br/>

                <b style={{color:"#F3B23E"}}>The Role of Net OI:</b> Serving as a pulse-check, Net OI helps gauge the collective interest or appetite in a
                given option. A surge might indicate heightened trading activity, while a dip could signal a waning
                interest.</Typography><br/><br/>

                <b style={{color:"#F3B23E"}}>Interpreting the Straddle OI Landscape:</b><br/><br/>
                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Straddle OI - A Dual Narrative:</b> By visualizing net straddle OI, which combines the OI for both calls and
                puts at each strike, traders get a panoramic view of the market's sentiment. This strike-wise breakdown
                can unveil patterns or trends that might otherwise remain obscured.<br/><br/>

                <b style={{color:"#F3B23E"}}>Temporal Dynamics of OI:</b> Observing the progression of net straddle OI over time is akin to watching a
                dance. As the choreography unfolds, traders can discern shifts, turns, and pivots that signify evolving
                market dynamics.</Typography><br/><br/>

                <b style={{color:"#F3B23E"}}>Strategizing with Net OI Insights:</b><br/><br/>
                <Typography sx={{ ml:1}}><b style={{color:"#F3B23E"}}>Guided Decision Making:</b> The undulating nature of the Options Snakes Line Chart provides traders with
                actionable intelligence. Recognizing peaks or valleys in net OI can guide entry or exit decisions, allowing
                traders to move in tandem with the market's rhythm.<br/><br/>

                <b style={{color:"#F3B23E"}}>Fine-tuning Positions:</b> Beyond just spotting opportunities, the insights from these charts can also inform
                position adjustments, hedging strategies, or even risk assessment.</Typography><br/><br/>

                In a nutshell, Options Snakes Line Charts are not just visual aids; they're strategic maps guiding traders
                through the ever-changing topography of the options market. With these charts as their guide, traders
                can anticipate the market's next move and dance along with its rhythm.
            </Typography>  
            </Box>
        </TabPanel>

        {/* All Options Total Volumes Chart */}
        <TabPanel value={value} index={16}openDrawer={openDrawer} isDrawerOpen={isDrawerOpen}>
        <Box  align='justify'>
            <Typography id="modal-modal-description" sx={{ ml:1, mr:1, mt: 2, mb:2,fontWeight: 600,color:"#F8D32D" }} variant="h5">{USER_MANUAL_TAB_LIST[value].title}</Typography>
            {/* <img src={oisnakesImg} alt="snake_chart" style={imgStyle} /><br/> */}
            <Typography sx={{ ml:1, mr:1, mt: 4 }}>
                <b style={{color:"#F3B23E"}}>Decoding the Moves of Total Volumes:</b><br/><br/>
                Total Call volumes across all strikes vs Total Put volumes across all strikes of the active series.<br/><br/>

                In a nutshell, Volumes Line Charts are not just visual aids; they're strategic maps guiding traders
                through the ever-changing topography of the options market. With these charts as their guide, traders
                can anticipate the market's next move and dance along with its rhythm.
            </Typography>  
            </Box>
        </TabPanel>
        
      </>
     
    )
}

export default UserManual
