import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Checkbox,Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import { TABLE_COLORS,OPTIONS_TABLE_CHARTS } from './table_constants';
import AbcIcon from '@mui/icons-material/Abc';
import { ShowChartOutlined } from '@mui/icons-material';
import { USER_ROLE_FIELDS } from './constant';



export const DefaultRenderer=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
        {props.value!=undefined && props.value!==""?
            <span>{props.value}</span>:
            <>-</>
        }
        </div>
}

export const DefaultRendererWithLocale=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
        {props.value!=undefined?
            <span>{props.value.toLocaleString(props.locale_string)}</span>:
            <>-</>
        }
        </div>
}

export const DateRenderer=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
    {props.data.dateList.length==1?
        <>
        <span>{props.data.date}</span>
        </>
        :
        <><span>{props.data.dateList.length}D:&nbsp;{props.data.date}</span></>
    }
    </div>
}

export const TPOVolumeRenderer=(props)=>{
    console.log("TPOVolumeRenderer",props)
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
        {props.data.tpo_vol_map!=undefined&&props.data.tpo_vol_map[props.tpo]!=undefined?
            <span>{props.data.tpo_vol_map[props.tpo].toLocaleString(props.locale_string)}</span>:
            <>-</>
        }
        </div>
}

export const DefaultRendererDifferenceWithLocale=(props)=>{
    return <div style={{display:'flex',width:"100%",alignSelf:"center",justifyContent:"flex-end"}}>
        {props.value!=undefined?
            <>
            {props.value>=0?
            <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>:
            <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>
                }

            <span>{props.value.toLocaleString(props.locale_string)}</span>
            </>:
            <>-</>
        }
        </div>
}

// cellRendererParams: {
//     locale_string: locale_string,

// },
export const getPriceTableColumneDef=(auth,locale_string)=>{
    let proUser=auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA)?true:false;
    let def;
    // if(proUser){
        def=[ 
            
            { field: 'date',headerName:"Date" ,minWidth: 100,maxWidth:120,cellRenderer:DateRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'vwap',headerName:"VWAP" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'vah',headerName:"VAH" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'vpoc',headerName:"VPOC" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'val',headerName:"VAL" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            
            { field: 'highVal',headerName:"High" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'ib_high',headerName:"IBH" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'lowVal',headerName:"Low" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
                cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'ib_low',headerName:"IBL" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
            cellStyle: function(params) {
            return { fontSize:12}
            }
            },
            { field: 'open',headerName:"Open" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'close',headerName:"Close" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'ib',headerName:"IB_R" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'range',headerName:"Day_R" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'oi',headerName:"OI" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererWithLocale,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'doi',headerName:"dOI" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererDifferenceWithLocale,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'coi',headerName:"COI" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererWithLocale,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'dcoi',headerName:"dCOI" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererDifferenceWithLocale,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'ib_vol',headerName:"IB_Vol" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererWithLocale,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'volume',headerName:"Volume" ,minWidth: 70,maxWidth:90,cellRenderer:DefaultRendererWithLocale,
            cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'A',headerName:"A" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"A"    
             }
            },
            { field: 'B',headerName:"B" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"B"    
             }
            },
            { field: 'C',headerName:"C" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"C"    
             }
            },
            { field: 'D',headerName:"D" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"D"    
             }
            },
            { field: 'E',headerName:"E" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"E"    
             }
            },
            { field: 'F',headerName:"F" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"F"    
             }
            },
            { field: 'G',headerName:"G" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"G"    
             }
            },
            { field: 'H',headerName:"H" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"H"    
             }
            },
            { field: 'I',headerName:"I" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"I"    
             }
            },
            { field: 'J',headerName:"J" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"J"    
             }
            },
            { field: 'K',headerName:"K" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"K"    
             }
            },
            { field: 'L',headerName:"L" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"L"    
             }
            },
            { field: 'M',headerName:"M" ,minWidth: 70,maxWidth:90,cellRenderer:TPOVolumeRenderer,
            cellStyle: function(params) {
                return { fontSize:12}
             },
             cellRendererParams: {
                tpo:"M"    
             }
            },
           
            
    ];
    // }else{

    // }

    return def;
}

