import { Drawer, Box, Typography, IconButton, ListItemSecondaryAction,Collapse} from '@mui/material'
import { useState,useEffect,useCallback } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { getDrawerList } from '../../common/utility/configData'
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Settings from '@mui/icons-material/Settings';
import { ChevronLeft } from '@mui/icons-material';
import { getIndexOfRoute,getIndexOfRouteNew } from '../../common/utility/helperFunc';
import { ALLOWED_ROLES_PRO_1, TEXT_MSGS, USER_ROLE_FIELDS } from '../../common/utility/constant';
import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';
import { useNavigate} from 'react-router-dom';
import { Snackbar, Alert} from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import DrawerContextMenu from './drawer_context_menu';
import useAuth from '../../common/hooks/useAuth';
import LockIcon from '@mui/icons-material/Lock';
import RupeeIcon from '@mui/icons-material/CurrencyRupee';
import packageJson from '../../../package.json';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { DrawerNestedMenu } from './drawerNestedMenu';
import { index } from 'd3';


const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';


export const APPDrawer = (props) => {
  
  const routePath=window?.location?.pathname;
  const { auth } = useAuth();
  // console.log("path================================>auth======================",window?.location?.pathname,window?.location,auth);
  const search=window?.location?.search;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [drawerListData,setDrawerList]=useState(getDrawerList(auth))
  // const [selectedIndex, setSelectedIndex] = useState(getIndexOfRoute(routePath,search,auth));
  const [selectedItem, setSelectedItem] = useState(getIndexOfRoute(routePath,search,auth));
  const { cancel,responseData, error, loaded, reset,executeAPI } = useAxios();
  const [showContextMenu,setShowContextMenu]=useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [url,setURL]=useState(undefined);
  const [collapsed, setCollapsed] =useState(true);

  const handleClick = (i) => {
    drawerListData[i].open=!drawerListData[i].open;
    setDrawerList(drawerListData);
    console.log(i,drawerListData[i])
    // setCollapsed(!collapsed);
  };
  
 
 
      
 
 const navigate = useNavigate();
 const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  const { open, msg,severity } = msgState;

  const exitMenu=()=>{
    setShowContextMenu(false);
   }

   useEffect(()=>{
    console.log("Selected Item in drawer=",selectedItem)
     },[])

  // const handleListItemClick = (event, index) => {
  //   const found = auth.role.some(r=> drawerListData[index].roles.indexOf(r) >= 0)
  //   const resourceAllowed=drawerListData[index].resources==undefined?true:auth.resources?.some(r=> drawerListData[index].resources.indexOf(r) >= 0);
  //   const isProNeeded = (ALLOWED_ROLES_PRO_1 === drawerListData[index].roles);
  //   if(!found || !resourceAllowed){
  //       console.log("Not allowed for the roles",auth.role,drawerListData[index].roles, isProNeeded)
  //       //show modal
  //       if(isProNeeded){
  //         if(drawerListData[index].name=="Orderflow Charts" && auth.role.includes(USER_ROLE_FIELDS.PRO)){
  //           console.log("OF drawer inside")
  //           props.openSubscribeProModal(true,TEXT_MSGS.PREMIUM_PLUS_UPGRADE_ORDERFLOW_MSG);
  //         }
  //         else
  //           props.openSubscribeProModal();
  //       }
  //       else
  //         props.openSubscribeModal();
  //       setIsDrawerOpen(false); 
  //       return;
  //   }
  //   if(selectedIndex!==index){
  //     console.log("drawer clicked event=",event)
  //     if(event.ctrlKey ||event.metaKey){
  //       window.open( `${process.env.REACT_APP_URL}${drawerListData[index].route.slice(1)}`, '_blank', 'noreferrer');
   
  //     }
  //   else
  //   {
  //     setSelectedIndex(index);
  //     console.log("drawer pageview",window.location.pathname + window.location.search,drawerListData[index].route);
  //     props.onChange(drawerListData[index].route,drawerListData[index].component);
  //     setIsDrawerOpen(false);  
  //   }
  // }
  // };
  const handleListItemClick = (event, index,item,parentIndex=-1) => {
    const found = auth.role.some(r=> item.items?item[index].roles.indexOf(r) >= 0:item.roles.indexOf(r) >= 0)
    const resourceAllowed=(item.items?item[index].resources==undefined:item.resources==undefined)?true:auth.resources?.some(r=> item.resources.indexOf(r) >= 0);
    const isProNeeded = (item.items?ALLOWED_ROLES_PRO_1 === item[index].roles:ALLOWED_ROLES_PRO_1 === item.roles);
    if(!found || !resourceAllowed){
        // console.log("Not allowed for the roles",auth.role,item, isProNeeded)
        //show modal
        if(isProNeeded){
          if(item.name=="Orderflow Charts" && auth.role.includes(USER_ROLE_FIELDS.PRO)){
            console.log("OF drawer inside")
            props.openSubscribeProModal(true,TEXT_MSGS.PREMIUM_PLUS_UPGRADE_ORDERFLOW_MSG);
          }
          else
            props.openSubscribeProModal();
        }
        else
          props.openSubscribeModal();
          setIsDrawerOpen(false); 
        return;
    }
    console.log("Broker item=",selectedItem,item)
    if(item.isBrokerPopup){
      props.openBrokerModal();
      setIsDrawerOpen(false); 
      return;
      
    }
    // if(selectedIndex!==index){
      if(selectedItem.childIndex!=index || selectedItem.parentIndex!=parentIndex){
        if(event.ctrlKey ||event.metaKey){
            window.open( `${process.env.REACT_APP_URL}${item.route.slice(1)}`, '_blank', 'noreferrer');
         }
        else
        {
          // setSelectedIndex(index);
          setSelectedItem({parentIndex:parentIndex,childIndex:index})
          // console.log("drawer pageview",window.location.pathname + window.location.search,item.route);
          console.log("Nested menu click 2=",index,parentIndex,selectedItem,item)
            props.onChange(item.route,item.component);
          setIsDrawerOpen(false);  
        }
    }else{
      window.location.reload();
    }
  };
  

  /**
 * API response handler  
 */  
  useEffect(() => {
    if(loaded){
      if(responseData!=null){
        navigate('/login', { replace: true });
      }
      else if(error!==null){
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
        ,severity:"info"});
        
        reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loaded,responseData,error,navigate]);



/**
 * API caller for logout  
 */  
 
  const logout=()=>{
    executeAPI(URL.LOGOUT,"POST",null); 
    setIsDrawerOpen(false);  
  
  }

/**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };
  
   //closes the context menu
  

  const handleContextMenu=(event,index,item)=>{
    // console.log("context menu drawer event=",event,index,event.target.baseURI);
    event.preventDefault()
    setURL(`${process.env.REACT_APP_URL}${item.route.slice(1)}`)
    // if(item.items)
    //   setURL(`${process.env.REACT_APP_URL}${item[index].route.slice(1)}`)
    // else
    //   setURL(`${process.env.REACT_APP_URL}${drawerListData[index].route.slice(1)}`)
    setAnchorPoint({ x: 10, y: event.pageY });
    setShowContextMenu(true);  
    }
  const openDrawer=()=>{
    const routePath=window?.location?.pathname;
    const search=window?.location?.search;
    // console.log("UseEffect get selected index=",getIndexOfRoute(routePath,search))
    setSelectedItem(getIndexOfRoute(routePath,search,auth));
    setIsDrawerOpen(true);
  }
  return (
    <>
    <>
      <IconButton
        onClick={() => openDrawer()}
        size='large'
        edge='start'
        aria-label='logo'>
        <MenuIcon sx={{color:'primaryTheme.shade01',width:"32px",height:"32px"}}/>
      </IconButton>
      <Drawer
        anchor='left'
        open={isDrawerOpen}
        onClose={() =>{ 
          if(showContextMenu)
          setShowContextMenu(false);
          else{
            setShowContextMenu(false);
            setIsDrawerOpen(false)
          }
          }}>
        
        <Box p={1} width='320px' role='presentation' textAlign="left" height="100%" >
        
          <Box sx={{width:"100%",display:"flex",justifyContent:"end"}}>
            <Box sx={{width:"70%",display:"flex",justifyContent:"flex-start",textAlign:"center",alignItems:"center"}}>
              <Typography variant='titleSmall' sx={{ml:2}}>
                DASHBOARD&nbsp;&nbsp;&nbsp;<span style={{fontWeight:500,fontSize:11}}>Version {packageJson.version}</span>
              </Typography> 
            </Box>
            <Box>
            {showContextMenu?
    <DrawerContextMenu 
      xPosition={anchorPoint.x}
      yPosition={anchorPoint.y} 
      url={url}
      exitMenu={exitMenu}
      />:
    <></>}
    </Box>
    
            <Box sx={{width:"30%",display:"flex",justifyContent:"end"}}>
              <IconButton 
                  sx={{ color: 'inherit'}}
                  onClick={() => setIsDrawerOpen(false)}>
                  <ChevronLeft/>
                </IconButton>
              </Box>
            </Box>
          
          <Divider sx={{pt:0}}/>

          {/* <ListItemButton
            //  divider={true}
              key={"subcription"}
              sx={{ py: 1, minHeight: 40, color: 'white' }}
              onContextMenu={(event)=>handleContextMenu(event,index)}
              onClick={(event) => handleListItemClick(event, index)}
            >
              <ListItemText
                primary={"Subscription Plans"}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
              <ListItemIcon sx={{ml:0,minWidth:18}}>
                <RupeeIcon sx={{width:18}} />
              </ListItemIcon>
          </ListItemButton>
          <Divider sx={{pt:0}}/> */}
          <Box sx={{pt:2}}>
          {isDrawerOpen &&
                drawerListData.map((item,index) => (
                  <>
                  {item.items==undefined?
                  <ListItemButton
                  //  divider={true}
                    key={item.name}
                    sx={{ py: 1, minHeight: 40, color: 'white', paddingRight:0  }}
                    // sx={item.name=="Subscription Plans"?{ py: 1, minHeight: 40, background: '#163569', color: 'white', paddingRight:0  }: { py: 1, minHeight: 40, color: 'white', paddingRight:0  }}
                    selected={selectedItem.childIndex === index && selectedItem.parentIndex==-1}
                    onContextMenu={(event)=>handleContextMenu(event,index,item)}
                    onClick={(event) => handleListItemClick(event, index,item)}
                  >
                    {/* <ListItemIcon sx={{ color: 'inherit' }}>
                      {item.icon}
                    </ListItemIcon> */}
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                    />
                    { !auth.role.some(r=> item.roles.indexOf(r) >= 0) || (item.resources!=undefined && !auth.resources?.some(r=> item.resources.indexOf(r) >= 0))?
                    
                        ALLOWED_ROLES_PRO_1 == item.roles ?
                          <ListItemIcon sx={{ml:0,minWidth:24, color:"#DCB146"}}>
                            <LockIcon sx={{width:18}} />
                          </ListItemIcon>
                          :
                          <ListItemIcon sx={{ml:0,minWidth:24, color:"silver"}}>
                            <LockIcon sx={{width:18}} />
                          </ListItemIcon>
                        
                        : 
                          item.name=="Subscription Plans"? 
                          <ListItemIcon sx={{ml:0,minWidth:18}}>
                            <RupeeIcon sx={{width:18}} />
                          </ListItemIcon> : 
                        <></>
                    }
                    </ListItemButton>
                    :<>
                    <DrawerNestedMenu item={item} onClick={handleListItemClick} onContextMenuClick={handleContextMenu} selectedItem={selectedItem} index={index}/>
                    {/* <ListItemButton sx={{ py: 1, minHeight: 40, color: 'white', paddingRight:0  }} onClick={()=>handleClick(index)}>
        
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                    />
                     {item.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                      {item.items.map((nestedItem,nestedIndex) => (
                        <ListItemButton sx={{ py: 1, minHeight: 40, color: 'white', paddingRight:0  }} onClick={()=>handleClick(nestedIndex)}>
        
                        <ListItemText
                          primary={nestedItem.name}
                          primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                        />
                         
                        </ListItemButton>
                        ))}
                      </Collapse> */}


                    </>
                    }
                    
                    </>
                  
                ))}
                </Box>
                <Divider sx={{pt:0}}/>
                <ListItemButton
                  //  divider={true}
                    key={"logout"}
                    sx={{ py: 1, minHeight: 40, color: 'white' }}
                    onClick={logout}
                  >
                    {/* <ListItemIcon sx={{ color: 'inherit' }}>
                      <PowerSettingsNewIcon/>
                    </ListItemIcon> */}
                    <ListItemText
                      primary={"Logout"}
                      primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                    />
                    </ListItemButton>
                  
          </Box>
      </Drawer>
    

      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
      <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
         {msg}
       </Alert>  
     </Snackbar>     
</>
  {/* {showContextMenu?
    <DrawerContextMenu 
      xPosition={anchorPoint.x}
      yPosition={anchorPoint.y} 
      url={url}
      exitMenu={exitMenu}
      />:
    <></>} */}
    </>
  )
}
