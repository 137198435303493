import React, { useEffect } from 'react'
import { Typography,Link, Container,Toolbar,IconButton,Tooltip,Grid,Box,Button} from '@mui/material'
import {styled, useTheme} from '@mui/material/styles';


export const Footer=styled(Toolbar)(
    ({theme})=> `
    background-color:${theme.palette.primaryTheme.shade07};
    height:32px;
    position:fixed;
    bottom:32px;
    justify-content:center;
    text-align:center;
    z-index:999;
    ${theme.breakpoints.up('mobile')}{
      min-height:32px;
    }
   `
  )
 // width:${props.canvasWidth}+"px;
//    bottom:32px;
    

function OptionsFooter(props) {
  const theme=useTheme();
 
  useEffect(()=>{
    console.log("Option Footer Selected rows=",props.selectedRows)
 })
  const openChart=()=>{
    props.openChart();
}
  return (
    <>
      <Footer sx={{borderTop: `1px solid ${theme.palette.primaryTheme.shade03}`,width:`calc(100vw)` }} >
      <Grid  container >
                <Grid item mobile={8} tablet={8} laptop={8} >
                    <Box display="flex" justifyContent="flex-start" sx={{width:"100%",height:"100%",textAlign:'center'}}>
                    {props.selectedRows.call!=undefined && props.selectedRows.put!=undefined?
                        <Typography  variant="copyRight" align="center" sx={{alignSelf:"center",color:'primaryTheme.shade01',fontSize:"14px"}} >
                           Selected Option Pair Chart:&nbsp; 
                           <Typography variant='link'  
                            
                                sx={{color: theme.palette.secondaryTheme.shade01,cursor:'pointer'}}
                                onClick={()=>props.openChart()}
                                >
                                  {/* {props.selectedRows.put.pe_instr}_{props.selectedRows.call.ce_instr} */}
                                {props.selectedRows.put.pe_instr.slice(2)}_{props.selectedRows.call.ce_instr.slice(2)}
                           </Typography>
                        </Typography>:
                        <Typography  variant="copyRight" align="center" sx={{alignSelf:"center",color:'primaryTheme.shade01'}}>
                          Select on the other side to view Option Chart.
                    </Typography>}
                    
                    </Box>
                </Grid>
                
                <Grid item mobile={4} tablet={4} laptop={4} >
                  <Box display="flex" sx={{height:"100%"}} justifyContent="end" alignItems="center">
                  {props.selectedRows.call!=undefined && props.selectedRows.put!=undefined?
                  <Button variant="contained" onClick={()=>openChart()} sx={{mr:3,fontSize:"12px",height:26,borderRadius:"0px",marginBottom:"2px"}}>
                    Open Chart
                  </Button>:
                  <></>}
                  </Box>
                </Grid>
            </Grid>
      </Footer>
    </>
  )
}

export default OptionsFooter