import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmActionDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const [posName, setPosName] = React.useState(props.name);
  
  const handleClose = () => {
    // setOpen(false);
    props.cancelHandler();
  };

  const handleSave = () => {
    // setOpen(true);
    // console.log("handleSave=",posName);
    if(posName != undefined)
      props.saveHandler(posName);  // delete position
    else
      props.saveHandler();
  };

 
  return (
    <div>
     
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{whiteSpace:'pre-line'}}>
            {props.description}
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleSave}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
