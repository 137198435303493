import React from 'react'
import { Toolbar,Grid,Button,Box,Link} from '@mui/material'
import {styled} from '@mui/material/styles';
import logo from '../../../../assets/images/logo.png';


const HeaderToolbar=styled(Toolbar)(
  ({theme})=> `
  background-color:${theme.palette.primaryTheme.shade07};
  width:100vw;
  height:64px;
  position:fixed;
  z-index:999;
  ${theme.breakpoints.up('mobile')}{
    min-height:64px;
  }
  `
)

/**
 * 
 * @param {showLogin} props: it will used to toggle between Login and Register button in the flow 
 * @returns The main header component for the login/registerflow
 */

function Header(props) {
  return (
    <div>
        <HeaderToolbar >
        <Grid  container >
          <Grid item mobile={6} tablet={6} laptop={6} >
            <Box display="flex" justifyContent="flex-start">
              <Link href="https://www.vtrender.com/" variant="secondaryLink"  target="_blank" rel="noopener noreferrer">
                <img src={logo} alt="Vtrender Logo" width={120} height={30}/>
              </Link>
            </Box>
        </Grid>
        <Grid item mobile={6} tablet={6} laptop={6} >
            <Box display="flex" justifyContent="flex-end" alignItems={"center"}>
                {props.showIntro?
                  <Button variant="outlined" href="/info-manual" sx={{mr:2}}>
                      Info Manual
                  </Button>:
                  <></>
                }
                <Button variant="outlined" href="/pricing" sx={{mr:2}}>
                    Pricing
                </Button>
                {props.showLogin?
                <Button  variant="outlined" href="/login" sx={{mr:2}}>
                    Login
                </Button>:
                <Button variant="outlined" href="/register" sx={{mr:2}}>
                    Register
                </Button>
                }
            
              </Box>
              </Grid>
        </Grid>
        </HeaderToolbar>
      
    </div>
  )
}

export default Header


