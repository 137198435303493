import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box,Typography,Checkbox,Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import { TABLE_COLORS,OPTIONS_TABLE_CHARTS } from './table_constants';
import AbcIcon from '@mui/icons-material/Abc';
import { ShowChartOutlined } from '@mui/icons-material';
import { USER_ROLE_FIELDS } from './constant';
import { OPTCHAIN_COLUMNS_LIST } from './constant';


export const CallCheckBoxRenderer = (props) => {
    const checkedHandler=(event)=> {
        let checked = event.target.checked;
        let colId = props.column.colId;
        console.log("checkbox clicked call=",checked,colId,props)
        props.node.setDataValue(colId, checked);
        props.handleChange(colId,checked,props.rowIndex);
      }
    return (
        <>
        {props.data.ce_instr!=undefined?
        <Checkbox 
            checked={props.data.ce_checked}
            onClick={checkedHandler}
        />:
        <></>
        } 
        </>  
  )
}

export const PutCheckBoxRenderer = (props) => {
    const checkedHandler=(event)=> {
        let checked = event.target.checked;
        let colId = props.column.colId;
        console.log("checkbox clicked put=",checked,colId,props)
        props.node.setDataValue(colId, checked);
        props.handleChange(colId,checked,props.rowIndex,props.data);
      }
    return (
        <>
        {props.data.pe_instr!=undefined?
            <Checkbox 
                checked={props.data.pe_checked}
                onClick={checkedHandler}
            />:
            <></>
        } 
        </>   
  )
}


export const DefaultRenderer=(props)=>{
    return <div style={{alignSelf:"center",alignItems:"center !important"}}>
        {props.value!=undefined?
            <span>{props.value}</span>:
            <>-</>
        }
        </div>
}

export const CallVolumeRenderer=(props)=>{
    return <div style={{color:props.data.ce_vol_alert?TABLE_COLORS.HIGHTLIGHT_VOL:null,fontWeight:props.data.ce_vol_alert?700:null, alignSelf:"center",alignItems:"center !important"}}>
            {props.value!=undefined?
                <span>{props.value.toLocaleString(props.data.locale_string)}</span>:
                <></>
            }
        </div>
}

export const PutVolumeRenderer=(props)=>{
    return <div style={{color:props.data.pe_vol_alert?TABLE_COLORS.HIGHTLIGHT_VOL:null,fontWeight:props.data.pe_vol_alert?700:null, alignSelf:"center",alignItems:"center !important"}}>
            {props.value!=undefined?
                <span>{props.value.toLocaleString(props.data.locale_string)}</span>:
                <>-</>
             }
        </div>
}

export const StrikeRenderer=(props)=>{
    const handleMP=()=>{
        props.handleMPChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL_PUT_BOTH,props.data);
    }
    const handleLine=()=>{
        props.handleLineChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL_PUT_BOTH,props.data); 
    }
   
    // console.log("Strike row index=",props.rowIndex);
    return <div style={{alignSelf:"center",display:"flex",width:"100%",justifyContent:"flex-start", alignItems:"center !important"}}>
                {props.data.straddle_signal!=undefined?
                <span style={{color: props.data.straddle_signal=="[B]"?TABLE_COLORS.GREEN:props.data.straddle_signal=="[S]"?TABLE_COLORS.RED:"#fff",fontWeight:700}} >{props.data.straddle_signal}&nbsp;</span>
                :<></>}
                <span>{props.value}</span>
                <div>
                {props.data.pe_instr!=undefined && props.data.ce_instr!=undefined?
                <>        
                    <Tooltip title={props.value + " Straddle MP chart"}>
                    <AbcIcon onClick={handleMP}sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}></AbcIcon>
                    </Tooltip>
                    <Tooltip title={props.value + " Straddle Line chart"}>
                    <ShowChartOutlined onClick={handleLine} sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}/>
                    </Tooltip>
                </>:
                <></>
                }
                </div>
            </div>
}

export const CallMaxMinOIRenderer=(props)=> {
    return  <div style={{alignSelf:"center",justifyContent:'flex-end'}}>
                {props.data.ce_max_oi!=undefined && props.data.ce_max_oi!=0?
                    <>
                    <span >{props.data.ce_max_oi}</span>
                    <div style={{height:"20px"}}>
                        <span >{props.data.ce_min_oi}</span>
                    </div>
                    </>:
                    <div style={{display:'flex',justifyContent:'center'}}>-</div>
                }           
            </div>
}

export const CallDOIOIRenderer=(props)=> {
    if(props.data.ce_doi==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return <div style={{alignSelf:"center"}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end",marginLeft:"-8px"}}>
                    {props.data.ce_doi>=0?
                    <>
                        {props.data.ce_doi_alert?<span style={{fontSize:"14px",marginRight:"-6px", color:TABLE_COLORS.GREEN}}>**&nbsp;</span>:<></>}
                        <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>
                    </>:
                    <>
                        {props.data.ce_doi_alert?<span style={{fontSize:"14px",marginRight:"-6px", color:TABLE_COLORS.RED}}>**&nbsp;</span>:<></>}
                        <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>
                    </>
                    }
                    <span style={{color: props.data.ce_doi>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{props.data.ce_doi.toLocaleString(props.data.locale_string)}</span>
                </div>
                <div style={{height:"20px",display:"flex",marginTop:"-5px",justifyContent:"flex-end"}}>
                    <span >{props.data.ce_oi_alert?"* "+props.data.ce_oi.toLocaleString(props.data.locale_string):props.data.ce_oi.toLocaleString(props.data.locale_string)}</span>
                </div>
            </div>
}

export const CallIvRenderer=(props)=> {
    if(props.data.ce_iv==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else {
        return <div style={{alignSelf:"center",alignItems:"center !important"}}>
            <span>IV: {props.data.ce_iv}</span>
            <br/>
            <span>D: {props.data.ce_delta}, G: {props.data.ce_gamma}</span>
        </div>
    }
}
    
export const CallCHGLTPRenderer=(props)=> {

    const handleMP=()=>{
        props.handleMPChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL,props.data);
    }
    const handleLine=()=>{
        props.handleLineChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL,props.data); 
    }
    if(props.data.ce_change==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>
   else return <div style={{alignSelf:"center"}}>
    <div style={{display:'flex',width:"110px",marginRight:"5px",alignItems:'center',justifyContent:"flex-start"}}>
     <div style={{display:'flex',width:"70px",justifyContent:"flex-end"}}>
     {props.data.ce_change>=0?
         <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>:
         <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>}
         <div>
         <span style={{color: props.data.ce_change>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{props.data.ce_change}</span>
         </div>
         </div>   
         {props.data.ce_instr!=undefined?
             <Tooltip title={props.data.key + " Call MP chart"}>
                 <AbcIcon onClick={handleMP}sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}></AbcIcon>
             </Tooltip>:
             <></>
         }

     </div>
     <div style={{height:"20px",marginRight:"5px",display:"flex",justifyContent:"flex-start"}}>
     <div style={{display:'flex',width:"70px",justifyContent:"flex-end"}}>
         <span style={{fontWeight:700}}>{props.data.ce_ltp}</span>
         </div>
         {props.data.ce_instr!=undefined?
             <Tooltip title={props.data.key + " Call Line chart"}>
                 <ShowChartOutlined onClick={handleLine} sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}/>
             </Tooltip>:
             <></>
         }
     </div>
 </div>;
   
}

export const CallVWAPVPOCRenderer=(props)=> {
     return <div style={{alignSelf:"center"}}>
             <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
            {props.data.ce_3d_vwap!=undefined && props.data.ce_3d_vwap!=null? 
                 <>
                (&nbsp;<span style={{color: TABLE_COLORS.VPOC,fontWeight:500,fontStyle:'italic'}} >{props.data.ce_3d_vpoc}</span>&nbsp;/&nbsp;
                <span style={{color: TABLE_COLORS.VWAP,fontWeight:500,fontStyle:'italic'}} >{props.data.ce_3d_vwap}</span>&nbsp;)
                </>:
                <span></span>}
                </div>
                <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                    {props.data.ce_vwap>0 || props.data.ce_vpoc>0?
                    <>
                    {/* <span style={{color: TABLE_COLORS.VPOC,fontWeight:700}} >{props.data.ce_vpoc_alert==true?"** "+props.data.ce_vpoc:props.data.ce_vpoc}</span>&nbsp;/&nbsp;
                    <span style={{color: TABLE_COLORS.VWAP,fontWeight:700}} > {props.data.ce_vwap}</span> */}
                    <Box sx={{display:'flex',flexDirection:"row"}}>
                    <Typography sx={{fontSize:14,marginLeft:"2px",minWidth:20,textAlign:"left",color: TABLE_COLORS.VPOC,fontWeight:700}}>
                    {props.data.ce_vpoc_alert==true?"** ":" "}
                    </Typography>
                    <Typography sx={{fontSize:14,marginLeft:"2px",mr:"2px",minWidth:40,textAlign:"left",color: TABLE_COLORS.VPOC,fontWeight:700}}>
                    {props.data.ce_vpoc}</Typography>&nbsp;|&nbsp;
                    <Typography sx={{fontSize:14,marginLeft:"5px",minWidth:50,textAlign:"left",color: TABLE_COLORS.VWAP,fontWeight:700}}>
                    {props.data.ce_vwap}
                    </Typography>
                    </Box>
                    </>
                    :<></>
                    }
                </div>
            </div>;
}

export const PutMaxMinOIRenderer=(props)=> {
    if(props.data.pe_max_oi==undefined || props.data.pe_max_oi==0)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>
    else return <div style={{alignSelf:"center"}}><span >{props.data.pe_max_oi}</span>
               <div style={{height:"20px"}}>
                    <span >{props.data.pe_min_oi}</span></div>
            </div>;
}

export const PutIvRenderer=(props)=> {
  if(props.data.pe_iv==undefined)
      return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

  else {
      return <div style={{alignSelf:"center",alignItems:"center !important"}}>
          <span>IV: {props.data.pe_iv}</span>
          <br/>
          <span>D: {props.data.pe_delta}, G: {props.data.pe_gamma}</span>
      </div>
  }
}
                
export const PutDOIOIRenderer=(props)=> {
    if(props.data.pe_doi==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return <div style={{alignSelf:"center"}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end",marginLeft:"-8px"}}>{props.data.pe_doi>=0?
                    <>
                    {props.data.pe_doi_alert?<span style={{fontSize:"14px",marginRight:"-6px", color:TABLE_COLORS.GREEN}}>**&nbsp;</span>:<></>}
                    <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>
                </>:
                <>
                    {props.data.pe_doi_alert?<span style={{fontSize:"14px",marginRight:"-6px", color:TABLE_COLORS.RED}}>**&nbsp;</span>:<></>}
                    <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>
                </>}
                    <span style={{color: props.data.pe_doi>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{props.data.pe_doi.toLocaleString(props.data.locale_string)}</span>
                </div>
                <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                    <span >{props.data.pe_oi_alert?"* "+props.data.pe_oi.toLocaleString(props.data.locale_string):props.data.pe_oi.toLocaleString(props.data.locale_string)}</span>
                </div>
        </div>;
}
                    
export const PutCHGLTPRenderer=(props)=> {
    const handleMP=()=>{
        props.handleMPChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_PUT,props.data);
    }
    const handleLine=()=>{
        props.handleLineChart(OPTIONS_TABLE_CHARTS.CHART_TYPE_PUT,props.data); 
    }
    if(props.data.pe_change==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>
   
    else return <div style={{alignSelf:"center"}}>
                   <div style={{display:'flex',width:"110px",marginRight:"5px",alignItems:'center',justifyContent:"flex-start"}}>
                    <div style={{display:'flex',width:"70px",justifyContent:"flex-end"}}>
                    {props.data.pe_change>=0?
                        <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>:
                        <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>}
                        <div>
                        <span style={{color: props.data.pe_change>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{props.data.pe_change}</span>
                        </div>
                        </div>   
                        {props.data.pe_instr!=undefined?
                            <Tooltip title={props.data.key + " Put MP chart"}>
                                <AbcIcon onClick={handleMP}sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}></AbcIcon>
                            </Tooltip>:
                            <></>
                        }
     
                    </div>
                    <div style={{height:"20px",marginRight:"5px",display:"flex",justifyContent:"flex-start"}}>
                    <div style={{display:'flex',width:"70px",justifyContent:"flex-end"}}>
                        <span style={{fontWeight:700}}>{props.data.pe_ltp}</span>
                        </div>
                        {props.data.pe_instr!=undefined?
                            <Tooltip title={props.data.key + " Put Line chart"}>
                                <ShowChartOutlined onClick={handleLine} sx={{color:TABLE_COLORS.CHART_ICON_COLOR,border:"0.5px solid",ml:1,height:18,cursor:'pointer'}}/>
                            </Tooltip>:
                            <></>
                        }
                    </div>
                </div>;
}
                
export const PutVWAPVPOCRenderer=(props)=> {   
    return <div style={{alignSelf:"center"}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                    {props.data.pe_3d_vwap!=undefined && props.data.pe_3d_vwap!=null? 
                    <>
                        (&nbsp;<span style={{color: TABLE_COLORS.VPOC,fontWeight:500,fontStyle:'italic'}} >{props.data.pe_3d_vpoc}</span>&nbsp;/&nbsp;
                        <span style={{color: TABLE_COLORS.VWAP,fontWeight:500,fontStyle:'italic'}} >{props.data.pe_3d_vwap}</span>&nbsp;)
                    </>:
                    <span></span>}
                </div>
                <div style={{height:"20px",display:"flex",justifyContent:"flex-end"}}>
                    {props.data.ce_vwap>0 || props.data.ce_vpoc>0?
                    <>
                    {/* <span style={{color: TABLE_COLORS.VPOC,fontWeight:700}} >{props.data.pe_vpoc_alert==true?"** "+props.data.pe_vpoc:props.data.pe_vpoc}</span>
                    &nbsp;/&nbsp;
                    <span style={{color: TABLE_COLORS.VWAP,fontWeight:700}} > {props.data.pe_vwap}</span> */}
                    <Box sx={{display:'flex',flexDirection:"row"}}>
                    <Typography sx={{fontSize:14,marginLeft:"2px",minWidth:20,textAlign:"left",color: TABLE_COLORS.VPOC,fontWeight:700}}>
                    {props.data.pe_vpoc_alert==true?"** ":" "}
                    </Typography>
                    <Typography sx={{fontSize:14,marginLeft:"2px",mr:"2px",minWidth:40,textAlign:"left",color: TABLE_COLORS.VPOC,fontWeight:700}}>
                    {props.data.pe_vpoc}</Typography>&nbsp;|&nbsp;
                    <Typography sx={{fontSize:14,marginLeft:"5px",minWidth:50,textAlign:"left",color: TABLE_COLORS.VWAP,fontWeight:700}}>
                    {props.data.pe_vwap}
                    </Typography>
                    </Box>
                    </>
                    :<></>
                    }
                </div>
            </div>;
}

export const OISpikeRenderer=(props)=> {
    if(props.data.ce_oi_spike==undefined)
        return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return  <div style={{alignSelf:"center",alignItems:"center !important",width:"100%"}}>
                    <span style={{color: TABLE_COLORS.VWAP}} >CE: {props.data.ce_oi_spike}</span>
                    {/* <span >{props.data.ce_oi_spike}</span> */}
                    <div style={{height:"20px"}}>
                        <span style={{color: TABLE_COLORS.VPOC}} >PE: {props.data.pe_oi_spike}</span>
                        {/* <span >{props.data.pe_oi_spike}</span> */}
                    </div>
                </div>
}                                

export const PCRStraddleRenderer=(props)=> {
        if(props.data.pcr==undefined)
            return <div style={{alignSelf:"center",justifyContent:'center'}}>-</div>

        else return  <div style={{alignSelf:"center",alignItems:"center !important",width:"100%"}}><span >{props.data.pcr}</span>
                    <div style={{height:"20px"}}>
                        <span >{props.data.straddle} (<span style={{color: props.data.straddle_change>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}}>{props.data.straddle_change}</span>)</span>
                    </div>
                </div>
}

export const StraddleVARenderer=(props)=> {
    return <div style={{alignSelf:"left", alignContent: "left",justifyContent:'flex-start'}}>
        {props.data.straddle_signal != undefined && (props.data.straddle_vwap>0 || props.data.straddle_vpoc>0)?
            <>
            <Box sx={{display:'flex',flexDirection:"row"}}>
            <Typography sx={{fontSize:14,marginLeft:"2px",minWidth:20,textAlign:"left",color: props.data.straddle_signal=="[B]"?TABLE_COLORS.GREEN:props.data.straddle_signal=="[S]"?TABLE_COLORS.RED:"#fff",fontWeight:700}}>
                {props.data.straddle_signal}
            </Typography>
            <Typography sx={{fontSize:14,marginLeft:"5px",minWidth:50,textAlign:"left",color: TABLE_COLORS.VPOC,fontWeight:700}}>
            {props.data.straddle_vpoc}</Typography>&nbsp;|&nbsp;
            <Typography sx={{fontSize:14,marginLeft:"2px",minWidth:50,textAlign:"left",color: TABLE_COLORS.VWAP,fontWeight:700}}>
            {props.data.straddle_vwap}
            </Typography>
            </Box>
            
        </>
        :<></>
        }
    </div>;
}

export const StraddleDOIOIRenderer=(props)=> {
    // if(props.data.straddle_signal==undefined)
    //     return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>
    // else 
    return <div style={{alignSelf:"center"}}>
        <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end",marginLeft:"-8px"}}>
            {props.data.straddle_doi>=0?
            <>
                {/* {props.data.straddle_doi_alert?<span style={{fontSize:"14px",marginRight:"-6px", color:TABLE_COLORS.GREEN}}>**&nbsp;</span>:<></>} */}
                <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20}}/>
            </>:
            <>
                {/* {props.data.straddle_doi_alert?<span style={{fontSize:"14px",marginRight:"-6px", color:TABLE_COLORS.RED}}>**&nbsp;</span>:<></>} */}
                <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20}}/>
            </>
            }
            <span style={{color: props.data.straddle_doi>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{props.data.straddle_doi.toLocaleString(props.data.locale_string)}</span>
        </div>
        <div style={{height:"20px",display:"flex",marginTop:"-5px",justifyContent:"flex-end"}}>
            <span>{props.data.straddle_oi.toLocaleString(props.data.locale_string)}</span>
        </div>
    </div>
}

const SyntFutRenderer=(props)=>{
    if(props.data.synth_fut==undefined || props.data.spot==undefined || props.data.fut==undefined)
            return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return  <div style={{alignSelf:"center"}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                    <span>{props.data.synth_fut}</span>&nbsp;(
                    <span>{(props.data.synth_fut-props.data.spot).toFixed(2)}</span>
                    )&nbsp;[
                    <span>F:&nbsp;{(props.data.synth_fut-props.data.fut).toFixed(2)}</span>]
                </div>
            </div>
 }
 export const WCashLkRenderer=(props)=> {
    if(props.data.ce_wcash==undefined)
            return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return  <div style={{alignSelf:"center",alignItems:"center !important",width:"100%"}}>
                <span >CE:&nbsp;{(props.data.ce_wcash/100000).toFixed(2)}</span>
                <div style={{height:"20px"}}>
                    <span >PE:&nbsp;{(props.data.pe_wcash/100000).toFixed(2)}</span>
                </div>
            </div>
}

export const GexCrRenderer=(props)=> {
    if(props.data.ce_gex==undefined || props.data.pe_gex==undefined)
            return <div style={{alignSelf:"center",justifyContend:'center'}}>-</div>

    else return  <div style={{alignSelf:"center",alignItems:"center !important",width:"100%"}}>
                <span >CE:&nbsp;{(props.data.ce_gex).toFixed(0)}</span>
                <div style={{height:"20px"}}>
                    <span >PE:&nbsp;{(props.data.pe_gex).toFixed(0)}</span>
                </div>
            </div>
}

 export const getColumneDef=(funCallCheckBoxHandler,funPutCheckBoxHandler,auth,openLineChart,openMPChart,hidden_cols)=>{
    let proUser=auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA)?true:false;
    let pro2User=auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2)?true:false;
    let def;
    console.log("hidden cols optchain=",hidden_cols)
    // if(pro2User){
    //     def=[
    //         {
    //             headerName:"Calls",
    //             groupId:"call_group",
    //             children:[
    //                 { field: 'ce_max_min_oi',headerName:"[Price] Max OI (Time) \n[Price] Min OI (Time)", minWidth: 190,maxWidth:230,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_max_min_oi),
    //                  cellRenderer: CallMaxMinOIRenderer,
    //                  cellStyle: function(params) {
    //                     // return {backgroundColor: params.data.ce_checked?TABLE_COLORS.SELECTED_ROW_COLOR:TABLE_COLORS.DEFAULT_ROW_COLOR};
    //                     return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                            
    //                 }
    //                 },
    //                 { field: 'ce_doi_oi',headerName:"dOI \n OI", minWidth: 60,maxWidth:90,cellRenderer:CallDOIOIRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_doi_oi),
    //                 cellStyle: function(params) {
    //                     return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
    //                  }
    //                 },
    //                 { field: 'ce_iv',headerName:"IV" ,minWidth:120,maxWidth:120,cellRenderer:CallIvRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_iv),
    //                     cellStyle: function(params) {
    //                         return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
    //                 },
    //                 { field: 'ce_vol',headerName:"Vol",minWidth: 60,maxWidth:90,cellRenderer:CallVolumeRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_vol),
    //                 cellStyle: function(params) {
    //                     return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
    //                     }
    //                 },
    //                 { field: 'ce_vpoc_vwap',headerName:"  VPOC / VWAP ",minWidth: 160,maxWidth:190,cellRenderer:CallVWAPVPOCRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_vpoc_vwap),
    //                     cellStyle: function(params) {
    //                         return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
    //                     }
    //                 },
    //                 { field: 'ce_chg_ltp',headerName:"Chg \nLTP",minWidth: 90,maxWidth:120,cellRenderer:CallCHGLTPRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_chg_ltp),
    //                     cellStyle: function(params) {
    //                         return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
    //                     },
    //                     cellRendererParams: {
    //                         handleLineChart: openLineChart,
    //                         handleMPChart:openMPChart
    //                     }, 
    //                  },
    //                 { field: 'ce_checked',headerName:"",minWidth: 50,maxWidth:70,cellRenderer: CallCheckBoxRenderer, 
    //                     cellRendererParams: {
    //                     handleChange: funCallCheckBoxHandler
    //                     },
    //                     cellStyle: function(params) {
    //                         return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
    //                     }
    //                },
    //             ]
    //         },
    //         {
    //             headerName:"",
    //             children:[
    //                 { field: 'key',headerName:"Strike",minWidth:120,maxWidth:140,cellRenderer:StrikeRenderer,
    //                 cellStyle: function(params) {
    //                     return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR: TABLE_COLORS.STRIKE_BACKGROUND_COLOR};
    //                 },
    //                 cellRendererParams: {
    //                     handleLineChart: openLineChart,
    //                     handleMPChart:openMPChart
    //                 },  
    //             },
                   
                   
    //             ]
    //         },
          
            
    //         {
    //             headerName:"Puts",
    //             groupId:"puts_group",
    //             children:[
    //                 { field: 'pe_checked',headerName:"",minWidth: 50,maxWidth:70,cellRenderer: PutCheckBoxRenderer, 
    //                   cellRendererParams: {
    //                   handleChange: funPutCheckBoxHandler
    //                   },
    //                   cellStyle: function(params) {
    //                       return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
    //                   }
    //                 },
    //                 { field: 'pe_chg_ltp',headerName:"Chg \nLTP",minWidth: 90,maxWidth:120,cellRenderer:PutCHGLTPRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_chg_ltp),
    //                     cellStyle: function(params) {
    //                         return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
    //                     },
    //                     cellRendererParams: {
    //                         handleLineChart: openLineChart,
    //                         handleMPChart:openMPChart
    //                     },
    //                 },
    //                 { field: 'pe_vpoc_ltp',headerName:"  VPOC / VWAP ",minWidth: 160,maxWidth:190,cellRenderer:PutVWAPVPOCRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_vpoc_vwap),
    //                     cellStyle: function(params) {
    //                         return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
    //                     }  
    //                 },
    //                 { field: 'pe_vol',headerName:"Vol",minWidth: 60,maxWidth:90,cellRenderer:PutVolumeRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_vol),
    //                     cellStyle: function(params) {
    //                         return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
    //                     }
    //                  },
    //                 { field: 'pe_iv',headerName:"IV" ,minWidth:120,maxWidth:120,cellRenderer:PutIvRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_iv),
    //                     cellStyle: function(params) {
    //                         return {backgroundColor: params.data.pe_checked||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
    //                     }
    //                 },
    //                 { field: 'pe_doi_oi',headerName:"dOI \n OI", minWidth: 60,maxWidth:90,cellRenderer:PutDOIOIRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_doi_oi),
    //                     cellStyle: function(params) {
    //                         return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
    //                     }
    //                 },
    //                 { field: 'pe_max_min_oi',headerName:"[Price] Max OI (Time) \n[Price] Min OI (Time)", minWidth: 190,maxWidth:230,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_max_min_oi),
    //                     cellRenderer: PutMaxMinOIRenderer,
    //                     cellStyle: function(params) {
    //                         return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
    //                     }
    //                 },
                   
                    
                    
    //             ]
          
    //         },
    //         {
    //             headerName:"Other Info",
    //             children:[
    //                 { field: 'oi_spike',headerName:"CE OI Spike\nPE OI Spike",minWidth:160,maxWidth:180,cellRenderer:OISpikeRenderer ,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.oi_spike),
    //                     cellStyle: function(params) {
    //                         return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
    //                     }
    //                 },
    //                 { field: 'pcr_straddle',headerName:"PCR\nStraddle",minWidth:70,maxWidth:100,cellRenderer:PCRStraddleRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.straddle_pcr),
    //                     cellStyle: function(params) {
    //                         return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
    //                  }
    //                 },
    //                 { field: 'oi_pain',headerName:"Strike\nPain",minWidth:90,maxWidth:120,cellRenderer:DefaultRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.strike_pain),
    //                     cellStyle: function(params) {
    //                         return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
    //                     }
    //                 },
    //                 { field: 'synth_fut',headerName:"Synthetic Fut\n(Strike + CE - PE)",minWidth:150,maxWidth:170,cellRenderer:SyntFutRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.synthetic_fut),
    //                     cellStyle: function(params) {
    //                         return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
    //                     }
    //                 },
    //                 // { field: 'gex_cr',headerName:"CE_GEX (Cr)\nPE_GEX (Cr)",minWidth:130,maxWidth:150,cellRenderer:GexCrRenderer,
    //                 //     cellStyle: function(params) {
    //                 //         return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
    //                 //     }
    //                 // },
    //             ]
    //         },
    //         ]
    // }
    // else if(proUser){
    if(proUser){
        def=[
            {
                headerName:"Calls",
                groupId:"call_group",
                children:[
                    { field: 'ce_max_min_oi',headerName:"[Price] Max OI (Time) \n[Price] Min OI (Time)", minWidth: 190,maxWidth:230,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_max_min_oi),
                     cellRenderer: CallMaxMinOIRenderer,
                     cellStyle: function(params) {
                        // return {backgroundColor: params.data.ce_checked?TABLE_COLORS.SELECTED_ROW_COLOR:TABLE_COLORS.DEFAULT_ROW_COLOR};
                        return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                            
                    }
                    },
                    { field: 'ce_doi_oi',headerName:"dOI \n OI", minWidth: 60,maxWidth:90,cellRenderer:CallDOIOIRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_doi_oi),
                    cellStyle: function(params) {
                        return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                     }
                    },
                    { field: 'ce_iv',headerName:"IV" ,minWidth:120,maxWidth:120,cellRenderer:CallIvRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_iv),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'ce_vol',headerName:"Vol",minWidth: 60,maxWidth:90,cellRenderer:CallVolumeRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_vol),
                    cellStyle: function(params) {
                        return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                    { field: 'ce_vpoc_vwap',headerName:"  VPOC | VWAP ",minWidth: 130,maxWidth:160,cellRenderer:CallVWAPVPOCRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_vpoc_vwap),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                    { field: 'ce_chg_ltp',headerName:"Chg \nLTP",minWidth: 90,maxWidth:120,cellRenderer:CallCHGLTPRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_chg_ltp),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        },
                        cellRendererParams: {
                            handleLineChart: openLineChart,
                            handleMPChart:openMPChart
                        }, 
                     },
                    { field: 'ce_checked',headerName:"",minWidth: 50,maxWidth:70,cellRenderer: CallCheckBoxRenderer, 
                        cellRendererParams: {
                        handleChange: funCallCheckBoxHandler
                        },
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                   },
                ]
            },
            {
                headerName:"",
                children:[
                    { field: 'key',headerName:"Strike",minWidth:120,maxWidth:140,cellRenderer:StrikeRenderer,
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR: TABLE_COLORS.STRIKE_BACKGROUND_COLOR};
                    },
                    cellRendererParams: {
                        handleLineChart: openLineChart,
                        handleMPChart:openMPChart
                    },  
                },
                   
                   
                ]
            },
          
            
            {
                headerName:"Puts",
                groupId:"puts_group",
                children:[
                    { field: 'pe_checked',headerName:"",minWidth: 50,maxWidth:70,cellRenderer: PutCheckBoxRenderer, 
                      cellRendererParams: {
                      handleChange: funPutCheckBoxHandler
                      },
                      cellStyle: function(params) {
                          return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                      }
                    },
                    { field: 'pe_chg_ltp',headerName:"Chg \nLTP",minWidth: 90,maxWidth:120,cellRenderer:PutCHGLTPRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_chg_ltp),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        },
                        cellRendererParams: {
                            handleLineChart: openLineChart,
                            handleMPChart:openMPChart
                        },
                    },
                    { field: 'pe_vpoc_ltp',headerName:"  VPOC | VWAP ",minWidth: 130,maxWidth:160,cellRenderer:PutVWAPVPOCRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_vpoc_vwap),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }  
                    },
                    { field: 'pe_vol',headerName:"Vol",minWidth: 60,maxWidth:90,cellRenderer:PutVolumeRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_vol),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                     },
                    { field: 'pe_iv',headerName:"IV" ,minWidth:120,maxWidth:120,cellRenderer:PutIvRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_iv),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.pe_checked||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                    { field: 'pe_doi_oi',headerName:"dOI \n OI", minWidth: 60,maxWidth:90,cellRenderer:PutDOIOIRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_doi_oi),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                    { field: 'pe_max_min_oi',headerName:"[Price] Max OI (Time) \n[Price] Min OI (Time)", minWidth: 190,maxWidth:230,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_max_min_oi),
                        cellRenderer: PutMaxMinOIRenderer,
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                   
                    
                    
                ]
          
            },
            {
                headerName:"Other Info",
                children:[
                    { field: 'oi_spike',headerName:"CE OI Spike\nPE OI Spike",minWidth:160,maxWidth:180,cellRenderer:OISpikeRenderer ,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.oi_spike),
                        cellStyle: function(params) {
                            return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                        }
                    },
                    { field: 'pcr_straddle',headerName:"PCR\nStraddle",minWidth:90,maxWidth:120,cellRenderer:PCRStraddleRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.straddle_pcr),
                        cellStyle: function(params) {
                            return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                     }
                    },
                    { field: 'straddle_va',headerName:"Straddle\nVPOC | VWAP",minWidth:130,maxWidth:160,cellRenderer:StraddleVARenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.straddle_pcr),
                        cellStyle: function(params) {
                            return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                     }
                    },
                    { field: 'straddle_oi',headerName:"Straddle dOI\nStraddle OI",minWidth:90,maxWidth:120,cellRenderer:StraddleDOIOIRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.straddle_pcr),
                        cellStyle: function(params) {
                            return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                     }
                    },
                    { field: 'oi_pain',headerName:"Strike\nPain",minWidth:90,maxWidth:120,cellRenderer:DefaultRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.strike_pain),
                        cellStyle: function(params) {
                            return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                        }
                    },
                    { field: 'synth_fut',headerName:"Synthetic Fut\n(Strike + CE - PE)",minWidth:150,maxWidth:170,cellRenderer:SyntFutRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.synthetic_fut),
                        cellStyle: function(params) {
                            return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                        }
                    },
                    // { field: 'wcash_lk',headerName:"CE WCash (Lk)\n(PE WCash (Lk)",minWidth:70,maxWidth:100,cellRenderer:WCashLkRenderer,
                    //     cellStyle: function(params) {
                    //         return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                    //     }
                    // },
                ]
            },
          
            ]
    }else{
        def=[
            {
                headerName:"Calls",
                groupId:"call_group",
                children:[
                    { field: 'ce_max_min_oi',headerName:"[Price] Max OI (Time) \n[Price] Min OI (Time)", minWidth: 190,maxWidth:230,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_max_min_oi),
                     cellRenderer: CallMaxMinOIRenderer,
                     cellStyle: function(params) {
                        // return {backgroundColor: params.data.ce_checked?TABLE_COLORS.SELECTED_ROW_COLOR:TABLE_COLORS.DEFAULT_ROW_COLOR};
                        return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                            
                    }
                    },
                    { field: 'ce_doi_oi',headerName:"dOI \n OI", minWidth: 60,maxWidth:90,cellRenderer:CallDOIOIRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_doi_oi),
                    cellStyle: function(params) {
                        return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                     }
                    },
                    { field: 'ce_iv',headerName:"IV" ,minWidth:120,maxWidth:120,cellRenderer:CallIvRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_iv),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};    }
                    },
                    { field: 'ce_vol',headerName:"Vol",minWidth: 60,maxWidth:90,cellRenderer:CallVolumeRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_vol),
                    cellStyle: function(params) {
                        return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                    { field: 'ce_chg_ltp',headerName:"Chg \nLTP",minWidth: 90,maxWidth:120,cellRenderer:CallCHGLTPRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.calls_chg_ltp),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        },
                        cellRendererParams: {
                            handleLineChart: openLineChart,
                            handleMPChart:openMPChart
                        }, 
                     },
                    { field: 'ce_checked',headerName:"",minWidth: 50,maxWidth:70,cellRenderer: CallCheckBoxRenderer, 
                        cellRendererParams: {
                        handleChange: funCallCheckBoxHandler
                        },
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.ce_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                   },
                ]
            },
            {
                headerName:"",
                children:[
                    { field: 'key',headerName:"Strike",minWidth:120,maxWidth:140,cellRenderer:StrikeRenderer,
                    cellStyle: function(params) {
                        return {backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR: TABLE_COLORS.STRIKE_BACKGROUND_COLOR};
                    },
                    cellRendererParams: {
                        handleLineChart: openLineChart,
                        handleMPChart:openMPChart
                    },  
                },
                   
                   
                ]
            },
          
            
            {
                headerName:"Puts",
                groupId:"puts_group",
                children:[
                    { field: 'pe_checked',headerName:"",minWidth: 50,maxWidth:70,cellRenderer: PutCheckBoxRenderer, 
                      cellRendererParams: {
                      handleChange: funPutCheckBoxHandler
                      },
                      cellStyle: function(params) {
                          return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                      }
                    },
                    { field: 'pe_chg_ltp',headerName:"Chg \nLTP",minWidth: 90,maxWidth:120,cellRenderer:PutCHGLTPRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_chg_ltp),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        },
                        cellRendererParams: {
                            handleLineChart: openLineChart,
                            handleMPChart:openMPChart
                        },
                    },
                    { field: 'pe_vol',headerName:"Vol",minWidth: 60,maxWidth:90,cellRenderer:PutVolumeRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_vol),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                     },
                    { field: 'pe_iv',headerName:"IV" ,minWidth: 120,maxWidth:120,cellRenderer:PutIvRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_iv),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.pe_checked||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                    { field: 'pe_doi_oi',headerName:"dOI \n OI", minWidth: 60,maxWidth:90,cellRenderer:PutDOIOIRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_doi_oi),
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                    { field: 'pe_max_min_oi',headerName:"[Price] Max OI (Time) \n[Price] Min OI (Time)", minWidth: 190,maxWidth:230,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.puts_max_min_oi),
                        cellRenderer: PutMaxMinOIRenderer,
                        cellStyle: function(params) {
                            return {backgroundColor: params.data.pe_checked ||params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null,fontSize:"12px"};
                        }
                    },
                   
                    
                    
                ]
          
            },
            {
                headerName:"Other Info",
                children:[
                    { field: 'oi_spike',headerName:"CE OI Spike\nPE OI Spike",minWidth:160,maxWidth:180,cellRenderer:OISpikeRenderer ,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.oi_spike),
                        cellStyle: function(params) {
                            return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                        }
                    },
                    { field: 'pcr_straddle',headerName:"PCR\nStraddle",minWidth:90,maxWidth:120,cellRenderer:PCRStraddleRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.straddle_pcr),
                        cellStyle: function(params) {
                            return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                     }
                    },
                    { field: 'oi_pain',headerName:"Strike\nPain",minWidth:90,maxWidth:120,cellRenderer:DefaultRenderer,hide:hidden_cols.includes(OPTCHAIN_COLUMNS_LIST.strike_pain),
                        cellStyle: function(params) {
                            return {fontSize:"12px",backgroundColor:params.data.highlighted?TABLE_COLORS.SELECTED_ROW_COLOR:null};
                        }
                    },
                   
                ]
            },
          
            ]
    }

            return def;
    }
    
/**
 * Metadata related renderer and column definitions
*/    

export const MetaDataDefaultRenderer=(props)=>{
    if(props.rowIndex==0)
        return <div style={{alignSelf:"center",alignItems:"center !important"}}>
            {props.value!=undefined && props.value!==""?
                <span>{props.value}</span>:
                <>-</>
            }   
            </div>
    else
    return <></>
}


export const MetaDataOIPCRRenderer=(props)=>{
    if(props.rowIndex==0)
        return <div style={{alignSelf:"center",display:'flex',flexDirection:"row", height:"100%", alignItems:"center"}}>
            <div>
                <span >{props.data.pcr}&nbsp;</span>
            </div>
            <div>
                (PE: <span>{props.data.pe_oi.toLocaleString(props.data.locale_string)}</span>
            </div>
            {props.data.pe_doi>=0?
                <div >
                <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20,marginTop:"5px"}}/>
                </div>:
                <div >       
                    <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20,marginTop:"5px"}}/>
               </div>     
            }
            <div>
                <span style={{color:props.data.pe_doi>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}}>{props.data.pe_doi.toLocaleString(props.data.locale_string)}&nbsp;</span>
                /&nbsp;CE: <span>{props.data.ce_oi.toLocaleString(props.data.locale_string)}</span>
          
            </div>
            {props.data.ce_doi>=0?
                <div >
                <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20,marginTop:"5px"}}/>
                </div>:
                <div >       
                    <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20,marginTop:"5px"}}/>
               </div>     
            }
             <div>
                <span style={{color:props.data.ce_doi>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}}>{props.data.ce_doi.toLocaleString(props.data.locale_string)}&nbsp;</span>
            </div>
           
          
            <div>
            
            )
            </div>
            </div>
    
    else
    return <div style={{alignSelf:"center"}}>
    <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
    <div>
    <span>PE Max: {props.data.max_pe_oi}</span>&nbsp;&nbsp;
    </div>
    <div>
                <span>PE Min: {props.data.max_pe_oi}</span>
    </div>

         </div>
    <div style={{height:"20px",marginTop:"-5px",display:"flex",justifyContent:"flex-end"}}>
    <span>CE Max: {props.data.max_ce_oi}</span>&nbsp;&nbsp;
                <span>CE Min: {props.data.max_ce_oi}</span>
    </div>
</div>
}

export const MetaDataOIPCRRendererNew=(props)=>{
    if(props.rowIndex==0)
        return<div style={{alignSelf:"center",display:'flex',flexDirection:"column",width:"100%", height:"100%", alignItems:"center"}}>
             
            <div style={{alignSelf:"center",display:'flex',flexDirection:"row",width:"100%", height:"100%", alignItems:"center",justifyContent:"center"}}>
            <div>
                <span >{props.data.pcr}&nbsp;</span>
            </div>
            <div>
                (PE: <span>{props.data.pe_oi.toLocaleString(props.data.locale_string)}</span>
            </div>
            {props.data.pe_doi>=0?
                <div >
                <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20,marginTop:"5px"}}/>
                </div>:
                <div >       
                    <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20,marginTop:"5px"}}/>
               </div>     
            }
            <div>
                <span style={{color:props.data.pe_doi>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}}>{props.data.pe_doi.toLocaleString(props.data.locale_string)}&nbsp;</span>
                /&nbsp;CE: <span>{props.data.ce_oi.toLocaleString(props.data.locale_string)}</span>
          
            </div>
            {props.data.ce_doi>=0?
                <div >
                <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:24,width:20,marginTop:"5px"}}/>
                </div>:
                <div >       
                    <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:24,width:20,marginTop:"5px"}}/>
               </div>     
            }
             <div>
                <span style={{color:props.data.ce_doi>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}}>{props.data.ce_doi.toLocaleString(props.data.locale_string)}&nbsp;</span>
            </div>
           
          
            <div>
            
            )
            </div>
            </div>
            {props.data.max_pe_oi!=undefined && props.data.min_pe_oi!=undefined && props.data.max_ce_oi!=undefined&& props.data.min_ce_oi!=undefined?
            <div style={{alignSelf:"center",borderTop:"1px solid #ffffff",paddingTop:"2px",width:"100%",height:"100%"}}>
                <div style={{display:'flex',borderBottom:"1px solid #ffffff",alignItems:'center',justifyContent:"center",width:"100%"}}>
                    <div style={{alignSelf:"center",width:"50%",borderRight:"1px solid #ffffff",height:"50%",textAlign:'center'}}>
                        <span>PE Max: {props.data.max_pe_oi}</span>&nbsp;&nbsp;
                    </div>
                    <div style={{alignSelf:"center",textAlign:"center",width:"50%",height:"50%"    }}>
                        <span>PE Min: {props.data.min_pe_oi}</span>
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center',justifyContent:"center",width:"100%"}}>
                    <div style={{alignSelf:"center",paddingRight:"2px",borderRight:"1px solid #ffffff",width:"50%",height:"50%",textAlign:'center'}}>
                        <span>CE Max: {props.data.max_ce_oi}</span>&nbsp;&nbsp;
                    </div>
                    <div style={{alignSelf:"center",textAlign:"center",width:"50%",height:"50%"}}>
                        <span>CE Min: {props.data.min_ce_oi}</span>
                    </div>
                </div>
            </div>:
            <></>
            }
            </div>
    
    
}
const MetaDataVolPCRRenderer=(props)=>{
    if(props.rowIndex==0)
    return  <div style={{alignSelf:"center"}}>
                {props.data.vpcr!=undefined?    
                    <>
                    <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                        <span>{props.data.vpcr}</span>&nbsp;(
                        <span>{props.data.pe_vol.toLocaleString(props.data.locale_string)}</span>&nbsp;/&nbsp;
                        <span>{props.data.ce_vol.toLocaleString(props.data.locale_string)})</span>
                    </div><div style={{display:'flex',alignItems:'center',justifyContent:"center"}}>
                    <span>Total Vol: {(Math.round(props.data.pe_vol + props.data.ce_vol)).toLocaleString(props.data.locale_string)}</span>
                    </div></>:
                    <>-</>
                }
            </div>
     else return <></>       
}        
const MetaDataGPCRSlopeRenderer=(props)=>{
    if(props.rowIndex==0)
    return  <div style={{alignSelf:"center"}}>
                {props.data.gpcr_bias!=undefined?
                <Tooltip title="Similar to OI PCR - less than 1 means bearish bias and greater than 1 means bullish bias">
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                    <span>{props.data.gpcr_bias}</span>&nbsp;(
                    <span>{props.data.gpcr}</span>)
                </div></Tooltip>:
                <>-</>}
            </div>
            else return <></>    
}
const MetaDataGPCRSlopeRendererNonAdmin=(props)=>{
    if(props.rowIndex==0)
    return  <div style={{alignSelf:"center"}}>
                {props.data.gpcr_bias!=undefined?
                <Tooltip title="Similar to OI PCR - less than 1 means bearish bias and greater than 1 means bullish bias">
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                    <span>{props.data.gpcr_bias}</span>
                </div></Tooltip>:
                <>-</>}
            </div>
            else return <></>    
}
const MetaDataGWallRenderer=(props)=>{
    if(props.rowIndex==0)
    return  <div style={{alignSelf:"center"}}>
            {props.data.gres!=undefined?
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                    <span>{props.data.gres} - {props.data.gsup}</span>
                </div>:
                <>-</>
            }
            </div>
     else return <></>           
}
const MetaDataIRangeRenderer=(props)=>{
    if(props.rowIndex==0)
    return  <div style={{alignSelf:"center"}}>
                {props.data.gsup!=undefined?    
                <><div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                    <span>{props.data.gres_ext} - {props.data.gsup_ext}</span>
                </div>
                <Tooltip title="Spectrum (mViz High - Low [level])">
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end",color:"#DCFF00"}}>
                    <span>{props.data.upper_mviz}</span>&nbsp;-&nbsp;
                    <span>{props.data.lower_mviz}</span>
                    &nbsp;
                    [<span>{props.data.mviz_level}</span>]
                </div></Tooltip></>:<>-</>
                }
            </div>
    else return <></>
}

const MetaDataGMeanRenderer=(props)=>{
    if(props.rowIndex==0)
    return  <div style={{alignSelf:"center"}}>
                {props.data.gsup!=undefined?
                    <><Tooltip title="Similar to OI Pain, only that we use option IV values too to calculate this level, hence this is more sensitive to inventory changes.">
                    <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                        <span>{props.data.gmean}</span>&nbsp;(
                        <span>{props.data.gmean_wt}</span>
                        )&nbsp;[
                        <span>{props.data.gmean_ext}</span>]
                    </div></Tooltip>
                    <Tooltip title="Spectrum (GWall High - Low  [gZero])">
                    <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end",color:"#0FFAEE"}}>
                        <span>{props.data.gwall_high}</span>&nbsp;-&nbsp;
                        <span>{props.data.gwall_low}</span>
                        &nbsp;&nbsp;
                        [<span>{props.data.gzero}</span>]
                    </div></Tooltip></>:
                    <>-</>
                }
            </div>
    else return <></>        
}

const MetaDataGMeanRendererNonAdmin=(props)=>{
    if(props.rowIndex==0)
    return  <div style={{alignSelf:"center"}}>
                {props.data.gsup!=undefined?
                    <Tooltip title="Similar to OI Pain, only that we use option IV values too to calculate this level, hence this is more sensitive to inventory changes.">
                    <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                        <span>{props.data.gmean}</span>
                    </div></Tooltip>:
                    <>-</>
                }
            </div>
    else return <></>        
}

const MetaDataOPTPARITYRenderer=(props)=>{
    if(props.rowIndex==0)
    return  <div style={{alignSelf:"center"}}>
                 {props.data.fproj?
                <div style={{display:'flex',alignItems:'center',justifyContent:"flex-end"}}>
                    <span>{props.data.fproj}</span>&nbsp;(
                    <span>{(props.data.fproj-props.data.spot).toFixed(1)}</span>
                    )&nbsp;[F:&nbsp;
                    <span>{ (props.data.fproj-props.data.fut).toFixed(1)}</span>]
                </div>:
                <>-</>
                    }
            </div>
      else return <></>         
}


export const getColumneDefMetadata=(auth)=>{
    let proUser=auth.role.find(el=>el==USER_ROLE_FIELDS.PRO)?true:false;
    let adminUser=auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN)?true:false;
    let def;
    if(adminUser){
        def=[
                { field: 'last_ts',headerName:"Time", minWidth: 50,maxWidth:80,
                        cellRenderer: MetaDataDefaultRenderer,
                        cellStyle: function(params) {
                        return { fontSize:12}
                        }
                },
                { field: 'g_mean',headerName:"Gamma Zero", minWidth: 150,maxWidth:190,
                    cellRenderer: MetaDataGMeanRenderer,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
                { field: 'oi_pain',headerName:"OI Pain", minWidth: 80,maxWidth:100,
                        cellRenderer: MetaDataDefaultRenderer,
                        cellStyle: function(params) {
                        return { fontSize:12}
                        }
                },
                { field: 'g_res',headerName:"Gamma Wall Range", minWidth: 160,maxWidth:180,
                    cellRenderer: MetaDataGWallRenderer,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
                { field: 'g_sup',headerName:"Inventory Range", minWidth:140,maxWidth:160,
                    cellRenderer: MetaDataIRangeRenderer,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
                { field: 'vix',headerName:"Series VIX", minWidth: 100,maxWidth:110,
                        cellRenderer: MetaDataDefaultRenderer,
                        cellStyle: function(params) {
                        return { fontSize:12}
                        }
                },
                { field: 'nvixrange',headerName:"VIX Range",minWidth:80,maxWidth:100, 
                    cellRenderer: MetaDataDefaultRenderer,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
                { field: 'nvixrange_1d',headerName:"1D VIX Range-Spot", minWidth: 150,maxWidth:180,
                    cellRenderer: MetaDataDefaultRenderer,
                    cellStyle: function(params) {
                    return { fontSize:12}
                    }
                },
                { field: 'eod_range',headerName:"Series VIX Range-Spot", minWidth: 160,maxWidth:200,
                    cellRenderer: MetaDataDefaultRenderer,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
                { field: 'oi_pcr',headerName:"OI PCR",minWidth: 460,maxWidth:480,
                        cellRenderer: MetaDataOIPCRRendererNew,
                        cellStyle: function(params) {
                        return { fontSize:12,paddingLeft: 0,paddingRight:0}
                        }
                },
                { field: 'vol_pcr',headerName:"Vol PCR",minWidth: 240,maxWidth:280,
                        cellRenderer: MetaDataVolPCRRenderer,
                        cellStyle: function(params) {
                        return { fontSize:12}
                        }
                },
                { field: 'gpcr_slope',headerName:"Gamma Wall Skew", minWidth: 120,maxWidth:150,
                    cellRenderer: MetaDataGPCRSlopeRenderer,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
                { field: 'wpcr',headerName:"Wt PCR", minWidth: 70,maxWidth:90,
                        cellRenderer: MetaDataDefaultRenderer,
                        cellStyle: function(params) {
                        return { fontSize:12}
                        }
                },
                { field: 'opt_parity_s_f_diff',headerName:"Opt_Parity (S_diff) [F_diff]", minWidth: 230,maxWidth:260,
                    cellRenderer: MetaDataOPTPARITYRenderer,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
    
            ]
    }else if(proUser){
        def=[
                { field: 'last_ts',headerName:"Time", minWidth: 50,maxWidth:80,
                        cellRenderer: MetaDataDefaultRenderer,
                        cellStyle: function(params) {
                        return { fontSize:12}
                        }
                },
                { field: 'g_mean',headerName:"Gamma Zero", minWidth: 150,maxWidth:190,
                    cellRenderer: MetaDataGMeanRendererNonAdmin,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
                { field: 'oi_pain',headerName:"OI Pain", minWidth: 80,maxWidth:100,
                        cellRenderer: MetaDataDefaultRenderer,
                        cellStyle: function(params) {
                        return { fontSize:12}
                        }
                },
                { field: 'g_res',headerName:"Gamma Wall Range", minWidth: 160,maxWidth:180,
                    cellRenderer: MetaDataGWallRenderer,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
                { field: 'vix',headerName:"Series VIX", minWidth: 100,maxWidth:110,
                        cellRenderer: MetaDataDefaultRenderer,
                        cellStyle: function(params) {
                        return { fontSize:12}
                        }
                },
                { field: 'nvixrange',headerName:"VIX Range",minWidth:80,maxWidth:100, 
                    cellRenderer: MetaDataDefaultRenderer,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
                { field: 'nvixrange_1d',headerName:"1D VIX Range-Spot", minWidth: 150,maxWidth:180,
                    cellRenderer: MetaDataDefaultRenderer,
                    cellStyle: function(params) {
                    return { fontSize:12}
                    }
                },
                { field: 'eod_range',headerName:"Series VIX Range-Spot", minWidth: 160,maxWidth:200,
                    cellRenderer: MetaDataDefaultRenderer,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
                { field: 'oi_pcr',headerName:"OI PCR",minWidth: 460,maxWidth:480,
                        cellRenderer: MetaDataOIPCRRendererNew,
                        cellStyle: function(params) {
                        return { fontSize:12,paddingLeft: 0,paddingRight:0}
                        }
                },
                { field: 'vol_pcr',headerName:"Vol PCR",minWidth: 240,maxWidth:280,
                        cellRenderer: MetaDataVolPCRRenderer,
                        cellStyle: function(params) {
                        return { fontSize:12}
                        }
                },
                { field: 'gpcr_slope',headerName:"Gamma Wall Skew", minWidth: 120,maxWidth:150,
                    cellRenderer: MetaDataGPCRSlopeRendererNonAdmin,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
                { field: 'wpcr',headerName:"Wt PCR", minWidth: 70,maxWidth:90,
                        cellRenderer: MetaDataDefaultRenderer,
                        cellStyle: function(params) {
                        return { fontSize:12}
                        }
                },
                { field: 'opt_parity_s_f_diff',headerName:"Opt_Parity (S_diff) [F_diff]", minWidth: 230,maxWidth:260,
                    cellRenderer: MetaDataOPTPARITYRenderer,
                    cellStyle: function(params) {
                        return { fontSize:12}
                    }
                },
    
            ]
    }else{
        def=[
            { field: 'time',headerName:"Time", minWidth: 50,maxWidth:80,
                 cellRenderer: MetaDataDefaultRenderer,
                 cellStyle: function(params) {
                    return { fontSize:12}
                 }
            },
            { field: 'oi_pain',headerName:"Max Pain", minWidth: 80,maxWidth:100,
                 cellRenderer: MetaDataDefaultRenderer,
                 cellStyle: function(params) {
                    return { fontSize:12}
                 }
            },
            { field: 'oi_pcr',headerName:"OI PCR",minWidth: 460,maxWidth:480,
                 cellRenderer: MetaDataOIPCRRendererNew,
                 cellStyle: function(params) {
                    return { fontSize:12,paddingLeft: 0,paddingRight:0}
                 }
            },
            { field: 'vol_pcr',headerName:"Vol PCR",minWidth: 220,maxWidth:250,
                 cellRenderer: MetaDataVolPCRRenderer,
                 cellStyle: function(params) {
                    return { fontSize:12}
                 }
            },

            { field: 'wpcr',headerName:"Wt PCR", minWidth: 70,maxWidth:90,
                 cellRenderer: MetaDataDefaultRenderer,
                 cellStyle: function(params) {
                    return { fontSize:12}
                 }
            },
            { field: 'vix',headerName:"Series VIX", minWidth: 80,maxWidth:110,
                 cellRenderer: MetaDataDefaultRenderer,
                 cellStyle: function(params) {
                    return { fontSize:12}
                 }
            },
            { field: 'nvixrange',headerName:"VIX Range",minWidth:80,maxWidth:110, 
                cellRenderer: MetaDataDefaultRenderer,
                cellStyle: function(params) {
                    return { fontSize:12}
                }
            },
            { field: 'nvixrange_1d',headerName:"1D VIX Range-Spot", minWidth: 160,maxWidth:190,
                cellRenderer: MetaDataDefaultRenderer,
                cellStyle: function(params) {
                return { fontSize:12}
             }
            },
            { field: 'eod_range',headerName:"Series VIX Range-Spot", minWidth: 170,maxWidth:200,
                cellRenderer: MetaDataDefaultRenderer,
                cellStyle: function(params) {
                    return { fontSize:12}
             }
            },

]


    }    

                return def;
}
