export const URL = {
  REGISTER: "authentication/register",
  ACTIVATE_USER: "authentication/activate-user",
  LOGIN: "authentication/login",
  FORGOT_PASSWORD: "authentication/forgot-password",
  REFRESH: "authentication/refresh",
  MPCHART: "chart/mp",
  MPCHART_STATUS: "chart/status",
  RESET_PASSWORD: "authentication/reset-password",
  UPDATE_PASSWORD: "user/update-password",
  UPDATE_USER: "user/update-user",
  RESEND_ACTIVATION_EMAIL: "authentication/resend-activation-email",
  RESEND_FORGOT_PASSWORD_EMAIL: "authentication/resend-forgot-password-email",
  // GET_USER_DETAILS: "user/get-user-details",
  LOGOUT: "authentication/logout",
  LINE_CHART_DATA:"chart/lc",
  MPCHART_DATA: "chart/mpdata",
  MPCHART_UIDATA:"chart/uidata",
  USER_SETTING:"/user/settings",
  USER_DETAILS:"/user/details",
  OPTIONS_TABLE:"/chart/optchain",
  IDX_TICKER:"/chart/idxticker",
  UPDATE_USER_SETTING:"/user/update-settings",
  GREEK_TABLE:"/chart/greekstable",
  FII_DATA:"/chart/fiidata",  //chart/fiidata?dt=19-12-2022 GET
  FEED_DATA:"/chart/feed-admin",
  USER_DATA:"/user/list",
  ORDER_DATA:"/user/get-order-details",
  USER_SUB_CHECK:"/user/manual-subscribe",
  REFRESH_IMDATA:"/chart/refresh-imdata",
  RELOAD_FEED_HIST:"/chart/reload-histick",
  SWITCH_FEED_DEVICE:"/chart/switch-device",
  SWITCH_MPLITE_ROLES:"/user/switch-mplite-roles",
  USER_POSITIONS:"user/positions",
  UPDATE_USER_POSITION:"user/update-positions",
  DELETE_USER_POSITION:"user/delete-positions",
  INDEX_DATA:"/chart/indextable",
  SAVE_BOOKMARK:"/chart/save-bookmark",
  GET_BOOKMARK_LIST:"chart/all-bookmarks",
  GET_BOOKMARK_DATA:"chart/link",
  DELETE_BOOKMARK:"chart/delete-bookmark",
  DELETE_ALL_AUTOSAVED_BOOKMARK:"chart/clear-autosaved-links",
  SUBSCRIPTION_ORDER:"/user/order",
  GET_SUBSCRIPTION_PLANS:"/user/plans?all=1",
  SUBSCRIPTION_STATUS:"/user/subscribe",
  USER_SUBSCRIPTION:"/user/subscription",
  CHART_STATUS:"/chart/status",
  OF_CHART:"/chart/of",
  GET_KRL_LIST:"/chart/krl",
  SAVE_KRL:"/chart/save-krl",
  DELETE_KRL:"/chart/remove-krl",
  BORKER_LOGIN:"/user/broker-login",
  ZERODHA_TOKEN:"/user/zerodha-token",
  FYERS_TOKEN:"/user/fyers-token",
  CANDLE_CHART:"/chart/cc",

};
