import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../common/hooks/useRefreshToken";
import useAuth from "../common/hooks/useAuth";
import {Grid, CircularProgress,Typography,Link} from "@mui/material";
import { TEXT_MSGS } from "../common/utility/constant";


const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();
    const [apiError,setAPIError]=useState(undefined);
    const location = useLocation();
    console.log("TestRefresh PersistLogin Auth,persist=",auth,persist);

    const [online, setOnline] = useState(window.navigator.onLine);
    useEffect(() => {
        let isMounted = true;
        console.log("ONLINE+++++++++++++++>",online);

        const verifyRefreshToken = async () => {
            try {
                setAPIError(undefined);
                await refresh();
                console.error("useRefreshToken10 success =");
            }
            catch (err) {
                console.error("useRefreshToken11 =",err);
                console.error(err);
                // err?.response?.status
                setAPIError(err)
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }

       
        // Avoids unwanted call to verifyRefreshToken
        // // !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);
        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, [])

    // useEffect(() => {
    //     console.log(`isLoading: ${isLoading}`)
    //     console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    // }, [isLoading])

    return (
        <>
            {apiError==undefined?
            <>
            {!persist 
                ? <Outlet />
                : isLoading
                    ? <Grid component="main" container display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{height: `calc(100vh - 64px)` }}>
                        <div className="flexGrow">
                            <CircularProgress />
                        </div>
                    </Grid>
                    : <Outlet />
            }
            </>:
            <>
                {apiError?.response?.status==401?
                <Navigate to="/login" state={{ from: location }} replace />:
                <Grid component="main" container display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{height: `calc(100vh - 64px)` }}>
            
                    {online?        
                    <Typography variant="warningText" sx={{fontSize:20,cursor:'default'}}>{apiError?.response?.data?.message?apiError?.response?.data?.message:TEXT_MSGS.NETWORK_ERROR_MSG}</Typography>:
                    <Typography variant="warningText" sx={{fontSize:20,cursor:'default'}}>{TEXT_MSGS.INTERNET_ERROR_MESSAGE}</Typography>
                    }
            
                    <div className="flexGrow">
                        <Link sx={{mt:2,pt:2,cursor:'pointer'}}onClick={()=>window.location.reload()}  variant="link">Refresh Page</Link>
                    </div>
                </Grid>
                }
            </>
            }
        </>
    )
}

export default PersistLogin