import { USER_ROLE_FIELDS } from "./constant"
export const TABLE_COLORS={

GREEN:"#49BD38",
RED:"#FC6F7C",
ORANGE:"#b0946b",
BLUE:"#3c75dc",
VPOC:"#f8b3f2",
VWAP:"#a0f49c",
SELECTED_ROW_COLOR:"#324F6D",
DEFAULT_ROW_COLOR:"#022d5a",
HIGHLIGHT_ROW_COLOR:"#456382",
STRIKE_BACKGROUND_COLOR:"#324F6D",
HIGHTLIGHT_VOL:"#d37133",
CHART_ICON_COLOR:"#36C2CF",
CHART_LINK_TEXT:"#36C2CF",
}

export const OPTIONS_TABLE_CHARTS={
  CHART_TYPE_CALL:1,
  CHART_TYPE_PUT:2,
  CHART_TYPE_CALL_PUT_BOTH:3,
  // LOCALE_STRING:"en-IN"  
}


export const GREEK_TABLE_TAB_DATA=[
  {
    name:"NIFTY Greek Chart",
    value:"NIFTY"
  },
  {
    name:"BANKNIFTY Greek Chart",
    value:"BANKNIFTY"
  },
  {
    name:"FINNIFTY Greek Chart",
    value:"FINNIFTY"
  },
  {
    name:"MIDCPNIFTY Greek Chart",
    value:"MIDCPNIFTY"
  }
]

export const GREEKS_TABLE_CHARTS={
  CHART_TYPE_CALL:1,
  CHART_TYPE_PUT:2,
  CHART_TYPE_CALL_PUT_BOTH:3,
  LOCALE_STRING:"en-IN"  
}
export const GREEKS_TABLE_TARGET_TIME = [
  {
    value: 0,
    label: "A",
    time:"09:15"
  },
  {
    value: 5,
    label: "",
    time:"09:30"
  },
  {
    value: 10,
    label: "B",
    time:"09:45"
  },
  {
    value: 15,
    label: "",
    time:"10:00"
  },
  {
    value: 20,
    label: "C",
    time:"10:15"
  },
  {
    value: 25,
    label: "",
    time:"10:30"
  },
  {
    value: 30,
    label: "D",
    time:"10:45"
  },
  {
    value: 35,
    label: "",
    time:"11:00"
  },
  {
    value: 40,
    label: "E",
    time:"11:15"
  },
  {
    value: 45,
    label: "",
    time:"11:30"
  },
  {
    value: 50,
    label: "F",
    time:"11:45"
  },
  {
    value: 55,
    label: "",
    time:"12:00"
  },
  {
    value: 60,
    label: "G",
    time:"12:15"
  },
  {
    value: 65,
    label: "",
    time:"12:30"
  },
  {
    value: 70,
    label: "H",
    time:"12:45"
  },
  {
    value: 75,
    label: "",
    time:"13:00"
  },
  {
    value: 80,
    label: "I",
    time:"13:15"
  },
  {
    value: 85,
    label: "",
    time:"13:30"
  },
  {
    value: 90,
    label: "J",
    time:"13:45"
  },
  {
    value: 95,
    label: "",
    time:"14:00"
  },
  {
    value: 100,
    label: "K",
    time:"14:15"
  },
  {
    value: 105,
    label: "",
    time:"14:30"
  },
  {
    value: 110,
    label: "L",
    time:"14:45"
  },
  {
    value: 115,
    label: "",
    time:"15:00"
  },
  {
    value: 120,
    label: "M",
    time:"15:15"
  },
  {
    value: 125,
    label: "",
    time:"15:30"
  },
  
];

export const INDEX_TABLE_TAB_DATA=[
  {
    name:"NIFTY_FUT",
    value:"NIFTY_FUT"
  },
  {
    name:"BANKNIFTY_FUT",
    value:"BANKNIFTY_FUT"
  },
  {
    name:"FINNIFTY_FUT",
    value:"FINNIFTY_FUT"
  },
  {
    name:"MIDCPNIFTY_FUT",
    value:"MIDCPNIFTY_FUT"
  },
  {
    name:"NIFTY",
    value:"NIFTY"
  },
  {
    name:"BANKNIFTY",
    value:"BANKNIFTY"
  },
  {
    name:"FINNIFTY",
    value:"FINNIFTY"
  },
  {
    name:"MIDCPNIFTY",
    value:"MIDCPNIFTY"
  },
]

export const INDEX_TABLE_TAB_DATA_BASIC=[
  {
    name:"NIFTY",
    value:"NIFTY"
  },
  {
    name:"BANKNIFTY",
    value:"BANKNIFTY"
  },
  {
    name:"FINNIFTY",
    value:"FINNIFTY"
  },
  {
    name:"MIDCPNIFTY",
    value:"MIDCPNIFTY"
  }
]

export const getIndexTableTabData=(auth)=>{
  const basicUser=(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true
  // if(basicUser)
  //  return INDEX_TABLE_TAB_DATA_BASIC;
  // else
    return INDEX_TABLE_TAB_DATA
}
