import cloneDeep from "lodash.clonedeep";
export const processIdxTicker = (data) => {
    let processedData=[];
    for (const key in data.INDEX_DATA) {
        let temp=data.INDEX_DATA[key];
        temp.key=key;
        processedData.push(temp);
    }
    return processedData;
};

export const processMetaData = (data,localeString) => {
  let processedData=[];
  console.log(data)
  console.log(localeString)
  data.locale_string = localeString;
  processedData.push(data);
  //add 2nd row only when the pe_oi and ce_oi values are present
  // if(data.max_pe_oi!=undefined &&data.min_pe_oi!=undefined && data.max_ce_oi!=undefined &&data.min_ce_oi!=undefined)
  // processedData.push(data);
      
  
  console.log("Metadata processed=",processedData,data)
  return processedData;
};



  export const processOptionsTableData = (data,selectedRows,metadata,localeString) => {
    console.log("selectedRows=",selectedRows);
    let processedData=[];
    for (const key in data) {
        let temp=data[key];
        // console.log(key)
        temp.key=key;
        temp.ce_checked=(selectedRows!=undefined && selectedRows.call!=undefined &&selectedRows.call.key==key?true:false);
        temp.pe_checked=(selectedRows!=undefined && selectedRows.put!=undefined &&selectedRows.put.key==key?true:false);
        temp.spot=metadata.spot;
        temp.fut=metadata.fut;
        temp.locale_string=localeString;

        if(key==metadata.highlight_strike)
        temp.highlighted=true;

        let index = metadata.vol_alerts_ce.findIndex(item => item == key);
        if(index!=-1)
        temp.ce_vol_alert=true;

        index = metadata.vol_alerts_pe.findIndex(item => item == key);
        if(index!=-1)
        temp.pe_vol_alert=true;

        index = metadata.oi_alerts_ce.findIndex(item => item == key);
        if(index!=-1)
        temp.ce_oi_alert=true;

        index = metadata.oi_alerts_pe.findIndex(item => item == key);
        if(index!=-1)
        temp.pe_oi_alert=true;
        
        index = metadata.doi_alerts_ce.findIndex(item => item == key);
        if(index!=-1)
        temp.ce_doi_alert=true;

        index = metadata.doi_alerts_pe.findIndex(item => item == key);
        if(index!=-1)
        temp.pe_doi_alert=true;

        if(temp.ce_vpoc>0 && temp.ce_ltp>temp.ce_vpoc)
        temp.ce_vpoc_alert=true

        if(temp.pe_vpoc>0 && temp.pe_ltp>temp.pe_vpoc)
        temp.pe_vpoc_alert=true 
        
        processedData.push(temp);
    }
    return processedData;
  };  

  export const processGreekTableData = (data,highlight_strike,localeString) => {
    // console.log("selectedRows=",selectedRows);
    let processedData=[];
    for (const key in data) {
      let length=Object.keys(data[key]).length
      let row=new Map();
      for (const keyInner in data[key]) {
        let tempCall={};
        let tempPut={};
        
          tempCall.id="call"+keyInner;
          tempCall.data=data[key][keyInner]
          tempCall.data.ce_qty=0;
          tempCall.data.locale_string=localeString;
          
          tempCall.data.date=keyInner;
          // tempCall.data.key=key;
          row.set(tempCall.id,data[key][keyInner]);
          
          // tempPut.id="put"+key+keyInner;
          tempPut.id="put"+keyInner;
          tempPut.data=data[key][keyInner]
          tempPut.data.pe_qty=0;
          tempPut.data.locale_string=localeString;
          
          tempPut.data.date=keyInner;
          // tempPut.data.key=key;
          row.set(tempPut.id,data[key][keyInner]);
          
         }
      // console.log("data=",row)
      let finalData={}
      finalData.key=key;
      finalData.data=row;
      if(key==highlight_strike)
        finalData.highlighted=true;
      else
      finalData.highlighted=false;
      processedData.push(finalData);
    }
    console.log("processedData=",processedData)
    return processedData;
  }


  export const processGreekPositionData=(data)=>{
    let processedData=[];
    for (const key in data) {
      if(data[key].position_data!=undefined){
      let temp={}
      temp.data=data[key];
      temp.key=key;
      temp.value=key;
      // console.log("processGreekPositionData temp=",temp);
      processedData.push(temp)
      }
    }
    return processedData;

  }

  export const processIndexData = (data,localeString) => {
    let processedData=[];
    for (const key in data) {
        let temp=data[key];
        temp.key=key;
        temp.name=key;
        temp.change=data[key]["Change"];
        temp.AvgVolPct=data[key]["AvgVol14Pct"];
        temp.open_high_low=data[key]["Open"]==data[key]["High"] ? 0 : data[key]["Open"]==data[key]["Low"] ? 1 : 2;
        temp.highlighted=key.includes("NIFTY")
        temp.isPositive=temp.key>=0?true:false;
        temp.locale_string=localeString;
        processedData.push(temp);
    }
    return processedData;
};


  