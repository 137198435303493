import { Drawer, Box, Typography, IconButton, ListItemSecondaryAction,Collapse} from '@mui/material'
import { useState,useEffect,useCallback } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { getDrawerList } from '../../common/utility/configData'
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Settings from '@mui/icons-material/Settings';
import { ChevronLeft } from '@mui/icons-material';
import { getIndexOfRoute } from '../../common/utility/helperFunc';
import { ALLOWED_ROLES_PRO_1, TEXT_MSGS, USER_ROLE_FIELDS } from '../../common/utility/constant';
import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';
import { useNavigate} from 'react-router-dom';
import { Snackbar, Alert} from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import DrawerContextMenu from './drawer_context_menu';
import useAuth from '../../common/hooks/useAuth';
import LockIcon from '@mui/icons-material/Lock';
import RupeeIcon from '@mui/icons-material/CurrencyRupee';
import packageJson from '../../../package.json';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';


export const DrawerNestedMenu = (props) => {
  
  const routePath=window?.location?.pathname;
  const { auth } = useAuth();
  // console.log("path================================>auth======================",window?.location?.pathname,window?.location,auth);
  const search=window?.location?.search;
  const [drawerListData,setDrawerList]=useState(getDrawerList(auth))
  const [selectedIndex, setSelectedIndex] = useState(getIndexOfRoute(routePath,search,auth));
  const [showContextMenu,setShowContextMenu]=useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [url,setURL]=useState(undefined);

  const [collapsed, setCollapsed] =useState(true);
 
  const isExpandable = props.items && props.items.length > 0
  const [open, setOpen] = useState(props.selectedItem&&props.selectedItem.parentIndex==props.index)

  function handleClick() {
    setOpen(!open)
  }
  
 const navigate = useNavigate();
 

  const exitMenu=()=>{
    setShowContextMenu(false);
   }

  

//   const handleListItemClick = (event, index) => {
//     const found = auth.role.some(r=> drawerListData[index].roles.indexOf(r) >= 0)
//     const resourceAllowed=drawerListData[index].resources==undefined?true:auth.resources?.some(r=> drawerListData[index].resources.indexOf(r) >= 0);
//     const isProNeeded = (ALLOWED_ROLES_PRO_1 === drawerListData[index].roles);
//     if(!found || !resourceAllowed){
//         console.log("Not allowed for the roles",auth.role,drawerListData[index].roles, isProNeeded)
//         //show modal
//         if(isProNeeded){
//           if(drawerListData[index].name=="Orderflow Charts" && auth.role.includes(USER_ROLE_FIELDS.PRO)){
//             console.log("OF drawer inside")
//             props.openSubscribeProModal(true,TEXT_MSGS.PREMIUM_PLUS_UPGRADE_ORDERFLOW_MSG);
//           }
//           else
//             props.openSubscribeProModal();
//         }
//         else
//           props.openSubscribeModal();
//         setIsDrawerOpen(false); 
//         return;
//     }
//     if(selectedIndex!==index){
//       console.log("drawer clicked event=",event)
//       if(event.ctrlKey ||event.metaKey){
//         window.open( `${process.env.REACT_APP_URL}${drawerListData[index].route.slice(1)}`, '_blank', 'noreferrer');
   
//       }
//     else
//     {
//       setSelectedIndex(index);
//       console.log("drawer pageview",window.location.pathname + window.location.search,drawerListData[index].route);
//       props.onChange(drawerListData[index].route,drawerListData[index].component);
//       setIsDrawerOpen(false);  
//     }
//   }
//   };

  

   //closes the context menu
  
useEffect(()=>{
    console.log("Nested menu selectedItem=",props.selectedItem,props.index)
},[])
  

  
  return (
    <>
   {/* onContextMenuClick */}
                    <ListItemButton sx={{ py: 1, minHeight: 40, color: 'white', paddingRight:0  }} onClick={()=>handleClick()}
                    selected={props.selectedItem.parentIndex==props.index && open==false}
                    >
        
                    <ListItemText
                      primary={props.item.name}
                      primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                    />

                    
                     {open ? 
                        <ListItemIcon sx={{ml:0,marginRight:"4px",minWidth:24, color:"#FFFFFF"}}>
                            <ExpandLess sx={{width:24}} />
                        </ListItemIcon>:
                        <ListItemIcon sx={{ml:0,marginRight:"4px",minWidth:24, color:"#FFFFFF"}}>
                            <ExpandMore sx={{width:24}} />
                        </ListItemIcon>
                    }

                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      {props.item.items.map((nestedItem,nestedIndex) => (
                        <ListItemButton sx={{ py: 1, minHeight: 40, color: 'white', paddingRight:0,marginLeft:"10px"  }} 
                        selected={props.selectedItem.parentIndex==props.index && props.selectedItem.childIndex==nestedIndex}
                        onContextMenu={(event)=>props.onContextMenuClick(event,nestedIndex,nestedItem)}
                        onClick={(event)=>props.onClick(event,nestedIndex,nestedItem,props.index)}>
        
                        <ListItemText
                          primary={nestedItem.name}
                          primaryTypographyProps={{ fontSize: 14, paddingLeft:"20px", fontWeight: 'medium' }}
                        />
                        { !auth.role.some(r=> nestedItem.roles.indexOf(r) >= 0) || (nestedItem.resources!=undefined && !auth.resources?.some(r=> nestedItem.resources.indexOf(r) >= 0))?
                    
                    ALLOWED_ROLES_PRO_1 == nestedItem.roles ?
                      <ListItemIcon sx={{ml:0,minWidth:24, color:"#DCB146"}}>
                        <LockIcon sx={{width:18}} />
                      </ListItemIcon>
                      :
                      <ListItemIcon sx={{ml:0,minWidth:24, color:"silver"}}>
                        <LockIcon sx={{width:18}} />
                      </ListItemIcon>
                    
                    : 
                    nestedItem.name=="Subscription Plans"? 
                      <ListItemIcon sx={{ml:0,minWidth:18}}>
                        <RupeeIcon sx={{width:18}} />
                      </ListItemIcon> : 
                    <></>
                }
                         
                        </ListItemButton>
                        ))}
                      </Collapse>


                    </>
 
  )
}
