import React from 'react'
import {Grid, Box, Paper} from "@mui/material";


/**
 * 
 * @param {*} param0 
 * @returns Conatiner to be used in all the form for login/register
 */
function FormContainer({children}) {
  return (
    <Grid item mobile={12} tablet={8} laptop={7} component={Paper} elevation={0} 
    sx={{height:"100%",pt:{mobile:8,tablet:8}}}
    square>
          <Box
            sx={{
              mx: {mobile:2,tablet:2,laptop:4},
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent:'center',
              minHeight:"100%",
              // minHeight:`calc(100% - 32px)`     //fixed header height=32px
            }}
          >
            {children}
            </Box>
    </Grid>        
  )
}

export default FormContainer
