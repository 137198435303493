import React,{useCallback,useEffect} from "react";
import  "../../pages/dashboard/styles/styles.scss";

const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';

const DrawerContextMenu = (props) => {
  let {
    xPosition,
    yPosition,
    url,
    exitMenu,
    } = props;

  useEffect(()=>{
    console.log("Drawer context menu call url=",url);
  },[])  
  
  const handleEscKey = useCallback((event) => {
    if (event.key === KEY_NAME_ESC) {
        event.preventDefault();
      exitMenu();
      return;
    }
  }, [exitMenu]);
 
  useEffect(() => {
    // console.log("Contextmenus electedIndexes =",selectedProfilesIndex);
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
 
    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);

  const openInNewTab=()=>{
    if(url!=undefined){
        exitMenu();
        window.open(url, '_blank', 'noreferrer');
    
    }
   
  }

  return (
    <>
    <ul
          className="context-menu"
          style={{
            top: yPosition,
            left: xPosition,
            zIndex:99999,
            position:'absolute'
          }}
        >
        <li onClick={(e) => openInNewTab()}>
            Open in new tab
        </li>
  
        <li onClick={(e) => exitMenu()}>
            Close
        </li>
        
        </ul>
        </>
  );
};

export default DrawerContextMenu;
