import { createTheme } from '@mui/material/styles';
import componentStyleOverrides from './compStyleOverride';
import colors from '../assets/styles/_themes-vars.module.scss';

/**
 * Material ui theme object for the whole app
 * Contains the customization based on the theme
 * Also containe the common custom variants for the material ui component to be used throughout the app
 */

const theme = createTheme({
    breakpoints: {
        values: {
          mobile: 0,
          tablet: 640,
          laptop: 1024,
          desktop: 1200,
        },
      },
    palette:{
        mode:'dark',
        common: {
            black: colors.paper
        },
        background: {
            paper: colors.paper,
            default: colors.paper
        },
        primary:{main:colors.white},
        secondary:{main:colors.secondaryThemeShade01},
        
        primaryTheme:{
            shade01:colors.primaryThemeShade01,
            shade02:colors.primaryThemeShade02,
            shade03:colors.primaryThemeShade03,
            shade04:colors.primaryThemeShade04,
            shade05:colors.primaryThemeShade05,
            shade06:colors.primaryThemeShade06,
            shade07:colors.primaryThemeShade07,
            shade08:colors.primaryThemeShade08,
            shade09:colors.primaryThemeShade09,
            shade10:colors.primaryThemeShade10
          },
          secondaryTheme:{
            shade01:colors.secondaryThemeShade01,
            shade02:colors.secondaryThemeShade02,
            shade03:colors.secondaryThemeShade03,
            shade04:colors.secondaryThemeShade04,
            shade05:colors.secondaryThemeShade05
          },
          success:{main:colors.success},
          error:{main:colors.error},
          warning:{main:colors.warning},
          vpoc:{main:colors.vpoc},
          vwap:{main:colors.vwap},
          hvn:{main:colors.hvn},
          characterColor:{main:colors.characterColor},
          iconColor:{main:colors.white},
          buttonTextColor:{main:colors.white,outlined:colors.primaryThemeShade02},
          borderColor:{main:colors.primaryThemeShade02},
          inputFieldColor:{main:colors.white},
          linkColor:{main:colors.secondaryThemeShade04,secondary:colors.white},
          volumeBar:{main:colors.volumeBar},
          
   },

    
      typography: {
        fontFamily: [
          'DM Sans',
          'sans-serif',
        ].join(','),
        link:{
          fontSize:14,
          fontWeight:500
        },
        linkSupportText:{
          fontSize:14,
          fontWeight:400
        },
        secondaryLink:{
          fontSize:12,
          fontWeight:500
        },
        copyRight:{
          fontSize:12,
          fontWeight:400,
          '@media (max-width:640px)': {
            fontSize: 10,
          },
          color:colors.white
        },
        titleLarge:{
          fontSize:40,
          fontWeight:500,
          color:colors.white,
          '@media (max-width:640px)': {
            fontSize: 20,
          },
        },
        titleMedium:{
          fontSize:32,
          fontWeight:500,
          '@media (max-width:640px)': {     //below tablet
            fontSize: 20,
          },
         
        },
        descriptionLarge:{
          fontSize:20,
          fontWeight:400,
          color:colors.white,
          '@media (max-width:640px)': {
            fontSize: 14,
          },
        },
        descriptionMedium:{
          fontSize:14,
          fontWeight:400,
          color:colors.white,
          '@media (max-width:640px)': {
            fontSize: 14,
          },
        },
        warningText:{
          fontSize:12,
          fontWeight:400,
          color:colors.warning,
          '@media (max-width:640px)': {
            fontSize: 10,
          },
        },
        errorText:{
          fontSize:12,
          fontWeight:400,
          color:colors.error,
          '@media (max-width:640px)': {
            fontSize: 10,
          },
        },
        titleSmall:{
          fontSize:18,
          fontWeight:500,
          '@media (max-width:640px)': {     //below tablet
            fontSize: 18,
          },
        },
        lableTextSettings:{
          fontSize:12,
          fontWeight:400,
          '@media (max-width:640px)': {     //below tablet
            fontSize: 12,
          },
        }
      },});

theme.components = componentStyleOverrides(theme);
export default theme;
