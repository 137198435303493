import React,{useCallback,useEffect, useState} from "react";
import "../styles/styles.scss"
import CallSplitIcon from '@mui/icons-material/CallSplit';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import { Splitscreen } from "@mui/icons-material";
import { CHART_SCREEN_TYPE, VA_TYPE } from "../../../common/utility/mp_chart_constants";
import { TIME_FRAME_VALUES } from "../../../common/utility/constant";

const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';

const ContextMenu = (props) => {
  let {
    xPosition,
    yPosition,
    profileData,
    selectedProfilesIndex,
    exitMenu,
    currentIndex,
    selectedInstrumentData,
    // showContextMenu = false,
   
    // setSelectedIndexes,
    // selectedIndexes,
    // setShowContextMenu,
    // unMerge,
    // isMerged,
    // isStacked,
    // isVolumeVisible,
    // isVolumeWithDataVisible,
    // updateIsVolumeVisible,
    updateDisplayType,
    toggleStackedProfiles,
    toggleVolumeProfile,
    toggleVolumeNumbers,
    mergeProfiles,
    unmergeProfile,
    toggleTPOBasedVA,
    showTPOonVolume,
    toggleVolumeOnlyProfile,
    showInfoInRightPanel,
    key,
    chartScreenType,
    createKRL,
    isTradingSessionActive,
    level,
    hasFullAccess,
    // updateIsVolumeWithDataVisible,
    // isLiveData,
  } = props;

  const [is_Monthly_Yearly,]=useState((props.selectedTimeFrame==TIME_FRAME_VALUES.monthly || props.selectedTimeFrame==TIME_FRAME_VALUES.monthly_series || props.selectedTimeFrame==TIME_FRAME_VALUES.yearly)?true:false)
      
  const handleEscKey = useCallback((event) => {
    if (event.key === KEY_NAME_ESC) {
      exitMenu();
    }
  }, [exitMenu]);
 
 
  useEffect(() => {
    // console.log("Contextmenus electedIndexes =",selectedProfilesIndex);
    console.log("context menu key, charscreen",key,chartScreenType,is_Monthly_Yearly)
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
 
    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);
 
  
  return (
    <>
    <ul
          className="context-menu"
          style={{
            top: yPosition,
            left: xPosition,
          }}
        >
        {selectedProfilesIndex.length==1 && profileData[currentIndex].showTPO?
        <>
        <li style={is_Monthly_Yearly || profileData[currentIndex].dateList.length==1 ||profileData[currentIndex].dateList.length>selectedInstrumentData.max_cmp_days? null:{pointerEvents: "none",opacity:0.6}} onClick={(e) => updateDisplayType(profileData[currentIndex],currentIndex)}>
            {profileData[currentIndex].isStacked ? "Split Profile" : "Stack Profile"}
        </li>
        <hr className="hr-menu"/>
        </>
        :
        <></>
        }


        {selectedProfilesIndex.length==1 && selectedInstrumentData.vp?
        <li style={selectedInstrumentData.vp? null:{pointerEvents: "none",opacity:0.6}} onClick={(e) => toggleVolumeProfile(!profileData[currentIndex].isVolumeProfile)}>
            {profileData[currentIndex].isVolumeProfile ||profileData[currentIndex].showOnlyVolumeProfile? "Hide Volume Profile" : "Show Volume Profile"}
        </li>
        :
        <></>
        }
        {selectedProfilesIndex.length==1 && selectedInstrumentData.vp && !profileData[currentIndex].isTPOonVolume?
        <li style={selectedInstrumentData.vp && profileData[currentIndex].isVolumeProfile? null:{pointerEvents: "none",opacity:0.6}} onClick={(e) => toggleVolumeNumbers(!profileData[currentIndex].isVolumeNumbers)}>
            {profileData[currentIndex].isVolumeNumbers ? "Hide Volume Numbers" : "Show Volume Numbers"}
        </li>
        :
        <></>
        }

      {selectedProfilesIndex.length==1 && selectedInstrumentData.vp && !profileData[currentIndex].isTPOonVolume?
        <li  onClick={(e) => showTPOonVolume(!profileData[currentIndex].isTPOonVolume)}>
            Volume behind TPO
        </li>
        :
        <></>
        }

        {selectedProfilesIndex.length==1 && selectedInstrumentData.vp &&!profileData[currentIndex].showOnlyVolumeProfile ?
        <>
        <li  onClick={(e) => toggleVolumeOnlyProfile(true)}>
           Show Volume Profile Only
        </li>
        <hr className="hr-menu"/>
        </>
        :
        <></>
        }
        {selectedProfilesIndex.length==1 && selectedInstrumentData.vp && profileData[currentIndex].showOnlyVolumeProfile ?
        <>
                <li  onClick={(e) => toggleVolumeOnlyProfile(false)}>
           Show TPO Profile Only
        </li>
        <hr className="hr-menu"/>
        </>

        :
        <></>
        }

        
        {selectedProfilesIndex.length==1 && selectedInstrumentData.vp?
        <>
        {/* <li  onClick={(e) => toggleTPOBasedVA(!profileData[currentIndex].tpoBasedVA)}>
            {profileData[currentIndex].tpoBasedVA ? "Volume Based VA" : "TPO Based VA"}
        </li> */}
        {profileData[currentIndex].vaType!=VA_TYPE.VOLUME_BASED?
        <>
          <li  onClick={(e) => toggleTPOBasedVA(VA_TYPE.VOLUME_BASED)}>
              Show Volume Based VA
          </li>
         
        </>:
        <></>
        }
        {profileData[currentIndex].vaType!=VA_TYPE.TPO_BASED?
        <>
          <li  onClick={(e) => toggleTPOBasedVA(VA_TYPE.TPO_BASED)}>
              Show TPO Based VA
          </li>
         
        </>:
        <></>
        }
         {profileData[currentIndex].vaType!=VA_TYPE.HYBRID?
        <>
          <li  onClick={(e) => toggleTPOBasedVA(VA_TYPE.HYBRID)}>
              Show Hybrid VA
          </li>
         
        </>:
        <></>
        }
         <hr className="hr-menu"/>
        </>

        :
        <></>
        }

       

        {selectedProfilesIndex.length==1 && profileData[currentIndex].dateList.length>1?
        
        <li onClick={(e) => unmergeProfile()}>
           Unmerge Profile
        </li>:
        <></>}


      {selectedProfilesIndex.length>1?
       <li onClick={(e) => toggleStackedProfiles(true)}>
            {"Show Stacked Profile"}
        </li>
         :
        <></>
         }
         {selectedProfilesIndex.length>1?
       <>
       <li onClick={(e) => toggleStackedProfiles(false)}>
            {"Show Split Profile"}
        </li>
        <hr className="hr-menu"/>
        
        </>
         :
        <></>
         }
        {selectedProfilesIndex.length>1 && selectedInstrumentData.vp?
       <li style={selectedInstrumentData.vp? null:{pointerEvents: "none",opacity:0.6}} onClick={(e) => toggleVolumeProfile(true)}>
            {"Show Volume Profile"}
        </li>
         :
        <></>
         }

         {selectedProfilesIndex.length>1 && selectedInstrumentData.vp?
       <li style={selectedInstrumentData.vp? null:{pointerEvents: "none",opacity:0.6}} onClick={(e) => toggleVolumeProfile(false)}>
            {"Hide Volume Profile"}
        </li>
         :
        <></>
         }

        {selectedProfilesIndex.length>1 && selectedInstrumentData.vp?
       <li style={selectedInstrumentData.vp? null:{pointerEvents: "none",opacity:0.6}} onClick={(e) => toggleVolumeNumbers(true)}>
            {"Show Volume Numbers"}
        </li>
         :
        <></>
         }
         {selectedProfilesIndex.length>1 && selectedInstrumentData.vp?
       <li style={selectedInstrumentData.vp? null:{pointerEvents: "none",opacity:0.6}} onClick={(e) => toggleVolumeNumbers(false)}>
            {"Hide Volume Numbers"}
        </li>
         :
        <></>
         }
         {selectedProfilesIndex.length>1 && selectedInstrumentData.vp?
       <>
       <li style={selectedInstrumentData.vp? null:{pointerEvents: "none",opacity:0.6}} onClick={(e) => showTPOonVolume(true)}>
            {"Volume behind TPO"}
        </li>
        <hr className="hr-menu"/>
        
        </>
         :
        <></>
         }


{selectedProfilesIndex.length>1 && selectedInstrumentData.vp?
       <li style={selectedInstrumentData.vp? null:{pointerEvents: "none",opacity:0.6}} onClick={(e) => toggleVolumeOnlyProfile(true)}>
            {"Show Volume Profile Only"}
        </li>
         :
        <></>
         }
         {selectedProfilesIndex.length>1 && selectedInstrumentData.vp?
       <>
       <li style={selectedInstrumentData.vp? null:{pointerEvents: "none",opacity:0.6}} onClick={(e) => toggleVolumeOnlyProfile(false)}>
            {"Show TPO Profile Only"}
        </li>
        <hr className="hr-menu"/>
        
        </>
         :
        <></>
         }

       
        {/* {selectedProfilesIndex.length>1 && selectedInstrumentData.vp?
        <li  onClick={(e) => toggleTPOBasedVA(false)}>
         Show Volume Based VA
        </li>
        :
        <></>
        } */}
         {selectedProfilesIndex.length>1 && selectedInstrumentData.vp?
        <>
         <li  onClick={(e) => toggleTPOBasedVA(VA_TYPE.VOLUME_BASED)}>
              Show Volume Based VA
          </li>
          <li  onClick={(e) => toggleTPOBasedVA(VA_TYPE.TPO_BASED)}>
              Show TPO Based VA
          </li>
          <li  onClick={(e) => toggleTPOBasedVA(VA_TYPE.HYBRID)}>
              Show Hybrid VA
          </li>
        <hr className="hr-menu"/>
        
        </>
        :
        <></>
        }
      
      {selectedProfilesIndex.length>1?
      <>
            <li  onClick={(e) => mergeProfiles()}>
           Merge Profiles
        </li>
        <hr className="hr-menu"/>
        
        </>:
        <></>
      }

      {selectedProfilesIndex.length==1 && selectedInstrumentData.krl && hasFullAccess?
         <>
          {/* <li  onClick={(e) => createKRL()}> */}
          <li style={isTradingSessionActive && profileData.length-1==selectedProfilesIndex[0]?{pointerEvents: "none",opacity:0.6}: null} onClick={(e) => createKRL(level)}>
            Create Custom KRL
          </li>
          <hr className="hr-menu"/>
           </>:
          <></>
       }
      
      {chartScreenType==CHART_SCREEN_TYPE.FULL_CHART?      //only show this menu for 1st container
        <li onClick={(e) => showInfoInRightPanel()}>
            Display Info in Right Panel
        </li>:
        <></>
      }
        
       
        {/* <li onClick={(e) => exitMenu()}>
            Share
        </li> */}

       
        </ul>
      {/* {showContextMenu ? (
        <ul
          className="context-menu"
          style={{
            top: yPosition,
            left: xPosition,
          }}
        >
          <li onClick={(e) => handleOnClick(e, "select")}>
            {selectedIndexes.indexOf(profileData) === -1
              ? "Select"
              : "Unselect"}
          </li>
          <li onClick={(e) => updateDisplayType(profileData, isLiveData)}>
            {isStacked ? "Split Profile" : "Stack"}
          </li>
          <li onClick={(e) => updateIsVolumeVisible(profileData, isLiveData)}>
            {!isVolumeVisible ? "Show Volume Profile" : "Hide Volume Profile"}
          </li>
          <li
            onClick={(e) =>
              updateIsVolumeWithDataVisible(profileData, isLiveData)
            }
          >
            {!isVolumeWithDataVisible
              ? "Show Volume with Data"
              : "Hide Volume with Data"}
          </li>
          {isMerged ? (
            <li onClick={(e) => unMerge(profileData)}>Unmerge</li>
          ) : null}
        </ul>
      ) : null} */}
    </>
  );
};

export default ContextMenu;
