/**
 * validator utility for all the forms
 * @param {*} values 
 * @returns 
 */

export const validateLoginForm = (values) => {
    const errors = {};
   
     if (!values.email_id.trim()) {
       errors.email_id = 'email id required';
     }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_id.trim())) {
       errors.email_id = 'invalid email address';
     }
   
     if (!values.password.trim()) {
       errors.password = 'password is required';
     }
   
     return errors;
};

export const validateRegisterationForm = (values) => {
  const errors = {};
  if (!values.first_name.trim()) {
    errors.first_name = 'First name is required';
  }
  if (!values.last_name.trim()) {
    errors.last_name = 'Last name is required';
  }
   if (!values.email_id.trim()) {
     errors.email_id = 'Email id required';
   }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_id.trim())) {
     errors.email_id = 'Email address is invlaid';
   }
 
  //commenting the phone number validation as it is not the required field
   // if(!values.contact.trim()){
  //   errors.contact="Mobile number is required"
  //  }else if(!/^[0-9]\d{9}$/i.test(values.contact.trim())){
  //   errors.contact="Mobile no. is invalid"
  //  }

   if (!values.password.trim()) {
     errors.password = 'Please provide password';
   }

   if(!values.terms){
    errors.terms="Please accept the terms & conditons, fees & privacy policy. "
   }
 
   return errors;
 };
 
 export const validateForgotPasswordForm = (values) => {
  const errors = {};
 
   if (!values.email_id.trim()) {
     errors.email_id = 'email id required';
   }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_id)) {
     errors.email_id = 'Invalid email address';
   }
 
   return errors;
};

export const validateResetPasswordForm = (values) => {
  const errors = {};
 
  if (!values.password.trim()) {
    errors.password = 'Please provide password';
  }
  else if (!values.confirm_password) {
    errors.confirm_password = 'Please confirm the password';
  }else{
    if(values.password!==values.confirm_password) {
      errors.confirm_password = "Password not matched";
    }
  }

   return errors;
};
